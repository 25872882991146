const listingFields = {
    start_date: {
        type: 'date',
        label: 'Start Date',
        validations: ['required']
    },
    expiration_date: {
        type: 'date',
        label: 'Expiration Date',
        validations: ['required']
    },
    expiration_alert_date: {
        type: 'date',
        label: 'Lease Expiration Date',
        validations: []
    },
    extension_date: {
        type: 'date',
        label: 'Lease Extension Date',
        validations: []
    },
    fee_schedule: {
        type: 'text',
        label: 'Fee Schedule',
        validations: ['min1','max250']
    },
    extension_fee: {
        type: 'radio',
        label: 'Extension Fee',
        options: [{id:1,desc:'Yes'},{id:0,desc:'No'}],
        validations: []
    },
    // if Yes to fee
    extension_fee_amount: {
        type: 'input',
        label: 'Extension Fee Amount',
        validations: ['min1','max100','no_letters']
    },
    agents: {
        type: 'multi',
        label: 'Listing Agents',
        validations: []
    },
    type: {
        type: 'select',
        label: 'Type',
        options: ['Sale', 'Lease']
    }
};

export default listingFields;

export const initialListingData = {
    id: '',
    start_date: '',
    expiration_date: '',
    expiration_alert_date: '',
    extension_date: '',
    fee_schedule: '',
    extension_fee: null,
    extension_fee_amount: '',
    other_data: {
        agents: [],
        type: '',
        files: []
    }
}

