import React, { useEffect, useState } from "react";
import Button from "../../../components/CustomButtons/Button";
import CustomRadio from "components/CustomRadio/CustomRadio";
import { connect } from 'react-redux';
import * as actions from "store/actions";
import Notification from "../../../components/Notification/Notification";
import NotificationAsk from "../../../components/Notification/NotificationAsk";
import Loader from "../../../components/Loader/Loader";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Table from "components/Table/Table.jsx";
import { IconButton } from "@material-ui/core";
import CustomInput from "components/CustomInput/CustomInputValidate";
import { getUser } from "../../../shared/authValidation";
import { Dialog } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import moment from "moment";
import { Tooltip } from "@material-ui/core";
import axios from "store/axios/axios-crm";
import axios2 from "store/axios/axios-rs"; 
import { clone, handleError } from "../../../shared/utility";

const statusDescriptions = {
    0: 'Pending',
    1: 'Denied',
    2: 'Approved'
}

const determineIfUserIsAnApprover = (data, rsheetReviews) => {
    let userIsApprover = false;
    const user = getUser();
    if (rsheetReviews) {
        rsheetReviews.forEach(rr => {
            console.log('rr', rr);
            if (data) {
                if (data.internal_reviewers) {
                    data.internal_reviewers.forEach(dir => {
                        if (dir.user_id === rr.user_id) {
                            if (user.id === dir.user_id) userIsApprover = true;
                        }
                    })
                }
            }
        });
    }
    return userIsApprover;
}

const getCurrentRsheetReview = (data, rsheetReviews) => {
    let review = null;
    const user = getUser();
    if (rsheetReviews) {
        rsheetReviews.forEach(rr => {
            console.log('rr', rr);
            if (data) {
                if (data.internal_reviewers) {
                    data.internal_reviewers.forEach(dir => {
                        if (dir.user_id === user.id && rr.status === 0) {
                            review = rr;
                        }
                    })
                }
            }
        });
    }
    return review;
}

const InternalReview2 = (props) => {
    const user = getUser();
    const { updateDataObj, data, setData, readonly, setUpdatedRsheetAndClose } = props;
    const [rsheetReviews, setRsheetReviews] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [openedAddUser, setOpenedAddUser] = useState(false);
    const [askDeleteReview, setAskDeleteReview] = useState(null);
    const [msg, setMsg] = useState(null);
    const [filter, setFilter] = useState(false);
    const [otherUsers, setOtherUsers] = useState([]);
    const [notes, setNotes] = useState('');
    
    const getOtherUsers = async () => {
        try {
            const response = await axios.get(`/other_users`);
            setOtherUsers(response.data);
        } catch(e) {
            handleError(e);
        }
    }

    const updateRsInternalReviewer = async (data) => {
        try {
            const response = await axios2.put('/internal_reviewer', data);
            console.log('response on updating rs internal reviewer', response);
            getRsInternalReviewers(data.rs_id);
        } catch(e) {
            handleError(e);
        }
    }

    const createRsInternalReviewer = async (data) => {
        try {
            const response = await axios2.post('/internal_reviewer', data);
            console.log('response on creating rs internal reviewer', response);
            getRsInternalReviewers(data.rs_id);
        } catch(e) {
            handleError(e);
        }
    }

    const getRsInternalReviewers = async (rs_id) => {
        try {
            let query = '/internal_reviewers?rs_id='+rs_id;
            const response = await axios2.get(query);
            setRsheetReviews(response.data);
        } catch(e) {
            handleError(e);
        }
    }

    const addInternalReviewers = async (reviewers) => {
        // only needs the contact ID
        let new_reviewers = [];
        for (let i = 0; i < reviewers.length; i++) {
            try {
                const response = await axios.get('/contact?id='+reviewers[i].id);
                console.log('contee', response.data);
                const contact = response.data;
                const obj = {
                    user_id: contact.connect_user_id,
                    contact_id: contact.id,
                    first_name: contact.first_name,
                    last_name: contact.last_name,
                    email: contact.email
                }
                new_reviewers.push(obj);
            } catch(e) {
                handleError(e);
            }
        }
        // only adds reviewers that aren't in the RS data yet
        let final_reviewers = [];
        console.log('new_rev', new_reviewers)
        new_reviewers.forEach(nr => {
            const arr = data.internal_reviewers ? data.internal_reviewers : [];
            let found = false;
            arr.forEach(ir => {
                if (nr.contact_id = ir.contact_id) {
                    found = true;
                }
            })
            if (!found) final_reviewers.push(nr);
        });
        const newData = clone(data);
        newData.require_internal_review = 'yes';
        newData.internal_reviewers = newData.internal_reviewers ? newData.internal_reviewers.concat(clone(final_reviewers)) : clone(final_reviewers);
        console.log('new data', newData);
        setData(newData);
    }

    useEffect(() => {
        if (data.id) {
            // get rsheet_reviews entries
            getRsInternalReviewers(data.id);
            if (!props.rsheetData) props.getRsheet(data.id);
        }
    }, []);

    const internalReviewers = data.internal_reviewers ? data.internal_reviewers : [];

    const currentUserIsAnApprover = determineIfUserIsAnApprover(data, rsheetReviews);

    const rsheetReview = currentUserIsAnApprover ? getCurrentRsheetReview(data, rsheetReviews) : null;

    const renderInternalReviewActions = () => {
        return <div className="">
            <div style={{backgroundColor:'white', maxWidth:500, margin:'0 auto', padding:20}}>
                <CustomInput
                    label="Internal Review Notes"
                    value={notes}
                    onChange={(e) => {
                        if (notes.length < 255) {
                            setNotes(e.target.value);
                        }
                    }}
                />
            </div>
            {(props.rsheetData && rsheetReview) && <Button color="primary" className="mr-20" onClick={() => {
                updateRsInternalReviewer({
                    status: 2,
                    rs_id: props.rsheetData.id,
                    notes,
                    user_id: user.id
                })
                props.createRsHistory({
                    status: 12, // approve
                    json_data: data,
                    rs_id: props.rsheetData.id,
                    notes
                })
                setTimeout(() => {
                    props.close();
                }, 2000);
            }}>APPROVE</Button>}

            {(props.rsheetData && rsheetReview) && <Button color="rose" onClick={async () => {
                await updateRsInternalReviewer({
                    status: 1,
                    rs_id: props.rsheetData.id,
                    notes,
                    user_id: user.id
                })
                props.createRsHistory({
                    status: 13, // deny
                    json_data: data,
                    rs_id: props.rsheetData.id,
                    notes
                })
                props.close();
            }}>DENY</Button>}
        </div>
    }

    console.log('InternalReview2', props, rsheetReviews, currentUserIsAnApprover);

    return <div><div className={`${props.viewOnly ?
        'lightgray-bg no-click enclosure' 
    : 
        ''}`}>
        <CustomRadio
            label="Require Internal Review?,Yes,No"
            options={['yes','no']}
            onChange={(e) => {
                if (!data.id) {
                    return setMsg('Please save routing sheet first');
                }
                if (e.target.value === 'yes' && data.id) {
                    //      Gather all illi represented agents
                    //      ----------------------------------
                    // {
                    //      id = contact ID
                    //      lastname = contact last name
                    //      name = contact first name 
                    // }
                    let internal_reviewers_to_add = [];
                    if (data.illi_represented_lessor === 'Yes') {
                        if (data.illi_represented_lessor_agents) {
                            for (let i = 0; i < data.illi_represented_lessor_agents.length; i++) {
                                const dat = data.illi_represented_lessor_agents[i];
                                internal_reviewers_to_add.push(dat);
                            }
                        }
                    }
                    if (data.illi_represented_lessee === 'Yes') {
                        if (data.illi_represented_lessee_agents) {
                            for (let i = 0; i < data.illi_represented_lessee_agents.length; i++) {
                                const dat = data.illi_represented_lessee_agents[i];
                                internal_reviewers_to_add.push(dat);
                            }
                        }
                    }
                    addInternalReviewers(internal_reviewers_to_add);
                    console.log('adding internal reviewers', internal_reviewers_to_add);
                } else {
                    updateDataObj('require_internal_review', e.target.value);
                }
            }}
            value={data.require_internal_review}
            readonly={readonly ? true : false}
        />

        {data.require_internal_review === 'yes' && props.rsheetData && <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Button color="primary" className="mr-20" onClick={() => {
                    getOtherUsers();
                    setOpenedAddUser(true);
                }}><i className="fa fa-plus"></i> ADD NEW REVIEWER</Button>
                {(data.id && internalReviewers.length > 0 && props.rsheetData.status !== 11) && <Button
                    color="white"
                    onClick={async () => {
                        const recordHistory = {
                            status: 11,
                            json_data: data,
                            rs_id: data.id
                        };
                        const updateObj = {
                            id: data.id,
                            json_data: JSON.stringify(data),
                            status: 11,
                            project_name: data.project_name
                        };
                        props.updateRsheet(updateObj, null, recordHistory);
                        for (let i = 0; i < data.internal_reviewers.length; i++) {
                            const dir = data.internal_reviewers[i];
                            await createRsInternalReviewer({
                                user_id: dir.user_id,
                                rs_id: data.id,
                                notes: 'Start pending review',
                                status: 0
                            })
                        }
                        setTimeout(() => {
                            props.close();
                        }, 2000);
                    }}
                >SET ROUTING SHEET FOR INTERNAL REVIEW</Button>}

            </GridItem>

            {<GridItem xs={12} sm={12} md={12}>
                    <div className="boxed" style={{marginTop:20}}>
                        <h4>Internal Reviewer(s)</h4>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={['Name / Email','Status','Date / Time','Notes','Actions']}
                            tableData={internalReviewers.map((ir,x) => {
                                let note = '';
                                let status = '';
                                let timestamp = '';
                                if (rsheetReviews) {
                                    rsheetReviews.forEach(rr => {
                                        if (rr.user_id === ir.user_id) {
                                            note = rr.notes;
                                            status = statusDescriptions[rr.status] ? statusDescriptions[rr.status] : '';
                                            timestamp = moment(rr.timestamp.replace("T", " ")).subtract(8, 'h').format("MM/DD/YYYY h:mm a");
                                            
                                        }
                                    })
                                }
                                let resubmitBtn = null;
                                if (status === 'Denied') {
                                    resubmitBtn = <Tooltip title="Re-submit" placement="top"><IconButton
                                        onClick={() => {
                                            createRsInternalReviewer({
                                                rs_id: data.id,
                                                user_id: ir.user_id,
                                                email: ir.email,
                                                first_name: ir.first_name,
                                                last_name: ir.last_name,
                                                status: 0
                                            });
                                            const updateObj = {
                                                id: data.id,
                                                json_data: JSON.stringify(data),
                                                status: 11,
                                                project_name: data.project_name
                                            };
                                            props.updateRsheet(updateObj, null, null);
                                            setTimeout(() => {
                                                props.close();
                                            }, 2000);
                                        }}
                                    >
                                        <i className="fa fa-envelope"></i>
                                    </IconButton></Tooltip>
                                } 
                                return [
                                    <div>{`${x+1}. ${ir.first_name} ${ir.last_name} - ${ir.email}`}</div>, // name
                                    status, // review status
                                    timestamp,
                                    note,
                                    status !== 'Approved' && <div>
                                        <Tooltip title="Remove" placement="top">
                                            <IconButton
                                                style={{marginRight:20}}
                                                onClick={() => {
                                                    setAskDeleteReview(x);
                                                }}
                                            >
                                                <i className="fa fa-trash"></i>
                                            </IconButton>
                                        </Tooltip>
                                        {resubmitBtn}
                                    </div>
                                ];
                            })}
                            coloredColls={[1]}
                            colorsColls={["primary"]}
                        />
                        {internalReviewers.length === 0 && <div><em>* No Reviewers *</em></div>}
                    </div>
            </GridItem>}
        </GridContainer>}

        {openedAddUser && <Dialog
            open={openedAddUser}
            keepMounted  
            maxWidth="md" 
            fullWidth={true}
        >
            <div className="close-btn" onClick={() => { setOpenedAddUser(false) }}><Close /></div>
            <div style={{padding:20,marginTop:60}} className="text-center">
                <h4>Other Users</h4>
                <CustomInput
                    label="Search Users"
                    value={filter}
                    onChange={(e) => {
                        setFilter(e.target.value);
                    }}
                />
                <div style={{height:500, overflow:'scroll'}} className="hover-row-gray">
                    <Table
                        tableHeaderColor="primary"
                        tableHead={['Name','Add']}
                        tableData={otherUsers.filter(ou => {
                            if (!filter) return true;
                            if (filter) {
                                const lowercaseName = ou.name.toLowerCase();
                                if (lowercaseName.indexOf(filter.toLowerCase()) !== -1) {
                                    return true;
                                }
                            }
                        }).filter(ou => {
                            let found = false;
                            if (data.internal_reviewers) {
                                data.internal_reviewers.forEach(dir => {
                                    if (dir.user_id === ou.id) {
                                        found = true;
                                    }
                                })
                            }
                            if (found) return false;
                            return true;
                        }).map((or,x) => {
                            console.log('or', or);
                            const contact = or.contact ? or.contact : { first_name: '', last_name: '' };
                            return [
                                `${x+1}. ${contact.first_name} ${contact.last_name} - ${or.mail}`,
                                <IconButton
                                    onClick={async () => {
                                        const obj = {
                                            user_id: or.id,
                                            contact_id: contact.id,
                                            first_name: contact.first_name,
                                            last_name: contact.last_name,
                                            email: or.mail
                                        };
                                        const internal_reviewers = data.internal_reviewers ? 
                                            data.internal_reviewers.concat([obj])
                                        :
                                            [obj];
                                        updateDataObj('internal_reviewers', internal_reviewers)
                                        setOpenedAddUser(false)
                                    }}  
                                >
                                    <i className="fa fa-plus"></i>
                                </IconButton>
                            ];
                        })}
                        coloredColls={[1]}
                        colorsColls={["primary"]}
                    />
                </div>
            </div>
        </Dialog>}

        {msg && <Notification 
            message={msg}
            open={msg ? true : false}
            close={() => {
                setMsg('');
                if (updatedRsheetAndClose) {
                    setUpdatedRsheetAndClose(false);
                    props.close();
                }
            }}
        />}

        {askDeleteReview !== null && <NotificationAsk 
            title="Are you sure?"
            message="Do you want to remove this reviewer"
            success={() => {
                updateDataObj('internal_reviewers', data.internal_reviewers.filter((ir,x) => {
                    if (x === askDeleteReview) return false;
                    return true;
                }))
                setAskDeleteReview(null);
            }}
            open={true}
            close={() => {
                setAskDeleteReview(null);
            }}
        />}

        </div>

        {currentUserIsAnApprover && <div className="bottom-dweller">
            {renderInternalReviewActions()}
        </div>}
    </div>
}

const mapStateToProps = state => {
    return {
        error_reviewers: state.rs.error_reviewers,
        success_reviewers: state.rs.success_reviewers,
        internal_reviewers_choices: state.rs.internal_reviewers_choices,
        internal_reviewers_loading: state.rs.internal_reviewers_loading,
        success_crud: state.rs.success_crud,
        history_list: state.rs.history_list,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getRsInternalReviewers: (data) => {
            dispatch(actions.getRsInternalReviewers(data));
        },
        createRsHistory: (data) => {
            dispatch(actions.createRsHistory(data));
        },
        deleteRsInternalReviewer: (data) => {
            dispatch(actions.deleteRsInternalReviewer(data));
        },
        updateRsheet: (params, invoiceData = null, recordHistory) => {
            dispatch(actions.updateRsheet(params, invoiceData, recordHistory));
        },
        getRsHistories: (data) => {
            dispatch(actions.getRsHistories(data));
        },
        getRsheet: (data) => {
            dispatch(actions.getRsheet(data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InternalReview2);