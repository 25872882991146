import React from "react";
import { useEffect, useCallback } from "react";
import { get } from 'lodash';
import { clone } from "../../../shared/utility";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";
import CustomInput from '../../../components/CustomInput/CustomInputValidate';

import { checkOutsideBrokerOrReferralFirmFields, setReferralFirmFeeAmountChoice } from "../RSheetsLogic";
import Brokers from "../CommonCustomElements/Brokers";
import LessEntry from "../CommonCustomElements/LessEntry";
import LessContacts from "../CommonCustomElements/LessContacts";
import ReferralsLessee from "../CommonCustomElements/ReferralsLessee";

const Step4 = (props) => {
    const { setQueryFor, getContacts, setOpenedContactModal, renderElement, data, setData } = props;

    useEffect(() => {
        setReferralFirmFeeAmountChoice(setData, data)("lessee");
    }, []);

    console.log('Step4 dat', data);

    return (
        <div>
            <LessEntry 
                data={data}
                setData={setData}
                rsType="Residential"
                fieldType="lessees"
                fieldName="Lessee"
            />
            {data.lessees.length === 0 && <div className="red-text" style={{margin:20}}>Lessee Required</div>}
            
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <hr />
                    <LessContacts 
                        data={data}
                        setData={setData}
                        fieldType="lessee_contacts"
                        fieldName="Lessee Contact"
                    />
                    <hr />
                </GridItem>

                <Brokers 
                    data={data}
                    setData={setData}
                    outside_brokers_field_name="outside_broker" 
                    outside_brokers_field_label="LESSEE"
                />

                <ReferralsLessee 
                    data={data}
                    setData={setData}
                    setQueryFor={setQueryFor}
                    getContacts={getContacts}
                    setOpenedContactModal={setOpenedContactModal}
                    renderElement={renderElement}
                />
            </GridContainer>
        </div>
    );
};

export default Step4;
