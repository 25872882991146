import React, { useState } from 'react';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInputValidate';
import { clone } from 'shared/utility';
import CustomDialogChooser from 'components/CustomDialog/CustomDialogChooser';
import { isEmpty, get } from 'lodash';
import { getContactAddress } from '../../../shared/utility';
import CustomCRMEditor from "components/CustomDialog/CustomCRMEditor";
import { IconButton } from '@material-ui/core';
import NotificationAsk from '../../../components/Notification/NotificationAsk';
import CustomRadio from "components/CustomRadio/CustomRadio";
import FileUploader from './FileUploader';

const LessEntry = (props) => {
    const [openedCustomDialogChooser, setOpenedCustomDialogChooser] = useState(false);
    const [edit, setEdit] = useState(null);
    const [askForDelete, setAskForDelete] = useState(null);
    const {
        data, 
        setData,
        fieldType = 'lessees', // e.g. 'lessees'
        fieldName = 'Lessee', // e.g. 'Lessee'
        rsType
    } = props;

    const extraFields = fieldType === "lessees" ? {
        "agency_disclosure": "",
        "can_we_reach_out_for_testimonial": "",
        "can_we_reach_out_for_testimonial_comment": "",
        
        "llc_corp_verified": "",
        "upload_sos": "",

        "spousal_consent": "",
        "upload_spousal_id": "",

        "guarantor": "",
        "guarantor_name": "",
        "upload_guarantor_id": "",
        "guarantor_spouse_name": "",
    } : {
        "agency_disclosure": "",
        "can_we_reach_out_for_testimonial": "",
        "can_we_reach_out_for_testimonial_comment": ""
    };

    const update = (index, field, value) => {
        const newData = clone(data);
        if (newData[fieldType])
        newData[fieldType][index][field] = value;
        setData(newData);
    }

    const choose = (chosen, a, b) => {
        console.log('chose less entry', chosen, a, b);
        const signers = get(chosen, 'signers', []);
        const company_data = get(chosen, 'company_data', null);
        // TYPES OF DATA FOR INTEGRATION / SCENARIOS
        // -----------------------------------------
        // 1. 1 Company only,
        // 2. 1 Company with  1 contact,
        // 3. 1 Contact only 

        // -----------------------------
        // 1. 1 Company only
        // -----------------------------
        if (company_data && signers.length === 0) {
            console.log('choice 1');
            if (!isEmpty(company_data)) {
                const c = company_data;
                const obj = {};
                obj.company_id = c.id;
                obj.company_name = c.name;
                obj.name = '';
                obj.last_name = '';
                obj.address1 = c.mailing_address1;
                obj.address2 = c.mailing_address2;
                obj.city = c.mailing_city;
                obj.state = c.mailing_state;
                obj.zip = c.mailing_zip;

                // PHONES
                obj.phone_id = c.id;
                obj.phone_type = 'Main'; // default
                obj.main_phone = c.main_phone;
                obj.work_phone = c.work_phone;
                obj.mobile_phone = c.mobile_phone;
                if (c.overrideContactPhone) {
                    if (c.overrideContactPhone.type) {
                        obj.phone_type = c.overrideContactPhone.type;
                    }
                }
                obj.fax = c.fax;
                obj.email = c.email;

                // company with DBA
                if (chosen.dba_data && chosen.dba_id) {
                    if (chosen.dba_data.length > 0) {
                        chosen.dba_data.forEach(dba => {
                            if (dba.id === chosen.dba_id) {
                                obj.dba_name = dba.name;
                                obj.dba_id = dba.id;
                                obj.dba_active = 1;
                                obj.dba_type = 'company';
                                if (dba.name) obj.company_name = `${obj.company_name} dba ${dba.name}`;
                            }
                        })
                    }
                }

                // CARE OF COMPANY / PROPERTY MANAGEMENT 
                if (chosen.care_of_company) {
                    const coc = chosen.care_of_company;
                    obj.company_id = coc.id;
                    // COMPANY DATA
                    obj.company_name = `${c.name} c/o ${coc.name}`;
                    obj.address1 = coc.mailing_address1;
                    obj.address2 = coc.mailing_address2;
                    obj.city = coc.mailing_city;
                    obj.state = coc.mailing_state;
                    obj.zip = coc.mailing_zip;
                    obj.fax = coc.fax;
                    obj.email = coc.email;
                }

                Object.keys(extraFields).forEach(field => {
                    obj[field] = "";
                })

                const newData = clone(data);
                if (newData[fieldType]) {
                    newData[fieldType].push(obj);
                } else {
                    newData[fieldType] = [obj];
                }
                console.log('new data', newData);
                setData(newData);
                setOpenedCustomDialogChooser(false);
            }
            
        } else if (company_data && signers.length > 0) {
            // -----------------------------
            // 2. 1 Company with  1 contact 
            // -----------------------------
            console.log('chose a company with 1 contact', company_data, signers[0]);
            const s = signers[0];
            const d = s.company_dats ? s.company_dats : {};
            const c = company_data;
            const obj = {};
            obj.company_id = c.id;
            obj.contact_id = s.id;
            // COMPANY DATA
            obj.company_name = c.name;
            obj.address1 = c.mailing_address1;
            obj.address2 = c.mailing_address2;
            obj.city = c.mailing_city;
            obj.state = c.mailing_state;
            obj.zip = c.mailing_zip;
            obj.fax = c.fax;
            obj.phone = ''; // it should be the company contact phone
            obj.email = ''; // it should be the company contact email
            // COMPANY CONTACT's DATA
            obj.name = s.first_name;
            obj.last_name = s.last_name;

            // DBA DATA
            if (chosen.dba_data && chosen.dba_id) {
                if (chosen.dba_data.length > 0) {
                    chosen.dba_data.forEach(dba => {
                        if (dba.id === chosen.dba_id) {
                            obj.dba_name = dba.name;
                            obj.dba_id = dba.id;
                            obj.dba_active = 1;
                            obj.dba_type = 'company';
                            if (dba.name) obj.company_name = `${obj.company_name} dba ${dba.name}`;
                        }
                    })
                }
            }

            // COMPANY CONTACT's DATA
            if (chosen.signers) {
                if (chosen.signers.length > 0) {
                    const s = chosen.signers[0];
                    obj.attention = s.first_name;
                    obj.attention_lastname = s.last_name;
                    const cs = s.company_dats ? s.company_dats : null;
                    if (cs) {
                        obj.phone = cs.phone ? cs.phone : '';
                        obj.email = cs.email ? cs.email : '';
                        obj.company_contact_entry_id = cs.id;
                    }
                }
            }

            const newData = clone(data);
            if (!newData[fieldType]) newData[fieldType] = [];
            newData[fieldType].push(obj);
            setData(newData);
            setOpenedCustomDialogChooser(false);
        } else if (chosen.first_name) {
            console.log('chose a pure contact only');
            // ------------------
            // 3. 1 Contact only 
            // ------------------
            const a = getContactAddress(chosen);
            const obj = {};
            const c = chosen;
            obj.name = c.first_name;
            obj.last_name = c.last_name;
            obj.contact_id = c.id;
            obj.address1 = a.street1;
            obj.address2 = a.street2;
            obj.city = a.city;
            obj.state = a.state;
            obj.zip = a.zip;
            obj.main_phone = c.main_phone;
            obj.mobile_phone = c.mobile_phone;
            obj.work_phone = c.work_phone;
            obj.phone_type = 'Main';
            if (c.overrideContactPhone) {
                if (c.overrideContactPhone.type) {
                    obj.phone_type = c.overrideContactPhone.type;
                }
            }
            obj.fax = c.fax;
            obj.email = c.email ? c.email : '';

            // DBA
            if (b) {
                if (b.name) {
                    obj.last_name = `${obj.last_name} dba ${b.name}`;
                    obj.dba_id = b.id;
                    obj.dba_type = 'contact';
                }
            }

            const newData = clone(data);
            if (!newData[fieldType]) newData[fieldType] = [];
            newData[fieldType].push(obj);
            setData(newData);
            setOpenedCustomDialogChooser(false);
        }

    }

    console.log('Less Entry', props);

    if (!data) return null;


    const less_entries = data[fieldType] ? data[fieldType] : [];

    return <>
    <div>
        <GridContainer>
            {!props.noAddButton && <GridItem xs={12} sm={12} md={12}>
                <Button color="primary" onClick={() => {
                    setOpenedCustomDialogChooser(true);
                }}>
                    <i className="fa fa-plus"></i> ADD {fieldName.toUpperCase()}
                </Button>
            </GridItem>}
            <GridItem xs={12} sm={12} md={12}>
                {less_entries.map((l,i) => {
                    console.log('ll->', l);
                    let l_type = '';
                    let l_id = '';
                    // OLD STYLE BACKWARD COMPATIBILITY
                    if (l.id) {
                        if (typeof l.id === 'number') {
                            l_type = 'contact';
                            l_id = l.id;
                        } else if (typeof l.id === 'string') {
                            if (l.id.indexOf('c') !== -1) {
                                l_type = 'company';
                                l_id = l.id.split('c')[1];
                            }
                        }
                    }
                    // NEW
                    if (l.company_id) {
                        l_type = 'company';
                        l_id = l.company_id;
                    } else if (l.contact_id) {
                        l_type = 'contact';
                        l_id = l.contact_id;
                    }

                    console.log('LessEntry', l_type, fieldType)
                    const show_spouse = l_type === 'company' ? 
                        false 
                    : 
                        fieldType === 'lessors' || fieldType === 'assignors' ? 
                            false 
                        : 
                            true;
                    const show_upload_sos = l_type === 'company' && fieldType === 'lessees' ? true : false;
                    const show_llc_corp = l_type === 'company' && fieldType === 'lessees' ? true : false;

                    return <div key={`less-${i}`} className="boxed">
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <h4><strong>{fieldName} {i+1}</strong></h4>
                            <div style={{float:'right'}}>
                                <IconButton
                                    onClick={() => {
                                        setAskForDelete(i);
                                    }}
                                    className="red-text"
                                >
                                    <i className="fa fa-trash"></i>
                                </IconButton>
                            </div>
                        </GridItem>
                        {l.company_name && <GridItem xs={12} sm={3} md={3}>
                            <div className="gray-bg">
                                <CustomInput 
                                    value={l.company_name}
                                    disabled={true}
                                    label="Company Name"
                                />
                            </div>
                        </GridItem>}
                        {l.name && <GridItem xs={12} sm={3} md={3}>
                            <div className="gray-bg">
                                <CustomInput 
                                    value={l.name}
                                    disabled={true}
                                    label="First Name"
                                />
                            </div>
                        </GridItem>}
                        {l.last_name && <GridItem xs={12} sm={3} md={3}>
                            <div className="gray-bg">
                                <CustomInput 
                                    value={l.last_name}
                                    disabled={true}
                                    label="Last Name"
                                />
                            </div>
                        </GridItem>}
                        <GridItem xs={12} sm={3} md={3}>
                            <CustomInput 
                                value={l.address1}
                                onClick={() => {
                                    if (l_type === 'company') {
                                        setEdit({
                                            value: l.address1,      
                                            type: 'company',       
                                            field: 'mailing_address1',      
                                            fieldType: 'input',  
                                            label: 'Address 1',      
                                            id: l_id,         
                                            chooseVal: (chosenVal) => {
                                                const newData = clone(data);
                                                newData[fieldType][i].address1 = chosenVal;
                                                setData(newData);
                                                setEdit(null); 
                                            }
                                        });
                                    } else if (l_type === 'contact') {
                                        setEdit({
                                            value: l.address1,      
                                            type: 'contact',       
                                            field: 'street1',      
                                            fieldType: 'input',  
                                            label: 'Address 1',      
                                            id: l_id,         
                                            chooseVal: (chosenVal) => {
                                                const newData = clone(data);
                                                newData[fieldType][i].address1 = chosenVal;
                                                setData(newData);
                                                setEdit(null); 
                                            }
                                        });
                                    }
                                }}
                                label="Address 1"
                            />
                            {!l.address1 && <div className="red-text">Required</div>}
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3}>
                            <CustomInput 
                                value={l.address2}
                                onClick={() => {
                                    if (l_type === 'company') {
                                        setEdit({
                                            value: l.address2,      
                                            type: 'company',       
                                            field: 'mailing_address2',      
                                            fieldType: 'input',  
                                            label: 'Address 2',      
                                            id: l_id,         
                                            chooseVal: (chosenVal) => {
                                                const newData = clone(data);
                                                newData[fieldType][i].address2 = chosenVal;
                                                setData(newData);
                                                setEdit(null); 
                                            }
                                        });
                                    } else if (l_type === 'contact') {
                                        setEdit({
                                            value: l.address2,      
                                            type: 'contact',       
                                            field: 'street2',      
                                            fieldType: 'input',  
                                            label: 'Address 2',      
                                            id: l_id,         
                                            chooseVal: (chosenVal) => {
                                                const newData = clone(data);
                                                newData[fieldType][i].address2 = chosenVal;
                                                setData(newData);
                                                setEdit(null); 
                                            }
                                        });
                                    }
                                }}
                                label="Address 2"
                            />
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3}>
                            <CustomInput 
                                value={l.city}
                                onClick={() => {
                                    if (l_type === 'company') {
                                        setEdit({
                                            value: l.city,      
                                            type: 'company',       
                                            field: 'mailing_city',      
                                            fieldType: 'input',  
                                            label: 'City',      
                                            id: l_id,         
                                            chooseVal: (chosenVal) => {
                                                const newData = clone(data);
                                                newData[fieldType][i].city = chosenVal;
                                                setData(newData);
                                                setEdit(null); 
                                            }
                                        });
                                    } else if (l_type === 'contact') {
                                        setEdit({
                                            value: l.city,      
                                            type: 'contact',       
                                            field: 'city',      
                                            fieldType: 'input',  
                                            label: 'City',      
                                            id: l_id,         
                                            chooseVal: (chosenVal) => {
                                                const newData = clone(data);
                                                newData[fieldType][i].city = chosenVal;
                                                setData(newData);
                                                setEdit(null); 
                                            }
                                        });
                                    }
                                }}
                                label="City"
                            />
                            {!l.city && <div className="red-text">Required</div>}
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3}>
                            <CustomInput 
                                value={l.state}
                                onClick={() => {
                                    if (l_type === 'company') {
                                        setEdit({
                                            value: l.state,      
                                            type: 'company',       
                                            field: 'mailing_state',      
                                            fieldType: 'states',  
                                            label: 'State',      
                                            id: l_id,         
                                            chooseVal: (chosenVal) => {
                                                const newData = clone(data);
                                                newData[fieldType][i].state = chosenVal;
                                                setData(newData);
                                                setEdit(null); 
                                            }
                                        });
                                    } else if (l_type === 'contact') {
                                        setEdit({
                                            value: l.state,      
                                            type: 'contact',       
                                            field: 'state',      
                                            fieldType: 'states',  
                                            label: 'State',      
                                            id: l_id,         
                                            chooseVal: (chosenVal) => {
                                                const newData = clone(data);
                                                newData[fieldType][i].state = chosenVal;
                                                setData(newData);
                                                setEdit(null); 
                                            }
                                        });
                                    }
                                }}
                                label="State"
                            />
                            {!l.state && <div className="red-text">Required</div>}
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3}>
                            <CustomInput 
                                value={l.zip}
                                onClick={() => {
                                    if (l_type === 'company') {
                                        setEdit({
                                            value: l.zip,      
                                            type: 'company',       
                                            field: 'mailing_zip',      
                                            fieldType: 'input',  
                                            label: 'Zip',      
                                            id: l_id,         
                                            chooseVal: (chosenVal) => {
                                                const newData = clone(data);
                                                newData[fieldType][i].zip = chosenVal;
                                                setData(newData);
                                                setEdit(null); 
                                            }
                                        });
                                    } else if (l_type === 'contact') {
                                        setEdit({
                                            value: l.zip,      
                                            type: 'contact',       
                                            field: 'zip',      
                                            fieldType: 'input',  
                                            label: 'Zip',      
                                            id: l_id,         
                                            chooseVal: (chosenVal) => {
                                                const newData = clone(data);
                                                newData[fieldType][i].zip = chosenVal;
                                                setData(newData);
                                                setEdit(null); 
                                            }
                                        });
                                    }
                                }}
                                label="Zip"
                            />
                            {!l.zip && <div className="red-text">Required</div>}
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3}>
                            <CustomInput 
                                value={l.email}
                                onClick={() => {
                                    const chooseVal = (chosenVal) => {
                                        const newData = clone(data);
                                        newData[fieldType][i].email = chosenVal;
                                        setData(newData);
                                        setEdit(null); 
                                    }
                                    if (l.company_contact_entry_id) {
                                        setEdit({
                                            value: l.email,      
                                            type: 'company_contact',       
                                            field: 'email',      
                                            fieldType: 'input',  
                                            label: 'Company Contact Email',      
                                            id: l.company_contact_entry_id,         
                                            chooseVal
                                        });
                                    } else {
                                        if (l_type === 'company') {
                                            setEdit({
                                                value: l.email,      
                                                type: 'company',       
                                                field: 'email',      
                                                fieldType: 'input',  
                                                label: 'Company Email',      
                                                id: l_id,         
                                                chooseVal
                                            });
                                        } else if (l_type === 'contact') {
                                            setEdit({
                                                value: l.email,      
                                                type: 'contact',       
                                                field: 'email',      
                                                fieldType: 'input',  
                                                label: 'Contact Email',      
                                                id: l_id,         
                                                chooseVal
                                            });
                                        }
                                    }
                                    
                                }}
                                label={l.company_contact_entry_id ? 'Company Contact Email' : 'Email'}
                            />
                            {!l.email && <div className="red-text">Required</div>}
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3}>
                            {
                                (l.phone && l.company_contact_entry_id) && <CustomInput 
                                    value={l.phone}
                                    onClick={() => {
                                        setEdit({
                                            value: l.phone,      
                                            type: 'company_contact',       
                                            field: 'phone',      
                                            fieldType: 'input',  
                                            label: 'Company Contact Phone',      
                                            id: l.company_contact_entry_id,         
                                            chooseVal: (chosenVal) => {
                                                const newData = clone(data);
                                                newData[fieldType][i].phone = chosenVal;
                                                setData(newData);
                                                setEdit(null);
                                            }
                                        });
                                    }}
                                    label="Company Contact Phone"
                                />
                            }

                            {!l.company_contact_entry_id && <div>
                            {
                                (l.phone_type === 'Main'
                                || !l.phone_type) &&

                                <CustomInput 
                                    value={l.main_phone}
                                    onClick={() => {
                                        const chooseVal = (chosenVal) => {
                                            const newData = clone(data);
                                            newData[fieldType][i].main_phone = chosenVal;
                                            setData(newData);
                                            setEdit(null); 
                                        }
                                        const obj = {
                                            value: l.main_phone,      
                                            field: 'main_phone',      
                                            fieldType: 'input',  
                                            label: 'Main Phone',      
                                            id: l_id,         
                                            chooseVal
                                        }
                                        if (l_type === 'company') {
                                            obj.type = 'company';
                                            setEdit(obj);
                                        } else if (l_type === 'contact') {
                                            obj.type = 'contact';
                                            setEdit(obj);
                                        }
                                    }}
                                    label="Main Phone"
                                />
                            }
                            {
                                l.phone_type === 'Work' &&

                                <CustomInput 
                                    value={l.work_phone}
                                    onClick={() => {
                                        const chooseVal = (chosenVal) => {
                                            const newData = clone(data);
                                            newData[fieldType][i].work_phone = chosenVal;
                                            setData(newData);
                                            setEdit(null); 
                                        }
                                        const obj = {
                                            value: l.work_phone,      
                                            field: 'work_phone',      
                                            fieldType: 'input',  
                                            label: 'Work Phone',      
                                            id: l_id,         
                                            chooseVal
                                        }
                                        if (l_type === 'company') {
                                            obj.type = 'company';
                                            setEdit(obj);
                                        } else if (l_type === 'contact') {
                                            obj.type = 'contact';
                                            setEdit(obj);
                                        }
                                    }}
                                    label="Work Phone"
                                />
                            }
                            {
                                l.phone_type === 'Mobile' &&

                                <CustomInput 
                                    value={l.mobile_phone}
                                    onClick={() => {
                                        const chooseVal = (chosenVal) => {
                                            const newData = clone(data);
                                            newData[fieldType][i].mobile_phone = chosenVal;
                                            setData(newData);
                                            setEdit(null); 
                                        }
                                        const obj = {
                                            value: l.mobile_phone,      
                                            field: 'mobile_phone',      
                                            fieldType: 'input',  
                                            label: 'Mobile Phone',      
                                            id: l_id,         
                                            chooseVal
                                        }
                                        if (l_type === 'company') {
                                            obj.type = 'company';
                                            setEdit(obj);
                                        } else if (l_type === 'contact') {
                                            obj.type = 'contact';
                                            setEdit(obj);
                                        }
                                    }}
                                    label="Mobile Phone"
                                />
                            }
                            </div>}
                            
                        </GridItem>

                        <GridItem xs={12} sm={3} md={3}>
                                <CustomInput 
                                    value={l.fax}
                                    onClick={() => {
                                        const chooseVal = (chosenVal) => {
                                            const newData = clone(data);
                                            newData[fieldType][i].fax = chosenVal;
                                            setData(newData);
                                            setEdit(null); 
                                        }
                                        const obj = {
                                            value: l.fax,      
                                            field: 'fax',      
                                            fieldType: 'input',  
                                            label: 'Fax',      
                                            id: l_id,         
                                            chooseVal
                                        }
                                        if (l_type === 'company') {
                                            obj.type = 'company';
                                            setEdit(obj);
                                        } else if (l_type === 'contact') {
                                            obj.type = 'contact';
                                            setEdit(obj);
                                        }
                                    }}
                                    label="Fax"
                                />
                        </GridItem>

                        {/* AGENCY DISCLOSURE  */}
                        <GridItem xs={12} sm={3} md={3}>
                            <CustomRadio
                                label="Agency Disclosure,Yes,No"
                                options={['Yes','No']}
                                onChange={(e) => {
                                    update(i, 'agency_disclosure', e.target.value);
                                }}
                                value={l.agency_disclosure}
                            />
                            {!l.agency_disclosure ? <div className="red-text">Required</div> : null}
                        </GridItem>

                        {/* LLC / CORP VERIFIED  */}
                        {show_llc_corp && <GridItem xs={12} sm={3} md={3}>
                            <CustomRadio
                                label="LLC / Corp Verified,Yes,No"
                                options={['Yes','No']}
                                onChange={(e) => {
                                    update(i, 'llc_corp_verified', e.target.value);
                                }}
                                value={l.llc_corp_verified}
                            />
                        </GridItem>}

                        {/* UPLOAD SOS  */}
                        {show_upload_sos && <GridItem xs={12} sm={3} md={3}>
                            <div className="boxed" style={{marginTop:20}}>
                                <h4>S.O.S.</h4>
                                <FileUploader 
                                    update={(val) => {
                                        update(i, 'upload_sos', val);
                                    }}
                                    remove={() => {
                                        update(i, 'upload_sos', '');
                                    }}
                                    rs_filename={l['upload_sos']}
                                />
                        </div>
                        </GridItem>}

                        {/* SPOUSE */}
                        {show_spouse && <GridItem xs={12} sm={3} md={3}>
                            <div className="boxed" style={{marginTop:20}}>
                                <CustomRadio
                                    label="Spousal Consent,Yes,N/A"
                                    options={['Yes','N/A']}
                                    onChange={(e) => {
                                        update(i, 'spousal_consent', e.target.value);
                                    }}
                                    value={l.spousal_consent}
                                />
                                {l.spousal_consent === 'Yes' &&
                                    <div className="boxed">
                                        <h4>Spousal ID</h4>
                                        <FileUploader 
                                            update={(val) => {
                                                update(i, 'upload_spousal_id', val);
                                            }}
                                            remove={() => {
                                                update(i, 'upload_spousal_id', '');
                                            }}
                                            rs_filename={l['upload_spousal_id']}
                                        />
                                    </div>
                                }
                                {!l.spousal_consent && <div className="red-text">Required</div>}
                            </div>
                        </GridItem>}

                        {/* GUARANTOR */}
                        {fieldType === "lessees" && <GridItem xs={12} sm={3} md={3}>
                            <div className="boxed" style={{marginTop:20}}>
                                <CustomRadio
                                    label="Guarantor,Yes,N/A"
                                    options={['Yes','N/A']}
                                    onChange={(e) => {
                                        update(i, 'guarantor', e.target.value);
                                    }}
                                    value={l.guarantor}
                                />
                                {!l.guarantor ? <div className="red-text">Required</div> : null}

                                {l.guarantor === 'Yes' && <div style={{marginBottom:20}}>
                                    <CustomInput 
                                        value={l.guarantor_name}
                                        onChange={(e) => {
                                            update(i, 'guarantor_name', e.target.value);
                                        }}
                                        label="Guarantor Name"
                                        required={true}
                                    />
                                    {!l.guarantor_name ? <div className="red-text">Required</div> : null}
                                </div>}

                                {l.guarantor === 'Yes' &&
                                    <div className="boxed">
                                        <h4>Guarantor ID</h4>
                                        <FileUploader 
                                            update={(val) => {
                                                update(i, 'upload_guarantor_id', val);
                                            }}
                                            remove={() => {
                                                update(i, 'upload_guarantor_id', '');
                                            }}
                                            rs_filename={l['upload_guarantor_id']}
                                        />
                                    </div>
                                }

                                {l.guarantor === 'Yes' && <CustomInput 
                                    value={l.guarantor_spouse_name}
                                    onChange={(e) => {
                                        update(i, 'guarantor_spouse_name', e.target.value);
                                    }}
                                    label="Guarantor Spouse Name"
                                />}
                            </div>
                        </GridItem>}

                        {/* TESTIMONIAL */}
                        <GridItem xs={12} sm={3} md={3}>
                            <div className="boxed" style={{marginTop:20}}>
                                <CustomRadio
                                    label="Can we reach out for testimonial?,Yes,No"
                                    options={['Yes','No']}
                                    onChange={(e) => {
                                        update(i, 'can_we_reach_out_for_testimonial', e.target.value);
                                    }}
                                    value={l.can_we_reach_out_for_testimonial}
                                />
                                {!l.can_we_reach_out_for_testimonial ? <div className="red-text">Required</div> : null}
                                {l.can_we_reach_out_for_testimonial === 'Yes' && <CustomInput 
                                    value={l.can_we_reach_out_for_testimonial_comment}
                                    onChange={(e) => {
                                        update(i, 'can_we_reach_out_for_testimonial_comment', e.target.value);
                                    }}
                                    label="Comment"
                                />}
                            </div>
                        </GridItem>

                    </GridContainer>
                </div>})}
            </GridItem>
        </GridContainer>
        

        {openedCustomDialogChooser && <CustomDialogChooser 
                open={openedCustomDialogChooser}
                close={() => {
                    setOpenedCustomDialogChooser(false);
                }}
                choose={choose}
                showContactCompaniesTab={true}
                create_and_choose={choose}
                title={`Select ${fieldName} Contact or Company`}
                label={`Search ${fieldName} Contact/Company`}
                no_property={true}
                allowNoContacts={true}
                hide_property_manager={true}
                // hide_choose_a_company_for_contact={true}
                reform_company_contact={true}
        />}

        {edit !== null && <CustomCRMEditor 
            {...edit}
            close={() => {
                setEdit(null)
            }}
        />}

        {askForDelete !== null && <NotificationAsk 
            title="Are you sure?"
            message={`Do you want to remove this ${fieldName}?`}
            success={() => {
                const newData = clone(data);
                newData[fieldType] = newData[fieldType].filter((l,idx) => {
                    if (idx === askForDelete) return false;
                    return true;
                })
                setData(newData);
                setAskForDelete(null);
            }}
            open={true}
            close={() => {
                setAskForDelete(null);
            }}
        />}
    </div>
    </>
}

export default LessEntry;