import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "store/actions";
import { get, isEmpty } from "lodash";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInputValidate from "components/CustomInput/CustomInputValidate.jsx";
import CustomInputStates from "components/CustomInput/CustomInputStates.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDialogChooser from "components/CustomDialog/CustomDialogChooser";
import CustomDialogCompanyView from "components/CustomDialog/CustomDialogCompanyView";

import CompaniesEditContact from "./CompaniesEditContact";

import AddressContactItem from "../shared/AddressContactItem";
import AddressCompanyItem from "../shared/AddressCompanyItem";

import { exists, clone } from "shared/utility";

import axios from 'store/axios/axios-crm';

import {
    email,
    min1,
    min5,
    min6,
    min10,
    max15,
    max50,
    max100,
    no_letters,
    no_numbers,
    no_special_chars,
} from "shared/validation";
import { handleError } from "../../../shared/utility";

const validate = {
    email,
    min1,
    min5,
    min6,
    min10,
    max15,
    max50,
    max100,
    no_letters,
    no_numbers,
    no_special_chars,
};

export const COMPANY_ENTITY_TYPE = {
    ACCOUNTANT: "A",
    LEGAL_INFO: "L",
    PROPERTY_MANAGEMENT: "P",
};

const AddressFields = (props) => {
    const { addressData, addType, addressIndex } = props;
    const { types } = props.addressFields;

    const getValidations = (f) => {
        return [];
    };

    const hasField = (fieldName) => {
        if (types[addType] && types[addType].fields.indexOf(fieldName) !== -1) return true;
        return false;
    };

    return (
        <GridContainer>
            {hasField("company_name") && (
                <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate
                        label="Company Name"
                        value={
                            !exists(addressIndex)
                                ? props.dataObj[`${addType}_company_name`]
                                : addressData[addType][addressIndex].company_name
                        }
                        onChange={(e) => {
                            if (!exists(addressIndex)) {
                                props.updateDataObj(`${addType}_company_name`, e.target.value);
                            }
                        }}
                        validate={getValidations("company_name")}
                    />
                </GridItem>
            )}
            {hasField("name") && (
                <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate
                        label="Name"
                        value={
                            !exists(addressIndex)
                                ? props.dataObj[`${addType}_name`]
                                : addressData[addType][addressIndex].name
                        }
                        onChange={(e) => {
                            if (!exists(addressIndex)) {
                                props.updateDataObj(`${addType}_name`, e.target.value);
                            }
                        }}
                        validate={getValidations("name")}
                    />
                </GridItem>
            )}
            <GridItem xs={12} sm={12} md={12}></GridItem>
            {hasField("address1") && (
                <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate
                        label="Address 1"
                        value={
                            !exists(addressIndex)
                                ? props.dataObj[`${addType}_address1`]
                                : addressData[addType][addressIndex].street1
                        }
                        onChange={(e) => {
                            if (!exists(addressIndex)) {
                                props.updateDataObj(`${addType}_address1`, e.target.value);
                            }
                        }}
                        validate={getValidations("address1")}
                    />
                </GridItem>
            )}
            {hasField("city") && (
                <GridItem xs={12} sm={2} md={2}>
                    <CustomInputValidate
                        label="City"
                        value={
                            !exists(addressIndex)
                                ? props.dataObj[`${addType}_city`]
                                : addressData[addType][addressIndex].city
                        }
                        onChange={(e) => {
                            if (!exists(addressIndex)) {
                                props.updateDataObj(`${addType}_city`, e.target.value);
                            }
                        }}
                        validate={getValidations("city")}
                    />
                </GridItem>
            )}
            {hasField("state") && (
                <GridItem xs={12} sm={2} md={2}>
                    <CustomInputStates
                        onChange={(value) => {
                            if (!exists(addressIndex)) {
                                props.updateDataObj(`${addType}_state`, value);
                            }
                        }}
                        value={
                            !exists(addressIndex)
                                ? props.dataObj[`${addType}_state`]
                                : addressData[addType][addressIndex].state
                        }
                    />
                </GridItem>
            )}
            {hasField("zip") && (
                <GridItem xs={12} sm={2} md={2}>
                    <CustomInputValidate
                        label="Zip"
                        value={
                            !exists(addressIndex)
                                ? props.dataObj[`${addType}_zip`]
                                : addressData[addType][addressIndex].zip
                        }
                        onChange={(e) => {
                            if (!exists(addressIndex)) {
                                props.updateDataObj(`${addType}_zip`, e.target.value);
                            }
                        }}
                        validate={getValidations("zip")}
                    />
                </GridItem>
            )}
            {hasField("address2") && (
                <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate
                        label="Address 2"
                        value={
                            !exists(addressIndex)
                                ? props.dataObj[`${addType}_address2`]
                                : addressData[addType][addressIndex].street2
                        }
                        onChange={(e) => {
                            if (!exists(addressIndex)) {
                                props.updateDataObj(`${addType}_address2`, e.target.value);
                            }
                        }}
                        validate={getValidations("street2")}
                    />
                </GridItem>
            )}
            <GridItem xs={12} sm={12} md={12}></GridItem>
            {hasField("phone") && (
                <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate
                        label="Phone Number"
                        value={
                            !exists(addressIndex)
                                ? props.dataObj[`${addType}_phone`]
                                : addressData[addType][addressIndex].phone
                        }
                        onChange={(e) => {
                            if (!exists(addressIndex)) {
                                props.updateDataObj(`${addType}_phone`, e.target.value);
                            }
                        }}
                        validate={getValidations("phone")}
                    />
                </GridItem>
            )}
            {hasField("other_number") && (
                <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate
                        label="Other Number"
                        value={
                            !exists(addressIndex)
                                ? props.dataObj[`${addType}_other_number`]
                                : addressData[addType][addressIndex].other_number
                        }
                        onChange={(e) => {
                            if (!exists(addressIndex)) {
                                props.updateDataObj(`${addType}_other_number`, e.target.value);
                            }
                        }}
                        validate={getValidations("other_number")}
                    />
                </GridItem>
            )}
            {hasField("fax") && (
                <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate
                        label="Fax"
                        value={
                            !exists(addressIndex)
                                ? props.dataObj[`${addType}_fax`]
                                : addressData[addType][addressIndex].fax
                        }
                        onChange={(e) => {
                            if (!exists(addressIndex)) {
                                props.updateDataObj(`${addType}_fax`, e.target.value);
                            }
                        }}
                        validate={getValidations("fax")}
                    />
                </GridItem>
            )}
            {hasField("email") && (
                <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate
                        label="Email"
                        value={
                            !exists(addressIndex)
                                ? props.dataObj[`${addType}_email`]
                                : addressData[addType][addressIndex].email
                        }
                        onChange={(e) => {
                            if (!exists(addressIndex)) {
                                props.updateDataObj(`${addType}_email`, e.target.value);
                            }
                        }}
                        validate={getValidations("email")}
                    />
                </GridItem>
            )}
            <GridItem xs={12} sm={12} md={12}>
                <hr />
            </GridItem>
        </GridContainer>
    );
};

const CompaniesFormAddresses = (props) => {
    const {
        addressData,
        updateAddress,
        addressFields,

        company_entities,
        createCompanyEntity,
        deleteCompanyEntity,
        deleteContactEntity,
        getCompanyEntities,
        originalCompanyData,
        entitiesData,
        setEntitiesData,

        getUpdatedCompanyEntity,
        updatedCompanyEntity,

        getUpdatedContactEntity,
        updatedContactEntity,
    } = props;

    const [openedContactModal, setOpenedContactModal] = useState(false);
    const [entityType, setEntityType] = useState(null);

    const [openedEditCompany, setOpenedEditCompany] = useState(false);
    const [companyID, setCompanyID] = useState(null);

    const [openedEditContact, setOpenedEditContact] = useState(false);
    const [contactID, setContactID] = useState(null);

    useEffect(() => {
        if (originalCompanyData && originalCompanyData.id) {
            getCompanyEntities(originalCompanyData.id);
        }
    }, [originalCompanyData]);

    useEffect(() => {
        if (!isEmpty(updatedCompanyEntity) && props.action === "Add") {
            const newEntities = clone(entitiesData);
            const indexToEdit = newEntities.findIndex((e) => get(e, "company_data.id") === updatedCompanyEntity.id);
            if (indexToEdit !== -1) {
                newEntities[indexToEdit].company_data = updatedCompanyEntity;
                setEntitiesData(newEntities);
            }
        }
    }, [updatedCompanyEntity]);

    useEffect(() => {
        if (!isEmpty(updatedContactEntity) && props.action === "Add") {
            const newEntities = clone(entitiesData);
            const indexToEdit = newEntities.findIndex((e) => get(e, "contact_data.id") === updatedContactEntity.id);
            if (indexToEdit !== -1) {
                newEntities[indexToEdit].contact_data = updatedContactEntity;
                setEntitiesData(newEntities);
            }
        }
    }, [updatedContactEntity]);

    const getEntities = async () => {

    }

    const choose = async (chosen, obj1, contact_dba) => {
        console.log('choosing', chosen, obj1, contact_dba);
        const entityData = {
            entity_type: entityType,
        };

        if (originalCompanyData && originalCompanyData.id) {
            entityData.company_id = originalCompanyData.id;
        }

        if (!chosen.company_data) {
            // Contact chosen
            entityData.type = 1;
            entityData.entity_id = chosen.id;
            if (props.action === "Add") {
                entityData.contact_data = chosen;
            }
            const dba_id = get(contact_dba, 'id', null);
            if (dba_id) {
                entityData.dba_id = dba_id;
            }
            const dba_data = get(chosen, 'dbas', null);
            if (dba_data) {
                entityData.dba_data = dba_data;
            }

            // Contact with company
            if (chosen.company_id) {
                entityData.entity_company_id = chosen.company_id;
                // if user selected a company dba, make sure the dba exist
                if (chosen.company_dba_id && chosen.company_dbas.find(i => i.id === chosen.company_dba_id)) {
                    entityData.dba_id = chosen.company_dba_id;
                }
                // reassign contact dba to secondary_dba_id
                if (contact_dba?.id) {
                    entityData.secondary_dba_id = contact_dba.id;
                }
            }
        } else {
            if (chosen && isEmpty(chosen.signers)) {
                // Company with no Contact chosen
                entityData.type = 2;
                entityData.entity_id = get(chosen, "company_data.id");
                if (chosen.dba_id) entityData.dba_id = chosen.dba_id;
                if (chosen.dba_data) entityData.dba_data = chosen.dba_data;
                if (props.action === "Add") {
                    entityData.company_data = get(chosen, "company_data", {});
                }
            } else {
                // Company with Contact chosen
                entityData.type = 1;
                entityData.entity_id = get(chosen, "signers[0].id");
                entityData.entity_company_id = get(chosen, "company_data.id");
                if (chosen.dba_id) entityData.dba_id = chosen.dba_id;
                if (chosen.dba_data) entityData.dba_data = chosen.dba_data;
                if (props.action === "Add") {
                    entityData.contact_data = get(chosen, "signers[0]", {});
                    entityData.company_data = get(chosen, "company_data", {});
                }
            }
        }
        
        if (props.action === "Edit") {
            // createCompanyEntity(entityData);
            try {
                if (entityData.dba_data) delete entityData.dba_data;
                await axios.post('/company_entity', entityData);
                getCompanyEntities(entityData.company_id);
            } catch(e) {
                handleError(e);
            }
        } else if (props.action === "Add") {
            const newEntities = clone(entitiesData);
            newEntities.push(entityData);
            setEntitiesData(newEntities);
        }

        setOpenedContactModal(false);
        setEntityType(null);
    };

    let accountantInfo = null;
    let legalInfo = null;
    let propManagementInfo = null;

    if (props.action === "Edit" || props.action === "View") {
        accountantInfo = company_entities.find((entity) => entity.entity_type === COMPANY_ENTITY_TYPE.ACCOUNTANT);
        legalInfo = company_entities.find((entity) => entity.entity_type === COMPANY_ENTITY_TYPE.LEGAL_INFO);
        propManagementInfo = company_entities.find(
            (entity) => entity.entity_type === COMPANY_ENTITY_TYPE.PROPERTY_MANAGEMENT
        );
    } else if (props.action === "Add") {
        accountantInfo = entitiesData.find((entity) => entity.entity_type === COMPANY_ENTITY_TYPE.ACCOUNTANT);
        legalInfo = entitiesData.find((entity) => entity.entity_type === COMPANY_ENTITY_TYPE.LEGAL_INFO);
        propManagementInfo = entitiesData.find(
            (entity) => entity.entity_type === COMPANY_ENTITY_TYPE.PROPERTY_MANAGEMENT
        );
    }

    return (
        <div className={props.action === "View" ? "no-click lightgray-bg enclosure" : "enclosure"}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <h4 className="bold">Mailing Address</h4>
                    <AddressFields
                        addressFields={addressFields}
                        updateAddress={updateAddress}
                        addressData={addressData}
                        addType="mailing"
                        dataObj={props.dataObj}
                        updateDataObj={props.updateDataObj}
                    />
                    <hr />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <h4 className="bold">Accountant Info</h4>
                    {!accountantInfo && (
                        <Button
                            color="primary"
                            onClick={() => {
                                setOpenedContactModal(true);
                                setEntityType(COMPANY_ENTITY_TYPE.ACCOUNTANT);
                            }}
                            disabled={props.action === "View"}
                        >
                            <i className="fa fa-plus"></i> ADD CONTACT/COMPANY
                        </Button>
                    )}
                    <br />
                    <br />
                    {accountantInfo &&
                        (accountantInfo.type === 1 ? (
                            <AddressContactItem
                                entity={accountantInfo}
                                canEdit={props.action !== "View"}
                                deleteCompanyEntity={deleteCompanyEntity}
                                deleteContactEntity={deleteContactEntity}
                                setOpenedEditContact={setOpenedEditContact}
                                setContactID={setContactID}
                                setOpenedEditCompany={setOpenedEditCompany}
                                setCompanyID={setCompanyID}
                                action={props.action}
                                entitiesData={entitiesData}
                                setEntitiesData={setEntitiesData}
                                boxed
								                fromType="COMPANY"
                            />
                        ) : (
                            <AddressCompanyItem
                                entity={accountantInfo}
                                canEdit={props.action !== "View"}
                                deleteCompanyEntity={deleteCompanyEntity}
                                deleteContactEntity={deleteContactEntity}
                                setOpenedEditCompany={setOpenedEditCompany}
                                setCompanyID={setCompanyID}
                                action={props.action}
                                entitiesData={entitiesData}
                                setEntitiesData={setEntitiesData}
                                boxed
								                fromType="COMPANY"
                            />
                        ))}
                    <hr />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <h4 className="bold">Legal Info</h4>
                    {!legalInfo && (
                        <Button
                            color="primary"
                            onClick={() => {
                                setOpenedContactModal(true);
                                setEntityType(COMPANY_ENTITY_TYPE.LEGAL_INFO);
                            }}
                            disabled={props.action === "View"}
                        >
                            <i className="fa fa-plus"></i> ADD CONTACT/COMPANY
                        </Button>
                    )}
                    <br />
                    <br />
                    {legalInfo &&
                        (legalInfo.type === 1 ? (
                            <AddressContactItem
                                entity={legalInfo}
                                canEdit={props.action !== "View"}
                                deleteCompanyEntity={deleteCompanyEntity}
                                deleteContactEntity={deleteContactEntity}
                                setOpenedEditContact={setOpenedEditContact}
                                setContactID={setContactID}
                                setOpenedEditCompany={setOpenedEditCompany}
                                setCompanyID={setCompanyID}
                                action={props.action}
                                entitiesData={entitiesData}
                                setEntitiesData={setEntitiesData}
                                boxed
								                fromType="COMPANY"
                            />
                        ) : (
                            <AddressCompanyItem
                                entity={legalInfo}
                                canEdit={props.action !== "View"}
                                deleteCompanyEntity={deleteCompanyEntity}
                                deleteContactEntity={deleteContactEntity}
                                setOpenedEditCompany={setOpenedEditCompany}
                                setCompanyID={setCompanyID}
                                action={props.action}
                                entitiesData={entitiesData}
                                setEntitiesData={setEntitiesData}
                                boxed
								                fromType="COMPANY"
                            />
                        ))}
                    <hr />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <h4 className="bold">Property Management Info</h4>
                    {!propManagementInfo && (
                        <Button
                            color="primary"
                            onClick={() => {
                                setOpenedContactModal(true);
                                setEntityType(COMPANY_ENTITY_TYPE.PROPERTY_MANAGEMENT);
                            }}
                            disabled={props.action === "View"}
                        >
                            <i className="fa fa-plus"></i> ADD CONTACT/COMPANY
                        </Button>
                    )}
                    <br />
                    <br />
                    {propManagementInfo &&
                        (propManagementInfo.type === 1 ? (
                            <AddressContactItem
                                entity={propManagementInfo}
                                canEdit={props.action !== "View"}
                                deleteCompanyEntity={deleteCompanyEntity}
                                deleteContactEntity={deleteContactEntity}
                                setOpenedEditContact={setOpenedEditContact}
                                setContactID={setContactID}
                                setOpenedEditCompany={setOpenedEditCompany}
                                setCompanyID={setCompanyID}
                                action={props.action}
                                entitiesData={entitiesData}
                                setEntitiesData={setEntitiesData}
                                boxed
								                fromType="COMPANY"
                            />
                        ) : (
                            <AddressCompanyItem
                                entity={propManagementInfo}
                                canEdit={props.action !== "View"}
                                deleteCompanyEntity={deleteCompanyEntity}
                                deleteContactEntity={deleteContactEntity}
                                setOpenedEditCompany={setOpenedEditCompany}
                                setCompanyID={setCompanyID}
                                action={props.action}
                                entitiesData={entitiesData}
                                setEntitiesData={setEntitiesData}
                                boxed
								                fromType="COMPANY"
                            />
                        ))}
                    <hr />
                </GridItem>
            </GridContainer>

            {openedContactModal && !(props.action === "View") && (
                <CustomDialogChooser
                    open={openedContactModal}
                    close={() => {
                        setOpenedContactModal(false);
                        setEntityType(null);
                    }}
                    choose={choose}
                    create_and_choose={choose}
                    title="Select Contact or Company"
                    label="Search Contacts"
                    allowNoContacts
                    no_property
                    withAddresses
                    singleContactOnly={true}
                    showContactCompaniesTab
                />
            )}

            {openedEditContact && (
                <CompaniesEditContact
                    open={openedEditContact}
                    close={() => {
                        setOpenedEditContact(false);
                        if (originalCompanyData && originalCompanyData.id && props.action === "Edit") {
                            getCompanyEntities(originalCompanyData.id);
                        } else {
                            getUpdatedContactEntity(contactID);
                        }
                    }}
                    contactId={contactID}
                />
            )}

            {openedEditCompany && (
                <CustomDialogCompanyView
                    open={openedEditCompany}
                    close={() => {
                        setOpenedEditCompany(false);
                        if (originalCompanyData && originalCompanyData.id && props.action === "Edit") {
                            getCompanyEntities(originalCompanyData.id);
                        } else {
                            getUpdatedCompanyEntity(companyID);
                        }
                    }}
                    companyID={companyID}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    const { originalCompanyData } = ownProps;
    const company = state.crm_companies;
    const contact = state.crm_contacts;
    return {
        company_entities:
            originalCompanyData && originalCompanyData.id
                ? get(company, `company_entities.${originalCompanyData.id}`, [])
                : [],
        updatedCompanyEntity: company.updated_company_entity,
        updatedContactEntity: contact.updated_contact_entity,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createCompanyEntity: (data) => dispatch(actions.createCompanyEntity(data)),
        deleteCompanyEntity: (id) => dispatch(actions.deleteCompanyEntity(id)),
        deleteContactEntity: (id) => dispatch(actions.deleteContactEntity(id)),
        getCompanyEntities: (contactId) => dispatch(actions.getCompanyEntities(contactId)),
        getUpdatedCompanyEntity: (companyId) => dispatch(actions.getUpdatedCompanyEntity(companyId)),
        getUpdatedContactEntity: (contactId) => dispatch(actions.getUpdatedContactEntity(contactId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesFormAddresses);
