import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInputValidate from "components/CustomInput/CustomInputValidate.jsx";
import CustomInput2 from "components/CustomInput/CustomInputValidate2.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDialog from "components/CustomDialog/CustomDialog";
import { contactSubTypeDecider, returnSubtype } from "./ContactsLogic";
import ContactW9Upload from "./ContactW9Upload";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
	min1,
	min6,
	min4,
    min9,
	min10,
	email,
	required,
	no_letters,
	no_special_chars,
	no_numbers,
	max4,
	max10,
	max15,
	max20,
	max50,
	max100,
    max125,
	max250,
	max500,
    valid_url
} from "shared/validation";
import contactFields from "./ContactsObj";
import Datetime from "react-datetime";
import Person from "@material-ui/icons/Person";
import FileUploader from "components/FileUploader/FileUploader";
import { getUser } from "../../../shared/authValidation";

const validate = {
	min1,
	min6,
	min4,
    min9,
	min10,
	email,
	required,
	no_letters,
	no_special_chars,
	no_numbers,
	max4,
	max10,
	max15,
	max20,
	max50,
	max100,
    max125,
	max250,
	max500,
};

const getValidations = (f) => {
    return contactFields[f].validations.map((v) => validate[v]);
};

const ContactsFormGeneral = (props) => {
    const [openedModal, setOpenedModal] = useState(false);

	const contactTypes = props.dataObj.contact_type
		? props.dataObj.contact_type.split(",")
		: [];
	const contactSubtypes = props.dataObj.contact_subtype
		? props.dataObj.contact_subtype.split(",")
		: [];

	const vendorOptions = props.contactOptions
		? props.contactOptions.vendorOptions
		: [];

    const number_edit_allowed = props.crmAllowed ?
        props.crmAllowed.hasOwnProperty('number_edit') ?
            true
            :
            false
        :
        false;

    const renderInput = (field, showLink) => {
        const val = props.dataObj[field];
        let validURL = false;
        if (showLink) {
            validURL = valid_url(val);
        }
        return <div>
            <CustomInputValidate
                label={contactFields[field].label}
                value={props.dataObj[field]}
                onChange={(e) => {
                    props.updateDataObj(field, e.target.value);
                }}
                validate={getValidations(field)}
                readonly={props.action === "View" ? true : false}
            />
            <div>
                {(val && (validURL && showLink)) && <a href={val} 
                    style={{textDecoration:'underline',fontSize:9,opacity: (showLink && val) ? 1 : 0}} 
                    target="_blank"
                >Open Link</a>}
                {(val && (!validURL && showLink)) && <span 
                    className="red-text"
                    style={{fontSize:9}}
                >Invalid URL</span>}
            </div>
        </div>
    }

    const current_user = getUser();
    console.log('current_user', current_user);
    const can_edit_illi_associates_checkbox = (current_user.mail === "dev@sap-developers.com" ||
        current_user.mail === "support@illicre.com") ? true : false;

    return (
        <div className={props.action === 'View' ? 'no-click lightgray-bg enclosure' : 'enclosure'}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <h4 className="bold">Main Info</h4>
                </GridItem>
                <GridItem xs={12} sm={4} md={3}>

                </GridItem>
                <GridItem xs={12} sm={4} md={3}>

                </GridItem>
                <GridItem xs={12} sm={4} md={3}>

                </GridItem>
                <GridItem xs={12} sm={4} md={3}>

                </GridItem>
                <GridItem xs={12} sm={4} md={3}>
                    <CustomInputValidate
                        label="First Name"
                        value={props.dataObj.first_name}
                        onChange={(e) => { props.updateDataObj('first_name', e.target.value) }}
                        validate={getValidations('first_name')}
                        readonly={props.action === 'View' ? true : false}
                        autoFocus={true}
                    />
                    <CustomInputValidate
                        label="Middle Initial"
                        value={props.dataObj.middle_initial}
                        onChange={(e) => { props.updateDataObj('middle_initial', e.target.value) }}
                        validate={getValidations('middle_initial')}
                        readonly={props.action === 'View' ? true : false}
                    />
                    <CustomInputValidate
                        label="Last Name"
                        value={props.dataObj.last_name}
                        onChange={(e) => { props.updateDataObj('last_name', e.target.value) }}
                        validate={getValidations('last_name')}
                        readonly={props.action === 'View' ? true : false}
                    />
                </GridItem>
                <GridItem xs={12} sm={4} md={3}>
                    <CustomInputValidate
                        label="Email"
                        value={props.dataObj.email}
                        onChange={(e) => { props.updateDataObj('email', e.target.value) }}
                        validate={getValidations('email')}
                        readonly={props.action === 'View' ? true : false}
                    />
                    <CustomInputValidate
                        label="Salutation"
                        value={props.dataObj.salutation}
                        onChange={(e) => { props.updateDataObj('salutation', e.target.value) }}
                        validate={getValidations('salutation')}
                        readonly={props.action === 'View' ? true : false}
                    />
                    <CustomInputValidate
                        label="Suffix"
                        value={props.dataObj.suffix}
                        onChange={(e) => { props.updateDataObj('suffix', e.target.value) }}
                        validate={getValidations('suffix')}
                        readonly={props.action === 'View' ? true : false}
                    />
                    <div className="custom relative">
                        {props.dataObj.birthdate && <div className="tiny-label">Birth Date</div>}
                        <Datetime
                            inputProps={{
                                placeholder: "Birth Date",
                            }}
                            closeOnSelect={true}
                            onChange={(e) => {
                                if (props.action === 'View') return;
                                if (typeof e === 'object') {
                                    const stringDate = e.format('MM/DD/YYYY');
                                    return props.updateDataObj('birthdate', stringDate);;
                                }
                                props.updateDataObj('birthdate', e)
                            }}
                            timeFormat={false}
                            value={props.dataObj.birthdate}
                        />
                    </div>
                </GridItem>
                <GridItem xs={12} sm={4} md={3}>
                    <CustomInputValidate
                        label="Spouse"
                        value={props.dataObj.spouse_first_name}
                        onChange={(e) => { props.updateDataObj('spouse_first_name', e.target.value) }}
                        validate={getValidations('spouse_first_name')}
                        readonly={props.action === 'View' ? true : false}
                    />
                    <CustomInputValidate
                        label="SSN"
                        value={props.dataObj.ssn}
                        onChange={(e) => { props.updateDataObj('ssn', e.target.value) }}
                        validate={getValidations('ssn')}
                        readonly={props.action === 'View' ? true : false}
                    />
                    {/* <CustomInputValidate
                        label="Title"
                        value={props.dataObj.title}
                        onChange={(e) => { props.updateDataObj('title', e.target.value) }}
                        validate={getValidations('title')}
                        readonly={props.action === 'View' ? true : false}
                    /> */}
                    {/* <CustomInputValidate
                        label="Website URL"
                        value={props.dataObj.website_url}
                        onChange={(e) => { props.updateDataObj('website_url', e.target.value) }}
                        validate={getValidations('website_url')}
                        readonly={props.action === 'View' ? true : false}
                    /> */}
                </GridItem>
                <GridItem xs={12} sm={4} md={3}>
                    {/* <CustomInputValidate
                        label="Department"
                        value={props.dataObj.department}
                        onChange={(e) => { props.updateDataObj('department', e.target.value) }}
                        validate={getValidations('department')}
                        readonly={props.action === 'View' ? true : false}
                    /> */}
                    {number_edit_allowed && <CustomInputValidate
                        label="Contact #"
                        value={props.dataObj.illi_number}
                        onChange={(e) => { props.updateDataObj('illi_number', e.target.value) }}
                        validate={getValidations('illi_number')}
                        readonly={props.action === 'View' ? true : false}
                    />}
                </GridItem>

                <GridItem xs={12} sm={3} md={3}>
                    <CustomInputValidate
                        label="Use Type"
                        value={props.dataObj.use_type}
                        onChange={(e) => { }}
                        validate={getValidations('department')}
                        readonly={props.action === 'View' ? true : false}
                        onClick={() => {
                            setOpenedModal(true);
                        }}
                    />
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    {/* // REMOVE REQUEST 3/2/22 */}
                    {/* <CustomSelect
                        label="Category"
                        options={contactFields.category.options}
                        choose={(e, n) => {
                            if (props.action === 'View') return;
                            props.updateDataObj('category', e);
                        }}
                        default={props.dataObj.category ? props.dataObj.category : ''}
                    /> */}
				</GridItem>
				<GridItem xs={12} sm={6} md={6}></GridItem>
			</GridContainer>

			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<hr style={{marginTop:40}} />
					<h4 className="bold">Phone Numbers</h4>
				</GridItem>
				<GridItem xs={12} sm={4} md={3}>
					<CustomInput2
						label="Main Phone"
						value={props.dataObj.main_phone}
						onChange={(e) => {
							props.updateDataObj("main_phone", e.target.value);
						}}
						validate={getValidations("main_phone")}
						readonly={props.action === "View" ? true : false}
                        is_phone={true}
					/>
				</GridItem>
				<GridItem xs={12} sm={4} md={3}>
					<CustomInputValidate
						label="Home Phone"
						value={props.dataObj.home_phone}
						onChange={(e) => {
							props.updateDataObj("home_phone", e.target.value);
						}}
						validate={getValidations("home_phone")}
						readonly={props.action === "View" ? true : false}
					/>
				</GridItem>
				<GridItem xs={12} sm={4} md={3}>
					<CustomInputValidate
						label="Mobile Phone"
						value={props.dataObj.mobile_phone}
						onChange={(e) => {
							props.updateDataObj("mobile_phone", e.target.value);
						}}
						validate={getValidations("mobile_phone")}
						readonly={props.action === "View" ? true : false}
					/>
				</GridItem>
				<GridItem xs={12} sm={4} md={3}>
					<CustomInputValidate
						label="Direct Phone"
						value={props.dataObj.other_phone}
						onChange={(e) => {
							props.updateDataObj("other_phone", e.target.value);
						}}
						validate={getValidations("other_phone")}
						readonly={props.action === "View" ? true : false}
					/>
				</GridItem>
				<GridItem xs={12} sm={4} md={3}>
                    {renderInput('fax')}
				</GridItem>
                <GridItem xs={12} sm={4} md={3}>
                    {renderInput('work_phone')}
				</GridItem>
                <GridItem xs={12} sm={4} md={6}>
				</GridItem>
				<GridItem xs={12} sm={4} md={3}>
                    {renderInput('assistant_name')}
				</GridItem>
                <GridItem xs={12} sm={4} md={3}>
                    {renderInput('assistant_email')}
				</GridItem>
                <GridItem xs={12} sm={4} md={3}>
                    {renderInput('assistant_phone')}
				</GridItem>
			</GridContainer>

            <GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<hr style={{marginTop:40}} />
					<h4 className="bold">Social Media</h4>
				</GridItem>
				<GridItem xs={12} sm={4} md={3}>
                    {renderInput('twitter', 'link')}
				</GridItem>
				<GridItem xs={12} sm={4} md={3}>
					{renderInput('facebook', 'link')}
				</GridItem>
				<GridItem xs={12} sm={4} md={3}>
					{renderInput('linkedin', 'link')}
				</GridItem>
                <GridItem xs={12} sm={4} md={3}>
					{renderInput('instagram', 'link')}
				</GridItem>
			</GridContainer>

			<GridContainer>
				<GridItem xs={12} sm={9} md={9}>
					<hr style={{marginTop:40}} />
					<h4 className="bold">Contact Type</h4>
					<small>(Select all that apply)</small>
				</GridItem>
				<GridItem xs={12} sm={3} md={3}></GridItem>

				{/* PROPERTY OWNER */}
				<GridItem xs={12} sm={3} md={2}>
					<FormGroup row className="mt-15">
						<FormControlLabel
							control={
								<Checkbox
									checked={contactTypes.indexOf("Property Owner") !== -1}
									onChange={() => {
										const v =
											contactTypes.indexOf("Property Owner") !== -1
												? contactTypes.filter((c) => {
														if (c === "Property Owner") return false;
														return true;
												  })
												: contactTypes.concat(["Property Owner"]);
										props.updateDataObj("contact_type", v.join(","));
									}}
									color="default"
								/>
							}
							label="Property Owner"
						/>
					</FormGroup>
				</GridItem>
				<GridItem xs={12} sm={3} md={4}>
					{contactTypes.indexOf("Property Owner") !== -1 && (
						<CustomSelect
							label="Property Owner Subtype"
							options={contactSubTypeDecider("Property Owner", vendorOptions)}
							choose={(e, n) => {
								let subtypes = props.dataObj.contact_subtype
									? props.dataObj.contact_subtype.split(",")
									: [];
								if (subtypes.indexOf(e) === -1) {
									subtypes.push(e);
								}
								props.updateDataObj("contact_subtype", subtypes.join(","));
							}}
							default={
								props.dataObj.contact_subtype
									? returnSubtype(
											props.dataObj.contact_subtype,
											"Property Owner"
									  )
									: ""
							}
						/>
					)}
				</GridItem>
				<GridItem xs={12} sm={6} md={6}></GridItem>

                <GridItem xs={12} sm={3} md={2}>
                    <FormGroup row className="mt-15">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={contactTypes.indexOf('Buyer') !== -1}
                                    onChange={() => {
                                        const v = contactTypes.indexOf('Buyer') !== -1 ?
                                            contactTypes.filter(c => {
                                                if (c === 'Buyer') return false;
                                                return true;
                                            })
                                            :
                                            contactTypes.concat(['Buyer']);
                                        props.updateDataObj('contact_type', v.join(','));
                                    }}
                                    color="default"
                                />
                            }
                            label="Buyer"
                        />
                    </FormGroup>
                </GridItem>
                <GridItem xs={12} sm={3} md={4}>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}></GridItem>

                <GridItem xs={12} sm={3} md={2}>
                    <FormGroup row className="mt-15">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={contactTypes.indexOf('Lessee') !== -1}
                                    onChange={() => {
                                        const v = contactTypes.indexOf('Lessee') !== -1 ?
                                            contactTypes.filter(c => {
                                                if (c === 'Lessee') return false;
                                                return true;
                                            })
                                            :
                                            contactTypes.concat(['Lessee']);
                                        props.updateDataObj('contact_type', v.join(','));
                                    }}
                                    color="default"
                                />
                            }
                            label="Lessee"
                        />
                    </FormGroup>
                </GridItem>
                
                <GridItem xs={12} sm={3} md={4}>
                    {contactTypes.indexOf('Lessee') !== -1 && <CustomSelect
                        label="Lessee Subtype"
                        options={contactSubTypeDecider('Lessee', vendorOptions)}
                        choose={(e, n) => {
                            let subtypes = props.dataObj.contact_subtype ? props.dataObj.contact_subtype.split(',') : [];
                            if (subtypes.indexOf(e) === -1) {
                                subtypes.push(e);
                            }
                            props.updateDataObj('contact_subtype', subtypes.join(','));
                        }}
                        default={props.dataObj.contact_subtype ? returnSubtype(props.dataObj.contact_subtype, 'Lessee') : ''}
                    />}
                    {contactTypes.indexOf('Lessee') !== -1 && <CustomSelect
                        label="Exclusive Right to Represent Buyer/Lessee?"
                        options={['','Yes','No']}
                        choose={(e, n) => {
                            props.updateDataObj('exclusive_right_to_represent_buyer', e);
                        }}
                        default={props.dataObj.exclusive_right_to_represent_buyer}
                    />}
                    {(contactTypes.indexOf('Lessee') !== -1 && props.dataObj.exclusive_right_to_represent_buyer === 'Yes') && <div className="boxed">
                        <FileUploader 
                            title="AIR Exclusive Right to Represent Buyer/Lessee"
                            update={(val) => {
                                props.updateDataObj('air_exclusive_right_to_represent_buyer', val);
                            }}
                            remove={() => {
                                props.updateDataObj('air_exclusive_right_to_represent_buyer', '');
                            }}
                            the_filename={props.dataObj.air_exclusive_right_to_represent_buyer}
                            type="crm"
                        />
                    </div>}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}></GridItem>

                <GridItem xs={12} sm={3} md={2}>
                    <FormGroup row className="mt-15">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={contactTypes.indexOf('Vendor') !== -1}
                                    onChange={() => {
                                        const v = contactTypes.indexOf('Vendor') !== -1 ?
                                            contactTypes.filter(c => {
                                                if (c === 'Vendor') return false;
                                                return true;
                                            })
                                            :
                                            contactTypes.concat(['Vendor']);
                                        props.updateDataObj('contact_type', v.join(','));
                                    }}
                                    color="default"
                                />
                            }
                            label="Vendor"
                        />
                    </FormGroup>
                </GridItem>
                <GridItem xs={12} sm={3} md={4}>
                    {contactTypes.indexOf('Vendor') !== -1 && <CustomSelect
                        label="Vendor Subtype"
                        options={contactSubTypeDecider('Vendor', vendorOptions)}
                        choose={(e, n) => {
                            let subtypes = props.dataObj.contact_subtype ? props.dataObj.contact_subtype.split(',') : [];
                            if (subtypes.indexOf(e) === -1) {
                                subtypes.push(e);
                            }
                            props.updateDataObj('contact_subtype', subtypes.join(','));
                        }}
                        default={props.dataObj.contact_subtype ? returnSubtype(props.dataObj.contact_subtype, 'Vendor') : ''}
                    />}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}></GridItem>

                <GridItem xs={12} sm={3} md={2}>
                    <FormGroup row className="mt-15">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={contactTypes.indexOf('Property Manager') !== -1}
                                    onChange={() => {
                                        const v = contactTypes.indexOf('Property Manager') !== -1 ?
                                            contactTypes.filter(c => {
                                                if (c === 'Property Manager') return false;
                                                return true;
                                            })
                                            :
                                            contactTypes.concat(['Property Manager']);
                                        props.updateDataObj('contact_type', v.join(','));
                                    }}
                                    color="default"
                                />
                            }
                            label="Property Manager"
                        />
                    </FormGroup>
                </GridItem>
                <GridItem xs={12} sm={3} md={4}>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}></GridItem>

                <GridItem xs={12} sm={3} md={2}>
                    <FormGroup row className="mt-15">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={contactTypes.indexOf('Real Estate Agent') !== -1}
                                    onChange={() => {
                                        const v = contactTypes.indexOf('Real Estate Agent') !== -1 ?
                                            contactTypes.filter(c => {
                                                if (c === 'Real Estate Agent') return false;
                                                return true;
                                            })
                                            :
                                            contactTypes.concat(['Real Estate Agent']);
                                        props.updateDataObj('contact_type', v.join(','));
                                    }}
                                    color="default"
                                />
                            }
                            label="Real Estate Agent"
                        />
                    </FormGroup>
                </GridItem>
                <GridItem xs={12} sm={3} md={4}>
                    {contactTypes.indexOf('Real Estate Agent') !== -1 && <CustomSelect
                        label="Real Estate Agent Subtype"
                        options={contactSubTypeDecider('Real Estate Agent', vendorOptions)}
                        choose={(e, n) => {
                            let subtypes = props.dataObj.contact_subtype ? props.dataObj.contact_subtype.split(',') : [];
                            if (subtypes.indexOf(e) === -1) {
                                subtypes.push(e);
                            }
                            props.updateDataObj('contact_subtype', subtypes.join(','));
                        }}
                        default={props.dataObj.contact_subtype ? returnSubtype(props.dataObj.contact_subtype, 'Real Estate Agent') : ''}
                    />}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    {(contactTypes.indexOf('Real Estate Agent') !== -1) && <CustomInputValidate
                        label="DRE#"
                        value={props.dataObj.dre}
                        onChange={(e) => {
                            props.updateDataObj("dre", e.target.value);
                        }}
                        validate={getValidations('dre')}
                        readonly={props.action === "View" ? true : false}
                    />}
                </GridItem>

                <GridItem xs={12} sm={3} md={2}>
                    <FormGroup row className="mt-15">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={contactTypes.indexOf('Real Estate Broker') !== -1}
                                    onChange={() => {
                                        const v = contactTypes.indexOf('Real Estate Broker') !== -1 ?
                                            contactTypes.filter(c => {
                                                if (c === 'Real Estate Broker') return false;
                                                return true;
                                            })
                                            :
                                            contactTypes.concat(['Real Estate Broker']);
                                        props.updateDataObj('contact_type', v.join(','));
                                    }}
                                    color="default"
                                />
                            }
                            label="Real Estate Broker"
                        />
                    </FormGroup>
                </GridItem>
                <GridItem xs={12} sm={3} md={4}>
                    {contactTypes.indexOf('Real Estate Broker') !== -1 && <CustomSelect
                        label="Real Estate Broker Subtype"
                        options={contactSubTypeDecider('Real Estate Broker', vendorOptions)}
                        choose={(e, n) => {
                            let subtypes = props.dataObj.contact_subtype ? props.dataObj.contact_subtype.split(',') : [];
                            if (subtypes.indexOf(e) === -1) {
                                subtypes.push(e);
                            }
                            props.updateDataObj('contact_subtype', subtypes.join(','));
                        }}
                        default={props.dataObj.contact_subtype ? returnSubtype(props.dataObj.contact_subtype, 'Real Estate Broker') : ''}
                    />}
                </GridItem>

                <GridItem xs={12} sm={6} md={6}>
                    {(contactTypes.indexOf('Real Estate Broker') !== -1) && <CustomInputValidate
                        label="DRE#"
                        value={props.dataObj.dre_broker}
                        onChange={(e) => {
                            props.updateDataObj("dre_broker", e.target.value);
                        }}
                        validate={getValidations('dre_broker')}
                        readonly={props.action === "View" ? true : false}
                    />}
                </GridItem>

                <GridItem xs={12} sm={3} md={2}>
                    <FormGroup row className="mt-15">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={contactTypes.indexOf('Developer') !== -1}
                                    onChange={() => {
                                        const v = contactTypes.indexOf('Developer') !== -1 ?
                                            contactTypes.filter(c => {
                                                if (c === 'Developer') return false;
                                                return true;
                                            })
                                            :
                                            contactTypes.concat(['Developer']);
                                        props.updateDataObj('contact_type', v.join(','));
                                    }}
                                    color="default"
                                />
                            }
                            label="Developer"
                        />
                    </FormGroup>
                </GridItem>

                <GridItem xs={12}></GridItem>

                <GridItem xs={12} sm={3} md={2}>
                    <div className={!can_edit_illi_associates_checkbox ? 'no-click gray-bg' : ''}>
                        <FormGroup row className="mt-15">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={contactTypes.indexOf('illi Associate') !== -1}
                                        onChange={() => {
                                            const v = contactTypes.indexOf('illi Associate') !== -1 ?
                                                contactTypes.filter(c => {
                                                    if (c === 'illi Associate') return false;
                                                    return true;
                                                })
                                                :
                                                contactTypes.concat(['illi Associate']);
                                            props.updateDataObj('contact_type', v.join(','));
                                        }}
                                        color="default"
                                    />
                                }
                                label="illi Associate"
                            />
                        </FormGroup>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={9} md={10}></GridItem>

                <GridItem xs={12} sm={3} md={2}>
                    <div className={!can_edit_illi_associates_checkbox ? 'no-click gray-bg' : ''}>
                        <FormGroup row className="mt-15">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={contactTypes.indexOf('illi Employee') !== -1}
                                        onChange={() => {
                                            const v = contactTypes.indexOf('illi Employee') !== -1 ?
                                                contactTypes.filter(c => {
                                                    if (c === 'illi Employee') return false;
                                                    return true;
                                                })
                                                :
                                                contactTypes.concat(['illi Employee']);
                                            props.updateDataObj('contact_type', v.join(','));
                                        }}
                                        color="default"
                                    />
                                }
                                label="illi Employee"
                            />
                        </FormGroup>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={9} md={10}></GridItem>

                <GridItem xs={12} sm={3} md={2}>
                    <FormGroup row className="mt-15">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={contactTypes.indexOf('Other') !== -1}
                                    onChange={() => {
                                        const v = contactTypes.indexOf('Other') !== -1 ?
                                            contactTypes.filter(c => {
                                                if (c === 'Other') return false;
                                                return true;
                                            })
                                            :
                                            contactTypes.concat(['Other']);
                                        props.updateDataObj('contact_type', v.join(','));
                                    }}
                                    color="default"
                                />
                            }
                            label="Other"
                        />
                    </FormGroup>
                </GridItem>
                <GridItem xs={12} sm={3} md={4}>
                    {contactTypes.indexOf('Other') !== -1 && <CustomInputValidate
                        label="Specify Other Contact Type"
                        value={props.dataObj.contact_type_other}
                        validate={getValidations('contact_type_other')}
                        onChange={(e) => { props.updateDataObj('contact_type_other', e.target.value) }}
                    />}
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>

                </GridItem>
                {/* ['Property Owner', 'Buyer', 'Tenant', 'Vendor',  */}
                {/* 'Property Manager', 'Real Estate Agent', 'Real Estate Broker','Other'] */}

                <GridItem xs={12} sm={6} md={6}>
                    {/* <CustomSelect
                        label="Contact Sub-Type"
                        default={props.dataObj.contact_subtype ? props.dataObj.contact_subtype : ''}
                        options={contactSubTypeDecider(contactTypes, vendorOptions)}
                        choose={(v) => {
                            props.updateDataObj('contact_subtype', v);
                        }}
                    /> */}
                </GridItem>

            </GridContainer>

            <GridContainer>
                <GridItem xs={12} sm={3} md={3}>
                    <div className="boxed">
                        <FileUploader 
                            title="Upload W9"
                            update={(val) => {
                                props.updateDataObj('w9', val);
                            }}
                            remove={() => {
                                props.updateDataObj('w9', '');
                            }}
                            the_filename={props.dataObj.w9}
                            type="crm"
                        />
                    </div>
                </GridItem>
            </GridContainer>

            {openedModal && (
                <CustomDialog
                    title='Select Use Type'
                    label='Filter Use Type'
                    icon={<Person />}
                    open={openedModal}
                    close={() => {
                        setOpenedModal(false);
                    }}
                    choose={(choice) => {
                        props.updateDataObj('use_type', choice);
                        setOpenedModal(false);
                    }}
                    options={props.contactOptions ? props.contactOptions.useTypeOptions : []}
                />
            )}
        </div>
    );
};

export default ContactsFormGeneral;
