/*
    Handling Users
*/
export const AUTH_START = 'AUTH_START';
export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_CHECKED = 'AUTH_CHECKED';
export const CLEAR_USERNAME = 'CLEAR_USERNAME';

export const GET_WORKSPACE_USERS = 'GET_WORKSPACE_USERS';
export const GET_WORKSPACE_USERS_START = 'GET_WORKSPACE_USERS_START';
export const GET_WORKSPACE_USERS_SUCCESS = 'GET_WORKSPACE_USERS_SUCCESS';
export const GET_WORKSPACE_USERS_FAIL = 'GET_WORKSPACE_USERS_FAIL';

export const SYNC_USERS = 'SYNC_USERS';
export const SYNC_USERS_START = 'SYNC_USERS_START';
export const SYNC_USERS_SUCCESS = 'SYNC_USERS_SUCCESS';
export const SYNC_USERS_FAIL = 'SYNC_USERS_FAIL';

export const CREATE_WORKSPACE_USER = 'CREATE_WORKSPACE_USER';
export const CREATE_WORKSPACE_USER_START = 'CREATE_WORKSPACE_USER_START';
export const CREATE_WORKSPACE_USER_SUCCESS = 'CREATE_WORKSPACE_USER_SUCCESS';
export const CREATE_WORKSPACE_USER_FAIL = 'CREATE_WORKSPACE_USER_FAIL';

export const CREATE_USER_FROM_WORKSPACE = 'CREATE_USER_FROM_WORKSPACE';
export const CREATE_USER_FROM_WORKSPACE_START = 'CREATE_USER_FROM_WORKSPACE_START';
export const CREATE_USER_FROM_WORKSPACE_SUCCESS = 'CREATE_USER_FROM_WORKSPACE_SUCCESS';
export const CREATE_USER_FROM_WORKSPACE_FAIL = 'CREATE_USER_FROM_WORKSPACE_FAIL';

export const DELETE_WORKSPACE_USER = 'DELETE_WORKSPACE_USER';
export const DELETE_WORKSPACE_USER_START = 'DELETE_WORKSPACE_USER_START';
export const DELETE_WORKSPACE_USER_SUCCESS = 'DELETE_WORKSPACE_USER_SUCCESS';
export const DELETE_WORKSPACE_USER_FAIL = 'DELETE_WORKSPACE_USER_FAIL';

export const UPDATE_WORKSPACE_USER = 'UPDATE_WORKSPACE_USER';
export const UPDATE_WORKSPACE_USER_START = 'UPDATE_WORKSPACE_USER_START';
export const UPDATE_WORKSPACE_USER_SUCCESS = 'UPDATE_WORKSPACE_USER_SUCCESS';
export const UPDATE_WORKSPACE_USER_FAIL = 'UPDATE_WORKSPACE_USER_FAIL';

export const CHECK_USERNAME = 'CHECK_USERNAME';
export const CHECK_USERNAME_START = 'CHECK_USERNAME_START';
export const CHECK_USERNAME_SUCCESS = 'CHECK_USERNAME_SUCCESS';
export const CHECK_USERNAME_FAIL = 'CHECK_USERNAME_FAIL';

export const GET_WORKSPACE_GROUPS = 'GET_WORKSPACE_GROUPS';
export const GET_WORKSPACE_GROUPS_START = 'GET_WORKSPACE_GROUPS_START';
export const GET_WORKSPACE_GROUPS_SUCCESS = 'GET_WORKSPACE_GROUPS_SUCCESS';
export const GET_WORKSPACE_GROUPS_FAIL = 'GET_WORKSPACE_GROUPS_FAIL';

export const CREATE_WORKSPACE_GROUP = 'CREATE_WORKSPACE_GROUP';
export const CREATE_WORKSPACE_GROUP_START = 'CREATE_WORKSPACE_GROUP_START';
export const CREATE_WORKSPACE_GROUP_SUCCESS = 'CREATE_WORKSPACE_GROUP_SUCCESS';
export const CREATE_WORKSPACE_GROUP_FAIL = 'CREATE_WORKSPACE_GROUP_FAIL';

export const DELETE_WORKSPACE_GROUP = 'DELETE_WORKSPACE_GROUP';
export const DELETE_WORKSPACE_GROUP_START = 'DELETE_WORKSPACE_GROUP_START';
export const DELETE_WORKSPACE_GROUP_SUCCESS = 'DELETE_WORKSPACE_GROUP_SUCCESS';
export const DELETE_WORKSPACE_GROUP_FAIL = 'DELETE_WORKSPACE_GROUP_FAIL';

export const UPDATE_WORKSPACE_GROUP = 'UPDATE_WORKSPACE_GROUP';
export const UPDATE_WORKSPACE_GROUP_START = 'UPDATE_WORKSPACE_GROUP_START';
export const UPDATE_WORKSPACE_GROUP_SUCCESS = 'UPDATE_WORKSPACE_GROUP_SUCCESS';
export const UPDATE_WORKSPACE_GROUP_FAIL = 'UPDATE_WORKSPACE_GROUP_FAIL';

export const GET_WORKSPACE_GROUP_MEMBERS = 'GET_WORKSPACE_GROUPS_MEMBERS';
export const GET_WORKSPACE_GROUP_MEMBERS_START =
	'GET_WORKSPACE_GROUPS_MEMBERS_START';
export const GET_WORKSPACE_GROUP_MEMBERS_SUCCESS =
	'GET_WORKSPACE_GROUPS_MEMBERS_SUCCESS';
export const GET_WORKSPACE_GROUP_MEMBERS_FAIL =
	'GET_WORKSPACE_GROUPS_MEMBERS_FAIL';

export const DELETE_WORKSPACE_GROUP_MEMBER = 'DELETE_WORKSPACE_GROUP_MEMBER';
export const DELETE_WORKSPACE_GROUP_MEMBER_START =
	'DELETE_WORKSPACE_GROUP_MEMBER_START';
export const DELETE_WORKSPACE_GROUP_MEMBER_SUCCESS =
	'DELETE_WORKSPACE_GROUP_MEMBER_SUCCESS';
export const DELETE_WORKSPACE_GROUP_MEMBER_FAIL =
	'DELETE_WORKSPACE_GROUP_MEMBER_FAIL';

export const CREATE_WORKSPACE_GROUP_MEMBER = 'CREATE_WORKSPACE_GROUP_MEMBER';
export const CREATE_WORKSPACE_GROUP_MEMBER_START =
	'CREATE_WORKSPACE_GROUP_MEMBER_START';
export const CREATE_WORKSPACE_GROUP_MEMBER_SUCCESS =
	'CREATE_WORKSPACE_GROUP_MEMBER_SUCCESS';
export const CREATE_WORKSPACE_GROUP_MEMBER_FAIL =
	'CREATE_WORKSPACE_GROUP_MEMBER_FAIL';

/*
    Handling UserPerms
*/
export const GET_USERS = 'GET_USERS';
export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

export const GET_USERS_FILTERED = 'GET_USERS_FILTERED';
export const GET_USERS_FILTERED_START = 'GET_USERS_FILTERED_START';
export const GET_USERS_FILTERED_SUCCESS = 'GET_USERS_FILTERED_SUCCESS';
export const GET_USERS_FILTERED_FAIL = 'GET_USERS_FILTERED_FAIL';

export const GET_USER = 'GET_USER';
export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';

export const GET_USER_BY_NAME = 'GET_USER_BY_NAME';
export const GET_USER_BY_NAME_START = 'GET_USER_BY_NAME_START';
export const GET_USER_BY_NAME_SUCCESS = 'GET_USER_BY_NAME_SUCCESS';
export const GET_USER_BY_NAME_FAIL = 'GET_USER_BY_NAME_FAIL';

export const GET_USER_BULK = 'GET_USER_BULK';
export const GET_USER_BULK_START = 'GET_USER_BULK_START';
export const GET_USER_BULK_SUCCESS = 'GET_USER_BULK_SUCCESS';
export const GET_USER_BULK_FAIL = 'GET_USER_BULK_FAIL';

export const SAVE_USER_PERMS = 'SAVE_USER_PERMS';
export const SAVE_USER_PERMS_START = 'SAVE_USER_PERMS_START';
export const SAVE_USER_PERMS_SUCCESS = 'SAVE_USER_PERMS_SUCCESS';
export const SAVE_USER_PERMS_FAIL = 'SAVE_USER_PERMS_FAIL';

export const CHOOSE_NEW_PERMS = 'CHOOSE_NEW_PERMS';

export const USERS_SYNC = 'USERS_SYNC';
export const SYNC_USER_START = 'SYNC_USER_START';
export const SYNC_USER_SUCCESS = 'SYNC_USER_SUCCESS';
export const SYNC_USER_FAIL = 'SYNC_USER_FAIL';

export const BULK_SYNC = 'BULK_SYNC';
export const BULK_SYNC_START = 'BULK_SYNC_START';
export const BULK_SYNC_SUCCESS = 'BULK_SYNC_SUCCESS';
export const BULK_SYNC_FAIL = 'BULK_SYNC_FAIL';

export const BULK_ASSIGN = 'BULK_ASSIGN';
export const BULK_ASSIGN_START = 'BULK_ASSIGN_START';
export const BULK_ASSIGN_SUCCESS = 'BULK_ASSIGN_SUCCESS';
export const BULK_ASSIGN_FAIL = 'BULK_ASSIGN_FAIL';
export const BULK_ASSIGN_END = 'BULK_ASSIGN_END';

export const NOTIFY_MAIL = 'NOTIFY_MAIL';

/*
  Settings
*/

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const UPDATE_SETTINGS_START = 'UPDATE_SETTINGS_START';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_FAIL = 'UPDATE_SETTINGS_FAIL';

export const READ_SETTINGS = 'READ_SETTINGS';
export const READ_SETTINGS_START = 'READ_SETTINGS_START';
export const READ_SETTINGS_SUCCESS = 'READ_SETTINGS_SUCCESS';
export const READ_SETTINGS_FAIL = 'READ_SETTINGS_FAIL';

export const MAINTENANCE_MODE = 'MAINTENANCE_MODE';
export const MAINTENANCE_MODE_SUCCESS = 'MAINTENANCE_MODE_SUCCESS';
export const CHECK_MAINTENANCE_MODE = 'CHECK_MAINTENANCE_MODE';
export const CHECK_MAINTENANCE_MODE_SUCCESS = 'CHECK_MAINTENANCE_MODE_SUCCESS';

export const TPP_MAINTENANCE = 'TPP_MAINTENANCE';
export const TPP_MAINTENANCE_START = 'TPP_MAINTENANCE_START';
export const TPP_MAINTENANCE_SUCCESS = 'TPP_MAINTENANCE_SUCCESS';
export const TPP_MAINTENANCE_FAIL = 'TPP_MAINTENANCE_FAIL';

export const TPP_MAINTENANCE_CHECK = 'TPP_MAINTENANCE_CHECK';
export const TPP_MAINTENANCE_CHECK_START = 'TPP_MAINTENANCE_CHECK_START';
export const TPP_MAINTENANCE_CHECK_SUCCESS = 'TPP_MAINTENANCE_CHECK_SUCCESS';
export const TPP_MAINTENANCE_CHECK_FAIL = 'TPP_MAINTENANCE_CHECK_FAIL';

/*
  CRM ALL
*/

export const CREATE_CRM_FILE = 'CREATE_CRM_FILE';
export const CREATE_CRM_FILE_START = 'CREATE_CRM_FILE_START';
export const CREATE_CRM_FILE_SUCCESS = 'CREATE_CRM_FILE_SUCCESS';
export const CREATE_CRM_FILE_FAIL = 'CREATE_CRM_FILE_FAIL';

export const GET_CRM_FILE = 'GET_CRM_FILE';
export const GET_CRM_FILE_START = 'GET_CRM_FILE_START';
export const GET_CRM_FILE_SUCCESS = 'GET_CRM_FILE_SUCCESS';
export const GET_CRM_FILE_FAIL = 'GET_CRM_FILE_FAIL';

export const GET_CRM_FILE_LIST = 'GET_CRM_FILE_LIST';
export const GET_CRM_FILE_LIST_START = 'GET_CRM_FILE_LIST_START';
export const GET_CRM_FILE_LIST_SUCCESS = 'GET_CRM_FILE_LIST_SUCCESS';
export const GET_CRM_FILE_LIST_FAIL = 'GET_CRM_FILE_LIST_FAIL';

export const DELETE_CRM_FILE = 'DELETE_CRM_FILE';
export const DELETE_CRM_FILE_START = 'DELETE_CRM_FILE_START';
export const DELETE_CRM_FILE_SUCCESS = 'DELETE_CRM_FILE_SUCCESS';
export const DELETE_CRM_FILE_FAIL = 'DELETE_CRM_FILE_FAIL';

/*
  CRM CONTACTS
*/

export const GET_CRM_DATA = 'GET_CRM_DATA';
export const GET_CRM_DATA_START = 'GET_CRM_DATA_START';
export const GET_CRM_DATA_SUCCESS = 'GET_CRM_DATA_SUCCESS';
export const GET_CRM_DATA_FAIL = 'GET_CRM_DATA_FAIL';

export const UPDATE_CRM_DATA = 'UPDATE_CRM_DATA';
export const UPDATE_CRM_DATA_START = 'UPDATE_CRM_DATA_START';
export const UPDATE_CRM_DATA_SUCCESS = 'UPDATE_CRM_DATA_SUCCESS';
export const UPDATE_CRM_DATA_FAIL = 'UPDATE_CRM_DATA_FAIL';

export const GET_CONTACTS = 'GET_CONTACTS';
export const GET_CONTACTS_START = 'GET_CONTACTS_START';
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';
export const GET_CONTACTS_FAIL = 'GET_CONTACTS_FAIL';

export const GET_CONTACT = 'GET_CONTACT';
export const GET_CONTACT_START = 'GET_CONTACT_START';
export const GET_CONTACT_SUCCESS = 'GET_CONTACT_SUCCESS';
export const GET_CONTACT_FAIL = 'GET_CONTACT_FAIL';

export const GET_CONTACT_DATA = 'GET_CONTACT_DATA';
export const GET_CONTACT_DATA_START = 'GET_CONTACT_DATA_START';
export const GET_CONTACT_DATA_SUCCESS = 'GET_CONTACT_DATA_SUCCESS';
export const GET_CONTACT_DATA_FAIL = 'GET_CONTACT_DATA_FAIL';

export const GET_CONTACT_NAMES = 'GET_CONTACT_NAMES';
export const GET_CONTACT_NAMES_START = 'GET_CONTACT_NAMES_START';
export const GET_CONTACT_NAMES_SUCCESS = 'GET_CONTACT_NAMES_SUCCESS';
export const GET_CONTACT_NAMES_FAIL = 'GET_CONTACT_NAMES_FAIL';

export const CLEAR_CONTACT_NAMES = 'CLEAR_CONTACT_NAMES';

export const CREATE_CONTACT = 'CREATE_CONTACT';
export const CREATE_CONTACT_START = 'CREATE_CONTACT_START';
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS';
export const CREATE_CONTACT_FAIL = 'CREATE_CONTACT_FAIL';

export const DELETE_CONTACT = 'DELETE_CONTACT';
export const DELETE_CONTACT_START = 'DELETE_CONTACT_START';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_FAIL = 'DELETE_CONTACT_FAIL';

export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const UPDATE_CONTACT_START = 'UPDATE_CONTACT_START';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT_FAIL = 'UPDATE_CONTACT_FAIL';

export const GET_ADDRESSES = 'GET_ADDRESSES';
export const GET_ADDRESSES_START = 'GET_ADDRESSES_START';
export const GET_ADDRESSES_SUCCESS = 'GET_ADDRESSES_SUCCESS';
export const GET_ADDRESSES_FAIL = 'GET_ADDRESSES_FAIL';

export const GET_PARTNERS = 'GET_PARTNERS';
export const GET_PARTNERS_START = 'GET_PARTNERS_START';
export const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS';
export const GET_PARTNERS_FAIL = 'GET_PARTNERS_FAIL';

export const GET_CONTACT_OPTIONS = 'GET_CONTACT_OPTIONS';
export const GET_CONTACT_OPTIONS_START = 'GET_CONTACT_OPTIONS_START';
export const GET_CONTACT_OPTIONS_SUCCESS = 'GET_CONTACT_OPTIONS_SUCCESS';
export const GET_CONTACT_OPTIONS_FAIL = 'GET_CONTACT_OPTIONS_FAIL';

export const GET_CONTACT_SHARES = 'GET_CONTACT_SHARES';
export const GET_CONTACT_SHARES_START = 'GET_CONTACT_SHARES_START';
export const GET_CONTACT_SHARES_SUCCESS = 'GET_CONTACT_SHARES_SUCCESS';
export const GET_CONTACT_SHARES_FAIL = 'GET_CONTACT_SHARES_FAIL';

export const GET_CONTACT_USERNAMES = 'GET_CONTACT_USERNAMES';
export const GET_CONTACT_USERNAMES_START = 'GET_CONTACT_USERNAMES_START';
export const GET_CONTACT_USERNAMES_SUCCESS = 'GET_CONTACT_USERNAMES_SUCCESS';
export const GET_CONTACT_USERNAMES_FAIL = 'GET_CONTACT_USERNAMES_FAIL';

export const CREATE_CONTACT_SHARE = 'CREATE_CONTACT_SHARE';
export const CREATE_CONTACT_SHARE_START = 'CREATE_CONTACT_SHARE_START';
export const CREATE_CONTACT_SHARE_SUCCESS = 'CREATE_CONTACT_SHARE_SUCCESS';
export const CREATE_CONTACT_SHARE_FAIL = 'CREATE_CONTACT_SHARE_FAIL';

export const DELETE_CONTACT_SHARE = 'DELETE_CONTACT_SHARE';
export const DELETE_CONTACT_SHARE_START = 'DELETE_CONTACT_SHARE_START';
export const DELETE_CONTACT_SHARE_SUCCESS = 'DELETE_CONTACT_SHARE_SUCCESS';
export const DELETE_CONTACT_SHARE_FAIL = 'DELETE_CONTACT_SHARE_FAIL';

export const ADD_PROPERTY_TO_CONTACT = 'ADD_PROPERTY_TO_CONTACT';
export const ADD_PROPERTY_TO_CONTACT_START = 'ADD_PROPERTY_TO_CONTACT_START';
export const ADD_PROPERTY_TO_CONTACT_SUCCESS =
	'ADD_PROPERTY_TO_CONTACT_SUCCESS';
export const ADD_PROPERTY_TO_CONTACT_FAIL = 'ADD_PROPERTY_TO_CONTACT_FAIL';

export const REMOVE_PROPERTY_TO_CONTACT = 'REMOVE_PROPERTY_TO_CONTACT';
export const REMOVE_PROPERTY_TO_CONTACT_START =
	'REMOVE_PROPERTY_TO_CONTACT_START';
export const REMOVE_PROPERTY_TO_CONTACT_SUCCESS =
	'REMOVE_PROPERTY_TO_CONTACT_SUCCESS';
export const REMOVE_PROPERTY_TO_CONTACT_FAIL =
	'REMOVE_PROPERTY_TO_CONTACT_FAIL';

export const GET_PROPERTY_TO_CONTACT = 'GET_PROPERTY_TO_CONTACT';
export const GET_PROPERTY_TO_CONTACT_START = 'GET_PROPERTY_TO_CONTACT_START';
export const GET_PROPERTY_TO_CONTACT_SUCCESS =
	'GET_PROPERTY_TO_CONTACT_SUCCESS';
export const GET_PROPERTY_TO_CONTACT_FAIL = 'GET_PROPERTY_TO_CONTACT_FAIL';

export const CLEAR_CONTACTS = 'CLEAR_CONTACTS';

export const CLEAR_FILE_LIST = 'CLEAR_FILE_LIST';

export const CLEAR_CRM_MESSAGES = 'CLEAR_CRM_MESSAGES';

export const GET_CONTACT_CHANGES = 'GET_CONTACT_CHANGES';
export const GET_CONTACT_CHANGES_START = 'GET_CONTACT_CHANGES_START';
export const GET_CONTACT_CHANGES_SUCCESS = 'GET_CONTACT_CHANGES_SUCCESS';
export const GET_CONTACT_CHANGES_FAIL = 'GET_CONTACT_CHANGES_FAIL';

export const SAVE_CONTACT_CHANGES = 'SAVE_CONTACT_CHANGES';
export const SAVE_CONTACT_CHANGES_START = 'SAVE_CONTACT_CHANGES_START';
export const SAVE_CONTACT_CHANGES_SUCCESS = 'SAVE_CONTACT_CHANGES_SUCCESS';
export const SAVE_CONTACT_CHANGES_FAIL = 'SAVE_CONTACT_CHANGES_FAIL';

export const SET_CONTACT_CHANGES = 'SET_CONTACT_CHANGES';

export const GET_CONTACT_ENTITIES = 'GET_CONTACT_ENTITIES';
export const GET_CONTACT_ENTITIES_START = 'GET_CONTACT_ENTITIES_START';
export const GET_CONTACT_ENTITIES_SUCCESS = 'GET_CONTACT_ENTITIES_SUCCESS';
export const GET_CONTACT_ENTITIES_FAIL = 'GET_CONTACT_ENTITIES_FAIL';

export const CREATE_CONTACT_ENTITY = 'CREATE_CONTACT_ENTITY';
export const CREATE_CONTACT_ENTITY_START = 'CREATE_CONTACT_ENTITY_START';
export const CREATE_CONTACT_ENTITY_SUCCESS = 'CREATE_CONTACT_ENTITY_SUCCESS';
export const CREATE_CONTACT_ENTITY_FAIL = 'CREATE_CONTACT_ENTITY_FAIL';

export const DELETE_CONTACT_ENTITY = 'DELETE_CONTACT_ENTITY';
export const DELETE_CONTACT_ENTITY_START = 'DELETE_CONTACT_ENTITY_START';
export const DELETE_CONTACT_ENTITY_SUCCESS = 'DELETE_CONTACT_ENTITY_SUCCESS';
export const DELETE_CONTACT_ENTITY_FAIL = 'DELETE_CONTACT_ENTITY_FAIL';

export const GET_UPDATED_CONTACT_ENTITY = 'GET_UPDATED_CONTACT_ENTITY';
export const GET_UPDATED_CONTACT_ENTITY_START = 'GET_UPDATED_CONTACT_ENTITY_START';
export const GET_UPDATED_CONTACT_ENTITY_SUCCESS = 'GET_UPDATED_CONTACT_ENTITY_SUCCESS';
export const GET_UPDATED_CONTACT_ENTITY_FAIL = 'GET_UPDATED_CONTACT_ENTITY_FAIL';

export const GET_CONTACT_DBAS = 'GET_CONTACT_DBAS';
export const GET_CONTACT_DBAS_START = 'GET_CONTACT_DBAS_START';
export const GET_CONTACT_DBAS_SUCCESS = 'GET_CONTACT_DBAS_SUCCESS';
export const GET_CONTACT_DBAS_FAIL = 'GET_CONTACT_DBAS_FAIL';

export const CREATE_CONTACT_DBA = 'CREATE_CONTACT_DBA';
export const CREATE_CONTACT_DBA_START = 'CREATE_CONTACT_DBA_START';
export const CREATE_CONTACT_DBA_SUCCESS = 'CREATE_CONTACT_DBA_SUCCESS';
export const CREATE_CONTACT_DBA_FAIL = 'CREATE_CONTACT_DBA_FAIL';

export const UPDATE_CONTACT_DBA = 'UPDATE_CONTACT_DBA';
export const UPDATE_CONTACT_DBA_START = 'UPDATE_CONTACT_DBA_START';
export const UPDATE_CONTACT_DBA_SUCCESS = 'UPDATE_CONTACT_DBA_SUCCESS';
export const UPDATE_CONTACT_DBA_FAIL = 'UPDATE_CONTACT_DBA_FAIL';

export const DELETE_CONTACT_DBA = 'DELETE_CONTACT_DBA';
export const DELETE_CONTACT_DBA_START = 'DELETE_CONTACT_DBA_START';
export const DELETE_CONTACT_DBA_SUCCESS = 'DELETE_CONTACT_DBA_SUCCESS';
export const DELETE_CONTACT_DBA_FAIL = 'DELETE_CONTACT_DBA_FAIL';

export const GET_CONTACT_COMPANIES = 'GET_CONTACT_COMPANIES';
export const GET_CONTACT_COMPANIES_START = 'GET_CONTACT_COMPANIES_START';
export const GET_CONTACT_COMPANIES_SUCCESS = 'GET_CONTACT_COMPANIES_SUCCESS';
export const GET_CONTACT_COMPANIES_FAIL = 'GET_CONTACT_COMPANIES_FAIL';

/*
  PROPERTIES
*/

export const GET_PROPERTIES = 'GET_PROPERTIES';
export const GET_PROPERTIES_START = 'GET_PROPERTIES_START';
export const GET_PROPERTIES_SUCCESS = 'GET_PROPERTIES_SUCCESS';
export const GET_PROPERTIES_FAIL = 'GET_PROPERTIES_FAIL';

export const GET_PROPERTY = 'GET_PROPERTY';
export const GET_PROPERTY_START = 'GET_PROPERTY_START';
export const GET_PROPERTY_SUCCESS = 'GET_PROPERTY_SUCCESS';
export const GET_PROPERTY_FAIL = 'GET_PROPERTY_FAIL';

export const GET_PROPERTY_CONTACTS = 'GET_PROPERTY_CONTACTS';
export const GET_PROPERTY_CONTACTS_START = 'GET_PROPERTY_CONTACTS_START';
export const GET_PROPERTY_CONTACTS_SUCCESS = 'GET_PROPERTY_CONTACTS_SUCCESS';
export const GET_PROPERTY_CONTACTS_FAIL = 'GET_PROPERTY_CONTACTS_FAIL';

export const GET_PROPERTY_NAMES = 'GET_PROPERTY_NAMES';
export const GET_PROPERTY_NAMES_START = 'GET_PROPERTY_NAMES_START';
export const GET_PROPERTY_NAMES_SUCCESS = 'GET_PROPERTY_NAMES_SUCCESS';
export const GET_PROPERTY_NAMES_FAIL = 'GET_PROPERTY_NAMES_FAIL';

export const CREATE_PROPERTY = 'CREATE_PROPERTY';
export const CREATE_PROPERTY_START = 'CREATE_PROPERTY_START';
export const CREATE_PROPERTY_SUCCESS = 'CREATE_PROPERTY_SUCCESS';
export const CREATE_PROPERTY_FAIL = 'CREATE_PROPERTY_FAIL';

export const DELETE_PROPERTY = 'DELETE_PROPERTY';
export const DELETE_PROPERTY_START = 'DELETE_PROPERTY_START';
export const DELETE_PROPERTY_SUCCESS = 'DELETE_PROPERTY_SUCCESS';
export const DELETE_PROPERTY_FAIL = 'DELETE_PROPERTY_FAIL';

export const UPDATE_PROPERTY = 'UPDATE_PROPERTY';
export const UPDATE_PROPERTY_START = 'UPDATE_PROPERTY_START';
export const UPDATE_PROPERTY_SUCCESS = 'UPDATE_PROPERTY_SUCCESS';
export const UPDATE_PROPERTY_FAIL = 'UPDATE_PROPERTY_FAIL';

export const GET_PROPERTY_SHARES = 'GET_PROPERTY_SHARES';
export const GET_PROPERTY_SHARES_START = 'GET_PROPERTY_SHARES_START';
export const GET_PROPERTY_SHARES_SUCCESS = 'GET_PROPERTY_SHARES_SUCCESS';
export const GET_PROPERTY_SHARES_FAIL = 'GET_PROPERTY_SHARES_FAIL';

export const CREATE_PROPERTY_SHARE = 'CREATE_PROPERTY_SHARE';
export const CREATE_PROPERTY_SHARE_START = 'CREATE_PROPERTY_SHARE_START';
export const CREATE_PROPERTY_SHARE_SUCCESS = 'CREATE_PROPERTY_SHARE_SUCCESS';
export const CREATE_PROPERTY_SHARE_FAIL = 'CREATE_PROPERTY_SHARE_FAIL';

export const DELETE_PROPERTY_SHARE = 'DELETE_PROPERTY_SHARE';
export const DELETE_PROPERTY_SHARE_START = 'DELETE_PROPERTY_SHARE_START';
export const DELETE_PROPERTY_SHARE_SUCCESS = 'DELETE_PROPERTY_SHARE_SUCCESS';
export const DELETE_PROPERTY_SHARE_FAIL = 'DELETE_PROPERTY_SHARE_FAIL';

export const CHECK_PROPERTY_EXISTING_ADDRESS =
	'CHECK_PROPERTY_EXISTING_ADDRESS';
export const CHECK_PROPERTY_EXISTING_ADDRESS_START =
	'CHECK_PROPERTY_EXISTING_ADDRESS_START';
export const CHECK_PROPERTY_EXISTING_ADDRESS_SUCCESS =
	'CHECK_PROPERTY_EXISTING_ADDRESS_SUCCESS';
export const CHECK_PROPERTY_EXISTING_ADDRESS_FAIL =
	'CHECK_PROPERTY_EXISTING_ADDRESS_FAIL';

export const GET_PROPERTY_CHANGES = 'GET_PROPERTY_CHANGES';
export const GET_PROPERTY_CHANGES_START = 'GET_PROPERTY_CHANGES_START';
export const GET_PROPERTY_CHANGES_SUCCESS = 'GET_PROPERTY_CHANGES_SUCCESS';
export const GET_PROPERTY_CHANGES_FAIL = 'GET_PROPERTY_CHANGES_FAIL';

export const SAVE_PROPERTY_CHANGES = 'SAVE_PROPERTY_CHANGES';
export const SAVE_PROPERTY_CHANGES_START = 'SAVE_PROPERTY_CHANGES_START';
export const SAVE_PROPERTY_CHANGES_SUCCESS = 'SAVE_PROPERTY_CHANGES_SUCCESS';
export const SAVE_PROPERTY_CHANGES_FAIL = 'SAVE_PROPERTY_CHANGES_FAIL';

export const SET_PROPERTY_CHANGES = 'SET_PROPERTY_CHANGES';

export const IMPORT_PROPERTIES = 'IMPORT_PROPERTIES';
export const IMPORT_PROPERTIES_START = 'IMPORT_PROPERTIES_START';
export const IMPORT_PROPERTIES_SUCCESS = 'IMPORT_PROPERTIES_SUCCESS';
export const IMPORT_PROPERTIES_FAIL = 'IMPORT_PROPERTIES_FAIL';
export const SET_IMPORT_PROPERTIES = 'SET_IMPORT_PROPERTIES';

export const IMPORT_COSTAR_PROPERTIES = 'IMPORT_COSTAR_PROPERTIES';
export const IMPORT_COSTAR_PROPERTIES_START = 'IMPORT_COSTAR_PROPERTIES_START';
export const IMPORT_COSTAR_PROPERTIES_SUCCESS = 'IMPORT_COSTAR_PROPERTIES_SUCCESS';
export const IMPORT_COSTAR_PROPERTIES_FAIL = 'IMPORT_COSTAR_PROPERTIES_FAIL';

export const CREATE_SEND_TO_MARKETING = 'CREATE_SEND_TO_MARKETING';
export const CREATE_SEND_TO_MARKETING_START = 'CREATE_SEND_TO_MARKETING_START';
export const CREATE_SEND_TO_MARKETING_SUCCESS = 'CREATE_SEND_TO_MARKETING_SUCCESS';
export const CREATE_SEND_TO_MARKETING_FAIL = 'CREATE_SEND_TO_MARKETING_FAIL';

export const GET_PROPERTY_DOCUMENTS = 'GET_PROPERTY_DOCUMENTS';
export const GET_PROPERTY_DOCUMENTS_START = 'GET_PROPERTY_DOCUMENTS_START';
export const GET_PROPERTY_DOCUMENTS_SUCCESS = 'GET_PROPERTY_DOCUMENTS_SUCCESS';
export const GET_PROPERTY_DOCUMENTS_FAIL = 'GET_PROPERTY_DOCUMENTS_FAIL';

export const CHECK_ILLI_NUMBER_FOR_PROPERTY = 'CHECK_ILLI_NUMBER_FOR_PROPERTY';
export const CHECK_ILLI_NUMBER_FOR_PROPERTY_START = 'CHECK_ILLI_NUMBER_FOR_PROPERTY_START';
export const CHECK_ILLI_NUMBER_FOR_PROPERTY_SUCCESS = 'CHECK_ILLI_NUMBER_FOR_PROPERTY_SUCCESS';
export const CHECK_ILLI_NUMBER_FOR_PROPERTY_FAIL = 'CHECK_ILLI_NUMBER_FOR_PROPERTY_FAIL';

export const GET_PROPERTY_FILES = 'GET_PROPERTY_FILES';
export const GET_PROPERTY_FILES_START = 'GET_PROPERTY_FILES_START';
export const GET_PROPERTY_FILES_SUCCESS = 'GET_PROPERTY_FILES_SUCCESS';
export const GET_PROPERTY_FILES_FAIL = 'GET_PROPERTY_FILES_FAIL';

/*
COMPANIES
*/

export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_START = 'GET_COMPANIES_START';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAIL = 'GET_COMPANIES_FAIL';

export const GET_COMPANY = 'GET_COMPANY';
export const GET_COMPANY_START = 'GET_COMPANY_START';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_FAIL = 'GET_COMPANY_FAIL';

export const CREATE_COMPANY = 'CREATE_COMPANY';
export const CREATE_COMPANY_START = 'CREATE_COMPANY_START';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAIL = 'CREATE_COMPANY_FAIL';

export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const UPDATE_COMPANY_START = 'UPDATE_COMPANY_START';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAIL = 'UPDATE_COMPANY_FAIL';

export const DELETE_COMPANY = 'DELETE_COMPANY';
export const DELETE_COMPANY_START = 'DELETE_COMPANY_START';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAIL = 'DELETE_COMPANY_FAIL';

export const RESET_COMPANY_CONTACTS = 'RESET_COMPANY_CONTACTS';

export const GET_COMPANY_CONTACTS = 'GET_COMPANY_CONTACTS';
export const GET_COMPANY_CONTACTS_START = 'GET_COMPANY_CONTACTS_START';
export const GET_COMPANY_CONTACTS_SUCCESS = 'GET_COMPANY_CONTACTS_SUCCESS';
export const GET_COMPANY_CONTACTS_FAIL = 'GET_COMPANY_CONTACTS_FAIL';

export const CREATE_COMPANY_CONTACTS = 'CREATE_COMPANY_CONTACTS';
export const CREATE_COMPANY_CONTACTS_START = 'CREATE_COMPANY_CONTACTS_START';
export const CREATE_COMPANY_CONTACTS_SUCCESS =
	'CREATE_COMPANY_CONTACTS_SUCCESS';
export const CREATE_COMPANY_CONTACTS_FAIL = 'CREATE_COMPANY_CONTACTS_FAIL';

export const DELETE_COMPANY_CONTACTS = 'DELETE_COMPANY_CONTACTS';
export const DELETE_COMPANY_CONTACTS_START = 'DELETE_COMPANY_CONTACTS_START';
export const DELETE_COMPANY_CONTACTS_SUCCESS =
	'DELETE_COMPANY_CONTACTS_SUCCESS';
export const DELETE_COMPANY_CONTACTS_FAIL = 'DELETE_COMPANY_CONTACTS_FAIL';

export const UPDATE_COMPANY_CONTACTS = 'UPDATE_COMPANY_CONTACTS';
export const UPDATE_COMPANY_CONTACTS_START = 'UPDATE_COMPANY_CONTACTS_START';
export const UPDATE_COMPANY_CONTACTS_SUCCESS =
	'UPDATE_COMPANY_CONTACTS_SUCCESS';
export const UPDATE_COMPANY_CONTACTS_FAIL = 'UPDATE_COMPANY_CONTACTS_FAIL';

export const GET_COMPANY_USERNAMES = 'GET_COMPANY_USERNAMES';
export const GET_COMPANY_USERNAMES_START = 'GET_COMPANY_USERNAMES_START';
export const GET_COMPANY_USERNAMES_SUCCESS = 'GET_COMPANY_USERNAMES_SUCCESS';
export const GET_COMPANY_USERNAMES_FAIL = 'GET_COMPANY_USERNAMES_FAIL';

export const GET_COMPANY_SHARES = 'GET_COMPANY_SHARES';
export const GET_COMPANY_SHARES_START = 'GET_COMPANY_SHARES_START';
export const GET_COMPANY_SHARES_SUCCESS = 'GET_COMPANY_SHARES_SUCCESS';
export const GET_COMPANY_SHARES_FAIL = 'GET_COMPANY_SHARES_FAIL';

export const CREATE_COMPANY_SHARE = 'CREATE_COMPANY_SHARE';
export const CREATE_COMPANY_SHARE_START = 'CREATE_COMPANY_SHARE_START';
export const CREATE_COMPANY_SHARE_SUCCESS = 'CREATE_COMPANY_SHARE_SUCCESS';
export const CREATE_COMPANY_SHARE_FAIL = 'CREATE_COMPANY_SHARE_FAIL';

export const DELETE_COMPANY_SHARE = 'DELETE_COMPANY_SHARE';
export const DELETE_COMPANY_SHARE_START = 'DELETE_COMPANY_SHARE_START';
export const DELETE_COMPANY_SHARE_SUCCESS = 'DELETE_COMPANY_SHARE_SUCCESS';
export const DELETE_COMPANY_SHARE_FAIL = 'DELETE_COMPANY_SHARE_FAIL';

export const GET_COMPANY_PROPERTIES = 'GET_COMPANY_PROPERTIES';
export const GET_COMPANY_PROPERTIES_START = 'GET_COMPANY_PROPERTIES_START';
export const GET_COMPANY_PROPERTIES_SUCCESS = 'GET_COMPANY_PROPERTIES_SUCCESS';
export const GET_COMPANY_PROPERTIES_FAIL = 'GET_COMPANY_PROPERTIES_FAIL';

export const CREATE_COMPANY_PROPERTIES = 'CREATE_COMPANY_PROPERTIES';
export const CREATE_COMPANY_PROPERTIES_START =
	'CREATE_COMPANY_PROPERTIES_START';
export const CREATE_COMPANY_PROPERTIES_SUCCESS =
	'CREATE_COMPANY_PROPERTIES_SUCCESS';
export const CREATE_COMPANY_PROPERTIES_FAIL = 'CREATE_COMPANY_PROPERTIES_FAIL';

export const DELETE_COMPANY_PROPERTIES = 'DELETE_COMPANY_PROPERTIES';
export const DELETE_COMPANY_PROPERTIES_START =
	'DELETE_COMPANY_PROPERTIES_START';
export const DELETE_COMPANY_PROPERTIES_SUCCESS =
	'DELETE_COMPANY_PROPERTIES_SUCCESS';
export const DELETE_COMPANY_PROPERTIES_FAIL = 'DELETE_COMPANY_PROPERTIES_FAIL';

export const GET_COMPANY_CHANGES = 'GET_COMPANY_CHANGES';
export const GET_COMPANY_CHANGES_START = 'GET_COMPANY_CHANGES_START';
export const GET_COMPANY_CHANGES_SUCCESS = 'GET_COMPANY_CHANGES_SUCCESS';
export const GET_COMPANY_CHANGES_FAIL = 'GET_COMPANY_CHANGES_FAIL';

export const SAVE_COMPANY_CHANGES = 'SAVE_COMPANY_CHANGES';
export const SAVE_COMPANY_CHANGES_START = 'SAVE_COMPANY_CHANGES_START';
export const SAVE_COMPANY_CHANGES_SUCCESS = 'SAVE_COMPANY_CHANGES_SUCCESS';
export const SAVE_COMPANY_CHANGES_FAIL = 'SAVE_COMPANY_CHANGES_FAIL';

export const SET_COMPANY_CHANGES = 'SET_COMPANY_CHANGES';
export const SET_COMPANY_CHANGES_START = 'SET_COMPANY_CHANGES_START';
export const SET_COMPANY_CHANGES_SUCCESS = 'SET_COMPANY_CHANGES_SUCCESS';
export const SET_COMPANY_CHANGES_FAIL = 'SET_COMPANY_CHANGES_FAIL';

export const GET_COMPANY_ENTITIES = 'GET_COMPANY_ENTITIES';
export const GET_COMPANY_ENTITIES_START = 'GET_COMPANY_ENTITIES_START';
export const GET_COMPANY_ENTITIES_SUCCESS = 'GET_COMPANY_ENTITIES_SUCCESS';
export const GET_COMPANY_ENTITIES_FAIL = 'GET_COMPANY_ENTITIES_FAIL';

export const CREATE_COMPANY_ENTITY = 'CREATE_COMPANY_ENTITY';
export const CREATE_COMPANY_ENTITY_START = 'CREATE_COMPANY_ENTITY_START';
export const CREATE_COMPANY_ENTITY_SUCCESS = 'CREATE_COMPANY_ENTITY_SUCCESS';
export const CREATE_COMPANY_ENTITY_FAIL = 'CREATE_COMPANY_ENTITY_FAIL';

export const DELETE_COMPANY_ENTITY = 'DELETE_COMPANY_ENTITY';
export const DELETE_COMPANY_ENTITY_START = 'DELETE_COMPANY_ENTITY_START';
export const DELETE_COMPANY_ENTITY_SUCCESS = 'DELETE_COMPANY_ENTITY_SUCCESS';
export const DELETE_COMPANY_ENTITY_FAIL = 'DELETE_COMPANY_ENTITY_FAIL';

export const GET_UPDATED_COMPANY_ENTITY = 'GET_UPDATED_COMPANY_ENTITY';
export const GET_UPDATED_COMPANY_ENTITY_START = 'GET_UPDATED_COMPANY_ENTITY_START';
export const GET_UPDATED_COMPANY_ENTITY_SUCCESS = 'GET_UPDATED_COMPANY_ENTITY_SUCCESS';
export const GET_UPDATED_COMPANY_ENTITY_FAIL = 'GET_UPDATED_COMPANY_ENTITY_FAIL';

export const GET_COMPANY_DBAS = 'GET_COMPANY_DBAS';
export const GET_COMPANY_DBAS_START = 'GET_COMPANY_DBAS_START';
export const GET_COMPANY_DBAS_SUCCESS = 'GET_COMPANY_DBAS_SUCCESS';
export const GET_COMPANY_DBAS_FAIL = 'GET_COMPANY_DBAS_FAIL';

export const CREATE_COMPANY_DBA = 'CREATE_COMPANY_DBA';
export const CREATE_COMPANY_DBA_START = 'CREATE_COMPANY_DBA_START';
export const CREATE_COMPANY_DBA_SUCCESS = 'CREATE_COMPANY_DBA_SUCCESS';
export const CREATE_COMPANY_DBA_FAIL = 'CREATE_COMPANY_DBA_FAIL';

export const UPDATE_COMPANY_DBA = 'UPDATE_COMPANY_DBA';
export const UPDATE_COMPANY_DBA_START = 'UPDATE_COMPANY_DBA_START';
export const UPDATE_COMPANY_DBA_SUCCESS = 'UPDATE_COMPANY_DBA_SUCCESS';
export const UPDATE_COMPANY_DBA_FAIL = 'UPDATE_COMPANY_DBA_FAIL';

export const DELETE_COMPANY_DBA = 'DELETE_COMPANY_DBA';
export const DELETE_COMPANY_DBA_START = 'DELETE_COMPANY_DBA_START';
export const DELETE_COMPANY_DBA_SUCCESS = 'DELETE_COMPANY_DBA_SUCCESS';
export const DELETE_COMPANY_DBA_FAIL = 'DELETE_COMPANY_DBA_FAIL';

export const GET_COMPANY_COMPANIES = 'GET_COMPANY_COMPANIES';
export const GET_COMPANY_COMPANIES_START = 'GET_COMPANY_COMPANIES_START';
export const GET_COMPANY_COMPANIES_SUCCESS = 'GET_COMPANY_COMPANIES_SUCCESS';
export const GET_COMPANY_COMPANIES_FAIL = 'GET_COMPANY_COMPANIES_FAIL';

export const CREATE_COMPANY_COMPANY = 'CREATE_COMPANY_COMPANY';
export const CREATE_COMPANY_COMPANY_START = 'CREATE_COMPANY_COMPANY_START';
export const CREATE_COMPANY_COMPANY_SUCCESS = 'CREATE_COMPANY_COMPANY_SUCCESS';
export const CREATE_COMPANY_COMPANY_FAIL = 'CREATE_COMPANY_COMPANY_FAIL';

export const UPDATE_COMPANY_COMPANY = 'UPDATE_COMPANY_COMPANY';
export const UPDATE_COMPANY_COMPANY_START = 'UPDATE_COMPANY_COMPANY_START';
export const UPDATE_COMPANY_COMPANY_SUCCESS = 'UPDATE_COMPANY_COMPANY_SUCCESS';
export const UPDATE_COMPANY_COMPANY_FAIL = 'UPDATE_COMPANY_COMPANY_FAIL';

export const DELETE_COMPANY_COMPANY = 'DELETE_COMPANY_COMPANY';
export const DELETE_COMPANY_COMPANY_START = 'DELETE_COMPANY_COMPANY_START';
export const DELETE_COMPANY_COMPANY_SUCCESS = 'DELETE_COMPANY_COMPANY_SUCCESS';
export const DELETE_COMPANY_COMPANY_FAIL = 'DELETE_COMPANY_COMPANY_FAIL';

/*
  LEASES
*/

export const GET_LEASES = 'GET_LEASES';
export const GET_LEASES_START = 'GET_LEASES_START';
export const GET_LEASES_SUCCESS = 'GET_LEASES_SUCCESS';
export const GET_LEASES_FAIL = 'GET_LEASES_FAIL';

export const GET_LEASE = 'GET_LEASE';
export const GET_LEASE_START = 'GET_LEASE_START';
export const GET_LEASE_SUCCESS = 'GET_LEASE_SUCCESS';
export const GET_LEASE_FAIL = 'GET_LEASE_FAIL';

export const CREATE_LEASE = 'CREATE_LEASE';
export const CREATE_LEASE_START = 'CREATE_LEASE_START';
export const CREATE_LEASE_SUCCESS = 'CREATE_LEASE_SUCCESS';
export const CREATE_LEASE_FAIL = 'CREATE_LEASE_FAIL';

export const UPDATE_LEASE = 'UPDATE_LEASE';
export const UPDATE_LEASE_START = 'UPDATE_LEASE_START';
export const UPDATE_LEASE_SUCCESS = 'UPDATE_LEASE_SUCCESS';
export const UPDATE_LEASE_FAIL = 'UPDATE_LEASE_FAIL';

export const DELETE_LEASE = 'DELETE_LEASE';
export const DELETE_LEASE_START = 'DELETE_LEASE_START';
export const DELETE_LEASE_SUCCESS = 'DELETE_LEASE_SUCCESS';
export const DELETE_LEASE_FAIL = 'DELETE_LEASE_FAIL';

/*
  LISTINGS
*/

export const GET_LISTINGS = 'GET_LISTINGS';
export const GET_LISTINGS_START = 'GET_LISTINGS_START';
export const GET_LISTINGS_SUCCESS = 'GET_LISTINGS_SUCCESS';
export const GET_LISTINGS_FAIL = 'GET_LISTINGS_FAIL';

export const GET_LISTING = 'GET_LISTING';
export const GET_LISTING_START = 'GET_LISTING_START';
export const GET_LISTING_SUCCESS = 'GET_LISTING_SUCCESS';
export const GET_LISTING_FAIL = 'GET_LISTING_FAIL';

export const CREATE_LISTING = 'CREATE_LISTING';
export const CREATE_LISTING_START = 'CREATE_LISTING_START';
export const CREATE_LISTING_SUCCESS = 'CREATE_LISTING_SUCCESS';
export const CREATE_LISTING_FAIL = 'CREATE_LISTING_FAIL';

export const UPDATE_LISTING = 'UPDATE_LISTING';
export const UPDATE_LISTING_START = 'UPDATE_LISTING_START';
export const UPDATE_LISTING_SUCCESS = 'UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_FAIL = 'UPDATE_LISTING_FAIL';

export const DELETE_LISTING = 'DELETE_LISTING';
export const DELETE_LISTING_START = 'DELETE_LISTING_START';
export const DELETE_LISTING_SUCCESS = 'DELETE_LISTING_SUCCESS';
export const DELETE_LISTING_FAIL = 'DELETE_LISTING_FAIL';

export const GET_LISTING_ENTRY = 'GET_LISTING_ENTRY';
export const GET_LISTING_ENTRY_START = 'GET_LISTING_ENTRY_START';
export const GET_LISTING_ENTRY_SUCCESS = 'GET_LISTING_ENTRY_SUCCESS';
export const GET_LISTING_ENTRY_FAIL = 'GET_LISTING_ENTRY_FAIL';


/*
  MEETINGS
*/

export const GET_MEETINGS = 'GET_MEETINGS';
export const GET_MEETINGS_START = 'GET_MEETINGS_START';
export const GET_MEETINGS_SUCCESS = 'GET_MEETINGS_SUCCESS';
export const GET_MEETINGS_FAIL = 'GET_MEETINGS_FAIL';

export const GET_MEETING = 'GET_MEETING';
export const GET_MEETING_START = 'GET_MEETING_START';
export const GET_MEETING_SUCCESS = 'GET_MEETING_SUCCESS';
export const GET_MEETING_FAIL = 'GET_MEETING_FAIL';

export const CREATE_MEETING = 'CREATE_MEETING';
export const CREATE_MEETING_START = 'CREATE_MEETING_START';
export const CREATE_MEETING_SUCCESS = 'CREATE_MEETING_SUCCESS';
export const CREATE_MEETING_FAIL = 'CREATE_MEETING_FAIL';

export const UPDATE_MEETING = 'UPDATE_MEETING';
export const UPDATE_MEETING_START = 'UPDATE_MEETING_START';
export const UPDATE_MEETING_SUCCESS = 'UPDATE_MEETING_SUCCESS';
export const UPDATE_MEETING_FAIL = 'UPDATE_MEETING_FAIL';

export const DELETE_MEETING = 'DELETE_MEETING';
export const DELETE_MEETING_START = 'DELETE_MEETING_START';
export const DELETE_MEETING_SUCCESS = 'DELETE_MEETING_SUCCESS';
export const DELETE_MEETING_FAIL = 'DELETE_MEETING_FAIL';

/*
  OFFERS
*/

export const GET_OFFERS = 'GET_OFFERS';
export const GET_OFFERS_START = 'GET_OFFERS_START';
export const GET_OFFERS_SUCCESS = 'GET_OFFERS_SUCCESS';
export const GET_OFFERS_FAIL = 'GET_OFFERS_FAIL';

export const GET_OFFER = 'GET_OFFER';
export const GET_OFFER_START = 'GET_OFFER_START';
export const GET_OFFER_SUCCESS = 'GET_OFFER_SUCCESS';
export const GET_OFFER_FAIL = 'GET_OFFER_FAIL';

export const CREATE_OFFER = 'CREATE_OFFER';
export const CREATE_OFFER_START = 'CREATE_OFFER_START';
export const CREATE_OFFER_SUCCESS = 'CREATE_OFFER_SUCCESS';
export const CREATE_OFFER_FAIL = 'CREATE_OFFER_FAIL';

export const UPDATE_OFFER = 'UPDATE_OFFER';
export const UPDATE_OFFER_START = 'UPDATE_OFFER_START';
export const UPDATE_OFFER_SUCCESS = 'UPDATE_OFFER_SUCCESS';
export const UPDATE_OFFER_FAIL = 'UPDATE_OFFER_FAIL';

export const DELETE_OFFER = 'DELETE_OFFER';
export const DELETE_OFFER_START = 'DELETE_OFFER_START';
export const DELETE_OFFER_SUCCESS = 'DELETE_OFFER_SUCCESS';
export const DELETE_OFFER_FAIL = 'DELETE_OFFER_FAIL';

/*
  SHOWINGS
*/

export const GET_SHOWINGS = 'GET_SHOWINGS';
export const GET_SHOWINGS_START = 'GET_SHOWINGS_START';
export const GET_SHOWINGS_SUCCESS = 'GET_SHOWINGS_SUCCESS';
export const GET_SHOWINGS_FAIL = 'GET_SHOWINGS_FAIL';

export const GET_SHOWING = 'GET_SHOWING';
export const GET_SHOWING_START = 'GET_SHOWING_START';
export const GET_SHOWING_SUCCESS = 'GET_SHOWING_SUCCESS';
export const GET_SHOWING_FAIL = 'GET_SHOWING_FAIL';

export const CREATE_SHOWING = 'CREATE_SHOWING';
export const CREATE_SHOWING_START = 'CREATE_SHOWING_START';
export const CREATE_SHOWING_SUCCESS = 'CREATE_SHOWING_SUCCESS';
export const CREATE_SHOWING_FAIL = 'CREATE_SHOWING_FAIL';

export const DELETE_SHOWING = 'DELETE_SHOWING';
export const DELETE_SHOWING_START = 'DELETE_SHOWING_START';
export const DELETE_SHOWING_SUCCESS = 'DELETE_SHOWING_SUCCESS';
export const DELETE_SHOWING_FAIL = 'DELETE_SHOWING_FAIL';

export const UPDATE_SHOWING = 'UPDATE_SHOWING';
export const UPDATE_SHOWING_START = 'UPDATE_SHOWING_START';
export const UPDATE_SHOWING_SUCCESS = 'UPDATE_SHOWING_SUCCESS';
export const UPDATE_SHOWING_FAIL = 'UPDATE_SHOWING_FAIL';

/*
  SUBPROPERTIES
*/

export const GET_SUB_PROPERTIES = 'GET_SUB_PROPERTIES';
export const GET_SUB_PROPERTIES_START = 'GET_SUB_PROPERTIES_START';
export const GET_SUB_PROPERTIES_SUCCESS = 'GET_SUB_PROPERTIES_SUCCESS';
export const GET_SUB_PROPERTIES_FAIL = 'GET_SUB_PROPERTIES_FAIL';

/*
  ERTL
*/

export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_TEMPLATES_START = 'GET_TEMPLATES_START';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_FAIL = 'GET_TEMPLATES_FAIL';

export const GET_TEMPLATE = 'GET_TEMPLATE';
export const GET_TEMPLATE_START = 'GET_TEMPLATE_START';
export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS';
export const GET_TEMPLATE_FAIL = 'GET_TEMPLATE_FAIL';

export const CREATE_TEMPLATE = 'CREATE_TEMPLATE';
export const CREATE_TEMPLATE_START = 'CREATE_TEMPLATE_START';
export const CREATE_TEMPLATE_SUCCESS = 'CREATE_TEMPLATE_SUCCESS';
export const CREATE_TEMPLATE_FAIL = 'CREATE_TEMPLATE_FAIL';

export const UPDATE_ERTL_TEMPLATE = 'UPDATE_ERTL_TEMPLATE';
export const UPDATE_ERTL_TEMPLATE_START = 'UPDATE_ERTL_TEMPLATE_START';
export const UPDATE_ERTL_TEMPLATE_SUCCESS = 'UPDATE_ERTL_TEMPLATE_SUCCESS';
export const UPDATE_ERTL_TEMPLATE_FAIL = 'UPDATE_ERTL_TEMPLATE_FAIL';

export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const DELETE_TEMPLATE_START = 'DELETE_TEMPLATE_START';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_FAIL = 'DELETE_TEMPLATE_FAIL';

export const GET_ENTRIES = 'GET_ENTRIES';
export const GET_ENTRIES_START = 'GET_ENTRIES_START';
export const GET_ENTRIES_SUCCESS = 'GET_ENTRIES_SUCCESS';
export const GET_ENTRIES_FAIL = 'GET_ENTRIES_FAIL';

export const GET_ENTRIES_BY_STATUS = 'GET_ENTRIES_BY_STATUS';
export const GET_ENTRIES_BY_STATUS_START = 'GET_ENTRIES_BY_STATUS_START';
export const GET_ENTRIES_BY_STATUS_SUCCESS = 'GET_ENTRIES_BY_STATUS_SUCCESS';
export const GET_ENTRIES_BY_STATUS_FAIL = 'GET_ENTRIES_BY_STATUS_FAIL';

export const CREATE_ENTRY = 'CREATE_ENTRY';
export const CREATE_ENTRY_START = 'CREATE_ENTRY_START';
export const CREATE_ENTRY_SUCCESS = 'CREATE_ENTRY_SUCCESS';
export const CREATE_ENTRY_FAIL = 'CREATE_ENTRY_FAIL';

export const GET_ENTRY_PDF = 'GET_ENTRY_PDF';
export const GET_ENTRY_PDF_START = 'GET_ENTRY_PDF_START';
export const GET_ENTRY_PDF_SUCCESS = 'GET_ENTRY_PDF_SUCCESS';
export const GET_ENTRY_PDF_FAIL = 'GET_ENTRY_PDF_FAIL';

export const UPDATE_ENTRY_STATUS = 'UPDATE_ENTRY_STATUS';
export const UPDATE_ENTRY_STATUS_START = 'UPDATE_ENTRY_STATUS_START';
export const UPDATE_ENTRY_STATUS_SUCCESS = 'UPDATE_ENTRY_STATUS_SUCCESS';
export const UPDATE_ENTRY_STATUS_FAIL = 'UPDATE_ENTRY_STATUS_FAIL';

export const UPDATE_ERTL = 'UPDATE_ERTL';
export const UPDATE_ERTL_START = 'UPDATE_ERTL_START';
export const UPDATE_ERTL_SUCCESS = 'UPDATE_ERTL_SUCCESS';
export const UPDATE_ERTL_FAIL = 'UPDATE_ERTL_FAIL';

export const DELETE_ERTL_ENTRY = 'DELETE_ERTL_ENTRY';
export const DELETE_ERTL_ENTRY_START = 'DELETE_ERTL_ENTRY_START';
export const DELETE_ERTL_ENTRY_SUCCESS = 'DELETE_ERTL_ENTRY_SUCCESS';
export const DELETE_ERTL_ENTRY_FAIL = 'DELETE_ERTL_ENTRY_FAIL';

export const GET_ERTL_HISTORY = 'GET_ERTL_HISTORY';
export const GET_ERTL_HISTORY_START = 'GET_ERTL_HISTORY_START';
export const GET_ERTL_HISTORY_SUCCESS = 'GET_ERTL_HISTORY_SUCCESS';
export const GET_ERTL_HISTORY_FAIL = 'GET_ERTL_HISTORY_FAIL';

export const GET_ERTL_PDF = 'GET_ERTL_PDF';
export const GET_ERTL_PDF_START = 'GET_ERTL_PDF_START';
export const GET_ERTL_PDF_SUCCESS = 'GET_ERTL_PDF_SUCCESS';
export const GET_ERTL_PDF_FAIL = 'GET_ERTL_PDF_FAIL';

export const GET_ERTL_HISTORY_ENTRY = 'GET_ERTL_HISTORY_ENTRY';
export const GET_ERTL_HISTORY_ENTRY_START = 'GET_ERTL_HISTORY_ENTRY_START';
export const GET_ERTL_HISTORY_ENTRY_SUCCESS = 'GET_ERTL_HISTORY_ENTRY_SUCCESS';
export const GET_ERTL_HISTORY_ENTRY_FAIL = 'GET_ERTL_HISTORY_ENTRY_FAIL';

export const GET_ERTL_URL = 'GET_ERTL_URL';
export const GET_ERTL_URL_START = 'GET_ERTL_URL_START';
export const GET_ERTL_URL_SUCCESS = 'GET_ERTL_URL_SUCCESS';
export const GET_ERTL_URL_FAIL = 'GET_ERTL_URL_FAIL';

export const GET_ERTL_ENTRY = 'GET_ERTL_ENTRY';
export const GET_ERTL_ENTRY_START = 'GET_ERTL_ENTRY_START';
export const GET_ERTL_ENTRY_SUCCESS = 'GET_ERTL_ENTRY_SUCCESS';
export const GET_ERTL_ENTRY_FAIL = 'GET_ERTL_ENTRY_FAIL';

export const GET_ERTL_APPROVERS = 'GET_ERTL_APPROVERS';
export const GET_ERTL_APPROVERS_START = 'GET_ERTL_APPROVERS_START';
export const GET_ERTL_APPROVERS_SUCCESS = 'GET_ERTL_APPROVERS_SUCCESS';
export const GET_ERTL_APPROVERS_FAIL = 'GET_ERTL_APPROVERS_FAIL';

/*
  APPOINTMENTS
*/

export const GET_CALENDARS = 'GET_CALENDARS';
export const GET_CALENDARS_START = 'GET_CALENDARS_START';
export const GET_CALENDARS_SUCCESS = 'GET_CALENDARS_SUCCESS';
export const GET_CALENDARS_FAIL = 'GET_CALENDARS_FAIL';

export const UPDATE_DEFAULT_CALENDAR = 'UPDATE_DEFAULT_CALENDAR';
export const UPDATE_DEFAULT_CALENDAR_START = 'UPDATE_DEFAULT_CALENDAR_START';
export const UPDATE_DEFAULT_CALENDAR_SUCCESS = 'UPDATE_DEFAULT_CALENDAR_SUCCESS';
export const UPDATE_DEFAULT_CALENDAR_FAIL = 'UPDATE_DEFAULT_CALENDAR_FAIL';

export const GET_APPOINTMENTS = 'GET_APPOINTMENTS';
export const GET_APPOINTMENTS_START =
	'GET_APPOINTMENTS_START';
export const GET_APPOINTMENTS_SUCCESS =
	'GET_APPOINTMENTS_SUCCESS';
export const GET_APPOINTMENTS_FAIL = 'GET_APPOINTMENTS_FAIL';

export const GET_APPOINTMENT = 'GET_APPOINTMENT';
export const GET_APPOINTMENT_START = 'GET_APPOINTMENT_START';
export const GET_APPOINTMENT_SUCCESS =
	'GET_APPOINTMENT_SUCCESS';
export const GET_APPOINTMENT_FAIL = 'GET_APPOINTMENT_FAIL';
export const GET_APPOINTMENT_CLEAR = 'GET_APPOINTMENT_CLEAR';

export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT';
export const CREATE_APPOINTMENT_START =
	'CREATE_APPOINTMENT_START';
export const CREATE_APPOINTMENT_SUCCESS =
	'CREATE_APPOINTMENT_SUCCESS';
export const CREATE_APPOINTMENT_FAIL =
	'CREATE_APPOINTMENT_FAIL';

export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT';
export const UPDATE_APPOINTMENT_START =
	'UPDATE_APPOINTMENT_START';
export const UPDATE_APPOINTMENT_SUCCESS =
	'UPDATE_APPOINTMENT_SUCCESS';
export const UPDATE_APPOINTMENT_FAIL =
	'UPDATE_APPOINTMENT_FAIL';

export const DELETE_APPOINTMENT = 'DELETE_APPOINTMENT';
export const DELETE_APPOINTMENT_START =
	'DELETE_APPOINTMENT_START';
export const DELETE_APPOINTMENT_SUCCESS =
	'DELETE_APPOINTMENT_SUCCESS';
export const DELETE_APPOINTMENT_FAIL =
	'DELETE_APPOINTMENT_FAIL';

/**
  ROUTING SHEETS
*/

export const GET_ROUTING_SHEETS = 'GET_ROUTING_SHEETS';
export const GET_ROUTING_SHEETS_START = 'GET_ROUTING_SHEETS_START';
export const GET_ROUTING_SHEETS_SUCCESS = 'GET_ROUTING_SHEETS_SUCCESS';
export const GET_ROUTING_SHEETS_FAIL = 'GET_ROUTING_SHEETS_FAIL';

export const GET_ROUTING_SHEET = 'GET_ROUTING_SHEET';
export const GET_ROUTING_SHEET_START = 'GET_ROUTING_SHEET_START';
export const GET_ROUTING_SHEET_SUCCESS = 'GET_ROUTING_SHEET_SUCCESS';
export const GET_ROUTING_SHEET_FAIL = 'GET_ROUTING_SHEET_FAIL';
export const GET_ROUTING_SHEET_CLEAR = 'GET_ROUTING_SHEET_CLEAR';

export const CREATE_ROUTING_SHEET = 'CREATE_ROUTING_SHEET';
export const CREATE_ROUTING_SHEET_START = 'CREATE_ROUTING_SHEET_START';
export const CREATE_ROUTING_SHEET_SUCCESS = 'CREATE_ROUTING_SHEET_SUCCESS';
export const CREATE_ROUTING_SHEET_FAIL = 'CREATE_ROUTING_SHEET_FAIL';

export const UPDATE_ROUTING_SHEET = 'UPDATE_ROUTING_SHEET';
export const UPDATE_ROUTING_SHEET_START = 'UPDATE_ROUTING_SHEET_START';
export const UPDATE_ROUTING_SHEET_SUCCESS = 'UPDATE_ROUTING_SHEET_SUCCESS';
export const UPDATE_ROUTING_SHEET_FAIL = 'UPDATE_ROUTING_SHEET_FAIL';

export const DELETE_ROUTING_SHEET = 'DELETE_ROUTING_SHEET';
export const DELETE_ROUTING_SHEET_START = 'DELETE_ROUTING_SHEET_START';
export const DELETE_ROUTING_SHEET_SUCCESS = 'DELETE_ROUTING_SHEET_SUCCESS';
export const DELETE_ROUTING_SHEET_FAIL = 'DELETE_ROUTING_SHEET_FAIL';

export const ROUTING_VERIFY_ADDRESS = 'ROUTING_VERIFY_ADDRESS';
export const ROUTING_VERIFY_ADDRESS_START = 'ROUTING_VERIFY_ADDRESS_START';
export const ROUTING_VERIFY_ADDRESS_SUCCESS = 'ROUTING_VERIFY_ADDRESS_SUCCESS';
export const ROUTING_VERIFY_ADDRESS_FAIL = 'ROUTING_VERIFY_ADDRESS_FAIL';
export const CLEAR_ROUTING_VERIFY_ADDRESS = 'CLEAR_ROUTING_VERIFY_ADDRESS';

export const GET_ROUTING_SHEETS_HISTORY = 'GET_ROUTING_SHEETS_HISTORY';
export const GET_ROUTING_SHEETS_HISTORY_START =
	'GET_ROUTING_SHEETS_HISTORY_START';
export const GET_ROUTING_SHEETS_HISTORY_SUCCESS =
	'GET_ROUTING_SHEETS_HISTORY_SUCCESS';
export const GET_ROUTING_SHEETS_HISTORY_FAIL =
	'GET_ROUTING_SHEETS_HISTORY_FAIL';

export const GET_ROUTING_SHEET_HISTORY = 'GET_ROUTING_SHEET_HISTORY';
export const GET_ROUTING_SHEET_HISTORY_START =
	'GET_ROUTING_SHEET_HISTORY_START';
export const GET_ROUTING_SHEET_HISTORY_SUCCESS =
	'GET_ROUTING_SHEET_HISTORY_SUCCESS';
export const GET_ROUTING_SHEET_HISTORY_FAIL = 'GET_ROUTING_SHEET_HISTORY_FAIL';

export const SET_ROUTING_VIEW = 'SET_ROUTING_VIEW';
export const CLEAR_ROUTING_VIEW = 'CLEAR_ROUTING_VIEW';

export const ROUTING_UPLOAD_FILES = 'ROUTING_UPLOAD_FILES';
export const ROUTING_UPLOAD_FILES_START = 'ROUTING_UPLOAD_FILES_START';
export const ROUTING_UPLOAD_FILES_SUCCESS = 'ROUTING_UPLOAD_FILES_SUCCESS';
export const ROUTING_UPLOAD_FILES_FAIL = 'ROUTING_UPLOAD_FILES_FAIL';

export const GET_RS_HISTORIES = 'GET_RS_HISTORIES';
export const GET_RS_HISTORIES_START = 'GET_RS_HISTORIES_START';
export const GET_RS_HISTORIES_SUCCESS = 'GET_RS_HISTORIES_SUCCESS';
export const GET_RS_HISTORIES_FAIL = 'GET_RS_HISTORIES_FAIL';

export const CREATE_RS_HISTORY = 'CREATE_RS_HISTORY';
export const CREATE_RS_HISTORY_START = 'CREATE_RS_HISTORY_START';
export const CREATE_RS_HISTORY_SUCCESS = 'CREATE_RS_HISTORY_SUCCESS';
export const CREATE_RS_HISTORY_FAIL = 'CREATE_RS_HISTORY_FAIL';

export const UPDATE_RS_HISTORY = 'UPDATE_RS_HISTORY';
export const UPDATE_RS_HISTORY_START = 'UPDATE_RS_HISTORY_START';
export const UPDATE_RS_HISTORY_SUCCESS = 'UPDATE_RS_HISTORY_SUCCESS';
export const UPDATE_RS_HISTORY_FAIL = 'UPDATE_RS_HISTORY_FAIL';

export const DELETE_RS_HISTORY = 'DELETE_RS_HISTORY';
export const DELETE_RS_HISTORY_START = 'DELETE_RS_HISTORY_START';
export const DELETE_RS_HISTORY_SUCCESS = 'DELETE_RS_HISTORY_SUCCESS';
export const DELETE_RS_HISTORY_FAIL = 'DELETE_RS_HISTORY_FAIL';

export const GET_RS_INTERNAL_REVIEWERS = 'GET_RS_INTERNAL_REVIEWERS';
export const GET_RS_INTERNAL_REVIEWERS_START = 'GET_RS_INTERNAL_REVIEWERS_START';
export const GET_RS_INTERNAL_REVIEWERS_SUCCESS = 'GET_RS_INTERNAL_REVIEWERS_SUCCESS';
export const GET_RS_INTERNAL_REVIEWERS_FAIL = 'GET_RS_INTERNAL_REVIEWERS_FAIL';

export const CREATE_RS_INTERNAL_REVIEWER = 'CREATE_RS_INTERNAL_REVIEWER';
export const CREATE_RS_INTERNAL_REVIEWER_START = 'CREATE_RS_INTERNAL_REVIEWER_START';
export const CREATE_RS_INTERNAL_REVIEWER_SUCCESS = 'CREATE_RS_INTERNAL_REVIEWER_SUCCESS';
export const CREATE_RS_INTERNAL_REVIEWER_FAIL = 'CREATE_RS_INTERNAL_REVIEWER_FAIL';

export const UPDATE_RS_INTERNAL_REVIEWER = 'UPDATE_RS_INTERNAL_REVIEWER';
export const UPDATE_RS_INTERNAL_REVIEWER_START = 'UPDATE_RS_INTERNAL_REVIEWER_START';
export const UPDATE_RS_INTERNAL_REVIEWER_SUCCESS = 'UPDATE_RS_INTERNAL_REVIEWER_SUCCESS';
export const UPDATE_RS_INTERNAL_REVIEWER_FAIL = 'UPDATE_RS_INTERNAL_REVIEWER_FAIL';

export const DELETE_RS_INTERNAL_REVIEWER = 'DELETE_RS_INTERNAL_REVIEWER';
export const DELETE_RS_INTERNAL_REVIEWER_START = 'DELETE_RS_INTERNAL_REVIEWER_START';
export const DELETE_RS_INTERNAL_REVIEWER_SUCCESS = 'DELETE_RS_INTERNAL_REVIEWER_SUCCESS';
export const DELETE_RS_INTERNAL_REVIEWER_FAIL = 'DELETE_RS_INTERNAL_REVIEWER_FAIL';

/*
    PMA
*/

export const CLEAR_PMA_MESSAGES = 'CLEAR_PMA_MESSAGES';

export const GET_PMA_ENTRY = 'GET_PMA_ENTRY';
export const GET_PMA_ENTRY_START = 'GET_PMA_ENTRY_START';
export const GET_PMA_ENTRY_SUCCESS = 'GET_PMA_ENTRY_SUCCESS';
export const GET_PMA_ENTRY_FAIL = 'GET_PMA_ENTRY_FAIL';

export const GET_PMA_ENTRIES = 'GET_PMA_ENTRIES';
export const GET_PMA_ENTRIES_START = 'GET_PMA_ENTRIES_START';
export const GET_PMA_ENTRIES_SUCCESS = 'GET_PMA_ENTRIES_SUCCESS';
export const GET_PMA_ENTRIES_FAIL = 'GET_PMA_ENTRIES_FAIL';

export const CREATE_PMA_ENTRY = 'CREATE_PMA_ENTRY';
export const CREATE_PMA_ENTRY_START = 'CREATE_PMA_ENTRY_START';
export const CREATE_PMA_ENTRY_SUCCESS = 'CREATE_PMA_ENTRY_SUCCESS';
export const CREATE_PMA_ENTRY_FAIL = 'CREATE_PMA_ENTRY_FAIL';

export const UPDATE_PMA_ENTRY = 'UPDATE_PMA_ENTRY';
export const UPDATE_PMA_ENTRY_START = 'UPDATE_PMA_ENTRY_START';
export const UPDATE_PMA_ENTRY_SUCCESS = 'UPDATE_PMA_ENTRY_SUCCESS';
export const UPDATE_PMA_ENTRY_FAIL = 'UPDATE_PMA_ENTRY_FAIL';

export const DELETE_PMA_ENTRY = 'DELETE_PMA_ENTRY';
export const DELETE_PMA_ENTRY_START = 'DELETE_PMA_ENTRY_START';
export const DELETE_PMA_ENTRY_SUCCESS = 'DELETE_PMA_ENTRY_SUCCESS';
export const DELETE_PMA_ENTRY_FAIL = 'DELETE_PMA_ENTRY_FAIL';

export const GET_PMA_PDF = 'GET_PMA_PDF';
export const GET_PMA_PDF_START = 'GET_PMA_PDF_START';
export const GET_PMA_PDF_SUCCESS = 'GET_PMA_PDF_SUCCESS';
export const GET_PMA_PDF_FAIL = 'GET_PMA_PDF_FAIL';

export const GET_PMA_HISTORY = 'GET_PMA_HISTORY';
export const GET_PMA_HISTORY_START = 'GET_PMA_HISTORY_START';
export const GET_PMA_HISTORY_SUCCESS = 'GET_PMA_HISTORY_SUCCESS';
export const GET_PMA_HISTORY_FAIL = 'GET_PMA_HISTORY_FAIL';

export const GET_PMA_APPROVERS = 'GET_PMA_APPROVERS';
export const GET_PMA_APPROVERS_START = 'GET_PMA_APPROVERS_START';
export const GET_PMA_APPROVERS_SUCCESS = 'GET_PMA_APPROVERS_SUCCESS';
export const GET_PMA_APPROVERS_FAIL = 'GET_PMA_APPROVERS_FAIL';

export const GET_PMA_SIGNING_URL = 'GET_PMA_SIGNING_URL';
export const GET_PMA_SIGNING_URL_START = 'GET_PMA_SIGNING_URL_START';
export const GET_PMA_SIGNING_URL_SUCCESS = 'GET_PMA_SIGNING_URL_SUCCESS';
export const GET_PMA_SIGNING_URL_FAIL = 'GET_PMA_SIGNING_URL_FAIL';

export const GET_PMA_HISTORY_PDF = 'GET_PMA_HISTORY_PDF';
export const GET_PMA_HISTORY_PDF_START = 'GET_PMA_HISTORY_PDF_START';
export const GET_PMA_HISTORY_PDF_SUCCESS = 'GET_PMA_HISTORY_PDF_SUCCESS';
export const GET_PMA_HISTORY_PDF_FAIL = 'GET_PMA_HISTORY_PDF_FAIL';

export const GET_PMA_TEMPLATES = 'GET_PMA_TEMPLATES';
export const GET_PMA_TEMPLATES_START = 'GET_PMA_TEMPLATES_START';
export const GET_PMA_TEMPLATES_SUCCESS = 'GET_PMA_TEMPLATES_SUCCESS';
export const GET_PMA_TEMPLATES_FAIL = 'GET_PMA_TEMPLATES_FAIL';

export const GET_PMA_TEMPLATE = 'GET_PMA_TEMPLATE';
export const GET_PMA_TEMPLATE_START = 'GET_PMA_TEMPLATE_START';
export const GET_PMA_TEMPLATE_SUCCESS = 'GET_PMA_TEMPLATE_SUCCESS';
export const GET_PMA_TEMPLATE_FAIL = 'GET_PMA_TEMPLATE_FAIL';

export const CREATE_PMA_TEMPLATE = 'CREATE_PMA_TEMPLATE';
export const CREATE_PMA_TEMPLATE_START = 'CREATE_PMA_TEMPLATE_START';
export const CREATE_PMA_TEMPLATE_SUCCESS = 'CREATE_PMA_TEMPLATE_SUCCESS';
export const CREATE_PMA_TEMPLATE_FAIL = 'CREATE_PMA_TEMPLATE_FAIL';

export const UPDATE_PMA_TEMPLATE = 'UPDATE_PMA_TEMPLATE';
export const UPDATE_PMA_TEMPLATE_START = 'UPDATE_PMA_TEMPLATE_START';
export const UPDATE_PMA_TEMPLATE_SUCCESS = 'UPDATE_PMA_TEMPLATE_SUCCESS';
export const UPDATE_PMA_TEMPLATE_FAIL = 'UPDATE_PMA_TEMPLATE_FAIL';

export const DELETE_PMA_TEMPLATE = 'DELETE_PMA_TEMPLATE';
export const DELETE_PMA_TEMPLATE_START = 'DELETE_PMA_TEMPLATE_START';
export const DELETE_PMA_TEMPLATE_SUCCESS = 'DELETE_PMA_TEMPLATE_SUCCESS';
export const DELETE_PMA_TEMPLATE_FAIL = 'DELETE_PMA_TEMPLATE_FAIL';

export const RESEND_PMA_APPROVER_NOTIFICATION =
	'RESEND_PMA_APPROVER_NOTIFICATION';
export const RESEND_PMA_APPROVER_NOTIFICATION_START =
	'RESEND_PMA_APPROVER_NOTIFICATION_START';
export const RESEND_PMA_APPROVER_NOTIFICATION_SUCCESS =
	'RESEND_PMA_APPROVER_NOTIFICATION_SUCCESS';
export const RESEND_PMA_APPROVER_NOTIFICATION_FAIL =
	'RESEND_PMA_APPROVER_NOTIFICATION_FAIL';

export const GET_PMA_FILE = 'GET_PMA_FILE';
export const GET_PMA_FILE_START = 'GET_PMA_FILE_START';
export const GET_PMA_FILE_SUCCESS = 'GET_PMA_FILE_SUCCESS';
export const GET_PMA_FILE_FAIL = 'GET_PMA_FILE_FAIL';

/* DOCUSIGN */

export const CREATE_DOCUSIGN = 'CREATE_DOCUSIGN';
export const CREATE_DOCUSIGN_START = 'CREATE_DOCUSIGN_START';
export const CREATE_DOCUSIGN_SUCCESS = 'CREATE_DOCUSIGN_SUCCESS';
export const CREATE_DOCUSIGN_FAIL = 'CREATE_DOCUSIGN_FAIL';

/*
    RSHEETS
*/

export const CLEAR_RSHEETS = 'CLEAR_RSHEETS';
export const CLEAR_RSHEETS_MESSAGES = 'CLEAR_RSHEETS_MESSAGES';
export const CLEAR_RSHEET_DATA = 'CLEAR_RSHEET_DATA'

export const GET_RSHEETS = 'GET_RSHEETS';
export const GET_RSHEETS_START = 'GET_RSHEETS_START';
export const GET_RSHEETS_SUCCESS = 'GET_RSHEETS_SUCCESS';
export const GET_RSHEETS_FAIL = 'GET_RSHEETS_FAIL';

export const GET_RSHEET = 'GET_RSHEET';
export const GET_RSHEET_START = 'GET_RSHEET_START';
export const GET_RSHEET_SUCCESS = 'GET_RSHEET_SUCCESS';
export const GET_RSHEET_FAIL = 'GET_RSHEET_FAIL';

export const CREATE_RSHEET = 'CREATE_RSHEET';
export const CREATE_RSHEET_START = 'CREATE_RSHEET_START';
export const CREATE_RSHEET_SUCCESS = 'CREATE_RSHEET_SUCCESS';
export const CREATE_RSHEET_FAIL = 'CREATE_RSHEET_FAIL';

export const UPDATE_RSHEET = 'UPDATE_RSHEET';
export const UPDATE_RSHEET_START = 'UPDATE_RSHEET_START';
export const UPDATE_RSHEET_SUCCESS = 'UPDATE_RSHEET_SUCCESS';
export const UPDATE_RSHEET_FAIL = 'UPDATE_RSHEET_FAIL';

export const DELETE_RSHEET = 'DELETE_RSHEET';
export const DELETE_RSHEET_START = 'DELETE_RSHEET_START';
export const DELETE_RSHEET_SUCCESS = 'DELETE_RSHEET_SUCCESS';
export const DELETE_RSHEET_FAIL = 'DELETE_RSHEET_FAIL';

export const CREATE_RSHEET_PDF = 'CREATE_RSHEET_PDF';
export const CREATE_RSHEET_PDF_START = 'CREATE_RSHEET_PDF_START';
export const CREATE_RSHEET_PDF_SUCCESS = 'CREATE_RSHEET_PDF_SUCCESS';
export const CREATE_RSHEET_PDF_FAIL = 'CREATE_RSHEET_PDF_FAIL';

export const CREATE_RSHEET_FILES = 'CREATE_RSHEET_FILES';
export const CREATE_RSHEET_FILES_START = 'CREATE_RSHEET_FILES_START';
export const CREATE_RSHEET_FILES_SUCCESS = 'CREATE_RSHEET_FILES_SUCCESS';
export const CREATE_RSHEET_FILES_FAIL = 'CREATE_RSHEET_FILES_FAIL';

export const GET_RSHEET_FILES = 'GET_RSHEET_FILES';
export const GET_RSHEET_FILES_START = 'GET_RSHEET_FILES_START';
export const GET_RSHEET_FILES_SUCCESS = 'GET_RSHEET_FILES_SUCCESS';
export const GET_RSHEET_FILES_FAIL = 'GET_RSHEET_FILES_FAIL';

export const CREATE_RSHEET_REVISION = 'CREATE_RSHEET_REVISION';
export const CREATE_RSHEET_REVISION_START = 'CREATE_RSHEET_REVISION_START';
export const CREATE_RSHEET_REVISION_SUCCESS = 'CREATE_RSHEET_REVISION_SUCCESS';
export const CREATE_RSHEET_REVISION_FAIL = 'CREATE_RSHEET_REVISION_FAIL';

/*
    ONBOARDING
*/

export const GET_ONBOARDINGS = 'GET_ONBOARDINGS';
export const GET_ONBOARDINGS_START = 'GET_ONBOARDINGS_START';
export const GET_ONBOARDINGS_SUCCESS = 'GET_ONBOARDINGS_SUCCESS';
export const GET_ONBOARDINGS_FAIL = 'GET_ONBOARDINGS_FAIL';
// Get Ob PDF, can be used for any pdf file
export const GET_OB_PDF = 'GET_OB_PDF';
export const GET_OB_PDF_START = 'GET_OB_PDF_START';
export const GET_OB_PDF_SUCCESS = 'GET_OB_PDF_SUCCESS';
export const GET_OB_PDF_FAIL = 'GET_OB_PDF_FAIL';
// Create OB Notice to Employee
export const CREATE_NTE_ENTRY = 'CREATE_NTE_ENTRY';
export const CREATE_NTE_ENTRY_START = 'CREATE_NTE_ENTRY_START';
export const CREATE_NTE_ENTRY_SUCCESS = 'CREATE_NTE_ENTRY_SUCCESS';
export const CREATE_NTE_ENTRY_FAIL = 'CREATE_NTE_ENTRY_FAIL';

export const UPDATE_ONBOARDING_ENTRY = 'UPDATE_ONBOARDING_ENTRY';
export const UPDATE_ONBOARDING_ENTRY_START = 'UPDATE_ONBOARDING_ENTRY_START';
export const UPDATE_ONBOARDING_ENTRY_SUCCESS =
	'UPDATE_ONBOARDING_ENTRY_SUCCESS';
export const UPDATE_ONBOARDING_ENTRY_FAIL = 'UPDATE_ONBOARDING_ENTRY_FAIL';

export const GET_OB_WORKSPACE_USER = 'GET_OB_WORKSPACE_USER';
export const GET_OB_WORKSPACE_USER_START = 'GET_OB_WORKSPACE_USER_START';
export const GET_OB_WORKSPACE_USER_SUCCESS = 'GET_OB_WORKSPACE_USER_SUCCESS';
export const GET_OB_WORKSPACE_USER_FAIL = 'GET_OB_WORKSPACE_USER_FAIL';

/* 
  OPTIONS
*/
export const GET_OPTIONS = 'GET_OPTIONS';
export const GET_OPTIONS_START = 'GET_OPTIONS_START';
export const GET_OPTIONS_SUCCESS = 'GET_OPTIONS_SUCCESS';
export const GET_OPTIONS_FAIL = 'GET_OPTIONS_FAIL';

/* 
  INVOICE SUBMISSIONS
*/

export const GET_LISTING_ENTRIES = 'GET_LISTING_ENTRIES';
export const GET_LISTING_ENTRIES_START = 'GET_LISTING_ENTRIES_START';
export const GET_LISTING_ENTRIES_SUCCESS = 'GET_LISTING_ENTRIES_SUCCESS';
export const GET_LISTING_ENTRIES_FAIL = 'GET_LISTING_ENTRIES_FAIL';

export const CREATE_LISTING_ENTRY = 'CREATE_LISTING_ENTRY';
export const CREATE_LISTING_ENTRY_START = 'CREATE_LISTING_ENTRY_START';
export const CREATE_LISTING_ENTRY_SUCCESS = 'CREATE_LISTING_ENTRY_SUCCESS';
export const CREATE_LISTING_ENTRY_FAIL = 'CREATE_LISTING_ENTRY_FAIL';

export const UPDATE_LISTING_ENTRY = 'UPDATE_LISTING_ENTRY';
export const UPDATE_LISTING_ENTRY_START = 'UPDATE_LISTING_ENTRY_START';
export const UPDATE_LISTING_ENTRY_SUCCESS = 'UPDATE_LISTING_ENTRY_SUCCESS';
export const UPDATE_LISTING_ENTRY_FAIL = 'UPDATE_LISTING_ENTRY_FAIL';

export const DELETE_LISTING_ENTRY = 'DELETE_LISTING_ENTRY';
export const DELETE_LISTING_ENTRY_START = 'DELETE_LISTING_ENTRY_START';
export const DELETE_LISTING_ENTRY_SUCCESS = 'DELETE_LISTING_ENTRY_SUCCESS';
export const DELETE_LISTING_ENTRY_FAIL = 'DELETE_LISTING_ENTRY_FAIL';

export const GET_LISTING_PDF = 'GET_LISTING_PDF';
export const GET_LISTING_PDF_START = 'GET_LISTING_PDF_START';
export const GET_LISTING_PDF_SUCCESS = 'GET_LISTING_PDF_SUCCESS';
export const GET_LISTING_PDF_FAIL = 'GET_LISTING_PDF_FAIL';

export const GET_LISTING_HISTORY = 'GET_LISTING_HISTORY';
export const GET_LISTING_HISTORY_START = 'GET_LISTING_HISTORY_START';
export const GET_LISTING_HISTORY_SUCCESS = 'GET_LISTING_HISTORY_SUCCESS';
export const GET_LISTING_HISTORY_FAIL = 'GET_LISTING_HISTORY_FAIL';

export const RESET_INVOICE_ID = 'RESET_INVOICE_ID';

export const VOID_INVOICES = 'VOID_INVOICES';
export const VOID_INVOICES_START = 'VOID_INVOICES_START';
export const VOID_INVOICES_SUCCESS = 'VOID_INVOICES_SUCCESS';
export const VOID_INVOICES_FAIL = 'VOID_INVOICES_FAIL';

export const CLEAR_INVOICES = 'CLEAR_INVOICES';
export const CLEAR_INVOICES_START = 'CLEAR_INVOICES_START';
export const CLEAR_INVOICES_SUCCESS = 'CLEAR_INVOICES_SUCCESS';
export const CLEAR_INVOICES_FAIL = 'CLEAR_INVOICES_FAIL';

/* 
  INVOICE SUBMISSIONS
*/

export const GET_INVOICE_ENTRIES = 'GET_INVOICE_ENTRIES';
export const GET_INVOICE_ENTRIES_START = 'GET_INVOICE_ENTRIES_START';
export const GET_INVOICE_ENTRIES_SUCCESS = 'GET_INVOICE_ENTRIES_SUCCESS';
export const GET_INVOICE_ENTRIES_FAIL = 'GET_INVOICE_ENTRIES_FAIL';

export const CREATE_INVOICE_ENTRY = 'CREATE_INVOICE_ENTRY';
export const CREATE_INVOICE_ENTRY_START = 'CREATE_INVOICE_ENTRY_START';
export const CREATE_INVOICE_ENTRY_SUCCESS = 'CREATE_INVOICE_ENTRY_SUCCESS';
export const CREATE_INVOICE_ENTRY_FAIL = 'CREATE_INVOICE_ENTRY_FAIL';

export const UPDATE_INVOICE_ENTRY = 'UPDATE_INVOICE_ENTRY';
export const UPDATE_INVOICE_ENTRY_START = 'UPDATE_INVOICE_ENTRY_START';
export const UPDATE_INVOICE_ENTRY_SUCCESS = 'UPDATE_INVOICE_ENTRY_SUCCESS';
export const UPDATE_INVOICE_ENTRY_FAIL = 'UPDATE_INVOICE_ENTRY_FAIL';

export const DELETE_INVOICE_ENTRY = 'DELETE_INVOICE_ENTRY';
export const DELETE_INVOICE_ENTRY_START = 'DELETE_INVOICE_ENTRY_START';
export const DELETE_INVOICE_ENTRY_SUCCESS = 'DELETE_INVOICE_ENTRY_SUCCESS';
export const DELETE_INVOICE_ENTRY_FAIL = 'DELETE_INVOICE_ENTRY_FAIL';

export const CLEAR_INVOICE_PDF_DATA = 'CLEAR_INVOICE_PDF_DATA';

export const RESEND_INVOICE_EMAIL = 'RESEND_INVOICE_EMAIL';
export const RESEND_INVOICE_EMAIL_START = 'RESEND_INVOICE_EMAIL_START';
export const RESEND_INVOICE_EMAIL_SUCCESS = 'RESEND_INVOICE_EMAIL_SUCCESS';
export const RESEND_INVOICE_EMAIL_FAIL = 'RESEND_INVOICE_EMAIL_FAIL';

/*

INVOICE PAYMENTS 

*/

export const GET_INVOICE_PAYMENTS = 'GET_INVOICE_PAYMENTS';
export const GET_INVOICE_PAYMENTS_START = 'GET_INVOICE_PAYMENTS_START';
export const GET_INVOICE_PAYMENTS_SUCCESS = 'GET_INVOICE_PAYMENTS_SUCCESS';
export const GET_INVOICE_PAYMENTS_FAIL = 'GET_INVOICE_PAYMENTS_FAIL';

export const CREATE_INVOICE_PAYMENT = 'CREATE_INVOICE_PAYMENT';
export const CREATE_INVOICE_PAYMENT_START = 'CREATE_INVOICE_PAYMENT_START';
export const CREATE_INVOICE_PAYMENT_SUCCESS = 'CREATE_INVOICE_PAYMENT_SUCCESS';
export const CREATE_INVOICE_PAYMENT_FAIL = 'CREATE_INVOICE_PAYMENT_FAIL';

export const UPDATE_INVOICE_PAYMENT = 'UPDATE_INVOICE_PAYMENT';
export const UPDATE_INVOICE_PAYMENT_START = 'UPDATE_INVOICE_PAYMENT_START';
export const UPDATE_INVOICE_PAYMENT_SUCCESS = 'UPDATE_INVOICE_PAYMENT_SUCCESS';
export const UPDATE_INVOICE_PAYMENT_FAIL = 'UPDATE_INVOICE_PAYMENT_FAIL';

/* 
  CONTACTS IMPORT
*/

export const GET_INVOICE_PDF = 'GET_INVOICE_PDF';
export const GET_INVOICE_PDF_START = 'GET_INVOICE_PDF_START';
export const GET_INVOICE_PDF_SUCCESS = 'GET_INVOICE_PDF_SUCCESS';
export const GET_INVOICE_PDF_FAIL = 'GET_INVOICE_PDF_FAIL';

export const GET_DIALPAD_CONTACTS = 'GET_DIALPAD_CONTACTS';
export const GET_DIALPAD_CONTACTS_START = 'GET_DIALPAD_CONTACTS_START';
export const GET_DIALPAD_CONTACTS_SUCCESS = 'GET_DIALPAD_CONTACTS_SUCCESS';
export const GET_DIALPAD_CONTACTS_FAIL = 'GET_DIALPAD_CONTACTS_FAIL';

export const IMPORT_DIALPAD_CONTACTS = 'IMPORT_DIALPAD_CONTACTS';
export const IMPORT_DIALPAD_CONTACTS_START = 'IMPORT_DIALPAD_CONTACTS_START';
export const IMPORT_DIALPAD_CONTACTS_SUCCESS = 'IMPORT_DIALPAD_CONTACTS_SUCCESS';
export const IMPORT_DIALPAD_CONTACTS_FAIL = 'IMPORT_DIALPAD_CONTACTS_FAIL';

export const GET_LOG_HISTORY = 'GET_LOG_HISTORY';
export const GET_LOG_HISTORY_START = 'GET_LOG_HISTORY_START';
export const GET_LOG_HISTORY_SUCCESS = 'GET_LOG_HISTORY_SUCCESS';
export const GET_LOG_HISTORY_FAIL = 'GET_LOG_HISTORY_FAIL';

export const CLEAR_COMPANY_CRM_MESSAGES = 'CLEAR_COMPANY_CRM_MESSAGES';
export const CLEAR_PROPERTY_CRM_MESSAGES = 'CLEAR_PROPERTY_CRM_MESSAGES';

export const GET_GOOGLE_CONTACTS = 'GET_GOOGLE_CONTACTS';
export const GET_GOOGLE_CONTACTS_START = 'GET_GOOGLE_CONTACTS_START';
export const GET_GOOGLE_CONTACTS_SUCCESS = 'GET_GOOGLE_CONTACTS_SUCCESS';
export const GET_GOOGLE_CONTACTS_FAIL = 'GET_GOOGLE_CONTACTS_FAIL';

export const GET_RS_TYPES = 'GET_RS_TYPES';
export const GET_RS_TYPES_START = 'GET_RS_TYPES_START';
export const GET_RS_TYPES_SUCCESS = 'GET_RS_TYPES_SUCCESS';
export const GET_RS_TYPES_FAIL = 'GET_RS_TYPES_FAIL';

export const GET_NEXT_RS_ILLI_NUMBER = 'GET_NEXT_RS_ILLI_NUMBER';
export const GET_NEXT_RS_ILLI_NUMBER_START = 'GET_NEXT_RS_ILLI_NUMBER_START';
export const GET_NEXT_RS_ILLI_NUMBER_SUCCESS = 'GET_NEXT_RS_ILLI_NUMBER_SUCCESS';
export const GET_NEXT_RS_ILLI_NUMBER_FAIL = 'GET_NEXT_RS_ILLI_NUMBER_FAIL';

export const CHECK_RS_ILLI_NUMBER = 'CHECK_RS_ILLI_NUMBER';
export const CHECK_RS_ILLI_NUMBER_START = 'CHECK_RS_ILLI_NUMBER_START';
export const CHECK_RS_ILLI_NUMBER_SUCCESS = 'CHECK_RS_ILLI_NUMBER_SUCCESS';
export const CHECK_RS_ILLI_NUMBER_FAIL = 'CHECK_RS_ILLI_NUMBER_FAIL';

export const GET_PROPERTY_OWNERS_CONTACTS = 'GET_PROPERTY_OWNERS_CONTACTS';
export const GET_PROPERTY_OWNERS_CONTACTS_START = 'GET_PROPERTY_OWNERS_CONTACTS_START';
export const GET_PROPERTY_OWNERS_CONTACTS_SUCCESS = 'GET_PROPERTY_OWNERS_CONTACTS_SUCCESS';
export const GET_PROPERTY_OWNERS_CONTACTS_FAIL = 'GET_PROPERTY_OWNERS_CONTACTS_FAIL';

export const GET_CLIENT_ERTL = 'GET_CLIENT_ERTL';
export const GET_CLIENT_ERTL_START = 'GET_CLIENT_ERTL_START';
export const GET_CLIENT_ERTL_SUCCESS = 'GET_CLIENT_ERTL_SUCCESS';
export const GET_CLIENT_ERTL_FAIL = 'GET_CLIENT_ERTL_FAIL';

export const UPDATE_CLIENT_ERTL_ENTRY = 'UPDATE_CLIENT_ERTL_ENTRY';
export const UPDATE_CLIENT_ERTL_ENTRY_START = 'UPDATE_CLIENT_ERTL_ENTRY_START';
export const UPDATE_CLIENT_ERTL_ENTRY_SUCCESS = 'UPDATE_CLIENT_ERTL_ENTRY_SUCCESS';
export const UPDATE_CLIENT_ERTL_ENTRY_FAIL = 'UPDATE_CLIENT_ERTL_ENTRY_FAIL';

export const SEND_ERTL_REVIEW_LINK = 'SEND_ERTL_REVIEW_LINK';
export const SEND_ERTL_REVIEW_LINK_START = 'SEND_ERTL_REVIEW_LINK_START';
export const SEND_ERTL_REVIEW_LINK_SUCCESS = 'SEND_ERTL_REVIEW_LINK_SUCCESS';
export const SEND_ERTL_REVIEW_LINK_FAIL = 'SEND_ERTL_REVIEW_LINK_FAIL';
export const GET_COMPANY_CONTACTS_DETAIL = 'GET_COMPANY_CONTACTS_DETAIL';
export const GET_COMPANY_CONTACTS_DETAIL_START = 'GET_COMPANY_CONTACTS_DETAIL_START';
export const GET_COMPANY_CONTACTS_DETAIL_SUCCESS = 'GET_COMPANY_CONTACTS_DETAIL_SUCCESS';
export const GET_COMPANY_CONTACTS_DETAIL_FAIL = 'GET_COMPANY_CONTACTS_DETAIL_FAIL';
export const VOID_PMA_ENTRY = 'VOID_PMA_ENTRY';
export const VOID_PMA_ENTRY_START = 'VOID_PMA_ENTRY_START';
export const VOID_PMA_ENTRY_SUCCESS = 'VOID_PMA_ENTRY_SUCCESS';
export const VOID_PMA_ENTRY_FAIL = 'VOID_PMA_ENTRY_FAIL';

/* 
	File Upload / Download
*/

export const CREATE_FILE_UPLOAD = 'CREATE_FILE_UPLOAD';
export const CREATE_FILE_UPLOAD_START = 'CREATE_FILE_UPLOAD_START';
export const CREATE_FILE_UPLOAD_SUCCESS = 'CREATE_FILE_UPLOAD_SUCCESS';
export const CREATE_FILE_UPLOAD_FAIL = 'CREATE_FILE_UPLOAD_FAIL';

export const GET_FILE_UPLOAD = 'GET_FILE_UPLOAD';
export const GET_FILE_UPLOAD_START = 'GET_FILE_UPLOAD_START';
export const GET_FILE_UPLOAD_SUCCESS = 'GET_FILE_UPLOAD_SUCCESS';
export const GET_FILE_UPLOAD_FAIL = 'GET_FILE_UPLOAD_FAIL';

export const GET_FILES_DATA = 'GET_FILES_DATA';
export const GET_FILES_DATA_START = 'GET_FILES_DATA_START';
export const GET_FILES_DATA_SUCCESS = 'GET_FILES_DATA_SUCCESS';
export const GET_FILES_DATA_FAIL = 'GET_FILES_DATA_FAIL';

/*
	Commission Splits 
*/

export const GET_COMMISSION_SPLITS = 'GET_COMMISSION_SPLITS';
export const GET_COMMISSION_SPLITS_START = 'GET_COMMISSION_SPLITS_START';
export const GET_COMMISSION_SPLITS_SUCCESS = 'GET_COMMISSION_SPLITS_SUCCESS';
export const GET_COMMISSION_SPLITS_FAIL = 'GET_COMMISSION_SPLITS_FAIL';

export const GET_COMMISSION_SPLIT = 'GET_COMMISSION_SPLIT';
export const GET_COMMISSION_SPLIT_START = 'GET_COMMISSION_SPLIT_START';
export const GET_COMMISSION_SPLIT_SUCCESS = 'GET_COMMISSION_SPLIT_SUCCESS';
export const GET_COMMISSION_SPLIT_FAIL = 'GET_COMMISSION_SPLIT_FAIL';

export const CREATE_COMMISSION_SPLIT = 'CREATE_COMMISSION_SPLIT';
export const CREATE_COMMISSION_SPLIT_START = 'CREATE_COMMISSION_SPLIT_START';
export const CREATE_COMMISSION_SPLIT_SUCCESS = 'CREATE_COMMISSION_SPLIT_SUCCESS';
export const CREATE_COMMISSION_SPLIT_FAIL = 'CREATE_COMMISSION_SPLIT_FAIL';

export const DELETE_COMMISSION_SPLIT = 'DELETE_COMMISSION_SPLIT';
export const DELETE_COMMISSION_SPLIT_START = 'DELETE_COMMISSION_SPLIT_START';
export const DELETE_COMMISSION_SPLIT_SUCCESS = 'DELETE_COMMISSION_SPLIT_SUCCESS';
export const DELETE_COMMISSION_SPLIT_FAIL = 'DELETE_COMMISSION_SPLIT_FAIL';

export const UPDATE_COMMISSION_SPLIT = 'UPDATE_COMMISSION_SPLIT';
export const UPDATE_COMMISSION_SPLIT_START = 'UPDATE_COMMISSION_SPLIT_START';
export const UPDATE_COMMISSION_SPLIT_SUCCESS = 'UPDATE_COMMISSION_SPLIT_SUCCESS';
export const UPDATE_COMMISSION_SPLIT_FAIL = 'UPDATE_COMMISSION_SPLIT_FAIL';

export const GET_COMMISSION_SPLITS_PROPERTY_EXISTENCE = 'GET_COMMISSION_SPLITS_PROPERTY_EXISTENCE';
export const GET_COMMISSION_SPLITS_PROPERTY_EXISTENCE_START = 'GET_COMMISSION_SPLITS_PROPERTY_EXISTENCE_START';
export const GET_COMMISSION_SPLITS_PROPERTY_EXISTENCE_SUCCESS = 'GET_COMMISSION_SPLITS_PROPERTY_EXISTENCE_SUCCESS';
export const GET_COMMISSION_SPLITS_PROPERTY_EXISTENCE_FAIL = 'GET_COMMISSION_SPLITS_PROPERTY_EXISTENCE_FAIL';
export const SET_COMMISSION_SPLITS_PROPERTY_EXISTENCE = 'SET_COMMISSION_SPLITS_PROPERTY_EXISTENCE';

export const CREATE_COMMISSION_SPLITS_HISTORY = 'CREATE_COMMISSION_SPLITS_HISTORY';
export const CREATE_COMMISSION_SPLITS_HISTORY_START = 'CREATE_COMMISSION_SPLITS_HISTORY_START';
export const CREATE_COMMISSION_SPLITS_HISTORY_SUCCESS = 'CREATE_COMMISSION_SPLITS_HISTORY_SUCCESS';
export const CREATE_COMMISSION_SPLITS_HISTORY_FAIL = 'CREATE_COMMISSION_SPLITS_HISTORY_FAIL';

export const GET_COMMISSION_SPLITS_HISTORY = 'GET_COMMISSION_SPLITS_HISTORY';
export const GET_COMMISSION_SPLITS_HISTORY_START = 'GET_COMMISSION_SPLITS_HISTORY_START';
export const GET_COMMISSION_SPLITS_HISTORY_SUCCESS = 'GET_COMMISSION_SPLITS_HISTORY_SUCCESS';
export const GET_COMMISSION_SPLITS_HISTORY_FAIL = 'GET_COMMISSION_SPLITS_HISTORY_FAIL';

export const GET_COMMISSION_SPLITS_REVIEWERS = 'GET_COMMISSION_SPLITS_REVIEWERS';
export const GET_COMMISSION_SPLITS_REVIEWERS_START = 'GET_COMMISSION_SPLITS_REVIEWERS_START';
export const GET_COMMISSION_SPLITS_REVIEWERS_SUCCESS = 'GET_COMMISSION_SPLITS_REVIEWERS_SUCCESS';
export const GET_COMMISSION_SPLITS_REVIEWERS_FAIL = 'GET_COMMISSION_SPLITS_REVIEWERS_FAIL';

export const CREATE_COMMISSION_SPLITS_PDF = 'CREATE_COMMISSION_SPLITS_PDF';
export const CREATE_COMMISSION_SPLITS_PDF_START = 'CREATE_COMMISSION_SPLITS_PDF_START';
export const CREATE_COMMISSION_SPLITS_PDF_SUCCESS = 'CREATE_COMMISSION_SPLITS_PDF_SUCCESS';
export const CREATE_COMMISSION_SPLITS_PDF_FAIL = 'CREATE_COMMISSION_SPLITS_PDF_FAIL';

export const GET_COMMISSION_SPLITS_APPROVERS = 'GET_COMMISSION_SPLITS_APPROVERS';
export const GET_COMMISSION_SPLITS_APPROVERS_START = 'GET_COMMISSION_SPLITS_APPROVERS_START';
export const GET_COMMISSION_SPLITS_APPROVERS_SUCCESS = 'GET_COMMISSION_SPLITS_APPROVERS_SUCCESS';
export const GET_COMMISSION_SPLITS_APPROVERS_FAIL = 'GET_COMMISSION_SPLITS_APPROVERS_FAIL';

export const GET_CS_SIGNING_URL = 'GET_CS_SIGNING_URL';
export const GET_CS_SIGNING_URL_START = 'GET_CS_SIGNING_URL_START';
export const GET_CS_SIGNING_URL_SUCCESS = 'GET_CS_SIGNING_URL_SUCCESS';
export const GET_CS_SIGNING_URL_FAIL = 'GET_CS_SIGNING_URL_FAIL';

export const CLEAR_CS_HISTORY = 'CLEAR_CS_HISTORY';

/*

Tenant Rep Entries

*/

export const GET_TR_ENTRIES = 'GET_TR_ENTRIES';
export const GET_TR_ENTRIES_START = 'GET_TR_ENTRIES_START';
export const GET_TR_ENTRIES_SUCCESS = 'GET_TR_ENTRIES_SUCCESS';
export const GET_TR_ENTRIES_FAIL = 'GET_TR_ENTRIES_FAIL';

export const GET_TR_ENTRY = 'GET_TR_ENTRY';
export const GET_TR_ENTRY_START = 'GET_TR_ENTRY_START';
export const GET_TR_ENTRY_SUCCESS = 'GET_TR_ENTRY_SUCCESS';
export const GET_TR_ENTRY_FAIL = 'GET_TR_ENTRY_FAIL';

export const CREATE_TR_ENTRY = 'CREATE_TR_ENTRY';
export const CREATE_TR_ENTRY_START = 'CREATE_TR_ENTRY_START';
export const CREATE_TR_ENTRY_SUCCESS = 'CREATE_TR_ENTRY_SUCCESS';
export const CREATE_TR_ENTRY_FAIL = 'CREATE_TR_ENTRY_FAIL';

export const UPDATE_TR_ENTRY = 'UPDATE_TR_ENTRY';
export const UPDATE_TR_ENTRY_START = 'UPDATE_TR_ENTRY_START';
export const UPDATE_TR_ENTRY_SUCCESS = 'UPDATE_TR_ENTRY_SUCCESS';
export const UPDATE_TR_ENTRY_FAIL = 'UPDATE_TR_ENTRY_FAIL';

export const DELETE_TR_ENTRY = 'DELETE_TR_ENTRY';
export const DELETE_TR_ENTRY_START = 'DELETE_TR_ENTRY_START';
export const DELETE_TR_ENTRY_SUCCESS = 'DELETE_TR_ENTRY_SUCCESS';
export const DELETE_TR_ENTRY_FAIL = 'DELETE_TR_ENTRY_FAIL';

export const GET_TR_APPROVERS = 'GET_TR_APPROVERS';
export const GET_TR_APPROVERS_START = 'GET_TR_APPROVERS_START';
export const GET_TR_APPROVERS_SUCCESS = 'GET_TR_APPROVERS_SUCCESS';
export const GET_TR_APPROVERS_FAIL = 'GET_TR_APPROVERS_FAIL';

/*

Tenant Rep Templates

*/

export const GET_TR_TEMPLATES = 'GET_TR_TEMPLATES';
export const GET_TR_TEMPLATES_START = 'GET_TR_TEMPLATES_START';
export const GET_TR_TEMPLATES_SUCCESS = 'GET_TR_TEMPLATES_SUCCESS';
export const GET_TR_TEMPLATES_FAIL = 'GET_TR_TEMPLATES_FAIL';

export const CREATE_TR_TEMPLATE = 'CREATE_TR_TEMPLATE';
export const CREATE_TR_TEMPLATE_START = 'CREATE_TR_TEMPLATE_START';
export const CREATE_TR_TEMPLATE_SUCCESS = 'CREATE_TR_TEMPLATE_SUCCESS';
export const CREATE_TR_TEMPLATE_FAIL = 'CREATE_TR_TEMPLATE_FAIL';

export const UPDATE_TR_TEMPLATE = 'UPDATE_TR_TEMPLATE';
export const UPDATE_TR_TEMPLATE_START = 'UPDATE_TR_TEMPLATE_START';
export const UPDATE_TR_TEMPLATE_SUCCESS = 'UPDATE_TR_TEMPLATE_SUCCESS';
export const UPDATE_TR_TEMPLATE_FAIL = 'UPDATE_TR_TEMPLATE_FAIL';

export const DELETE_TR_TEMPLATE = 'DELETE_TR_TEMPLATE';
export const DELETE_TR_TEMPLATE_START = 'DELETE_TR_TEMPLATE_START';
export const DELETE_TR_TEMPLATE_SUCCESS = 'DELETE_TR_TEMPLATE_SUCCESS';
export const DELETE_TR_TEMPLATE_FAIL = 'DELETE_TR_TEMPLATE_FAIL';

export const CREATE_TR_PDF = 'CREATE_TR_PDF';
export const CREATE_TR_PDF_START = 'CREATE_TR_PDF_START';
export const CREATE_TR_PDF_SUCCESS = 'CREATE_TR_PDF_SUCCESS';
export const CREATE_TR_PDF_FAIL = 'CREATE_TR_PDF_FAIL';

export const CREATE_TR_HISTORY_PDF = 'CREATE_TR_HISTORY_PDF';
export const CREATE_TR_HISTORY_PDF_START = 'CREATE_TR_HISTORY_PDF_START';
export const CREATE_TR_HISTORY_PDF_SUCCESS = 'CREATE_TR_HISTORY_PDF_SUCCESS';
export const CREATE_TR_HISTORY_PDF_FAIL = 'CREATE_TR_HISTORY_PDF_FAIL';

export const GET_TR_HISTORY = 'GET_TR_HISTORY';
export const GET_TR_HISTORY_START = 'GET_TR_HISTORY_START';
export const GET_TR_HISTORY_SUCCESS = 'GET_TR_HISTORY_SUCCESS';
export const GET_TR_HISTORY_FAIL = 'GET_TR_HISTORY_FAIL';

export const CREATE_TR_HISTORY = 'CREATE_TR_HISTORY';
export const CREATE_TR_HISTORY_START = 'CREATE_TR_HISTORY_START';
export const CREATE_TR_HISTORY_SUCCESS = 'CREATE_TR_HISTORY_SUCCESS';
export const CREATE_TR_HISTORY_FAIL = 'CREATE_TR_HISTORY_FAIL';

export const GET_TR_SIGNING_URL = 'GET_TR_SIGNING_URL';
export const GET_TR_SIGNING_URL_START = 'GET_TR_SIGNING_URL_START';
export const GET_TR_SIGNING_URL_SUCCESS = 'GET_TR_SIGNING_URL_SUCCESS';
export const GET_TR_SIGNING_URL_FAIL = 'GET_TR_SIGNING_URL_FAIL';

/*

GCI

*/

export const GET_GCI_ENTRIES = 'GET_GCI_ENTRIES';
export const GET_GCI_ENTRIES_START = 'GET_GCI_ENTRIES_START';
export const GET_GCI_ENTRIES_SUCCESS = 'GET_GCI_ENTRIES_SUCCESS';
export const GET_GCI_ENTRIES_FAIL = 'GET_GCI_ENTRIES_FAIL';

export const CREATE_GCI_ENTRY = 'CREATE_GCI_ENTRY';
export const CREATE_GCI_ENTRY_START = 'CREATE_GCI_ENTRY_START';
export const CREATE_GCI_ENTRY_SUCCESS = 'CREATE_GCI_ENTRY_SUCCESS';
export const CREATE_GCI_ENTRY_FAIL = 'CREATE_GCI_ENTRY_FAIL';

export const UPDATE_GCI_ENTRY = 'UPDATE_GCI_ENTRY';
export const UPDATE_GCI_ENTRY_START = 'UPDATE_GCI_ENTRY_START';
export const UPDATE_GCI_ENTRY_SUCCESS = 'UPDATE_GCI_ENTRY_SUCCESS';
export const UPDATE_GCI_ENTRY_FAIL = 'UPDATE_GCI_ENTRY_FAIL';

export const DELETE_GCI_ENTRY = 'DELETE_GCI_ENTRY';
export const DELETE_GCI_ENTRY_START = 'DELETE_GCI_ENTRY_START';
export const DELETE_GCI_ENTRY_SUCCESS = 'DELETE_GCI_ENTRY_SUCCESS';
export const DELETE_GCI_ENTRY_FAIL = 'DELETE_GCI_ENTRY_FAIL';

export const GET_EARNED_COMMISSIONS = 'GET_EARNED_COMMISSIONS';
export const GET_EARNED_COMMISSIONS_START = 'GET_EARNED_COMMISSIONS_START';
export const GET_EARNED_COMMISSIONS_SUCCESS = 'GET_EARNED_COMMISSIONS_SUCCESS';
export const GET_EARNED_COMMISSIONS_FAIL = 'GET_EARNED_COMMISSIONS_FAIL';

export const CREATE_EARNED_COMMISSIONS = 'CREATE_EARNED_COMMISSIONS';
export const CREATE_EARNED_COMMISSIONS_START = 'CREATE_EARNED_COMMISSIONS_START';
export const CREATE_EARNED_COMMISSIONS_SUCCESS = 'CREATE_EARNED_COMMISSIONS_SUCCESS';
export const CREATE_EARNED_COMMISSIONS_FAIL = 'CREATE_EARNED_COMMISSIONS_FAIL';