import { Dialog } from "@material-ui/core";
import Button from "components/CustomButtons/Button.jsx";
import CustomDialog from "components/CustomDialog/CustomDialogWithContact";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { sortBy } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { clone } from "shared/utility";
import * as actions from "store/actions";
import PropertiesFormAgentSortableList from "./PropertiesFormAgentSortableList";

import axios from 'store/axios/axios-crm';
import { handleError } from '../../../shared/utility';

const PropertiesFormOther = (props) => {
    const {
        contact_properties,
        dataObj,
        setDataObj,
        success,
        error,
        contactsForAdd,
        setContactsForAddNow,
        updateContact
    } = props;
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [openedSearchModal, setOpenedSearchModal] = useState(false);
    const [openedAskBeforeDelete, setOpenedAskBeforeDelete] = useState(false);
    const [currentDelete, setCurrentDelete] = useState(null);

    const getPropertyToContact = async (property_id) => {
        try {
            const response = await axios.get(
                '/property_to_contact?property_id=' + property_id
            );
            console.log('response', response.data);
            setContacts(response.data.filter(r => {
                return r.contact_type === 'REA'
            }));
        } catch (e) {
            handleError(e);
            alert('error getting property contacts');
        }
    }

    const addPropertyToContact = async (data) => {
        try {
            const newData = clone(data);
            if (newData.hasOwnProperty('from_property')) delete newData.from_property;
            await axios.post('/property_to_contact', newData);
            getPropertyToContact(dataObj.id, "property", "others");
        } catch (e) {
            handleError(e);
            alert('Unable to add property contact');
        }
    }

    const removePropertyToContact = async (data) => {
        try {
            await axios.delete(
                '/property_to_contact/?id=' + data
            );
            getPropertyToContact(dataObj.id, "property", "others");
        } catch (e) {
            handleError(e);
            alert('Unable to remove contact');
        }
    }

    useEffect(() => {
        // Edit Mode
        if (dataObj.id) {
            getPropertyToContact(dataObj.id, "property", "others");
        } else {
            // Add Mode
            setContacts(
                contactsForAdd.filter((f) => {
                    if (f.contact_type === "REA") return true;
                    return false;
                })
            );
        }
    }, []);

    useEffect(() => {
        if (contact_properties) {
            let sortedContacts = contact_properties.filter((cp) => {
                if (cp.contact_type === "REA") return true;
                return false;
            });
            if (dataObj.sorted_agent) {
                const sortedAgentIds = dataObj.sorted_agent.split(",");
                sortedContacts = sortBy(sortedContacts, (contact) => {
                    const index = sortedAgentIds.indexOf(contact.contact_data.id.toString())
                    return index === -1 ? sortedAgentIds.length : index;
                }
                );
            }
            setContacts(sortedContacts);
        }
    }, [contact_properties]);

    useEffect(() => {
        if (loading2) {
            if (success === "Successfully added property - contact") {
                getPropertyToContact(dataObj.id, "property", "others");
            }
            if (success === "Successfully removed property - contact") {
                getPropertyToContact(dataObj.id, "property", "others");
            }
        }
    }, [success, loading2]);

    useEffect(() => {
        setContacts(
            contactsForAdd.filter((f) => {
                if (f.contact_type === "REA") return true;
                return false;
            })
        );
    }, [contactsForAdd]);

    const choose = (the_contact_data) => {
        if (props.action !== "Add") {
            // save right away
            setLoading2(true);
            addPropertyToContact(
                {
                    contact_type: "REA",
                    contact_id: the_contact_data.id,
                    property_id: dataObj.id,
                },
                "property"
            );
            // update contact - make sure he is designated as Real Estate Agent
            let current_contact_type = the_contact_data.contact_type ? the_contact_data.contact_type.split(',') : [];
            if (current_contact_type.indexOf('Real Estate Agent') === -1) {
                updateContact({
                    id: the_contact_data.id,
                    contact_type: current_contact_type.concat(['Real Estate Agent']).join(',')
                })
            }
        } else {
            // Add Mode
            const newContactsForAdd = clone(contactsForAdd).concat([
                {
                    contact_type: "REA",
                    contact_id: the_contact_data.id,
                    contact_data: the_contact_data,
                },
            ]);
            setContactsForAddNow(newContactsForAdd);
            // update contact - make sure he is designated as Real Estate Agent
            let current_contact_type = the_contact_data.contact_type ? the_contact_data.contact_type.split(',') : [];
            if (current_contact_type.indexOf('Real Estate Agent') === -1) {
                updateContact({
                    id: the_contact_data.id,
                    contact_type: current_contact_type.concat(['Real Estate Agent']).join(',')
                })
            }
        }
        setOpenedSearchModal(false);
    }

    return (
        <div>
            <div
                className={props.action === "View" ? "no-click-disabled lightgray-bg-disabled enclosure" : "enclosure"}
            >
                <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <h3>{contacts.length} Associate(s)</h3>
                        {props.action !== "View" && (
                            <Button
                                style={{ marginBottom: 20 }}
                                color="primary"
                                onClick={() => {
                                    setOpenedSearchModal(true);
                                }}
                            >
                                <i className="fa fa-plus"></i> Add Associate
                            </Button>
                        )}
                        <PropertiesFormAgentSortableList
                            action={props.action}
                            items={contacts}
                            onDelete={(c) => {
                                if (props.action !== "Add") {
                                    setOpenedAskBeforeDelete(true);
                                    setCurrentDelete(c);
                                } else {
                                    setContactsForAddNow(
                                        contactsForAdd.filter((f) => {
                                            if (f.contact_data) {
                                                if (f.contact_data.id === c.id) {
                                                    if (f.contact_type === "REA") {
                                                        return false;
                                                    }
                                                }
                                            }
                                            return true;
                                        })
                                    );
                                }
                            }}
                            onItemClick={(o) => {
                                props.setOpenedEditContact(true);
                                props.setContactID(o.id);
                            }}
                            onSortEnd={(newList) => {
                                const sortedIds = newList
                                    .map((item) => {
                                        if (item.contact_data && item.contact_data.id) {
                                            return item.contact_data.id;
                                        }
                                        return "";
                                    })
                                    .join(",");
                                const newData = clone(dataObj);
                                newData.sorted_agent = sortedIds;
                                setDataObj(newData);
                                setContacts(newList);
                            }}
                        />
                    </GridItem>

                    {openedSearchModal && (
                        <CustomDialog
                            open={openedSearchModal}
                            close={() => {
                                setOpenedSearchModal(false);
                            }}
                            choose={choose}
                            peopleType="agents"
                            create_and_choose={choose}
                            title="Select Contact"
                            label="Search Contacts"
                        />
                    )}

                    {openedAskBeforeDelete && (
                        <Dialog
                            open={openedAskBeforeDelete}
                            onClose={() => {
                                setOpenedAskBeforeDelete(false);
                            }}
                        >
                            <div className="dialog-wrapper text-center">
                                <h3>Are you sure you want to delete this associate ?</h3>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        if (currentDelete) {
                                            if (currentDelete.id) {
                                                if (props.action !== "Add") {
                                                    setLoading2(true);
                                                    removePropertyToContact(
                                                        currentDelete.id,
                                                        "property",
                                                        dataObj.id
                                                    );
                                                }
                                            }
                                        }
                                        setOpenedAskBeforeDelete(false);
                                    }}
                                >
                                    YES, DELETE
                                </Button>
                                <Button
                                    color="white"
                                    onClick={() => {
                                        setOpenedAskBeforeDelete(false);
                                    }}
                                >
                                    CANCEL
                                </Button>
                            </div>
                        </Dialog>
                    )}
                </GridContainer>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        contact_properties: state.crm_contacts.contact_properties,
        success: state.crm_contacts.success,
        error: state.crm_contacts.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPropertyToContact: (data, type, kind) => {
            dispatch(actions.getPropertyToContact(data, type, kind));
        },
        updateContact: (data, partnersData, addressData) => {
            dispatch(actions.updateContact(data, partnersData, addressData));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesFormOther);
