import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import checkPermissions from '../../shared/checkPermissions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Cached from "@material-ui/icons/Cached";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomRadio from "components/CustomRadio/CustomRadio";
import { FadeLoader } from 'react-spinners';
import { num_whitelist } from "shared/validation";
import axios from 'store/axios/axios-settings';
import Notification from "../../components/Notification/Notification";

class SettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enableMaintenanceCheck: '',
      settings: null,
      emailSettings: null,
      message: null
    }
  }

  setNotification = (message) => {
    this.setState({message});
  }

  componentDidMount() {
    // load settings
    axios.get('/data').then((res) => {
      console.log('dat', res.data);
      if (res.data) {
        const settings = {}
        res.data.forEach(rd => {
          settings[rd.id] = rd.data;
        })
        this.setState({
          settings,
          emailSettings: settings[8]
        })
      }
    }).catch(err => {
      console.log('err', err);
      this.setNotification('Error loading settings');
    })
  }

  componentDidUpdate(prevProps, prevState) {
    
  }

  render() {
    const permitted = checkPermissions('SiteSettings');
    if (!permitted) {
      return <Redirect to="/dashboard" />;
    }
    console.log('state', this.state)
    return (
  <div>
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <div className="pointed"><Cached /></div>
            </CardIcon>
          </CardHeader>
          <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h1>Site Settings</h1>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <h3>Email Setting</h3>
                  <CustomSelect 
                    options={['on','off']}
                    default={this.state.emailSettings}
                    choose={(e) => {
                      this.setState({
                        emailSettings: e
                      })
                    }}
                  />
                  <Button
                    color="rose"
                    onClick={async () => {
                      axios.post('/data', {
                        8: this.state.emailSettings
                      }).then(res => {
                        this.setNotification('Saved email setting');
                      }).catch(err => {
                        console.log('err', err);
                        this.setNotification('Error saving settings');
                      })
                    }}
                  >SAVE EMAIL SETTING</Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <h3>Use Type</h3>
                    To change the Company/Contact Use Type Options modify this Google Spreadsheet: <br />
                    <a style={{color:'red'}} 
                      target="_blank"
                      href="https://docs.google.com/spreadsheets/d/1bOQSYu2YckJr4uulaWCRmbsGpnzN-RM-sMAy7VvoEPU/edit?gid=1050389069#gid=1050389069">CLICK HERE</a>
                </GridItem>
                
              </GridContainer>
          </CardBody>
        </Card>
      </GridItem>

      {this.state.message && <Notification 
        message={this.state.message}
        open={this.state.message ? true : false}
        close={() => {
          this.setState({
            message: null
          })
        }}
      />}
    </GridContainer>

  </div>
    );
  }
}

const mapStateToProps = state => {
  
  return {
    loading: state.settings.loading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    readSettings: () => {
      dispatch(actions.readSettings());
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
