import { getPaymentAmount, shouldGetPaymentAmount } from '../RSheetsLogic';
import { formatDollar, formatNumberWithCommas } from "../../../shared/utility";

const renderPaymentScheduleFullFormPDF = props => {
    const { data, sheetType } = props;
    if (!data.invoices) return `
        <div class="the-table">
            <table>
                <tbody>
                    <tr>
                        <td class="col-100">No Payment Schedule Entries</td>
                    </tr>
                </tbody>
            </table>
        </div>
    `;
    if (data.invoices.length === 0) return `
        <div class="the-table">
            <table>
                <tbody>
                    <tr>
                        <td class="col-100">No Payment Schedule Entries</td>
                    </tr>
                </tbody>
            </table>
        </div>
    `;
    return `<div>
        ${data.invoices.map((dv,i) => {
            const dat = dv.data ? dv.data : { entries: [] };
            const entries = dat.entries;
            const line_items = dat.line_items;

            return `<div style="margin-bottom:40px">
                <h4>Invoice #${i+1}</h4>
                <div class="the-table">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    #
                                </th>
                                <th>
                                    Payment Value
                                </th>
                                <th>
                                    Option
                                </th>
                                <th>
                                    Notes
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            ${entries.map((p,ii) => {
                                const shouldGetPayment = shouldGetPaymentAmount(p.option);
                                const paym = getPaymentAmount(data, p.option, null, sheetType);
                                const paymentAmount = paym ? formatNumberWithCommas(paym) : '0.00';
                                const displayOnly = ii > 0 ? p.display_only ? 'DISPLAY ONLY' : null : null;
                                if (displayOnly) return '';
                                const val = shouldGetPayment ? paymentAmount : p.value;
                                return `<tr>
                                    <th>
                                        ${ii+1}
                                    </th>
                                    <th>
                                        $${val}
                                    </th>
                                    <th>
                                        ${p.option}
                                    </th>
                                    <th>
                                        ${p.notes}
                                    </th>
                                </tr>`
                            }).join('')}
                        </tbody>
                    </table>

                    ${line_items.length > 0 ? `
                        <div>
                            <hr />
                            <h4>Line Items</h4>
                            <div class="the-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                #
                                            </th>
                                            <th>
                                                Amount
                                            </th>
                                            <th>
                                                Description
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        ${line_items.map((li,i) => {
                                            return `<tr>
                                                <td style="text-align:center">
                                                    #${i+1}
                                                </td>
                                                <td style="text-align:center">
                                                    $${formatDollar(li.amount)}
                                                </td>
                                                <td style="text-align:center">
                                                    ${li.description}
                                                </td>
                                            </tr>`  
                                        }).join('')}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    `: ''}

                </div>

            </div>`
        }).join('')}
    </div>`
}

export default renderPaymentScheduleFullFormPDF;