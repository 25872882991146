import { Dialog } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";
import InvoicePreview from "./InvoicePreview";
import { get } from "lodash";
import { Close } from "@material-ui/icons";
import Loader from "../../../components/Loader/Loader";
import moment from "moment";
import { alphabet } from "../../../shared/utility";

const InvoiceChoose = (props) => {
    const { data, rsType } = props;
    const [openedPreview, setOpenedPreview] = useState(null);
    const invoices = data.invoices ? data.invoices : [];

    console.log('invoice choose', data, rsType);

    return <>
        <Dialog
            open={true}
            keepMounted  
            maxWidth="sm" 
            fullWidth={true}
        >
            <div className="close-btn" onClick={() => { props.close(); }}><Close /></div>
            <div style={{padding:20}}>
                <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <div className="text-center">
                                <h3>Choose Invoice to Preview</h3>
                            </div>
                            {openedPreview && <div><Loader center={true} /></div>}
                            {invoices.map((di,i) => {
                                console.log('invoice', di);
                                const alphabet_ = invoices.length > 1 ? `-${alphabet[i]}` : '';
                                const invoice_number = di.invoice_number ? di.invoice_number : i + 1;
                                if (openedPreview) return null;
                                return <div key={`invoice-${i}`} style={{marginBottom:20,textAlign:'center'}}>
                                    <Button 
                                        color="primary"
                                        onClick={() => {
                                            let title = `Generated from RS illi# ${get(data, "illi_number", "")}`;
                                            if (data.title) {
                                                const extraTitle = ` - Rev ${data.title.split("_").length}`;
                                                title = newInvoiceForm.title + extraTitle;
                                            }
                                            const invoice = `1000${alphabet_}`;
                                            setOpenedPreview({
                                                status: 1,
                                                data: {
                                                    rsData: data,
                                                    invoice_index: i, // used to calculate befores
                                                    attn: '',
                                                    date: moment().format('MM/DD/YYYY'),
                                                    illi: get(data, "illi_number", ""),
                                                    sqft: "",
                                                    agent: "",
                                                    buyer: "",
                                                    rs_id: data.id,
                                                    terms: "",
                                                    title,
                                                    seller: "",
                                                    balance: "",
                                                    bill_to: "",
                                                    invoice,
                                                    project: "",
                                                    total_rate: "",
                                                    total_month: "",
                                                    leasing_term: "",
                                                    total_amount: "",
                                                    consideration: "",
                                                    property_address: "",
                                                    total_commission: "",
                                                    total_annual_rent: "",

                                                    line_items: di.data.line_items,
                                                    entries: di.data.entries,
                                                }
                                            });
                                        }}
                                    >Invoice #{invoice_number}</Button>
                                </div>
                            })}
                        </GridItem>
                </GridContainer>
            </div>
        </Dialog>

        {openedPreview !== null && <InvoicePreview 
            data={openedPreview}
            close={() => {
                setOpenedPreview(null);
            }}
            rsType={rsType}
        />}
    </>
}

export default InvoiceChoose;