import React, { useState, useEffect } from 'react';
import CustomInput from 'components/CustomInput/CustomInput2.jsx';
import InputAdornment from '@material-ui/core/InputAdornment';
import { clone } from '../../shared/utility';

function makeid(length) {
	var result = '';
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

const InputField = (props) => {
    const [value, setValue] = useState(props.value);
	const {classes, isDollar} = props;
	let error = '';
	if (props.validate) {
		if (props.validate.length > 0) {
			props.validate.forEach((val) => {
				if(val !== undefined) {
					const err = val(props.value);
					if (err) {
						error = err;
					}
				}
			});
		}
	}

    useEffect(() => {
		console.log('CustomInput2', props.label, props);
        if (props.value) {
            setValue(props.value);
        }
    }, [props.value]);

    // const formatNumber = (num) => {
    //     if (!num) return "";
	// 	console.log('thenum', num);
	// 	if (typeof num === 'number') {
	// 		num = num.toString();
	// 	}
    //     return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //   };

	  const formatNumber = (num) => {
		if (num === null || num === undefined) return "";
		
		if (typeof num === 'number') {
		  num = num.toString();
		}
	  
		const [integerPart, decimalPart] = num.split(".");
		
		const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	  
		return decimalPart !== undefined 
		  ? `${formattedInteger}.${decimalPart}`
		  : formattedInteger;
	  };

	  const formatPhone = (value) => {
		// Check if value is only digits
		const isNumeric = /^\d+$/.test(value);
	
		if (isNumeric) {
		  const length = value.length;
	
		  if (length === 11) {
			return `+${value.slice(0, 1)} (${value.slice(1, 4)}) ${value.slice(4, 7)}-${value.slice(7)}`;
		  } else if (length === 10) {
			return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6)}`;
		  }
		}
	
		// If not numeric or not 10/11 digits, return raw input
		return value;
	  };

  

	const handleChange = (e) => {
		if (props.is_phone) {
			// Remove all non-digit characters
			const raw = e.target.value;
			setValue(raw);
			if (props.onChange) {
				props.onChange({
					target: {
						value: raw
					}
				});
			}
			return;
		}

		let rawValue = e.target.value.replace(/,/g, ""); // Remove commas
	
		// Allow only numbers and at most one dot
		if (/^\d*\.?\d*$/.test(rawValue)) {
			setValue(rawValue);
			
			if (props.onChange) {
				props.onChange({
					target: {
						value: rawValue
					}
				});
			}
		}
	};
	

	const inputProps = {
		onChange: handleChange,
		onClick: () => {
			if (props.onClick) props.onClick();
		},
		type: props.number_only ? 'number' : 'text',
		autoCapitalize: props.uppercase ? "true" : "false",
		value: props.is_phone ? formatPhone(value) : formatNumber(value),
		autoFocus: props.autoFocus ? true : false,
		role: 'presentation',
		autoComplete: 'nope',
		disabled: props.disabled ? true : props.readonly ? true : false,
		readOnly: props.readonly ? true : false,
		startAdornment: props.startAdornment ? <InputAdornment position="end">{props.startAdornment}<span> </span></InputAdornment> : null,
		endAdornment: props.endAdornment ? <InputAdornment position="start">{props.endAdornment}<span> </span></InputAdornment> : null
	};

	if (props.type) {
		inputProps.type = props.type;
	}

	return (
		<div className={`${(props.readonly && props.readonly_show_gray) ? 'gray-bg-input' : ''}`}>
			<CustomInput
				rows={props.rows ? props.rows : 1}
				labelText={props.label}
				id={props.name ? props.name : 'a' + makeid(5)}
				formControlProps={{
					fullWidth: true
				}}
				name={props.name}
				inputProps={inputProps}
				error={error ? true : false}
				isDollar={isDollar}
				noDollarSign={props.noDollarSign}
			/>
			{props.additionalError && (
				<div className='red ii' style={{marginTop: 0, fontSize: '10px'}}>
					{props.additionalError}
				</div>
			)}
			{error && (
				<div className='red aa' style={{marginTop: 0, fontSize: '10px'}}>
					{error}
				</div>
			)}
			{!error && (
				<div className='red bb' style={{marginTop: 0, opacity: 0, fontSize: '10px'}}></div>
			)}
			{props.similar && !error ? props.similar : null}
		</div>
	);
};

export default InputField;
