import React from 'react';
import { useEffect, useCallback } from 'react';
import { get } from 'lodash';
import { clone } from '../../../shared/utility';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Button from '../../../components/CustomButtons/Button';

import { checkOutsideBrokerOrReferralFirmFields, setReferralFirmFeeAmountChoice } from '../RSheetsLogic';
import Brokers from '../CommonCustomElements/Brokers';
import LessEntry from '../CommonCustomElements/LessEntry';
import LessContacts from '../CommonCustomElements/LessContacts';
import ReferralsLessor from '../CommonCustomElements/ReferralsLessor';

export const LESSOR_OUTSIDE_BROKER_FIELDS = [
	'lessor_outside_broker_company_name',
	'lessor_outside_broker_name',
	'lessor_outside_broker_lastname',
	'lessor_outside_broker_address1',
	'lessor_outside_broker_city',
	'lessor_outside_broker_state',
	'lessor_outside_broker_zip',
	'lessor_outside_broker_contact',
	'lessor_outside_broker_fax',
	'lessor_outside_broker_main_phone',
	'lessor_outside_broker_email',
	'lessor_outside_broker_taxid',
	'lessor_outside_broker_w9',
	'lessor_outside_broker_w9_upload',
	'lessor_outside_broker_commission',
];
export const LESSOR_OUTSIDE_BROKE_NON_REQUIRED_FIELDS = ['lessor_outside_broker_fax','lessor_outside_broker_taxid'];

export const LESSOR_REFERRAL_FIRM_FIELDS = [
	'referral_firm_company_name',
	'referral_firm_name',
	'referral_firm_lastname',
	'referral_firm_address1',
	'referral_firm_city',
	'referral_firm_state',
	'referral_firm_zip',
	'referral_firm_contact',
	'referral_firm_fax',
	'referral_firm_main_phone',
	'referral_firm_email',
	'referral_firm_taxid',
	'referral_firm_w9',
	'referral_firm_w9_upload',
	'referral_firm_fee_amount_choice',
	'referral_firm_fee_amount',
	'referral_firm_fee_amount_percentage',
];
export const LESSOR_REFERRAL_FIRM_NON_REQUIRED_FIELDS = ['referral_firm_fax', 'referral_firm_fee_amount_choice','referral_firm_taxid']

const Step5 = (props) => {
	const {
		setQueryFor,
		getContacts,
		setOpenedContactModal,
		renderElement,
		data,
		setData,
	} = props;
	console.log('Step5', data);

	useEffect(() => {
		setReferralFirmFeeAmountChoice(setData, data)('lessor');
	}, []);

	return (
		<GridContainer>
			{/* Lessor */}
			<GridItem xs={12} sm={12} md={12}>
				<LessEntry 
					data={data}
					setData={setData}
					rsType="No Listing"
					fieldType="lessors"
					fieldName="Lessor"
				/>
				{data.lessors.length === 0 && <div className="red-text" style={{margin:20}}>Lessor Required</div>}
			</GridItem>
			<GridItem xs={12} sm={12} md={12}>
				<hr />
				<LessContacts 
					data={data}
					setData={setData}
					fieldType="lessor_contacts"
					fieldName="Lessor Contact"
				/>
			</GridItem>

			{/* TODO: Ask if No Listing has No Outside Brokers for Lessor */}

			<Brokers 
				data={data}
				setData={setData}
				outside_brokers_field_name="lessor_outside_broker" 
				outside_brokers_field_label="LESSOR"
			/>

			<ReferralsLessor 
				data={data}
				setData={setData}
				setQueryFor={setQueryFor}
				getContacts={getContacts}
				setOpenedContactModal={setOpenedContactModal}
				renderElement={renderElement}
			/>
		</GridContainer>
	);
};

export default Step5;
