import { clone, concat, flatMap, get, isEmpty, orderBy, reverse, sortBy, uniqWith } from "lodash";
import React from "react";
import CloseComponent from "../../components/Close/Close";
import { cloneJ, formatDollar, round } from "../../shared/utility";
import {
    calculateAbatedRanges,
    calculateAdjustedCommission,
    calculateEstimatedAbatedRanges,
    calculateEstimatedTermsTotal,
    getFlatFeeCommission,
    getPaymentAmount,
    isEstimatedLease,
    isFlatFeeCommissionEnabled,
    isSaleRSType,
    getFlatFeeCommissionFromSale,
    getTotalLineItems, 
} from "../RSheets/RSheetsLogic";
import {
    calculateTermsTotal,
    getRemainingBalance,
    getBalanceDue,
    getRsData,
    renderTermTable,
    renderSaleInvoiceDetails,
    getInvoiceFormData,
    calculateBalanceForward,
    calculateBalanceForwardTotal,
    getInvoiceIndex,
    getTermText
} from "./InvoiceLogic";
import { 
    currencyToNumber,
    round2dec,
    checkIfFullMonth,
    checkIfFullYear,
    getMonthEstimate
} from "../../shared/utility";
import { useMemo } from "react";
import termLogic from "../RSheets/CommonCustomElements/Term/TermLogicMain";
import moment from "moment";

const getPaymentSchedule = (parentData) => {
    let arr = [];
    const id = parentData.id;
    const json_data = parentData.json_data;
    if (id && json_data) {
        if (json_data.invoices) {
            arr = json_data.invoices;
        }
    }
    return arr;
}

const retrieveInvoiceIndex = (paymentSchedule, invoice_id) => {
    let index = 0;
    paymentSchedule.forEach((ps,indx) => {
        if (ps.id === invoice_id) {
            index = indx;
        }
    });
    return index;
}

const InvoiceFullForm = (props) => {
    const { closeForm, fieldModels, formData, openDataModal, rsheetData, parentData } = props;
    const rsData = rsheetData ? cloneJ(rsheetData).json_data : null;
    const invoice_id = parentData.id;
    const invoice_index = getInvoiceIndex(rsData, invoice_id);
    if (!rsData) return null;
    const rsInvoiceData = getInvoiceFormData(rsData, invoice_id);
    if (!rsInvoiceData) return null;
    
    let attn = getRsData(rsData, "billing_attention") + " " + getRsData(rsData, "billing_attention_lastname");
    let attnEmail = getRsData(rsData, "billing_email");

    let billingCompany = getRsData(rsData, "billing_company");
    let billingCoc = '';
    if (billingCompany.indexOf('c/o') !== -1) {
        const arr = billingCompany.split('c/o');
        billingCompany = arr[0];
        billingCoc = arr[1];
    }
    let billingAddress = `${getRsData(rsData, "billing_address1")} ${getRsData(rsData, 'billing_address2') ? `, ${getRsData(rsData, 'billing_address2')}` : ''}`;
    let billingCity = getRsData(rsData, "billing_city");
    let billingState = getRsData(rsData, "billing_state");
    let billingZip = getRsData(rsData, "billing_zip");

    let lesseeFieldName = 'Lessee';
    let lessorFieldName = 'Lessor';

    const rsType = get(formData, 'rsType', null);
    // LEASE
    if (rsType === 4) {
        // RS TYPE = SALE
        if (rsType === 4) {
            attn = getRsData(rsData, "escrow_officer_name");
            attnEmail = getRsData(rsData, "escrow_email");

            billingCompany = getRsData(rsData, "escrow_company");
            if (billingCompany.indexOf('c/o') !== -1) {
                const arr = billingCompany.split('c/o');
                billingCompany = arr[0];
                billingCoc = arr[1];
            } else {
                billingCoc = '';
            }
            billingAddress = `${getRsData(rsData, "escrow_address")} ${getRsData(rsData, 'escrow_address2') ? `, ${getRsData(rsData, 'escrow_address2')}` : ''}`;
            billingCity = getRsData(rsData, "escrow_city");
            billingState = getRsData(rsData, "escrow_state");
            billingZip = getRsData(rsData, "escrow_zip");

            lesseeFieldName = 'Buyer';
            lessorFieldName = 'Seller';
        }
    } else if (rsType === 6) {
        // RS TYPE = CONSULTING 
        rsData.flat_fee_commission = 'Yes';
    }

    const billingPart = () => {
        return <div>
            <p>{billingCompany}</p>
            {billingCoc && <p>c/o {billingCoc}</p>}
            <p>{billingAddress}</p>
            <p>{billingCity}, {billingState} {billingZip}</p>
        </div>
    }

    const isEstimated = rsData && isEstimatedLease(rsData);
    console.log('rsData ->', rsData, props);
    const calcs = termLogic.calculateLeaseTerm(rsData);
    const invoice_calcs = termLogic.calculateInvoiceRows(calcs, rsData);
    const payment_schedule_data = get(props, 'parentData.data.payment_schedule_data', null);
    console.log('invoice_calcs InvoiceFullForm', props, payment_schedule_data, invoice_calcs);
    
    let ranges = [];
    get(rsData, "lease_term", []).forEach((lt) => {
        ranges = ranges.concat(lt.ranges);
    });

    const abatedRanges = isEstimated
        ? calculateEstimatedAbatedRanges({
              lease_term: get(rsData, "lease_term", []),
              abated_rent_ranges: get(rsData, "abated_rent_ranges", []),
          })
        : calculateAbatedRanges({
              lease_term: get(rsData, "lease_term", []),
              abated: get(rsData, "abated_rent_ranges", []),
          });
    const totalRanges = isEstimated
        ? orderBy(concat(ranges, abatedRanges), ["month1", "day1"], "asc")
        : sortBy(concat(ranges, abatedRanges), (r) => new Date(r.date1));

    const payment_schedule = get(rsInvoiceData, 'data.entries', []);
    const associates = concat(
            getRsData(rsData, "commission_splits_agent", { isArray: true }),
            getRsData(rsData, "commission_splits_agent2", { isArray: true })
        );

    const totalCommissionBeforeAdjustment = useMemo(() => {
        const flatFeeEnabled = isFlatFeeCommissionEnabled(rsData);
        if (flatFeeEnabled) {
            return getFlatFeeCommission(rsData);
        } else {
            return parseFloat(invoice_calcs.total_commission);
        }
    }, []);

    let saleCommission = 0;
    if (rsType === 4) {
        if (rsData.sale_sale_commission) {
            saleCommission = typeof rsData.sale_sale_commission === 'string' ? 
                parseFloat(rsData.sale_sale_commission.replace(/[$,]/g, ""))
            :
                rsData.sale_sale_commission;
        }
    }

    console.log('totalCommission calculation', invoice_calcs.total_commission, calculateAdjustedCommission(rsData), rsData);
    const totalLineItems = getTotalLineItems(
        rsInvoiceData.data ?
            rsInvoiceData.data.line_items ? 
                rsInvoiceData.data.line_items 
            : 
                []
        :
            []
    );
    const totalCommission = totalCommissionBeforeAdjustment + calculateAdjustedCommission(rsData) + saleCommission;

    const renderLesseeNames = () => {
        const lessees = getRsData(rsData, "lessees", { isArray: true });
        const names = lessees.map((l) => {
            console.log('lless', l);
            const firstName = get(l, "name", "");
            const lastName = get(l, "last_name", "");
            const fullName = `${firstName} ${lastName}`;

			if (fullName.trim() === "") return get(l, "company_name", "");
			return fullName;
        });
        return names.join(", ");
    };

    const renderLessorNames = () => {
        const lessors = getRsData(rsData, "lessors", { isArray: true });
        const names = lessors.map((l) => {
            console.log('llessr', l);
            const firstName = get(l, "name", "");
            const lastName = get(l, "last_name", "");
			const fullName = `${firstName} ${lastName}`;

			if (fullName.trim() === "") return get(l, "company_name", "");
			return fullName;
        });
        return names.join(", ");
    };

    const renderTerms = (invoice_calcs, calcs) => {
        console.log('invoice_calcs', invoice_calcs, calcs);
        const years = get(invoice_calcs, `years`, []);
        // REGULAR
        if (!calcs.is_estimated) {
            const terms = years.map((year,y) => {
                const year_arr = year.rows.map((rr_arr,j) => {
                    const rrarr = rr_arr.map((rr,o) => {
                        console.log('rraww', rr);
                        const ll1 = moment(rr.date1, 'MM/DD/YYYY').format('LL');
                        const ll2 = moment(rr.date2, 'MM/DD/YYYY').format('LL');
                        const termLabel = `${ll1} to ${ll2}`;
                        if (rr.isAbated) {
                            let rr_term_rent = '$0.00';
                            if (rr.abated_value !== rr.term_rent) {
                                rr_term_rent = formatDollar(rr.term_rent - rr.abated_value);
                            }
                            let rr_commission = '$0.00';
                            if (rr.abated_value !== rr.term_rent) {
                                rr_commission = formatDollar((rr.term_rent - rr.abated_value) * (rr.commission_rate * 0.01) );
                            }
                            let rr_commission_rate = '0';
                            if (rr.abated_value !== rr.term_rent) {
                                rr_commission_rate = rr.commission_rate;
                            }
                            return <tr className="fs-roboto" key={`row-${y}-${j}-${o}`}>
                                <td className="col-25" style={{ fontSize: "10px", padding: "2px 7px" }}>
                                    {termLabel} (Abated Rent)
                                </td>
                                <td className="col-14 textRight" style={{ fontSize: "10px", padding: "2px 0x" }}>
                                    {formatDollar(rr.monthly_rent)}
                                </td>
                                <td className="col-14 textRight" style={{ fontSize: "10px", padding: "2px 0x" }}>
                                    {round2dec(rr.total_number_of_months)}
                                </td>
                                <td className="col-14 textRight" style={{ fontSize: "10px", padding: "2px 0x" }}>
                                    {rr_term_rent}
                                </td>
                                <td className="col-14 textRight" style={{ fontSize: "10px", padding: "2px 0x" }}>
                                    {rr_commission_rate} %
                                </td>
                                <td className="col-14 textRight" style={{ fontSize: "10px", padding: "2px 0x" }}>
                                    {rr_commission}
                                </td>
                                <td className="col-5"></td>
                            </tr>
                        } else {
                            return <tr className="fs-roboto" key={`row-${y}-${j}-${o}`}>
                                <td className="col-25" style={{ fontSize: "10px", padding: "2px 7px" }}>
                                    {termLabel}
                                </td>
                                <td className="col-14 textRight" style={{ fontSize: "10px", padding: "2px 0x" }}>
                                    {formatDollar(rr.monthly_rent)}
                                </td>
                                <td className="col-14 textRight" style={{ fontSize: "10px", padding: "2px 0x" }}>
                                    {round2dec(rr.total_number_of_months)}
                                </td>
                                <td className="col-14 textRight" style={{ fontSize: "10px", padding: "2px 0x" }}>
                                    {formatDollar(rr.term_rent)}
                                </td>
                                <td className="col-14 textRight" style={{ fontSize: "10px", padding: "2px 0x" }}>
                                    {rr.commission_rate} %
                                </td>
                                <td className="col-14 textRight" style={{ fontSize: "10px", padding: "2px 0x" }}>
                                    {formatDollar(rr.commission)}
                                </td>
                                <td className="col-5"></td>
                            </tr>
                        }
                    });
                    return rrarr;
                })
                return year_arr;
            })
            return terms;
        } else {
            // ESTIMATED
            const the_years = get(invoice_calcs, `years_compressed`, []);
            // check if there are any abated (if so then format is For Month X to Month X)
            let hasAbated = false;
            the_years.forEach((yr) => {
                if (yr.rows) {
                    yr.rows.forEach((yrr) => {
                        if (yrr.is_abated) hasAbated = true;
                    });
                }
            });
            return <>
                {the_years.map((yr,y) => {
                    const year = `YEAR ${y+1}`;
                    return <>
                        {yr.rows.map((row,r) => {
                            const { months_est, monthly_rent } = row;
                            let termLabel = '';
                            termLabel = `${row.date1} to ${row.date2}`;
                            // if full months - take out Days
                            const isFullMonth = checkIfFullMonth(row.date1, row.date2);
                            const isFullYear = checkIfFullYear(row.date1, row.date2);
                            if (isFullYear && !hasAbated) {
                                termLabel = year;
                                year = '';
                            } else {
                                termLabel = `For ${getMonthEstimate(row.date1)} through ${getMonthEstimate(row.date2)}`;
                            }
                            return (
                                <>
                                    <tr className="fs-roboto" key={`row-${y}-${r}`}>
                                        <td className="col-25" style={{ fontSize: "10px", padding: "2px 7px" }}>
                                            {termLabel}
                                        </td>
                                        <td className="col-14 textRight" style={{ fontSize: "10px", padding: "2px 0x" }}>
                                            {formatDollar(monthly_rent)}
                                        </td>
                                        <td className="col-14 textRight" style={{ fontSize: "10px", padding: "2px 0x" }}>
                                            {months_est}
                                        </td>
                                        <td className="col-14 textRight" style={{ fontSize: "10px", padding: "2px 0x" }}>
                                            {formatDollar(row.term_rent)}
                                        </td>
                                        <td className="col-14 textRight" style={{ fontSize: "10px", padding: "2px 0x" }}>
                                            {row.commission_rate} %
                                        </td>
                                        <td className="col-14 textRight" style={{ fontSize: "10px", padding: "2px 0x" }}>
                                            {formatDollar(row.commission)}
                                        </td>
                                        <td className="col-5"></td>
                                    </tr>
                                </>
                            );
                        })}
                    </>
                })}
            </>
        }
    };

    const renderLineItems = () => {
        const lineItems = rsInvoiceData.data ? 
            rsInvoiceData.data.line_items ? rsInvoiceData.data.line_items : []
        :
            [];
        return lineItems.map((li,idx) => {
            return <tr style={{ paddingTop: "10px" }} key={`line-itms-${idx}`}>
                <td className="col-80" style={{ padding: "5px 7px 0" }}>
                    <span style={{fontWeight: 'bold'}}>{li.description}</span>
                </td>
                <td className="col-20 textRight fs-roboto" style={{ padding: "5px 7px 0px" }}>
                    {formatDollar(li.amount)}
                </td>
            </tr>;
        });
    }

    const renderCommissionAdjustment = () => {
        const commissionAdjustments = get(rsData, "commission_adjustment_details", []);
        const hasCommissionAdjustment =
        get(rsData, "commission_adjustment") === "Yes" && !isEmpty(commissionAdjustments);

		if (!hasCommissionAdjustment) return null;
        return commissionAdjustments.map((ca, idx) => {
            if (idx === 0) {
                return (
                    <tr style={{ paddingTop: "10px" }} key={idx}>
                        <td className="col-80" style={{ padding: "5px 7px 0" }}>
                            <span style={{fontWeight: 'bold'}}>Adjustment</span> {get(ca, "comment", "")}
                        </td>
                        <td className="col-20 textRight fs-roboto" style={{ padding: "5px 7px 0px" }}>
                            {formatDollar(currencyToNumber(get(ca, "value", 0)))}
                        </td>
                    </tr>
                );
            }
            return (
                <tr key={idx}>
                    <td className="col-80" style={{ padding: "0 7px" }}>
                        <span style={{ lineHeight: "13px" }}>
                            <span style={{fontWeight: 'bold'}}>Adjustment</span> {get(ca, "comment", "")}
                        </span>
                    </td>
                    <td className="col-20 textRight fs-roboto" style={{ padding: "0 7px" }}>
                        {formatDollar(currencyToNumber(get(ca, "value", 0)))}
                    </td>
                </tr>
            );
        });
    };

    const renderInvoiceDetails = (indexTo) => {
        console.log('renderInvoiceDetails', indexTo, payment_schedule);
        let newPayments = [];
        if (payment_schedule.length > 0 && !Array.isArray(payment_schedule[0])) {
            newPayments = [clone(payment_schedule)];
        } else {
            newPayments = clone(payment_schedule);
        }

        if (payment_schedule.length === 0) return null;

        const currentInvoice = parseInt(get(rsData, "currentInvoice", 0));

        const paymentsToRender = newPayments[indexTo];
        console.log('payment_schedule', paymentsToRender, newPayments, payment_schedule, currentInvoice, rsData);
        if (!paymentsToRender) return null;

        return paymentsToRender
            .map((pm, index) => {
                const paymentCalc = getPaymentAmount(rsData, pm.option, pm.value);
                console.log('paymentCalc', paymentCalc);
                const dollar = typeof paymentCalc === 'string' ? paymentCalc : formatDollar(paymentCalc);
                const pm_notes = pm.notes ? <div style={{fontSize:9}}> - {pm.notes}</div> : '';
                if (index === 0) {
                    return <tr style={{ paddingTop: "10px" }} key={index}>
                        <td className="col-80" style={{ padding: "5px 7px 0" }}>
                            {pm.option ? pm.option.split(". ")[1] : ''}
                            {pm_notes}
                        </td>
                        <td className="col-20 textRight fs-roboto" style={{ padding: "5px 7px 0px" }}>{dollar}</td>
                    </tr>;
                }
                return <tr key={index}>
                    <td className="col-80" style={{ padding: "0 7px" }}>
                        <span>{pm.option ? pm.option.split(". ")[1] : ''}</span>
                        {pm_notes}
                    </td>
                    <td className="col-20 textRight fs-roboto" style={{ padding: "5px 7px 0px" }}>{dollar}</td>
                </tr>;
            });
    };

    // FIXME
    console.log('totalCommission', totalCommission, props, rsInvoiceData);
    const balanceDue = getBalanceDue({
        line_items: rsInvoiceData.data ? rsInvoiceData.data.line_items : [],
        entries: rsInvoiceData.data ? rsInvoiceData.data.entries : [],
        rsData: rsData
    }, totalCommission);
    const totalPaidToDate = getRemainingBalance(totalCommission - balanceDue, invoice_index, {
        invoicePayments: props.invoicePayments ? props.invoicePayments.filter(ip => {
            return true;
            // if (invoice_id) {
            //     if (ip.invoice_id === invoice_id) {
            //         return true;
            //     }
            // }
        }) : []
    }, rsData.invoices);

    const renderAssociates = () => {
        return (
            <table className="col-30">
                <tbody>
                    {associates.map((a, index) => {
                        const name = a.Name.split(" ");
                        const formattedName = reverse(name).join(", ");
                        if (index === 0) {
                            return (
                                <tr key={`associate-${index}`}>
                                    <td className="col-40 acumin fwsemibold">
                                        Associate(s):
                                    </td>
                                    <td className="col-60">
                                        {formattedName}
                                    </td>
                                </tr>
                            );
                        } else {
                            return (
                                <tr key={`associate-${index}`}>
                                    <td className="col-40"></td>
                                    <td className="col-60">{formattedName}</td>
                                </tr>
                            );
                        }
                    })}
					<tr style={{ height: '15px' }}></tr>
					<tr>
						<td className="col-60">
							<p>
                            	<span className="text-red acumin fwsemibold">illi</span>#
								{formInput("illi")}
                        	</p>
                            {rsData.pm_number && <p>
                                <strong>PM#:</strong> {rsData.pm_number}
                            </p>}
                            <p>
                                {rsData.date_lease_signed ? <>
                                    <strong>Date Lease Signed:</strong> {rsData.date_lease_signed}
                                </> : null}
                            </p>
						</td>
						<td className="col-60">
							
						</td>
					</tr>
                </tbody>
            </table>
        );
    };

    const getBalanceForward = (invoice_index, balanceForward) => {
        /* 
        *
        *   Gets Balance from invoices before
        * 
        */
        let element = null;
        if (invoice_index > 0) {
            element = <tr>
                        <td className="col-70">
                            <span className="fwsemibold acumin" style={{ lineHeight: "10px" }}>
                                Balance Forward:
                            </span>
                        </td>
                        <td
                            className="col-30 textRight fs-roboto"
                            style={{
                                paddingRight: "10px",
                                lineHeight: "10px",
                            }}
                        >
                            {formatDollar(balanceForward)}
                        </td>
                    </tr>
        }
        return element;
    }

    const formInput = (field) => {
        const newField = formData[field];
        const canOverwrite = get(fieldModels, `${field}.canOverwrite`, true);

        return (
            <span
                className={`${!canOverwrite ? "hovered" : ""} line-block ${newField ? "green-block" : "red-block"}`}
                style={{
                    display: "inline-block",
                    margin: "10px 10px 0",
                    minWidth: 100,
                    minHeight: 30,
                }}
                onClick={() => {
                    if (canOverwrite) {
                        openDataModal(field);
                    }
                }}
            >
                {!newField && <span className="gray-text">{fieldModels ? fieldModels[field].label : ""}</span>}
                {newField && (
                    <span>
                        <strong>{newField}</strong>
                    </span>
                )}
            </span>
        );
    };

    console.log('InvoiceFullForm', props);

    const payment_schedule2 = getPaymentSchedule(props.parentData);

    const remaining_balance_due = totalCommission - (totalPaidToDate.paid - totalLineItems);
    const total_amount_this_invoice = balanceDue - totalPaidToDate.paid;
    const total_amount_due_this_invoice = balanceDue - totalPaidToDate.paid;
    const balance_forward = calculateBalanceForwardTotal(invoice_index, props.invoicePayments, rsData);
    const total_balance_due_now = total_amount_this_invoice + balance_forward;

    // Anchor Here 

    const getRemainingBalanceDue = (invoice_index, remainingBalanceDue) => {
        if (invoice_index === 0) {
            return <tr>
                <td className="col-70">
                    <span className="fwsemibold acumin" style={{ lineHeight: "10px" }}>
                        Remaining Balance Due:
                    </span>
                </td>
                <td
                    className="col-30 textRight fs-roboto"
                    style={{
                        paddingRight: "10px",
                        lineHeight: "10px",
                    }}
                >
                    {formatDollar(remainingBalanceDue)}
                </td>
            </tr>
        }
        return null;
    }

    const getTotalAmountThisInvoice = (invoice_index, totalAmountThisInvoice) => {
        if (invoice_index !== 0) {
            return <tr>
                <td className="col-70">
                    <span className="fwsemibold acumin" style={{ lineHeight: "10px" }}>
                        Total Amount This Invoice:
                    </span>
                </td>
                <td
                    className="col-30 textRight fs-roboto"
                    style={{
                        paddingRight: "10px",
                        lineHeight: "10px",
                    }}
                >
                    -{formatDollar(totalAmountThisInvoice)}
                </td>
            </tr>
        }
        return null;
    }

    const getTotalAmountDueThisInvoice = (invoice_index, totalAmountDueThisInvoice) => {
        if (invoice_index === 0) {
            return <div
                className="text-red acumin"
                style={{
                    fontSize: 13,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <p> Total Amount Due This Invoice: </p>
                <p className="fs-roboto" style={{ paddingRight: 4, fontSize: 12 }}>
                    {formatDollar(totalAmountDueThisInvoice)}
                </p>
            </div>
        }
        return null;
    }

    const getTotalBalanceDueNow = (invoice_index, totalBalanceDueNow) => {
        if (invoice_index !== 0) {
            return <div
                className="text-red acumin"
                style={{
                    fontSize: 13,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <p> Total Amount Due This Invoice: </p>
                <p className="fs-roboto" style={{ paddingRight: 4, fontSize: 12 }}>
                    {formatDollar(totalBalanceDueNow)}
                </p>
            </div>
        }
        return null;
    }

    const leaseTermText = getTermText(rsData);

    // If there is a street address and no unit or space number then unit 
    // needs to be removed from invoice and premises address is the street 
    // address on the space
    const unit_or_address = rsData.unit_number ? <tr>
        <td className="col-30" style={{ padding: " 0px 7px 0" }}>
            <span className="fwsemibold acumin">Unit Number:</span>
        </td>
        <td className="col-70">{getRsData(rsData, "unit_number")}</td>
    </tr> : null;

    return (
        <div style={{ padding: "40px 60px" }} className="legible-text">
            <CloseComponent close={() => closeForm()} />

            <div className="invoice-full-form">
                <div className="style6">
                    <div className="style7">
                        <img
                            src="https://staging.connect.illicre.com/assets/logo.png"
                            style={{ width: 85, marginLeft: "-7px" }}
                        />
                        <div className="text-11" style={{ marginTop: 0, marginLeft: 10 }}>
                            <h3 className="invoice-heading acumin-condense">illi Commercial Real Estate</h3>
                            <p style={{ marginTop: "-1px" }}>5945 Canoga Ave.</p>
                            <p style={{ marginTop: "-1px" }}>Woodland Hills, CA 91367</p>
                            <p className="red-text" style={{ marginTop: "-1px" }}>
                                (818) 501-2212
                            </p>
                        </div>
                    </div>
                    <div className="textRight text-11">
                        <p className="text-11">{formInput("date")}</p>
                        <p>
                            <span className="fwsemibold acumin">Invoice</span>#{formInput("invoice")}
                        </p>
                    </div>
                </div>
                <div className="invoice-table col-45 box-shadow" style={{ paddingBottom: 25, marginTop: 35 }}>
                    <h3
                        className="invoice-heading acumin-condense text-center border-red"
                        style={{ borderBottom: "1px solid #da2128" }}
                    >
                        BILL TO
                    </h3>
                    <div style={{ margin: "15px 15px 7px", fontSize: 13 }}>
                        <div>
                            {billingPart()}
                        </div>
                        <div style={{ marginTop: 15 }}>Attn: {attn}</div>
                        {attnEmail && <div>{attnEmail}</div>} 

                    </div>
                </div>
                <div className="invoice-table box-shadow col-100" style={{ marginTop: 15, paddingBottom: 15 }}>
                    <div style={{ display: "flex" }}>
                        <table className="col-70">
                            <tbody>
                                <tr>
                                    <td className="col-30" style={{ padding: "10px 7px 0" }}>
                                        <span className="acumin fwsemibold">Project:</span>
                                    </td>
                                    <td className="col-70" style={{ padding: "10px 0px 0" }}>
                                        {getRsData(rsData, "project_name")}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="col-30" style={{ padding: " 0px 7px 0" }}>
                                        <span className="fwsemibold acumin">Premises Address:</span>
                                    </td>
                                    <td className="col-70">{getRsData(rsData, "premises_address")}, {getRsData(rsData, "city")}</td>
                                </tr>
                                {unit_or_address}
                                <tr>
                                    <td className="col-30" style={{ padding: "5px 7px 0" }}>
                                        <span className="fwsemibold acumin">{lessorFieldName}:</span>
                                    </td>
                                    <td className="col-70" style={{ padding: "5px 0px 0" }}>
                                        {renderLessorNames()}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="col-30" style={{ padding: "0px 7px 0" }}>
                                        <span className="fwsemibold acumin">{lesseeFieldName}:</span>
                                    </td>
                                    <td className="col-70">{renderLesseeNames()}</td>
                                </tr>
                                {(isFlatFeeCommissionEnabled(rsData) && rsType !== 4) && (
                                    <tr>
                                        <td className="col-30" style={{ padding: "27px 7px 0" }}>
                                            <span className="fwsemibold acumin">Commission Breakdown:</span>
                                        </td>
                                        <td className="col-70" style={{ padding: "27px 0 0" }}>Flat Fee for Services Rendered{"  "} {formatDollar(getFlatFeeCommission(rsData))}</td>
                                    </tr>
                                )}
                                {rsType === 4 && (
                                    <tr>
                                        <td className="col-30" style={{ padding: "27px 7px 0" }}>
                                            <span className="fwsemibold acumin">Commission Breakdown:</span>
                                        </td>
                                        <td className="col-70" style={{ padding: "27px 0 0" }}>Flat Fee for Services Rendered{"  "} {formatDollar(getFlatFeeCommissionFromSale(rsData))}</td>
                                    </tr>
                                )}
                                {leaseTermText && <tr>
                                    <td className="col-30" style={{ padding: "27px 7px 0" }}>
                                        <span className="fwsemibold acumin">Lease Term:</span>
                                    </td>
                                    <td className="col-70" style={{ padding: "27px 0 0" }}>
                                        <span className="fwsemibold acumin">{leaseTermText}</span>
                                    </td>
                                </tr>}
                            </tbody>
                        </table>
                        {renderAssociates()}
                    </div>
                    <br />

                    {(!isFlatFeeCommissionEnabled(rsData) && rsType !== 4) && (
                        <table className="col-100">
                            <tbody>
                                <tr className="gray-bar acumin fwsemibold">
                                    <td
                                        className="col-25"
                                        style={{
                                            padding: "2px 7px",
                                            marginBottom: 3,
                                        }}
                                    >
                                        <strong>Term</strong>
                                    </td>
                                    <td className="col-14 textRight">
                                        <strong>Monthly Rent</strong>
                                    </td>
                                    <td className="col-14 textRight">
                                        <strong>Months</strong>
                                    </td>
                                    <td className="col-14 textRight">
                                        <strong>Term Rent</strong>
                                    </td>
                                    <td className="col-14 textRight">
                                        <strong>Rate</strong>
                                    </td>
                                    <td className="col-14 textRight">
                                        <strong>Commission</strong>
                                    </td>
                                    <td className="col-5" />
                                </tr>
                                {renderTerms(invoice_calcs, calcs)}
                                <tr>
                                    <td
                                        className="col-25"
                                        style={{
                                            padding: "5px 7px",
                                            marginBottom: 3,
                                        }}
                                    />
                                    <td className="col-14 textRight" />
                                    <td className="col-14 textRight" />
                                    <td className="col-14 textRight" />
                                    <td className="col-14 textRight" />
                                    <td className="col-14 textRight" />
                                    <td className="col-5" />
                                </tr>
                                <tr className="invoice-total">
                                    <td className="col-25" style={{ padding: "60px 0 0" }} />
                                    <td className="col-14 textRight fwsemibold acumin">
                                        <div style={{ marginTop: 7 }}>Totals:</div>
                                    </td>
                                    <td className="col-14 textRight">
                                        <div
                                            className="bt fs-roboto"
                                            style={{
                                                paddingTop: 5,
                                                fontSize: 10,
                                                width: "40%",
                                            }}
                                        >
                                            {invoice_calcs.total_months}
                                        </div>
                                    </td>
                                    <td className="col-14 textRight">
                                        <div
                                            className="bt fs-roboto"
                                            style={{
                                                paddingTop: 5,
                                                fontSize: 10,
                                                width: "64%",
                                            }}
                                        >
                                            {formatDollar(invoice_calcs.total_term_rent)}
                                        </div>
                                    </td>
                                    <td className="col-14 textRight" />
                                    <td className="col-14 textRight">
                                        <div
                                            className="bt fs-roboto"
                                            style={{
                                                paddingTop: 5,
                                                fontSize: 10,
                                                width: "67%",
                                            }}
                                        >
                                            {formatDollar(invoice_calcs.total_commission)}
                                        </div>
                                    </td>
                                    <td className="col-5" />
                                </tr>
                            </tbody>
                        </table>
                    )}
                </div>
                <h3 style={{ marginTop: 18 }} className="invoice-heading acumin-condense">
                    PAYMENT SCHEDULE:
                </h3>
                <table className="invoice-detail col-100">
                    <tbody>
                        <tr className="gray-bar" style={{ marginTop: 10 }}>
                            <td className="col-80 acumin fwsemibold" style={{ padding: "3px 7px 3px" }}>
                                DESCRIPTION
                            </td>
                            <td className="textRight col-20 acumin fwsemibold" style={{ padding: "0 10px" }}>
                                <strong>AMOUNT DUE</strong>
                            </td>
                        </tr>
                        {renderInvoiceDetails(retrieveInvoiceIndex(payment_schedule2, props.parentData.id))}
                        {renderSaleInvoiceDetails(props.rsheetData ? props.rsheetData.type : null, rsData)}
                        {renderLineItems()}
                    </tbody>
                </table>
                <section
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                        marginTop: 48,
                    }}
                >
                    <div
                        className="inline-block invoice-table box-shadow"
                        style={{ padding: "5px 10px 10px", width: "43%" }}
                    >
                        <h3 className="invoice-heading acumin-condense">PAYMENT INSTRUCTIONS:</h3>
                        <p className="text-7" style={{ marginTop: "-3px" }}>
                            Please include
                            <span className="acumin fwsemibold"> invoice</span> number with payment
                        </p>
                        <p className="acumin fwsemibold" style={{ margin: "6px 0 7px" }}>
                            MAKE CHECK PAYABLE AND MAIL TO:
                        </p>
                        <h3 className="invoice-heading acumin-condense" style={{ fontSize: 16 }}>
                            illi Commercial Real Estate
                        </h3>
                        <p style={{ marginTop: "-2px" }}>5945 Canoga Ave</p>
                        <div style={{ marginTop: "-2px" }}>Woodland Hills, CA 91367</div>
                        <p className="text-red" style={{ marginTop: "-2px" }}>
                            Tax ID: 26-1085204
                        </p>
                    </div>
                    <div className="inline-block" style={{ width: "46%" }}>
                        <h3 style={{ marginBottom: "-2px", marginLeft: 2 }} className="invoice-heading acumin-condense">
                            SUMMARY:
                        </h3>

                        <table className="col-100">
                            <tbody>
                                <tr>
                                    <td className="col-70">
                                        <span className="fwsemibold acumin" style={{ lineHeight: "10px" }}>
                                            Transaction Commission:
                                        </span>
                                    </td>
                                    <td
                                        className="col-30 textRight fs-roboto"
                                        style={{
                                            paddingRight: "10px",
                                            lineHeight: "10px",
                                        }}
                                    >
                                        {formatDollar(totalCommission)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="col-70">
                                        <span className="fwsemibold acumin" style={{ lineHeight: "10px" }}>
                                            Adjustments:
                                        </span>
                                    </td>
                                    <td
                                        className="col-30 textRight fs-roboto"
                                        style={{
                                            paddingRight: "10px",
                                            lineHeight: "10px",
                                        }}
                                    >
                                        {formatDollar(totalLineItems)}
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td className="col-70">
                                        <span className="fwsemibold acumin" style={{ lineHeight: "10px" }}>
                                            Less Total Paid To Date:
                                        </span>
                                    </td>
                                    <td
                                        className="col-30 textRight fs-roboto"
                                        style={{
                                            paddingRight: "10px",
                                            lineHeight: "10px",
                                        }}
                                    >
                                        -{formatDollar(totalPaidToDate.paid)}
                                    </td>
                                </tr>
                                {
                                    // Only for other than first invoice
                                    getBalanceForward(invoice_index, balance_forward)
                                }
                                {
                                    // Only for first invoice
                                    getRemainingBalanceDue(invoice_index, remaining_balance_due)
                                }
                                {
                                    // Only for other than first invoice
                                    getTotalAmountThisInvoice(invoice_index, total_amount_this_invoice)
                                }
                                
                            </tbody>
                        </table>
                        <div
                            className="invoice-table box-shadow"
                            style={{
                                padding: "1px 5px 3px",
                                margin: "5px 0 15px 0px",
                            }}
                        >
                            {getTotalAmountDueThisInvoice(invoice_index, total_amount_due_this_invoice)}
                            {getTotalBalanceDueNow(invoice_index, total_balance_due_now)}
                        </div>
                        <p className="acumin-condense fwsemibold" style={{ margin: "-5px 0 -2px 2px", fontSize: 13 }}>
                            OTHER PAYMENT OPTIONS:
                        </p>
                        <p style={{ marginLeft: 2 }}>Via Zelle Use alisa@illicre.com</p>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default InvoiceFullForm;
