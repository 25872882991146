import { sectionNames, sectionRequirements } from "./RSheetsResidentialModel";
import { residentialFields, subFields } from "./RSheetsResidentialModel";
import {
	commatize,
	get2DecimalRound,
	currencyToNumber,
	formatDollar,
	capitalize
} from "shared/utility";
import {
    calculateAbatedRanges as calculateAbatedRanges3,
    calculateAdjustedCommission,
    calculateEstimatedAbatedRanges,
    calculateEstimatedTermsTotal,
    calculateEstimatedTotal,
    calculateLeaseTermTotal,
    calculateTermsTotal,
    isEstimatedLease,
    isFlatFeeCommissionEnabled,
    validCommissions,
    shouldGetPaymentAmount,
    getPaymentAmount,
    calculateAbatedRanges_RS as calculateAbatedRanges,
    getDateDifference,
    makeAllRanges,
    makeTotalCalcs,
    calculateLeaseLogic_RS as calculateLeaseLogic,
    getAbatedTotal,
    calculateStartingRent,
    calculateAbatedValues,
    extractRequirements,
	newYearlyCalculation,
    calculateYearAbatedRanges,
    checkInternalReview,
    checkThePaymentSchedule,
    validCommissions2,
    renderHTMLHead,
    typeDescriptionsDict,
    checkTheLessees,
    checkTheLessors,
} from "../RSheetsLogic";
import { concat, flatMap, get, isEmpty, sum } from "lodash";
import {
    email,
    max10,
    max100,
    max15,
    max20,
    max250,
    max4,
    max50,
    max500,
    min1,
    min10,
    min4,
    min6,
    no_letters,
    no_numbers,
    no_special_chars,
    required,
} from "shared/validation";
import { renderCommissionAdjustmentsHTML } from "../CommonCustomElements/CommissionAdjustmentTable";
import termLogic from '../CommonCustomElements/Term/TermLogicMain';
import { clone } from "../../../shared/utility";
import { renderOutsideBrokersHTML } from "../CommonCustomElements/BrokersLogic";
import { validatePresplits } from "../CommonCustomElements/PreSplitsForm";
import renderPaymentScheduleFullFormPDF from "../CommonCustomElements/PaymentScheduleFullFormPDF";

const validate = {
    min1,
    min6,
    min4,
    min10,
    email,
    required,
    no_letters,
    no_special_chars,
    no_numbers,
    max4,
    max10,
    max15,
    max20,
    max50,
    max100,
    max250,
    max500,
};

const findLabel = (field) => {
    let label = '';
    const fields = Object.keys(residentialFields);
    for (let i = 0; i < fields.length; i++) {
        const sfield = fields[i];
        if (sfield === field) {
            if (residentialFields[field]) {
                if (residentialFields[field].label) {
                    label = residentialFields[field].label;
                    break;
                } 
            }
        }
    }
    if (!label) {
        return field.toUpperCase();
    }
    return label;
}

export const statusDescriptions = {
    1: "Draft",
    2: "Submitted to level 1",
    3: "Submitted to level 2",
    4: "Submitted to level 3",
    5: "Level 1 Denied to Submitter",
    6: "Level 2 Denied to Level 1",
    7: "Level 3 Denied to Level 2",
    8: "Final Approver denied to Level 3",
    9: "Final Approver approved",
    10: "Submitted to final approver for approval",
};

export const doAction = (tab, getRsheets, status) => {
    if (tab === "user_drafts") {
        getRsheets({
            type: 2,
            status: [1, 5],
        });
    } else if (tab === "my_drafts") {
        getRsheets({
            type: 2,
            own: "true",
            status: [1, 5],
        });
    } else if (tab === "pending_my_drafts") {
        getRsheets({
            type: 2,
            status,
        });
    } else if (tab === "pending_further_approval") {
        getRsheets({
            type: 2,
            status,
        });
    } else if (tab === "my_completed") {
        getRsheets({
            type: 2,
            status: 9,
            own: true,
        });
    } else if (tab === "all_completed") {
        getRsheets({
            type: 2,
            status: 9,
        });
    }
};

export const generateSections = (data, internalReviewers) => {
    // checking if data is complete

	let sections = [];
	Object.values(sectionNames).forEach((v, index) => {
		if (data) {
			let complete = true;
            let errors = [];

            if (sectionRequirements['Lease Information']) {
				sectionRequirements['Lease Information'].forEach((vd) => {
					if (
						v === 'Lease Information' &&
						typeof data[`${vd}_estimated`] !== 'undefined'
					) {
						const date_is_estimated = isEstimatedLease(data, false);
						const dataArr = data[`${vd}_estimated_tbd`]
							? data[`${vd}_estimated_tbd`].split(',')
							: [];
						const value =
							data[`${vd}_estimated`] && dataArr.indexOf('TBD') !== -1
								? 'TBD'
								: data[vd]
								? data[vd]
								: '';
						if (!value) {
							complete = false;
						}
						if (date_is_estimated && !data.lease_length) {
							complete = false;
						}

						if (!date_is_estimated && !data.lease_end_date) {
							complete = false;
						}
					} 
				});
			}

            if (v === 'Property Information') {
                // Required to have a value
                [
                    'date','project_name','la_city_verification','illi_number',
                    'project_address',
                    //'lead_source',
                    // 'can_deal_be_submitted_to_public_relations',
                    // 'type_of_lease',
                    // 'deal_generated_by',
                    // 'property_type',
                    'illi_represented_lessor', // TODO: if yes -> are associates required ?
                    'illi_represented_lessee'
                ].forEach(field => {
                    if (!data[field]) {
                        complete = false;
                        const fieldLabel = findLabel(field);
                        errors.push(`${fieldLabel} is required`);
                    }
                });

                if (data.listings_procured_by) {
                    if (data.listings_procured_by.length === 0) {
                        complete = false;
                        errors.push('Listings Procured By is required');
                    }
                }
            }

            if (v === 'Premises Information') {
                // Required to have a value
                [
                    'premises_address',
                    'city',
                    'state',
                    'zip',
                    'county',
                    'loopnet_marketing_updated',
                ].forEach(field => {
                    if (!data[field]) {
                        complete = false;
                        const fieldLabel = findLabel(field);
                        errors.push(`${fieldLabel} is required`);
                    }
                });

            }

            if (v === 'Lease Information') {
                [
                    //'business_name',
                    //'type_of_business',
                    'lease_start_date',
                    //'lease_end_date',
                    'rent_commencement',
                    'date_lease_signed',
                    'occupancy_date',
                    //'early_possession',
                    'flat_fee_commission',
                    //'option_renewal',
                    //'option_commission',
                    //'lease_type',
                    'contingency'
                ].forEach(field => {
                    if (!data[field]) {
                        complete = false;
                        const fieldLabel = findLabel(field);
                        errors.push(`${fieldLabel} is required`);
                    }
                });

                if (data.early_possession === 'Yes') {
                    // REMOVED 1/31/2025
                    // if (!data.early_possession_date) {
                    //     complete = false;
                    //     errors.push('Early possession date is required');
                    // }
                }
                if (data.contingency === 'Yes') {
                    if (!data.contingency_detail) {
                        complete = false;
                        errors.push('Contingency Detail required');
                    }
                }
                if (data.flat_fee_commission === 'Yes') {
                    if (!data.flat_fee_commission_amount) {
                        complete = false;
                        errors.push('Flat fee commission amount required');
                    }
                } else {
                    if (data.rate_type === "Fixed Percentage") {
                        if (!data.rate_type_cpi_percentage) {
                            complete = false;
                            errors.push('Percentage rate required');
                        }
                    }
                    if (!data.rate_type) {
                        complete = false;
                        errors.push('Rate Type required');
                    }
                }
            }

            if (v === 'Pre-Splits') {
                const vp = validatePresplits(data);
                const hasPresplits = data.presplits ? data.presplits.length > 0 ? true : false : false;
                if (!vp && hasPresplits) {
                    complete = false;
                    errors.push('Pre-splits section error');
                }
            }

            if (v === 'Lessee') {
                const errCheck = checkTheLessees(data);
                if (errCheck.errors.length > 0) {
                    errors = errors.concat(errCheck.errors);
                }
                if (!errCheck.complete) complete = false;
            }

            if (v === 'Lessor') {
                const errCheck = checkTheLessors(data);
                if (errCheck.errors.length > 0) {
                    errors = errors.concat(errCheck.errors);
                }
                if (!errCheck.complete) complete = false;
            }
            

            if (v === 'Commission Splits') {
                if (!validCommissions2(data)) {
                    errors.push('Invalid commission splits');
                    complete = false;
                }
            }

            if (v === 'Commission Adjustments') {
				const commission_adjustment = get(data, 'commission_adjustment', null);
				const commission_adjustment_details = get(
					data,
					'commission_adjustment_details',
					[]
				);

				if (
					commission_adjustment === 'No' ||
					!isEmpty(commission_adjustment_details)
				) {
					
				} else {
                    errors.push('Commission Adjustment details are required');
					complete = false;
				}
			}

            if (v === 'Billing Information') {
                ['address1','city','state','zip','phone',
                'email'].forEach(bfield => {
                    const field = `billing_${bfield}`;
                    if (!data[field]) {
                        complete = false;
                        const fieldLabel = findLabel(field);
                        errors.push(`${fieldLabel} is required`);
                    }
                })
                if (!data.billing_contact_id && !data.billing_company_id && !data.billing_id) {
                    errors.push(`Billing Contact or Company is required`);
                    complete = false;
                }
            }

            if (v === 'Copy of Invoice') {
                if (data.copy_of_invoice) {
                    if (data.copy_of_invoice.indexOf('Other') !== -1) {
                        if (!data.copy_of_invoice_other) {
                            errors.push('Copy of Invoice (other) is required');
                            complete = false;
                        }
                    }
				} else {
                    errors.push('At least one invoice is required');
                    complete = false;
                }
            }

            if (v === 'Payment Schedule') {
                const payment_schedule_check = checkThePaymentSchedule(data);
                if (!payment_schedule_check) {
                    complete = false;
                    errors.push('Payment Schedule Incomplete');
                }
			}

            if (v === 'Comments and Special Instructions') {
                if (!data.comments_and_special_instructions) {
                    errors.push('Comments and Special Instructions required');
                    complete = false;
                }
            }

            if (v === 'Documents') {
                if (data.documents) {
                    if (data.documents.length === 0) complete = false;
                } else {
                    complete = false;
                }
            }

            if (v === 'Internal Review') {
                const check = checkInternalReview({
                    data,
                    complete: JSON.parse(JSON.stringify(complete)),
                    errors: JSON.parse(JSON.stringify(errors)),
                    internalReviewers
                });
                complete = check.complete;
                errors = check.errors;
            }

			sections.push({
				name: v,
				complete,
                errors
			});
		}
	});
    console.log('sections', sections, data);
	return sections;
};

export const getRsTypeName = (id, rsTypes) => {
    if (!id || !rsTypes || !Array.isArray(rsTypes)) return "";
    const type = rsTypes.find((item) => item.id === id);
    if (!type) return "";
    return type.name;
};

export const renderHTML = (data, rsData = null) => {
    const diffObj = getDateDifference(data);
    const calcs = termLogic.calculateLeaseTerm(data);
    const isEstimated = isEstimatedLease(data);

    // FIXME - DEPRECATED
    const isFlatFee = data.flat_fee_commission === "Yes";

    const shouldShowRanges = !isFlatFeeCommissionEnabled(data);

    const hideStartingRentPerSqft = get(data, "flat_fee_space", "") === "Yes";

    const hasLesseeAssociate = get(data, "illi_represented_lessee", "") !== "No";
    const hasLessorAssociate = get(data, "illi_represented_lessor", "") !== "No";

    const make = (field) => {
        let value = get(data, field, "");
        
        if (value === null) value = '';

        const prefix =
            residentialFields[field].prefix && residentialFields[field].prefix !== "$"
                ? `<span>${residentialFields[field].prefix}</span>`
                : "";

        return `<span><strong>${residentialFields[field].label} :</strong>${prefix} ${value}</span>`;
    };

    const makeCheckboxes = (field) => {
        const htmlSnippet = `<span>${residentialFields[field].options
            .map((o) => {
                if (!o) return "";
                if (data[field]) {
                    if (data[field].indexOf(o) !== -1) {
                        return `<span class="ml-10 grayed"><input type="checkbox" checked />${o}</span>`;
                    }
                }
                return `<span class="ml-10 grayed"><input type="checkbox" />${o}</span>`;
            })
            .join("")}</span>`;

        return htmlSnippet;
    };

    const makeArray = (field, key, is_total) => {
        if (key === "multi-dates") {
            if (field === "lease_term") {
                return `
                  <ul class="list-unstyled">
                      ${data.lease_term
                          .map((m, i) => {
                              let all_total = 0;
                              let total_abated = 0;
                              if (field === "lease_term") {
                                  if (data.abated_rent_ranges) {
                                      if (data.abated_rent_ranges[i]) {
                                          const tot = getAbatedTotal(data.abated_rent_ranges[i], data);
                                          total_abated += tot;
                                      }
                                  }
                              }
                              return `<li>
                              <h4>Year ${m.year_num}</h4>
                              ${m.ranges.length === 0 ? `<div><em>None</em></div>` : ""}
                              ${m.ranges
                                  .map((mr, j) => {
                                      const { value, month1, month2, day1, day2, date1, date2, commissionRate } = mr;

                                      const term = isEstimated
                                          ? `<span>
                                          ${month1} ${day1} - ${month2} - ${day2}
                                      </span>`
                                          : `<span>
                                              ${mr.date1} to ${mr.date2}
                                          </span>`;

                                      const totalTerm = isEstimated ? calculateEstimatedTotal(mr) : null;

                                      const calcs = calculateLeaseLogic({
                                          date1,
                                          date2,
                                          value,
                                          data,
                                      });
                                      let allTotal = 0;
                                      calcs.forEach((cc) => {
                                          allTotal += cc.calcRounded;
                                      });
                                      all_total += allTotal;
                                      return `<div>
                                      ${term} - $${commatize(mr.value, true)} 
                                      ${
                                          field === "lease_term"
                                              ? `<div>${
                                                    commissionRate ? commatize(commissionRate, true) : 0
                                                }% commission rate</div>`
                                              : ""
                                      }
                                      <hr />
                                  </div>`;
                                  })
                                  .join("")}
                          </li>`;
                          })
                          .join("")}
                  </ul>
              `;
            } else if (field === "abated_rent_ranges") {
                return `<ul class="list-unstyled">
              ${data[field]
                  .map((m, i) => {
                      return `<li>
                      <h4>Year ${m.year_num}</h4>
                      ${m.ranges
                          .map((mr, j) => {
                            const lease_term = get(data, "lease_term", []);
        
                            const abatedRanges = calculateAbatedValues({
                                lease_term,
                                abated_range: mr,
                            });

                            const totalAbated = calculateTermsTotal(abatedRanges);

                            const term = isEstimated
                                ? `${mr.month1} ${mr.day1} - ${mr.month2} ${mr.day2}`
                                : `${mr.date1} to ${mr.date2}`;
                            return `<div>
                            <span class="mr-20">${term}</span> - ${formatDollar(totalAbated.totalTermRent, true)}
                          </div>`;
                          })
                          .join("")}
                  </li>`;
                  })
                  .join("")}
          </ul>`;
            }
            return `<ul class="list-unstyled">
              ${data[field]
                  .map((m, i) => {
                      return `<li>
                      <h4>Year ${m.year_num}</h4>
                      ${m.ranges
                          .map((mr, j) => {
                              return `<div>
                              <span class="mr-20">${mr.date1} to ${mr.date2}</span> - $${commatize(mr.value)}
                          </div>`;
                          })
                          .join("")}
                  </li>`;
                  })
                  .join("")}
          </ul>`;
        } else if (key === "multi-contact") {
            return `<ul class="list-unstyled">
              ${data[field]
                  .map((m, i) => {
                        let contactPhone = `Main Phone: ${m.main_phone || ""}<br />`;
                        if (a.phone_type === 'Mobile') {
                            contactPhone = `Mobile Phone: ${m.mobile_phone || ""}<br />`;
                        } else if (a.phone_type === 'Work') {
                            contactPhone = `Work Phone: ${m.work_phone || ""}<br />`;
                        }
                      return `<li>
                  <div className="bordered-thin">
                      First Name: ${m.name || ""}<br />
                      Last Name: ${m.last_name || ""}<br />
                      ${m.dba_name ? `DBA: ${m.dba_name} <br />` : ''}
                      Address 1: ${m.address1 || ""}<br />
                      Address 2: ${m.address2 || ""}<br />
                      City: ${m.city || ""}<br />
                      State: ${m.state || ""}<br />
                      Zip: ${m.zip || ""}<br />
                      Email: ${m.email || ""}<br />
                      ${contactPhone}
                  </div>
                  </li>`;
                  })
                  .join("<br />")}
          </ul>`;
        } else if (key === "name") {
            let html = ``;
            if (data[field]) {
                html = data[field]
                    .map((m) => {
                        // return m.name + " " + m.last_name;
                        return `${m.name} ${m.last_name} ${m.dba_name ? `DBA ${m.dba_name}` : ''}`;
                    })
                    .join(", ");
            }
            return html;
        } else {
            let html = ``;
            if (data[field]) {
                html = data[field].map((m) => m[key]).join(", ");
            }
            return html;
        }
    };

    const renderLessors = () => {
        const model = subFields.lessor;
        const orderedKeys = [
            "id",
            "company_name",
            "name",
            "last_name",
            "dba_name",
            "main_phone",
            "mobile_phone",
            "work_phone",
            "email",
            "address1",
            "address2",
            "city",
            "state",
            "zip",
            "fax",
            "guarantor",
            "upload_sos",
            "guarantor_name",
            "spousal_consent",
            "agency_disclosure",
            "llc_corp_verified",
            "upload_spousal_id",
            "upload_guarantor_id",
            "guarantor_spouse_name",
            "can_we_reach_out_for_testimonial",
            "can_we_reach_out_for_testimonial_comment",
        ];
        return `<div>
          ${data.lessors
              .map((dl, i) => {
                  return `<div>
                  <h4>Lessor ${i + 1}</h4>
                  ${orderedKeys
                      .map((field) => {
                        // HIDE UNSELECTED
                        if (dl.phone_type) {
                            if (dl.phone_type === 'Work') {
                                if (field === 'mobile_phone' || field === 'main_phone') return '';
                            } else if (dl.phone_type === 'Mobile') {
                                if (field === 'work_phone' || field === 'main_phone') return '';
                            } else if (dl.phone_type === 'Main') {
                                if (field === 'work_phone' || field === 'mobile_phone') return '';
                            }
                        } else {
                            if (field === 'work_phone' || field === 'mobile_phone') return '';
                        }

                          if (field === "id" || !model[field] || !Object.keys(dl).includes(field)) return '';
                          if (dl.company_name) {
                              if (field === "name" || field === "last_name") {
                                  return '';
                              }
                          } else {
                              if (field === "company_name") {
                                  return '';
                              }
                          }
                          return `<div>
                          <strong>${model[field].label}</strong> : <span>${dl[field] ? dl[field] : "n/a"}</span>
                      </div>`;
                      })
                      .join("")}
              </div>`;
              })
              .join("<br />")}
      </div>`;
    };

    const renderLessees = () => {
        const model = subFields.lessee;
        const orderedKeys = [
            "id",
            "company_name",
            "name",
            "last_name",
            "dba_name",
            "main_phone",
            "mobile_phone",
            "work_phone",
            "email",
            "address1",
            "address2",
            "city",
            "state",
            "zip",
            "fax",
            "guarantor",
            "upload_sos",
            "guarantor_name",
            "spousal_consent",
            "agency_disclosure",
            "llc_corp_verified",
            "upload_spousal_id",
            "upload_guarantor_id",
            "guarantor_spouse_name",
            "can_we_reach_out_for_testimonial",
            "can_we_reach_out_for_testimonial_comment",
        ];
        return `<div>
          ${data.lessees
              .map((dl, i) => {
                  return `<div>
                  <h4>Lessee ${i + 1}</h4>
                  ${orderedKeys
                      .map((field) => {
                        // HIDE UNSELECTED
                        if (dl.phone_type) {
                            if (dl.phone_type === 'Work') {
                                if (field === 'mobile_phone' || field === 'main_phone') return '';
                            } else if (dl.phone_type === 'Mobile') {
                                if (field === 'work_phone' || field === 'main_phone') return '';
                            } else if (dl.phone_type === 'Main') {
                                if (field === 'work_phone' || field === 'mobile_phone') return '';
                            }
                        } else {
                            if (field === 'work_phone' || field === 'mobile_phone') return '';
                        }

                          if (field === "id" || !model[field] || !Object.keys(dl).includes(field)) return '';
                          if (dl.company_name) {
                              if (field === "name" || field === "last_name") {
                                  return '';
                              }
                          } else {
                              if (field === "company_name") {
                                  return '';
                              }
                          }
                          return `<div>
                          <strong>${model[field].label}</strong> : <span>${dl[field] ? dl[field] : "n/a"}</span>
                           </div>`;
                      })
                      .join("")}
                      </div>`;
              })
              .join("<br />")}
      </div>`;
    };

    const renderCommissionsLessor = () => {
        const total =
            data.commission_splits_agent
                .map((acc) => {
                    if (acc.Percentage === "" || isNaN(acc.Percentage)) {
                        acc.Percentage = "0";
                        return acc;
                    } else {
                        return acc;
                    }
                })
                .reduce((acc, curr) => {
                    return acc + parseFloat(curr.Percentage);
                }, 0) + "%";

        return `<tbody>
              <tr>
                  <td className="col-50">
                      <strong>Name</strong>
                  </td>
                  <td className="col-50">
                      <strong>Percentage</strong>
                  </td>
              </tr>
          ${data.commission_splits_agent
              .map((c, i) => {
                  return `<tr>
                          <td className="col-50">
                              ${c.Name} ${c.dba_name ? `DBA ${c.dba_name}` : ''}
                          </td>
                          <td className="col-50">
                              ${c.Percentage}
                          </td>
                      </tr>`;
              })
              .join("")}
          <tr>
              <td className="col-50">
                  <strong>Total</strong>
              </td>
              <td className="col-50">
                  ${total}
              </td>
          </tr>
      </tbody>`;
    };

    const renderCommissionsLessee = () => {
        const total =
            data.commission_splits_agent2
                .map((acc) => {
                    if (acc.Percentage === "" || isNaN(acc.Percentage)) {
                        acc.Percentage = "0";
                        return acc;
                    } else {
                        return acc;
                    }
                })
                .reduce((acc, curr) => {
                    return acc + parseFloat(curr.Percentage);
                }, 0) + "%";

        return `<tbody>
              <tr>
                  <td className="col-50">
                      <strong>Name</strong>
                  </td>
                  <td className="col-50">
                      <strong>Percentage</strong>
                  </td>
              </tr>
          ${data.commission_splits_agent2
              .map((c, i) => {
                  return `<tr>
                          <td className="col-50">
                              ${c.Name} ${c.dba_name ? `DBA ${c.dba_name}` : ''}
                          </td>
                          <td className="col-50">
                              ${c.Percentage}
                          </td>
                      </tr>`;
              })
              .join("")}
          <tr>
              <td className="col-50">
                  <strong>Total</strong>
              </td>
              <td className="col-50">
                  ${total}
              </td>
          </tr>
      </tbody>`;
    };

    const renderCommissionsOtherLessor = () => {
        return `<tbody>
          <tr>
              <td className="col-50">
                  <strong>Name</strong>
              </td>
              <td className="col-50">
                  <strong>Value</strong>
              </td>
          </tr>
          <tr>
              <td className="col-50">
                  Lessor Referral
              </td>
              <td className="col-50">
                  ${
                      data.referral_firm_fee_amount_choice === "Value"
                          ? data.referral_firm_fee_amount
                          : data.referral_firm_fee_amount_percentage + " %"
                  }
              </td>
          </tr>
      </tbody>
      `;
    };

    const renderCommissionsOtherLessee = () => {
        return `<tbody>
          <tr>
              <td className="col-50">
                  <strong>Name</strong>
              </td>
              <td className="col-50">
                  <strong>Value</strong>
              </td>
          </tr>
          <tr>
              <td className="col-50">
                  Lessee Referral
              </td>
              <td className="col-50">
                  ${
                      data.lessee_referral_firm_fee_amount_choice === "Value"
                          ? data.lessee_referral_firm_fee_amount
                          : data.lessee_referral_firm_fee_amount_percentage + " %"
                  }
              </td>
          </tr>
      </tbody>
      `;
    };


    const renderReferrals = (name) => {
        const prefix = name === 'lessor' ? '' : 'lessee_';
        const html = [];

        if (!data[`${name}_referrals`]) return '';

        data[`${name}_referrals`].forEach((ref, i) => {
            const fields = [];

            ['company_name', 'name', 'lastname', 'contact_dba_name', 'address1', 'city', 'state', 'zip', 'contact', 'main_phone', 'fax', 'email', 'taxid', 'w9'].forEach((fld) => {
                const field = `${prefix}referral_firm_${fld}`;
                fields.push(`<div><strong>${residentialFields[field].label}</strong> : ${ref[fld] || ''}</div>`);
            });

            const a = `<div>
                <h4>Referral #${i + 2}</h4>
                ${fields.join('')}
            </div>`;

            html.push(a);
        });

        return html.join('');
    }

    const renderField = (field) => {
        if (data[field]) return data[field];
        return "";
    };

    const lessorOutsideBrokersHTML = renderOutsideBrokersHTML({
        data,
        title: "LESSOR",
        field: "lessor_outside_broker"
    });

    const lesseeOutsideBrokersHTML = renderOutsideBrokersHTML({
        data,
        title: "LESSEE",
        field: "outside_broker"
    });

    return `
      <html>
          ${renderHTMLHead()}
      <body>
          <div>
              <table style="width:100%">
                  <tbody>
                      <tr>
                          <td class="col-10"><img src="https://illicre.com/wp-content/themes/zoacres/assets/illi-logo-simple.png" style="width:60px" /></td>
                          <td class="col-90">
                              <div class="text-center"><h3>ROUTING SHEET - RESIDENTIAL</h3></div>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <div class="the-table">
              <table>
                  <tbody>
                      <tr>
                          <td class="col-20">${make("date")}</td>
                          <td class="col-60">${make("project_name")}</td>
                          <td class="col-20">${make("illi_number")}</td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <div class="the-table">
              <table>
                  <tbody>
                      <tr>
                          <td class="col-80 no-bt">${make("project_address")}</td>
                          ${
                              data.pm_number
                                  ? `<td class="col-20 no-bt">${make("pm_number")}</td>`
                                  : `<td class="col-20 no-bt"></td>`
                          }
                      </tr>
                  </tbody>
              </table>
          </div>
          <div class="the-table">
              <table>
                  <tbody>
                      <tr>
                          <td class="col-80 no-bt bold">Is Property Located in the City of Los Angeles ?</td>
                          <td class="col-20 no-bt">${makeCheckboxes("la_city_verification")}</td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <div class="the-table">
              <table>
                  <tbody>
                      <tr>
                          <td class="col-30 no-bt">
                              <strong>Listings Procured By : </strong>
                              ${makeArray("listings_procured_by", "name")}
                          </td>
                          <td class="col-30 no-bt">${make("lead_source")}</td>
                          <td class="col-40 no-bt">
                              <strong>Deal Generated By : </strong>
                              ${makeCheckboxes("deal_generated_by")}
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <div class="the-table">
              <table>
                  <tbody>
                      <tr>
                          <td class="col-40 no-bt">
                              <strong>illi Represented Lessor :</strong>
                              ${makeCheckboxes("illi_represented_lessor")}
                          </td>
                          <td class="col-60 no-bt"><strong>Agent(s)</strong> : ${makeArray(
                              "illi_represented_lessor_agents",
                              "name"
                          )}</td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <div class="the-table">
              <table>
                  <tbody>
                      <tr>
                          <td class="col-40 no-bt">
                              <strong>illi Represented Lessee :</strong>
                              ${makeCheckboxes("illi_represented_lessee")}
                          </td>
                          <td class="col-60 no-bt"><strong>Agent(s)</strong> : ${makeArray(
                              "illi_represented_lessee_agents",
                              "name"
                          )}</td>
                      </tr>
                  </tbody>
              </table>
          </div>

          <br />
          <br />
          <div class="the-table">
              <table>
                  <tbody>
                      <tr>
                          <td class="col-50">
                              ${make("premises_address")}
                          </td>
                          <td class="col-50">
                              ${make("unit_number")}
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <div class="the-table">
              <table>
                  <tbody>
                      <tr>
                          <td class="col-50 no-bt">
                              ${make("city")}
                          </td>
                          <td class="col-50 no-bt">
                              ${make("zip")}
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <div class="the-table">
              <table>
                  <tbody>
                      <tr>
                          <td class="col-60 no-bt">
                              ${make("county")}
                          </td>
                          <td class="col-40 no-bt">
                              <strong>MLS Updated? : </strong>
                              ${makeCheckboxes('loopnet_marketing_updated')}
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <br />
          <br />
          <div>
              <h4 class="underlined">LEASE INFORMATION</h4>
          </div>
          <div class="the-table">
              <table>
                  <tbody>
                      <tr>
                          <td class="col-50">
                              ${make("lease_start_date")}
                          </td>
                          <td class="col-50">
                              ${make("date_lease_signed")}
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>

          <div class="the-table">
              <table>
                  <tbody>
                      <tr>
                          <td class="col-50 no-bt">
                            ${make("lease_end_date")}
                          </td>
                          <td class="col-50 no-bt">
                              ${make("occupancy_date")}
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>

          ${
              !hideStartingRentPerSqft
                  ? `<div class="the-table">
              <table>
                  <tbody>
                      <tr>
                          <td class="col-50 no-bt">
                          </td>
                          <td class="col-50 no-bt">
                              ${make("rent_commencement")}
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>`
                  : ""
          }

          <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                class="col-50 no-bt"
                            >
                                <div>
                                    <strong>Contingency</strong> ${makeCheckboxes("contingency")}
                                </div>
                            </td>
                            ${
                                data.contingency === "Yes"
                                    ? `<td
                                    class="col-50 no-bt"
                                >
                                    ${make("contingency_detail")}
                                </td>`
                                    : `<td class="col-50 no-bt">
                                    <strong>Contingency Detail:</strong>
                                </td>`
                            }
                        </tr>
                    </tbody>
                </table>
            </div>

          <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                class="col-50 no-bt"
                            >
                                <div>
                                    <strong>Flat Fee Commission</strong> ${makeCheckboxes("flat_fee_commission")}
                                </div>
                            </td>
                            ${
                                data.flat_fee_commission === "Yes"
                                    ? `<td
                                    class="col-50 no-bt"
                                >
                                    ${make("flat_fee_commission_amount")}
                                </td>`
                                    : `<td class="col-50 no-bt">
                                    <strong>Flat Fee Commission Amount:</strong> N/A
                                </td>`
                            }
                        </tr>
                    </tbody>
                </table>
            </div>

			<div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                class="col-50 no-bt"
                            >
                                
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="the-table">
                <table>
                    <tbody>
                        <tr style="${data.flat_fee_commission === "Yes" ? "display:none" : ""}">
                            <td
                                class="col-50 no-bt"
                            >
                                <div>
                                    <strong>Rental Increases</strong> ${makeCheckboxes("rate_type")}
                                </div>
                            </td>
                            ${
                                data.rate_type === "Fixed Percentage"
                                    ? `<td
                                    class="col-50 no-bt"
                                >
                                    ${make("rate_type_cpi_percentage")}
                                </td>`
                                    : `<td class="col-50 no-bt">
                                    <strong>Percentage Rate:</strong> N/A
                                </td>`
                            }
                        </tr>
                    </tbody>
                </table>
            </div>

          ${
              shouldShowRanges
                  ? `
          <div class="the-table">
          <table>
              <tbody>
                  <tr>
                      <td class="col-50">
                          <strong>Lease Term : </strong>${
                              !isEstimated && diffObj
                                  ? `<div>${diffObj.years} Years, ${diffObj.months} Months, ${diffObj.days} Days</div>`
                                  : ""
                          }
                          ${
                              isEstimated && get(data, "lease_length", "")
                                  ? `<div>${get(data, "lease_length", "")} Months</div>`
                                  : ""
                          }
                          <div>
                            ${termLogic.renderHTML.renderLeaseTerm(calcs)}     
                          </div>
                      </td>
                      <td class="col-50">
                          <strong>Abated Rent</strong>
                          <div>
                            ${termLogic.renderHTML.renderAbatedTerms(calcs)}   
                          </div>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>

      <div class="the-table">
          <table>
              <tbody>
                  <tr>
                      <td class="col-100 no-bt">
                          <div>
                              ${termLogic.renderHTML.renderRentTotals(calcs)}    
                          </div>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
          `
                  : renderCommissionAdjustmentsHTML(data)
          }
          

          <br />
          <br />
          <div class="the-table">
              <table>
                  <tbody>
                      <tr>
                          <td class="col-45 no-bt no-br no-bl no-bb">
                              <h4 class="underlined">LESSOR</h4>
                          </td>
                          <td class="col-5 no-bt no-bb no-br"></td>
                          <td class="col-45 no-bt no-br no-bl no-bb">
                              <h4 class="underlined">LESSEE</h4>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>

          <div class="the-table">
          <table>
              <tbody>
                  <tr>
                      <td class="col-45">
                          ${renderLessors()}
                      </td>
                      <td class="col-5 no-bt no-bb"></td>
                      <td class="col-45">
                          ${renderLessees()}
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>

      <br />
      <br />
      <div class="the-table">
          <table>
              <tbody>
                  <tr>
                      <td class="col-45 no-bt no-br no-bl no-bb">
                          <h4 class="underlined">LESSOR CONTACT(S)</h4>
                      </td>
                      <td class="col-5 no-bt no-bb no-br"></td>
                      <td class="col-45 no-bt no-br no-bl no-bb">
                          <h4 class="underlined">LESSEE CONTACT(S)</h4>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
      <div class="the-table">
          <table>
              <tbody>
                  <tr>
                      ${
                          data.lessor_contacts
                              ? `<td class="col-45"> 
                          ${makeArray("lessor_contacts", "multi-contact")}
                          ${data.lessor_contacts.length === 0 ? `<div class="text-center"><em>N/A</em></div>` : ""}
                      </td>`
                              : ""
                      }
                      <td class="col-5 no-bt no-bb"></td>
                      ${
                          data.lessee_contacts
                              ? `<td class="col-45">
                          ${makeArray("lessee_contacts", "multi-contact")}
                          ${data.lessee_contacts.length === 0 ? `<div class="text-center"><em>N/A</em></div>` : ""}
                      </td>`
                              : ""
                      }
                  </tr>
              </tbody>
          </table>
      </div>

          <br />
          <h4 style="text-decoration:underline">COMMISSION DISTRIBUTION LESSOR</h4>
          ${
              hasLessorAssociate
                  ? `<div class="the-table small-table-text" style="width:100%">
                <table>
                    ${renderCommissionsLessor()}
                </table>
            </div>`
                  : ""
          }
          <div class="the-table small-table-text mt-10" style="width:100%">
              <table>
                  ${renderCommissionsOtherLessor()}
              </table>
          </div>
          <br />

          <br />
          <h4 style="text-decoration:underline"> COMMISSION DISTRIBUTION LESSEE</h4>
          ${
              hasLesseeAssociate
                  ? `<div class="the-table small-table-text" style="width:100%">
                <table>
                    ${renderCommissionsLessee()}
                </table>
            </div>`
                  : ""
          }
          <div class="the-table small-table-text mt-10" style="width:100%">
              <table>
                  ${renderCommissionsOtherLessee()}
              </table>
          </div>
          <br />
          <br />
            <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td class="col-45 no-bt no-br no-bl no-bb">
                                ${lessorOutsideBrokersHTML}
                            </td>
                            <td class="col-5 no-bt no-bb no-br"></td>
                            <td class="col-45 no-bt no-br no-bl no-bb">
                                ${lesseeOutsideBrokersHTML}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
          <br />
          <div class="the-table">
              <table>
                  <tbody>
                      <tr>
                          <td class="col-45 no-bt no-br no-bl no-bb">
                              <h4 style="text-decoration:underline">LESSOR REFERRAL</h4>
                          </td>
                          <td class="col-5 no-bt no-bb no-br"></td>
                          <td class="col-45 no-bt no-br no-bl no-bb">
                              <h4 style="text-decoration:underline">LESSEE REFERRAL</h4>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          ${[
              "name",
              "lastname",
              "contact_dba_name",
              "address1",
              "city",
              "state",
              "zip",
              "contact",
              "main_phone",
              "fax",
              "email",
              "taxid",
              "w9",
          ]
              .map((ff, i) => {
                  let r = `referral_firm_${ff}`;
                  let o = `lessee_referral_firm_${ff}`;
                  const add = i === 0 ? `` : `no-bt`;
                  const r_phone_type = data.referral_firm_phone_type;
                    const o_phone_type = data.lessee_referral_firm_phone_type;
                    if (ff === 'main_phone') {
                        if (r_phone_type === 'Work') {
                            r = `referral_firm_work_phone`;
                        } else if (r_phone_type === 'Mobile') {
                            r = `referral_firm_mobile_phone`;
                        }
                        if (o_phone_type === 'Work') {
                            o = `lessee_referral_firm_work_phone`;
                        } else if (o_phone_type === 'Mobile') {
                            o = `lessee_referral_firm_mobile_phone`;
                        }
                        return `<div class="the-table">
                            <table>
                                <tbody>
                                    <tr>
                                        <td class="col-45 ${add}">
                                            ${make(r)}
                                        </td>
                                        <td class="col-5 no-bt no-bb"></td>
                                        <td class="col-45 ${add}">
                                            ${make(o)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>`
                    }
                  if (ff === "w9") {
                      return `<div class="the-table">
                      <table>
                          <tbody>
                              <tr>
                                  <td class="col-45 no-bt no-bb no-br no-bl">
                                      <strong>W9 : </strong>
                                      ${makeCheckboxes(r)}
                                  </td>
                                  <td class="col-5 no-bt no-bb"></td>
                                  <td class="col-45 ${add}">
                                      <strong>W9 : </strong>
                                      ${makeCheckboxes(o)}
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </div>`;
                  }
                  return `<div class="the-table">
                  <table>
                      <tbody>
                          <tr>
                              <td class="col-45 ${add}">
                                  ${make(r)}
                              </td>
                              <td class="col-5 no-bt no-bb"></td>
                              <td class="col-45 ${add}">
                                  ${make(o)}
                              </td>
                          </tr>
                      </tbody>
                  </table>
              </div>`;
              })
              .join("")}

            <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td class="col-45 no-bt no-br no-bl no-bb">
                                <h4 style="text-decoration:underline">OTHER LESSOR REFERRAL(S)</h4>
                            </td>
                            <td class="col-5 no-bt no-bb no-br"></td>
                            <td class="col-45 no-bt no-br no-bl no-bb">
                                <h4 style="text-decoration:underline">OTHER LESSEE REFERRAL(S)</h4>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td class="col-45"> 
                                ${renderReferrals('lessor')}
                                ${(!data?.lessor_referrals || data?.lessor_referrals.length === 0) ? `<div class="text-center"><em>N/A</em></div>` : ""}
                            </td>
                            <td class="col-5 no-bt no-bb"></td>
                            <td class="col-45">
                                ${renderReferrals('lessee')}
                                ${(!data?.lessee_referrals || data?.lessee_referrals.length === 0) ? `<div class="text-center"><em>N/A</em></div>` : ""}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

          <div>
              <h4 style="text-decoration:underline">BILLING INFORMATION</h4>
          </div>
          <div class="the-table">
              <table>
                  <tbody>
                      <tr>
                          <td class="col-30">
                              ${make("billing_attention")}
                          </td>
                          <td class="col-30">
                              ${make("billing_attention_lastname")}
                          </td>
                          <td class="col-40">
                              ${make("billing_attention_dba_name")}
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <div class="the-table">
              <table>
                  <tbody>
                        <tr>
                            <td class="col-50 no-bt">
                                ${make("billing_company")}
                            </td>
                            <td class="col-50 no-bt">
                                ${make("billing_dba_name")}
                            </td>
                        </tr>
                  </tbody>
              </table>
          </div>
          <div class="the-table">
              <table>
                  <tbody>
                      <tr>
                          <td class="col-50 no-bt">
                              ${make("billing_address1")}
                          </td>
                          <td class="col-50 no-bt">
                              ${make("billing_address2")}
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <div class="the-table">
              <table>
                  <tbody>
                      <tr>
                          <td class="col-40 no-bt">
                              ${make("billing_city")}
                          </td>
                          <td class="col-40 no-bt">
                              ${make("billing_state")}
                          </td>
                          <td class="col-20 no-bt">
                              ${make("billing_zip")}
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <div class="the-table">
              <table>
                  <tbody>
                      <tr>
                          <td class="col-20 no-bt">
                              ${make("billing_phone")}
                          </td>
                          <td class="col-20 no-bt">
                              ${make("billing_fax")}
                          </td>
                          <td class="col-60 no-bt">
                              ${make("billing_email")}
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <br />
          <br />
          <div>
              <h4 style="text-decoration:underline">COPY OF INVOICE</h4>
          </div>
          <div class="the-table">
              <table>
                  <tbody>
                      <tr>
                          <td class="col-100">
                              <strong>Copy of Invoice : </strong>
                              ${makeCheckboxes("copy_of_invoice")}
                          </td>
                      </tr>
                      ${
                          data.copy_of_invoice.indexOf("Other") !== -1 &&
                          `<tr>
                          <td className="col-100" >
                             <strong>Other : </strong>
                              ${makeCheckboxes("copy_of_invoice")}
                          </td>
                        </tr>`
                      }
                      </tr>
                  </tbody>
              </table>
          </div>
          <br />
          <br />
          <div>
              <h4 style="text-decoration:underline">PAYMENT SCHEDULE</h4>
          </div>
          ${
            renderPaymentScheduleFullFormPDF({
                data,
                sheetType: typeDescriptionsDict['Residential']
            })
          }

          <br />
          <br />
          <div>
              <h4 style="text-decoration:underline">COMMENTS AND SPECIAL INSTRUCTIONS</h4>
          </div>
          <div class="the-table">
              <table>
                  <tbody>
                      ${
                          data.comments_and_special_instructions
                              ? `<tr>
                              <td class="col-100">
                                  ${data.comments_and_special_instructions}
                              </td>
                          </tr>`
                              : ""
                      }
                  </tbody>
              </table>
          </div>


      </body>
  </html>
  `;
};

export const renderSignRequestForm = (data) => {
    if (data.sign_removal_requested !== "Yes") return null;

    const makeCheckboxes = (field) => {
        const htmlSnippet = `<span>${residentialFields[field].options
            .map((o) => {
                if (!o) return "";
                if (data[field]) {
                    if (data[field].indexOf(o) !== -1) {
                        return `<span class="ml-10 grayed"><input type="checkbox" checked />${o}</span>`;
                    }
                }
                return `<span class="ml-10 grayed"><input type="checkbox" />${o}</span>`;
            })
            .join("")}</span>`;
        return htmlSnippet;
    };

    const renderField = (field) => {
        if (data[field]) return data[field];
        return "";
    };

    return `
        <html>
            ${renderHTMLHead()}
        <body>
        
        <div>
        <table class="the-table">
            <tbody>
                <tr>
                    <td class="col-10">
                        <img
                            src="https://illicre.com/wp-content/themes/zoacres/assets/illi-logo-simple.png"
                            style="width: 60px"
                        />
                    </td>
                    <td class="col-90">
                        <div class="text-center">
                            <h3>SIGN REQUEST</h3>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <br />

    <div style="display: flex; justify-content: center">
        <div style="flex-basis: 30%">
            <span>
                <strong>illi#:</strong>
            </span>
            <span
                class="col-20"
            >
                ${renderField("illi_number")}
            </span>
        </div>
        <div style="flex-basis: 30%">
            <span>
                <strong>Date:</strong>
            </span>
            <span
            >
                ${renderField("date")}
            </span>
        </div>
        <div style="flex-basis: 30%">
            <span>
                <strong>Submitted By:</strong>
            </span>
        </div>
    </div>

    <br />

    <div class="the-table">
        <table>
            <tr>
                <th class="col-20">Street Address:</th>
                <td
                    colspan="5"
                >
                    ${renderField("project_address")}
                </td>
            </tr>
            <tr>
                <th class="col-20">City:</th>
                <td
                >
                    ${renderField("city")}
                </td>
                <th class="col-20">State:</th>
                <td
                >
                    ${renderField("state")}
                </td>
                <th class="col-20">Zip:</th>
                <td
                >
                    ${renderField("zip")}
                </td>
            </tr>
            <tr>
                <th class="col-20">Cross Street:</th>
                <td colspan="5"></td>
            </tr>
        </table>
    </div>

    <br />

    <div>
        <div class="col-50 inline-block">
            <span>Access Necessary?</span>
            <span
                class="col-80"
            >
                ${makeCheckboxes("access_necessary")}
            </span>
        </div>
        <div class="col-50 inline-block">
            <span>Contact:</span>
            <span
                class="col-80"
            >
                ${renderField("contact")}
            </span>
        </div>
    </div>

    <br />

    <div class="the-table">
        <table>
            <tr>
                <th class="col-20">Order Type:</th>
                <td
                    class="col-80"
                >
                    ${makeCheckboxes("order_type")}
                </td>
            </tr>
        </table>
    </div>

    <br />

    <div>
        <h4>MAINTENANCE DESCRIPTION / SPECIAL INSTRUCTIONS</h4>
        <div class="the-table">
            <span
            >
                ${renderField("maintenance_description")}
            </span>
        </div>
    </div>

    <br />

    <div class="the-table">
        <table>
            <tr>
                <th class="col-20">Sign Size:</th>
                <td
                >
                    ${renderField("sign_size")}
                </td>
                <th class="col-20">Location:</th>
                <td
                >
                    ${renderField("location")}
                </td>
            </tr>
        </table>
    </div>

    <br />

    <div class="the-table">
        <table>
            <tr>
                <th class="col-20">
                    Reason for sign request or change:
                </th>
                <td
                    class="col-80"
                >
                    ${renderField("sign_request_reason")}
                </td>
            </tr>
        </table>
    </div>

    <br />

    <div style="display: flex; justify-content: center">
        <div style="flex-basis: 30%">
            <span>Date: </span>
            <span
                class="col-20"
            >
                ${renderField("date")}
            </span>
        </div>
        <div style="flex-basis: 30%">
            <span>Todd Nathanson Signature:</span>
        </div>
    </div>

        </body>
    </html>
    `;
};
