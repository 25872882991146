import React, { useEffect } from 'react';

import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Button from '../../../components/CustomButtons/Button';

const renderSignRequestExtraFields = (renderElement) => {
	const extra_fields = [
		'order_type',
		'maintenance_description',
		'sign_size',
		'location',
		'sign_request_reason',
	];

	return extra_fields.map((field) => {
		return (
			<GridItem xs={12} sm={3} md={3} key={field}>
				{renderElement(field)}
			</GridItem>
		);
	});
};

const Step2 = ({ data, renderElement, setOpenedChooseSpacesModal }) => {

	return (
		/* Premises Information */
		<GridContainer>
			{
				<GridItem xs={12} sm={12} md={12}>
					{(data.spaces && data.lease_space_type !== 'Lease with Single Space') && (
						<div>
							<Button
								color='primary'
								className='mr-20'
								disabled={data.spaces.length > 0 ? false : true}
								onClick={() => {
									setOpenedChooseSpacesModal(true);
								}}
							>
								SELECT ADDRESS
							</Button>
							{data.spaces.length === 0 && (
								<span style={{ color: 'red' }}>
									Select a Property with Spaces
								</span>
							)}
						</div>
					)}
				</GridItem>
			}

			<GridItem xs={12} sm={6} md={6}>
				{renderElement('premises_address')}
			</GridItem>

			<GridItem xs={12} sm={6} md={6}>
				{renderElement('unit_number')}
			</GridItem>

			<GridItem xs={12} sm={3} md={3}>
				{renderElement('city')}
			</GridItem>

			<GridItem xs={12} sm={3} md={3}>
				{renderElement('state')}
			</GridItem>

			<GridItem xs={12} sm={3} md={3}>
				{renderElement('zip')}
			</GridItem>

			<GridItem xs={12} sm={3} md={3}>
				{renderElement('county')}
			</GridItem>

			<GridItem xs={12} sm={3} md={3}>
				{renderElement('window_signs_returned')}
			</GridItem>
			{data.window_signs_returned && data.window_signs_returned === 'Yes' && (
				<GridItem xs={12} sm={3} md={3}>
					{renderElement('window_sign_number')}
				</GridItem>
			)}

			<GridItem xs={12} sm={3} md={3}>
				{renderElement('sign_removal_requested')}
			</GridItem>
			{data.sign_removal_requested && data.sign_removal_requested === 'Yes' && renderSignRequestExtraFields(renderElement)}

			<GridItem xs={12} sm={6} md={6}>
				{renderElement('loopnet_marketing_updated')}
			</GridItem>

			<GridItem xs={12} sm={12} md={12}>
				{renderElement('property_type')}
			</GridItem>
		</GridContainer>
	);
};

export default Step2;
