import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Close from "@material-ui/icons/Close";
import Datetime from "react-datetime";

import { connect } from "react-redux";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInputValidate";
import { no_letters } from "shared/validation";
import * as actions from "store/actions";
import { clone } from "shared/utility";
import { isValidDate } from "../../shared/utility";
import CustomDateInput from "../../components/DateInput/CustomDateInput";
import moment from "moment";
import { get } from "lodash";
import { commissionCalc } from "../RSheets/CommonCustomElements/CommissionLogic";

const InvoiceApplyPaymentDialog = (props) => {
    console.log('InvoiceApplyPaymentDialog', props);
    const { open, close, createInvoicePayment, invoice } = props;

    const [payment, setPayment] = useState({
        date: "",
        amount: "",
        checkNumber: "",
        payee: "",
    });

    const formatDate = (date) => {
        const arr = date.split('/');
        return `${arr[2]}-${arr[0]}-${arr[1]}`;
    }

    const handleChange = (name, value) => {
        const newPayment = clone(payment);
        newPayment[name] = value;
        setPayment(newPayment);
    };

    const { date, amount, checkNumber, payee } = payment;

    const canSubmit = date && moment(date).isValid();

    const rsData = get(props, 'invoice.json_data', null);
    const rsType = get(props, 'invoice.data.type', null);

    console.log('InvoiceApplyPaymentDialog', props, rsData);

    return (
        <Dialog open={open} onClose={close} keepMounted maxWidth={false} fullWidth={false}>
            <div
                className="close-btn"
                onClick={() => {
                    close();
                }}
            >
                <Close />
            </div>
            <div style={{ margin: 20, padding: 50 }}>
                <h3>APPLY PAYMENT</h3>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <CustomDateInput
                            label="Payment Date"
                            onChange={(e) => {
                                let stringDate = e;
                                if (typeof e === "object") {
                                    stringDate = e.format("MM/DD/YYYY");
                                }
                                handleChange("date", stringDate);
                            }}
                            value={date}
                            isRequired
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                            label={"Payment Amount"}
                            value={amount}
                            onChange={(e) => {
                                handleChange("amount", e.target.value);
                            }}
                            isDollar
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                            label={"Check Number"}
                            value={checkNumber}
                            onChange={(e) => {
                                handleChange("checkNumber", e.target.value);
                            }}
                            validate={[no_letters]}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                            label={"Payee Name"}
                            value={payee}
                            onChange={(e) => {
                                handleChange("payee", e.target.value);
                            }}
                        />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                        <Button
                            color="danger"
                            disabled={!canSubmit}
                            style={{ marginTop: 30, marginRight: 20 }}
                            onClick={() => {
                                const all_commission_calculations = commissionCalc.calculate(rsData);
                                createInvoicePayment({
                                    payment_date: formatDate(payment.date),
                                    payment_amount: payment.amount,
                                    check_number: payment.checkNumber,
                                    payee_name: payment.payee,
                                    rs_id: invoice.rs_id,
                                    invoice_id: invoice.id,
                                }, {
                                    all_commission_calculations,
                                    rsData
                                });
                                close();
                            }}
                        >
                            SUBMIT
                        </Button>
                        <Button
                            style={{ marginTop: 30, marginRight: 20 }}
                            onClick={() => {
                                close();
                            }}
                        >
                            CLOSE
                        </Button>
                    </GridItem>
                </GridContainer>
            </div>
        </Dialog>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        createInvoicePayment: (data, otherData) => dispatch(actions.createInvoicePayment(data, otherData)),
    };
};

export default connect(null, mapDispatchToProps)(InvoiceApplyPaymentDialog);
