import React from "react";
import { useState } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import CustomInput2 from "components/CustomInput/CustomInputValidate2.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomRadio from "components/CustomRadio/CustomRadio";
import Button from "components/CustomButtons/Button.jsx";
import { clone, cloneJ, commatize, formatNumberWithCommas, parseFloatString, round2dec } from "../../../shared/utility";
import Table from "components/Table/Table";
import { Dialog } from "@material-ui/core";
import Notification from "../../../components/Notification/Notification";
import Datetime from "react-datetime";
import {
	min1,
	required,
	no_letters,
	max50,
} from "shared/validation";
import { useEffect } from "react";
import NotificationAsk from "../../../components/Notification/NotificationAsk";

const SingleSpaceForm = props => {
    const { updateDataObj, dataObj, setMadeChanges, propertyUseTypes, setDataObj } = props;

    const [validSqft, setValidSqft] = useState(true);
    const [useTypes, setUseTypes] = useState([]);
    const [notification, setNotification] = useState('');
    const [vacationHasChangedFromNoToYes, setVacationHasChangedFromNoToYes] = useState(false);
    const [startSendingMarketing, setStartSendingMarketing] = useState(false);

    const renderElement = (field, label, validations, uppercase, isCurrency) => {
        return (
            <CustomInput
                value={dataObj[field]}
                label={label}
                onChange={(e) => {
                    const newData = clone(dataObj);
                    newData[field] = e.target.value;
                    setDataObj(newData);
                }}
                validate={validations}
                uppercase={uppercase ? true : false}
                isCurrency={isCurrency ? true : false}
                startAdornment={isCurrency ? '$' : ''}
            />
        );
    };

    useEffect(() => {
        const puty = clone(propertyUseTypes);
        console.log('puty', puty);
        if (puty.unit_use_type) {
            let useTypes = puty.unit_use_type.concat([]).sort();
            useTypes.push('Other');
            setUseTypes(useTypes);
        }
    }, [propertyUseTypes]);

    const update = (field, value) => {
            const newData = clone(dataObj);
            newData[field] = value;
            setDataObj(newData);
        }
    
    const addressMessage = dataObj.address_is_range === 'Yes' ? 
        !dataObj.single_space_address && !dataObj.single_space_unit_number_address ? 
            <span style={{color:'orange'}}>Street Address is required if there is no Unit Number</span> 
        :
            null
    : 
        null;
    const unitNumberMessage = dataObj.address_is_range === 'Yes' ?
            !dataObj.single_space_address && !dataObj.single_space_unit_number_address ? 
                <span style={{color:'orange'}}>Unit is Required if there is no Street Address</span>
            : 
                null
        :  
            !dataObj.single_space_unit_number_address ?
                <span style={{color:'red'}}>Unit is Required</span>
            :
                null;

    const monthly_amount = round2dec(parseFloatString(dataObj.single_space_price_per_sqft) * parseFloatString(dataObj.single_space_total_sqft));

    return <>
    <div className="boxed">
        <h3>Single Space</h3>
        <GridContainer>
            <GridItem xs={12} sm={3} md={3}>
                {renderElement("single_space_address", "Street Address")}
                {addressMessage}
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
                {renderElement("single_space_unit_number_address", "Space Address / Unit Number")}
                {unitNumberMessage}
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
                <CustomInput2
                    value={dataObj.single_space_total_sqft}
                    label={"Total Square Feet"}
                    onChange={(e) => {
                        const value = e.target.value;
                        const spcs = dataObj.spaces ? dataObj.spaces : []
                        const otherSpacesSqft = dataObj.spaces.reduce((totalSpaceSqft, current) => parseFloat(current.total_sqft) + totalSpaceSqft, 0);
                        const totalSpaceSqft = otherSpacesSqft + parseFloat(value) ;
                        const totalBuildingSqft = dataObj.total_building_sqft ? parseFloat(dataObj.total_building_sqft) : 0;
                        if (totalSpaceSqft > totalBuildingSqft) {
                            setValidSqft(false);
                        } else {
                            setValidSqft(true);
                        }
                        updateDataObj('single_space_total_sqft', e.target.value);
                    }}
                    validate={[
                        min1,
                        max50,
                        required
                    ]}
                />
                {!validSqft ? <div className="red-text">Sq. Ft must not be more than total sq.ft. of building</div> : null}
            </GridItem>
            <GridItem xs={12} sm={3} md={3}></GridItem>
            <GridItem xs={12} sm={3} md={3}>
                <CustomInput2
                    value={dataObj.single_space_min_divisible}
                    label="Min Divisible"
                    onChange={(e) => {
                        updateDataObj('single_space_min_divisible', e.target.value);
                    }}
                    validate={[
                        min1,
                        max50
                    ]}
                />
                <small>* Must be less or equal of total square feet of this space</small>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
                <CustomInput2
                    value={dataObj.single_space_max_contigous}
                    label="Max Contigous"
                    onChange={(e) => {
                        updateDataObj('single_space_max_contigous', e.target.value);
                    }}
                />
                <small>* Must be less or equal of property's total square feet</small>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
                <CustomRadio
                    label="Build Out,Yes,No"
                    options={["Yes", "No"]}
                    onChange={(e) => {
                        updateDataObj('single_space_build_out', e.target.value);
                    }}
                    value={dataObj.single_space_build_out}
                />
            </GridItem>

            <GridItem xs={12} sm={3} md={3}></GridItem>
            <GridItem xs={12} sm={3} md={3}>
                <CustomRadio
                    label="Price Type,Flat Price,Price Per Square Feet"
                    options={["Yes", "No", ""]}
                    onChange={(e) => {
                        const newData = clone(dataObj);
                        newData["single_space_is_flat_price"] = e.target.value;
                        if (newData.single_space_is_flat_price) {
                            // render Price per Sq Ft. blank
                            newData.single_space_price_per_sqft = '';
                        } else {
                            // render Flat price blank
                            newData.single_space_flat_price = '';
                        }
                        setDataObj(newData);
                    }}
                    value={dataObj.single_space_is_flat_price}
                />
                {!dataObj.single_space_is_flat_price && <div className="red-text">
                        <small>Required</small>
                    </div>}
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
                {dataObj['single_space_is_flat_price'] === 'No' && <div>
                    {renderElement("single_space_price_per_sqft", "Price Per Square Feet", [
                        min1,
                        max50
                    ], false, 'is_currency')}
                    {!dataObj.single_space_price_per_sqft && <div className="red-text">
                        <small>Required</small>
                    </div>}
                </div>}
                {dataObj['single_space_is_flat_price'] === "Yes" && <div>
                    {renderElement("single_space_flat_price", "Flat Price", [
                        min1,
                        max50
                    ], false, 'is_currency')}
                    {!dataObj.single_space_flat_price && <div className="red-text">
                        <small>Required</small>
                    </div>}
                </div>}
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
                <div style={{opacity: dataObj.single_space_use_type === 'Residential' ? 0 : 1}}><CustomSelect
                    label="Lease Type"
                    choose={(e, n) => {
                        const newData = clone(dataObj);
                        newData["single_space_lease_type"] = e;
                        if (e !== 'MG' && e !== 'NNN') {
                            newData.single_space_monthly_amount = '';
                        }
                        setDataObj(newData);
                    }}
                    options={props.propertyUseTypes.lease_type}
                    default={dataObj['single_space_lease_type']}
                />
                    <CustomRadio
                        label="Sub Lease,Yes,No"
                        options={["Yes", "No"]}
                        onChange={(e) => {
                            const newData = clone(dataObj);
                            newData.single_space_sub_lease = e.target.value;
                            setDataObj(newData);
                        }}
                        value={dataObj.single_space_sub_lease}
                    />
                </div>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
                {(dataObj.single_space_lease_type === 'MG' || dataObj.single_space_lease_type === 'NNN') && <CustomInput
                    value={formatNumberWithCommas(monthly_amount)}
                    label={`${dataObj.single_space_lease_type} Monthly Amount`}
                    onChange={(e) => {
                        return;
                    }}
                    isCurrency={true}
                    startAdornment='$'
                />}
                <CustomSelect
                    label="Use Type"
                    choose={(e, n) => {
                        const newData = clone(dataObj);
                        newData["single_space_use_type"] = e;
                        if (e === 'Residential') {
                            newData["single_space_lease_type"] = '';
                        }
                        setDataObj(newData);
                    }}
                    options={useTypes}
                    default={dataObj['single_space_use_type']}
                />
            </GridItem>
            {dataObj["single_space_use_type"] === "Other" && (
                <GridItem xs={12} sm={3} md={3}>
                    {renderElement("single_space_use_type_other", "Use Type - Other", [required])}
                </GridItem>
            )}
            <GridItem xs={12} sm={3} md={3}>
                <CustomRadio
                    label="Vacant,Yes,No"
                    options={["Yes", "No"]}
                    onChange={(e) => {
                        const newData = clone(dataObj);
                        newData["single_space_vacant"] = e.target.value;
                        if (e.target.value === 'No') {
                            newData.single_space_date_available = '';
                        }
                        setDataObj(newData);
                    }}
                    value={dataObj.single_space_vacant}
                />
                {dataObj.single_space_vacant === "No" && <div>
                <div className="custom relative">
                    {dataObj.single_space_date_available && <div className="tiny-label">Date Available</div>}
                    <Datetime
                        inputProps={{
                            placeholder: "Date Available",
                        }}
                        closeOnSelect={true}
                        onChange={(e) => {
                            if (typeof e === 'object') {
                                const stringDate = e.format('MM/DD/YYYY');
                                return update('single_space_date_available', stringDate);;
                            }
                            updateDataObj('single_space_date_available', e)
                        }}
                        timeFormat={false}
                        value={dataObj.single_space_date_available}
                    />
                </div>
                </div>}
            </GridItem>
        </GridContainer>
        {/* <p>Total building square feet: {commatize(parseFloatString(dataObj.total_building_sqft))}</p> */}
        <hr />
        {/* <p className={`${!validSqft ? 'error-message' : ''}`}>* Total spaces' square feet cannot exceed total building square feet.</p> */}
        
        
    </div>
    </>
}

export default SingleSpaceForm;