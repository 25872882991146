import React from 'react';
import { useEffect } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { FormControlLabel, Switch } from '@material-ui/core';
import Datetime from 'react-datetime';

import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInputValidate';
import CustomInput2 from 'components/CustomInput/CustomInputValidate2';
import CustomSelect from 'components/CustomSelect/CustomSelect.jsx';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import {
	getDateDiffs2,
	commatize,
	generateMonthArray,
	generateDayArray,
} from 'shared/utility';
import { required } from 'shared/validation';

import {
	createMonthArray,
	getCurrentPositionFromTheFirstFullMonth,
	getLastFullMonthRange,
} from '../../RSheetsLogic';

const TermForm = (props) => {
	console.log('TermForm', props)
	const {
		data,
		value,
		month1,
		month2,
		date1,
		date2,
		day1,
		day2,
		usePercentage,
		percent,
		update,
		updateMultiFields,
		isRequired,
		allTotal,
		commissionRate,
		flat_fee,
		field,
		action,
		isEstimated,
		currentYear,
		isAbated,
		isCPI,
		isNextRange,
		currentIndex,
	} = props;

	const lease_length = parseInt(get(data, 'lease_length', 0));
	const monthOptions = createMonthArray(lease_length, currentYear);
	console.log('monthOptions', monthOptions);
	const dayOptions = generateDayArray();
	console.log('last full month range', data,
		currentYear - 1,
		currentIndex,
		isAbated);
	const firstFullMonth = getLastFullMonthRange(
		data,
		currentYear - 1,
		currentIndex,
		isAbated
	);

	const setMonthlyValue = () => {
		const firstFullMonthRange = parseFloat(
			get(firstFullMonth, 'range.value', 0)
		);
		const cpiPercentage = parseFloat(data.rate_type_cpi_percentage);

		const monthlyValue = firstFullMonthRange * (1 + cpiPercentage * 0.01);
		updateMultiFields({
			value: monthlyValue.toFixed(2),
		});
	};

	const showCPIButton = isCPI && Boolean(firstFullMonth.range);

	const TermFormDelete = (handleDelete) => {
		console.log('handleDelete', handleDelete)
		if (handleDelete) {
			if (handleDelete.hasOwnProperty('handleDelete')) {
				if (!handleDelete.handleDelete) {
					return <GridItem xs={12} sm={4} md={3}></GridItem>;
				}
			}
		}
		return (
			<>
				{!handleDelete && <GridItem xs={12} sm={4} md={3}></GridItem>}
				{handleDelete && (
					<GridItem xs={12} sm={4} md={3}>
						<div className='mt-20'>
							<i
								className='fas fa-trash red-text hoverable'
								onClick={handleDelete}
							></i>
						</div>
					</GridItem>
				)}
			</>
		);
	};

	return (
		<>
			{showCPIButton && (
				<GridContainer>
					<GridItem xs={12} sm={2} md={2}>
						<Button onClick={() => setMonthlyValue()} color='primary'>
							Add Fixed Percent
						</Button>
					</GridItem>
				</GridContainer>
			)}
			<GridContainer>
				{isAbated && (
					<GridItem xs={12} sm={2} md={3}>
						<FormControlLabel
							control={
								<Switch
									checked={usePercentage}
									onChange={(e) => {
										update('usePercentage', e.target.checked);
									}}
								/>
							}
							label='Use Percentage'
						/>
					</GridItem>
				)}
				<GridItem xs={12} sm={2} md={3}>
					<div
						className={`${props.inputOnClick ? 'hoverable' : ''}`}
						onClick={() => {
							if (props.inputOnClick) {
								props.inputOnClick();
							}
						}}
					>
						<div className={`${props.inputOnClick ? 'avoid-clicks' : ''}`}>
							{usePercentage && isAbated ? (
								<CustomInput
									label='Monthly Percentage'
									value={percent}
									onChange={(e) => {
										update('percent', e.target.value);
									}}
									validate={[required]}
									type='number'
									onClick={props.inputOnClick ? props.inputOnClick : () => {}}
									endAdornment='%'
								/>
							) : (
								<CustomInput2
									label='Monthly $ Value'
									value={value}
									onChange={(e) => {
										const obj = {
											value: e.target.value,
											flat_fee: e.target.value,
										};
										updateMultiFields(obj);
									}}
									validate={[required]}
									// type='number'
									isCurrency={true}
									onClick={props.inputOnClick ? props.inputOnClick : () => {}}
								/>
							)}
						</div>
					</div>
				</GridItem>
				{field === 'lease_term' && (
					<GridItem xs={12} sm={2} md={3}>
						<div
							className={`${props.inputOnClick ? 'hoverable' : ''}`}
							onClick={() => {
								if (props.inputOnClick) {
									props.inputOnClick();
								}
							}}
						>
							<div className={`${props.inputOnClick ? 'avoid-clicks' : ''}`}>
								{props.data.flat_fee_commission === 'Yes' ? (
									<CustomInput2
										label='Flat Fee'
										value={flat_fee}
										onChange={(e) => {
											update('flat_fee', e.target.value);
										}}
										startAdornment="$"
										isCurrency={true}
									/>
								) : (
									<CustomInput
										label='Commission Rate'
										value={commissionRate}
										onChange={(e) => {
											update('commissionRate', e.target.value);
										}}
										validate={[required]}
										type='number'
										endAdornment={commissionRate ? '%' : ''}
										onClick={props.inputOnClick ? props.inputOnClick : () => {}}
									/>
								)}
							</div>
						</div>
					</GridItem>
				)}
				{!isEstimated && (
					<GridItem xs={12} sm={2} md={3}>
						<div
							className={`custom relative ${
								isRequired && !date1 ? 'required-date' : ''
							}`}
						>
							<div className='hoverable gray-text date-clear'>
								{date1 && (
									<small
										onClick={() => {
											update('date1', '');
										}}
									>
										clear
									</small>
								)}
							</div>
							{date1 && <div className='tiny-label'>Date Start</div>}
							<div
								className={`${props.inputOnClick ? 'hoverable' : ''}`}
								onClick={() => {
									if (props.inputOnClick) {
										props.inputOnClick();
									}
								}}
							>
								<div className={`${props.inputOnClick ? 'avoid-clicks' : ''}`}>
									<Datetime
										inputProps={{
											placeholder: 'Date Start',
											onClick: props.inputOnClick
												? props.inputOnClick
												: () => {},
										}}
										closeOnSelect={true}
										onChange={(e) => {
											if (typeof e === 'object') {
												const stringDate = e.format('MM/DD/YYYY');
												update('date1', stringDate);
												return;
											}
											update('date1', e);
										}}
										timeFormat={false}
										value={date1}
										onClick={props.inputOnClick ? props.inputOnClick : () => {}}
									/>
								</div>
							</div>
							{isRequired && !date1 && (
								<div className='red-text'>
									<small>Required</small>
								</div>
							)}
						</div>
					</GridItem>
				)}
				{!isEstimated && (
					<GridItem xs={12} sm={2} md={3}>
						<div
							className={`custom relative ${
								isRequired && !date2 ? 'required-date' : ''
							}`}
						>
							<div className='hoverable gray-text date-clear'>
								{date2 && (
									<small
										onClick={() => {
											update('date2', '');
										}}
									>
										clear
									</small>
								)}
							</div>
							{date2 && <div className='tiny-label'>Date End</div>}
							<div
								className={`${props.inputOnClick ? 'hoverable' : ''}`}
								onClick={() => {
									if (props.inputOnClick) {
										props.inputOnClick();
									}
								}}
							>
								<div className={`${props.inputOnClick ? 'avoid-clicks' : ''}`}>
									<Datetime
										inputProps={{
											placeholder: 'Date End',
											onClick: props.inputOnClick
												? props.inputOnClick
												: () => {},
										}}
										closeOnSelect={true}
										onChange={(e) => {
											if (typeof e === 'object') {
												const stringDate = e.format('MM/DD/YYYY');
												update('date2', stringDate);
												return;
											}
											update('date2', e);
										}}
										timeFormat={false}
										value={date2}
										onClick={props.inputOnClick ? props.inputOnClick : () => {}}
									/>
								</div>
							</div>
							{isRequired && !date2 && (
								<div className='red-text'>
									<small>Required</small>
								</div>
							)}
						</div>
					</GridItem>
				)}
				{isEstimated && (
					<>
						<GridItem xs={12} sm={2} md={3}>
							{month1 && <div className='tiny-label'>Month Start</div>}
							<div>
								<CustomSelect
									label={'Month Start'}
									options={monthOptions ? monthOptions : []}
									choose={(e, n) => {
										update('month1', e);
									}}
									default={month1}
								/>
							</div>
							{isRequired && !month1 && (
								<div className='red-text'>
									<small>Required</small>
								</div>
							)}
						</GridItem>
						<GridItem xs={12} sm={2} md={3}>
							{day1 && <div className='tiny-label'>Day Start</div>}
							<div>
								<CustomSelect
									label={'Date Start'}
									options={dayOptions}
									choose={(e, n) => {
										update('day1', e);
									}}
									default={day1}
								/>
							</div>
							{isRequired && !day1 && (
								<div className='red-text'>
									<small>Required</small>
								</div>
							)}
						</GridItem>
						<GridItem xs={12} sm={2} md={3}></GridItem>
						<GridItem xs={12} sm={2} md={3}></GridItem>
						<GridItem xs={12} sm={2} md={3}>
							{month2 && <div className='tiny-label'>Month End</div>}
							<div>
								<CustomSelect
									label={'Month End'}
									options={monthOptions}
									choose={(e, n) => {
										update('month2', e);
									}}
									default={month2}
								/>
							</div>
							{isRequired && !month2 && (
								<div className='red-text'>
									<small>Required</small>
								</div>
							)}
						</GridItem>
						<GridItem xs={12} sm={2} md={3}>
							{day2 && <div className='tiny-label'>Day End</div>}
							<div>
								<CustomSelect
									label={'Date End'}
									options={dayOptions}
									choose={(e, n) => {
										update('day2', e);
									}}
									default={day2}
								/>
							</div>
							{isRequired && !day2 && (
								<div className='red-text'>
									<small>Required</small>
								</div>
							)}
						</GridItem>
					</>
				)}
				<TermFormDelete handleDelete={props.delete} />
			</GridContainer>
		</>
	);
};

export default TermForm;
