import React, { useState, useEffect, useRef } from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Table from "components/Table/Table.jsx";
import CustomInput from "components/CustomInput/CustomInputValidate";
import Button from "../../components/CustomButtons/Button";
import axios from "store/axios/axios-commission-splits";
import { getUser } from "../../shared/authValidation";
import { 
    statusDescriptionsDict, statusDescriptions,
    getHistory
} from "./CommissionSplitsLogic";
import moment from "moment";
import Notification from "../../components/Notification/Notification";
import FileUploader from "../../components/FileUploader/FileUploader";
import { clone, handleError } from "../../shared/utility";

const Step4 = (props) => {
    const [notification, setNotification] = useState('');
    const [currentUser, setCurrentUser] = useState(null);
    const [shouldCloseForm, setShouldCloseForm] = useState(false);
    const [notes, setNotes] = useState('');
    const [reviewers, setReviewers] = useState(null);
    const [hasSent, setHasSent] = useState(false);
    const [csHistory, setCsHistory] = useState([]);
    const { currentUserIsSubmitter, setDataObj } = props;

    const getCommissionSplitsHistory = async (cs_id) => {
        // get commission splits history
        try {
            const response = await axios.get('/history?cs_id='+cs_id);
            setCsHistory(response.data);
        } catch(e) {
            handleError(e);
        }
        try {
            const reviewers_response = await axios.get(`/pending_reviewers?cs_id=${cs_id}`);
            setReviewers(reviewers_response.data);
        } catch(e) {
            handleError(e);
            console.log('Error retrieving reviewers');
        }

    }

    useEffect(() => {
        if (props.entryData) getCommissionSplitsHistory(props.entryData.id)
        setCurrentUser(getUser());
    }, []);
    // Load all Agents from Step2 
    // Compare with all current users, 
    const { dataObj, all_potential_reviewers, history } = props;

    const createHistory = async (histories) => {
        try {
            for (let i = 0; i < histories.length; i++) {
                const ad = histories[i];
                await axios.post('/history', ad);
            }
        } catch(e) {
            console.log('errir', e);
            throw e;
        }
    }

    const createNotifications = async (an_arr, histories) => {
        try {
            console.log('creating notification 1');
            for (let i = 0; i < an_arr.length; i++) {
                const an = an_arr[i];
                await axios.post('/send_notification', an);
            }
            console.log('creating notification 2');
            await createHistory(histories);
            console.log('creating notification 3');
            getCommissionSplitsHistory(props.entryData.id)
            console.log('creating notification 4');
            setHasSent(true);
            setNotification('Sent notifications to associates')
        } catch(e) {
            console.log('err',e);
            setNotification('There was a problem');
        }
    }

    console.log('Step4', { 
        csHistory,
        reviewers,
        currentUserIsSubmitter,
        currentUser, 
        props, 
        all_potential_reviewers, 
        contacts: dataObj.contacts
        }
    );

    const user_id = getUser().id;
    const userIsAReviewer = reviewers ? reviewers.some(s => s.pending_user_id === user_id) : false;
    console.log('Step4 - reviewers', reviewers, dataObj.contacts, user_id);
    let userHasReviewedAlready = reviewers ? reviewers.some(s => {
        if (s.pending_user_id === user_id) {
            if (s.status === 2 || s.status === 3) {
                return true;
            }
        }
    }) : false;

    console.log('Step4-a', userHasReviewedAlready);

    let soloReviewer = false;

    if (reviewers) {
        if (reviewers.length === 0 && user_id) {
            if (dataObj.contacts) {
                if (dataObj.contacts.length === 1) {
                    const dc = dataObj.contacts[0];
                    console.log('got here');
                    if (dc.connect_user_id === user_id) {
                        console.log('got here 2');
                        soloReviewer = true;
                        userHasReviewedAlready = false;
                    }
                }
            }
        }
    }

    const overrideAssociateApproval = getUser('CommissionSplits override_associates');
    const completelyApproved = dataObj.contacts ? dataObj.contacts.every(dc => dc.approved === true) : false;

    console.log('Step4-b', props, userHasReviewedAlready, soloReviewer);

    return <div className={props.action === 'view' ? 'gray-bg no-click' : ''}>
        {(overrideAssociateApproval || dataObj.pdf_attachment) && <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <h3>Upload PDF Attachment</h3>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
                <FileUploader 
                    title=""
                    update={(val) => {
                        props.updateDataObj('pdf_attachment', val, true)
                    }}
                    remove={() => {
                        props.updateDataObj('pdf_attachment', '', true)
                    }}
                    the_filename={dataObj.pdf_attachment}
                    type="crm"
                    fileType="application/pdf"
                    disabled={props.parentTab === 'my_completed' || props.parentTab === 'all_completed' || props.action === 'view' ? true : false}
                />
            </GridItem>
        </GridContainer>}
        {<div className={
            (props.parentTab === 'my_completed' 
                || props.parentTab === 'all_completed' 
                || props.parentTab === '' 
                || props.action === 'view') ? 'gray-bg no-click' : ''
        }><GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Table 
                    tableHeaderColor="primary"
                    tableHead={['Name','Email','Status']}
                    tableData={dataObj.contacts.map((a,i) => {
                        let isCurrentUser = false;
                        let currentUser = null;
                        if (all_potential_reviewers) {
                            for (let j = 0; j < all_potential_reviewers.length; j++) {
                                const apr = all_potential_reviewers[j];
                                if (apr.mail === a.email) {
                                    isCurrentUser = true;
                                    currentUser = apr;
                                }
                            }
                        }
                        const uid = currentUser ? currentUser.id : null;
                        const hs = getHistory(uid, history);
                        let status = 'Not received yet';
                        if (reviewers) {
                            reviewers.forEach(r => {
                                if (r.pending_user_id === a.connect_user_id) {
                                    if (r.status === 1) status = 'Pending review';
                                    if (r.status === 2) status = 'Approved';
                                    if (r.status === 3) status = 'Denied';
                                } 
                            })
                        }
                        return [
                            `${a.first_name} ${a.last_name} ${(a.dba_name && a.dba_active) ? `DBA ${a.dba_name}` : ``}`,
                            a.email,
                            status,
                        ]
                    })}
                />
                <hr />
            </GridItem>
            {props.history && <GridItem xs={12} sm={12} md={12}>
                {props.history.length > 0 && <h4>History</h4>}
                {props.history.length > 0 && <Table 
                    tableHeaderColor="primary"
                    tableHead={['Date','Name','Action','Notes']}
                    tableData={
                        props.history ? props.history.map(h => {
                            return [
                                moment(h.timestamp.replace("T", " ")).format("MM/DD/YYYY h:mm a"),
                                h.name,
                                statusDescriptions[h.action],
                                h.message
                            ]
                        }) : []
                    }
                />}
                {props.history.length > 0 && <hr />}
            </GridItem>}


            {!hasSent && <GridItem xs={4} sm={4} md={4}>

                {/* HIDE REVIEW ACTIONS IF IN COMPLETED TAB or IS A SOLO REVIEWER  */}
                {(props.parentTab !== 'my_completed' 
                    && props.parentTab !== 'all_completed') 
                    && !soloReviewer && <div>
                    {(
                        props.entryData?.id 
                        && props.parentTab !== 'pending_associate_review' 
                        && currentUserIsSubmitter
                        && !completelyApproved
                    ) 
                        && <div className="mt-20">
                        <CustomInput 
                            label="Send for Review Notes"
                            value={notes}
                            onChange={(e) => {
                                setNotes(e.target.value);
                            }}
                        />
                        <Button color="primary" size="sm"
                            disabled={false}
                            onClick={async () => {
                                let newHistories = [];
                                let notifications = [];
                                // check for duplicate emails
                                const hasDuplicates = dataObj.contacts.some((obj, index, arr) => 
                                    arr.findIndex(user => user.email === obj.email) !== index
                                );
                                if (hasDuplicates) return setNotification('Duplicate emails are not allowed');
                                dataObj.contacts.forEach(c => {
                                    let isCurrentUser = false;
                                    let currentUser = null;
                                    if (all_potential_reviewers) {
                                        for (let j = 0; j < all_potential_reviewers.length; j++) {
                                            const apr = all_potential_reviewers[j];
                                            if (apr.mail === c.email) {
                                                isCurrentUser = true;
                                                currentUser = apr;
                                            }
                                        }
                                    }
                                    const uid = currentUser ? currentUser.id : null;
                                    newHistories.push({
                                        cs_id: props.entryData?.id,
                                        action: statusDescriptionsDict['Sent to Pending Approval by Associate'], 
                                        json_data: JSON.stringify(dataObj),
                                        message: notes,
                                        user_id: uid
                                    })
                                    notifications.push({
                                        name: `${c.first_name} ${c.last_name}`,
                                        connect_user_id: c.connect_user_id,
                                        email: c.email,
                                        id: props.entryData?.id,
                                        type: 'pending approval'
                                    })
                                })
                                await createNotifications(notifications, newHistories);
                                props.save(null, null, true, 'Sent for associate approval', {
                                    status: 8
                                });
                                setNotes('')
                                props.close();
                            }}
                        >SEND FOR REVIEW</Button>
                    </div>}
                </div>}

                {soloReviewer && <div className="mt-20">
                    <CustomInput 
                        label="Review Notes"
                        value={notes}
                        onChange={(e) => {
                            setNotes(e.target.value);
                        }}
                    />
                    {<Button color="primary" size="sm"
                        className="mr-20"
                        onClick={async () => {
                            let newHistories = [{
                                cs_id: props.entryData?.id,
                                action: statusDescriptionsDict['Approved by Associate'],
                                json_data: JSON.stringify(dataObj),
                                message: notes
                            }];
                            try {
                                // See if everyone has approved already
                                await createHistory(newHistories);
                                const newContacts = dataObj.contacts.map(c => {
                                    if (c.connect_user_id === user_id) {
                                        c.approved = true;
                                    }
                                    return c;
                                })
                                props.updateDataObj('contacts', newContacts, true);
                                getCommissionSplitsHistory(props.entryData?.id);
                                setNotes('');
                                setHasSent(true);
                                setNotification('Commission Split Approved');
                            } catch(err) {
                                setNotification('Error approving. please try again');
                                console.log('asdf',err);
                            }
                        }}
                    >APPROVE</Button>}
                </div>}

                {(userIsAReviewer && !soloReviewer) && <div className="mt-20">
                    {!userHasReviewedAlready && <CustomInput 
                        label="Reviewer Notes *"
                        value={notes}
                        onChange={(e) => {
                            setNotes(e.target.value);
                        }}
                    />}
                    {!userHasReviewedAlready && <Button color="primary" size="sm"
                        className="mr-20"
                        onClick={async () => {
                            let newHistories = [{
                                cs_id: props.entryData?.id,
                                action: statusDescriptionsDict['Approved by Associate'],
                                json_data: JSON.stringify(dataObj),
                                message: notes
                            }];
                            try {
                                await createHistory(newHistories);
                                const newContacts = dataObj.contacts.map(c => {
                                    if (c.connect_user_id === user_id) {
                                        c.approved = true;
                                    }
                                    return c;
                                })
                                const newData = clone(dataObj);
                                newData.contacts = newContacts;
                                setDataObj(newData);
                                // check if all have approved 
                                const notYetApproved = newData.contacts.filter(c => {
                                    if (!c.approved) return true;
                                })
                                if (notYetApproved.length === 0) {
                                    props.save(newData, null, true, 'Sent back to drafts for completion', {
                                        status: 1
                                    });
                                    setTimeout(() => {
                                        props.close();
                                    }, 1000);
                                    return;
                                }
                                getCommissionSplitsHistory(props.entryData?.id);
                                setNotes('');
                                setHasSent(true);
                                setNotification('Commission Split Approved');
                            } catch(err) {
                                setNotification('Error approving. please try again');
                                console.log('asdf',err);
                            }
                        }}
                    >APPROVE</Button>}

                    {!userHasReviewedAlready && <Button color="white" size="sm"
                        onClick={async () => {
                            setShouldCloseForm(true);
                            let newHistories = [{
                                cs_id: props.entryData?.id,
                                action: statusDescriptionsDict['Rejected by Associate'],
                                json_data: JSON.stringify(dataObj),
                                message: notes
                            }];
                            try {
                                await createHistory(newHistories);
                                const newContacts = dataObj.contacts.map(c => {
                                    if (c.connect_user_id === user_id) {
                                        c.approved = false;
                                    }
                                    return c;
                                })
                                props.updateDataObj('contacts', newContacts, true);
                                getCommissionSplitsHistory(props.entryData?.id);
                                setNotes('');
                                setHasSent(true);
                                setNotification('Commission Split Denied');
                            } catch(e) {
                                setNotification('Error denying, please try again');
                            }
                        }}
                    >DENY</Button>}
                </div>}
            </GridItem>}
        </GridContainer></div>}

        {notification && <Notification
            open={notification ? true : false}
            close={() => {
                setNotification('');
            }}
            message={notification}
        />}
    </div>
}

export default Step4;