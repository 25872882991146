import { put, call } from "redux-saga/effects";
import { get, isArray, isEmpty, uniq, compact } from "lodash";

import axios from "../axios/axios-rs";
import axiosCRM from "../axios/axios-crm";
import axiosInvoice from "../axios/axios-invoice";
import * as actions from "../actions/rs";
import * as invoiceActions from "../actions/invoice";
import { clone } from "shared/utility";
import { generateInvoiceHTML } from "../../containers/Invoices/InvoiceObj";
import { handleError } from "../../shared/utility";

// function* pushToUrlSaga(action) {
//     const { url } = action.payload;
//     const history = useHistory();
//     history.push(url); // Use the useHistory hook to push to a different URL
// }

// function* watchPushToUrl() {
//     yield takeEvery("PUSH_TO_URL", pushToUrlSaga);
// }

export function* getRsheetsSaga(action) {
    yield put(actions.getRsheetsStart());
    try {
        const data = action.data;
        if (data.type && !data.status) {
            let query = "/rsheets?type=" + data.type;
            Object.keys(data).forEach((param) => {
                if (param !== "type") {
                    if (Array.isArray(data[param])) {
                        const joined = data[param].join(",");
                        query += `&${param}=${joined}`;
                    } else {
                        query += `&${param}=${data[param]}`;
                    }
                }
            });
            const response = yield axios.get(query);
            yield put(actions.getRsheetsSuccess(response.data));
        } else if ((data.status || data.status === 0) && !data.type) {
            // by status
            let query = `/rsheets?q=1`;
            Object.keys(data).forEach((param) => {
                if (param !== "type") {
                    if (Array.isArray(data[param])) {
                        const joined = data[param].join(",");
                        query += `&${param}=${joined}`;
                    } else {
                        query += `&${param}=${data[param]}`;
                    }
                }
            });
            const response = yield axios.get(query);
            yield put(actions.getRsheetsSuccess(response.data));
        }
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
                return yield put(actions.getRsheetsFail(e.response.data));
            }
        }
        yield put(actions.getRsheetsFail(e));
    }
}

export function* getRsheetSaga(action) {
    yield put(actions.getRsheetStart());
    try {
        const query = `/rsheet?id=` + action.data;
        const response = yield axios.get(query);

        const {data} = response;

        if (data) {
            // storage for fetched dbas
            const dbas = {
                contact: {
                    data: [],
                },
                company: {
                    data: [],
                },
            }


            const getDba = function* (dba_id, dba_type) {
                if (dba_id && dba_type) {
                    let matchedDba = dbas[dba_type].data.find(x => x.id === dba_id);
                    console.log('dbas', dbas);
                    if (!matchedDba) {
                        const dba = yield axiosCRM.get(`/${dba_type}_dbas?dba_id=${dba_id}`);
                        if (dba?.data) {
                            dbas[dba_type].data.push(dba.data);
                            matchedDba = dba.data;
                        }
                    }
                    return matchedDba?.active;
                }
            }

            const getDbas = function* (array, dbaId = 'dba_id', dbaType = 'dba_type', dbaActive = 'dba_active') {
                if (!array?.length) return;
                try {
                    for (let i = 0; array.length; i++) {
                        const id = array[i][dbaId];
                        const type = array[i][dbaType];
                        array[i][dbaActive] = yield getDba(id, type);
                    }
                } catch(e) {
                }
            }

            // properties that need to check for dbas
            const {
                listings_procured_by,
                illi_represented_lessor_agents,
                illi_represented_lessee_agents,
                commission_splits_agent,
                commission_splits_agent2,
                lessees,
                lessors,
                lessor_contacts,
                lessee_contacts,
                lessor_referrals,
                lessee_referrals,
            } = data.json_data;

            yield getDbas(listings_procured_by);
            yield getDbas(illi_represented_lessor_agents);
            yield getDbas(illi_represented_lessee_agents);
            yield getDbas(commission_splits_agent);
            yield getDbas(commission_splits_agent2);
            yield getDbas(lessees);
            yield getDbas(lessors);
            yield getDbas(lessor_contacts);
            yield getDbas(lessee_contacts);
            yield getDbas(lessor_referrals, 'contact_dba_id', 'contact_dba_type', 'contact_dba_active');
            yield getDbas(lessee_referrals, 'contact_dba_id', 'contact_dba_type', 'contact_dba_active');

            data.json_data.billing_dba_active = yield getDba(data.json_data?.billing_dba_id, data.json_data?.billing_dba_type);
            data.json_data.billing_attention_dba_active = yield getDba(data.json_data?.billing_attention_dba_id, data.json_data?.billing_attention_dba_type);

            // data.json_data.referral_firm_company_dba_active = yield getDba(data.json_data?.referral_firm_company_dba_id, data.json_data?.referral_firm_company_dba_type);
            data.json_data.referral_firm_contact_dba_active = yield getDba(data.json_data?.referral_firm_contact_dba_id, data.json_data?.referral_firm_contact_dba_type);
            
            // data.json_data.lessee_referral_firm_company_dba_active = yield getDba(data.json_data?.lessee_referral_firm_company_dba_id, data.json_data?.lessee_referral_firm_company_dba_type);
            data.json_data.lessee_referral_firm_contact_dba_active = yield getDba(data.json_data?.lessee_referral_firm_contact_dba_id, data.json_data?.lessee_referral_firm_contact_dba_type);


        }
        
        yield put(actions.getRsheetSuccess(data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
                return yield put(actions.getRsheetFail(e.response.data));
            }
        }
        yield put(actions.getRsheetFail(e));
    }
}

export function* createRsheetSaga(action) {
    yield put(actions.createRsheetStart());
    try {
        const data = clone(action.data);
        if (data.formDataObj) delete data.formDataObj;
        let query = `/rsheet`;
        const response = yield axios.post(query, data);
        const id = response.data.insertId;
        // record history of creation 
        console.log('record history?', action.recordHistory);
        if (action.recordHistory) {
            const recordHistory = clone(action.recordHistory);
            recordHistory.rs_id = id;
            const response_create_history = yield axios.post('/rs_history', recordHistory);
            console.log('response on creation of history', response_create_history);
        }
        yield put(actions.createRsheetSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
                return yield put(actions.createRsheetFail(e.response.data));
            }
        }
        yield put(actions.createRsheetFail(e));
    }
}

export function* updateRsheetSaga(action) {
    yield put(actions.updateRsheetStart());
    try {
        const data = clone(action.data);
        const json_data = JSON.parse(data.json_data);
        const invoiceDatas = action.invoiceData;
        let invoicesProcessed = [];
        let openInvoiceLink = '';

        if (json_data) {
            data.json_data = JSON.stringify(json_data);
        }
        console.log('sending RS data', data, invoiceDatas);
        if (data.formDataObj) delete data.formDataObj;
        const query = "/rsheet";
        const response = yield axios.put(query, data);

        if (invoiceDatas) {
            // Check if there are already existing invoices
            const existingInvoices = yield axiosInvoice.get('/entries?rs_id='+data.id);
            let existsAlready = false;
            if (existingInvoices.data) {
                if (existingInvoices.data.length > 0) {
                    existsAlready = true;
                }
            }
            console.log('existing Invoices', existingInvoices.data);
            if (invoiceDatas.length > 0 && !existsAlready) {
                // CREATE NEW INVOICES
                for (let j = 0; j < invoiceDatas.length; j++) {
                    const invoiceData = invoiceDatas[j].invoiceData;
                    const response = yield axiosInvoice.post("/entry", invoiceData);
                    const invoiceID = response.data.insertId;
                    invoicesProcessed.push(invoiceID);
                    if (j === 0) {
                        // open first invoice automatically
                        openInvoiceLink = '/invoices?tab=pending&invoice_id='+invoiceID;
                    }
                }
            }
            // FINAL APPROVAL
            console.log('got here 1');
            if (data.status === 9) {
                // SEND INVOICES TO BILLING
                const theInvoices = yield axiosInvoice.get('/entries?rs_id='+data.id);
                if (theInvoices.data) {
                    console.log('got here 2', theInvoices.data);
                    if (theInvoices.data.length > 0) {
                        for (let i = 0; i < theInvoices.data.length; i++) {
                            console.log('notification response 1', json_data);
                            const theInvoice = theInvoices.data[i];
                            const formData = theInvoice.data;
                            formData.rsData = json_data;
                            console.log('generating invoice', formData);
                            const body = {
                                type: 'approved',
                                id: theInvoice.id,
                                html: generateInvoiceHTML(formData, null, i)
                            };
                            console.log('notification response 1a', body);
                            const notification_response = yield axiosInvoice.post('/notify', body);
                            console.log('notification response 2', notification_response);
                        }
                    }
                }
            }
        }

        // Clone User Properties, Contacts and Companies if RS is approved
        if (data?.status === 9) {
            const jsonData = JSON.parse(data.json_data);
            const property_id = get(jsonData, "chosen.property", null) || jsonData.property_id;

            const chosen = get(jsonData, "chosen", {});
            let contactIdsToClone = [];
            let companyIdsToClone = [];
            if (jsonData.billing_company_id) {
                if (companyIdsToClone.indexOf(jsonData.billing_company_id) === -1) {
                    companyIdsToClone.push(jsonData.billing_company_id);
                }
            }
            if (jsonData.billing_contact_id) {
                if (contactIdsToClone.indexOf(jsonData.billing_contact_id) === -1) {
                    contactIdsToClone.push(jsonData.billing_contact_id);
                }
            }
            if (jsonData.business_contact_id) {
                if (contactIdsToClone.indexOf(jsonData.business_contact_id) === -1) {
                    contactIdsToClone.push(jsonData.business_contact_id);
                }
            }
            if (jsonData.business_company_id) {
                if (companyIdsToClone.indexOf(jsonData.business_company_id) === -1) {
                    companyIdsToClone.push(jsonData.business_company_id);
                }
            }
            console.log('clonesy', companyIdsToClone, contactIdsToClone);
            Object.keys(chosen).forEach((key) => {
                if (key !== "property") {
                    const contact = get(chosen, `${key}.contact`, null);

                    if (contact) {
                        if (isArray(contact)) {
                            contactIdsToClone.push(...contact);
                        } else {
                            contactIdsToClone.push(contact);
                        }
                    }

                    const company = get(chosen, `${key}.company`, null);

                    if (company) {
                        if (isArray(company)) {
                            companyIdsToClone.push(...company);
                        } else {
                            companyIdsToClone.push(company);
                        }
                    }

                    const contact_ids = get(chosen, `${key}.contact_ids`, null);

                    if (contact_ids) {
                        if (isArray(contact_ids)) {
                            contactIdsToClone.push(...contact_ids);
                        } else {
                            contactIdsToClone.push(contact_ids);
                        }
                    }

                    console.log('cloning', contact_ids);

                    const company_ids = get(chosen, `${key}.company_ids`, null);

                    if (company_ids) {
                        if (isArray(company_ids)) {
                            companyIdsToClone.push(...company_ids);
                        } else {
                            companyIdsToClone.push(company_ids);
                        }
                    }

                    console.log('cloning', company_ids);
                }
            });

            if (property_id) {
                const res_property_copy = yield axiosCRM.post("/property_copy", {
                    id: property_id,
                    spaces_indexes_to_copy: get(jsonData, "spaces", []).map((_val, index) => index),
                });
            }

            if (!isEmpty(contactIdsToClone)) {
                const res_contact_copy = yield axiosCRM.post("/bulk_contact_copy", {
                    ids: compact(uniq(contactIdsToClone)),
                });
            }

            if (!isEmpty(companyIdsToClone)) {
                const res_company_copy = yield axiosCRM.post("/bulk_company_copy", {
                    ids: compact(uniq(companyIdsToClone)),
                });
            }
        }

        // record history of creation
        if (action.recordHistory) {
            const recordHistory = clone(action.recordHistory);
            const response_create_history = yield axios.post('/rs_history', recordHistory);
            console.log('response on creation of history', response_create_history);
            if (action.pdfObj) {
                try {
                    // creates PDF
                    console.log('response on creation of history 1', action.pdfObj);
                    const pdfObj = clone(action.pdfObj);
                    const rsheet_history_id = response_create_history.data.id;
                    pdfObj.filename = `rs-approved-${rsheet_history_id}`;
                    yield axios.post("/pdf", pdfObj);
                } catch(e) {
                    handleError(e);
                }
                
            }
        }

        //if (openInvoiceLink) window.location.href = openInvoiceLink;

        yield put(actions.updateRsheetSuccess(response.data));
    } catch (e) {
        console.log('e', e);
        if (e.response) {
            console.log('ee', e.response);
            if (e.response.data) {
                return yield put(actions.updateRsheetFail(e.response.data));
            }
        }
        yield put(actions.updateRsheetFail(e));
    }
}

export function* deleteRsheetSaga(action) {
    yield put(actions.deleteRsheetStart());
    try {
        const response = yield axios.delete("/rsheet?id=" + action.data.id);
        yield put(actions.deleteRsheetSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
                return yield put(actions.deleteRsheetFail(e.response.data));
            }
        }
        yield put(actions.deleteRsheetFail(e));
    }
}

export function* createRsheetPdfSaga(action) {
    yield put(actions.createRsheetPdfStart());
    try {
        const response = yield axios.post("/pdf", action.data);
        yield put(actions.createRsheetPdfSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
                return yield put(actions.createRsheetPdfFail(e.response.data));
            }
        }
        yield put(actions.createRsheetPdfFail(e));
    }
}

export function* createRsheetFilesSaga(action) {
    yield put(actions.createRsheetFilesStart());
    try {
        const response = yield axios.post("/upload", action.data);
        yield put(actions.createRsheetFilesSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
                return yield put(actions.createRsheetFilesFail(e.response.data));
            }
        }
        yield put(actions.createRsheetFilesFail(e));
    }
}

export function* getRsheetFilesSaga(action) {
    yield put(actions.getRsheetFilesStart());
    try {
        const { rsId, lesseeId, fileList, folderPaths } = action.data;
        const response = yield axios.get("/download", {
            params: {
                rsId: rsId,
                lesseeId: lesseeId,
                fileList: fileList,
                folderPaths: folderPaths,
            },
        });
        yield put(actions.getRsheetFilesSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
                return yield put(actions.getRsheetFilesFail(e.response.data));
            }
        }
        yield put(actions.getRsheetFilesFail(e));
    }
}

export function* getRsTypesSaga(action) {
    yield put(actions.getRsTypesStart());
    try {
        const response = yield axios.get("/types");
        yield put(actions.getRsTypesSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
                return yield put(actions.getRsTypesFail(e.response.data));
            }
        }
        yield put(actions.getRsTypesFail(e));
    }
}

export function* getNextRsIlliNumberSaga(action) {
    yield put(actions.getNextRsIlliNumberStart());
    try {
        const response = yield axios.get("/illi_number/next?type=" + action.data);
        yield put(actions.getNextRsIlliNumberSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
                return yield put(actions.getNextRsIlliNumberFail(e.response.data));
            }
        }
        yield put(actions.getNextRsIlliNumberFail(e));
    }
}

export function* checkRsIlliNumberSaga(action) {
    yield put(actions.checkRsIlliNumberStart());
    try {
        const { type, illi_number } = action.data;
        const response = yield axios.get(`/illi_number/check?type=${type}&illi_number=${illi_number}`);
        yield put(actions.checkRsIlliNumberSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
                return yield put(actions.checkRsIlliNumberFail(e.response.data));
            }
        }
        yield put(actions.checkRsIlliNumberFail(e));
    }
}

export function* createRsheetRevisionSaga(action) {
    yield put(actions.createRsheetRevisionStart());
    try {
        const response = yield axios.post("/rsheet_revision", action.data);
        if (response.data) {
            if (response.data.rsheetRevision) {
                const revision = response.data.rsheetRevision;
                if (revision.id) {
                    // create copy of invoices 
                    const original_rs_id = action.data.id;
                    const original_invoices = yield axiosInvoice.get(`/entries?rs_id=${original_rs_id}`);
                    let newInvoices = [];
                    if (original_invoices.data) {
                        const oid = original_invoices.data;
                        for (let i = 0; i < oid.length; i++) {
                            const oi = oid[i];
                            if (oi.data) oi.data.rs_id = revision.id;
                            oi.rs_id = revision.id;
                            newInvoices.push(oi);
                        }
                    }
                    for (let i = 0; i < newInvoices.length; i++) {
                        const ni = newInvoices[i];
                        yield axiosInvoice.post(`/entry`, ni);
                    }
                    const response_new_invoices = yield axiosInvoice.get(`/entries?rs_id=${revision.id}`);
                    yield put(invoiceActions.getInvoiceEntriesSuccess(response_new_invoices.data));
                }
            }
        }
        yield put(actions.createRsheetRevisionSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
                return yield put(actions.createRsheetRevisionFail(e.response.data));
            }
        }
        yield put(actions.createRsheetRevisionFail(e));
    }
}

export function* getRsHistoriesSaga(action) {
    yield put(actions.getRsHistoriesStart());
    try {
        const response = yield axios.get('/rs_histories?rs_id='+action.data);
        yield put(actions.getRsHistoriesSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getRsHistoriesFail(e.response.data));
            }
          }
        yield put(actions.getRsHistoriesFail(e));
    }
}

export function* createRsHistorySaga(action) {
    yield put(actions.createRsHistoryStart());
    try {
        const response = yield axios.post('/rs_history', action.data);
        yield put(actions.createRsHistorySuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.createRsHistoryFail(e.response.data));
            }
          }
        yield put(actions.createRsHistoryFail(e));
    }
}

export function* updateRsHistorySaga(action) {
    yield put(actions.updateRsHistoryStart());
    try {
        const response = yield axios.put('/rs_history', action.data);
        yield put(actions.updateRsHistorySuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.updateRsHistoryFail(e.response.data));
            }
          }
        yield put(actions.updateRsHistoryFail(e));
    }
}

export function* deleteRsHistorySaga(action) {
    yield put(actions.deleteRsHistoryStart());
    try {
        const response = yield axios.delete('/rs_history?id='+action.data);
        yield put(actions.deleteRsHistorySuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.deleteRsHistoryFail(e.response.data));
            }
          }
        yield put(actions.deleteRsHistoryFail(e));
    }
}

export function* getRsInternalReviewersSaga(action) {
    yield put(actions.getRsInternalReviewersStart());
    try {
        let query = '/internal_reviewers';
        if (action.data) query += '?rs_id='+action.data;
        const response = yield axios.get(query);
        yield put(actions.getRsInternalReviewersSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getRsInternalReviewersFail(e.response.data));
            }
          }
        yield put(actions.getRsInternalReviewersFail(e));
    }
}


export function* createRsInternalReviewerSaga(action) {
    yield put(actions.createRsInternalReviewerStart());
    try {
        yield axios.post('/internal_reviewer', action.data);
        const response_get = yield axios.get('/internal_reviewers?rs_id='+action.data.rs_id);
        yield put(actions.createRsInternalReviewerSuccess(response_get.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.createRsInternalReviewerFail(e.response.data));
            }
          }
        yield put(actions.createRsInternalReviewerFail(e));
    }
}

export function* updateRsInternalReviewerSaga(action) {
    yield put(actions.updateRsInternalReviewerStart());
    try {
        yield axios.put('/internal_reviewer', action.data);
        const response_get = yield axios.get('/internal_reviewers?rs_id='+action.data.rs_id);
        yield put(actions.updateRsInternalReviewerSuccess(response_get.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.updateRsInternalReviewerFail(e.response.data));
            }
          }
        yield put(actions.updateRsInternalReviewerFail(e));
    }
}

export function* deleteRsInternalReviewerSaga(action) {
    yield put(actions.deleteRsInternalReviewerStart());
    try {
        const query = '/internal_reviewer?id='+action.data.id;
        console.log('query', query);
        yield axios.delete(query);
        const response_get = yield axios.get('/internal_reviewers?rs_id='+action.data.rs_id);
        yield put(actions.deleteRsInternalReviewerSuccess(response_get.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.deleteRsInternalReviewerFail(e.response.data));
            }
          }
        yield put(actions.deleteRsInternalReviewerFail(e));
    }
}