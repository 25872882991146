import React from "react";

import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";
import CommissionDistribution from "../CommonCustomElements/CommissionDistribution";
import { getUser } from "../../../shared/authValidation";

const Step1 = (props) => {
    const { renderElement, data, userPerms } = props;
    return (
		/* Property Information */
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                {props.canEditInvoiceRelatedData && <Button
                    color="primary"
                    onClick={() => {
                        const user = getUser();
                        if (user.type === 'E') {
                            props.openIlli();
                        } else {
                            props.openAgent();
                        }
                    }}
                >
                    SELECT PROPERTY
                </Button>}

                {data.property_id && <Button
                    className="ml-20"
                    color="white"
                    onClick={() => {
                        props.updateProperty(data.property_id); 
                    }}
                >
                    UPDATE CURRENT PROPERTY DATA
                </Button>}
            </GridItem>

            <GridItem xs={12} sm={3} md={3}>
                {renderElement("date")}
            </GridItem>

            <GridItem xs={12} sm={3} md={3}>
                {renderElement("project_name")}
            </GridItem>

            <GridItem xs={12} sm={3} md={3}>
                {renderElement("la_city_verification")}
                <div className="group-wrap">
                    Check if in City of L.A -
                    <a href="http://finance.lacity.org" target="_blank" style={{ textDecoration: "underline" }}>
                        <strong>http://finance.lacity.org</strong>
                    </a>
                </div>
            </GridItem>

            <GridItem xs={12} sm={3} md={3}></GridItem>

            <GridItem xs={12} sm={3} md={3}>
                {renderElement("illi_number", userPerms.hasOwnProperty("Routing number edit") ? "yes" : "no")}
            </GridItem>

            {data.pm_number && (
                <GridItem xs={12} sm={6} md={6}>
                    {renderElement("pm_number")}
                </GridItem>
            )}

            <GridItem xs={12} sm={6} md={6}>
                {renderElement("project_address")}
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
                <div className="group-wrap">
                    <GridContainer>
                        <GridItem xs={12} sm={3} md={3}>
                            {renderElement("illi_represented_lessor")}
                        </GridItem>
                        <GridItem xs={12} sm={9} md={9}>
                            {data.illi_represented_lessor === "Yes" && (
                                <div>{renderElement("illi_represented_lessor_agents")}</div>
                            )}
                        </GridItem>
                    </GridContainer>
                </div>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
                <div className="group-wrap">
                    <GridContainer>
                        <GridItem xs={12} sm={3} md={3}>
                            {renderElement("illi_represented_lessee")}
                        </GridItem>
                        <GridItem xs={12} sm={9} md={9}>
                            {data.illi_represented_lessee === "Yes" && (
                                <div>{renderElement("illi_represented_lessee_agents")}</div>
                            )}
                        </GridItem>
                    </GridContainer>
                </div>
            </GridItem>

            <CommissionDistribution 
                data={data}
                setData={props.setData}
                field_one="commission_distribution_lessee"
                field_two="commission_distribution_lessor"
                label_one="Lessee"
                label_two="Lessor"
            />
        </GridContainer>
    );
};

export default Step1;
