import React, { useEffect, useState } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import Button from "components/CustomButtons/Button.jsx";
import { IconButton, Tooltip } from '@material-ui/core';
import CustomDialogWithCompany from 'components/CustomDialog/CustomDialogWithCompany';
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import Notification from 'components/Notification/NotificationFixedTop';
import NotificationAsk from 'components/Notification/NotificationAsk';
import { clone } from 'shared/utility';
import { TextField } from '@material-ui/core';
import { FadeLoader } from 'react-spinners';
import { getUser } from '../../../shared/authValidation';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Edit, Delete, Save, Close } from "@material-ui/icons";
import Loader from 'components/Loader/Loader';
import { get } from 'lodash';
import axios from 'store/axios/axios-crm';
import { handleError } from '../../../shared/utility';

const ContactsFormCompanies = (props) => {
    console.log('ContactsFormCompanies', props)
    const loggedInUser = getUser();
    const [deleteCompany, setDeleteCompany] = useState(false);
    const [openAddCompanyForm, setOpenAddCompanyForm] = useState(false);
    const [contactCompanies, setContactCompanies] = useState([]);
    const [updateContact, setUpdateContact] = useState(false);
    const [dataToDelete, setDataToDelete] = useState(null);
    const [message, setMessage] = useState('');
    const [notificationAsk, setNotificationAsk] = useState(false);
    const { canEdit, canDelete, contactData, companiesForAdd, setCompaniesForAdd, dbasForAdd, setDbasForAdd } = props;
    const [contactDbas, setContactDbas] = useState([]);
    const [addDba, setAddDba] = useState(false);
    const [editDba, setEditDba] = useState(null);
    const [dbaName, setDbaName] = useState(null);
    const [editCompany, setEditCompany] = useState(null);

    // ****************
    // COMPANY CONTACTS 
    // ****************
    const getCompanyContacts = async (contact_id) => {
        try {
            const data = { contact_id }
            let params = '';
            if (data.company_id) {
                params = `?id=${data.company_id}`;
            } else if (data.contact_id) {
                params = `?contact_id=${data.contact_id}`;
            }
            const response = await axios.get('/company_contacts' + params);
            const company_contacts = response.data;
            let cc = [];
            for (let i = 0; i < company_contacts.length; i++) {
                const c = company_contacts[i];

                // get contact info
                try {
                    const company_response = await axios.get('/company?id=' + c.company_id);
                    c.company_data = company_response.data;
                } catch(e) {
                    handleError(e);
                }
                
                // get company info
                try {
                    const contact_response = await axios.get('/contact?id=' + c.contact_id);
                    c.contact_data = contact_response.data;
                } catch(e) {
                    handleError(e);
                }
                
                // get dba info 
                if (c.dba_id) {
                    try {
                        const dbas_response = await axios.get('/company_dbas?dba_id=' + c.dba_id);
                        c.dba_data = dbas_response.data;
                    } catch(e) {
                        handleError(e);
                    }
                }
                if (c.company_data && c.contact_data) {
                    cc.push(c);
                }
            }
            setContactCompanies(cc);
        } catch (e) {
            handleError(e);
            alert('Could not get company contacts');
        }
    }

    const createCompanyContacts = async (data) => {
        try {
            await axios.post('/company_contacts', data);
            getCompanyContacts(data.contact_id);
        } catch (e) {
            handleError(e);
            alert('Could not create company contact');
        }
    }

    const updateCompanyContacts = async (data) => {
        try {
            await axios.put('/company_contacts', data);
            getCompanyContacts(data.contact_id);
        } catch (e) {
            handleError(e);
            alert('Could not update company contacts');
        }
    }

    const deleteCompanyContacts = async (data) => {
        console.log('company contacts to be deleted', data);
        if (typeof data !== 'number') {
            try {
                await axios.delete('/company_contacts?id=' + data.id);
                getCompanyContacts(props.contactData.id);
            } catch (e) {
                handleError(e);
                alert('Could not delete company contacts');
            }
        } else {
            try {
                await axios.delete('/company_contacts?id=' + data);
                getCompanyContacts(props.contactData.id);
            } catch (e) {
                handleError(e);
                alert('Could not delete company contacts');
            }
        }
    }

    // ****************
    // CONTACT DBAs 
    // ****************

    const getContactDbas = async (contact_id) => {
        try {
			const response = await axios.get('/contact_dbas?id='+contact_id);
            console.log('contact dbas', response.data);
			setContactDbas(response.data.filter(a => a.active === 1));
        } catch (e) {
            handleError(e);
            alert('Could not retrieve contact DBAs');
        }
    }

    const createContactDba = async (data) => {
        try {
			await axios.post('/contact_dba', data);
			getContactDbas(props.contactData.id);
        } catch (e) {
            handleError(e);
            alert('Could not create contact DBA');
        }
    }

    const updateContactDba = async (data) => {
        try {
			await axios.put('/contact_dba', data);
			getContactDbas(props.contactData.id);
        } catch (e) {
            handleError(e);
            alert('Could not update contact DBA');
        }
    }

    const deleteContactDba = async (id) => {
        console.log('delete contact dba', id);
        if (typeof id === 'number') {
            try {
                await axios.delete('/contact_dba?id='+id);
                getContactDbas(props.contactData.id);
            } catch (e) {
                handleError(e);
                alert('Could not delete contact DBA');
            }
        }
    }
    
    const choose = (chosen, chosenType, dbaChosen) => {
        console.log('choosing', chosen, chosenType, dbaChosen);
        if (props.action === 'Edit') {
            createCompanyContacts({
                company_id: chosen.id ? chosen.id : chosen.insertId,
                contact_id: contactData ? contactData.id : null,
                dba_id: dbaChosen ? dbaChosen.id : null,
                is_company: false
            });
        } else if (props.action === 'Add') {
            if (props.companiesForAdd && props.setCompaniesForAdd) {
                const newObj = {
                    contact_id: null,
                    company_id: chosen.id,
                    dba_id: dbaChosen ? dbaChosen.id : null,
                    is_company: false,
                    department: chosen.department ? chosen.department : "",
                    title: chosen.title ? chosen.title : "",
                    email: chosen.email ? chosen.email : "",
                    phone: chosen.main_phone ? chosen.main_phone : "",
                    company_data: chosen,
                    dba_data: dbaChosen
                };
                const newData = props.companiesForAdd.concat([
                    newObj
                ]);
                props.setCompaniesForAdd(newData)
            }
        }
        setOpenAddCompanyForm(false);
    };

    useEffect(() => {
        if ((props.action === 'Edit' || props.action === 'View') && props.contactData) {
            if (props.contactData.id) {
                getCompanyContacts(props.contactData.id);
                getContactDbas(props.contactData.id);
            }
        }
        // automatically opens Companies tab if prefill has company_name - used for Google/Dialpad Importer
        if (props.showCompanyAssigned && props.prefill && props.action === 'Add') {
            if (props.prefill.company_name) {
                if (props.companiesForAdd) {
                    let companyAlreadyExists = false;
                    props.companiesForAdd.forEach(cc => {
                        const cm = cc.company_data;
                        if (cm) {
                            if (cm.name) {
                                if (cm.name === props.prefill.company_name.trim()) {
                                    companyAlreadyExists = true;
                                }
                            }
                        }
                    });
                    if (!companyAlreadyExists) {
                        setOpenAddCompanyForm(true);
                    }
                }
            }
        }
        let companyAlreadyExists = false;
        if (props.prefill && props.action !== "Add") {
            if (props.prefill.company_name) {
                if (props.companyContactsData) {
                    props.companyContactsData.forEach(cd => {
                        const cm = cd.company_data;
                        if (cm.name === props.prefill.company_name) {
                            companyAlreadyExists = true;
                        }	
                    });
                }
            }
        }
        if (props.showCompanyAssigned && props.prefill && props.action !== 'Add') {
            if (props.prefill.company_name && props.canEdit && !companyAlreadyExists) {
                if (props.action === 'View') props.setAction('Edit');
                setOpenAddCompanyForm(true);
            }
        }
    }, [])

    useEffect(() => {
        if (props.contactDbas) {
            setContactDbas(props.contactDbas.filter(dba => dba.active !== 0));
        }
    }, [props.contactDbas]);

    useEffect(() => {
        if (updateContact && props.success === 'Successfully updated company contact') {
            setMessage(props.success);
            setUpdateContact(false);
        }
    }, [props.success]);

    useEffect(() => {
        if (props.contacts_success === 'Successfully updated DBA') {
            setEditDba(null);
            setDbaName(null);
        }
    }, [props.contacts_success]);

    const saveNewDba = () => {
        if (!dbaName) {
            setMessage('Please enter DBA name');
        } else {
            if (props.action === 'Edit') {
                createContactDba({
                    contact_id: props.contactData.id,
                    name: dbaName
                })
            } else if (props.action === 'Add') {
                console.log(dbasForAdd);
                setDbasForAdd([...dbasForAdd, {name: dbaName, id: Date.now() }]);
            }
            setAddDba(false);
            setDbaName(null);
        }
    };

    const updateDba = (dba) => {
        if (!dba.name) {
            setMessage('Please enter DBA name');
        } else {
            if (props.action === 'Edit') {
                updateContactDba({...dba, name: dbaName});
            } else if (props.action === 'Add') {
                setDbasForAdd(dbasForAdd.map(item => item.id === editDba ? {...item, name: dbaName} : item));
            }
        }
        setDbaName(null);
        setEditDba(null);
    }

    return (
        <>
        {props.loadingDBA && <Loader />}
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                {props.action !== 'Add' && <h3 className="red-text">{contactDbas.length} {contactDbas.length === 1 ? 'DBA' : 'DBAs'} :</h3>}
                {props.action === 'Add' && <h3 className="red-text">{dbasForAdd.length} {dbasForAdd.length === 1 ? 'DBA' : 'DBAs'} :</h3>}
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                {/* NEW DBA INPUT */}
                {addDba && <div onKeyPress={(e => e.key === 'Enter' && saveNewDba())}>
                    <CustomInput
                        label="New DBA"
                        value={dbaName}
                        onChange={e => setDbaName(e.target.value)}
                        autoFocus={true}
                        onSubmit={() => {saveNewDba()}}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={() => saveNewDba()}>
                                    <Tooltip id="tooltip-save-dba" title="Save DBA" placement="top">
                                        <Save/>
                                    </Tooltip>
                                </IconButton>
                                
                                <IconButton onClick={() => setAddDba(false)}>
                                    <Tooltip id="tooltip-cancel-add-dba" title="Cancel" placement="top">
                                        <Close/>
                                    </Tooltip>
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </div>
                }

                {/* ADD MODE */}
                {props.action == 'Add' && dbasForAdd.sort((a, b) => (a.id > b.id ? -1 : 0)).map((dba, i) => {
                    const disabled = editDba !== dba.id
                    return (
                        <div key={`contact-dba-${dba.id}`} onKeyPress={e => e.key === 'Enter' && updateDba(dba)}>
                            {disabled && <CustomInput
                                label={`DBA #${i + 1}`}
                                value={dba.name}
                                disabled
                                endAdornment={
                                    <InputAdornment position="end">
                                        {editDba !== dba.id && props.action !== 'View' && <IconButton disabled={addDba} onClick={() => {
                                            setEditDba(dba.id);
                                            setDbaName(dba.name);
                                        }}>
                                            <Tooltip id="tooltip-edit-dba" title="Edit DBA" placement="top">
                                                <Edit/>
                                            </Tooltip>
                                        </IconButton>}
                                    </InputAdornment>
                                }
                            />}
                            {!disabled && <CustomInput
                                key={`contact-dba-${dba.id}`}
                                label={`DBA #${i + 1}`}
                                value={disabled ? dba.name : dbaName}
                                onChange={(e) => {setDbaName(e.target.value)}}
                                autoFocus={true}
                                endAdornment={
                                    <InputAdornment position="end">
                                        {editDba === dba.id && <IconButton onClick={() => {
                                            updateDba(dba);
                                        }}>
                                            <Tooltip id="tooltip-save-dba" title="Save DBA" placement="top">
                                                <Save/>
                                            </Tooltip>
                                        </IconButton>}

                                        <IconButton onClick={() => {
                                            setEditDba(null);
                                        }}>
                                            <Tooltip id="tooltip-cancel-add-dba" title="Cancel" placement="top">
                                                <Close/>
                                            </Tooltip>
                                        </IconButton>

                                        {
                                            (editDba === dba.id) &&
                                            <IconButton onClick={() => {
                                                setDbasForAdd(dbasForAdd.filter(item => item.id !== dba.id));
                                            }}>
                                                <Tooltip id="tooltip-delete-dba" title="Delete DBA" placement="top">
                                                    <Delete/>
                                                </Tooltip>
                                            </IconButton>
                                        }
                                    </InputAdornment>
                                }
                            />}
                        </div>
                        );
                    }
                )}

                {/* EDIT / VIEW MODE */}
                {props.action !== 'Add' && <>

                {contactDbas.sort((a, b) => (a.id > b.id ? -1 : 0)).map((dba, i) => {
                    const disabled = editDba !== dba.id
                    return (
                        <div key={`contact-dba-${dba.id}`} onKeyPress={e => e.key === 'Enter' && updateDba(dba)}>
                        {disabled && <CustomInput
                            label={`DBA #${i + 1}`}
                            value={dba.name}
                            disabled
                            endAdornment={
                                <InputAdornment position="end">
                                    {editDba !== dba.id && props.action !== 'View' && <IconButton disabled={addDba} onClick={() => {
                                        setEditDba(dba.id);
                                        setDbaName(dba.name);
                                    }}>
                                        <Tooltip id="tooltip-edit-dba" title="Edit DBA" placement="top">
                                            <Edit/>
                                        </Tooltip>
                                    </IconButton>}
                                </InputAdornment>
                            }
                        />}
                        {!disabled && <CustomInput
                            key={`contact-dba-${dba.id}`}
                            label={`DBA #${i + 1}`}
                            value={disabled ? dba.name : dbaName}
                            onChange={(e) => setDbaName(e.target.value)}
                            autoFocus={true}
                            endAdornment={
                                <InputAdornment position="end">
                                    {editDba === dba.id && <IconButton onClick={() => {
                                        updateDba(dba);
                                    }}>
                                        <Tooltip id="tooltip-save-dba" title="Save DBA" placement="top">
                                            <Save/>
                                        </Tooltip>
                                    </IconButton>}

                                    <IconButton onClick={() => {
                                        setEditDba(null);
                                    }}>
                                        <Tooltip id="tooltip-cancel-add-dba" title="Cancel" placement="top">
                                            <Close/>
                                        </Tooltip>
                                    </IconButton>

                                    {
                                        (canDelete && props.action !== 'View') &&
                                        <IconButton onClick={() => {
                                            setDataToDelete(dba.id);
                                        }}>
                                            <Tooltip id="tooltip-delete-dba" title="Delete DBA" placement="top">
                                                <Delete/>
                                            </Tooltip>
                                        </IconButton>
                                    }
                                </InputAdornment>
                            }
                        />}

                        </div>
                        );
                    }
                )}
                </>}
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                {props.action !== 'View' && <Button color="primary" onClick={() => {
                    setAddDba(true);
                    setDbaName(null);
                    setEditDba(null);
                }}><i className="fas fa-plus"></i> Add DBA</Button>}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <hr/>
                {props.action !== 'Add' && <h3 className="red-text">{contactCompanies.length} {contactCompanies.length === 1 ? 'Company' : 'Companies'} :</h3>}
                {props.action == 'Add' && <h3 className="red-text">{companiesForAdd.length} {companiesForAdd.length === 1 ? 'Company' : 'Companies'} :</h3>}
                {props.action !== 'View' && <Button color="primary" onClick={() => {
                    setOpenAddCompanyForm(true);
                }}><i className="fas fa-plus"></i> Add Company</Button>}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                {props.loading && <div className="fade-loader-center">
                        <FadeLoader />
                </div>}
            </GridItem>
                {/* EDIT MODE */}
                {(!props.loading && props.action !== 'Add') && <>
                    {contactCompanies.map((cd, i) => {
                    const cc = cd.contact_data;
                    const cm = cd.company_data;
                    const dbaName = get(cd, `dba_data.name`, null);
                    const dbaActive = get(cd, `dba_data.active`, null);
                    const disabled = editCompany === i ? false : true;
                    const chosen = editCompany === i ? true : false;
                    return <GridItem xs={12} sm={6} md={3} key={`${i}-contact-cont`}>
                    <div style={{ fontSize: 16 }} className="boxed">
                        <div style={{float:'right'}}>
                            {((canEdit && props.action !== "View") && !chosen) && <IconButton onClick={() => {
                                setEditCompany(i);
                            }}>
                                <Tooltip id="tooltip-save-dba" title="Edit" placement="top">
                                    <Edit />
                                </Tooltip>
                            </IconButton>}
                            {((canEdit && props.action !== "View") && chosen) && <IconButton onClick={() => {
                                const data = clone(cd);
                                data.is_company = false;
                                setUpdateContact(true);
                                updateCompanyContacts(data);
                                setEditCompany(null);
                            }}>
                                <Tooltip id="tooltip-save-dba" title="Save" placement="top">
                                    <Save />
                                </Tooltip>
                            </IconButton>}
                            {((canEdit && props.action !== 'View') && chosen) && <IconButton
                                onClick={() => {
                                    setDataToDelete({
                                        id: cd.id,
                                        contact_id: cc.id,
                                        company_id: cm.id,
                                        is_company: false
                                    });
                                    setNotificationAsk('Are you sure you want to delete this company - '+cm.name);
                                }}
                            >
                                <i className="fas fa-trash-alt red-text"></i>
                            </IconButton>}
                        </div>
                        <span className="hoverable bold-on-hover" onClick={() => {
                            props.setOpenedEditCompany(true);
                            props.setCompanyID(cm.id);
                        }}>
                            <small>#{i+1}</small>
                            <h4><strong>{cm ? cm.name : ''}</strong></h4>
                            {dbaName && <p><strong>dba {dbaName} {!dbaActive && <span className="red-text"> (removed)</span>}</strong></p>}
                        </span>
                        {disabled && <CustomInput
                            label="Department"
                            value={cd.department}
                            onChange={(e) => { 
                                const newContactCompanies = clone(contactCompanies);
                                newContactCompanies[i].department = e.target.value;
                                setContactCompanies(newContactCompanies);
                            }}
                            disabled={disabled}
                        />}
                        {!disabled && <CustomInput
                            label="Department"
                            value={cd.department}
                            onChange={(e) => { 
                                const newContactCompanies = clone(contactCompanies);
                                newContactCompanies[i].department = e.target.value;
                                setContactCompanies(newContactCompanies);
                            }}
                            autoFocus={true}
                        />}
                        <CustomInput
                            label="Title"
                            value={cd.title}
                            onChange={(e) => { 
                                const newContactCompanies = clone(contactCompanies);
                                newContactCompanies[i].title = e.target.value;
                                setContactCompanies(newContactCompanies);
                            }}
                            disabled={disabled}
                        />
                        <CustomInput
                            label="Phone"
                            value={cd.phone}
                            onChange={(e) => { 
                                const newContactCompanies = clone(contactCompanies);
                                newContactCompanies[i].phone = e.target.value;
                                setContactCompanies(newContactCompanies);
                            }}
                            disabled={disabled}
                        />
                        <CustomInput
                            label="Email"
                            value={cd.email}
                            onChange={(e) => { 
                                const newContactCompanies = clone(contactCompanies);
                                newContactCompanies[i].email = e.target.value;
                                setContactCompanies(newContactCompanies);
                            }}
                            disabled={disabled}
                        />
                        <div className='custom-textfield'>
                            <TextField
                                label='Notes'
                                multiline
                                rowsMax='4'
                                value={cd.notes ? cd.notes : ''}
                                onChange={(e) => {
                                    const newContactCompanies = clone(contactCompanies);
                                    newContactCompanies[i].notes = e.target.value;
                                    setContactCompanies(newContactCompanies);
                                }}
                                className=''
                                margin='normal'
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    </GridItem>
                })}
                </>}

                {/* ADD MODE */}
                {props.action === 'Add' && <>
                    {companiesForAdd.map((cd, i) => {
                    console.log('companisForAdd', companiesForAdd)
                    const cc = cd.contact_data;
                    const cm = cd.company_data;
                    const dbaName = get(cd, `dba_data.name`, null);
                    return <GridItem xs={12} sm={6} md={3} key={`${i}-contact-cont`}><div key={`${i}-contact-cont`} style={{ fontSize: 16 }} className="boxed">
                        <div style={{float:'right'}}>
                            <IconButton
                                onClick={() => {
                                    setCompaniesForAdd(companiesForAdd.filter((cc,j) => {
                                        return i !== j;
                                    }))
                                }}
                            >
                                <i className="fas fa-trash-alt red-text"></i>
                            </IconButton>
                        </div>
                        <span className="hoverable bold-on-hover" onClick={() => {
                            props.setOpenedEditCompany(true);
                            props.setCompanyID(cm.id);
                        }}>
                            <small>#{i+1}</small>
                            <h4><strong>{cm ? cm.name : ''}</strong></h4>
                            {dbaName && <p><strong>dba {dbaName}</strong></p>}
                        </span>
                        <CustomInput
                            label="Department"
                            value={cd.department}
                            onChange={(e) => { 
                                setCompaniesForAdd(companiesForAdd.map((cc,j) => {
                                   if (j === i) {
                                     const newCC = clone(cc);
                                     newCC.department = e.target.value;
                                     return newCC;
                                   } else {
                                    return cc;
                                   }
                                }))
                            }}
                        />
                        <CustomInput
                            label="Title"
                            value={cd.title}
                            onChange={(e) => { 
                                setCompaniesForAdd(companiesForAdd.map((cc,j) => {
                                    if (j === i) {
                                      const newCC = clone(cc);
                                      newCC.title = e.target.value;
                                      return newCC;
                                    } else {
                                     return cc;
                                    }
                                 }))
                            }}
                        />
                        <CustomInput
                            label="Phone"
                            value={cd.phone}
                            onChange={(e) => { 
                                setCompaniesForAdd(companiesForAdd.map((cc,j) => {
                                    if (j === i) {
                                      const newCC = clone(cc);
                                      newCC.phone = e.target.value;
                                      return newCC;
                                    } else {
                                     return cc;
                                    }
                                 }))
                            }}
                        />
                        <CustomInput
                            label="Email"
                            value={cd.email}
                            onChange={(e) => { 
                                setCompaniesForAdd(companiesForAdd.map((cc,j) => {
                                    if (j === i) {
                                      const newCC = clone(cc);
                                      newCC.email = e.target.value;
                                      return newCC;
                                    } else {
                                     return cc;
                                    }
                                 }))
                            }}
                        />
                        <div className='custom-textfield'>
                            <TextField
                                label='Notes'
                                multiline
                                rowsMax='4'
                                value={cd.notes ? cd.notes : ''}
                                onChange={(e) => {
                                    setCompaniesForAdd(companiesForAdd.map((cc,j) => {
                                        if (j === i) {
                                          const newCC = clone(cc);
                                          newCC.notes = e.target.value;
                                          return newCC;
                                        } else {
                                         return cc;
                                        }
                                     }))
                                }}
                                className=''
                                margin='normal'
                            />
                        </div>
                    </div>
                    </GridItem>
                })}
                </>}

            {openAddCompanyForm && <CustomDialogWithCompany
                indx="id"
                desc="name"
                label="Add Company"
                title="Add Company"
                open={openAddCompanyForm}
                close={() => {
                    setOpenAddCompanyForm(false);
                }}
                create_and_choose={choose}
                choose={choose}
                theContactData={contactData}
                loggedInUser={loggedInUser}
                prefill={props.prefill}
            />}
            {message && <Notification 
                open={message ? true : false}
                close={() => { setMessage('') }}
                message={message}
                autoClose={3000}
            />}
            {notificationAsk && <NotificationAsk 
                open={notificationAsk ? true : false}
                close={() => { 
                    setNotificationAsk('');
                    setDataToDelete(null);
                }}
                success={() => {
                    setDeleteCompany(true);
                    deleteCompanyContacts(dataToDelete);
                    setNotificationAsk('');
                    setDataToDelete(null);
                }}
                title="Are you sure?"
                message={notificationAsk}
                success_button_text="Yes, Delete"
            />}
            {(dataToDelete && !notificationAsk) && <NotificationAsk 
                open={dataToDelete ? true : false}
                close={() => { 
                    setDataToDelete(null);
                }}
                success={() => {
                    deleteContactDba(dataToDelete);
                    setDataToDelete(null);
                }}
                title="Are you sure?"
                message={`Are your sure you want to delete this DBA - 
                    ${props.contactDbas.find(i => i.id === dataToDelete) ? props.contactDbas.find(i => i.id === dataToDelete).name : ''}?`}
                success_button_text="Yes, Delete"
            />}
        </GridContainer>
        </>
    )
}

const mapStateToProps = state => {
    const c = state.crm_companies
    return {
        contactsData: state.crm_contacts.contactsData,
        companyContactsData: state.crm_companies.companyContactsData,
        companyContact: state.crm_contacts.contactData,
        success: c.success,
        contacts_success: state.crm_contacts.success,
        companyData: state.crm_companies.companyData,
        loading: c.loading,
        contactDbas: state.crm_contacts.contactDbas
    }
}

export default connect(mapStateToProps, null)(ContactsFormCompanies);

