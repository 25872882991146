import * as actionTypes from './actionTypes';

export const clearRSheets = () => {
    return {
        type: actionTypes.CLEAR_RSHEETS
    }
}

export const clearRSheetsMessages = () => {
    return {
        type: actionTypes.CLEAR_RSHEETS_MESSAGES
    }
}

export const clearRSheetData = () => {
    return {
        type: actionTypes.CLEAR_RSHEET_DATA
    }
}


// GET_RSHEETS

export const getRsheets = (data) => {
    return {
        type: actionTypes.GET_RSHEETS,
        data
    }
}

export const getRsheetsStart = () => {
    return {
        type: actionTypes.GET_RSHEETS_START
    }
}

export const getRsheetsSuccess = (response) => {
    return {
        type: actionTypes.GET_RSHEETS_SUCCESS,
        response
    }
}

export const getRsheetsFail = (err) => {
    return {
        type: actionTypes.GET_RSHEETS_FAIL,
        err
    }
}

// GET_RSHEET

export const getRsheet = (data) => {
    return {
        type: actionTypes.GET_RSHEET,
        data
    }
}

export const getRsheetStart = () => {
    return {
        type: actionTypes.GET_RSHEET_START
    }
}

export const getRsheetSuccess = (response) => {
    return {
        type: actionTypes.GET_RSHEET_SUCCESS,
        response
    }
}

export const getRsheetFail = (err) => {
    return {
        type: actionTypes.GET_RSHEET_FAIL,
        err
    }
}

// CREATE_RSHEET

export const createRsheet = (data, invoiceData, recordHistory) => {
    return {
        type: actionTypes.CREATE_RSHEET,
        data,
        invoiceData,
        recordHistory
    }
}

export const createRsheetStart = () => {
    return {
        type: actionTypes.CREATE_RSHEET_START
    }
}

export const createRsheetSuccess = (response) => {
    return {
        type: actionTypes.CREATE_RSHEET_SUCCESS,
        response
    }
}

export const createRsheetFail = (err) => {
    return {
        type: actionTypes.CREATE_RSHEET_FAIL,
        err
    }
}

// UPDATE_RSHEET

export const updateRsheet = (data, invoiceData = null, recordHistory, pdfObj) => {
    return {
        type: actionTypes.UPDATE_RSHEET,
        data,
        invoiceData,
        recordHistory,
        pdfObj
    }
}

export const updateRsheetStart = () => {
    return {
        type: actionTypes.UPDATE_RSHEET_START
    }
}

export const updateRsheetSuccess = (response) => {
    return {
        type: actionTypes.UPDATE_RSHEET_SUCCESS,
        response
    }
}

export const updateRsheetFail = (err) => {
    return {
        type: actionTypes.UPDATE_RSHEET_FAIL,
        err
    }
}

// DELETE_RSHEET

export const deleteRsheet = (data) => {
    return {
        type: actionTypes.DELETE_RSHEET,
        data
    }
}

export const deleteRsheetStart = () => {
    return {
        type: actionTypes.DELETE_RSHEET_START
    }
}

export const deleteRsheetSuccess = (response) => {
    return {
        type: actionTypes.DELETE_RSHEET_SUCCESS,
        response
    }
}

export const deleteRsheetFail = (err) => {
    return {
        type: actionTypes.DELETE_RSHEET_FAIL,
        err
    }
}

// CREATE_RSHEET_PDF

export const createRsheetPdf = (data) => {
    return {
        type: actionTypes.CREATE_RSHEET_PDF,
        data
    }
}

export const createRsheetPdfStart = () => {
    return {
        type: actionTypes.CREATE_RSHEET_PDF_START
    }
}

export const createRsheetPdfSuccess = (response) => {
    return {
        type: actionTypes.CREATE_RSHEET_PDF_SUCCESS,
        response
    }
}

export const createRsheetPdfFail = (err) => {
    return {
        type: actionTypes.CREATE_RSHEET_PDF_FAIL,
        err
    }
}

// CREATE_RSHEET_FILES

export const createRsheetFiles = (data) => {
    return {
        type: actionTypes.CREATE_RSHEET_FILES,
        data
    }
}

export const createRsheetFilesStart = () => {
    return {
        type: actionTypes.CREATE_RSHEET_FILES_START
    }
}

export const createRsheetFilesSuccess = (response) => {
    return {
        type: actionTypes.CREATE_RSHEET_FILES_SUCCESS,
        response
    }
}

export const createRsheetFilesFail = (err) => {
    return {
        type: actionTypes.CREATE_RSHEET_FILES_FAIL,
        err
    }
}

// GET_RSHEET_FILES

export const getRsheetFiles = (data) => {
    return {
        type: actionTypes.GET_RSHEET_FILES,
        data
    }
}

export const getRsheetFilesStart = () => {
    return {
        type: actionTypes.GET_RSHEET_FILES_START
    }
}

export const getRsheetFilesSuccess = (response) => {
    return {
        type: actionTypes.GET_RSHEET_FILES_SUCCESS,
        response
    }
}

export const getRsheetFilesFail = (err) => {
    return {
        type: actionTypes.GET_RSHEET_FILES_FAIL,
        err
    }
}

// GET_RS_TYPES 

export const getRsTypes = () => {
    return {
        type: actionTypes.GET_RS_TYPES,
    }
}

export const getRsTypesStart = () => {
    return {
        type: actionTypes.GET_RS_TYPES_START
    }
}

export const getRsTypesSuccess = (response) => {
    return {
        type: actionTypes.GET_RS_TYPES_SUCCESS,
        response
    }
}

export const getRsTypesFail = (err) => {
    return {
        type: actionTypes.GET_RS_TYPES_FAIL,
        err
    }
}

// GET_NEXT_RS_ILLI_NUMBER 

export const getNextRsIlliNumber = (data) => {
    return {
        type: actionTypes.GET_NEXT_RS_ILLI_NUMBER,
        data
    }
}

export const getNextRsIlliNumberStart = () => {
    return {
        type: actionTypes.GET_NEXT_RS_ILLI_NUMBER_START
    }
}

export const getNextRsIlliNumberSuccess = (response) => {
    return {
        type: actionTypes.GET_NEXT_RS_ILLI_NUMBER_SUCCESS,
        response
    }
}

export const getNextRsIlliNumberFail = (err) => {
    return {
        type: actionTypes.GET_NEXT_RS_ILLI_NUMBER_FAIL,
        err
    }
}

// CHECK_RS_ILLI_NUMBER 

export const checkRsIlliNumber = (data) => {
    return {
        type: actionTypes.CHECK_RS_ILLI_NUMBER,
        data
    }
}

export const checkRsIlliNumberStart = () => {
    return {
        type: actionTypes.CHECK_RS_ILLI_NUMBER_START
    }
}

export const checkRsIlliNumberSuccess = (response) => {
    return {
        type: actionTypes.CHECK_RS_ILLI_NUMBER_SUCCESS,
        response
    }
}

export const checkRsIlliNumberFail = (err) => {
    return {
        type: actionTypes.CHECK_RS_ILLI_NUMBER_FAIL,
        err
    }
}

// CREATE_RSHEET_REVISION 

export const createRsheetRevision = (data) => {
    return {
        type: actionTypes.CREATE_RSHEET_REVISION,
        data
    }
}

export const createRsheetRevisionStart = () => {
    return {
        type: actionTypes.CREATE_RSHEET_REVISION_START
    }
}

export const createRsheetRevisionSuccess = (response) => {
    return {
        type: actionTypes.CREATE_RSHEET_REVISION_SUCCESS,
        response
    }
}

export const createRsheetRevisionFail = (err) => {
    return {
        type: actionTypes.CREATE_RSHEET_REVISION_FAIL,
        err
    }
}

// GET_RS_HISTORIES 

export const getRsHistories = (data) => {
    return {
        type: actionTypes.GET_RS_HISTORIES,
        data
    }
}

export const getRsHistoriesStart = () => {
    return {
        type: actionTypes.GET_RS_HISTORIES_START
    }
}

export const getRsHistoriesSuccess = (response) => {
    return {
        type: actionTypes.GET_RS_HISTORIES_SUCCESS,
        response
    }
}

export const getRsHistoriesFail = (err) => {
    return {
        type: actionTypes.GET_RS_HISTORIES_FAIL,
        err
    }
}

// CREATE_RS_HISTORY 

export const createRsHistory = (data) => {
    return {
        type: actionTypes.CREATE_RS_HISTORY,
        data
    }
}

export const createRsHistoryStart = () => {
    return {
        type: actionTypes.CREATE_RS_HISTORY_START
    }
}

export const createRsHistorySuccess = (response) => {
    return {
        type: actionTypes.CREATE_RS_HISTORY_SUCCESS,
        response
    }
}

export const createRsHistoryFail = (err) => {
    return {
        type: actionTypes.CREATE_RS_HISTORY_FAIL,
        err
    }
}

// UPDATE_RS_HISTORY 

export const updateRsHistory = (data) => {
    return {
        type: actionTypes.UPDATE_RS_HISTORY,
        data
    }
}

export const updateRsHistoryStart = () => {
    return {
        type: actionTypes.UPDATE_RS_HISTORY_START
    }
}

export const updateRsHistorySuccess = (response) => {
    return {
        type: actionTypes.UPDATE_RS_HISTORY_SUCCESS,
        response
    }
}

export const updateRsHistoryFail = (err) => {
    return {
        type: actionTypes.UPDATE_RS_HISTORY_FAIL,
        err
    }
}

// DELETE_RS_HISTORY 

export const deleteRsHistory = (data) => {
    return {
        type: actionTypes.DELETE_RS_HISTORY,
        data
    }
}

export const deleteRsHistoryStart = () => {
    return {
        type: actionTypes.DELETE_RS_HISTORY_START
    }
}

export const deleteRsHistorySuccess = (response) => {
    return {
        type: actionTypes.DELETE_RS_HISTORY_SUCCESS,
        response
    }
}

export const deleteRsHistoryFail = (err) => {
    return {
        type: actionTypes.DELETE_RS_HISTORY_FAIL,
        err
    }
}

// GET_RS_INTERNAL_REVIEWERS 

export const getRsInternalReviewers = (data) => {
    return {
        type: actionTypes.GET_RS_INTERNAL_REVIEWERS,
        data
    }
}

export const getRsInternalReviewersStart = () => {
    return {
        type: actionTypes.GET_RS_INTERNAL_REVIEWERS_START
    }
}

export const getRsInternalReviewersSuccess = (response) => {
    return {
        type: actionTypes.GET_RS_INTERNAL_REVIEWERS_SUCCESS,
        response
    }
}

export const getRsInternalReviewersFail = (err) => {
    return {
        type: actionTypes.GET_RS_INTERNAL_REVIEWERS_FAIL,
        err
    }
}

// CREATE_RS_INTERNAL_REVIEWER 

export const createRsInternalReviewer = (data) => {
    return {
        type: actionTypes.CREATE_RS_INTERNAL_REVIEWER,
        data
    }
}

export const createRsInternalReviewerStart = () => {
    return {
        type: actionTypes.CREATE_RS_INTERNAL_REVIEWER_START
    }
}

export const createRsInternalReviewerSuccess = (response) => {
    return {
        type: actionTypes.CREATE_RS_INTERNAL_REVIEWER_SUCCESS,
        response
    }
}

export const createRsInternalReviewerFail = (err) => {
    return {
        type: actionTypes.CREATE_RS_INTERNAL_REVIEWER_FAIL,
        err
    }
}

// UPDATE_RS_INTERNAL_REVIEWER 

export const updateRsInternalReviewer = (data) => {
    return {
        type: actionTypes.UPDATE_RS_INTERNAL_REVIEWER,
        data
    }
}

export const updateRsInternalReviewerStart = () => {
    return {
        type: actionTypes.UPDATE_RS_INTERNAL_REVIEWER_START
    }
}

export const updateRsInternalReviewerSuccess = (response) => {
    return {
        type: actionTypes.UPDATE_RS_INTERNAL_REVIEWER_SUCCESS,
        response
    }
}

export const updateRsInternalReviewerFail = (err) => {
    return {
        type: actionTypes.UPDATE_RS_INTERNAL_REVIEWER_FAIL,
        err
    }
}

// DELETE_RS_INTERNAL_REVIEWER 

export const deleteRsInternalReviewer = (data) => {
    return {
        type: actionTypes.DELETE_RS_INTERNAL_REVIEWER,
        data
    }
}

export const deleteRsInternalReviewerStart = () => {
    return {
        type: actionTypes.DELETE_RS_INTERNAL_REVIEWER_START
    }
}

export const deleteRsInternalReviewerSuccess = (response) => {
    return {
        type: actionTypes.DELETE_RS_INTERNAL_REVIEWER_SUCCESS,
        response
    }
}

export const deleteRsInternalReviewerFail = (err) => {
    return {
        type: actionTypes.DELETE_RS_INTERNAL_REVIEWER_FAIL,
        err
    }
}
