import React from "react";
import { getPaymentAmount, shouldGetPaymentAmount } from '../RSheetsLogic';
import { formatDollar, formatNumberWithCommas } from "../../../shared/utility";

const PaymentScheduleFullForm = props => {
    const { data, sheetType } = props;
    if (!data.invoices) return <div className="the-table">
        <table>
            <tbody>
                <tr>
                    <td className="col-100">No Payment Schedule Entries</td>
                </tr>
            </tbody>
        </table>
    </div>;
    if (data.invoices.length === 0) return <div className="the-table">
        <table>
            <tbody>
                <tr>
                    <td className="col-100">No Payment Schedule Entries</td>
                </tr>
            </tbody>
        </table>
    </div>;

    return <>
        {data.invoices.map((dv,i) => {
            const dat = dv.data ? dv.data : { entries: [] };
            const entries = dat.entries;
            const line_items = dat.line_items;

            return <div key={`${dv}-div`} style={{marginBottom:40}}>
                <h4>Invoice #{i+1}</h4>
                <div className="the-table">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    #
                                </th>
                                <th>
                                    Payment Value
                                </th>
                                <th>
                                    Option
                                </th>
                                <th>
                                    Notes
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {entries.map((p,ii) => {
                                const shouldGetPayment = shouldGetPaymentAmount(p.option);
                                const paym = getPaymentAmount(data, p.option, null, sheetType);
                                const paymentAmount = paym ? formatNumberWithCommas(paym) : '0.00';
                                const displayOnly = ii > 0 ? p.display_only ? 'DISPLAY ONLY' : null : null;
                                if (displayOnly) return null;
                                const val = shouldGetPayment ? paymentAmount : p.value;
                                return <tr key={`${ii}-divv`}>
                                    <th>
                                        {ii+1}
                                    </th>
                                    <th>
                                        ${val}
                                    </th>
                                    <th>
                                        {p.option}
                                    </th>
                                    <th>
                                        {displayOnly ? <div>(Display Only)</div> : null}
                                        {p.notes}
                                    </th>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>

                {line_items.length > 0 && <div>
                    <hr />
                    <h4>Line Items</h4>
                    <div className="the-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        #
                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                    <th>
                                        Description
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {line_items.map((li,i) => {
                                    return <tr key={`li-${i}`}>
                                        <td style={{textAlign:'center'}}>
                                            #{i+1}
                                        </td>
                                        <td style={{textAlign:'center'}}>
                                            ${formatDollar(li.amount)}
                                        </td>
                                        <td style={{textAlign:'center'}}>
                                            {li.description}
                                        </td>
                                    </tr>   
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>}
            </div>
        })}
    </>
}

export default PaymentScheduleFullForm;