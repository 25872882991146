import React, { useEffect, useState } from "react";
import { Dialog } from "@material-ui/core";
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import Button from "../CustomButtons/Button";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInputValidate";
import CustomInputStates from "components/CustomInput/CustomInputStates";
import CustomSelect from "components/CustomSelect/CustomSelect";
import { Close } from "@material-ui/icons";
import { handleError } from "../../shared/utility";
import Notification from "../Notification/Notification";
import axios from "store/axios/axios-crm";

const CustomCRMEditor = (props) => {
    console.log('CustomCRMEditor', props);
    const [val, setVal] = useState(null);
    const [name, setName] = useState(null);
    const [notification, setNotification] = useState('');
    const {
        value,      // required
        type,       // required
        field,      // required
        fieldType,  // required
        options,    // required if <select>
        label,      // required
        id,         // required

        chooseVal,  // Fn' required
        close,      // Fn' required

        company_id, 
        contact_id
    } = props;

    useEffect(() => {
        setVal(value);
        // GET ORIGINAL BACKEND DATA
        const obj = {
            type,
            id,
            field
        };
        if (contact_id) obj.contact_id = contact_id;
        if (company_id) obj.company_id = company_id;
        props.getCrmData(obj);
    }, []);

    const form = () => {
        if (fieldType === 'input') {
            return <CustomInput 
                value={val}
                onChange={(e) => {
                    setVal(e.target.value);
                }}
                label={label}
                autoFocus
            />
        }
        if (fieldType === 'states') {
            return <CustomInputStates
                value={val}
                onChange={(e) => {
                    setVal(e);
                }}
                label={label}
            />
        }
        if (fieldType === 'select') {
            if (type === 'company_dba' || type === 'contact_dba') {
                console.log('this is the company dba');
                return <CustomSelect
                    options={props.original_crm_data ? props.original_crm_data.filter(d => d.active === 1) : []}
                    choose={(e) => {
                        setVal(e);
                        let named = '';
                        if (props.original_crm_data) {
                            props.original_crm_data.forEach(cd => {
                                if (cd.id === e) {
                                    named = cd.name;
                                }
                            })
                        }
                        setName(named);
                    }}
                    indx="id"
                    desc="name"
                    default={id}
                />
            } else {
                return <CustomSelect
                    options={options}
                    choose={(e) => {
                        setVal(e);
                    }}
                    default={val}
                />
            }
        }
    }

    const getDBA = (arr, id) => {
        console.log('getting dba', arr, id)
        let val = '';
        if (arr) {
            if (Array.isArray(arr)) {
                arr.forEach(a => {
                    if (a.id === id) {
                        val = a.name;
                    }
                })
            }
        }
        return val;
    }

    console.log('CustomCrmEditor', props);

    return <div>
        <Dialog
            open={true}
            onClose={() => {
                props.close();
            }}
        >
            <div className="dialog-wrapper">
                <div className="close-btn"
                    onClick={() => {
                        props.close();
                    }}
                >
                    <Close />
                </div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <h3>{props.label}</h3>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <div>Original</div>
                        <div><strong>{props.original_crm_data ? 
                            (type !== 'company_dba' && type !== 'contact_dba') ? 
                                props.original_crm_data[field] 
                            :
                                getDBA(props.original_crm_data, id)
                        : 
                            null}</strong></div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <div>Current</div>
                        {form()}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className="mt-20">
                            {(type !== 'company_dba' && type !== 'contact_dba') && <Button
                                className="mr-20"
                                onClick={async () => {
                                    try {
                                        const response = await axios.put('/crm_data', {
                                            type,
                                            id,
                                            field,
                                            val
                                        });
                                        console.log('response', response.data);
                                    } catch(e) {
                                        handleError(e);
                                        setNotification('Could not update CRM, please make sure you are allowed to edit this entry.')
                                        return;
                                    }
                                    chooseVal(val);
                                    close();
                                }}
                                color="primary"
                            >UPDATE ORIGINAL DATA AND DOCUMENT</Button>}
                            <Button
                                className="mr-20"
                                onClick={() => {
                                    chooseVal(val, name);
                                    close();
                                }}
                                color="rose"
                            >UPDATE DOCUMENT {(type !== 'company_dba' && type !== 'contact_dba') ? 'ONLY' : ''}</Button>
                            <Button
                                onClick={() => {
                                    close();
                                }}
                                color="white"
                            >CANCEL</Button>
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
        </Dialog>

        {notification && <Notification 
            message={notification}
            open={true}
            close={() => {
                setNotification('');
            }}
        />}
    </div>
}

const mapStateToProps = (state) => {
	const c = state.crm_contacts;
	return {
		loading_crm_data: c.loading_crm_data,
        error_crm_data: c.error_crm_data,
        success_crm_data: c.success_crm_data,
        original_crm_data: c.original_crm_data,
        companyDbas: state.crm_companies.companyDbas,
        contactDbas: state.crm_contacts.contactDbas
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getCrmData: (data) => {
            dispatch(actions.getCrmData(data));
        },
        updateCrmData: (data) => {
            dispatch(actions.updateCrmData(data));
        },
        getCompanyDbas: (company_id) => {
            dispatch(actions.getCompanyDbas(company_id));
        },
        getContactDbas: (contact_id) => {
            dispatch(actions.getContactDbas(contact_id));
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomCRMEditor);