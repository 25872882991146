import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import checkPermissions from "../../shared/checkPermissions";
import checkPermissionsActions from "../../shared/checkPermissionsActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import RSheetsAllList from "../../containers/RSheets/RSheetsAllList";
import RSheetsTypeOptions from "../../containers/RSheets/RSheetsTypeOptions";
import RSheetsForm from "../../containers/RSheets/RSheetsForm";
import { doAction } from "../../containers/RSheets/RSheetsLogic";
import NotificationAsk from "components/Notification/NotificationAsk";
import Button from "../../components/CustomButtons/Button";
import { getParameterByName } from "../../shared/utility";

const getAdminData = (args) => {
    const { 
        permsArr, 
        setLevel, 
        setAllLevels, 
        tab,
        getRsheets
    } = args;
    let highestLevel = 0;
    if (permsArr) {
        const levels = permsArr.filter(f => {
            if (f.indexOf('approve') !== -1) {
                return true;
            }
        }).map(m => {
            const mp = m.split('_level_')[1];
            return parseInt(mp);
        })
        console.log('levels', levels, permsArr);
        const maxLevel = Math.max(...levels);
        setLevel(maxLevel);
        setAllLevels(levels);
        // DB status
        const pendingObj = {
            1: [2,6], // submitted for level 1 approver's approval, level 2 denied back to level 1
            2: [
                2,6,
                3,7
            ], // submitted for level 2 approver's approval, 
            3: [
                2,6,
                3,7,
                4,8
            ], // submitted for level 3 approver's approval
            4: [
                2,6,
                3,7,
                4,8,
                10
            ], // submitted for level 4 final approver's appro
        }
        const furtherApprovalObj = {
            1: [3,4,10],
            2: [4,10],
            3: [10],
            4: []
        }
        // 1 - Draft 
        // 2 - Submitted to level 1 
        // 3 - Submitted to level 2
        // 4 - Submitted to level 3 
        // 5 - Level 1 Denied to Submitter = 2 -> 5
        // 6 - Level 2 Denied to Level 1 = 3 ->  6
        // 7 - Level 3 Denied to Level 2 = 4 -> 7
        // 8 - Final Approver denied to Level 3 = 10 -> 8
        // 9 - Final Approver approved
        // 10 - Submitted to final approver for approval 
        let the_status = pendingObj[maxLevel];
        if (tab === 'pending_further_approval') {
            the_status = furtherApprovalObj[maxLevel];
        }
        console.log('doing Action', {tab, getRsheets, status:the_status})
        doAction({tab, getRsheets, status:the_status});
    }
}

class RSheetsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: "",
            error: null,
            success: null,
            addSheet: false,
            currentType: null,
            showForm: true,
            currentAction: 'Add',
            level: null,
            allLevels: null,
            deleteData: null,
            askBeforeDelete: false,
            shouldReload: false,
            prefill_property_id: null
        };
    }

    setLevel = (level) => {
        this.setState({level});
    };

    setAllLevels = (allLevels) => {
        this.setState({allLevels});
    };

    setTab = (tab) => {
        this.setState({ tab });
    };

    setAddSheet = (addSheet) => {
        this.setState({ addSheet });
    }

    clearMessages = (callback) => {
        this.setState({ error: null, success: null }, () => {
            if (callback) {
                callback();
            }
        });
    };

    historyPush = (url) => {
        this.props.history.push(url);
    }

    componentDidMount() {
        // default is to load My Drafts
        this.goToMyDrafts();
        const action = getParameterByName('p', window.location.href);
        if (action) {
            const permitted = checkPermissions("Routing");
            if (permitted) {
                const property_id = getParameterByName('property_id', window.location.href);
                if (property_id) {
                    const obj = {};
                    obj.addSheet = true;
                    obj.prefill_property_id = parseInt(property_id);
                    this.setState(obj);
                }
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.error !== this.props.error)
            this.setState({ error: this.props.error });
        if (prevProps.success !== this.props.success)
            this.setState({ success: this.props.success });
        if (prevProps.success_crud === null && this.props.success_crud) {
            if (this.state.shouldReload) {
                this.reload();
                this.setState({shouldReload:false});
            }
        }
    }

    reload = () => {
        const { getRsheets } = this.props;
        const { tab } = this.state;
        const permissions = checkPermissionsActions("Routing");
        const permsArr = permissions ? Object.keys(permissions) : [];
        // NOTE: not just admin anymore
        getAdminData({
            permsArr, 
            setLevel: this.setLevel, 
            setAllLevels: this.setAllLevels,
            tab,
            getRsheets
        });
    }

    goToMyDrafts = () => {
        this.setTab('my_drafts');
        const permissions = checkPermissionsActions("Routing");
        const permsArr = permissions ? Object.keys(permissions) : [];
        const setLevel = this.setLevel;
        const setAllLevels = this.setAllLevels;
        const { getRsheets } = this.props;
        getAdminData({
            permsArr, 
            setLevel, 
            setAllLevels,
            tab: 'my_drafts',
            getRsheets
        });
    }

    choosePendingMyApproval = () => {
        const { getRsheets } = this.props;
        const permissions = checkPermissionsActions("Routing");
        const permsArr = permissions ? Object.keys(permissions) : [];
        const setLevel = this.setLevel;
        const setAllLevels = this.setAllLevels;
        this.setTab('pending_my_drafts');
        const obj = {
            permsArr, 
            setLevel, 
            setAllLevels,
            tab: 'pending_my_drafts',
            getRsheets
        };
        console.log('obj', obj);
        getAdminData(obj);
    }

    choosePendingFurtherApproval = () => {
        const { getRsheets } = this.props;
        const permissions = checkPermissionsActions("Routing");
        const permsArr = permissions ? Object.keys(permissions) : [];
        const setLevel = this.setLevel;
        const setAllLevels = this.setAllLevels;
        this.setTab('pending_further_approval');
        getAdminData({
            permsArr, 
            setLevel, 
            setAllLevels,
            tab: 'pending_further_approval',
            getRsheets
        });
    }

    chooseMyCompleted = () => {
        const { getRsheets } = this.props;
        const permissions = checkPermissionsActions("Routing");
        const permsArr = permissions ? Object.keys(permissions) : [];
        const setLevel = this.setLevel;
        const setAllLevels = this.setAllLevels;
        this.setTab('my_completed');
        getAdminData({
            permsArr, 
            setLevel, 
            setAllLevels,
            tab: 'my_completed',
            getRsheets
        });
    }

    chooseAllCompleted = () => {
        const { getRsheets } = this.props;
        const permissions = checkPermissionsActions("Routing");
        const permsArr = permissions ? Object.keys(permissions) : [];
        const setLevel = this.setLevel;
        const setAllLevels = this.setAllLevels;
        this.setTab('all_completed');
        getAdminData({
            permsArr, 
            setLevel, 
            setAllLevels,
            tab: 'all_completed',
            getRsheets
        });
    }

    chooseVoided = () => {
        const { getRsheets } = this.props;
        const permissions = checkPermissionsActions("Routing");
        const permsArr = permissions ? Object.keys(permissions) : [];
        const setLevel = this.setLevel;
        const setAllLevels = this.setAllLevels;
        this.setTab('voided');
        getAdminData({
            permsArr, 
            setLevel, 
            setAllLevels,
            tab: 'voided',
            getRsheets
        });
    }

    render() {
        const permitted = checkPermissions("Routing");
        if (!permitted) {
            return <Redirect to="/dashboard" />;
        }

        const permissions = checkPermissionsActions("Routing");
        const permsArr = permissions ? Object.keys(permissions) : [];
        const isApprover = permissions
            ? JSON.stringify(permsArr).indexOf("approve") !== -1
                ? true
                : false
            : false;

        const { tab } = this.state;
        const { getRsheets } = this.props;

        console.log('levels', this.state.level, this.state.allLevels);

        return (
            <div style={{ marginTop: 20 }}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="rose" icon>
                                {
                                    permissions.fill_form && (
                                        <CardIcon
                                            key={'my_drafts'}
                                            color={
                                                tab === 'my_drafts'
                                                    ? "success"
                                                    : "rose"
                                            }
                                            onClick={() => {
                                                this.goToMyDrafts();
                                            }}
                                            className="hovered"
                                        >
                                            <strong>My Drafts</strong>
                                        </CardIcon>
                                    )
                                }
								{
									isApprover && (
                                        <CardIcon
                                            key={'user_drafts'}
                                            color={
                                                tab === 'user_drafts'
                                                    ? "success"
                                                    : "rose"
                                            }
                                            onClick={() => {
                                                this.setTab('user_drafts');
                                                doAction({
                                                    getRsheets,
                                                    tab: 'user_drafts'
                                                });
                                            }}
                                            className="hovered"
                                        >
                                            <strong>User Drafts</strong>
                                        </CardIcon>
                                    )
								}
								{
									(
                                        <CardIcon
                                            key={'pending_my_drafts'}
                                            color={
                                                tab === 'pending_my_drafts'
                                                    ? "success"
                                                    : "rose"
                                            }
                                            onClick={this.choosePendingMyApproval}
                                            className="hovered"
                                        >
                                            <strong>Pending My Approval</strong>
                                        </CardIcon>
                                    )
								}
								{
									isApprover && (
                                        <CardIcon
                                            key={'pending_further_approval'}
                                            color={
                                                tab === 'pending_further_approval'
                                                    ? "success"
                                                    : "rose"
                                            }
                                            onClick={this.choosePendingFurtherApproval}
                                            className="hovered"
                                        >
                                            <strong>Pending Further Approval</strong>
                                        </CardIcon>
                                    )
								}
								<CardIcon
									key={'my_completed'}
									color={
										tab === 'my_completed'
											? "success"
											: "rose"
									}
									onClick={this.chooseMyCompleted}
									className="hovered"
								>
									<strong>My Completed</strong>
								</CardIcon>
								{
									isApprover && (
                                        <CardIcon
                                            key={'all_completed'}
                                            color={
                                                tab === 'all_completed'
                                                    ? "success"
                                                    : "rose"
                                            }
                                            onClick={this.chooseAllCompleted}
                                            className="hovered"
                                        >
                                            <strong>All Completed</strong>
                                        </CardIcon>
                                    )
								}
                                {isApprover && <CardIcon
									key={'voided'}
									color={
										tab === 'voided'
											? "success"
											: "rose"
									}
									onClick={this.chooseVoided}
									className="hovered"
								>
									<strong>All Voided</strong>
								</CardIcon>}
                            </CardHeader>
                            <CardBody>
                                {this.state.tab && <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <h3>Routing Sheets</h3>
                                        {(tab === 'user_drafts' || tab === 'my_drafts') && <Button 
                                            color="rose"
                                            onClick={() => {
                                                this.setAddSheet(true);
                                            }}
                                        >ADD NEW</Button>}
                                    </GridItem>
                                </GridContainer>}

                                {this.state.tab && <RSheetsAllList 
                                    list={this.props.rsheetsData}
                                    tab={this.state.tab}
                                    getRsheet={this.props.getRsheet}
                                    createRsheetRevision={this.props.createRsheetRevision}
                                    createRsHistory={this.props.createRsHistory}
                                    rsheetRevision={this.props.rsheetRevision}
                                    goToMyDrafts={this.goToMyDrafts}
                                    setViewOnly={(viewOnly) => { this.setState({viewOnly}) }}
                                    setShowForm={(showForm) => { this.setState({showForm}) }}
                                    setCurrentAction={() => { this.setState({currentAction: 'Edit'}) }}
                                    setAskBeforeDelete={(deleteData) => { this.setState({
                                        askBeforeDelete: true,
                                        deleteData
                                    })}}
                                    setCurrentType={(currentType) => { this.setState({currentType})}}
                                    loadingEntries={this.props.loadingEntries}
                                    getRsInternalReviewers={this.props.getRsInternalReviewers}
                                />}

                                {/* Ask which Routing Sheet Type to Add */}
                                {this.state.addSheet && <RSheetsTypeOptions 
                                    open={this.state.addSheet}
                                    close={() => { this.setAddSheet(false) }}
                                    addNewSheet={(currentType) => {
                                        this.setState({
                                            currentType,
                                            showForm: true,
                                            currentAction: 'Add',
                                            viewOnly: false,
                                        });
                                    }}
                                />}

                                {this.state.showForm && <RSheetsForm 
                                    currentType={this.state.currentType}
                                    action={this.state.currentAction}
                                    setShowForm={(showForm) => { 
                                        this.setState({showForm}) 
                                    }}
                                    showForm={this.state.showForm}
                                    tab={this.state.tab}
                                    viewOnly={this.state.viewOnly}
                                    getAdminData={(tab) => {
                                        if (tab === 'pending_my_drafts') {
                                            this.choosePendingMyApproval();
                                        } else if (tab === 'pending_further_approval') {
                                            this.choosePendingFurtherApproval();
                                        } else if (tab === 'my_completed') {
                                            this.chooseMyCompleted();
                                        } else if (tab === 'all_completed') {
                                            this.chooseAllCompleted();
                                        } else if (tab === 'voided') {
                                            this.chooseVoided();
                                        }
                                    }}
                                    level={this.state.level}
                                    historyPush={this.historyPush}
                                    prefill_property_id={this.state.prefill_property_id}
                                    reset_prefill_property_id={() => {
                                        this.setState({
                                            prefill_property_id: null
                                        });
                                    }}
                                    booga={true}
                                />}

                                {this.state.askBeforeDelete && <NotificationAsk 
                                    open={this.state.askBeforeDelete}
                                    close={() => {
                                        this.setState({askBeforeDelete:false});
                                    }}
                                    success={() => {
                                        if (this.state.deleteData) {
                                            this.setState({shouldReload:true});
                                            this.props.deleteRsheet({
                                                id: this.state.deleteData.id
                                            });
                                        }
                                        this.setState({askBeforeDelete:false});
                                    }}
                                    title="Are You Sure?"
                                    message="Do you want to delete this routing sheet ?"
                                />}

                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.rs.error,
        success: state.rs.success,
        success_crud: state.rs.success_crud,
        loading: state.rs.loading,
        loadingEntries: state.rs.loadingEntries,
        rsTypes: state.rs.rsTypes,
        rsheetsData: state.rs.rsheetsData,
        rsheetRevision: state.rs.rsheetRevision
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getRoutingSheetType: () => dispatch(actions.getRsTypes()),
        getRsheets: (data) => dispatch(actions.getRsheets(data)),
        getRsheet: (id) => dispatch(actions.getRsheet(id)),
        updateRsheet: (params) => dispatch(actions.updateRsheet(params)),
        createRsheetRevision: (data) => dispatch(actions.createRsheetRevision(data)),
        deleteRsheet: (data) => dispatch(actions.deleteRsheet(data)),
        createRsHistory: (data) => dispatch(actions.createRsHistory(data)),
        getRsInternalReviewers: (data) => {
            dispatch(actions.getRsInternalReviewers(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RSheetsPage);
