import React, { useEffect, useState } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { clone } from 'shared/utility';
import Button from "components/CustomButtons/Button.jsx";
import { IconButton } from '@material-ui/core';
import CustomDialogWithProperty from '../../../components/CustomDialog/CustomDialogWithProperty';
import ContactsProperty from './ContactsProperty';
import { Dialog } from '@material-ui/core';
import { FadeLoader } from 'react-spinners';
import { getUser } from '../../../shared/authValidation';
import crmObj from '../crmObj';
import axios from 'store/axios/axios-crm';
import { handleError } from '../../../shared/utility';

const ContactsProperties = (props) => {
    const loggedInUser = getUser();
    const [data, setData] = useState({
        property_contacts: [],
        leases: [],
        listings: [],
        meetings: []
    });
    const [propertyData, setPropertyData] = useState(null);
    const [openedForm, setOpenedForm] = useState(false);
    const [addProperty, setAddProperty] = useState(false);
    const [deleteProperty, setDeleteProperty] = useState(false);
    const [updateProperty, setUpdateProperty] = useState(false);
    const [openedAskBeforeDelete, setOpenedAskBeforeDelete] = useState(false);
    const [currentDelete, setCurrentDelete] = useState(null);
    const [properties, setProperties] = useState([]);

    const { propertyContacts, canEdit, propertiesForAdd, setPropertiesForAdd, dataObj, updateDataObj } = props;

    const getPropertyToContact = async (data) => {
        try {
            const response = await axios.get(
                '/property_to_contact?id=' + data
            );
            setProperties(response.data);
        } catch (e) {
            handleError(e);
            //alert('Could not load properties');
        }
    }

    const addPropertyToContact = async (data) => {
        try {
            const newData = clone(data);
            if (newData.hasOwnProperty('from_property')) delete newData.from_property;
            await axios.post('/property_to_contact', newData);
            getPropertyToContact(props.contactData.id);
        } catch (e) {
            handleError(e);
            alert('Could not add property to contact');
        }
    }

    const removePropertyToContact = async (data) => {
        try {
            await axios.delete(
                '/property_to_contact/?id=' + data
            );
            getPropertyToContact(props.contactData.id);
        } catch (e) {
            handleError(e);
            alert('Could not remove property for contact');
        }
    }

    useEffect(() => {
        if ((props.action === 'Edit' || props.action === 'View') && props.contactData) {
            if (props.contactData.id) {
                getPropertyToContact(props.contactData.id);
            }
        }
    }, [])

    useEffect(() => {
        if (propertyContacts) {
            setData(clone(propertyContacts));
        }
    }, [propertyContacts]);

    const choose = (chosen, typeObj) => {
        if (props.action === 'Edit') {
            setUpdateProperty(true);
            const newObj = {
                contact_id: props.contactData ? props.contactData.id : null,
                property_id: chosen.id
            };
            if (typeObj) {
                if (typeObj.acronymType) newObj.contact_type = typeObj.acronymType;
                if (typeObj.subType) newObj.subtype = typeObj.subType;
                if (typeObj.theType) {
                    const currentContactType = props.contactData.contact_type;
                    if (crmObj.contactOrCompanyPropertySubTypes[typeObj.theType]) {
                        const shouldExistContactType = crmObj.contactOrCompanyPropertySubTypes[typeObj.theType].contactType;
                        if (shouldExistContactType) {
                            let arr = currentContactType ? currentContactType.split(',') : [];
                            if (arr.indexOf(shouldExistContactType) === -1) {
                                arr.push(shouldExistContactType)
                                const new_contact_type = arr.join(',');
                                const newContactData = clone(props.contactData);
                                newContactData.contact_type = new_contact_type;
                                if (shouldExistContactType === 'Other') {
                                    if (typeObj.subType) {
                                        newContactData.contact_type_other = newContactData.contact_type_other ? 
                                                (newContactData.contact_type_other + ',' + typeObj.subType)
                                            :
                                                typeObj.subType
                                    }
                                }
                                // update current frontend form
                                props.setDataObj(newContactData);

                                if (props.contactData.id) {
                                    // update backend contact data
                                    props.updateContact({
                                        id: props.contactData.id,
                                        contact_type: new_contact_type
                                    });
                                }
                            }
                        }
                    }
                }
            }
            addPropertyToContact(newObj);
            setAddProperty(false);
        } else if (props.action === 'Add') {
            const newObj = {
                contact_id: null,
                property_id: chosen.id,
                property_data: chosen
            };
            if (typeObj) {
                if (typeObj.acronymType) newObj.contact_type = typeObj.acronymType;
                if (typeObj.subType) newObj.subtype = typeObj.subType;
                if (typeObj.theType) {
                    const currentContactType = dataObj.contact_type ? dataObj.contact_type : '';
                    if (crmObj.contactOrCompanyPropertySubTypes[typeObj.theType]) {
                        const shouldExistContactType = crmObj.contactOrCompanyPropertySubTypes[typeObj.theType].contactType;
                        if (shouldExistContactType) {
                            let arr = currentContactType ? currentContactType.split(',') : [];
                            if (arr.indexOf(shouldExistContactType) === -1) {
                                arr.push(shouldExistContactType)
                                const new_contact_type = arr.join(',');
                                updateDataObj('contact_type', new_contact_type);
                            }
                        }
                    }
                }
            }
            setPropertiesForAdd(propertiesForAdd.concat([newObj]));
        }
    } 

    const loadProperty = async (property_id, property_data) => {
        setPropertyData(null);
        try {
            let url = "/property?id=" + property_id;
            const response = await axios.get(url);
            setOpenedForm(true);
            console.log('loaded property', response.data, property_data);
            setPropertyData(response.data);
        } catch (err) {
            handleError(err);
            alert('Could not load property');
        }
    }

    console.log('ContactsProperties', props);

    return (
        <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
                <h3 className="red-text">Properties :</h3>
                {(props.loading && props.action !== 'Add') && <div className="fade-loader-center">
                        <FadeLoader />
                </div>}
                {(!props.loading && props.action !== 'Add') && <div>
                    {properties.length === 1 && <h4>1 Property:</h4>}
                    {properties.length !== 1 && <h4>{properties.length} Properties:</h4>}
                    {properties.map((pm,i) => {
                        console.log('propm', pm)
                        let the_contact_type = pm.contact_type ? crmObj.typeDefinitions[pm.contact_type] : '';
                        let the_subtype = pm.subtype ? ` - ${pm.subtype}`: ``;
                        // Label Property Manager differently
                        if (pm.subtype === 'Property Manager') {
                            the_contact_type = 'Property Manager';
                            the_subtype = '';
                        }
                        return <div key={`${i}-property-cont`} style={{fontSize:16}} className="boxed">
                            <span className="hoverable bold-on-hover" onClick={() => {
                                if (pm.property_data.id) loadProperty(pm.property_data.id, pm.property_data);
                            }}>
                                <strong>#{i+1} - {pm.property_data ? pm.property_data.name : ''} ({the_contact_type} {the_subtype})</strong><br />{pm.property_data ? pm.property_data.street1 : ''}, {pm.property_data ? pm.property_data.city : ''} {pm.property_data ? pm.property_data.state : ''}
                            </span>
                            {props.action !== 'View' && <div>
                                {canEdit && <IconButton
                                    onClick={() => {
                                        setOpenedAskBeforeDelete(true);
                                        setCurrentDelete(pm);
                                    }}
                                >
                                    <i className="fas fa-trash-alt red-text"></i>
                                </IconButton>}
                            </div>}
                        </div> 
                    })}
                </div>} 

                {props.action === 'Add' && <div>
                    {propertiesForAdd.length === 1 && <h4>1 Property:</h4>}
                    {propertiesForAdd.length !== 1 && <h4>{propertiesForAdd.length} Properties:</h4>}
                    {propertiesForAdd.map((pm,i) => {
                        console.log('propm', pm)
                        let the_contact_type = pm.contact_type ? crmObj.typeDefinitions[pm.contact_type] : '';
                        let the_subtype = pm.subtype ? ` - ${pm.subtype}`: ``;
                        // Label Property Manager differently
                        if (pm.subtype === 'Property Manager') {
                            the_contact_type = 'Property Manager';
                            the_subtype = '';
                        }
                        return <div key={`${i}-property-cont`} style={{fontSize:16}} className="boxed">
                            <span className="hoverable bold-on-hover" onClick={() => {
                                setOpenedForm(true);
                                setPropertyData(pm.property_data);
                            }}>
                                <strong>#{i+1} - {pm.property_data ? pm.property_data.name : ''} ({the_contact_type} {the_subtype})</strong><br />{pm.property_data ? pm.property_data.street1 : ''}, {pm.property_data ? pm.property_data.city : ''} {pm.property_data ? pm.property_data.state : ''}
                            </span>
                            {props.action !== 'View' && <div>
                                {canEdit && <IconButton
                                    onClick={() => {
                                        setPropertiesForAdd(propertiesForAdd.filter((pp,ii) => {
                                            return ii !== i;
                                        }))
                                    }}
                                >
                                    <i className="fas fa-trash-alt red-text"></i>
                                </IconButton>}
                            </div>}
                        </div> 
                    })}
                </div>}
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                {props.action !== 'View' && <div>
                    {(canEdit && props.action !== 'Add') && <Button color="primary" onClick={() => {
                        props.getPropertyNames();
                        setAddProperty(true);
                    }}><i className="fas fa-plus"></i> Add Property</Button>}

                    {props.action === 'Add' && <Button color="primary" onClick={() => {
                        props.getPropertyNames();
                        setAddProperty(true);
                    }}><i className="fas fa-plus"></i> Add Property</Button>}
                </div>}
            </GridItem>

            {openedAskBeforeDelete && <Dialog 
                    open={openedAskBeforeDelete}
                    onClose={() => {
                        setOpenedAskBeforeDelete(false);
                    }}
                >
                    <div className="dialog-wrapper text-center">
                        <h3>Are you sure you want to remove this property from this contact ?</h3>
                        <Button color="primary" onClick={() => { 
                            if (currentDelete) {
                                if (currentDelete.id) {
                                    setDeleteProperty(true);
                                    removePropertyToContact(currentDelete.id, 'contact', props.contactData.id);
                                }
                            }
                            setOpenedAskBeforeDelete(false) }}>YES, REMOVE</Button>
                        <Button color="white" onClick={() => { setOpenedAskBeforeDelete(false) }}>CANCEL</Button>
                    </div>
                </Dialog>}

            {(openedForm && propertyData) && <ContactsProperty 
                open={openedForm}
                close={() => {
                    setOpenedForm(false);
                }}
                thePropertyData={propertyData}
                theContactData={props.contactData}
            />}

            {addProperty && <CustomDialogWithProperty 
                options={props.propertyNames ? 
                    props.propertyNames.propertiesData ?
                        props.propertyNames.propertiesData
                    : 
                        []
                : 
                    []}
                indx="id"
                desc="name"
                label="Add Property"
                title="Add Property"
                propertyType={addProperty}
                open={addProperty ? true : false}
                close={() => {
                    setAddProperty(false);
                }}
                askForType={true}
                crmType="Contact"
                choose={choose}
                create_and_choose={choose}
                theContactData={props.contactData}
                loggedInUser={loggedInUser}
            />}
        </GridContainer>
    )
}

const mapStateToProps = state => {
    const p = state.crm_properties;
    const c = state.crm_contacts;
    return {
        propertyNames: p.propertyNames,
        propertyContacts: p.propertyContacts,
        success: c.success,
        error: c.error,
        loading: c.loading
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        getPropertyNames: () => {
            dispatch(actions.getPropertyNames());
        }
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(ContactsProperties);

