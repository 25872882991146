import React, { useState } from 'react';
import GridItem from 'components/Grid/GridItem';
import Button from '../../../components/CustomButtons/Button';
import CustomDialogChooser from "components/CustomDialog/CustomDialogChooser";
import { clone } from '../../../shared/utility';

const LeaseCompany = (props) => {
    const { renderElement, data, setData } = props;
    const [openedChooser, setOpenedChooser] = useState(false);

    const choose = (chosen, a, dba) => {
        console.log('chosen', chosen, a, dba);
        // Contact
        if (chosen.first_name) {
            const newData = clone(data);
            newData.business_contact_id = chosen.id;
            newData.business_name = `${chosen.first_name ? chosen.first_name : ''} ${chosen.last_name ? chosen.last_name : ''}`;
            newData.type_of_business = chosen.use_type || '';
            if (dba) {
                if (dba.name) newData.business_name = `${newData.business_name} dba ${dba.name}`;
            }
            setData(newData);
            setOpenedChooser(false);
            return;
        }

        // Company
        if (chosen.company_data) {
            if (chosen.company_data.name) {
                const newData = clone(data);
                newData.business_company_id = chosen.company_data.id;
                newData.business_name = chosen.company_data.name;
                newData.type_of_business = chosen.company_data.use_type || '';
                if (chosen.dba_id) {
                    let dba_chosen = null;
                    if (chosen.dba_data) {
                        chosen.dba_data.forEach(dbd => {
                            if (dbd.id === chosen.dba_id) {
                                dba_chosen = dbd;
                            }
                        })
                    }
                    if (dba_chosen) newData.business_name = `${newData.business_name} dba ${dba_chosen.name}`;
                }
                setData(newData);
            }
            setOpenedChooser(false);
            return;
        }
    }

    return <>
            <GridItem xs={12} sm={12} md={12}>
                <Button
                    color="primary"
                    onClick={() => setOpenedChooser(true)}
                >
                    SELECT COMPANY/CONTACT
                </Button>
            </GridItem>

            <GridItem xs={12} sm={6} md={6}>
                <div className="gray-bg no-click">
                    {renderElement("business_name")}
                </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                <div className="gray-bg no-click">
                    {renderElement("type_of_business")}
                </div>
            </GridItem>

            {openedChooser && (
                <CustomDialogChooser
                    open={openedChooser}
                    close={() => {
                        setOpenedChooser(false);
                    }}
                    choose={choose}
                    create_and_choose={choose}
                    title="Select Contact or Company"
                    label="Search Contacts/Companies"
                    // showContactCompaniesTab={true}
                    no_property={true}
                    allowNoContacts={true}
                />
            )}
    </>
}

export default LeaseCompany;