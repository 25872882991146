import React, { useEffect, useState } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { connect } from 'react-redux';
import Button from "components/CustomButtons/Button.jsx";
import { IconButton } from '@material-ui/core';
import CustomDialogWithProperty from '../../../components/CustomDialog/CustomDialogWithProperty';
import Notification from 'components/Notification/Notification';
import NotificationAsk from 'components/Notification/NotificationAsk';
import { FadeLoader } from 'react-spinners';
import { getUser } from '../../../shared/authValidation';
import crmObj from '../crmObj';
import { clone, handleError } from '../../../shared/utility';
import { createAddressObject } from '../Properties/PropertiesLogic';
import axios from 'store/axios/axios-crm';

const CompaniesProperties = (props) => {
    const loggedInUser = getUser();
    const [properties, setProperties] = useState([]);
    const [message, setMessage] = useState('');
    const [notificationAsk, setNotificationAsk] = useState(false);
    const [dataToDelete, setDataToDelete] = useState(null);
    const [addProperty, setAddProperty] = useState(false);
    const [companyPropertiesData, setCompanyPropertiesData] = useState([]);

    const { canEdit, canDelete } = props;

    const getCompanyProperties = async (data) => {
        try {
            let params = '';
            if (data.company_id) {
                params = `?id=${data.company_id}`;
            } else if (data.property_id) {
                params = `?property_id=${data.property_id}`;
            }
            const response = await axios.get('/company_properties' + params);
            console.log('response company properties 1', response.data);
            const company_properties = response.data;
            let cc = [];
            for (let i = 0; i < company_properties.length; i++) {
                const c = company_properties[i];
                // get company info
                const company_response = await axios.get('/company?id=' + c.company_id);
                c.company_data = company_response.data;
                console.log('response company properties 2', company_response.data);
                // get property info
                try {
                    const property_response = await axios.get('/property?id=' + c.property_id);
                    c.property_data = property_response.data;
                    console.log('response company properties 3', property_response.data);
                } catch(e) {
                    handleError(e);
                }
                if (c.property_data) cc.push(c);
            }
            setProperties(cc);
        } catch (e) {
            handleError(e);
            //alert('Couldnt get company properties');
        }
    }

    const createCompanyProperties = async (data) => {
        try {
            const newData = clone(data);
            if (newData.hasOwnProperty('is_company')) delete newData.is_company;
            await axios.post('/company_properties', newData);
            getCompanyProperties(data);
        } catch (e) {
            handleError(e);
            alert('Couldnt create company properties');
        }
    }

    const deleteCompanyProperties = async (data) => {
        try {
            await axios.delete('/company_properties?id=' + data.id);
            getCompanyProperties(data);
        } catch (e) {
            handleError(e);
            alert('Could not delete company property');
        }
    }

    const choose = (chosen, typeObj) => {
        console.log('choosing prop', typeObj);
        const newObj = {
            company_id: props.dataObj ? props.dataObj.id : null,
            property_id: chosen.id,
            is_company: true
        };
        if (typeObj) {
            if (typeObj.acronymType) newObj.company_type = typeObj.acronymType;
            if (typeObj.subType) newObj.subtype = typeObj.subType;
            if (typeObj.theType) {
                const currentContactType = props.dataObj.contact_type;
                console.log('choosing prop - ', crmObj.contactOrCompanyPropertySubTypes[typeObj.theType]);
                if (crmObj.contactOrCompanyPropertySubTypes[typeObj.theType]) {
                    const shouldExistContactType = crmObj.contactOrCompanyPropertySubTypes[typeObj.theType].contactType;
                    if (shouldExistContactType) {
                        let arr = currentContactType ? currentContactType.split(',') : [];
                        if (arr.indexOf(shouldExistContactType) === -1) {
                            arr.push(shouldExistContactType)
                            const new_contact_type = arr.join(',');
                            const newDataObj = clone(props.dataObj);
                            newDataObj.contact_type = new_contact_type;
                            if (shouldExistContactType === 'Other') {
                                if (typeObj.subType) {
                                    newDataObj.contact_type_other = newDataObj.contact_type_other ? 
                                            (newDataObj.contact_type_other + ',' + typeObj.subType)
                                        :
                                            typeObj.subType
                                }
                            }
                            // update current frontend form
                            props.setDataObj(newDataObj);
                            
                            if (props.dataObj.id) {
                                // update backend contact data
                                // props.updateCompany({
                                //     id: props.dataObj.id,
                                //     contact_type: new_contact_type
                                // });
                            }
                        }
                    }
                }
            }
        }
        // open property 
        createCompanyProperties(newObj)
        setAddProperty(false);
    };

    useEffect(() => {
        
        if ((props.action === 'Edit' || props.action === 'View') && props.dataObj) {
            if (props.dataObj.id) {
                getCompanyProperties({
                    company_id: props.dataObj.id,
                    is_company: true
                });
            }
        }
    }, [])

    return (
        <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
                <h3 className="red-text">Properties :</h3>
                {props.loading && <div className="fade-loader-center">
                    <FadeLoader />
                </div>}
                {!props.loading && <div>
                    <h4>{properties.length} {properties.length === 1 ? 'Property' : 'Properties'}:</h4>
                    {properties.map((cd, i) => {
                        console.log('cd', cd)
                        const cc = cd.property_data;
                        let the_contact_type = cd.company_type ? crmObj.typeDefinitions[cd.company_type] : '';
                        let the_subtype = cd.subtype ? ` - ${cd.subtype}`: ``;
                        // Label Property Manager differently
                        if (the_subtype.indexOf('Property Manager') !== -1) {
                            the_contact_type = 'Property Manager';
                            the_subtype = '';
                        }
                        const property = createAddressObject(cc);
                        return <div key={`${i}-contact-cont`} style={{ fontSize: 16 }} className="boxed">
                            <strong>Project Name</strong>: {cc.name}<br />
                            <strong>Contact Type</strong>: {the_contact_type} {the_subtype}<br />
                            <strong>Street 1:</strong> {property.street1}<br />
                            {property.street2 && <div><strong>Street 2:</strong> {property.street2}</div>}
                            <strong>City:</strong> {cc.city}<br />
                            <strong>Zip:</strong> {cc.zip}<br />
                            {canDelete && <IconButton
                                    style={{textAlign:'right'}}
                                    onClick={() => {
                                        const data = {
                                            id: cd.id,
                                            property_id: cd.property_data.id,
                                            company_id: cd.company_data.id,
                                            is_company: true
                                        }
                                        setDataToDelete(data);
                                        setNotificationAsk('Are you sure you want to delete property association - ', cc.name);
                                    }}
                                >
                                    <i className="fas fa-trash-alt red-text"></i>
                                </IconButton>}
                        </div>
                })}
                </div>}
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                {(canEdit && props.action !== 'View') && <Button color="primary" onClick={() => {
                    setAddProperty(true);
                }}><i className="fas fa-plus"></i> Add Property</Button>}
            </GridItem>

            
            
            {message && <Notification 
                open={message ? true : false}
                close={() => { setMessage('') }}
                message={message}
            />}
            {notificationAsk && <NotificationAsk 
                open={notificationAsk ? true : false}
                close={() => { 
                    setNotificationAsk('');
                    setDataToDelete(null);
                }}
                success={() => {
                    deleteCompanyProperties(dataToDelete)
                    setNotificationAsk('');
                    setDataToDelete(null);
                }}
                title="Are you sure?"
                message={notificationAsk}
                success_button_text="Yes, Delete"
            />}

            {addProperty && <CustomDialogWithProperty 
                options={[]}
                indx="id"
                desc="name"
                label="Add Property"
                title="Add Property"
                propertyType={addProperty}
                open={addProperty ? true : false}
                close={() => {
                    setAddProperty(false);
                }}
                askForType={true}
                crmType="Company"
                choose={choose}
                create_and_choose={choose}
                loggedInUser={loggedInUser}
            />}
        </GridContainer>
    )
}

export default connect(null, null)(CompaniesProperties);

