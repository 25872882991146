import React from 'react';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Button from '../../../components/CustomButtons/Button';
import { clone, get } from 'lodash';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from "store/axios/axios-crm";
import { handleError } from '../../../shared/utility';
import LessEntry from '../CommonCustomElements/LessEntry';
import { chooseCRM } from '../RSheetsLogic';
import NotificationAsk from '../../../components/Notification/NotificationAsk';

const Step3 = (props) => {
	const {
		data,
		setData,
	} = props;

	const [companies, setCompanies] = useState([]);
	const [contacts, setContacts] = useState([]);
	const [askToAdd, setAskToAdd] = useState(null);
	const [loadedPropertyOwnersAlready, setLoadedPropertyOwnersAlready] = useState(false);

	const property_id = get(data, 'property_id', null);

	const getPropertyToContact = async (property_id) => {
		try {
			const response = await axios.get(
				'/property_to_contact?property_id=' + property_id
			);
			console.log('response', response.data);
			const property_contacts = response.data;
			try {
				for (let i = 0; i < property_contacts.length; i++) {
					const rdl = property_contacts[i];
					const cont = rdl.contact_data;
					const contact = await axios.get('/contact?id='+cont.id);
					console.log('contact response', contact.data);
					property_contacts[i].contact_data = contact.data;
				}
			} catch(e) {
				handleError(e);
			}
			setContacts(property_contacts.filter(r => {
				return r.contact_type === 'OWN'
			}));
		} catch (e) {
			handleError(e);
		}
	}

	const getCompanyProperties = async (property_id) => {
        try {
            const params = `?property_id=${property_id}`;
            const response = await axios.get('/company_properties' + params);
            const company_properties = response.data;
            let cc = [];
            for (let i = 0; i < company_properties.length; i++) {
                const c = company_properties[i];
                // get company info
                const company_response = await axios.get('/company?id=' + c.company_id);
                c.company_data = company_response.data;
                cc.push(c);
            }
            setCompanies(cc);
        } catch (e) {
            handleError(e);
        }
    }

	const getAllPropertyOwners = async () => {
		// on tab load and with property_id load all owner contacts
		if (property_id) {
			await getCompanyProperties(property_id);
			await getPropertyToContact(property_id);
			setLoadedPropertyOwnersAlready(true);
		}
	}

	useEffect(() => {
		getAllPropertyOwners();
	}, []);

	useEffect(() => {
		// Check if all data lessors are in property owners
		if (data.lessors && loadedPropertyOwnersAlready) {
			if (data.lessors.length > 0) {
				const propertyOwnersToAdd = getPropertyOwnersToAdd('compare');
				if (propertyOwnersToAdd.length > 0) {
					// if not, then popup and ask to add
					setAskToAdd(propertyOwnersToAdd);
				}
			}
		}
	}, [data.lessors]);

	console.log('LeaseAssignment Fee Property Owners', contacts, companies, askToAdd);

	const handleAddOwner = (owner) => {
		if (typeof owner.id === 'string') {
			// COMPANY
			const chosen = {} 
			const company = clone(owner);
			company.id = parseInt(owner.id.split('c')[1]);
			chosen.company_data = company;
			chooseCRM(chosen, null, null, { 
				fieldType: 'lessors', 
				extraFields: {
					"agency_disclosure": "",
					"can_we_reach_out_for_testimonial": "",
					"can_we_reach_out_for_testimonial_comment": ""
				},
				setData,
				data
			})
		} else {
			// CONTACT
			const chosen = owner;
			chooseCRM(chosen, null, null, { 
				fieldType: 'lessors', 
				extraFields: {
					"agency_disclosure": "",
					"can_we_reach_out_for_testimonial": "",
					"can_we_reach_out_for_testimonial_comment": ""
				},
				setData,
				data
			})
		}
	};

	const getPropertyOwnersToAdd = (compare) => {
		const contacts_arr = contacts.map(c => {
			return c.contact_data;
		})
		const companies_arr = companies.map(c => {
			const company_data = clone(c.company_data);
			company_data.id = `c${company_data.id}`;
			return company_data;
		})
		const combined = contacts_arr.concat(companies_arr);
		console.log('combined', combined);

		if (compare) {
			// looks for missing lessors from property owners list
			const propertyOwnersToAdd = data.lessors.filter(dl => {
				let found = false;
				combined.forEach(cc => {
					console.log('combined comparing', dl, cc);
					if (dl.id === cc.id) {

						console.log('combined found 1');
						found = true;
					}
					if (dl.company_id) {
						const c_id = `c${dl.company_id}`;
						if (c_id === cc.id) {
							console.log('combined found 2');
							found = true;
						}
					}
					if (dl.contact_id) {
						if (cc.id === dl.contact_id) {
							console.log('combined found 3');
							found = true;
						}
					}
				})
				console.log('combined result', found);
				if (found) return false;
				return true;
			})
			return propertyOwnersToAdd;
		}
		
		const propertyOwners = combined.filter(cc => {
			let found = false;
			if (data.lessors) {
				data.lessors.forEach(dl => {
					console.log('combined comparing', dl, cc);
					if (dl.id === cc.id) found = true;
					if (dl.company_id) {
						const c_id = `c${dl.company_id}`;
						if (c_id === cc.id) {
							found = true;
						}
					}
					if (dl.contact_id) {
						if (cc.id === dl.contact_id) found = true;
					}
				})
			}
			if (found) return false;
			return true;
		});
		return propertyOwners;
	}

	const renderOwners = () => {
		const propertyOwners = getPropertyOwnersToAdd();
		if (propertyOwners.length === 0) return <div><em>No Property Owner(s) to Add</em></div>
		return propertyOwners.map((owner,o) => {
			console.log('owner', owner)
			if (typeof owner.id === 'string') {
				// COMPANY
				return (
					<GridContainer key={`${o}-owner`}>
						<GridItem xs={6} sm={6} md={6}>
							<Button color='rose' onClick={() => handleAddOwner(owner)}>
								Add Company - {owner.name}
							</Button>
						</GridItem>
					</GridContainer>
				);
			} else {
				// CONTACT
				return (
					<GridContainer key={`${o}-owner`}>
						<GridItem xs={6} sm={6} md={6}>
							<Button color='rose' onClick={() => handleAddOwner(owner)}>
								Add Contact - {owner.first_name} {owner.last_name}
							</Button>
						</GridItem>
					</GridContainer>
				);
			}
		});
	};

	const addPropertyOwners = async (toAdd) => {
		for (let i = 0; i < toAdd.length; i++) {
			const add = toAdd[i];
			try {
				if (add.id) {
					if (typeof add.id === 'string') {
						// COMPANY
						const data = {
							property_id: property_id,
							company_id: add.id.split('c')[1],
							company_type: 'OWN'
						}
						await axios.post('/company_properties', data);
					} else {
						// CONTACT
						const data = {
							contact_type: 'OWN',
							contact_id: add.id,
							property_id
						}
						await axios.post('/property_to_contact', data);
					}
				} else {
					if (add.company_id) {
						// COMPANY
						const data = {
							property_id: property_id,
							company_id: add.company_id,
							company_type: 'OWN'
						}
						await axios.post('/company_properties', data);
					} else if (add.contact_id) {
						// CONTACT
						const data = {
							contact_type: 'OWN',
							contact_id: add.contact_id,
							property_id
						}
						await axios.post('/property_to_contact', data);
					}
				}
				
			} catch(e) {
				handleError(e);
			}
		}
		getAllPropertyOwners();
	}

	return (
		<GridContainer>
			{/* Lessor */}
			{!property_id && (
				<GridItem xs={12} sm={12} md={12}>
					<div className='red'>Add Property First</div>
				</GridItem>
			)}

			<GridItem xs={12} sm={12} md={12}>
				<div className='boxed'>
					<h4>Current Property Owner(s)</h4>
					{renderOwners()}
				</div>
			</GridItem>

			<LessEntry 
				data={data}
                setData={setData}
                rsType="Lease Assignment"
                fieldType="lessors"
                fieldName="Lessor"
				// noAddButton={true}
			/>

			{askToAdd && <NotificationAsk 
				open={askToAdd ? true : false}
				close={() => {
					setAskToAdd(null);
				}}
				title="Add as Property Owners"
				message={<div>
					Do you want to add the following as Property Owners?
					{askToAdd.map((ata,x) => {
						return <div key={`${x}-ata`}>
							{ata.company_name ? <div>{ata.company_name}</div> : null}
							{ata.name ? <div>{ata.name} {ata.lastname}</div> : null}
						</div>
					})}
				</div>}
				success={() => {
					addPropertyOwners(askToAdd);
					setAskToAdd(null);
				}}
			/>}
		</GridContainer>
	);
};

export default Step3;
