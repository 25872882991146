import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { clone, get } from "lodash";
import { connect } from "react-redux";
import * as actions from "store/actions";

import Button from "components/CustomButtons/Button";
import Notification from "../../../components/Notification/Notification";

import { defaultData as invoiceData } from "../../Invoices/InvoiceObj";
import RSheetsPDFPreview from "../RSheetsPDFPreview";

import { doAction, generateSections, renderHTML } from "./RSheetsNoListingLogic";
import { getRsheetLadderOptions, typeDescriptionsDict } from "../RSheetsLogic";
import RSheetsDenyModal from "../RSheetsDenyModal";
import InvoicePreview from "../CommonCustomElements/InvoicePreview";
import { INVOICE_STATUS } from "../../Invoices/InvoiceObj";
import { getUser } from "../../../shared/authValidation";
import InvoiceChoose from "../CommonCustomElements/InvoiceChoose";

const RSheetsLeaseFormActions = (props) => {
    const rsheetPerms = getRsheetLadderOptions();
    const levels = get(rsheetPerms, 'userPerms.approve', []);
    const max_approver_level = levels.length > 0 ? Math.max.apply(Math, levels) : null;

    const { invoiceID } = props;
    const [openedPDFPreview, setOpenedPDFPreview] = useState(false);
    const [loadedPDF, setLoadedPDF] = useState(false);
    const [notification, setNotification] = useState("");
    const [closeForm, setCloseForm] = useState(false);
    const [invoiceForm, setInvoiceForm] = useState(invoiceData);
    const [openedDenyModal, setOpenedDenyModal] = useState(false);
    const [invoicePreview, setInvoicePreview] = useState(null);
    const [openedChooseInvoice, setOpenedChooseInvoice] = useState(false);

    const handlePreviewInvoice = () => {
        const { data } = props;

        const newInvoiceForm = clone(invoiceForm);
        newInvoiceForm.rsData = data;
        newInvoiceForm.title = `Generated from RS illi# ${get(data, "illi_number", "")}`;
        if (data.title) {
            const extraTitle = ` - Rev ${data.title.split("_").length}`;
            newInvoiceForm.title = newInvoiceForm.title + extraTitle;
        }
        newInvoiceForm.invoice = "001";
        newInvoiceForm.illi = get(data, "illi_number", "");
        newInvoiceForm.rs_id = data.id;

        const invoiceData = {
            status: 1,
            data: newInvoiceForm,
        };
        setInvoicePreview(invoiceData);
    }

    const handleApproveOrSubmit = (invoiceData = null) => {
        const sections = generateSections(data, props.internalReviewers);
        let errors = [];
        sections.forEach((s) => {
            s.errors.forEach((e) => {
                errors.push(e);
            });
        });
        if (errors.length > 0) {
            const elem = (
                <div>
                    <h3>Required</h3>
                </div>
            );
            return setNotification(
                <div>
                    {elem}
                    {errors.map((e, x) => {
                        return <div key={`${x}-error`}>{e}</div>;
                    })}
                </div>
            );
        }
        setNotification("Sending...");

        if (!props.level || !isFinite(props.level)) {
            // SUBMITTER ONLY
            const recordHistory = {
                status: 2,
                json_data: data
            }
            if (!data.id) {
                if (props.setHasSubmitted) {
                    props.setHasSubmitted(true);
                }
                props.createRsheet(
                    {
                        json_data: JSON.stringify(data),
                        status: 2,
                        project_name: data.project_name,
                        type: 8,
                    },
                    invoiceData,
                    recordHistory
                );
            } else {
                if (props.setHasSubmitted) {
                    props.setHasSubmitted(true);
                }
                const updateObj = {
                    id: data.id,
                    json_data: JSON.stringify(data),
                    status: 2,
                    project_name: data.project_name,
                    notifyApprover: 1
                };
                recordHistory.rs_id = data.id;
                props.updateRsheet(updateObj, invoiceData, recordHistory);
                props.setFormDataObj({});
                setCloseForm(true);
            }
        } else {
            // APPROVER
            // --------
            // my tab = submitter is the same as approver and he is the highest level (4)
            let finalStatus = 2; // standard pending
            if (props.tab === "my_drafts") {
                if (props.level === 4) {
                    finalStatus = 9; // approve right away
                } else {
                    finalStatus = rsheetPerms.approveObj[props.level];
                }
            } else {
                finalStatus = rsheetPerms.approveObj[props.level];
            }
            const updateObj = {
                json_data: JSON.stringify(data),
                status: finalStatus,
                project_name: data.project_name,
                type: 8 // NO LISTING
            };
            if (finalStatus === 9) {
                updateObj.notifySubmitter = 'Your routing sheet has been approved';
            }
            if (props.level !== 4) {
                updateObj.notifyApprover = props.level + 1;
            }
            const recordHistory = {
                status: finalStatus,
                json_data: data
            }
            if (!data.id) {
                props.createRsheet(updateObj, invoiceData, recordHistory);
            } else {
                updateObj.id = data.id;
                recordHistory.rs_id = data.id;
                if (finalStatus === 9) {
                    // create PDF
                    const pdfObj = {
                        htmlContent: renderHTML(data, props.rsheetData)
                    };
                    props.updateRsheet(updateObj, invoiceData, recordHistory, pdfObj);
                } else {
                    props.updateRsheet(updateObj, invoiceData, recordHistory);
                }
            }
            setCloseForm(true);
            props.setFormDataObj({});
        }
    };

    const handleGenerateInvoice = () => {
        const { data } = props;

        let invoiceData = [];

        if (data.payment_schedule) {
            if (data.payment_schedule.length > 0) {
                for (let i = 0; i < data.payment_schedule.length; i++) {
                    const payment_schedule_data = data.payment_schedule[i];
                    const newInvoiceForm = clone(invoiceForm);
                    newInvoiceForm.title = `Generated from RS illi# ${get(data, "illi_number", "")}`;
                    if (data.title) {
                        const extraTitle = ` - Rev ${data.title.split("_").length}`;
                        newInvoiceForm.title = newInvoiceForm.title + extraTitle;
                    }
                    newInvoiceForm.illi = get(data, "illi_number", "");
                    newInvoiceForm.rs_id = data.id;
                    newInvoiceForm.payment_schedule_data = {
                        data: payment_schedule_data,
                        index: i
                    };

                    const obj = {
                        invoiceData: {
                            status: max_approver_level === 4 ? 
                                INVOICE_STATUS.APPROVED
                            :
                                INVOICE_STATUS.PENDING
                            ,
                            data: newInvoiceForm,
                        }
                    }
                    invoiceData.push(obj);
                }
            }
        }
        handleApproveOrSubmit(invoiceData.length > 0 ? invoiceData : null);
    };

    const renderRejectDescription = (data) => {
        if (!data) return null;
        if (data.status === 2) {
            return <div>Deny to Submitter</div>;
        } else if (data.status === 3) {
            return <div>Deny to Level 1</div>;
        } else if (data.status === 4) {
            return <div>Deny to Level 2</div>;
        } else if (data.status === 10) {
            return <div>Deny to Level 3</div>;
        }
    };

    const renderApproveDescription = (data) => {
        const approveLevel = rsheetPerms.approveObj[props.level];
        if (!data) return null;
        if (approveLevel === 2) {
            return <div>Approve to Level 2</div>;
        } else if (approveLevel === 3) {
            return <div>Approve to Level 3</div>;
        } else if (approveLevel === 4) {
            return <div>Approve to Final Approver</div>;
        } else if (approveLevel === 10) {
            return null;
        }
    };

    const renderDescription = (data) => {
        if (!data) return null;
        if (data.status === 2) {
            return <div>(Currently Pending Level 1 Approval)</div>;
        } else if (data.status === 3) {
            return <div>(Currently Pending Level 2 Approval)</div>;
        } else if (data.status === 4) {
            return <div>(Currently Pending Level 3 Approval)</div>;
        } else if (data.status === 10) {
            return <div>(Currently Pending Final Approval)</div>;
        }
    };

    useEffect(() => {
        if (props.success) {
            if (props.success.indexOf("Created Sheet") !== -1) {
                props.setOpenedForm(false);
                doAction(props.tab, props.getRsheets);
            } else if (props.success === "Updated Sheet") {
                if (props.level) {
                    if (props.getAdminData) props.getAdminData();
                } else {
                    doAction(props.tab, props.getRsheets);
                }
                if (props.hasSubmitted) {
                    setNotification("Submitted Routing Sheet");
                    props.setHasSubmitted(false);
                }
                if (props.setActivityTracker) props.setActivityTracker(false);
                if (closeForm) {
                    // props.setOpenedForm(false);
                    props.closeTheForm();
                    setCloseForm(false);
                }
            }
        }
    }, [props.success]);

    useEffect(() => {
        if (props.pdfBuffer && loadedPDF) {
            setOpenedPDFPreview(true);
            setLoadedPDF(false);
        }
    }, [props.pdfBuffer]);

    const { data } = props;
    const data_id = data.id;

    const submitText = max_approver_level === 4 ? "APPROVE" : ("SUBMIT TO LEVEL " + (max_approver_level + 1));
    
    const usery = getUser();
    console.log('usery', usery);
    let canPreviewInvoice = false;
    if (usery.permObj) {
        if (usery.permObj) {
            const perms = Object.keys(usery.permObj);
            if (perms.indexOf('Invoice edit') !== -1) {
                canPreviewInvoice = true;
                console.log('usery', canPreviewInvoice);
            }
        }
    }

    return (
        <div>
            {/* {renderDescription(data)}
            {renderApproveDescription(data)}
            {renderRejectDescription(data)} */}
            {
                <div>
                    <TextField
                        style={{ width: "100%" }}
                        id="multiline-flexible-notes"
                        label="Internal Notes"
                        multiline
                        rowsMax="4"
                        value={data.notes}
                        onChange={(e) => {
                            props.updateDataObj("notes", e.target.value);
                        }}
                        margin="normal"
                    />
                    <hr />
                </div>
            }
            <Button
                color="primary"
                className="mr-20"
                onClick={() => {
                    setLoadedPDF(true);
                    props.createRsheetPdf({
                        htmlContent: renderHTML(data, props.rsheetData),
                    });
                }}
            >
                PREVIEW PDF
            </Button>
            {!props.viewOnly && (
                <Button
                    color="white"
                    className="mr-20"
                    onClick={() => {
                        if (!data.project_address || !data.project_name) {
                            return setNotification('Please select a property with address and project name to begin saving.')
                        }
                        props.save();
                    }}
                >
                    SAVE
                </Button>
            )}

            {!props.viewOnly && (
                <Button
                    color="success"
                    className="mr-20"
                    onClick={() => {
                        handleApproveOrSubmit();
                    }}
                >
                    {submitText}
                </Button>
            )}

            {!props.viewOnly && max_approver_level > 2 && (
                <Button
                    className="mr-20"
                    color="primary"
                    onClick={() => {
                        handleGenerateInvoice();
                    }}
                >
                    {submitText} &amp; GENERATE INVOICE
                </Button>
            )}

            {(!props.viewOnly && max_approver_level && data_id) &&
                props.tab !== "my_drafts" && 
                props.tab !== "user_drafts" && (
                <Button
                    color="white"
                    className="mr-20"
                    onClick={() => {
                        // APPROVER REJECTION
                        setOpenedDenyModal(true)
                    }}
                >
                    DENY
                </Button>
            )}

            {canPreviewInvoice && <Button
                className="mr-20"
                color="white"
                onClick={() => {
                    //handlePreviewInvoice();
                    setOpenedChooseInvoice(true);
                }}
            >
                PREVIEW INVOICE
            </Button>}

            {openedChooseInvoice && <InvoiceChoose 
                data={data}
                close={() => {
                    setOpenedChooseInvoice(false);
                }}
                rsType={typeDescriptionsDict['No Listing']}
            />}

            {openedPDFPreview && (
                <RSheetsPDFPreview
                    close={() => {
                        setOpenedPDFPreview(false);
                    }}
                    open={openedPDFPreview}
                    pdfData={props.pdfBuffer ? props.pdfBuffer : null}
                />
            )}

            {notification && (
                <Notification
                    open={notification ? true : false}
                    close={() => {
                        if (notification === "Submitted Routing Sheet") {
                            props.closeTheForm();
                        }
                        setNotification("");
                    }}
                    message={notification}
                />
            )}

            {openedDenyModal && <RSheetsDenyModal 
                data={data}
                open={openedDenyModal}
                close={() => { setOpenedDenyModal(false) }}
                choose={(status_to_change_to) => {
                    // APPROVER REJECTION
                    setCloseForm(true);
                    const recordHistory = {
                        status: status_to_change_to,
                        json_data: data,
                        rs_id: data.id
                    }
                    props.updateRsheet({
                        id: data.id,
                        json_data: JSON.stringify(data),
                        formDataObj: props.formDataObj,
                        status: status_to_change_to,
                        project_name: data.project_name,
                        type: 8, // No Listing
                    }, null, recordHistory);
                    props.setFormDataObj({});
                }}
            />}

            {invoicePreview && <InvoicePreview 
                rsType={8}
                data={invoicePreview}
                close={() => {
                    setInvoicePreview(null);
                }}
            />}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        error: state.rs.error,
        success: state.rs.success,
        rsheetData: state.rs.rsheetData,
        pdfBuffer: state.rs.pdfBuffer,
        invoiceID: state.invoice.invoiceID,
        internalReviewers: state.rs.internal_reviewers_choices,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createRsheet: (data, invoiceData = null, recordHistory) => {
            dispatch(actions.createRsheet(data, invoiceData, recordHistory));
        },
        getRsheets: (params) => {
            dispatch(actions.getRsheets(params));
        },
        updateRsheet: (params, invoiceData = null, recordHistory, pdfObj = null) => {
            dispatch(actions.updateRsheet(params, invoiceData, recordHistory, pdfObj));
        },
        createRsheetPdf: (params) => {
            dispatch(actions.createRsheetPdf(params));
        },
        createRsheetFiles: (params) => {
            dispatch(actions.createRsheetFiles(params));
        },
        createInvoiceEntry: (data) => {
            dispatch(actions.createInvoiceEntry(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RSheetsLeaseFormActions);
