const showingFields = {
    start_date: {
        type: 'date picker',
        label: 'Start Date',
        validations: ['required']
    },
    start_time: {
        type: 'time picker',
        label: 'Start Time',
        validations: ['required']
    },
    end_date: {
        type: 'date picker',
        label: 'End Date',
        validations: ['required']
    },
    end_time: {
        type: 'time picker',
        label: 'End Time',
        validations: ['required']
    },
    notes: {
        type: 'textfield',
        label: 'Notes',
        validations: ['min1','max500']
    },
    guests: {
        type: 'multi',
        label: 'Showing Guests',
        validations: []
    },
}

export default showingFields;

const initialData = {
    start_date: '',
    start_time: '09:00 AM',
    end_date: '',
    end_time: '10:00 AM',
    guests: [],
    notes: '',
    other_data: {
        files: []
    }
};

export const initialShowingData = initialData;