import React from "react";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";
import { checkOutsideBrokerOrReferralFirmFields } from "../RSheetsLogic";
import { useCallback } from 'react';
import { clone } from "../../../shared/utility";
import Brokers from "../CommonCustomElements/Brokers";
import LessEntry from "../CommonCustomElements/LessEntry";
import LessContacts from "../CommonCustomElements/LessContacts";
const ASSIGNOR_OUTSIDE_BROKER_FIELDS = [
    "assignor_outside_broker_name",
    "assignor_outside_broker_lastname",
    "assignor_outside_broker_address1",
    "assignor_outside_broker_city",
    "assignor_outside_broker_state",
    "assignor_outside_broker_zip",
    "assignor_outside_broker_contact",
    "assignor_outside_broker_fax",
    "assignor_outside_broker_main_phone",
    "assignor_outside_broker_email",
    "assignor_outside_broker_taxid",
    "assignor_outside_broker_w9",
    "assignor_outside_broker_w9_upload",
    "assignor_outside_broker_commission",
];

const ASSIGNOR_OUTSIDE_BROKE_NON_REQUIRED_FIELDS = ["lessor_outside_broker_fax","assignor_outside_broker_taxid"];

const Step5 = (props) => {
    const { setQueryFor, getContacts, setOpenedContactModal, renderElement, data, setData } = props;

    const areOutsideBrokerFieldsRequired = checkOutsideBrokerOrReferralFirmFields(
        data,
        ASSIGNOR_OUTSIDE_BROKER_FIELDS,
        ASSIGNOR_OUTSIDE_BROKE_NON_REQUIRED_FIELDS
    );

    const renderOutsideBrokerFields = useCallback(() => {
		if (!data.assignor_outside_broker_id) {
            return null;
        }
        return ASSIGNOR_OUTSIDE_BROKER_FIELDS.map((lfield) => {
            const extraValidations =
                !ASSIGNOR_OUTSIDE_BROKE_NON_REQUIRED_FIELDS.includes(lfield) && areOutsideBrokerFieldsRequired
                    ? ["required"]
                    : [];

            if (lfield === "assignor_outside_broker_w9_upload") {
                if (data.outside_broker_w9 === "Yes") {
                    return (
                        <GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}>
                            {renderElement(lfield)}
                        </GridItem>
                    );
                } else {
                    return null;
                }
            }
            let labelOverride = null;
            if (data.assignor_outside_broker_id) {
                // is a company
                if (data.assignor_outside_broker_id.toString().indexOf("c") !== -1) {
                    if (lfield === "assignor_outside_broker_lastname") return null;
                    if (lfield === "assignor_outside_broker_name") {
                        labelOverride = "Company Name";
                    }
                }
            }
            return (
                <GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}>
                    {renderElement(lfield, null, null, null, labelOverride, extraValidations)}
                </GridItem>
            );
        });
    }, [data]);

    return (
        <GridContainer>
            {/* Lessor */}
            <GridItem xs={12} sm={12} md={12}>
                <LessEntry 
                    data={data}
                    setData={setData}
                    rsType="Lease Assignment"
                    fieldType="assignors"
                    fieldName="Assignor"
                />
                {data.assignors.length === 0 && <div className="red-text" style={{margin:20}}>Assignor Required</div>}
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
                <hr />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <LessContacts 
                    data={data}
                    setData={setData}
                    fieldType="assignor_contacts"
                    fieldName="Assignor Contact"
                />
                <hr />
            </GridItem>

            <Brokers 
				data={data}
				setData={setData}
				outside_brokers_field_name="lessor_outside_broker" 
				outside_brokers_field_label="ASSIGNOR"
			/>

        </GridContainer>
    );
};

export default Step5;
