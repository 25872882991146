import React from 'react';
import { useEffect, useCallback } from 'react';
import { get } from 'lodash';
import { clone } from '../../../shared/utility';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Button from '../../../components/CustomButtons/Button';
import CustomInput from '../../../components/CustomInput/CustomInputValidate';

import {
	checkOutsideBrokerOrReferralFirmFields,
	setReferralFirmFeeAmountChoice,
} from '../RSheetsLogic';
import Brokers from '../CommonCustomElements/Brokers';
import LessEntry from '../CommonCustomElements/LessEntry';
import LessContacts from '../CommonCustomElements/LessContacts';
import ReferralsLessor from '../CommonCustomElements/ReferralsLessor';

const Step5 = (props) => {
	const {
		setQueryFor,
		getContacts,
		setOpenedContactModal,
		renderElement,
		data,
		setData,
	} = props;

	useEffect(() => {
		setReferralFirmFeeAmountChoice(setData, data)('lessor');
	}, []);

	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={12}>
				<LessEntry 
					data={data}
					setData={setData}
					rsType="Residential"
					fieldType="lessors"
					fieldName="Lessor"
				/>
				{data.lessors.length === 0 && <div className="red-text" style={{margin:20}}>Lessor Required</div>}
			</GridItem>

			<GridItem xs={12} sm={12} md={12}>
				<hr />
			</GridItem>
			<GridItem xs={12} sm={12} md={12}>
				<LessContacts 
					data={data}
					setData={setData}
					fieldType="lessor_contacts"
					fieldName="Lessor Contact"
				/>	
			</GridItem>

			<Brokers 
				data={data}
				setData={setData}
				outside_brokers_field_name="lessor_outside_broker" 
				outside_brokers_field_label="LESSOR"
			/>

			<ReferralsLessor 
				data={data}
				setData={setData}
				setQueryFor={setQueryFor}
				getContacts={getContacts}
				setOpenedContactModal={setOpenedContactModal}
				renderElement={renderElement}
			/>
		</GridContainer>
	);
};

export default Step5;
