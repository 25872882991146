import React from "react";
import { useEffect, useCallback, useState } from "react";
import { get } from 'lodash';
import { clone } from "shared/utility";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";

import { checkOutsideBrokerOrReferralFirmFields, setReferralFirmFeeAmountChoice } from "../RSheetsLogic";
import CustomCRMEditor from "components/CustomDialog/CustomCRMEditor";
import CustomInput from 'components/CustomInput/CustomInputValidate';
import CustomRadio from "../../../components/CustomRadio/CustomRadio";

export const LESSEE_OUTSIDE_BROKER_FIELDS = [
    "outside_broker_company_name",
    "outside_broker_name",
    "outside_broker_lastname",
    "outside_broker_address1",
    "outside_broker_city",
    "outside_broker_state",
    "outside_broker_zip",
    "outside_broker_contact",
    "outside_broker_fax",
    "outside_broker_main_phone",
    "outside_broker_email",
    "outside_broker_taxid",
    "outside_broker_w9",
    "outside_broker_w9_upload",
    "outside_broker_commission",
];
export const LESSEE_OUTSIDE_BROKE_NON_REQUIRED_FIELDS = ['outside_broker_fax','outside_broker_company_name',"outside_broker_taxid"]

export const LESSEE_REFERRAL_FIRM_FIELDS = [
    "lessee_referral_firm_company_name",
    "lessee_referral_firm_name",
    "lessee_referral_firm_lastname",
    "lessee_referral_firm_address1",
    "lessee_referral_firm_city",
    "lessee_referral_firm_state",
    "lessee_referral_firm_zip",
    "lessee_referral_firm_contact",
    "lessee_referral_firm_fax",
    "lessee_referral_firm_main_phone",
    "lessee_referral_firm_email",
    "lessee_referral_firm_taxid",
    "lessee_referral_firm_w9",
    "lessee_referral_firm_w9_upload",
    "lessee_referral_firm_fee_amount_choice",
    "lessee_referral_firm_fee_amount_percentage",
    "lessee_referral_firm_fee_amount",
];
export const LESSEE_REFERRAL_FIRM_NON_REQUIRED_FIELDS = ['lessee_referral_firm_fax', 'lessee_referral_firm_fee_amount_choice','lessee_referral_firm_company_name',"lessee_referral_firm_taxid"]

const ReferralsLessee = props => {
    const [edit, setEdit] = useState(null);
    const { 
        data, 
        setData,
        setQueryFor,
        getContacts,
        setOpenedContactModal,
        renderElement
    } = props;

    const areReferralFirmFieldsRequired = checkOutsideBrokerOrReferralFirmFields(data, LESSEE_REFERRAL_FIRM_FIELDS, LESSEE_REFERRAL_FIRM_NON_REQUIRED_FIELDS);

    const renderReferralFirmFields = useCallback(() => {
		return LESSEE_REFERRAL_FIRM_FIELDS.map((lfield) => {
			const extraValidations = (!LESSEE_REFERRAL_FIRM_NON_REQUIRED_FIELDS.includes(lfield) && areReferralFirmFieldsRequired) ? ['required'] : []

            // if (data.lessee_referral_firm_id) {
			// 	// is a company
			// 	if (data.lessee_referral_firm_id.toString().indexOf("c") !== -1 && get(data, 'lessee_referral_firm_company_only', false)) {
            //         if (lfield === 'lessee_referral_firm_name') return null;
            //         if (lfield === 'lessee_referral_firm_lastname') return null;
            //     }
            // }

			if (!lfield) {
				return <GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}></GridItem>;
			}
			if (data.lessee_referral_firm_w9 !== "Yes") {
				if (lfield === "lessee_referral_firm_w9_upload") return null;
			}
			if (
				data.lessee_referral_firm_fee_amount_choice === "Percentage" &&
				lfield === "lessee_referral_firm_fee_amount"
			)
				return null;
			if (
				data.lessee_referral_firm_fee_amount_choice === "Value" &&
				lfield === "lessee_referral_firm_fee_amount_percentage"
			)
				return null;
            if (!data.lessee_referral_firm_fee_amount_choice &&
				lfield === "lessee_referral_firm_fee_amount_percentage") {
                return null;
            }
            if (!data.lessee_referral_firm_fee_amount_choice &&
				lfield === "lessee_referral_firm_fee_amount") {
                return null;
            }

            // console.log('The lfield', lfield);
            const prefix = `lessee_referral_firm_`;
            const field = lfield.split(prefix)[1];
            const id = data[prefix+'id'] ? data[prefix+'id'] : '';
            if (!id) return null;
            let company_id = null;
            let contact_id = null;
            if (typeof id === 'string') {
                company_id = id.split('c')[1];
            } else {
                contact_id = id;
            }
            
            if (field === 'address1') {
                return <GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}>
                    <CustomInput 
                        value={data[lfield]}
                        label="Address"
                        onClick={() => {
                            const chooseVal = (chosenVal) => {
                                const newData = clone(data);
                                newData[lfield] = chosenVal;
                                setData(newData);
                            }
                            if (company_id) {
                                setEdit({
                                    value: data[lfield],      
                                    type: 'company',       
                                    field: 'mailing_address1',      
                                    fieldType: 'input',  
                                    label: 'Address 1',      
                                    id: company_id,         
                                    chooseVal
                                });
                            } else if (contact_id) {
                                setEdit({
                                    value: data[lfield],      
                                    type: 'contact',       
                                    field: 'street1',      
                                    fieldType: 'input',  
                                    label: 'Address 1',      
                                    id: contact_id,         
                                    chooseVal
                                });
                            }
                        }}
                    />
                    {!data[lfield] && <div className="red-text">Required</div>}
				</GridItem>
            } else if (field === 'city') {
                return <GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}>
                    <CustomInput 
                        value={data[lfield]}
                        label="City"
                        onClick={() => {
                            const chooseVal = (chosenVal) => {
                                const newData = clone(data);
                                newData[lfield] = chosenVal;
                                setData(newData);
                            }
                            if (company_id) {
                                setEdit({
                                    value: data[lfield],      
                                    type: 'company',       
                                    field: 'mailing_city',      
                                    fieldType: 'input',  
                                    label: 'City',      
                                    id: company_id,         
                                    chooseVal
                                });
                            } else if (contact_id) {
                                setEdit({
                                    value: data[lfield],      
                                    type: 'contact',       
                                    field: 'city',      
                                    fieldType: 'input',  
                                    label: 'City',      
                                    id: contact_id,         
                                    chooseVal
                                });
                            }
                        }}
                    />
                    {!data[lfield] && <div className="red-text">Required</div>}
				</GridItem>
            } else if (field === 'state') {
                return <GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}>
                    <CustomInput 
                        value={data[lfield]}
                        label="State"
                        onClick={() => {
                            const chooseVal = (chosenVal) => {
                                const newData = clone(data);
                                newData[lfield] = chosenVal;
                                setData(newData);
                            }
                            if (company_id) {
                                setEdit({
                                    value: data[lfield],      
                                    type: 'company',       
                                    field: 'mailing_state',      
                                    fieldType: 'states',  
                                    label: 'State',      
                                    id: company_id,         
                                    chooseVal
                                });
                            } else if (contact_id) {
                                setEdit({
                                    value: data[lfield],      
                                    type: 'contact',       
                                    field: 'state',      
                                    fieldType: 'states',  
                                    label: 'State',      
                                    id: contact_id,         
                                    chooseVal
                                });
                            }
                        }}
                    />
                    {!data[lfield] && <div className="red-text">Required</div>}
				</GridItem>
            } else if (field === 'zip') {
                return <GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}>
                    <CustomInput 
                        value={data[lfield]}
                        label="Zip"
                        onClick={() => {
                            const chooseVal = (chosenVal) => {
                                const newData = clone(data);
                                newData[lfield] = chosenVal;
                                setData(newData);
                            }
                            if (company_id) {
                                setEdit({
                                    value: data[lfield],      
                                    type: 'company',       
                                    field: 'mailing_zip',      
                                    fieldType: 'input',  
                                    label: 'Zip',      
                                    id: company_id,         
                                    chooseVal
                                });
                            } else if (contact_id) {
                                setEdit({
                                    value: data[lfield],      
                                    type: 'contact',       
                                    field: 'zip',      
                                    fieldType: 'input',  
                                    label: 'Zip',      
                                    id: contact_id,         
                                    chooseVal
                                });
                            }
                        }}
                    />
                    {!data[lfield] && <div className="red-text">Required</div>}
				</GridItem>
            } else if (field === 'fax') {
                return <GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}>
                    <CustomInput 
                        value={data[lfield]}
                        label="Fax"
                        onClick={() => {
                            const chooseVal = (chosenVal) => {
                                const newData = clone(data);
                                newData[lfield] = chosenVal;
                                setData(newData);
                            }
                            if (company_id) {
                                setEdit({
                                    value: data[lfield],      
                                    type: 'company',       
                                    field: 'fax',      
                                    fieldType: 'input',  
                                    label: 'Fax',      
                                    id: company_id,         
                                    chooseVal
                                });
                            } else if (contact_id) {
                                setEdit({
                                    value: data[lfield],      
                                    type: 'contact',       
                                    field: 'fax',      
                                    fieldType: 'input',  
                                    label: 'Fax',      
                                    id: contact_id,         
                                    chooseVal
                                });
                            }
                        }}
                    />
				</GridItem>
            } else if (field === 'main_phone') {
                const label = 'Main Phone';
                return <GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}>
                    <CustomInput 
                        value={data[lfield]}
                        label={label}
                        onClick={() => {
                            const chooseVal = (chosenVal) => {
                                const newData = clone(data);
                                newData[lfield] = chosenVal;
                                setData(newData);
                            }
                            if (company_id) {
                                setEdit({
                                    value: data[lfield],      
                                    type: 'company',       
                                    field,      
                                    fieldType: 'input',  
                                    label,      
                                    id: company_id,         
                                    chooseVal
                                });
                            } else if (contact_id) {
                                setEdit({
                                    value: data[lfield],      
                                    type: 'contact',       
                                    field,      
                                    fieldType: 'input',  
                                    label,      
                                    id: contact_id,         
                                    chooseVal
                                });
                            }
                        }}
                    />
                    {!data[lfield] && <div className="red-text">Required</div>}
				</GridItem>
            } else if (field === 'email') {
                const label = 'Email';
                return <GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}>
                    <CustomInput 
                        value={data[lfield]}
                        label={label}
                        onClick={() => {
                            const chooseVal = (chosenVal) => {
                                const newData = clone(data);
                                newData[lfield] = chosenVal;
                                setData(newData);
                            }
                            if (company_id) {
                                setEdit({
                                    value: data[lfield],      
                                    type: 'company',       
                                    field,      
                                    fieldType: 'input',  
                                    label,      
                                    id: company_id,         
                                    chooseVal
                                });
                            } else if (contact_id) {
                                setEdit({
                                    value: data[lfield],      
                                    type: 'contact',       
                                    field,      
                                    fieldType: 'input',  
                                    label,      
                                    id: contact_id,         
                                    chooseVal
                                });
                            }
                        }}
                    />
                    {!data[lfield] && <div className="red-text">Required</div>}
				</GridItem>
            }
            if (lfield === 'lessee_referral_firm_fee_amount_choice') {
                return <GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}>
                <div>
                    <CustomRadio 
                        label="Fee Amount,Value,Percentage"
                        options={['Value','Percentage']}
                        value={data[lfield]}
                        onChange={(e) => {
                            const newData = clone(data);
                            newData[lfield] = e.target.value;
                            setData(newData);
                        }}
                    />
                    {!data[lfield] && <div className="red-text">Required</div>}
                </div>
            </GridItem>
            }
            const hidden_fields = ['name', 'lastname', 'company_name'];
            console.log('fee_amount_choice', );
			return (
				<GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}>
                    <div className={hidden_fields.indexOf(field) !== -1 ? `gray-bg no-click` : ``}>
                        {renderElement(lfield, null, null, null, null, extraValidations)}
                    </div>
				</GridItem>
			);
		}, [data])
	})

    return <>
        <GridItem xs={12} sm={12} md={12}>
            <hr />
            <h4>
                <strong>{props.title ? props.title : `LESSEE REFERRAL`}</strong>
            </h4>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
        {!data.lessee_referral_firm_id && <Button
            color="primary"
            onClick={() => {
                setQueryFor("lessee_referral_firm");
                getContacts({
                    names_only: "All",
                });
                setOpenedContactModal(true);
            }}
        >
            SELECT REFERRAL
        </Button>}
        {data.lessee_referral_firm_id && (!data?.lessee_referrals || !data?.lessee_referrals.length) && <Button
            color="success"
            onClick={() => {
                const newData = clone(data);
                const field_arr = Object.keys(data);
                field_arr.forEach(fieldy => {
                    if (fieldy.indexOf('lessee_referral_firm_') !== -1) {
                        newData[fieldy] = '';
                    }
                });
                setData(newData);
            }}
        >
            REMOVE REFERRAL
        </Button>}
    </GridItem>

    {data.lessee_referral_firm_id ? renderReferralFirmFields() : null}
    
        {edit !== null && <CustomCRMEditor 
            {...edit}
            close={() => {
                setEdit(null)
            }}
        />}
    </>
}

export default ReferralsLessee;