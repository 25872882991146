import React, { useEffect, useMemo, useState } from "react";
import { concat, every, flattenDeep, get, some } from "lodash";
import Button from "../../../components/CustomButtons/Button";
import { alphabet, clone, currencyToNumber, formatDollar, get2DecimalRound, handleError } from "../../../shared/utility";
import PaymentSchedule from "./PaymentSchedule";
import { IconButton } from "@material-ui/core";
import axios from "store/axios/axios-invoice";
import { useRef } from "react";
import {
    calculateAbatedRanges,
    calculateAdjustedCommission,
    calculateEstimatedAbatedRanges,
    calculateEstimatedTermsTotal,
    getFlatFeeCommission,
    isEstimatedLease,
    isFlatFeeCommissionEnabled,
    newYearlyCalculation,
    typeDescriptionsDict,
} from "../RSheetsLogic";
import { getUser } from "../../../shared/authValidation";
import InvoicePaymentLines from "./InvoicePaymentLines";
import NotificationAsk from "../../../components/Notification/NotificationAsk";
import { getTotalInvoicePayment, getTotalInvoicePaymentWithoutLineItems } from "../../Invoices/InvoiceLogic";
import moment from "moment";

const InvoicePayment = (props) => {
    const { updateDataObj, field, data, commissionValue = undefined } = props;
    const [scrollRef, setScrollRef] = useState(null);
    const [openedAskDelete, setOpenedAskDelete] = useState(-1);
    const [invoicesData, setInvoicesData] = useState({}); // status
    const ref = useRef(null);

    const payments = data.invoices ? data.invoices : [];
    const lease_term = get(data, "lease_term", []);
    const abated_rent_ranges = get(data, "abated_rent_ranges", []);
    const isEstimated = isEstimatedLease(data);

    const flatFeeEnabled = isFlatFeeCommissionEnabled(data) 
        || commissionValue 
        || props.sheetType === 'Consulting'
        || props.sheetType === 'LeaseAssignmentFee';
    const flatFeeCommission = currencyToNumber(commissionValue) || getFlatFeeCommission(data);
    console.log('flatFeeCommission', flatFeeCommission)
	const isActualDays = get(data, "commission_based_off", "") === "Actual Days";

    // Range calculation happens everywhere, so minimize the calculation as much as possible
    const total = useMemo(() => {
        if (flatFeeEnabled) {
            return flatFeeCommission + calculateAdjustedCommission(data);
        } else {
            const abated_ranges = isEstimated
                ? calculateEstimatedAbatedRanges({ lease_term, abated_rent_ranges })
                : calculateAbatedRanges({ lease_term, abated: abated_rent_ranges });
            const ranges = lease_term.flatMap((item) => item.ranges);
            const combinedRanges = concat(abated_ranges, ranges);

            if (isEstimated) {
                const totalCalc = calculateEstimatedTermsTotal(combinedRanges)
                return parseFloat(totalCalc.totalCommission) + calculateAdjustedCommission(data);
            } else {
                const grossCommission = newYearlyCalculation(ranges, false, isActualDays).commission;
                const abatedCommission = newYearlyCalculation(abated_ranges, false, isActualDays).commission;

                return grossCommission - abatedCommission + calculateAdjustedCommission(data);
            }
        }
    }, [lease_term, abated_rent_ranges, flatFeeEnabled, flatFeeCommission]);

    const getInvoices = async () => {
        try {
            const response = await axios.get(`/entries?rs_id=${data.id}`);
            const rdata = response.data;
            console.log('invoice data', rdata);
            const newObj = {};
            rdata.forEach(rd => {
                newObj[rd.id] = {
                    status: rd.status,
                    dat: rd,
                    invoice_num: rd.invoice_num
                }
            })
            setInvoicesData(newObj);
        } catch(e) {
            handleError(e);
        }
    }

    // Scroll
    useEffect(() => {
        if (scrollRef) {
            handleScrollClick();
            setScrollRef(null);
        }
    }, [scrollRef]);

    useEffect(() => {
        // load invoices by RS ID 
        if (data.id) {
            getInvoices();
        }
    }, []);

    const handleScrollClick = () => {
        ref.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
        });
    };

    const canRender = true; //payments.length === 0 || (payments.length > 0 && Array.isArray(payments[0]));

    const canAddLineEntries = getUser("Invoice enter_line_items");

    console.log('user->', canAddLineEntries);

    const renderPayments = () => {
        const main_invoice_id = '1000';
        const paymentsSection = <div>
            {payments.map((pm, index) => {
                const alphabet_ = alphabet[index];
                const invoice_id = pm.id;
                let invoice_num = `${main_invoice_id}-${alphabet_}`;
                if (invoicesData.hasOwnProperty(invoice_id)) {
                    if (invoicesData[invoice_id].invoice_id) {
                        invoice_num = invoicesData[invoice_id].invoice_id;
                    }
                }
                return (
                    <div className="boxed" key={index} ref={scrollRef === index ? ref : null}>
                        <div style={{ float: "right" }}>
                            <IconButton onClick={() => setOpenedAskDelete(index)}>
                                <i className="fas fa-trash-alt red-text"></i>
                            </IconButton>
                        </div>
                        <h4 className="red-text bold">Invoice # {invoice_num}</h4>
                        <PaymentSchedule
                            data={data}
                            field={field}
                            handleAddNewPayment={handleAddNewPayment(index)}
                            handleUpdatePayment={handleUpdatePayment(index)}
                            handleDeletePayment={(pm_index) => {
                                console.log('ddeleting', index, pm_index);
                                handleDeletePayment(index, pm_index);
                            }}
                            iv_index={index}
                            payments={pm.data.entries}
                            invoiceList={pm.data.entries}
                            totalPayment={totalPayment}
                            total={total}
                            updateDataObj={updateDataObj}
                            invoicesData={invoicesData}
                            sheetType={props.sheetType}
                        />
                        {canAddLineEntries && <InvoicePaymentLines 
                            updateDataObj={updateDataObj}
                            data={data}
                            invoice_index={index}
                        />}
                    </div>
                );
            })}

            {openedAskDelete !== -1 && <NotificationAsk 
                title="Are you sure?"
                message="Do you want to delete this invoice?"
                open={true}
                close={() => {
                    setOpenedAskDelete(-1);
                }}
                success={async () => {
                    const invoiceToBeDeleted = data.invoices[openedAskDelete];
                    if (invoiceToBeDeleted.id) {
                        console.log('deleting', invoiceToBeDeleted.id);
                        try {
                            await axios.delete('/entry?id='+invoiceToBeDeleted.id+'&actual=true');
                        } catch(e) {
                            handleError(e);
                            alert('Could not delete invoice');
                            return;
                        }
                    }
                    const newData = clone(data);
                    newData.invoices = newData.invoices.filter((inv,indx) => {
                        if (indx !== openedAskDelete) return true;
                    })
                    updateDataObj('invoices', newData.invoices);
                    if (props.saveRS) {
                        props.saveRS(newData);
                    }
                    setOpenedAskDelete(-1);
                }}
            />}
        </div>
        return paymentsSection;
    };

    const handleAddNewPayment = (iv_index) => (pm) => {
        console.log('iv_ind', iv_index, pm);
        const newData = clone(data);
        newData.invoices[iv_index].data.entries.push(pm);
        updateDataObj('invoices', newData.invoices);
    };

    const handleUpdatePayment = (iv_index) => (pm_index, newPm) => {
        const newData = clone(data);
        newData.invoices[iv_index].data.entries[pm_index] = newPm;
        updateDataObj('invoices', newData.invoices);
    };

    const handleDeletePayment = (iv_index, pm_index) => {
        console.log('deleting', iv_index, pm_index)
        if (typeof iv_index === 'number') {
            const newData = clone(data);
            const newPms = newData.invoices;
            newPms[iv_index].data.entries.splice(pm_index, 1);
            // const currentOptions = flattenDeep(newPms[iv_index].data.entries).map((pm) => pm.option);

            // // // When remove payment with First Half, make payment with Second Half EMPTY
            // // if (
            // //     every(currentOptions, (item) => !item.toLowerCase().includes("first half")) &&
            // //     some(currentOptions, (item) => item.toLowerCase().includes("second half"))
            // // ) {
            // //     for (let i in newPms) {
            // //         for (let j in newPms[i].data.entries) {
            // //             if (newPms[i].data.entries[j].option && newPms[i].data.entries[j].option.toLowerCase().includes("second half")) {
            // //                 newPms[i].data.entries[j].option = "";
            // //                 newPms[i].data.entries[j].value = "";
            // //             }
            // //         }
            // //     }
            // // }

            updateDataObj('invoices', newData.invoices);
        }
    };

    const handleAddInvoice = async () => {
        // Add new real invoice 
        const newData = clone(data);
        const newInvoice = {
            rs_id: data.id,
            title: "Generated from RS illi# " + data.illi_number,
            data: {
                entries: [],
                line_items: []
            }
        }
        try {
            const postObj = {
                status: 1, // PENDING
                data: {
                    rsData: data,
                    invoice_index: "", // used to calculate befores
                    attn: '',
                    date: moment().format('MM/DD/YYYY'),
                    illi: get(data, "illi_number", ""),
                    sqft: "",
                    agent: "",
                    buyer: "",
                    rs_id: newInvoice.rs_id,
                    terms: "",
                    title: newInvoice.title,
                    seller: "",
                    balance: "",
                    bill_to: "",
                    invoice: "",
                    project: "",
                    total_rate: "",
                    total_month: "",
                    leasing_term: "",
                    total_amount: "",
                    consideration: "",
                    property_address: "",
                    total_commission: "",
                    total_annual_rent: "",

                    entries: [],
                    line_items: []
                }
            };
            console.log('post', postObj)
            const result = await axios.post('/entry', postObj);
            const insertId = result.data.insertId ? result.data.insertId : null;
            if (!insertId) throw "No ID";
            newInvoice.id = insertId;
            if (newData.invoices) {
                newData.invoices.push(newInvoice);
            } else {
                newData.invoices = [newInvoice];
            }
            updateDataObj('invoices', newData.invoices);
            if (props.saveRS) {
                props.saveRS(newData);
            }
            setScrollRef(newData.invoices.length - 1);
        } catch(e) {
            handleError(e);
            alert('Could not create invoice');
        }
        
    };

    const handleDeleteInvoice = (iv_index) => {
        const newPms = clone(payments);
        newPms.splice(iv_index, 1);

        const currentOptions = flattenDeep(newPms).map((pm) => pm.option);

        // When remove payment with First Half, make payment with Second Half EMPTY
        if (
            every(currentOptions, (item) => !item.toLowerCase().includes("first half")) &&
            some(currentOptions, (item) => item.toLowerCase().includes("second half"))
        ) {
            for (let i in newPms) {
                for (let j in newPms[i]) {
                    if (newPms[i][j].option && newPms[i][j].option.toLowerCase().includes("second half")) {
                        newPms[i][j].option = "";
                    }
                }
            }
        }

        updateDataObj(field, newPms);
    };

    // Calculate total payments from ALL invoices
    const totalPayment = getTotalInvoicePaymentWithoutLineItems(data, props.sheetType ? typeDescriptionsDict[props.sheetType] : null);
    console.log('total=>', total, totalPayment);
	const commissionRemaining = formatDollar(parseFloat(get2DecimalRound(total) - get2DecimalRound(totalPayment)));
	const commissionRemainingClass = commissionRemaining == '$0.00' ? 'green' : 'red'

    console.log('InvoicePayment', props, payments, canRender);

    return (
        <div className={!props.canEditInvoiceRelatedData ? 'gray-bg-input avoid-clicks' : ''}>
            <div style={{display: 'flex', alignItems: 'canter'}}>
                <Button disabled={totalPayment >= total} color="primary" onClick={handleAddInvoice}>
                    <i className="fas fa-plus"></i> ADD INVOICE
                </Button>

                <div style={{marginLeft: '20px'}}>
                    <div className='black'>Total Commission: {formatDollar(total)}</div>
					<div className={commissionRemainingClass}>Total Commission Remaining to bill: {commissionRemaining}</div>
                </div>
            </div>

            {canRender && (
                <div style={{ padding: 20 }}>
                    {payments.length === 0 && (
                        <li className="text-center">
                            <em>No entries</em>
                        </li>
                    )}
                    {renderPayments()}
                </div>
            )}
        </div>
    );
};

export default InvoicePayment;
