// TABLE OF CONTENTS
// * Sale Fields *

import propertyFields from 'containers/CRM/Properties/PropertiesObj';

export const RS_TYPE = [
	'Residential',
	'Lease',
	'Sub-lease',
	'Sales',
	'Tenant Rep',
	'Consulting',
	'Option Commission',
	'No Listing',
	'Lease Assignment Fee',
];

export const types = {
	1: 'Draft',
	2: 'Pending Level 1 Approval',
	3: 'Pending Level 2 Approval',
	4: 'Pending Level 3 Approval',
	5: 'Rejected, Level 1',
	6: 'Rejected, Level 2',
	7: 'Rejected, Level 3',
	8: 'Rejected, Level 4',
	9: 'Approved, Level 4 Approval',
	10: 'Pending Level 4 Approval',
};

const COMMON_FIELDS = {
	//! PROPERTY INFORMATION
	date: {
		label: 'Date',
		type: 'date',
		validations: ['required'],
	},
	project_name: {
		label: 'Project Name',
		type: 'input',
		readonly: true,
		validations: ['required'],
	},
	project_address: {
		label: 'Project Address',
		type: 'input',
		readonly: true,
		validations: ['required'],
	},
	street1: {
		label: 'Project Street1',
		type: 'input',
		readonly: true,
		validations: ['required'],
	},
	spaces: {
		label: 'Project Spaces',
		type: 'spaces',
		validations: ['required'],
		readonly: true,
	},
	la_city_verification: {
		label: 'Is Property Located in the City of L.A ?',
		type: 'radio',
		options: ['Yes', 'No'],
		readonly: true,
		validations: ['required'],
	},
	listings_procured_by: {
		label: 'Listings Procured By',
		type: 'multi-contact',
		validations: ['required'],
	},
	lead_source: {
		label: 'Lead Source',
		type: 'input',
		validations: [],
	},
	deal_generated_by: {
		label: 'Deal Generated By',
		type: 'radio',
		options: ['PM', 'Brokerage'],
        validations: ['required'],
	},
	illi_represented_lessor: {
		label: 'illi Represented - Lessor',
		type: 'radio',
		options: ['Yes', 'No'],
        validations: ['required'],
	},
	illi_represented_lessor_agents: {
		label: 'illi Represented - Lessor - Agent(s)',
		type: 'multi-contact-agent',
		peopleType: 'agent',
        validations: ['required'],
	},
	illi_represented_lessee: {
		label: 'illi Represented - Lessee',
		type: 'radio',
		options: ['Yes', 'No'],
        validations: ['required'],
	},
	illi_represented_lessee_agents: {
		label: 'illi Represented - Lessee - Agent(s)',
		type: 'multi-contact-agent',
		peopleType: 'agent',
        validations: ['required'],
	},
	property_type: {
		label: 'Use Type',
		type: 'select',
		options: propertyFields.use_type.options.filter((f) => {
			if (f === 'Student') return false;
			if (!f) return false;
			return true;
		}).sort(),
		validations: ['required'],
	},

	//! PREMISES INFORMATION
	premises_address: {
		label: 'Premises Address',
		type: 'input',
		readonly: true,
		validations: ['required'],
	},
	unit_number: {
		label: 'Unit Number',
		type: 'input',
		readonly: true,
		validations: [],
	},
	state: {
		label: 'State',
		type: 'state',
		readonly: true,
		validations: ['required'],
	},
	city: {
		label: 'City',
		type: 'input',
		readonly: true,
		validations: ['required'],
	},
	zip: {
		label: 'Zip',
		type: 'input',
		readonly: true,
		validations: ['required'],
	},
	county: {
        label: 'County',
        type: 'input',
        readonly: true,
		validations: ['required'],
    },
	window_signs_returned: {
		label: 'Window Signs Returned?',
		type: 'select',
		options: ['Yes', 'No', 'N/A'],
        validations: ['required'],
	},
	window_sign_number: {
		label: 'Window Sign Number(s)',
		type: 'input',
		validations: [],
	},
	sign_removal_requested: {
		label: 'Request Sign Removal',
		type: 'select',
		options: ['Yes', 'No', 'N/A'],
        validations: ['required'],
	},
	loopnet_marketing_updated: {
		label: 'Loopnet/Marketing Updated?',
		type: 'radio',
		options: ['Yes', 'No', 'N/A'],
        validations: ['required'],
	},

	// This field is use to store the state of the space
	flat_fee_space: {

	},

	//! LEASE INFORMATION
	business_name: {
		label: 'Business Name',
		type: 'input',
        validations: ['required'],
	},
	type_of_business: {
		label: 'Type of Business',
		type: 'input',
        validations: ['required'],
	},
	lease_length: {
		label: 'Length of Lease',
		type: 'input2',
        suffix: ' Months',
		validations: ['no_letters'],
	},
	lease_start_date: {
		label: 'Lease Start Date',
		type: 'date2',
		validations: ['required'],
	},
	lease_start_date_estimated: {
		label: '',
		type: 'checkbox',
		options: ['Lease Start Date Estimated'],
        validations: ['required'],
	},
	lease_start_date_estimated_tbd: {
		label: '',
		type: 'checkbox',
		options: ['TBD'],
        validations: ['required'],
	},
	lease_end_date: {
		label: 'Lease End Date',
		type: 'date3',
		validations: ['required'],
	},
	rent_commencement: {
		label: 'Rent Commencement',
		type: 'date2',
		validations: ['required'],
	},
	rent_commencement_estimated: {
		label: '',
		type: 'checkbox',
		options: ['Rent Commencement Estimated'],
        validations: ['required'],
	},
	rent_commencement_estimated_tbd: {
		label: '',
		type: 'checkbox',
		options: ['TBD'],
        validations: ['required'],
	},
	total_sf_leased: {
		label: 'Total Sq.Ft. Leased',
		type: 'input3',
		readonly: true,
	},
	starting_rent: {
		label: 'Starting Rent Per Sqft ',
		type: 'input',
		readonly: true,
	},
	flat_fee_commission: {
		label: 'Flat Fee Commission',
		type: 'radio',
		options: ['Yes', 'No'],
        validations: ['required'],
	},
	flat_fee_commission_amount: {
		label: 'Flat Fee Commission Amount',
		type: 'input',
        validations: ['required'],
		dollar: true,
	},
	commission_based_off: {
		label: 'Rent Based Off',
		type: 'radio',
		options: ['30 Days', 'Actual Days'],
        validations: ['required'],
		defaultValue: 'Actual Days'
	},
	rate_type: {
		label: 'Rental Increases',
		type: 'radio',
		options: ['Fixed Amount', 'Fixed Percentage'],
        validations: ['required'],
	},
	rate_type_cpi_percentage: {
		label: 'Percentage Rate',
		type: 'input',
		validations: ['required', 'percentage'],
		suffix: '%',
	},
	early_possession: {
		label: 'Early Possession',
		type: 'radio',
		options: ['Yes', 'No'],
		validations: ['required'],
	},
	early_possession_date: {
		label: 'Early Possession Date',
		type: 'date',
		validations: ['required'],
	},
	contingency: {
		label: 'Contingency',
		type: 'radio',
		options: ['Yes', 'No'],
        validations: ['required'],
	},
	contingency_detail: {
		label: 'Contingency Detail',
		type: 'textarea',
        validations: ['required']
	},
	lease_term: {
		label: 'Lease Term',
		type: 'lease_term_ranges',
		options: [],
		validations: ['required'],
	},
	lease_type: {
		label: 'Lease Type',
		type: 'select',
		options: ['NNN', 'MG', 'FSG'],
		validations: ['required'],
	},
	date_lease_signed: {
		label: 'Date Lease Signed',
		type: 'date',
		validations: ['required'],
	},
	occupancy_date: {
		label: 'Occupancy Date',
		type: 'date2',
		validations: ['required'],
	},
	occupancy_date_estimated: {
		label: '',
		type: 'checkbox',
		options: ['Occupancy Date Estimated'],
        validations: ['required'],
	},
	occupancy_date_estimated_tbd: {
		label: '',
		types: 'checkbox',
		options: ['TBD'],
        validations: ['required'],
	},
	option_renewal: {
		label: 'Option Renewal',
		type: 'radio',
		options: ['Yes', 'No'],
		validations: ['required'],
	},
	option_commission: {
		label: 'Option Commission',
		type: 'radio',
		options: ['Yes', 'No'],
		validations: ['required'],
	},

	// PRESPLITS 
	presplits: {
		label: 'Pre-splits',
		type: 'object_array'
	},

	//! LESSEES
	abated_rent_ranges: {
		label: 'Abated Rent Ranges',
		type: 'lease_term_ranges',
	},
	can_deal_be_submitted_to_public_relations: {
		label: 'Can deal be submitted to Public Relations?',
		type: 'radio',
		options: ['Yes', 'No'],
        validations: ['required'],
	},
	can_deal_be_submitted_to_public_relations_comment: {
		label: 'Comment',
		type: 'input',
	},
	lessees_address1: {
		label: 'Address',
		type: 'input',
	},
	lessees_address2: {
		label: 'Address 2',
		type: 'input',
	},
	lessees_city: {
		label: 'City',
		type: 'input',
	},
	lessees_state: {
		label: 'State',
		type: 'state',
	},
	lessees_zip: {
		label: 'Zip',
		type: 'input',
	},
	lessees_contact: {
		label: 'Contact',
		type: 'input',
	},
	lessees_main_phone: {
		label: 'Phone',
		type: 'input',
	},
	lessees_work_phone: {
		label: 'Work Phone',
		type: 'input',
	},
	lessees_mobile_phone: {
		label: 'Mobile Phone',
		type: 'input',
	},
	lessees_fax: {
		label: 'Fax',
		type: 'input',
	},
	lessees_email: {
		label: 'Email',
		type: 'input',
	},
	lessees_agency_disclosure: {
		label: 'Agency Disclosure',
		type: 'radio',
		options: ['Yes', 'No'],
	},
	lessees_llc_corp_verified: {
		label: 'LLC / Corp Verified',
		type: 'radio',
		options: ['Yes', 'N/A'],
	},
	lessees_upload_sos: {
		label: 'S.O.S.',
		type: 'file_upload',
		filetypes: ['pdf', 'png', 'jpg'],
	},
	lessees_spousal_consent: {
		label: 'Spousal Consent',
		type: 'radio',
		options: ['Yes', 'N/A'],
	},
	lessees_upload_spousal_id: {
		label: 'Spousal ID',
		type: 'file_upload',
		filetypes: ['pdf', 'png', 'jpg'],
	},
	lessees_guarantor: {
		label: 'Guarantor',
		type: 'radio',
		options: ['Yes', 'N/A'],
	},
	lessees_guarantor_name: {
		label: 'Guarantor Name',
		type: 'input',
	},
	lessees_upload_guarantor_id: {
		label: 'Guarantor ID',
		type: 'file_upload',
		filetypes: ['pdf', 'png', 'jpg'],
	},
	lessees_guarantor_spouse_name: {
		label: 'Guarantor Spouse Name',
		type: 'input',
	},
	lessees_can_we_reach_out_for_testimonial: {
		label: 'Can we reach out for testimonial ?',
		type: 'radio',
		options: ['Yes', 'No'],
	},
	lessees_can_we_reach_out_for_testimonial_comment: {
		label: 'Comment',
		type: 'input',
	},
	lessees: {
		label: 'Lessee',
		type: 'lessees',
	},
	lessee_contacts: {
		label: 'Lessee Contacts',
		type: 'multi-contact',
	},

	//! REFFERAL FOR LESSEE
	lessee_referral_firm: {
		label: 'Referral',
		type: 'multi-contact',
	},
	lessee_referral_firm_company_name: {
		label: 'Payee Company Name',
		type: 'input',
	},
	lessee_referral_firm_name: {
		label: 'Payee First Name',
		type: 'input',
	},
	lessee_referral_firm_lastname: {
		label: 'Payee Last Name',
		type: 'input',
	},
	lessee_referral_firm_contact_dba_name: {
		label: 'Payee DBA',
		type: 'input',
	},
	lessee_referral_firm_address1: {
		label: 'Address',
		type: 'input',
	},
	lessee_referral_firm_city: {
		label: 'City',
		type: 'input',
	},
	lessee_referral_firm_state: {
		label: 'State',
		type: 'state',
	},
	lessee_referral_firm_zip: {
		label: 'Zip',
		type: 'input',
	},
	lessee_referral_firm_contact: {
		label: 'Contact',
		type: 'input',
	},
	lessee_referral_firm_main_phone: {
		label: 'Main Phone',
		type: 'input',
	},
	lessee_referral_firm_mobile_phone: {
		label: 'Mobile Phone',
		type: 'input',
	},
	lessee_referral_firm_work_phone: {
		label: 'Work Phone',
		type: 'input',
	},
	lessee_referral_firm_fax: {
		label: 'Fax',
		type: 'input',
	},
	lessee_referral_firm_email: {
		label: 'Email',
		type: 'input',
	},
	lessee_referral_firm_taxid: {
		label: 'Tax ID',
		type: 'input',
	},
	lessee_referral_firm_w9: {
		label: 'W9',
		type: 'radio',
		options: ['Yes', 'No'],
	},
	lessee_referral_firm_w9_upload: {
		label: 'Referral W9',
		type: 'file_upload',
		filetypes: ['pdf', 'png', 'jpg'],
	},
	lessee_referral_firm_fee_amount_choice: {
		label: 'Fee Amount',
		type: 'radio',
		options: ['Value', 'Percentage'],
	},
	lessee_referral_firm_fee_amount_percentage: {
		label: 'Fee Amount Percentage',
		type: 'input',
		suffix: '%',
	},
	lessee_referral_firm_fee_amount: {
		label: 'Fee Amount',
		type: 'input',
		dollar: true,
	},

	//! LESSEE OUTSIDE BROKER
	outside_broker: {
		label: 'Outside Broker',
		type: 'multi-contact',
	},
	outside_broker_company_name: {
		label: 'Company Name',
		type: 'input',
	},
	outside_broker_name: {
		label: 'First Name',
		type: 'input',
	},
	outside_broker_lastname: {
		label: 'Last Name',
		type: 'input',
	},
	outside_broker_address1: {
		label: 'Address',
		type: 'input',
	},
	outside_broker_address2: {
		label: 'Address 2',
		type: 'input',
	},
	outside_broker_city: {
		label: 'City',
		type: 'input',
	},
	outside_broker_state: {
		label: 'State',
		type: 'state',
	},
	outside_broker_zip: {
		label: 'Zip',
		type: 'input',
	},
	outside_broker_contact: {
		label: 'Agent(s)',
		type: 'input',
	},
	outside_broker_main_phone: {
		label: 'Main Phone',
		type: 'input',
	},
	outside_broker_mobile_phone: {
		label: 'Mobile Phone',
		type: 'input',
	},
	outside_broker_work_phone: {
		label: 'Work Phone',
		type: 'input',
	},
	outside_broker_fax: {
		label: 'Fax',
		type: 'input',
	},
	outside_broker_email: {
		label: 'Email',
		type: 'input',
	},
	outside_broker_taxid: {
		label: 'Tax ID',
		type: 'input',
	},
	outside_broker_w9: {
		label: 'W9',
		type: 'radio',
		options: ['Yes', 'No'],
	},
	outside_broker_w9_upload: {
		label: 'W9',
		type: 'file_upload',
		filetypes: ['pdf', 'png', 'jpg'],
	},
	outside_broker_commission: {
		label: 'Outside Broker Commission',
		type: 'input',
		inputType: 'number',
	},

	//! LESSOR - OUTSIDE BROKER
	lessor_outside_broker: {
		label: 'Outside Broker',
		type: 'multi-contact',
	},
	lessor_outside_broker_lastname: {
		label: 'Last Name',
		type: 'input',
	},
	lessor_outside_broker_address1: {
		label: 'Address',
		type: 'input',
	},
	lessor_outside_broker_address2: {
		label: 'Address 2',
		type: 'input',
	},
	lessor_outside_broker_city: {
		label: 'City',
		type: 'input',
	},
	lessor_outside_broker_state: {
		label: 'State',
		type: 'state',
	},
	lessor_outside_broker_zip: {
		label: 'Zip',
		type: 'input',
	},
	lessor_outside_broker_contact: {
		label: 'Agent(s)',
		type: 'input',
	},
	lessor_outside_broker_main_phone: {
		label: 'Main Phone',
		type: 'input',
	},
	lessor_outside_broker_work_phone: {
		label: 'Work Phone',
		type: 'input',
	},
	lessor_outside_broker_mobile_phone: {
		label: 'Mobile Phone',
		type: 'input',
	},
	lessor_outside_broker_fax: {
		label: 'Fax',
		type: 'input',
	},
	lessor_outside_broker_email: {
		label: 'Email',
		type: 'input',
	},
	lessor_outside_broker_taxid: {
		label: 'Tax ID',
		type: 'input',
	},
	lessor_outside_broker_w9: {
		label: 'W9',
		type: 'radio',
		options: ['Yes', 'No'],
	},
	lessor_outside_broker_w9_upload: {
		label: 'W9',
		type: 'file_upload',
		filetypes: ['pdf', 'png', 'jpg'],
	},
	lessor_outside_broker_commission: {
		label: 'Outside Broker Commission',
		type: 'input',
		inputType: 'number',
	},

	//! REFERRAL
	referral_firm: {
		label: 'Referral',
		type: 'multi-contact',
	},
	referral_firm_company_name: {
		label: 'Payee Company Name',
		type: 'input',
	},
	referral_firm_name: {
		label: 'Payee First Name',
		type: 'input',
	},
	referral_firm_lastname: {
		label: 'Payee Last Name',
		type: 'input',
	},
	referral_firm_contact_dba_name: {
		label: 'Payee DBA',
		type: 'input',
	},
	referral_firm_address1: {
		label: 'Address',
		type: 'input',
	},
	referral_firm_city: {
		label: 'City',
		type: 'input',
	},
	referral_firm_zip: {
		label: 'Zip',
		type: 'input',
	},
	referral_firm_state: {
		label: 'State',
		type: 'state',
	},
	referral_firm_contact: {
		label: 'Contact',
		type: 'input',
	},
	referral_firm_main_phone: {
		label: 'Phone',
		type: 'input',
	},
	referral_firm_mobile_phone: {
		label: 'Mobile Phone',
		type: 'input',
	},
	referral_firm_work_phone: {
		label: 'Work Phone',
		type: 'input',
	},
	referral_firm_fax: {
		label: 'Fax',
		type: 'input',
	},
	referral_firm_email: {
		label: 'Email',
		type: 'input',
	},
	referral_firm_taxid: {
		label: 'Tax ID',
		type: 'input',
	},
	referral_firm_w9: {
		label: 'W9',
		type: 'radio',
		options: ['Yes', 'No'],
	},
	referral_firm_w9_upload: {
		label: 'Referral W9',
		type: 'file_upload',
		filetypes: ['pdf', 'png', 'jpg'],
	},
	referral_firm_fee_amount_choice: {
		label: 'Fee Amount',
		type: 'radio',
		options: ['Value', 'Percentage'],
	},
	referral_firm_fee_amount_percentage: {
		label: 'Fee Amount Percentage',
		type: 'input',
		suffix: '%',
	},
	referral_firm_fee_amount: {
		label: 'Fee Amount',
		type: 'input',
		dollar: true,
	},

	// PRE_SPLIT
	lessee_pre_splits: {
		label: 'Lessee Pre Splits',
		type: 'object_array'
	},
	lessor_pre_splits: {
		label: 'Lessor Pre Splits',
		type: 'object_array'
	},

	// COMMISSION DISTRIBUTION
	commission_distribution_lessee: {
		label: 'Lessee Commission Distribution',
		type: 'input'
	},
	commission_distribution_lessor: {
		label: 'Lessor Commission Distribution',
		type: 'input'
	},

	//! LESSORS
	lessor_contacts: {
		label: 'Lessor Contact(s)',
		type: 'multi-contact',
	},
	lessors: {
		label: 'Lessor',
		type: 'lessors',
	},

	//! BILLING INFORMATION
	billing_id: {
		label: 'Billing ID',
		type: 'input',
        validations: ['required'],
	},
	billing_company: {
		label: 'Billing Company',
		type: 'input',
        validations: ['required'],
	},
	billing_dba_name: {
		label: 'Billing Company DBA',
		type: 'input',
	},
	billing_attention: {
		label: 'Attention First Name',
		type: 'input',
        validations: ['required'],
	},
	billing_attention_lastname: {
		label: 'Attention Last Name',
		type: 'input',
        validations: ['required'],
	},
	billing_attention_dba_name: {
		label: 'Attention DBA',
		type: 'input',
	},
	billing_address1: {
		label: 'Address 1',
		type: 'input',
        validations: ['required'],
	},
	billing_address2: {
		label: 'Address 2',
		type: 'input',
	},
	billing_city: {
		label: 'City',
		type: 'input',
        validations: ['required'],
	},
	billing_state: {
		label: 'State',
		type: 'state',
		validations: ['required'],
	},
	billing_zip: {
		label: 'Zip',
		type: 'input',
        validations: ['required'],
	},
	billing_phone: {
		label: 'Phone',
		type: 'input',
        validations: ['required'],
	},
	billing_fax: {
		label: 'Fax',
		type: 'input',
	},
	billing_email: {
		label: 'Email',
		type: 'input',
        validations: ['required'],
	},

	//! COMMISSION SPLITS
	commission_splits_agent: {
		label: 'Lessor Commission Splits',
		type: 'object_array',
        validations: ['required'],
	},
	commission_splits_other: {
		label: 'Other Lessor Commission Splits',
		type: 'object_array',
        validations: ['required'],
	},
	commission_splits_agent2: {
		label: 'Lessee Commission Splits',
		type: 'object_array',
        validations: ['required'],
	},
	commission_splits_other2: {
		label: 'Other Lessee Commission Splits',
		type: 'object_array',
        validations: ['required'],
	},

	//! COPY OF INVOICE
	copy_of_invoice: {
		label: 'Copy of Invoice',
		options: [
			'illi Agents',
			'Outside Broker',
			'Lessor',
			'Lessee',
			'Management Company',
			'Other',
		],
		type: 'checkbox',
        validations: ['required'],
	},
	copy_of_invoice_other: {
		label: 'Other - (enter value)',
		type: 'input',
        validations: ['required'],
	},

	//! SIGN REQUEST FORM
	access_necessary: {
		label: 'Access Necessary',
		type: 'select',
		options: ['Yes', 'No'],
        validations: ['required'],
	},
	contact: {
		label: 'Contact',
		type: 'input',
        validations: ['required'],
	},
	order_type: {
		label: 'Order Type',
		type: 'select',
		options: ['Removal', 'Maintenance'],
        validations: ['required'],
	},
	maintenance_description: {
		label: 'Maintenance Description / Special Instructions',
		type: 'textarea',
        validations: ['required'],
	},
	sign_size: {
		label: 'Sign Size',
		type: 'input',
        validations: ['required'],
	},
	location: {
		label: 'Location',
		type: 'input',
        validations: ['required'],
	},
	rider: {
		label: 'Rider(s)',
		type: 'input',
        validations: ['required'],
	},
	sign_support_configuration: {
		label: 'Sign Support Configuration',
		type: 'select',
		options: ['S/F', 'D/F', 'V-SHAPE'],
        validations: ['required'],
	},
	sign_request_reason: {
		label: 'Reason for sign request or change',
		type: 'textarea',
        validations: ['required'],
	},

	//! PAYMENT SCHEDULE
	payment_schedule: {
		label: 'Payment Schedule',
		type: 'object_array',
        validations: ['required'],
	},

	//! COMMENTS AND SPECIAL INSTRUCTIONS
	comments_and_special_instructions: {
		label: 'Comments and Special instructions',
		type: 'textarea',
		validations: ['required'],
	},

	//! FINAL
	approved_by: {
		label: '',
		type: 'checkbox',
		options: ['Approved by AN', 'Approved by SS'],
        validations: ['required'],
	},
	approved_by_tn: {
		label: 'Approved by TN',
		type: 'signature',
        validations: ['required'],
	},
	notes: {
		label: 'Notes',
		type: 'textarea',
        validations: ['required'],
	},

	//! COMMISSION ADJUSTMENTS
	commission_adjustment: {
		label: 'Are there commission adjustments?',
		type: 'radio',
		options: ['Yes', 'No'],
        validations: ['required'],
	},
	commission_adjustment_details: {
		type: 'object_array',
        validations: ['required'],
	},

	//! DOCUMENTS
	documents: {
		label: 'Documents',
		type: 'object_array',
        validations: [],
	},
};

export const FIELDS = {
	// * Commission
	Commision: {
		// PROPERTY INFORMATION
		date: COMMON_FIELDS.date,
		project_name: COMMON_FIELDS.project_name,
		illi_number: {
			label: 'illi #',
			type: 'input',
			validations: ['required'],
			readonly: true
		},
		pm_number: {
			label: 'PM #',
			type: 'input',
            validations: ['required'],
			readonly: true,
		},
		project_address: COMMON_FIELDS.project_address,
		street1: COMMON_FIELDS.street1,
		spaces: COMMON_FIELDS.spaces,
		la_city_verification: COMMON_FIELDS.la_city_verification,
		listings_procured_by: COMMON_FIELDS.listings_procured_by,
		lead_source: COMMON_FIELDS.lead_source,
		deal_generated_by: COMMON_FIELDS.deal_generated_by,
		illi_represented_lessor: COMMON_FIELDS.illi_represented_lessor,
		illi_represented_lessor_agents:
			COMMON_FIELDS.illi_represented_lessor_agents,
		illi_represented_lessee: COMMON_FIELDS.illi_represented_lessee,
		illi_represented_lessee_agents:
			COMMON_FIELDS.illi_represented_lessee_agents,
		property_type: COMMON_FIELDS.property_type,

		commission_distribution_lessee: COMMON_FIELDS.commission_distribution_lessee,
		commission_distribution_lessor: COMMON_FIELDS.commission_distribution_lessor,

		// PREMISES INFORMATION
		premises_address: COMMON_FIELDS.premises_address,
		unit_number: COMMON_FIELDS.unit_number,
		state: COMMON_FIELDS.state,
		city: COMMON_FIELDS.city,
		zip: COMMON_FIELDS.zip,
		county: COMMON_FIELDS.county,
		loopnet_marketing_updated: COMMON_FIELDS.loopnet_marketing_updated,
		flat_fee_space: COMMON_FIELDS.flat_fee_space,

		// LEASE INFORMATION
		business_name: COMMON_FIELDS.business_name,
		type_of_business: COMMON_FIELDS.type_of_business,
		lease_start_date: COMMON_FIELDS.lease_start_date,
		lease_length: COMMON_FIELDS.lease_length,
		lease_start_date_estimated: COMMON_FIELDS.lease_start_date_estimated,
		lease_start_date_estimated_tbd:
			COMMON_FIELDS.lease_start_date_estimated_tbd,
		lease_end_date: COMMON_FIELDS.lease_end_date,
		rent_commencement: COMMON_FIELDS.rent_commencement,
		rent_commencement_estimated: COMMON_FIELDS.rent_commencement_estimated,
		rent_commencement_estimated_tbd:
			COMMON_FIELDS.rent_commencement_estimated_tbd,
		total_sf_leased: COMMON_FIELDS.total_sf_leased,
		starting_rent: COMMON_FIELDS.starting_rent,
		flat_fee_commission: COMMON_FIELDS.flat_fee_commission,
		flat_fee_commission_amount: COMMON_FIELDS.flat_fee_commission_amount,
		commission_based_off: COMMON_FIELDS.commission_based_off,
		rate_type: COMMON_FIELDS.rate_type,
		rate_type_cpi_percentage: COMMON_FIELDS.rate_type_cpi_percentage,
		contingency: COMMON_FIELDS.contingency,
		contingency_detail: COMMON_FIELDS.contingency_detail,
		lease_term: COMMON_FIELDS.lease_term,
		lease_type: COMMON_FIELDS.lease_type,
		date_lease_signed: COMMON_FIELDS.date_lease_signed,
		occupancy_date: COMMON_FIELDS.occupancy_date,
		occupancy_date_estimated: COMMON_FIELDS.occupancy_date_estimated,
		occupancy_date_estimated_tbd: COMMON_FIELDS.occupancy_date_estimated_tbd,
		option_renewal: COMMON_FIELDS.option_renewal,
		option_commission: COMMON_FIELDS.option_commission,
		abated_rent_ranges: COMMON_FIELDS.abated_rent_ranges,

		// LESSEES
		lessees_id: {
			label: 'Lessee ID',
			type: 'input',
		},
		lessees_name: {
			label: 'Lessee Name',
			type: 'input',
		},
		lessees_name: {
			label: 'Lessee Last Name',
			type: 'input',
		},
		lessees_address1: COMMON_FIELDS.lessees_address1,
		lessees_address2: COMMON_FIELDS.lessees_address2,
		lessees_city: COMMON_FIELDS.lessees_city,
		lessees_state: COMMON_FIELDS.lessees_state,
		lessees_zip: COMMON_FIELDS.lessees_zip,
		lessees_contact: COMMON_FIELDS.lessees_contact,
		lessees_main_phone: COMMON_FIELDS.lessees_main_phone,
		lessees_fax: COMMON_FIELDS.lessees_fax,
		lessees_email: COMMON_FIELDS.lessees_email,
		lessees_agency_disclosure: COMMON_FIELDS.lessees_agency_disclosure,
		lessees_llc_corp_verified: COMMON_FIELDS.lessees_llc_corp_verified,
		lessees_upload_sos: COMMON_FIELDS.lessees_upload_sos,
		lessees_spousal_consent: COMMON_FIELDS.lessees_spousal_consent,
		lessees_upload_spousal_id: COMMON_FIELDS.lessees_upload_spousal_id,
		lessees_guarantor: COMMON_FIELDS.lessees_guarantor,
		lessees_guarantor_name: COMMON_FIELDS.lessees_guarantor_name,
		lessees_upload_guarantor_id: COMMON_FIELDS.lessees_upload_guarantor_id,
		lessees_guarantor_spouse_name: COMMON_FIELDS.lessees_guarantor_spouse_name,
		lessees_can_we_reach_out_for_testimonial:
			COMMON_FIELDS.lessees_can_we_reach_out_for_testimonial,
		lessees_can_we_reach_out_for_testimonial_comment:
			COMMON_FIELDS.lessees_can_we_reach_out_for_testimonial_comment,
		lessees: COMMON_FIELDS.lessees,
		lessee_contacts: COMMON_FIELDS.lessee_contacts,

		// PRE SPLITS
		presplits: COMMON_FIELDS.presplits,

		// REFERRAL FOR LESSEE
		lessee_referral_firm: COMMON_FIELDS.lessee_referral_firm,
		lessee_referral_firm_company_name:
			COMMON_FIELDS.lessee_referral_firm_company_name,
		lessee_referral_firm_name: COMMON_FIELDS.lessee_referral_firm_name,
		lessee_referral_firm_lastname: COMMON_FIELDS.lessee_referral_firm_lastname,
		lessee_referral_firm_contact_dba_name: COMMON_FIELDS.lessee_referral_firm_contact_dba_name,
		lessee_referral_firm_address1: COMMON_FIELDS.lessee_referral_firm_address1,
		lessee_referral_firm_city: COMMON_FIELDS.lessee_referral_firm_city,
		lessee_referral_firm_state: COMMON_FIELDS.lessee_referral_firm_state,
		lessee_referral_firm_zip: COMMON_FIELDS.lessee_referral_firm_zip,
		lessee_referral_firm_contact: COMMON_FIELDS.lessee_referral_firm_contact,
		lessee_referral_firm_main_phone: COMMON_FIELDS.lessee_referral_firm_main_phone,
		lessee_referral_firm_work_phone: COMMON_FIELDS.lessee_referral_firm_work_phone,
		lessee_referral_firm_mobile_phone: COMMON_FIELDS.lessee_referral_firm_mobile_phone,
		lessee_referral_firm_fax: COMMON_FIELDS.lessee_referral_firm_fax,
		lessee_referral_firm_email: COMMON_FIELDS.lessee_referral_firm_email,
		lessee_referral_firm_taxid: COMMON_FIELDS.lessee_referral_firm_taxid,
		lessee_referral_firm_w9: COMMON_FIELDS.lessee_referral_firm_w9,
		lessee_referral_firm_w9_upload:
			COMMON_FIELDS.lessee_referral_firm_w9_upload,
		lessee_referral_firm_fee_amount_choice:
			COMMON_FIELDS.lessee_referral_firm_fee_amount_choice,
		lessee_referral_firm_fee_amount_percentage:
			COMMON_FIELDS.lessee_referral_firm_fee_amount_percentage,
		lessee_referral_firm_fee_amount:
			COMMON_FIELDS.lessee_referral_firm_fee_amount,

		// LESSEE OUTSIDE BROKER
		outside_broker: COMMON_FIELDS.outside_broker,
		outside_broker_company_name: COMMON_FIELDS.outside_broker_company_name,
		outside_broker_name: {
			label: 'Company Name / First Name',
			type: 'input',
		},
		outside_broker_lastname: COMMON_FIELDS.outside_broker_lastname,
		outside_broker_address1: COMMON_FIELDS.outside_broker_address1,
		outside_broker_address2: COMMON_FIELDS.outside_broker_address2,
		outside_broker_city: COMMON_FIELDS.outside_broker_city,
		outside_broker_state: COMMON_FIELDS.outside_broker_state,
		outside_broker_zip: COMMON_FIELDS.outside_broker_zip,
		outside_broker_contact: COMMON_FIELDS.outside_broker_contact,
		outside_broker_main_phone: COMMON_FIELDS.outside_broker_main_phone,
		outside_broker_fax: COMMON_FIELDS.outside_broker_fax,
		outside_broker_email: COMMON_FIELDS.outside_broker_email,
		outside_broker_taxid: COMMON_FIELDS.outside_broker_taxid,
		outside_broker_w9: COMMON_FIELDS.outside_broker_w9,
		outside_broker_w9_upload: COMMON_FIELDS.outside_broker_w9_upload,
		outside_broker_commission: COMMON_FIELDS.outside_broker_commission,

		// LESSOR - OUTSIDE BROKER
		lessor_outside_broker: {
			label: 'Outside Broker',
			type: 'multi-contact',
		},
		lessor_outside_broker_company_name: {
			label: 'Company Name',
			type: 'input',
		},
		lessor_outside_broker_name: {
			label: 'Company Name',
			type: 'input',
		},
		lessor_outside_broker_lastname:
			COMMON_FIELDS.lessor_outside_broker_lastname,
		lessor_outside_broker_address1:
			COMMON_FIELDS.lessor_outside_broker_address1,
		lessor_outside_broker_address2:
			COMMON_FIELDS.lessor_outside_broker_address2,
		lessor_outside_broker_city: COMMON_FIELDS.lessor_outside_broker_city,
		lessor_outside_broker_state: COMMON_FIELDS.lessor_outside_broker_state,
		lessor_outside_broker_zip: COMMON_FIELDS.lessor_outside_broker_zip,
		lessor_outside_broker_contact: COMMON_FIELDS.lessor_outside_broker_contact,
		lessor_outside_broker_main_phone:
			COMMON_FIELDS.lessor_outside_broker_main_phone,
		lessor_outside_broker_fax: COMMON_FIELDS.lessor_outside_broker_fax,
		lessor_outside_broker_email: COMMON_FIELDS.lessor_outside_broker_email,
		lessor_outside_broker_taxid: COMMON_FIELDS.lessor_outside_broker_taxid,
		lessor_outside_broker_w9: COMMON_FIELDS.lessor_outside_broker_w9,
		lessor_outside_broker_w9_upload:
			COMMON_FIELDS.lessor_outside_broker_w9_upload,
		lessor_outside_broker_commission:
			COMMON_FIELDS.lessor_outside_broker_commission,

		// REFERRAL
		referral_firm: COMMON_FIELDS.referral_firm,
		referral_firm_company_name: COMMON_FIELDS.referral_firm_company_name,
		referral_firm_name: COMMON_FIELDS.referral_firm_name,
		referral_firm_lastname: COMMON_FIELDS.referral_firm_lastname,
		referral_firm_contact_dba_name: COMMON_FIELDS.referral_firm_contact_dba_name,
		referral_firm_address1: COMMON_FIELDS.referral_firm_address1,
		referral_firm_city: COMMON_FIELDS.referral_firm_city,
		referral_firm_state: COMMON_FIELDS.referral_firm_state,
		referral_firm_zip: COMMON_FIELDS.referral_firm_zip,
		referral_firm_contact: COMMON_FIELDS.referral_firm_contact,
		referral_firm_main_phone: COMMON_FIELDS.referral_firm_main_phone,
		referral_firm_work_phone: COMMON_FIELDS.referral_firm_work_phone,
		referral_firm_mobile_phone: COMMON_FIELDS.referral_firm_mobile_phone,
		referral_firm_fax: COMMON_FIELDS.referral_firm_fax,
		referral_firm_email: COMMON_FIELDS.referral_firm_email,
		referral_firm_taxid: COMMON_FIELDS.referral_firm_taxid,
		referral_firm_w9: COMMON_FIELDS.referral_firm_w9,
		referral_firm_w9_upload: COMMON_FIELDS.referral_firm_w9_upload,
		referral_firm_fee_amount_choice:
			COMMON_FIELDS.referral_firm_fee_amount_choice,
		referral_firm_fee_amount_percentage:
			COMMON_FIELDS.referral_firm_fee_amount_percentage,
		referral_firm_fee_amount: COMMON_FIELDS.referral_firm_fee_amount,

		// LESSORS
		lessor_contacts: COMMON_FIELDS.lessor_contacts,
		lessors: COMMON_FIELDS.lessors,

		// BILLING INFORMATION
		billing_id: COMMON_FIELDS.billing_id,
		billing_company: COMMON_FIELDS.billing_company,
		billing_attention: COMMON_FIELDS.billing_attention,
		billing_attention_lastname: COMMON_FIELDS.billing_attention_lastname,
		billing_address1: COMMON_FIELDS.billing_address1,
		billing_address2: COMMON_FIELDS.billing_address2,
		billing_city: COMMON_FIELDS.billing_city,
		billing_state: COMMON_FIELDS.billing_state,
		billing_zip: COMMON_FIELDS.billing_zip,
		billing_phone: COMMON_FIELDS.billing_phone,
		billing_fax: COMMON_FIELDS.billing_fax,
		billing_email: COMMON_FIELDS.billing_email,

		// COMMISSION SPLITS
		commission_splits_agent: COMMON_FIELDS.commission_splits_agent,
		commission_splits_other: COMMON_FIELDS.commission_splits_other,
		commission_splits_agent2: COMMON_FIELDS.commission_splits_agent2,
		commission_splits_other2: COMMON_FIELDS.commission_splits_other2,

		// COPY OF INVOICE
		copy_of_invoice: COMMON_FIELDS.copy_of_invoice,
		copy_of_invoice_other: COMMON_FIELDS.copy_of_invoice_other,

		// PAYMENT SCHEDULE
		payment_schedule: COMMON_FIELDS.payment_schedule,

		// COMMENTS AND SPECIAL INSTRUCTIONS
		comments_and_special_instructions:
			COMMON_FIELDS.comments_and_special_instructions,

		// FINAL
		approved_by: COMMON_FIELDS.approved_by,
		approved_by_tn: COMMON_FIELDS.approved_by_tn,
		notes: COMMON_FIELDS.notes,

		// COMMISSION ADJUSTMENTS
		commission_adjustment: COMMON_FIELDS.commission_adjustment,
		commission_adjustment_details: COMMON_FIELDS.commission_adjustment_details,

		// DOCUMENTS
		documents: COMMON_FIELDS.documents
	},
	// * Consulting
	Consulting: {
		// PROPERTY INFORMATION
		date: COMMON_FIELDS.date,
		project_name: COMMON_FIELDS.project_name,
		illi_number: {
			label: 'illi #',
			type: 'input',
			validations: ['required'],
			readonly: true
		},
		pm_number: {
			label: 'PM #',
			type: 'input',
            validations: ['required'],
			readonly: true,
		},
		project_address: COMMON_FIELDS.project_address,
		street1: COMMON_FIELDS.street1,
		spaces: COMMON_FIELDS.spaces,
		la_city_verification: COMMON_FIELDS.la_city_verification,
		illi_represented_lessor: {
			label: 'illi Represented - Lessor/Seller',
			type: 'radio',
			validations: ['required'],
			options: ['Yes', 'No'],
		},
		illi_represented_lessor_agents: {
			label: 'illi Represented - Lessor/Seller - Agent(s)',
			type: 'multi-contact-agent',
			peopleType: 'agent',
			validations: ['required'],
		},
		illi_represented_lessee: {
			label: 'illi Represented - Lessee/Buyer',
			type: 'radio',
			options: ['Yes', 'No'],
			validations: ['required'],
		},
		illi_represented_lessee_agents: {
			label: 'illi Represented - Lessor/Buyer - Agent(s)',
			type: 'multi-contact-agent',
			peopleType: 'agent',
			validations: ['required'],
		},
		property_type: COMMON_FIELDS.property_type,
		type_of_lease: {
			label: 'Type of Lease',
			type: 'select',
			options: ['Lease', 'Sale', 'Opinion of Value', 'Other'],
            validations: ['required'],
		},
		type_of_lease_other: {
			label: 'Other',
			type: 'input',
            validations: ['required'],
		},
		can_deal_be_submitted_to_public_relations:
			COMMON_FIELDS.can_deal_be_submitted_to_public_relations,
		can_deal_be_submitted_to_public_relations_comment:
			COMMON_FIELDS.can_deal_be_submitted_to_public_relations_comment,

		commission_distribution_lessee: COMMON_FIELDS.commission_distribution_lessee,
		commission_distribution_lessor: COMMON_FIELDS.commission_distribution_lessor,

		// PREMISES INFORMATION
		premises_address: COMMON_FIELDS.premises_address,
		unit_number: COMMON_FIELDS.unit_number,
		city: COMMON_FIELDS.city,
		zip: COMMON_FIELDS.zip,
		county: COMMON_FIELDS.county,
		flat_fee_space: COMMON_FIELDS.flat_fee_space,

		// LEASE INFORMATION
		business_name: COMMON_FIELDS.business_name,
		type_of_business: COMMON_FIELDS.type_of_business,
		lease_start_date: COMMON_FIELDS.lease_start_date,
		lease_length: COMMON_FIELDS.lease_length,
		lease_start_date_estimated: {
			label: '',
			type: 'checkbox',
			options: ['Lease Start Date Estimated'],
            validations: ['required'],
		},
		lease_start_date_estimated_tbd:
			COMMON_FIELDS.lease_start_date_estimated_tbd,
		lease_end_date: COMMON_FIELDS.lease_end_date,
		lease_end_date_estimated: {
			label: '',
			type: 'checkbox',
			options: ['Lease End Date Estimated'],
            validations: ['required'],
		},
		rent_commencement: COMMON_FIELDS.rent_commencement,
		rent_commencement_estimated: COMMON_FIELDS.rent_commencement_estimated,
		rent_commencement_estimated_tbd:
			COMMON_FIELDS.rent_commencement_estimated_tbd,
		total_sf_leased: COMMON_FIELDS.total_sf_leased,
		starting_rent: COMMON_FIELDS.starting_rent,
		lease_term: COMMON_FIELDS.lease_term,
		lease_type: COMMON_FIELDS.lease_type,
		early_possession: COMMON_FIELDS.early_possession,
		early_possession_date: COMMON_FIELDS.early_possession_date,
		commission_based_off: COMMON_FIELDS.commission_based_off,
		rate_type: COMMON_FIELDS.rate_type,
		rate_type_cpi_percentage: COMMON_FIELDS.rate_type_cpi_percentage,
		contingency: COMMON_FIELDS.contingency,
		contingency_detail: COMMON_FIELDS.contingency_detail,
		date_lease_signed: COMMON_FIELDS.date_lease_signed,
		occupancy_date: COMMON_FIELDS.occupancy_date,
		occupancy_date_estimated: COMMON_FIELDS.occupancy_date_estimated,
		occupancy_date_estimated_tbd: COMMON_FIELDS.occupancy_date_estimated_tbd,
		option_renewal: COMMON_FIELDS.option_renewal,
		option_commission: COMMON_FIELDS.option_commission,
		abated_rent_ranges: COMMON_FIELDS.abated_rent_ranges,

		// LESSEES
		lessees_id: {
			label: 'Lessor/Buyer ID',
			type: 'input',
		},
		lessees_name: {
			label: 'Lessor/Buyer Name',
			type: 'input',
		},
		lessees_name: {
			label: 'Lessor/Buyer Last Name',
			type: 'input',
		},
		lessees_address1: COMMON_FIELDS.lessees_address1,
		lessees_address2: COMMON_FIELDS.lessees_address2,
		lessees_city: COMMON_FIELDS.lessees_city,
		lessees_state: COMMON_FIELDS.lessees_state,
		lessees_zip: COMMON_FIELDS.lessees_zip,
		lessees_contact: COMMON_FIELDS.lessees_contact,
		lessees_main_phone: COMMON_FIELDS.lessees_main_phone,
		lessees_fax: COMMON_FIELDS.lessees_fax,
		lessees_email: COMMON_FIELDS.lessees_email,
		lessees: {
			label: 'Lessor/Buyer',
			type: 'lessees',
		},
		lessee_contacts: {
			label: 'Lessor/Buyer Contacts',
			type: 'multi-contact',
		},

		// PRE SPLITS
		presplits: COMMON_FIELDS.presplits,

		// REFERRAL FOR LESSEE
		lessee_referral_firm: COMMON_FIELDS.lessee_referral_firm,
		lessee_referral_firm_name: COMMON_FIELDS.lessee_referral_firm_name,
		lessee_referral_firm_lastname: COMMON_FIELDS.lessee_referral_firm_lastname,
		lessee_referral_firm_contact_dba_name: COMMON_FIELDS.lessee_referral_firm_contact_dba_name,
		lessee_referral_firm_address1: COMMON_FIELDS.lessee_referral_firm_address1,
		lessee_referral_firm_city: COMMON_FIELDS.lessee_referral_firm_city,
		lessee_referral_firm_state: COMMON_FIELDS.lessee_referral_firm_state,
		lessee_referral_firm_zip: COMMON_FIELDS.lessee_referral_firm_zip,
		lessee_referral_firm_contact: COMMON_FIELDS.lessee_referral_firm_contact,
		lessee_referral_firm_main_phone:
			COMMON_FIELDS.lessee_referral_firm_main_phone,
		lessee_referral_firm_work_phone: COMMON_FIELDS.lessee_referral_firm_work_phone,
		lessee_referral_firm_mobile_phone: COMMON_FIELDS.lessee_referral_firm_mobile_phone,
		lessee_referral_firm_fax: COMMON_FIELDS.lessee_referral_firm_fax,
		lessee_referral_firm_email: COMMON_FIELDS.lessee_referral_firm_email,
		lessee_referral_firm_taxid: COMMON_FIELDS.lessee_referral_firm_taxid,
		lessee_referral_firm_w9: COMMON_FIELDS.lessee_referral_firm_w9,
		lessee_referral_firm_w9_upload:
			COMMON_FIELDS.lessee_referral_firm_w9_upload,
		lessee_referral_firm_fee_amount:
			COMMON_FIELDS.lessee_referral_firm_fee_amount,

		// LESSEE OUTSIDE BROKER
		outside_broker: COMMON_FIELDS.outside_broker,
		outside_broker_name: {
			label: 'Company Name / First Name',
			type: 'input',
		},
		outside_broker_lastname: COMMON_FIELDS.outside_broker_lastname,
		outside_broker_address1: COMMON_FIELDS.outside_broker_address1,
		outside_broker_address2: COMMON_FIELDS.outside_broker_address2,
		outside_broker_city: COMMON_FIELDS.outside_broker_city,
		outside_broker_state: COMMON_FIELDS.outside_broker_state,
		outside_broker_zip: COMMON_FIELDS.outside_broker_zip,
		outside_broker_contact: COMMON_FIELDS.outside_broker_contact,
		outside_broker_main_phone: COMMON_FIELDS.outside_broker_main_phone,
		outside_broker_fax: COMMON_FIELDS.outside_broker_fax,
		outside_broker_email: COMMON_FIELDS.outside_broker_email,
		outside_broker_taxid: COMMON_FIELDS.outside_broker_taxid,
		outside_broker_w9: COMMON_FIELDS.outside_broker_w9,
		outside_broker_w9_upload: COMMON_FIELDS.outside_broker_w9_upload,
		outside_broker_commission: COMMON_FIELDS.outside_broker_commission,

		// LESSOR - OUTSIDE BROKER
		lessor_outside_broker: {
			label: 'Outside Broker',
			type: 'multi-contact',
		},
		lessor_outside_broker_name: {
			label: 'Company Name',
			type: 'input',
		},
		lessor_outside_broker_lastname:
			COMMON_FIELDS.lessor_outside_broker_lastname,
		lessor_outside_broker_address1:
			COMMON_FIELDS.lessor_outside_broker_address1,
		lessor_outside_broker_address2:
			COMMON_FIELDS.lessor_outside_broker_address2,
		lessor_outside_broker_city: COMMON_FIELDS.lessor_outside_broker_city,
		lessor_outside_broker_state: COMMON_FIELDS.lessor_outside_broker_state,
		lessor_outside_broker_zip: COMMON_FIELDS.lessor_outside_broker_zip,
		lessor_outside_broker_contact: COMMON_FIELDS.lessor_outside_broker_contact,
		lessor_outside_broker_main_phone:
			COMMON_FIELDS.lessor_outside_broker_main_phone,
		lessor_outside_broker_fax: COMMON_FIELDS.lessor_outside_broker_fax,
		lessor_outside_broker_email: COMMON_FIELDS.lessor_outside_broker_email,
		lessor_outside_broker_taxid: COMMON_FIELDS.lessor_outside_broker_taxid,
		lessor_outside_broker_w9: COMMON_FIELDS.lessor_outside_broker_w9,
		lessor_outside_broker_w9_upload:
			COMMON_FIELDS.lessor_outside_broker_w9_upload,
		lessor_outside_broker_commission:
			COMMON_FIELDS.lessor_outside_broker_commission,

		// REFERRAL
		referral_firm: COMMON_FIELDS.referral_firm,
		referral_firm_name: COMMON_FIELDS.referral_firm_name,
		referral_firm_lastname: COMMON_FIELDS.referral_firm_lastname,
		referral_firm_contact_dba_name: COMMON_FIELDS.referral_firm_contact_dba_name,
		referral_firm_address1: COMMON_FIELDS.referral_firm_address1,
		referral_firm_city: COMMON_FIELDS.referral_firm_city,
		referral_firm_state: COMMON_FIELDS.referral_firm_state,
		referral_firm_zip: COMMON_FIELDS.referral_firm_zip,
		referral_firm_contact: COMMON_FIELDS.referral_firm_contact,
		referral_firm_main_phone: COMMON_FIELDS.referral_firm_main_phone,
		referral_firm_work_phone: COMMON_FIELDS.referral_firm_work_phone,
		referral_firm_mobile_phone: COMMON_FIELDS.referral_firm_mobile_phone,
		referral_firm_fax: COMMON_FIELDS.referral_firm_fax,
		referral_firm_email: COMMON_FIELDS.referral_firm_email,
		referral_firm_taxid: COMMON_FIELDS.referral_firm_taxid,
		referral_firm_w9: COMMON_FIELDS.referral_firm_w9,
		referral_firm_w9_upload: COMMON_FIELDS.referral_firm_w9_upload,
		referral_firm_fee_amount: COMMON_FIELDS.referral_firm_fee_amount,

		// LESSORS
		lessor_contacts: {
			label: 'Lessor/Seller Contacts',
			type: 'multi-contact',
		},
		lessors: {
			label: 'Lessor/Seller',
			type: 'lessors',
		},

		// BILLING INFORMATION
		billing_id: COMMON_FIELDS.billing_id,
		billing_company: COMMON_FIELDS.billing_company,
		billing_attention: COMMON_FIELDS.billing_attention,
		billing_attention_lastname: COMMON_FIELDS.billing_attention_lastname,
		billing_address1: COMMON_FIELDS.billing_address1,
		billing_address2: COMMON_FIELDS.billing_address2,
		billing_city: COMMON_FIELDS.billing_city,
		billing_state: COMMON_FIELDS.billing_state,
		billing_zip: COMMON_FIELDS.billing_zip,
		billing_phone: COMMON_FIELDS.billing_phone,
		billing_fax: COMMON_FIELDS.billing_fax,
		billing_email: COMMON_FIELDS.billing_email,
		billing_consulting_fee: {
			label: 'Consulting Commission',
			type: 'input',
			dollar: true,
            validations: ['required'],
		},
		flat_fee_commission_amount: COMMON_FIELDS.flat_fee_commission_amount,

		// COMMISSION SPLITS
		commission_splits_agent: {
			label: 'Lessor/Seller Commission Splits',
			type: 'object_array',
            validations: ['required'],
		},
		commission_splits_other: {
			label: 'Other Lessor/Seller Commission Splits',
			type: 'object_array',
            validations: ['required'],
		},
		commission_splits_agent2: {
			label: 'Lessor/Buyer Commission Splits',
			type: 'object_array',
            validations: ['required'],
		},
		commission_splits_other2: {
			label: 'Other Lessor/Buyer Commission Splits',
			type: 'object_array',
            validations: ['required'],
		},

		// COPY OF INVOICE
		copy_of_invoice: {
			label: 'Copy of Invoice',
			options: [
				'illi Agents',
				'Outside Broker',
				'Lessor/Seller',
				'Lessor/Buyer',
				'Management Company',
				'Other',
			],
			type: 'checkbox',
            validations: ['required'],
		},
		copy_of_invoice_other: COMMON_FIELDS.copy_of_invoice_other,

		// PAYMENT SCHEDULE
		payment_schedule: COMMON_FIELDS.payment_schedule,

		// COMMENTS AND SPECIAL INSTRUCTIONS
		comments_and_special_instructions:
			COMMON_FIELDS.comments_and_special_instructions,

		// FINAL
		approved_by: COMMON_FIELDS.approved_by,
		approved_by_tn: COMMON_FIELDS.approved_by_tn,
		notes: COMMON_FIELDS.notes,

		// COMMISSION ADJUSTMENTS
		commission_adjustment: COMMON_FIELDS.commission_adjustment,
		commission_adjustment_details: COMMON_FIELDS.commission_adjustment_details,

		// DOCUMENTS
		documents: COMMON_FIELDS.documents,

		is_consulting: {
			type: 'input'
		}
	},
	// * Lease
	Lease: {
		// PROPERTY INFORMATION
		date: COMMON_FIELDS.date,
		project_name: COMMON_FIELDS.project_name,
		illi_number: {
			label: 'illi #',
			type: 'input',
			validations: ['required'],
			readonly: true,
		},
		pm_number: {
			label: 'PM #',
			type: 'input',
            validations: ['required'],
			readonly: true,
		},
		project_address: COMMON_FIELDS.project_address,
		street1: COMMON_FIELDS.street1,
		spaces: COMMON_FIELDS.spaces,
		la_city_verification: COMMON_FIELDS.la_city_verification,
		listings_procured_by: COMMON_FIELDS.listings_procured_by,
		lead_source: COMMON_FIELDS.lead_source,
		deal_generated_by: COMMON_FIELDS.deal_generated_by,
		illi_represented_lessor: COMMON_FIELDS.illi_represented_lessor,
		illi_represented_lessor_agents:
			COMMON_FIELDS.illi_represented_lessor_agents,
		illi_represented_lessee: COMMON_FIELDS.illi_represented_lessee,
		illi_represented_lessee_agents:
			COMMON_FIELDS.illi_represented_lessee_agents,
		property_type: COMMON_FIELDS.property_type,
		type_of_lease: {
			label: 'Type of Lease',
			type: 'select',
			options: ['New Lease', 'Renewal'],
            validations: ['required'],
		},
		can_deal_be_submitted_to_public_relations:
			COMMON_FIELDS.can_deal_be_submitted_to_public_relations,
		can_deal_be_submitted_to_public_relations_comment:
			COMMON_FIELDS.can_deal_be_submitted_to_public_relations_comment,

		commission_distribution_lessee: COMMON_FIELDS.commission_distribution_lessee,
		commission_distribution_lessor: COMMON_FIELDS.commission_distribution_lessor,

		// PREMISES INFORMATION
		premises_address: COMMON_FIELDS.premises_address,
		unit_number: COMMON_FIELDS.unit_number,
		state: COMMON_FIELDS.state,
		city: COMMON_FIELDS.city,
		zip: COMMON_FIELDS.zip,
		county: COMMON_FIELDS.county,
		window_signs_returned: COMMON_FIELDS.window_signs_returned,
		window_sign_number: COMMON_FIELDS.window_sign_number,
		sign_removal_requested: COMMON_FIELDS.sign_removal_requested,
		loopnet_marketing_updated: COMMON_FIELDS.loopnet_marketing_updated,
		flat_fee_space: COMMON_FIELDS.flat_fee_space,

		// LEASE INFORMATION
		business_name: COMMON_FIELDS.business_name,
		type_of_business: COMMON_FIELDS.type_of_business,
		lease_start_date: COMMON_FIELDS.lease_start_date,
		lease_length: COMMON_FIELDS.lease_length,
		lease_start_date_estimated: COMMON_FIELDS.lease_start_date_estimated,
		lease_start_date_estimated_tbd:
			COMMON_FIELDS.lease_start_date_estimated_tbd,
		lease_end_date: COMMON_FIELDS.lease_end_date,
		rent_commencement: COMMON_FIELDS.rent_commencement,
		rent_commencement_estimated: COMMON_FIELDS.rent_commencement_estimated,
		rent_commencement_estimated_tbd:
			COMMON_FIELDS.rent_commencement_estimated_tbd,
		total_sf_leased: COMMON_FIELDS.total_sf_leased,
		// starting_rent: COMMON_FIELDS.starting_rent,
		lease_term: COMMON_FIELDS.lease_term,
		lease_type: COMMON_FIELDS.lease_type,
		flat_fee_commission: COMMON_FIELDS.flat_fee_commission,
		flat_fee_commission_amount: COMMON_FIELDS.flat_fee_commission_amount,
		commission_based_off: COMMON_FIELDS.commission_based_off,
		rate_type: COMMON_FIELDS.rate_type,
		rate_type_cpi_percentage: COMMON_FIELDS.rate_type_cpi_percentage,
		early_possession: COMMON_FIELDS.early_possession,
		early_possession_date: COMMON_FIELDS.early_possession_date,
		contingency: COMMON_FIELDS.contingency,
		contingency_detail: COMMON_FIELDS.contingency_detail,
		date_lease_signed: COMMON_FIELDS.date_lease_signed,
		occupancy_date: COMMON_FIELDS.occupancy_date,
		occupancy_date_estimated: COMMON_FIELDS.occupancy_date_estimated,
		occupancy_date_estimated_tbd: COMMON_FIELDS.occupancy_date_estimated_tbd,
		option_renewal: COMMON_FIELDS.option_renewal,
		option_commission: COMMON_FIELDS.option_commission,
		abated_rent_ranges: COMMON_FIELDS.abated_rent_ranges,

		// LESSEES
		lessees_id: {
			label: 'Lessee ID',
			type: 'input',
		},
		lessees_name: {
			label: 'Lessee Name',
			type: 'input',
		},
		lessees_name: {
			label: 'Lessee Last Name',
			type: 'input',
		},
		lessees_address1: COMMON_FIELDS.lessees_address1,
		lessees_address2: COMMON_FIELDS.lessees_address2,
		lessees_city: COMMON_FIELDS.lessees_city,
		lessees_state: COMMON_FIELDS.lessees_state,
		lessees_zip: COMMON_FIELDS.lessees_zip,
		lessees_contact: COMMON_FIELDS.lessees_contact,
		lessees_main_phone: COMMON_FIELDS.lessees_main_phone,
		lessees_fax: COMMON_FIELDS.lessees_fax,
		lessees_email: COMMON_FIELDS.lessees_email,
		lessees_agency_disclosure: COMMON_FIELDS.lessees_agency_disclosure,
		lessees_llc_corp_verified: COMMON_FIELDS.lessees_llc_corp_verified,
		lessees_upload_sos: COMMON_FIELDS.lessees_upload_sos,
		lessees_spousal_consent: COMMON_FIELDS.lessees_spousal_consent,
		lessees_upload_spousal_id: COMMON_FIELDS.lessees_upload_spousal_id,
		lessees_guarantor: COMMON_FIELDS.lessees_guarantor,
		lessees_guarantor_name: COMMON_FIELDS.lessees_guarantor_name,
		lessees_upload_guarantor_id: COMMON_FIELDS.lessees_upload_guarantor_id,
		lessees_guarantor_spouse_name: COMMON_FIELDS.lessees_guarantor_spouse_name,
		lessees_can_we_reach_out_for_testimonial:
			COMMON_FIELDS.lessees_can_we_reach_out_for_testimonial,
		lessees_can_we_reach_out_for_testimonial_comment:
			COMMON_FIELDS.lessees_can_we_reach_out_for_testimonial_comment,
		lessees: COMMON_FIELDS.lessees,
		lessee_contacts: {
			label: 'Lessee Contact(s)',
			type: 'multi-contact',
		},

		// PRE SPLITS
		presplits: COMMON_FIELDS.presplits,

		// REFERRAL FOR LESSEE
		lessee_referral_firm: COMMON_FIELDS.lessee_referral_firm,
		lessee_referral_firm_company_name:
			COMMON_FIELDS.lessee_referral_firm_company_name,
		lessee_referral_firm_name: COMMON_FIELDS.lessee_referral_firm_name,
		lessee_referral_firm_lastname: COMMON_FIELDS.lessee_referral_firm_lastname,
		lessee_referral_firm_contact_dba_name: COMMON_FIELDS.lessee_referral_firm_contact_dba_name,
		lessee_referral_firm_address1: COMMON_FIELDS.lessee_referral_firm_address1,
		lessee_referral_firm_city: COMMON_FIELDS.lessee_referral_firm_city,
		lessee_referral_firm_state: COMMON_FIELDS.lessee_referral_firm_state,
		lessee_referral_firm_zip: COMMON_FIELDS.lessee_referral_firm_zip,
		lessee_referral_firm_contact: COMMON_FIELDS.lessee_referral_firm_contact,
		lessee_referral_firm_main_phone:
			COMMON_FIELDS.lessee_referral_firm_main_phone,
		lessee_referral_firm_work_phone: COMMON_FIELDS.lessee_referral_firm_work_phone,
		lessee_referral_firm_mobile_phone: COMMON_FIELDS.lessee_referral_firm_mobile_phone,
		lessee_referral_firm_work_phone: COMMON_FIELDS.lessee_referral_firm_work_phone,
		lessee_referral_firm_mobile_phone: COMMON_FIELDS.lessee_referral_firm_mobile_phone,
		lessee_referral_firm_fax: COMMON_FIELDS.lessee_referral_firm_fax,
		lessee_referral_firm_email: COMMON_FIELDS.lessee_referral_firm_email,
		lessee_referral_firm_taxid: COMMON_FIELDS.lessee_referral_firm_taxid,
		lessee_referral_firm_w9: COMMON_FIELDS.lessee_referral_firm_w9,
		lessee_referral_firm_w9_upload:
			COMMON_FIELDS.lessee_referral_firm_w9_upload,
		lessee_referral_firm_fee_amount_choice:
			COMMON_FIELDS.lessee_referral_firm_fee_amount_choice,
		lessee_referral_firm_fee_amount_percentage:
			COMMON_FIELDS.lessee_referral_firm_fee_amount_percentage,
		lessee_referral_firm_fee_amount: {
			label: 'Fee Amount Value',
			dollar: true,
			type: 'input',
		},

		// LESSEE OUTSIDE BROKER
		outside_broker: COMMON_FIELDS.outside_broker,
		outside_broker_company_name: COMMON_FIELDS.outside_broker_company_name,
		outside_broker_name: {
			label: 'First Name',
			type: 'input',
		},
		outside_broker_lastname: COMMON_FIELDS.outside_broker_lastname,
		outside_broker_address1: COMMON_FIELDS.outside_broker_address1,
		outside_broker_address2: COMMON_FIELDS.outside_broker_address2,
		outside_broker_city: COMMON_FIELDS.outside_broker_city,
		outside_broker_state: COMMON_FIELDS.outside_broker_state,
		outside_broker_zip: COMMON_FIELDS.outside_broker_zip,
		outside_broker_contact: COMMON_FIELDS.outside_broker_contact,
		outside_broker_main_phone: COMMON_FIELDS.outside_broker_main_phone,
		outside_broker_fax: COMMON_FIELDS.outside_broker_fax,
		outside_broker_email: COMMON_FIELDS.outside_broker_email,
		outside_broker_taxid: COMMON_FIELDS.outside_broker_taxid,
		outside_broker_w9: COMMON_FIELDS.outside_broker_w9,
		outside_broker_w9_upload: COMMON_FIELDS.outside_broker_w9_upload,
		outside_broker_commission: COMMON_FIELDS.outside_broker_commission,

		// LESSOR - OUTSIDE BROKER
		lessor_outside_broker: {
			label: 'Outside Broker',
			type: 'multi-contact',
		},
		lessor_outside_broker_company_name: {
			label: 'Company Name',
			type: 'input',
		},
		lessor_outside_broker_name: {
			label: 'First Name',
			type: 'input',
		},
		lessor_outside_broker_lastname:
			COMMON_FIELDS.lessor_outside_broker_lastname,
		lessor_outside_broker_address1:
			COMMON_FIELDS.lessor_outside_broker_address1,
		lessor_outside_broker_address2:
			COMMON_FIELDS.lessor_outside_broker_address2,
		lessor_outside_broker_city: COMMON_FIELDS.lessor_outside_broker_city,
		lessor_outside_broker_state: COMMON_FIELDS.lessor_outside_broker_state,
		lessor_outside_broker_zip: COMMON_FIELDS.lessor_outside_broker_zip,
		lessor_outside_broker_contact: COMMON_FIELDS.lessor_outside_broker_contact,
		lessor_outside_broker_main_phone:
			COMMON_FIELDS.lessor_outside_broker_main_phone,
		lessor_outside_broker_fax: COMMON_FIELDS.lessor_outside_broker_fax,
		lessor_outside_broker_email: COMMON_FIELDS.lessor_outside_broker_email,
		lessor_outside_broker_taxid: COMMON_FIELDS.lessor_outside_broker_taxid,
		lessor_outside_broker_w9: COMMON_FIELDS.lessor_outside_broker_w9,
		lessor_outside_broker_w9_upload:
			COMMON_FIELDS.lessor_outside_broker_w9_upload,
		lessor_outside_broker_commission:
			COMMON_FIELDS.lessor_outside_broker_commission,

		// REFERRAL
		referral_firm: COMMON_FIELDS.referral_firm,
		referral_firm_company_name: COMMON_FIELDS.referral_firm_company_name,
		referral_firm_name: COMMON_FIELDS.referral_firm_name,
		referral_firm_lastname: COMMON_FIELDS.referral_firm_lastname,
		referral_firm_contact_dba_name: COMMON_FIELDS.referral_firm_contact_dba_name,
		referral_firm_address1: COMMON_FIELDS.referral_firm_address1,
		referral_firm_city: COMMON_FIELDS.referral_firm_city,
		referral_firm_state: COMMON_FIELDS.referral_firm_state,
		referral_firm_zip: COMMON_FIELDS.referral_firm_zip,
		referral_firm_contact: COMMON_FIELDS.referral_firm_contact,
		referral_firm_main_phone: COMMON_FIELDS.referral_firm_main_phone,
		referral_firm_work_phone: COMMON_FIELDS.referral_firm_work_phone,
		referral_firm_mobile_phone: COMMON_FIELDS.referral_firm_mobile_phone,
		referral_firm_fax: COMMON_FIELDS.referral_firm_fax,
		referral_firm_email: COMMON_FIELDS.referral_firm_email,
		referral_firm_taxid: COMMON_FIELDS.referral_firm_taxid,
		referral_firm_w9: COMMON_FIELDS.referral_firm_w9,
		referral_firm_w9_upload: COMMON_FIELDS.referral_firm_w9_upload,
		referral_firm_fee_amount_choice:
			COMMON_FIELDS.referral_firm_fee_amount_choice,
		referral_firm_fee_amount_percentage:
			COMMON_FIELDS.referral_firm_fee_amount_percentage,
		referral_firm_fee_amount: COMMON_FIELDS.referral_firm_fee_amount,

		// LESSORS
		lessor_contacts: COMMON_FIELDS.lessor_contacts,
		lessors: COMMON_FIELDS.lessors,

		// BILLING INFORMATION
		billing_id: COMMON_FIELDS.billing_id,
		billing_company: COMMON_FIELDS.billing_company,
		billing_attention: COMMON_FIELDS.billing_attention,
		billing_attention_lastname: COMMON_FIELDS.billing_attention_lastname,
		billing_address1: COMMON_FIELDS.billing_address1,
		billing_address2: COMMON_FIELDS.billing_address2,
		billing_city: COMMON_FIELDS.billing_city,
		billing_state: COMMON_FIELDS.billing_state,
		billing_zip: COMMON_FIELDS.billing_zip,
		billing_phone: COMMON_FIELDS.billing_phone,
		billing_fax: COMMON_FIELDS.billing_fax,
		billing_email: COMMON_FIELDS.billing_email,

		// COMMISSION SPLITS
		commission_splits_agent: COMMON_FIELDS.commission_splits_agent,
		commission_splits_other: COMMON_FIELDS.commission_splits_other,
		commission_splits_agent2: COMMON_FIELDS.commission_splits_agent2,
		commission_splits_other2: COMMON_FIELDS.commission_splits_other2,

		// COPY OF INVOICE
		copy_of_invoice: COMMON_FIELDS.copy_of_invoice,
		copy_of_invoice_other: COMMON_FIELDS.copy_of_invoice_other,

		// PAYMENT SCHEDULE
		payment_schedule: COMMON_FIELDS.payment_schedule,

		// COMMENTS AND SPECIAL INSTRUCTIONS
		comments_and_special_instructions:
			COMMON_FIELDS.comments_and_special_instructions,

		// FINAL
		approved_by: COMMON_FIELDS.approved_by,
		approved_by_tn: COMMON_FIELDS.approved_by_tn,
		notes: COMMON_FIELDS.notes,

		// SIGN REQUEST FORM
		access_necessary: COMMON_FIELDS.access_necessary,
		contact: COMMON_FIELDS.contact,
		order_type: COMMON_FIELDS.order_type,
		maintenance_description: COMMON_FIELDS.maintenance_description,
		sign_size: COMMON_FIELDS.sign_size,
		location: COMMON_FIELDS.location,
		rider: COMMON_FIELDS.rider,
		sign_support_configuration: COMMON_FIELDS.sign_support_configuration,
		sign_request_reason: COMMON_FIELDS.sign_request_reason,

		// COMMISSION ADJUSTMENTS
		commission_adjustment: COMMON_FIELDS.commission_adjustment,
		commission_adjustment_details: COMMON_FIELDS.commission_adjustment_details,

		// DOCUMENTS
		documents: COMMON_FIELDS.documents
	},
	// * LeaseAssignmentFee
	LeaseAssignmentFee: {
		// PROPERTY INFORMATION
		date: COMMON_FIELDS.date,
		project_name: COMMON_FIELDS.project_name,
		illi_number: {
			label: 'illi #',
			type: 'input',
			validations: ['required'],
			readonly: true,
		},
		pm_number: {
			label: 'PM #',
			type: 'input',
			validations: ['no_letters', 'required'],
		},
		project_address: COMMON_FIELDS.project_address,
		street1: COMMON_FIELDS.street1,
		spaces: COMMON_FIELDS.spaces,
		la_city_verification: COMMON_FIELDS.la_city_verification,
		listings_procured_by: COMMON_FIELDS.listings_procured_by,
		lead_source: COMMON_FIELDS.lead_source,
		deal_generated_by: COMMON_FIELDS.deal_generated_by,
		illi_represented_assignor: {
			label: 'illi Represented - Assignor',
			type: 'radio',
			options: ['Yes', 'No'],
            validations: ['required'],
		},
		illi_represented_assignor_agents: {
			label: 'illi Represented - Assignor - Agent(s)',
			type: 'multi-contact-agent',
			peopleType: 'agent',
            validations: ['required'],
		},
		illi_represented_lessor: COMMON_FIELDS.illi_represented_lessor,
		illi_represented_lessor_agents:
			COMMON_FIELDS.illi_represented_lessor_agents,
		illi_represented_lessee: {
			label: 'illi Represented - Assignee',
			type: 'radio',
			options: ['Yes', 'No'],
            validations: ['required'],
		},
		illi_represented_lessee_agents: {
			label: 'illi Represented - Assignee - Agent(s)',
			type: 'multi-contact-agent',
			peopleType: 'agent',
            validations: ['required'],
		},
		property_type: COMMON_FIELDS.property_type,
		type_of_lease: {
			label: 'Type of Lease',
			type: 'select',
			options: ['New Lease', 'Renewal'],
            validations: ['required'],
		},
		can_deal_be_submitted_to_public_relations:
			COMMON_FIELDS.can_deal_be_submitted_to_public_relations,
		can_deal_be_submitted_to_public_relations_comment:
			COMMON_FIELDS.can_deal_be_submitted_to_public_relations_comment,

		commission_distribution_lessee: COMMON_FIELDS.commission_distribution_lessee,
		commission_distribution_lessor: COMMON_FIELDS.commission_distribution_lessor,

		// PREMISES INFORMATION
		premises_address: COMMON_FIELDS.premises_address,
		unit_number: COMMON_FIELDS.unit_number,
		state: COMMON_FIELDS.state,
		city: COMMON_FIELDS.city,
		zip: COMMON_FIELDS.zip,
		county: COMMON_FIELDS.county,
		window_signs_returned: COMMON_FIELDS.window_signs_returned,
		window_sign_number: COMMON_FIELDS.window_sign_number,
		sign_removal_requested: COMMON_FIELDS.sign_removal_requested,
		loopnet_marketing_updated: COMMON_FIELDS.loopnet_marketing_updated,
		flat_fee_space: COMMON_FIELDS.flat_fee_space,

		// LEASE INFORMATION
		business_name: COMMON_FIELDS.business_name,
		type_of_business: COMMON_FIELDS.type_of_business,
		lease_start_date: COMMON_FIELDS.lease_start_date,
		lease_length: COMMON_FIELDS.lease_length,
		lease_start_date_estimated: COMMON_FIELDS.lease_start_date_estimated,
		lease_start_date_estimated_tbd:
			COMMON_FIELDS.lease_start_date_estimated_tbd,
		lease_end_date: COMMON_FIELDS.lease_end_date,
		rent_commencement: COMMON_FIELDS.rent_commencement,
		rent_commencement_estimated: COMMON_FIELDS.rent_commencement_estimated,
		rent_commencement_estimated_tbd:
			COMMON_FIELDS.rent_commencement_estimated_tbd,
		total_sf_leased: COMMON_FIELDS.total_sf_leased,
		starting_rent: COMMON_FIELDS.starting_rent,
		lease_term: COMMON_FIELDS.lease_term,
		lease_type: COMMON_FIELDS.lease_type,
		early_possession: COMMON_FIELDS.early_possession,
		early_possession_date: COMMON_FIELDS.early_possession_date,
		commission_based_off: COMMON_FIELDS.commission_based_off,
		rate_type: COMMON_FIELDS.rate_type,
		rate_type_cpi_percentage: COMMON_FIELDS.rate_type_cpi_percentage,
		contingency: COMMON_FIELDS.contingency,
		contingency_detail: COMMON_FIELDS.contingency_detail,
		date_lease_signed: COMMON_FIELDS.date_lease_signed,
		occupancy_date: COMMON_FIELDS.occupancy_date,
		occupancy_date_estimated: COMMON_FIELDS.occupancy_date_estimated,
		occupancy_date_estimated_tbd: COMMON_FIELDS.occupancy_date_estimated_tbd,
		option_renewal: COMMON_FIELDS.option_renewal,
		option_commission: COMMON_FIELDS.option_commission,
		abated_rent_ranges: COMMON_FIELDS.abated_rent_ranges,

		// LESSEES
		lessees_id: {
			label: 'Assignee ID',
			type: 'input',
		},
		lessees_name: {
			label: 'Assignee Name',
			type: 'input',
		},
		lessees_name: {
			label: 'Assignee Last Name',
			type: 'input',
		},
		lessees_address1: COMMON_FIELDS.lessees_address1,
		lessees_address2: COMMON_FIELDS.lessees_address2,
		lessees_city: COMMON_FIELDS.lessees_city,
		lessees_state: COMMON_FIELDS.lessees_state,
		lessees_zip: COMMON_FIELDS.lessees_zip,
		lessees_contact: COMMON_FIELDS.lessees_contact,
		lessees_main_phone: COMMON_FIELDS.lessees_main_phone,
		lessees_fax: COMMON_FIELDS.lessees_fax,
		lessees_email: COMMON_FIELDS.lessees_email,
		lessees_agency_disclosure: COMMON_FIELDS.lessees_agency_disclosure,
		lessees_llc_corp_verified: COMMON_FIELDS.lessees_llc_corp_verified,
		lessees_upload_sos: COMMON_FIELDS.lessees_upload_sos,
		lessees_spousal_consent: COMMON_FIELDS.lessees_spousal_consent,
		lessees_upload_spousal_id: COMMON_FIELDS.lessees_upload_spousal_id,
		lessees_guarantor: COMMON_FIELDS.lessees_guarantor,
		lessees_guarantor_name: COMMON_FIELDS.lessees_guarantor_name,
		lessees_upload_guarantor_id: COMMON_FIELDS.lessees_upload_guarantor_id,
		lessees_guarantor_spouse_name: COMMON_FIELDS.lessees_guarantor_spouse_name,
		lessees_can_we_reach_out_for_testimonial:
			COMMON_FIELDS.lessees_can_we_reach_out_for_testimonial,
		lessees_can_we_reach_out_for_testimonial_comment:
			COMMON_FIELDS.lessees_can_we_reach_out_for_testimonial_comment,
		lessees: {
			label: 'Assignee',
			type: 'lessees',
		},
		lessee_contacts: {
			label: 'Assignee Contact(s)',
			type: 'multi-contact',
		},

		// PRE SPLITS
		presplits: COMMON_FIELDS.presplits,

		// REFERRAL FOR LESSEE
		lessee_referral_firm: COMMON_FIELDS.lessee_referral_firm,
		lessee_referral_firm_company_name:
			COMMON_FIELDS.lessee_referral_firm_company_name,
		lessee_referral_firm_name: COMMON_FIELDS.lessee_referral_firm_name,
		lessee_referral_firm_lastname: COMMON_FIELDS.lessee_referral_firm_lastname,
		lessee_referral_firm_address1: COMMON_FIELDS.lessee_referral_firm_address1,
		lessee_referral_firm_city: COMMON_FIELDS.lessee_referral_firm_city,
		lessee_referral_firm_state: COMMON_FIELDS.lessee_referral_firm_state,
		lessee_referral_firm_zip: COMMON_FIELDS.lessee_referral_firm_zip,
		lessee_referral_firm_contact: COMMON_FIELDS.lessee_referral_firm_contact,
		lessee_referral_firm_main_phone:
			COMMON_FIELDS.lessee_referral_firm_main_phone,
		lessee_referral_firm_work_phone: COMMON_FIELDS.lessee_referral_firm_work_phone,
		lessee_referral_firm_mobile_phone: COMMON_FIELDS.lessee_referral_firm_mobile_phone,
		lessee_referral_firm_fax: COMMON_FIELDS.lessee_referral_firm_fax,
		lessee_referral_firm_email: COMMON_FIELDS.lessee_referral_firm_email,
		lessee_referral_firm_taxid: COMMON_FIELDS.lessee_referral_firm_taxid,
		lessee_referral_firm_w9: COMMON_FIELDS.lessee_referral_firm_w9,
		lessee_referral_firm_w9_upload:
			COMMON_FIELDS.lessee_referral_firm_w9_upload,
		lessee_referral_firm_fee_amount_choice:
			COMMON_FIELDS.lessee_referral_firm_fee_amount_choice,
		lessee_referral_firm_fee_amount_percentage:
			COMMON_FIELDS.lessee_referral_firm_fee_amount_percentage,
		lessee_referral_firm_fee_amount: {
			label: 'Fee Amount Value',
			dollar: true,
			type: 'input',
		},

		// LESSEE OUTSIDE BROKER
		outside_broker: COMMON_FIELDS.outside_broker,
		outside_broker_company_name: COMMON_FIELDS.outside_broker_company_name,
		outside_broker_name: {
			label: 'First Name',
			type: 'input',
		},
		outside_broker_lastname: COMMON_FIELDS.outside_broker_lastname,
		outside_broker_address1: COMMON_FIELDS.outside_broker_address1,
		outside_broker_address2: COMMON_FIELDS.outside_broker_address2,
		outside_broker_city: COMMON_FIELDS.outside_broker_city,
		outside_broker_state: COMMON_FIELDS.outside_broker_state,
		outside_broker_zip: COMMON_FIELDS.outside_broker_zip,
		outside_broker_contact: COMMON_FIELDS.outside_broker_contact,
		outside_broker_main_phone: COMMON_FIELDS.outside_broker_main_phone,
		outside_broker_fax: COMMON_FIELDS.outside_broker_fax,
		outside_broker_email: COMMON_FIELDS.outside_broker_email,
		outside_broker_taxid: COMMON_FIELDS.outside_broker_taxid,
		outside_broker_w9: COMMON_FIELDS.outside_broker_w9,
		outside_broker_w9_upload: COMMON_FIELDS.outside_broker_w9_upload,
		outside_broker_commission: COMMON_FIELDS.outside_broker_commission,

		// ASSIGNOR
		assignors_id: {
			label: 'Assignor ID',
			type: 'input',
		},
		assignors_name: {
			label: 'Assignor Name',
			type: 'input',
		},
		assignors_name: {
			label: 'Assignor Last Name',
			type: 'input',
		},
		assignors_address1: {
			label: 'Address',
			type: 'input',
		},
		assignors_address2: {
			label: 'Address 2',
			type: 'input',
		},
		assignors_city: {
			label: 'City',
			type: 'input',
		},
		assignors_state: {
			label: 'State',
			type: 'state',
		},
		assignors_zip: {
			label: 'Zip',
			type: 'input',
		},
		assignors_contact: {
			label: 'Contact',
			type: 'input',
		},
		assignors_main_phone: {
			label: 'Phone',
			type: 'input',
		},
		assignors_fax: {
			label: 'Fax',
			type: 'input',
		},
		assignors_email: {
			label: 'Email',
			type: 'input',
		},
		assignors_agency_disclosure: {
			label: 'Agency Disclosure',
			type: 'radio',
			options: ['Yes', 'No'],
		},
		assignors_llc_corp_verified: {
			label: 'LLC / Corp Verified',
			type: 'radio',
			options: ['Yes', 'N/A'],
		},
		assignors_upload_sos: {
			label: 'S.O.S.',
			type: 'file_upload',
			filetypes: ['pdf', 'png', 'jpg'],
		},
		assignors_spousal_consent: {
			label: 'Spousal Consent',
			type: 'radio',
			options: ['Yes', 'N/A'],
		},
		assignors_upload_spousal_id: {
			label: 'Spousal ID',
			type: 'file_upload',
			filetypes: ['pdf', 'png', 'jpg'],
		},
		assignors_guarantor: {
			label: 'Guarantor',
			type: 'radio',
			options: ['Yes', 'N/A'],
		},
		assignors_guarantor_name: {
			label: 'Guarantor Name',
			type: 'input',
		},
		assignors_upload_guarantor_id: {
			label: 'Guarantor ID',
			type: 'file_upload',
			filetypes: ['pdf', 'png', 'jpg'],
		},
		assignors_guarantor_spouse_name: {
			label: 'Guarantor Spouse Name',
			type: 'input',
		},
		assignors_can_we_reach_out_for_testimonial: {
			label: 'Can we reach out for testimonial ?',
			type: 'radio',
			options: ['Yes', 'No'],
		},
		assignors_can_we_reach_out_for_testimonial_comment: {
			label: 'Comment',
			type: 'input',
		},
		assignor_contacts: {
			label: 'Assignor Contact(s)',
			type: 'multi-contact',
		},

		// REFERRAL FOR ASSIGNOR
		assignor_referral_firm: {
			label: 'Referral',
			type: 'multi-contact',
		},
		assignor_referral_firm_company_name: {
			label: 'Payee Company Name',
			type: 'input',
		},
		assignor_referral_firm_name: {
			label: 'Payee First Name',
			type: 'input',
		},
		assignor_referral_firm_lastname: {
			label: 'Payee Last Name',
			type: 'input',
		},
		assignor_referral_firm_address1: {
			label: 'Address',
			type: 'input',
		},
		assignor_referral_firm_city: {
			label: 'City',
			type: 'input',
		},
		assignor_referral_firm_state: {
			label: 'State',
			type: 'state',
		},
		assignor_referral_firm_zip: {
			label: 'Zip',
			type: 'input',
		},
		assignor_referral_firm_contact: {
			label: 'Contact',
			type: 'input',
		},
		assignor_referral_firm_main_phone: {
			label: 'Main Phone',
			type: 'input',
		},
		assignor_referral_firm_work_phone: {
			label: 'Work Phone',
			type: 'input',
		},
		assignor_referral_firm_mobile_phone: {
			label: 'Mobile Phone',
			type: 'input',
		},
		assignor_referral_firm_fax: {
			label: 'Fax',
			type: 'input',
		},
		assignor_referral_firm_email: {
			label: 'Email',
			type: 'input',
		},
		assignor_referral_firm_taxid: {
			label: 'Tax ID',
			type: 'input',
		},
		assignor_referral_firm_w9: {
			label: 'W9',
			type: 'radio',
			options: ['Yes', 'No'],
		},
		assignor_referral_firm_w9_upload: {
			label: 'Referral W9',
			type: 'file_upload',
			filetypes: ['pdf', 'png', 'jpg'],
		},
		assignor_referral_firm_fee_amount_choice: {
			label: 'Fee Amount',
			type: 'radio',
			options: ['Value', 'Percentage'],
		},
		assignor_referral_firm_fee_amount_percentage: {
			label: 'Fee Amount Percentage',
			type: 'input',
			suffix: '%',
		},
		assignor_referral_firm_fee_amount: {
			label: 'Fee Amount Value',
			dollar: true,
			type: 'input',
		},

		// ASSIGNOR OUTSIDE BROKER
		assignor_outside_broker: {
			label: 'Outside Broker',
			type: 'multi-contact',
		},
		assignor_outside_broker_company_name: {
			label: 'Company Name',
			type: 'input',
		},
		assignor_outside_broker_name: {
			label: 'First Name',
			type: 'input',
		},
		assignor_outside_broker_lastname: {
			label: 'Last Name',
			type: 'input',
		},
		assignor_outside_broker_address1: {
			label: 'Address',
			type: 'input',
		},
		assignor_outside_broker_address2: {
			label: 'Address 2',
			type: 'input',
		},
		assignor_outside_broker_city: {
			label: 'City',
			type: 'input',
		},
		assignor_outside_broker_state: {
			label: 'State',
			type: 'state',
		},
		assignor_outside_broker_zip: {
			label: 'Zip',
			type: 'input',
		},
		assignor_outside_broker_contact: {
			label: 'Agent(s)',
			type: 'input',
		},
		assignor_outside_broker_main_phone: {
			label: 'Main Phone',
			type: 'input',
		},
		assignor_outside_broker_work_phone: {
			label: 'Work Phone',
			type: 'input',
		},
		assignor_outside_broker_mobile_phone: {
			label: 'Mobile Phone',
			type: 'input',
		},
		assignor_outside_broker_fax: {
			label: 'Fax',
			type: 'input',
		},
		assignor_outside_broker_email: {
			label: 'Email',
			type: 'input',
		},
		assignor_outside_broker_taxid: {
			label: 'Tax ID',
			type: 'input',
		},
		assignor_outside_broker_w9: {
			label: 'W9',
			type: 'radio',
			options: ['Yes', 'No'],
		},
		assignor_outside_broker_w9_upload: {
			label: 'W9',
			type: 'file_upload',
			filetypes: ['pdf', 'png', 'jpg'],
		},
		assignor_outside_broker_commission: {
			label: 'Outside Broker Commission',
			type: 'input',
			validations: ['required'],
			inputType: 'number',
		},

		// LESSOR - OUTSIDE BROKER
		lessor_outside_broker: {
			label: 'Outside Broker',
			type: 'multi-contact',
		},
		lessor_outside_broker_company_name: {
			label: 'Company Name',
			type: 'input',
		},
		lessor_outside_broker_name: {
			label: 'First Name',
			type: 'input',
		},
		lessor_outside_broker_lastname:
			COMMON_FIELDS.lessor_outside_broker_lastname,
		lessor_outside_broker_address1:
			COMMON_FIELDS.lessor_outside_broker_address1,
		lessor_outside_broker_address2:
			COMMON_FIELDS.lessor_outside_broker_address2,
		lessor_outside_broker_city: COMMON_FIELDS.lessor_outside_broker_city,
		lessor_outside_broker_state: COMMON_FIELDS.lessor_outside_broker_state,
		lessor_outside_broker_zip: COMMON_FIELDS.lessor_outside_broker_zip,
		lessor_outside_broker_contact: COMMON_FIELDS.lessor_outside_broker_contact,
		lessor_outside_broker_main_phone:
			COMMON_FIELDS.lessor_outside_broker_main_phone,
		lessor_outside_broker_fax: COMMON_FIELDS.lessor_outside_broker_fax,
		lessor_outside_broker_email: COMMON_FIELDS.lessor_outside_broker_email,
		lessor_outside_broker_taxid: COMMON_FIELDS.lessor_outside_broker_taxid,
		lessor_outside_broker_w9: COMMON_FIELDS.lessor_outside_broker_w9,
		lessor_outside_broker_w9_upload:
			COMMON_FIELDS.lessor_outside_broker_w9_upload,
		lessor_outside_broker_commission:
			COMMON_FIELDS.lessor_outside_broker_commission,

		// REFERRAL
		referral_firm: COMMON_FIELDS.referral_firm,
		referral_firm_company_name: COMMON_FIELDS.referral_firm_company_name,
		referral_firm_name: COMMON_FIELDS.referral_firm_name,
		referral_firm_lastname: COMMON_FIELDS.referral_firm_lastname,
		referral_firm_address1: COMMON_FIELDS.referral_firm_address1,
		referral_firm_city: COMMON_FIELDS.referral_firm_city,
		referral_firm_state: COMMON_FIELDS.referral_firm_state,
		referral_firm_zip: COMMON_FIELDS.referral_firm_zip,
		referral_firm_contact: COMMON_FIELDS.referral_firm_contact,
		referral_firm_main_phone: COMMON_FIELDS.referral_firm_main_phone,
		referral_firm_work_phone: COMMON_FIELDS.referral_firm_work_phone,
		referral_firm_mobile_phone: COMMON_FIELDS.referral_firm_mobile_phone,
		referral_firm_fax: COMMON_FIELDS.referral_firm_fax,
		referral_firm_email: COMMON_FIELDS.referral_firm_email,
		referral_firm_taxid: COMMON_FIELDS.referral_firm_taxid,
		referral_firm_w9: COMMON_FIELDS.referral_firm_w9,
		referral_firm_w9_upload: COMMON_FIELDS.referral_firm_w9_upload,
		referral_firm_fee_amount_choice:
			COMMON_FIELDS.referral_firm_fee_amount_choice,
		referral_firm_fee_amount_percentage:
			COMMON_FIELDS.referral_firm_fee_amount_percentage,
		referral_firm_fee_amount: COMMON_FIELDS.referral_firm_fee_amount,

		// LESSORS
		lessor_contacts: {
			label: 'Assignor Contact(s)',
			type: 'multi-contact',
		},
		lessors: COMMON_FIELDS.lessors,

		// ASSIGNORS
		assignors: {
			label: 'Assignor',
			type: 'lessors',
		},

		// BILLING INFORMATION
		billing_id: COMMON_FIELDS.billing_id,
		billing_company: COMMON_FIELDS.billing_company,
		billing_attention: COMMON_FIELDS.billing_attention,
		billing_attention_lastname: COMMON_FIELDS.billing_attention_lastname,
		billing_address1: COMMON_FIELDS.billing_address1,
		billing_address2: COMMON_FIELDS.billing_address2,
		billing_city: COMMON_FIELDS.billing_city,
		billing_state: COMMON_FIELDS.billing_state,
		billing_zip: COMMON_FIELDS.billing_zip,
		billing_phone: COMMON_FIELDS.billing_phone,
		billing_fax: COMMON_FIELDS.billing_fax,
		billing_email: COMMON_FIELDS.billing_email,
		billing_flat_fee: {
			label: 'Flat Fee Commission',
			type: 'input',
			dollar: true,
		},

		// COMMISSION SPLITS
		commission_splits_agent: {
			label: 'Assignor Commission Splits',
			type: 'object_array',
            validations: ['required'],
		},
		commission_splits_other: {
			label: 'Other Assignor Commission Splits',
			type: 'object_array',
            validations: ['required'],
		},
		commission_splits_agent2: {
			label: 'Assignee Commission Splits',
			type: 'object_array',
            validations: ['required'],
		},
		commission_splits_other2: {
			label: 'Other Assignee Commission Splits',
			type: 'object_array',
            validations: ['required'],
		},

		// COPY OF INVOICE
		copy_of_invoice: {
			label: 'Copy of Invoice',
			options: [
				'illi Agents',
				'Outside Broker',
				'Assignor',
				'Assignee',
				'Management Company',
				'Other',
			],
			type: 'checkbox',
            validations: ['required'],
		},
		copy_of_invoice_other: COMMON_FIELDS.copy_of_invoice_other,

		// PAYMENT SCHEDULE
		payment_schedule: COMMON_FIELDS.payment_schedule,

		// COMMENTS AND SPECIAL INSTRUCTIONS
		comments_and_special_instructions:
			COMMON_FIELDS.comments_and_special_instructions,

		// FINAL
		approved_by: COMMON_FIELDS.approved_by,
		approved_by_tn: COMMON_FIELDS.approved_by_tn,
		notes: COMMON_FIELDS.notes,

		// COMMISSION ADJUSTMENTS
		commission_adjustment: COMMON_FIELDS.commission_adjustment,
		commission_adjustment_details: COMMON_FIELDS.commission_adjustment_details,

		// DOCUMENTS
		documents: COMMON_FIELDS.documents
	},
	// * No Listing
	NoListing: {
		// PROPERTY INFORMATION
		date: COMMON_FIELDS.date,
		project_name: COMMON_FIELDS.project_name,
		illi_number: {
			label: 'illi #',
			type: 'input',
			validations: ['required'],
			readonly: true,
		},
		pm_number: {
			label: 'PM #',
			type: 'input',
            validations: ['required'],
			readonly: true,
		},
		project_address: COMMON_FIELDS.project_address,
		street1: COMMON_FIELDS.street1,
		spaces: COMMON_FIELDS.spaces,
		la_city_verification: COMMON_FIELDS.la_city_verification,
		// illi_represented_lessor: COMMON_FIELDS.illi_represented_lessor,
		illi_represented_lessor: {
			label: 'illi Represented - Lessor',
			type: 'radio',
			options: ['Yes'],
			validations: ['required'],
		},
		illi_represented_lessor_agents:
			COMMON_FIELDS.illi_represented_lessor_agents,
		illi_represented_lessee: COMMON_FIELDS.illi_represented_lessee,
		illi_represented_lessee_agents:
			COMMON_FIELDS.illi_represented_lessee_agents,
		property_type: COMMON_FIELDS.property_type,
		type_of_lease: {
			label: 'Type of Lease',
			type: 'select',
			options: ['New Lease'],
			defaultValue: ['New Lease'],
			readonly: true,
			validations: ['required'],
		},
		can_deal_be_submitted_to_public_relations:
			COMMON_FIELDS.can_deal_be_submitted_to_public_relations,
		can_deal_be_submitted_to_public_relations_comment:
			COMMON_FIELDS.can_deal_be_submitted_to_public_relations_comment,

		commission_distribution_lessee: COMMON_FIELDS.commission_distribution_lessee,
		commission_distribution_lessor: COMMON_FIELDS.commission_distribution_lessor,

		// PREMISES INFORMATION
		premises_address: COMMON_FIELDS.premises_address,
		unit_number: {
			label: 'Unit Number',
			type: 'input',
			readonly: true,
			validations: [],
		}, // note required
		state: COMMON_FIELDS.state,
		city: COMMON_FIELDS.city,
		zip: COMMON_FIELDS.zip,
		county: COMMON_FIELDS.county,
		window_signs_returned: COMMON_FIELDS.window_signs_returned,
		window_sign_number: COMMON_FIELDS.window_sign_number,
		sign_removal_requested: COMMON_FIELDS.sign_removal_requested,
		loopnet_marketing_updated: COMMON_FIELDS.loopnet_marketing_updated,
		flat_fee_space: COMMON_FIELDS.flat_fee_space,

		// LEASE INFORMATION
		business_name: COMMON_FIELDS.business_name,
		type_of_business: COMMON_FIELDS.type_of_business,
		lease_start_date: COMMON_FIELDS.lease_start_date,
		lease_length: COMMON_FIELDS.lease_length,
		lease_start_date_estimated: COMMON_FIELDS.lease_start_date_estimated,
		lease_start_date_estimated_tbd:
			COMMON_FIELDS.lease_start_date_estimated_tbd,
		lease_end_date: COMMON_FIELDS.lease_end_date,
		rent_commencement: COMMON_FIELDS.rent_commencement,
		rent_commencement_estimated: COMMON_FIELDS.rent_commencement_estimated,
		rent_commencement_estimated_tbd:
			COMMON_FIELDS.rent_commencement_estimated_tbd,
		total_sf_leased: COMMON_FIELDS.total_sf_leased,
		starting_rent: COMMON_FIELDS.starting_rent,
		flat_fee_commission: COMMON_FIELDS.flat_fee_commission,
		flat_fee_commission_amount: COMMON_FIELDS.flat_fee_commission_amount,
		commission_based_off: COMMON_FIELDS.commission_based_off,
		rate_type: COMMON_FIELDS.rate_type,
		rate_type_cpi_percentage: COMMON_FIELDS.rate_type_cpi_percentage,
		lease_term: COMMON_FIELDS.lease_term,
		lease_type: COMMON_FIELDS.lease_type,
		early_possession: COMMON_FIELDS.early_possession,
		early_possession_date: COMMON_FIELDS.early_possession_date,
		contingency: COMMON_FIELDS.contingency,
		contingency_detail: COMMON_FIELDS.contingency_detail,
		date_lease_signed: COMMON_FIELDS.date_lease_signed,
		occupancy_date: COMMON_FIELDS.occupancy_date,
		occupancy_date_estimated: COMMON_FIELDS.occupancy_date_estimated,
		occupancy_date_estimated_tbd: COMMON_FIELDS.occupancy_date_estimated_tbd,
		option_renewal: COMMON_FIELDS.option_renewal,
		option_commission: COMMON_FIELDS.option_commission,
		abated_rent_ranges: COMMON_FIELDS.abated_rent_ranges,

		// LESSEES
		lessees_id: {
			label: 'Lessee ID',
			type: 'input',
		},
		lessees_name: {
			label: 'Lessee Name',
			type: 'input',
		},
		lessees_name: {
			label: 'Lessee Last Name',
			type: 'input',
		},
		lessees_address1: COMMON_FIELDS.lessees_address1,
		lessees_address2: COMMON_FIELDS.lessees_address2,
		lessees_city: COMMON_FIELDS.lessees_city,
		lessees_state: COMMON_FIELDS.lessees_state,
		lessees_zip: COMMON_FIELDS.lessees_zip,
		lessees_contact: COMMON_FIELDS.lessees_contact,
		lessees_main_phone: COMMON_FIELDS.lessees_main_phone,
		lessees_work_phone: COMMON_FIELDS.lessees_work_phone,
		lessees_mobile_phone: COMMON_FIELDS.lessees_mobile_phone,
		lessees_fax: COMMON_FIELDS.lessees_fax,
		lessees_email: COMMON_FIELDS.lessees_email,
		lessees_agency_disclosure: COMMON_FIELDS.lessees_agency_disclosure,
		lessees_llc_corp_verified: COMMON_FIELDS.lessees_llc_corp_verified,
		lessees_upload_sos: COMMON_FIELDS.lessees_upload_sos,
		lessees_spousal_consent: COMMON_FIELDS.lessees_spousal_consent,
		lessees_upload_spousal_id: COMMON_FIELDS.lessees_upload_spousal_id,
		lessees_guarantor: COMMON_FIELDS.lessees_guarantor,
		lessees_guarantor_name: COMMON_FIELDS.lessees_guarantor_name,
		lessees_upload_guarantor_id: COMMON_FIELDS.lessees_upload_guarantor_id,
		lessees_guarantor_spouse_name: COMMON_FIELDS.lessees_guarantor_spouse_name,
		lessees_can_we_reach_out_for_testimonial:
			COMMON_FIELDS.lessees_can_we_reach_out_for_testimonial,
		lessees_can_we_reach_out_for_testimonial_comment:
			COMMON_FIELDS.lessees_can_we_reach_out_for_testimonial_comment,
		lessees: COMMON_FIELDS.lessees,
		lessee_contacts: COMMON_FIELDS.lessee_contacts,

		// PRE SPLITS
		presplits: COMMON_FIELDS.presplits,

		// REFERRAL FOR LESSEE
		lessee_referral_firm: COMMON_FIELDS.lessee_referral_firm,
		lessee_referral_firm_company_name:
			COMMON_FIELDS.lessee_referral_firm_company_name,
		lessee_referral_firm_name: COMMON_FIELDS.lessee_referral_firm_name,
		lessee_referral_firm_lastname: COMMON_FIELDS.lessee_referral_firm_lastname,
		lessee_referral_firm_contact_dba_name: COMMON_FIELDS.lessee_referral_firm_contact_dba_name,
		lessee_referral_firm_address1: COMMON_FIELDS.lessee_referral_firm_address1,
		lessee_referral_firm_city: COMMON_FIELDS.lessee_referral_firm_city,
		lessee_referral_firm_state: COMMON_FIELDS.lessee_referral_firm_state,
		lessee_referral_firm_zip: COMMON_FIELDS.lessee_referral_firm_zip,
		lessee_referral_firm_contact: COMMON_FIELDS.lessee_referral_firm_contact,
		lessee_referral_firm_main_phone:
			COMMON_FIELDS.lessee_referral_firm_main_phone,
		lessee_referral_firm_work_phone: COMMON_FIELDS.lessee_referral_firm_work_phone,
		lessee_referral_firm_mobile_phone: COMMON_FIELDS.lessee_referral_firm_mobile_phone,
		lessee_referral_firm_fax: COMMON_FIELDS.lessee_referral_firm_fax,
		lessee_referral_firm_email: COMMON_FIELDS.lessee_referral_firm_email,
		lessee_referral_firm_taxid: COMMON_FIELDS.lessee_referral_firm_taxid,
		lessee_referral_firm_w9: COMMON_FIELDS.lessee_referral_firm_w9,
		lessee_referral_firm_w9_upload:
			COMMON_FIELDS.lessee_referral_firm_w9_upload,
		lessee_referral_firm_fee_amount_choice:
			COMMON_FIELDS.lessee_referral_firm_fee_amount_choice,
		lessee_referral_firm_fee_amount_percentage:
			COMMON_FIELDS.lessee_referral_firm_fee_amount_percentage,
		lessee_referral_firm_fee_amount: {
			label: 'Fee Amount Value',
			type: 'input',
			dollar: true,
		},

		// LESSEE OUTSIDE BROKER
		outside_broker: COMMON_FIELDS.outside_broker,
		outside_broker_company_name: COMMON_FIELDS.outside_broker_company_name,
		outside_broker_name: {
			label: 'Company Name / First Name',
			type: 'input',
		},
		outside_broker_lastname: COMMON_FIELDS.outside_broker_lastname,
		outside_broker_address1: COMMON_FIELDS.outside_broker_address1,
		outside_broker_address2: COMMON_FIELDS.outside_broker_address2,
		outside_broker_city: COMMON_FIELDS.outside_broker_city,
		outside_broker_state: COMMON_FIELDS.outside_broker_state,
		outside_broker_zip: COMMON_FIELDS.outside_broker_zip,
		outside_broker_contact: COMMON_FIELDS.outside_broker_contact,
		outside_broker_main_phone: COMMON_FIELDS.outside_broker_main_phone,
		outside_broker_work_phone: COMMON_FIELDS.outside_broker_work_phone,
		outside_broker_mobile_phone: COMMON_FIELDS.outside_broker_mobile_phone,
		outside_broker_fax: COMMON_FIELDS.outside_broker_fax,
		outside_broker_email: COMMON_FIELDS.outside_broker_email,
		outside_broker_taxid: COMMON_FIELDS.outside_broker_taxid,
		outside_broker_w9: COMMON_FIELDS.outside_broker_w9,
		outside_broker_w9_upload: COMMON_FIELDS.outside_broker_w9_upload,
		outside_broker_commission: COMMON_FIELDS.outside_broker_commission,

		// LESSOR - OUTSIDE BROKER
		lessor_outside_broker: {
			label: 'Outside Broker',
			type: 'multi-contact',
		},
		lessor_outside_broker_company_name: {
			label: 'Company Name',
			type: 'input',
		},
		lessor_outside_broker_name: {
			label: 'First Name',
			type: 'input',
		},
		lessor_outside_broker_lastname:
			COMMON_FIELDS.lessor_outside_broker_lastname,
		lessor_outside_broker_address1:
			COMMON_FIELDS.lessor_outside_broker_address1,
		lessor_outside_broker_address2:
			COMMON_FIELDS.lessor_outside_broker_address2,
		lessor_outside_broker_city: COMMON_FIELDS.lessor_outside_broker_city,
		lessor_outside_broker_state: COMMON_FIELDS.lessor_outside_broker_state,
		lessor_outside_broker_zip: COMMON_FIELDS.lessor_outside_broker_zip,
		lessor_outside_broker_contact: COMMON_FIELDS.lessor_outside_broker_contact,
		lessor_outside_broker_main_phone:
			COMMON_FIELDS.lessor_outside_broker_main_phone,
		lessor_outside_broker_work_phone: COMMON_FIELDS.lessor_outside_broker_work_phone,
		lessor_outside_broker_mobile_phone: COMMON_FIELDS.lessor_outside_broker_mobile_phone,
		lessor_outside_broker_fax: COMMON_FIELDS.lessor_outside_broker_fax,
		lessor_outside_broker_email: COMMON_FIELDS.lessor_outside_broker_email,
		lessor_outside_broker_taxid: COMMON_FIELDS.lessor_outside_broker_taxid,
		lessor_outside_broker_w9: COMMON_FIELDS.lessor_outside_broker_w9,
		lessor_outside_broker_w9_upload:
			COMMON_FIELDS.lessor_outside_broker_w9_upload,
		lessor_outside_broker_commission:
			COMMON_FIELDS.lessor_outside_broker_commission,

		// REFERRAL
		referral_firm: COMMON_FIELDS.referral_firm,
		referral_firm_company_name: COMMON_FIELDS.referral_firm_company_name,
		referral_firm_name: COMMON_FIELDS.referral_firm_name,
		referral_firm_lastname: COMMON_FIELDS.referral_firm_lastname,
		referral_firm_contact_dba_name: COMMON_FIELDS.referral_firm_contact_dba_name,
		referral_firm_address1: COMMON_FIELDS.referral_firm_address1,
		referral_firm_city: COMMON_FIELDS.referral_firm_city,
		referral_firm_state: COMMON_FIELDS.referral_firm_state,
		referral_firm_zip: COMMON_FIELDS.referral_firm_zip,
		referral_firm_contact: COMMON_FIELDS.referral_firm_contact,
		referral_firm_main_phone: COMMON_FIELDS.referral_firm_main_phone,
		referral_firm_work_phone: COMMON_FIELDS.referral_firm_work_phone,
		referral_firm_mobile_phone: COMMON_FIELDS.referral_firm_mobile_phone,
		referral_firm_fax: COMMON_FIELDS.referral_firm_fax,
		referral_firm_email: COMMON_FIELDS.referral_firm_email,
		referral_firm_taxid: COMMON_FIELDS.referral_firm_taxid,
		referral_firm_w9: COMMON_FIELDS.referral_firm_w9,
		referral_firm_w9_upload: COMMON_FIELDS.referral_firm_w9_upload,
		referral_firm_fee_amount_choice:
			COMMON_FIELDS.referral_firm_fee_amount_choice,
		referral_firm_fee_amount_percentage:
			COMMON_FIELDS.referral_firm_fee_amount_percentage,
		referral_firm_fee_amount: COMMON_FIELDS.referral_firm_fee_amount,

		// LESSORS
		lessor_contacts: COMMON_FIELDS.lessor_contacts,
		lessors: COMMON_FIELDS.lessors,

		// BILLING INFORMATION
		billing_id: COMMON_FIELDS.billing_id,
		billing_company: COMMON_FIELDS.billing_company,
		billing_attention: COMMON_FIELDS.billing_attention,
		billing_attention_lastname: COMMON_FIELDS.billing_attention_lastname,
		billing_address1: COMMON_FIELDS.billing_address1,
		billing_address2: COMMON_FIELDS.billing_address2,
		billing_city: COMMON_FIELDS.billing_city,
		billing_state: COMMON_FIELDS.billing_state,
		billing_zip: COMMON_FIELDS.billing_zip,
		billing_phone: COMMON_FIELDS.billing_phone,
		billing_fax: COMMON_FIELDS.billing_fax,
		billing_email: COMMON_FIELDS.billing_email,

		// COMMISSION SPLITS
		commission_splits_agent: COMMON_FIELDS.commission_splits_agent,
		commission_splits_other: COMMON_FIELDS.commission_splits_other,
		commission_splits_agent2: COMMON_FIELDS.commission_splits_agent2,
		commission_splits_other2: COMMON_FIELDS.commission_splits_other2,

		// COPY OF INVOICE
		copy_of_invoice: COMMON_FIELDS.copy_of_invoice,
		copy_of_invoice_other: COMMON_FIELDS.copy_of_invoice_other,

		// PAYMENT SCHEDULE
		payment_schedule: COMMON_FIELDS.payment_schedule,

		// SIGN REQUEST FORM

		access_necessary: COMMON_FIELDS.access_necessary,
		contact: COMMON_FIELDS.contact,
		order_type: COMMON_FIELDS.order_type,
		maintenance_description: COMMON_FIELDS.maintenance_description,
		sign_size: COMMON_FIELDS.sign_size,
		location: COMMON_FIELDS.location,
		rider: COMMON_FIELDS.rider,
		sign_support_configuration: COMMON_FIELDS.sign_support_configuration,
		sign_request_reason: COMMON_FIELDS.sign_request_reason,

		// COMMENTS AND SPECIAL INSTRUCTIONS
		comments_and_special_instructions:
			COMMON_FIELDS.comments_and_special_instructions,

		// FINAL
		approved_by: COMMON_FIELDS.approved_by,
		approved_by_tn: COMMON_FIELDS.approved_by_tn,
		notes: COMMON_FIELDS.notes,

		// COMMISSION ADJUSTMENTS
		commission_adjustment: COMMON_FIELDS.commission_adjustment,
		commission_adjustment_details: COMMON_FIELDS.commission_adjustment_details,

		// DOCUMENTS
		documents: COMMON_FIELDS.documents
	},
	// * Residential
	Residential: {
		// PROPERTY INFORMATION
		date: COMMON_FIELDS.date,
		project_name: COMMON_FIELDS.project_name,
		illi_number: {
			label: 'illi #',
			type: 'input',
			validations: ['required'],
			readonly: true
		},
		pm_number: {
			label: 'PM #',
			type: 'input',
            validations: ['required'],
			readonly: true,
		},
		project_address: COMMON_FIELDS.project_address,
		street1: COMMON_FIELDS.street1,
		spaces: COMMON_FIELDS.spaces,
		la_city_verification: COMMON_FIELDS.la_city_verification,
		listings_procured_by: COMMON_FIELDS.listings_procured_by,
		lead_source: COMMON_FIELDS.lead_source,
		deal_generated_by: COMMON_FIELDS.deal_generated_by,
		illi_represented_lessor: COMMON_FIELDS.illi_represented_lessor,
		illi_represented_lessor_agents:
			COMMON_FIELDS.illi_represented_lessor_agents,
		illi_represented_lessee: COMMON_FIELDS.illi_represented_lessee,
		illi_represented_lessee_agents:
			COMMON_FIELDS.illi_represented_lessee_agents,

		commission_distribution_lessee: COMMON_FIELDS.commission_distribution_lessee,
		commission_distribution_lessor: COMMON_FIELDS.commission_distribution_lessor,

		// PREMISES INFORMATION
		premises_address: COMMON_FIELDS.premises_address,
		unit_number: COMMON_FIELDS.unit_number,
		city: COMMON_FIELDS.city,
		zip: COMMON_FIELDS.zip,
		county: COMMON_FIELDS.county,
		sign_removal_requested: COMMON_FIELDS.sign_removal_requested,
		loopnet_marketing_updated: {
			label: 'MLS Updated?',
			type: 'radio',
			options: ['Yes', 'No', 'N/A'],
            validations: ['required'],
		},
		flat_fee_space: COMMON_FIELDS.flat_fee_space,

		// LEASE INFORMATION
		lease_start_date: COMMON_FIELDS.lease_start_date,
		lease_length: COMMON_FIELDS.lease_length,
		lease_start_date_estimated: COMMON_FIELDS.lease_start_date_estimated,
		lease_start_date_estimated_tbd:
			COMMON_FIELDS.lease_start_date_estimated_tbd,
		lease_end_date: COMMON_FIELDS.lease_end_date,
		rent_commencement: COMMON_FIELDS.rent_commencement,
		rent_commencement_estimated: COMMON_FIELDS.rent_commencement_estimated,
		rent_commencement_estimated_tbd:
			COMMON_FIELDS.rent_commencement_estimated_tbd,
		total_sf_leased: COMMON_FIELDS.total_sf_leased,
		starting_rent: COMMON_FIELDS.starting_rent,
		flat_fee_commission: COMMON_FIELDS.flat_fee_commission,
		flat_fee_commission_amount: COMMON_FIELDS.flat_fee_commission_amount,
		commission_based_off: COMMON_FIELDS.commission_based_off,
		rate_type: COMMON_FIELDS.rate_type,
		rate_type_cpi_percentage: COMMON_FIELDS.rate_type_cpi_percentage,
		contingency: COMMON_FIELDS.contingency,
		contingency_detail: COMMON_FIELDS.contingency_detail,
		lease_term: COMMON_FIELDS.lease_term,
		date_lease_signed: COMMON_FIELDS.date_lease_signed,
		occupancy_date: COMMON_FIELDS.occupancy_date,
		occupancy_date_estimated: COMMON_FIELDS.occupancy_date_estimated,
		occupancy_date_estimated_tbd: COMMON_FIELDS.occupancy_date_estimated_tbd,
		abated_rent_ranges: COMMON_FIELDS.abated_rent_ranges,

		// LESSEES
		lessees_id: {
			label: 'Lessee ID',
			type: 'input',
		},
		lessees_name: {
			label: 'Lessee Name',
			type: 'input',
		},
		lessees_name: {
			label: 'Lessee Last Name',
			type: 'input',
		},
		lessees_address1: {
			label: 'Address',
			type: 'input',
		},
		lessees_address2: {
			label: 'Address 2',
			type: 'input',
		},
		lessees_city: {
			label: 'City',
			type: 'input',
		},
		lessees_state: {
			label: 'State',
			type: 'state',
		},
		lessees_zip: {
			label: 'Zip',
			type: 'input',
		},
		lessees_contact: {
			label: 'Contact',
			type: 'input',
		},
		lessees_main_phone: {
			label: 'Phone',
			type: 'input',
		},
		lessees_fax: {
			label: 'Fax',
			type: 'input',
		},
		lessees_email: {
			label: 'Email',
			type: 'input',
		},
		lessees_agency_disclosure: COMMON_FIELDS.lessees_agency_disclosure,
		lessees_llc_corp_verified: COMMON_FIELDS.lessees_llc_corp_verified,
		lessees_upload_sos: COMMON_FIELDS.lessees_upload_sos,
		lessees_spousal_consent: COMMON_FIELDS.lessees_spousal_consent,
		lessees_upload_spousal_id: COMMON_FIELDS.lessees_upload_spousal_id,
		lessees_guarantor: COMMON_FIELDS.lessees_guarantor,
		lessees_guarantor_name: COMMON_FIELDS.lessees_guarantor_name,
		lessees_upload_guarantor_id: COMMON_FIELDS.lessees_upload_guarantor_id,
		lessees_guarantor_spouse_name: COMMON_FIELDS.lessees_guarantor_spouse_name,
		lessees_can_we_reach_out_for_testimonial: {
			label: 'Can we reach out for testimonial ?',
			type: 'radio',
			options: ['Yes', 'No'],
		},
		lessees_can_we_reach_out_for_testimonial_comment:
			COMMON_FIELDS.lessees_can_we_reach_out_for_testimonial_comment,
		lessees_can_we_reach_out_for_testimonial_comment:
			COMMON_FIELDS.lessees_can_we_reach_out_for_testimonial_comment,
		lessees: COMMON_FIELDS.lessees,
		lessee_contacts: COMMON_FIELDS.lessee_contacts,

		// PRE SPLITS
		presplits: COMMON_FIELDS.presplits,

		// REFERRAL FOR LESSEE
		lessee_referral_firm: COMMON_FIELDS.lessee_referral_firm,
		lessee_referral_firm_company_name:
			COMMON_FIELDS.lessee_referral_firm_company_name,
		lessee_referral_firm_name: COMMON_FIELDS.lessee_referral_firm_name,
		lessee_referral_firm_lastname: COMMON_FIELDS.lessee_referral_firm_lastname,
		lessee_referral_firm_contact_dba_name: COMMON_FIELDS.lessee_referral_firm_contact_dba_name,
		lessee_referral_firm_address1: COMMON_FIELDS.lessee_referral_firm_address1,
		lessee_referral_firm_city: COMMON_FIELDS.lessee_referral_firm_city,
		lessee_referral_firm_state: COMMON_FIELDS.lessee_referral_firm_state,
		lessee_referral_firm_zip: COMMON_FIELDS.lessee_referral_firm_zip,
		lessee_referral_firm_contact: COMMON_FIELDS.lessee_referral_firm_contact,
		lessee_referral_firm_main_phone: COMMON_FIELDS.lessee_referral_firm_main_phone,
		lessee_referral_firm_work_phone: COMMON_FIELDS.lessee_referral_firm_work_phone,
		lessee_referral_firm_mobile_phone: COMMON_FIELDS.lessee_referral_firm_mobile_phone,
		lessee_referral_firm_fax: COMMON_FIELDS.lessee_referral_firm_fax,
		lessee_referral_firm_email: COMMON_FIELDS.lessee_referral_firm_email,
		lessee_referral_firm_taxid: COMMON_FIELDS.lessee_referral_firm_taxid,
		lessee_referral_firm_w9: COMMON_FIELDS.lessee_referral_firm_w9,
		lessee_referral_firm_w9_upload:
			COMMON_FIELDS.lessee_referral_firm_w9_upload,
		lessee_referral_firm_fee_amount_choice:
			COMMON_FIELDS.lessee_referral_firm_fee_amount_choice,
		lessee_referral_firm_fee_amount_percentage:
			COMMON_FIELDS.lessee_referral_firm_fee_amount_percentage,
		lessee_referral_firm_fee_amount: {
			label: 'Fee Amount Value',
			dollar: true,
			type: 'input',
		},

		// LESSEE OUTSIDE BROKER
		outside_broker: COMMON_FIELDS.outside_broker,
		outside_broker_company_name: COMMON_FIELDS.outside_broker_company_name,
		outside_broker_name: {
			label: 'Company Name / First Name',
			type: 'input',
		},
		outside_broker_lastname: COMMON_FIELDS.outside_broker_lastname,
		outside_broker_address1: COMMON_FIELDS.outside_broker_address1,
		outside_broker_address2: COMMON_FIELDS.outside_broker_address2,
		outside_broker_city: COMMON_FIELDS.outside_broker_city,
		outside_broker_state: COMMON_FIELDS.outside_broker_state,
		outside_broker_zip: COMMON_FIELDS.outside_broker_zip,
		outside_broker_contact: COMMON_FIELDS.outside_broker_contact,
		outside_broker_main_phone: COMMON_FIELDS.outside_broker_main_phone,
		outside_broker_work_phone: COMMON_FIELDS.outside_broker_work_phone,
		outside_broker_mobile_phone: COMMON_FIELDS.outside_broker_mobile_phone,
		outside_broker_fax: COMMON_FIELDS.outside_broker_fax,
		outside_broker_email: COMMON_FIELDS.outside_broker_email,
		outside_broker_taxid: COMMON_FIELDS.outside_broker_taxid,
		outside_broker_w9: COMMON_FIELDS.outside_broker_w9,
		outside_broker_w9_upload: COMMON_FIELDS.outside_broker_w9_upload,
		outside_broker_commission: COMMON_FIELDS.outside_broker_commission,

		// LESSOR - OUTSIDE BROKER
		lessor_outside_broker: {
			label: 'Outside Broker',
			type: 'multi-contact',
		},
		lessor_outside_broker_company_name: {
			label: 'Company Name',
			type: 'input',
		},
		lessor_outside_broker_name: {
			label: 'Company Name',
			type: 'input',
		},
		lessor_outside_broker_lastname:
			COMMON_FIELDS.lessor_outside_broker_lastname,
		lessor_outside_broker_address1:
			COMMON_FIELDS.lessor_outside_broker_address1,
		lessor_outside_broker_address2:
			COMMON_FIELDS.lessor_outside_broker_address2,
		lessor_outside_broker_city: COMMON_FIELDS.lessor_outside_broker_city,
		lessor_outside_broker_state: {
			label: 'State',
			type: 'state',
		},
		lessor_outside_broker_zip: COMMON_FIELDS.lessor_outside_broker_zip,
		lessor_outside_broker_contact: COMMON_FIELDS.lessor_outside_broker_contact,
		lessor_outside_broker_main_phone:
			COMMON_FIELDS.lessor_outside_broker_main_phone,
		lessor_outside_broker_fax: COMMON_FIELDS.lessor_outside_broker_fax,
		lessor_outside_broker_email: COMMON_FIELDS.lessor_outside_broker_email,
		lessor_outside_broker_taxid: COMMON_FIELDS.lessor_outside_broker_taxid,
		lessor_outside_broker_w9: COMMON_FIELDS.lessor_outside_broker_w9,
		lessor_outside_broker_w9_upload:
			COMMON_FIELDS.lessor_outside_broker_w9_upload,
		lessor_outside_broker_commission:
			COMMON_FIELDS.lessor_outside_broker_commission,

		// REFERRAL
		referral_firm: COMMON_FIELDS.referral_firm,
		referral_firm_company_name: COMMON_FIELDS.referral_firm_company_name,
		referral_firm_name: COMMON_FIELDS.referral_firm_name,
		referral_firm_lastname: COMMON_FIELDS.referral_firm_lastname,
		referral_firm_contact_dba_name: COMMON_FIELDS.referral_firm_contact_dba_name,
		referral_firm_address1: COMMON_FIELDS.referral_firm_address1,
		referral_firm_city: COMMON_FIELDS.referral_firm_city,
		referral_firm_state: COMMON_FIELDS.referral_firm_state,
		referral_firm_zip: COMMON_FIELDS.referral_firm_zip,
		referral_firm_contact: COMMON_FIELDS.referral_firm_contact,
		referral_firm_main_phone: COMMON_FIELDS.referral_firm_main_phone,
		referral_firm_work_phone: COMMON_FIELDS.referral_firm_work_phone,
		referral_firm_mobile_phone: COMMON_FIELDS.referral_firm_mobile_phone,
		referral_firm_fax: COMMON_FIELDS.referral_firm_fax,
		referral_firm_email: COMMON_FIELDS.referral_firm_email,
		referral_firm_taxid: COMMON_FIELDS.referral_firm_taxid,
		referral_firm_w9: COMMON_FIELDS.referral_firm_w9,
		referral_firm_w9_upload: COMMON_FIELDS.referral_firm_w9_upload,
		referral_firm_fee_amount_choice:
			COMMON_FIELDS.referral_firm_fee_amount_choice,
		referral_firm_fee_amount_percentage:
			COMMON_FIELDS.referral_firm_fee_amount_percentage,
		referral_firm_fee_amount: COMMON_FIELDS.referral_firm_fee_amount,

		// LESSORS
		lessor_contacts: COMMON_FIELDS.lessor_contacts,
		lessors: COMMON_FIELDS.lessors,

		// BILLING INFORMATION
		billing_id: COMMON_FIELDS.billing_id,
		billing_company: COMMON_FIELDS.billing_company,
		billing_dba_name: COMMON_FIELDS.billing_dba_name,
		billing_attention: COMMON_FIELDS.billing_attention,
		billing_attention_lastname: COMMON_FIELDS.billing_attention_lastname,
		billing_attention_dba_name: COMMON_FIELDS.billing_attention_dba_name,
		billing_address1: COMMON_FIELDS.billing_address1,
		billing_address2: COMMON_FIELDS.billing_address2,
		billing_city: COMMON_FIELDS.billing_city,
		billing_state: COMMON_FIELDS.billing_state,
		billing_zip: COMMON_FIELDS.billing_zip,
		billing_phone: COMMON_FIELDS.billing_phone,
		billing_fax: COMMON_FIELDS.billing_fax,
		billing_email: COMMON_FIELDS.billing_email,

		// COMMISSION SPLITS
		commission_splits_agent: COMMON_FIELDS.commission_splits_agent,
		commission_splits_other: COMMON_FIELDS.commission_splits_other,
		commission_splits_agent2: COMMON_FIELDS.commission_splits_agent2,
		commission_splits_other2: COMMON_FIELDS.commission_splits_other2,

		// COPY OF INVOICE
		copy_of_invoice: COMMON_FIELDS.copy_of_invoice,
		copy_of_invoice_other: COMMON_FIELDS.copy_of_invoice_other,

		// PAYMENT SCHEDULE
		payment_schedule: COMMON_FIELDS.payment_schedule,

		// SIGN REQUEST FORM
		access_necessary: COMMON_FIELDS.access_necessary,
		contact: COMMON_FIELDS.contact,
		order_type: COMMON_FIELDS.order_type,
		maintenance_description: COMMON_FIELDS.maintenance_description,
		sign_size: COMMON_FIELDS.sign_size,
		location: COMMON_FIELDS.location,
		rider: COMMON_FIELDS.rider,
		sign_support_configuration: COMMON_FIELDS.sign_support_configuration,
		sign_request_reason: COMMON_FIELDS.sign_request_reason,

		// COMMENTS AND SPECIAL INSTRUCTIONS
		comments_and_special_instructions:
			COMMON_FIELDS.comments_and_special_instructions,

		// FINAL
		approved_by: COMMON_FIELDS.approved_by,
		approved_by_tn: COMMON_FIELDS.approved_by_tn,
		notes: COMMON_FIELDS.notes,

		// COMMISSION ADJUSTMENTS
		commission_adjustment: COMMON_FIELDS.commission_adjustment,
		commission_adjustment_details: COMMON_FIELDS.commission_adjustment_details,

		// DOCUMENTS
		documents: COMMON_FIELDS.documents
	},
	// * Sale Fields *
	Sale: {
		// PROPERTY INFORMATION
		date: COMMON_FIELDS.date,
		project_name: COMMON_FIELDS.project_name,
		illi_number: {
			label: 'illi #',
			type: 'input',
			validations: ['required'],
			readonly: true
		},
		pm_number: {
			label: 'PM #',
			type: 'input',
            validations: ['required'],
			readonly: true,
		},
		project_address: COMMON_FIELDS.project_address,
		street1: COMMON_FIELDS.street1,
		spaces: COMMON_FIELDS.spaces,
		la_city_verification: COMMON_FIELDS.la_city_verification,
		listings_procured_by: COMMON_FIELDS.listings_procured_by,
		lead_source: COMMON_FIELDS.lead_source,
		deal_generated_by: COMMON_FIELDS.deal_generated_by,
		illi_represented_lessor: {
			label: 'illi Represented - Seller',
			type: 'radio',
			options: ['Yes', 'No'],
            validations: ['required'],
		},
		illi_represented_lessor_agents: {
			label: 'illi Represented - Seller - Agent(s)',
			type: 'multi-contact-agent',
			peopleType: 'agent',
            validations: ['required'],
		},
		illi_represented_lessee: {
			label: 'illi Represented - Buyer',
			type: 'radio',
			options: ['Yes', 'No'],
            validations: ['required'],
		},
		illi_represented_lessee_agents: {
			label: 'illi Represented - Buyer - Agent(s)',
			type: 'multi-contact-agent',
			peopleType: 'agent',
            validations: ['required'],
		},
		property_type: COMMON_FIELDS.property_type,
		can_deal_be_submitted_to_public_relations:
			COMMON_FIELDS.can_deal_be_submitted_to_public_relations,
		can_deal_be_submitted_to_public_relations_comment:
			COMMON_FIELDS.can_deal_be_submitted_to_public_relations_comment,

		commission_distribution_lessee: COMMON_FIELDS.commission_distribution_lessee,
		commission_distribution_lessor: COMMON_FIELDS.commission_distribution_lessor,

		// MARKETING INFORMATION
		premises_address: COMMON_FIELDS.premises_address,
		unit_number: {
			label: 'Unit Number',
			type: 'input',
			readonly: true,
			validations: [],
		}, // not required
		city: COMMON_FIELDS.city,
		zip: COMMON_FIELDS.zip,
		county: COMMON_FIELDS.county,
		window_signs_returned: COMMON_FIELDS.window_signs_returned,
		window_sign_number: COMMON_FIELDS.window_sign_number,
		sign_removal_requested: COMMON_FIELDS.sign_removal_requested,
		loopnet_marketing_updated: COMMON_FIELDS.loopnet_marketing_updated,
		flat_fee_space: COMMON_FIELDS.flat_fee_space,

		// LEASE INFORMATION
		business_name: COMMON_FIELDS.business_name,
		type_of_business: COMMON_FIELDS.type_of_business,
		lease_start_date: COMMON_FIELDS.lease_start_date,
		lease_start_date_estimated: COMMON_FIELDS.lease_start_date_estimated,
		lease_end_date: {
			label: 'Lease End Date',
			type: 'date2',
			validations: ['required'],
		},
		lease_end_date_estimated: {
			label: '',
			type: 'checkbox',
			options: ['Lease End Date Estimated'],
            validations: ['required'],
		},
		rent_commencement: COMMON_FIELDS.rent_commencement,
		rent_commencement_estimated: COMMON_FIELDS.rent_commencement_estimated,
		total_sf_leased: COMMON_FIELDS.total_sf_leased,
		starting_rent: COMMON_FIELDS.starting_rent,
		lease_term: COMMON_FIELDS.lease_term,
		lease_type: COMMON_FIELDS.lease_type,
		early_possession: COMMON_FIELDS.early_possession,
		early_possession_date: COMMON_FIELDS.early_possession_date,
		commission_based_off: COMMON_FIELDS.commission_based_off,
		rate_type: COMMON_FIELDS.rate_type,
		rate_type_cpi_percentage: COMMON_FIELDS.rate_type_cpi_percentage,
		contingency: COMMON_FIELDS.contingency,
		contingency_detail: COMMON_FIELDS.contingency_detail,
		date_lease_signed: COMMON_FIELDS.date_lease_signed,
		occupancy_date: {
			label: 'Occupancy Date',
			type: 'date',
			validations: ['required'],
		},
		option_renewal: COMMON_FIELDS.option_renewal,
		option_commission: COMMON_FIELDS.option_commission,
		abated_rent_ranges: COMMON_FIELDS.abated_rent_ranges,

		// LESSEES
		lessees_id: {
			label: 'Buyer ID',
			type: 'input',
		},
		lessees_name: {
			label: 'Buyer Name',
			type: 'input',
		},
		lessees_name: {
			label: 'Buyer Last Name',
			type: 'input',
		},
		lessees_address1: COMMON_FIELDS.lessees_address1,
		lessees_address2: COMMON_FIELDS.lessees_address2,
		lessees_city: COMMON_FIELDS.lessees_city,
		lessees_state: COMMON_FIELDS.lessees_state,
		lessees_zip: COMMON_FIELDS.lessees_zip,
		lessees_contact: COMMON_FIELDS.lessees_contact,
		lessees_main_phone: COMMON_FIELDS.lessees_main_phone,
		lessees_mobile_phone: COMMON_FIELDS.lessees_mobile_phone,
		lessees_work_phone: COMMON_FIELDS.lessees_work_phone,
		lessees_fax: COMMON_FIELDS.lessees_fax,
		lessees_email: COMMON_FIELDS.lessees_email,
		lessees_agency_disclosure: COMMON_FIELDS.lessees_agency_disclosure,
		lessees_llc_corp_verified: COMMON_FIELDS.lessees_llc_corp_verified,
		lessees_upload_sos: COMMON_FIELDS.lessees_upload_sos,
		lessees_spousal_consent: COMMON_FIELDS.lessees_spousal_consent,
		lessees_upload_spousal_id: COMMON_FIELDS.lessees_upload_spousal_id,
		lessees_guarantor: COMMON_FIELDS.lessees_guarantor,
		lessees_guarantor_name: COMMON_FIELDS.lessees_guarantor_name,
		lessees_upload_guarantor_id: COMMON_FIELDS.lessees_upload_guarantor_id,
		lessees_guarantor_spouse_name: COMMON_FIELDS.lessees_guarantor_spouse_name,
		lessees_can_we_reach_out_for_testimonial:
			COMMON_FIELDS.lessees_can_we_reach_out_for_testimonial,
		lessees_can_we_reach_out_for_testimonial_comment:
			COMMON_FIELDS.lessees_can_we_reach_out_for_testimonial_comment,
		lessees: {
			label: 'Buyer',
			type: 'lessees',
		},
		lessee_contacts: {
			label: 'Buyer Contacts',
			type: 'multi-contact',
		},

		// PRE SPLITS
		presplits: COMMON_FIELDS.presplits,

		// REFERRAL FOR LESSEE
		lessee_referral_firm: COMMON_FIELDS.lessee_referral_firm,
		lessee_referral_firm_name: COMMON_FIELDS.lessee_referral_firm_name,
		lessee_referral_firm_lastname: COMMON_FIELDS.lessee_referral_firm_lastname,
		lessee_referral_firm_contact_dba_name: COMMON_FIELDS.lessee_referral_firm_contact_dba_name,
		lessee_referral_firm_address1: COMMON_FIELDS.lessee_referral_firm_address1,
		lessee_referral_firm_city: COMMON_FIELDS.lessee_referral_firm_city,
		lessee_referral_firm_state: COMMON_FIELDS.lessee_referral_firm_state,
		lessee_referral_firm_zip: COMMON_FIELDS.lessee_referral_firm_zip,
		lessee_referral_firm_contact: COMMON_FIELDS.lessee_referral_firm_contact,
		lessee_referral_firm_main_phone:
			COMMON_FIELDS.lessee_referral_firm_main_phone,
		lessee_referral_firm_work_phone: COMMON_FIELDS.lessee_referral_firm_work_phone,
		lessee_referral_firm_mobile_phone: COMMON_FIELDS.lessee_referral_firm_mobile_phone,
		lessee_referral_firm_fax: COMMON_FIELDS.lessee_referral_firm_fax,
		lessee_referral_firm_email: COMMON_FIELDS.lessee_referral_firm_email,
		lessee_referral_firm_taxid: COMMON_FIELDS.lessee_referral_firm_taxid,
		lessee_referral_firm_w9: COMMON_FIELDS.lessee_referral_firm_w9,
		lessee_referral_firm_w9_upload:
			COMMON_FIELDS.lessee_referral_firm_w9_upload,
		lessee_referral_firm_fee_amount:
			COMMON_FIELDS.lessee_referral_firm_fee_amount,

		// LESSEE OUTSIDE BROKER
		outside_broker: COMMON_FIELDS.outside_broker,
		outside_broker_name: {
			label: 'Company Name / First Name',
			type: 'input',
		},
		outside_broker_lastname: COMMON_FIELDS.outside_broker_lastname,
		outside_broker_address1: COMMON_FIELDS.outside_broker_address1,
		outside_broker_address2: COMMON_FIELDS.outside_broker_address2,
		outside_broker_city: COMMON_FIELDS.outside_broker_city,
		outside_broker_state: COMMON_FIELDS.outside_broker_state,
		outside_broker_zip: COMMON_FIELDS.outside_broker_zip,
		outside_broker_contact: COMMON_FIELDS.outside_broker_contact,
		outside_broker_main_phone: COMMON_FIELDS.outside_broker_main_phone,
		outside_broker_work_phone: COMMON_FIELDS.outside_broker_work_phone,
		outside_broker_mobile_phone: COMMON_FIELDS.outside_broker_mobile_phone,
		outside_broker_fax: COMMON_FIELDS.outside_broker_fax,
		outside_broker_email: COMMON_FIELDS.outside_broker_email,
		outside_broker_taxid: COMMON_FIELDS.outside_broker_taxid,
		outside_broker_w9: COMMON_FIELDS.outside_broker_w9,
		outside_broker_w9_upload: COMMON_FIELDS.outside_broker_w9_upload,
		outside_broker_commission: COMMON_FIELDS.outside_broker_commission,

		// LESSOR - OUTSIDE BROKER
		lessor_outside_broker: {
			label: 'Outside Broker',
			type: 'multi-contact',
		},
		lessor_outside_broker_name: {
			label: 'Company Name',
			type: 'input',
		},
		lessor_outside_broker_lastname:
			COMMON_FIELDS.lessor_outside_broker_lastname,
		lessor_outside_broker_address1:
			COMMON_FIELDS.lessor_outside_broker_address1,
		lessor_outside_broker_address2:
			COMMON_FIELDS.lessor_outside_broker_address2,
		lessor_outside_broker_city: COMMON_FIELDS.lessor_outside_broker_city,
		lessor_outside_broker_state: {
			label: 'State',
			type: 'state',
		},
		lessor_outside_broker_zip: COMMON_FIELDS.lessor_outside_broker_zip,
		lessor_outside_broker_contact: COMMON_FIELDS.lessor_outside_broker_contact,
		lessor_outside_broker_main_phone: COMMON_FIELDS.lessor_outside_broker_main_phone,
		lessor_outside_broker_mobile_phone: COMMON_FIELDS.lessor_outside_broker_mobile_phone,
		lessor_outside_broker_work_phone: COMMON_FIELDS.lessor_outside_broker_work_phone,
		lessor_outside_broker_fax: COMMON_FIELDS.lessor_outside_broker_fax,
		lessor_outside_broker_email: COMMON_FIELDS.lessor_outside_broker_email,
		lessor_outside_broker_taxid: COMMON_FIELDS.lessor_outside_broker_taxid,
		lessor_outside_broker_w9: COMMON_FIELDS.lessor_outside_broker_w9,
		lessor_outside_broker_w9_upload:
			COMMON_FIELDS.lessor_outside_broker_w9_upload,
		lessor_outside_broker_commission:
			COMMON_FIELDS.lessor_outside_broker_commission,

		// BUYER REFERRAL
		referral_firm: COMMON_FIELDS.referral_firm,
		referral_firm_name: COMMON_FIELDS.referral_firm_name,
		referral_firm_lastname: COMMON_FIELDS.referral_firm_lastname,
		referral_firm_contact_dba_name: COMMON_FIELDS.referral_firm_contact_dba_name,
		referral_firm_address1: COMMON_FIELDS.referral_firm_address1,
		referral_firm_city: COMMON_FIELDS.referral_firm_city,
		referral_firm_state: COMMON_FIELDS.referral_firm_state,
		referral_firm_zip: COMMON_FIELDS.referral_firm_zip,
		referral_firm_contact: COMMON_FIELDS.referral_firm_contact,
		referral_firm_main_phone: COMMON_FIELDS.referral_firm_main_phone,
		referral_firm_mobile_phone: COMMON_FIELDS.referral_firm_mobile_phone,
		referral_firm_work_phone: COMMON_FIELDS.referral_firm_work_phone,
		referral_firm_fax: COMMON_FIELDS.referral_firm_fax,
		referral_firm_email: COMMON_FIELDS.referral_firm_email,
		referral_firm_taxid: COMMON_FIELDS.referral_firm_taxid,
		referral_firm_w9: COMMON_FIELDS.referral_firm_w9,
		referral_firm_w9_upload: COMMON_FIELDS.referral_firm_w9_upload,
		referral_firm_fee_amount: COMMON_FIELDS.referral_firm_fee_amount,

		// LESSORS
		lessor_contacts: {
			label: 'Seller Contacts',
			type: 'multi-contact',
		},
		lessors: {
			label: 'Seller',
			type: 'lessors',
		},

		// BILLING INFORMATION
		billing_id: COMMON_FIELDS.billing_id,
		billing_company: COMMON_FIELDS.billing_company,
		billing_attention: COMMON_FIELDS.billing_attention,
		billing_attention_lastname: COMMON_FIELDS.billing_attention_lastname,
		billing_address1: COMMON_FIELDS.billing_address1,
		billing_address2: COMMON_FIELDS.billing_address2,
		billing_city: COMMON_FIELDS.billing_city,
		billing_state: COMMON_FIELDS.billing_state,
		billing_zip: COMMON_FIELDS.billing_zip,
		billing_phone: COMMON_FIELDS.billing_phone,
		billing_fax: COMMON_FIELDS.billing_fax,
		billing_email: COMMON_FIELDS.billing_email,

		// COMMISSION SPLITS
		commission_splits_agent: {
			label: 'Seller Commission Splits',
			type: 'object_array',
            validations: ['required']
		},
		commission_splits_other: {
			label: 'Other Seller Commission Splits',
			type: 'object_array',
            validations: ['required']
		},
		commission_splits_agent2: {
			label: 'Buyer Commission Splits',
			type: 'object_array',
            validations: ['required']
		},
		commission_splits_other2: {
			label: 'Other Buyer Commission Splits',
			type: 'object_array',
            validations: ['required']
		},

		// COPY OF INVOICE
		copy_of_invoice: {
			label: 'Copy of Invoice',
			options: [
				'illi Agents',
				'Outside Broker',
				'Seller',
				'Buyer',
				'Management Company',
				'Other',
			],
			type: 'checkbox',
            validations: ['required']
		},
		copy_of_invoice_other: COMMON_FIELDS.copy_of_invoice_other,

		// PAYMENT SCHEDULE
		payment_schedule: COMMON_FIELDS.payment_schedule,

		// FINAL
		approved_by: COMMON_FIELDS.approved_by,
		approved_by_tn: COMMON_FIELDS.approved_by_tn,
		notes: COMMON_FIELDS.notes,

		// ESCROW INFORMATION
		escrow_officer_name: {
			label: 'Escrow officer name',
			type: 'input',
            validations: ['required'],
		},
		escrow_company: {
			label: 'Company',
			type: 'input',
            validations: ['required'],
		},
		escrow_address: {
			label: 'Address',
			type: 'input',
            validations: ['required'],
		},
		escrow_address_2: {
			label: 'Address 2',
			type: 'input',
            validations: [],
		},
		escrow_city: {
			label: 'City',
			type: 'input',
            validations: ['required'],
		},
		escrow_state: {
			label: 'State',
			type: 'state',
            validations: ['required'],
		},
		escrow_zip: {
			label: 'Zip',
			type: 'input',
            validations: ['required'],
		},
		escrow_phone: {
			label: 'Phone',
			type: 'input',
            validations: ['required'],
		},
		escrow_email: {
			label: 'Email',
			type: 'input',
            validations: ['required'],
		},

		// SALE
		sale_purchase_price: {
			label: 'Purchase Price',
			type: 'input',
			inputType: 'number',
            validations: ['required'],
		},
		sale_commission_rate: {
			label: 'Commission Rate',
			type: 'input',
			suffix: '%',
            validations: ['required'],
		},
		sale_sale_commission: {
			label: 'Sale Commission',
			type: 'input',
			dollar: true,
            validations: ['required'],
		},
		sale_escrow_opening_date: {
			label: 'Escrow Opening Date',
			type: 'date',
            validations: ['required'],
		},
		sale_escrow_closing_date: {
			label: 'Escrow Closing Date',
			type: 'date',
            validations: ['required'],
		},
		sale_building_sqft: {
			label: 'SQFT of Building',
			type: 'input',
			validations: ['no_letters'],
		},
		sale_land_sqft: {
			label: 'SQFT of Land',
			type: 'input',
			validations: ['no_letters'],
		},

		// SIGN REQUEST FORM
		access_necessary: COMMON_FIELDS.access_necessary,
		contact: COMMON_FIELDS.contact,
		order_type: COMMON_FIELDS.order_type,
		maintenance_description: COMMON_FIELDS.maintenance_description,
		sign_size: COMMON_FIELDS.sign_size,
		location: COMMON_FIELDS.location,
		rider: COMMON_FIELDS.rider,
		sign_support_configuration: COMMON_FIELDS.sign_support_configuration,
		sign_request_reason: COMMON_FIELDS.sign_request_reason,

		// COMMISSION ADJUSTMENTS
		commission_adjustment: COMMON_FIELDS.commission_adjustment,
		commission_adjustment_details: COMMON_FIELDS.commission_adjustment_details,

		// COMMENTS AND SPECIAL INSTRUCTIONS
		comments_and_special_instructions:
			COMMON_FIELDS.comments_and_special_instructions,

		// DOCUMENTS
		documents: COMMON_FIELDS.documents
	},
	// * Sublease
	Sublease: {
		// PROPERTY INFORMATION
		date: COMMON_FIELDS.date,
		project_name: COMMON_FIELDS.project_name,
		illi_number: {
			label: 'illi #',
			type: 'input',
			validations: ['required'],
			readonly: true,
		},
		pm_number: {
			label: 'PM #',
			type: 'input',
            validations: ['required'],
			readonly: true,
		},
		project_address: COMMON_FIELDS.project_address,
		street1: COMMON_FIELDS.street1,
		spaces: COMMON_FIELDS.spaces,
		la_city_verification: COMMON_FIELDS.la_city_verification,
		listings_procured_by: COMMON_FIELDS.listings_procured_by,
		lead_source: COMMON_FIELDS.lead_source,
		deal_generated_by: COMMON_FIELDS.deal_generated_by,
		illi_represented_lessor: {
			label: 'illi Represented - Sub-Lessor',
			type: 'radio',
			options: ['Yes', 'No'],
            validations: ['required'],
		},
		illi_represented_lessor_agents: {
			label: 'illi Represented - Sub-Lessor - Agent(s)',
			type: 'multi-contact-agent',
			peopleType: 'agent',
            validations: ['required'],
		},
		illi_represented_lessee: {
			label: 'illi Represented - Sub-Lessee',
			type: 'radio',
			options: ['Yes', 'No'],
            validations: ['required'],
		},
		illi_represented_lessee_agents: {
			label: 'illi Represented - Sub-Lessee - Agent(s)',
			type: 'multi-contact-agent',
			peopleType: 'agent',
            validations: ['required'],
		},
		property_type: COMMON_FIELDS.property_type,
		type_of_lease: {
			label: 'Type of Lease',
			type: 'select',
			options: ['New Lease', 'Renewal'],
            validations: ['required'],
		},
		can_deal_be_submitted_to_public_relations:
			COMMON_FIELDS.can_deal_be_submitted_to_public_relations,
		can_deal_be_submitted_to_public_relations_comment:
			COMMON_FIELDS.can_deal_be_submitted_to_public_relations_comment,

		commission_distribution_lessee: COMMON_FIELDS.commission_distribution_lessee,
		commission_distribution_lessor: COMMON_FIELDS.commission_distribution_lessor,

		// PREMISES INFORMATION
		premises_address: COMMON_FIELDS.premises_address,
		unit_number: COMMON_FIELDS.unit_number,
		state: COMMON_FIELDS.state,
		city: COMMON_FIELDS.city,
		zip: COMMON_FIELDS.zip,
		county: COMMON_FIELDS.county,
		window_signs_returned: COMMON_FIELDS.window_signs_returned,
		window_sign_number: COMMON_FIELDS.window_sign_number,
		sign_removal_requested: COMMON_FIELDS.sign_removal_requested,
		loopnet_marketing_updated: COMMON_FIELDS.loopnet_marketing_updated,
		flat_fee_space: COMMON_FIELDS.flat_fee_space,

		// LEASE INFORMATION
		business_name: COMMON_FIELDS.business_name,
		type_of_business: COMMON_FIELDS.type_of_business,
		lease_start_date: COMMON_FIELDS.lease_start_date,
		lease_length: COMMON_FIELDS.lease_length,
		lease_start_date_estimated: COMMON_FIELDS.lease_start_date_estimated,
		lease_start_date_estimated_tbd:
			COMMON_FIELDS.lease_start_date_estimated_tbd,
		lease_end_date: COMMON_FIELDS.lease_end_date,
		rent_commencement: COMMON_FIELDS.rent_commencement,
		rent_commencement_estimated: COMMON_FIELDS.rent_commencement_estimated,
		rent_commencement_estimated_tbd:
			COMMON_FIELDS.rent_commencement_estimated_tbd,
		total_sf_leased: COMMON_FIELDS.total_sf_leased,
		starting_rent: COMMON_FIELDS.starting_rent,
		flat_fee_commission: COMMON_FIELDS.flat_fee_commission,
		flat_fee_commission_amount: COMMON_FIELDS.flat_fee_commission_amount,
		lease_term: COMMON_FIELDS.lease_term,
		lease_type: COMMON_FIELDS.lease_type,
		commission_based_off: COMMON_FIELDS.commission_based_off,
		rate_type: COMMON_FIELDS.rate_type,
		rate_type_cpi_percentage: COMMON_FIELDS.rate_type_cpi_percentage,
		early_possession: COMMON_FIELDS.early_possession,
		early_possession_date: COMMON_FIELDS.early_possession_date,
		contingency: COMMON_FIELDS.contingency,
		contingency_detail: COMMON_FIELDS.contingency_detail,
		date_lease_signed: COMMON_FIELDS.date_lease_signed,
		occupancy_date: COMMON_FIELDS.occupancy_date,
		occupancy_date_estimated: COMMON_FIELDS.occupancy_date_estimated,
		occupancy_date_estimated_tbd: COMMON_FIELDS.occupancy_date_estimated_tbd,
		option_renewal: COMMON_FIELDS.option_renewal,
		option_commission: COMMON_FIELDS.option_commission,
		abated_rent_ranges: COMMON_FIELDS.abated_rent_ranges,

		// LESSEES
		lessees_id: {
			label: 'Sub-Lessee ID',
			type: 'input',
		},
		lessees_name: {
			label: 'Sub-Lessee Name',
			type: 'input',
		},
		lessees_name: {
			label: 'Sub-Lessee Last Name',
			type: 'input',
		},
		lessees_address1: COMMON_FIELDS.lessees_address1,
		lessees_address2: COMMON_FIELDS.lessees_address2,
		lessees_city: COMMON_FIELDS.lessees_city,
		lessees_state: COMMON_FIELDS.lessees_state,
		lessees_zip: COMMON_FIELDS.lessees_zip,
		lessees_contact: COMMON_FIELDS.lessees_contact,
		lessees_main_phone: COMMON_FIELDS.lessees_main_phone,
		lessees_mobile_phone: COMMON_FIELDS.lessees_mobile_phone,
		lessees_work_phone: COMMON_FIELDS.lessees_work_phone,
		lessees_fax: COMMON_FIELDS.lessees_fax,
		lessees_email: COMMON_FIELDS.lessees_email,
		lessees_agency_disclosure: COMMON_FIELDS.lessees_agency_disclosure,
		lessees_llc_corp_verified: COMMON_FIELDS.lessees_llc_corp_verified,
		lessees_upload_sos: COMMON_FIELDS.lessees_upload_sos,
		lessees_spousal_consent: COMMON_FIELDS.lessees_spousal_consent,
		lessees_upload_spousal_id: COMMON_FIELDS.lessees_upload_spousal_id,
		lessees_guarantor: COMMON_FIELDS.lessees_guarantor,
		lessees_guarantor_name: COMMON_FIELDS.lessees_guarantor_name,
		lessees_upload_guarantor_id: COMMON_FIELDS.lessees_upload_guarantor_id,
		lessees_guarantor_spouse_name: COMMON_FIELDS.lessees_guarantor_spouse_name,
		lessees_can_we_reach_out_for_testimonial:
			COMMON_FIELDS.lessees_can_we_reach_out_for_testimonial,
		lessees_can_we_reach_out_for_testimonial_comment:
			COMMON_FIELDS.lessees_can_we_reach_out_for_testimonial_comment,
		lessees: {
			label: 'Sub-Lessee',
			type: 'lessees',
		},
		lessee_contacts: {
			label: 'Sub-Lessee Contact(s)',
			type: 'multi-contact',
		},

		// PRE SPLITS
		presplits: COMMON_FIELDS.presplits,

		// REFERRAL FOR LESSEE
		lessee_referral_firm: COMMON_FIELDS.lessee_referral_firm,
		lessee_referral_firm_company_name:
			COMMON_FIELDS.lessee_referral_firm_company_name,
		lessee_referral_firm_name: COMMON_FIELDS.lessee_referral_firm_name,
		lessee_referral_firm_lastname: COMMON_FIELDS.lessee_referral_firm_lastname,
		lessee_referral_firm_contact_dba_name: COMMON_FIELDS.lessee_referral_firm_contact_dba_name,
		lessee_referral_firm_address1: COMMON_FIELDS.lessee_referral_firm_address1,
		lessee_referral_firm_city: COMMON_FIELDS.lessee_referral_firm_city,
		lessee_referral_firm_state: COMMON_FIELDS.lessee_referral_firm_state,
		lessee_referral_firm_zip: COMMON_FIELDS.lessee_referral_firm_zip,
		lessee_referral_firm_contact: COMMON_FIELDS.lessee_referral_firm_contact,
		lessee_referral_firm_main_phone: COMMON_FIELDS.lessee_referral_firm_main_phone,
		lessee_referral_firm_work_phone: COMMON_FIELDS.lessee_referral_firm_work_phone,
		lessee_referral_firm_mobile_phone: COMMON_FIELDS.lessee_referral_firm_mobile_phone,
		lessee_referral_firm_fax: COMMON_FIELDS.lessee_referral_firm_fax,
		lessee_referral_firm_email: COMMON_FIELDS.lessee_referral_firm_email,
		lessee_referral_firm_taxid: COMMON_FIELDS.lessee_referral_firm_taxid,
		lessee_referral_firm_w9: COMMON_FIELDS.lessee_referral_firm_w9,
		lessee_referral_firm_w9_upload:
			COMMON_FIELDS.lessee_referral_firm_w9_upload,
		lessee_referral_firm_fee_amount_choice:
			COMMON_FIELDS.lessee_referral_firm_fee_amount_choice,
		lessee_referral_firm_fee_amount_percentage:
			COMMON_FIELDS.lessee_referral_firm_fee_amount_percentage,
		lessee_referral_firm_fee_amount: {
			label: 'Fee Amount Value',
			dollar: true,
			type: 'input',
		},

		// LESSEE OUTSIDE BROKER
		outside_broker: COMMON_FIELDS.outside_broker,
		outside_broker_company_name: COMMON_FIELDS.outside_broker_company_name,
		outside_broker_name: {
			label: 'First Name',
			type: 'input',
		},
		outside_broker_lastname: COMMON_FIELDS.outside_broker_lastname,
		outside_broker_address1: COMMON_FIELDS.outside_broker_address1,
		outside_broker_address2: COMMON_FIELDS.outside_broker_address2,
		outside_broker_city: COMMON_FIELDS.outside_broker_city,
		outside_broker_state: COMMON_FIELDS.outside_broker_state,
		outside_broker_zip: COMMON_FIELDS.outside_broker_zip,
		outside_broker_contact: COMMON_FIELDS.outside_broker_contact,
		outside_broker_main_phone: COMMON_FIELDS.outside_broker_main_phone,
		outside_broker_work_phone: COMMON_FIELDS.outside_broker_work_phone,
		outside_broker_mobile_phone: COMMON_FIELDS.outside_broker_mobile_phone,
		outside_broker_fax: COMMON_FIELDS.outside_broker_fax,
		outside_broker_email: COMMON_FIELDS.outside_broker_email,
		outside_broker_taxid: COMMON_FIELDS.outside_broker_taxid,
		outside_broker_w9: COMMON_FIELDS.outside_broker_w9,
		outside_broker_w9_upload: COMMON_FIELDS.outside_broker_w9_upload,
		outside_broker_commission: COMMON_FIELDS.outside_broker_commission,

		// LESSOR - OUTSIDE BROKER
		lessor_outside_broker: {
			label: 'Outside Broker',
			type: 'multi-contact',
		},
		lessor_outside_broker_company_name: {
			label: 'Company Name',
			type: 'input',
		},
		lessor_outside_broker_name: {
			label: 'First Name',
			type: 'input',
		},
		lessor_outside_broker_lastname:
			COMMON_FIELDS.lessor_outside_broker_lastname,
		lessor_outside_broker_address1:
			COMMON_FIELDS.lessor_outside_broker_address1,
		lessor_outside_broker_address2:
			COMMON_FIELDS.lessor_outside_broker_address2,
		lessor_outside_broker_city: COMMON_FIELDS.lessor_outside_broker_city,
		lessor_outside_broker_state: COMMON_FIELDS.lessor_outside_broker_state,
		lessor_outside_broker_zip: COMMON_FIELDS.lessor_outside_broker_zip,
		lessor_outside_broker_contact: COMMON_FIELDS.lessor_outside_broker_contact,
		lessor_outside_broker_main_phone: COMMON_FIELDS.lessor_outside_broker_main_phone,
		lessor_outside_broker_mobile_phone: COMMON_FIELDS.lessor_outside_broker_mobile_phone,
		lessor_outside_broker_work_phone: COMMON_FIELDS.lessor_outside_broker_work_phone,
		lessor_outside_broker_fax: COMMON_FIELDS.lessor_outside_broker_fax,
		lessor_outside_broker_email: COMMON_FIELDS.lessor_outside_broker_email,
		lessor_outside_broker_taxid: COMMON_FIELDS.lessor_outside_broker_taxid,
		lessor_outside_broker_w9: COMMON_FIELDS.lessor_outside_broker_w9,
		lessor_outside_broker_w9_upload:
			COMMON_FIELDS.lessor_outside_broker_w9_upload,
		lessor_outside_broker_commission:
			COMMON_FIELDS.lessor_outside_broker_commission,

		// REFERRAL
		referral_firm: COMMON_FIELDS.referral_firm,
		referral_firm_company_name: COMMON_FIELDS.referral_firm_company_name,
		referral_firm_name: COMMON_FIELDS.referral_firm_name,
		referral_firm_lastname: COMMON_FIELDS.referral_firm_lastname,
		referral_firm_contact_dba_name: COMMON_FIELDS.referral_firm_contact_dba_name,
		referral_firm_address1: COMMON_FIELDS.referral_firm_address1,
		referral_firm_city: COMMON_FIELDS.referral_firm_city,
		referral_firm_state: COMMON_FIELDS.referral_firm_state,
		referral_firm_zip: COMMON_FIELDS.referral_firm_zip,
		referral_firm_contact: COMMON_FIELDS.referral_firm_contact,
		referral_firm_main_phone: COMMON_FIELDS.referral_firm_main_phone,
		referral_firm_work_phone: COMMON_FIELDS.referral_firm_work_phone,
		referral_firm_mobile_phone: COMMON_FIELDS.referral_firm_mobile_phone,
		referral_firm_fax: COMMON_FIELDS.referral_firm_fax,
		referral_firm_email: COMMON_FIELDS.referral_firm_email,
		referral_firm_taxid: COMMON_FIELDS.referral_firm_taxid,
		referral_firm_w9: COMMON_FIELDS.referral_firm_w9,
		referral_firm_w9_upload: COMMON_FIELDS.referral_firm_w9_upload,
		referral_firm_fee_amount_choice:
			COMMON_FIELDS.referral_firm_fee_amount_choice,
		referral_firm_fee_amount_percentage:
			COMMON_FIELDS.referral_firm_fee_amount_percentage,
		referral_firm_fee_amount: COMMON_FIELDS.referral_firm_fee_amount,

		// LESSORS
		lessor_contacts: {
			label: 'Sub-Lessor Contact(s)',
			type: 'multi-contact',
		},
		lessors: {
			label: 'Sub-Lessor',
			type: 'lessors',
		},

		// BILLING INFORMATION
		billing_id: COMMON_FIELDS.billing_id,
		billing_company: COMMON_FIELDS.billing_company,
		billing_attention: COMMON_FIELDS.billing_attention,
		billing_attention_lastname: COMMON_FIELDS.billing_attention_lastname,
		billing_address1: COMMON_FIELDS.billing_address1,
		billing_address2: COMMON_FIELDS.billing_address2,
		billing_city: COMMON_FIELDS.billing_city,
		billing_state: COMMON_FIELDS.billing_state,
		billing_zip: COMMON_FIELDS.billing_zip,
		billing_phone: COMMON_FIELDS.billing_phone,
		billing_fax: COMMON_FIELDS.billing_fax,
		billing_email: COMMON_FIELDS.billing_email,

		// COMMISSION SPLITS
		commission_splits_agent: {
			label: 'Sub-Lessor Commission Splits',
			type: 'object_array',
            validations: ['required'],
		},
		commission_splits_other: {
			label: 'Other Sub-Lessor Commission Splits',
			type: 'object_array',
            validations: ['required'],
		},
		commission_splits_agent2: {
			label: 'Sub-Lessee Commission Splits',
			type: 'object_array',
            validations: ['required'],
		},
		commission_splits_other2: {
			label: 'Other Sub-Lessee Commission Splits',
			type: 'object_array',
            validations: ['required'],
		},

		// COPY OF INVOICE
		copy_of_invoice: {
			label: 'Copy of Invoice',
			options: [
				'illi Agents',
				'Outside Broker',
				'Sub-Lessor',
				'Sub-Lessee',
				'Management Company',
				'Other',
			],
			type: 'checkbox',
            validations: ['required'],
		},
		copy_of_invoice_other: COMMON_FIELDS.copy_of_invoice_other,

		// PAYMENT SCHEDULE
		payment_schedule: COMMON_FIELDS.payment_schedule,

		// COMMENTS AND SPECIAL INSTRUCTIONS
		comments_and_special_instructions:
			COMMON_FIELDS.comments_and_special_instructions,

		// FINAL
		approved_by: COMMON_FIELDS.approved_by,
		approved_by_tn: COMMON_FIELDS.approved_by_tn,
		notes: COMMON_FIELDS.notes,

		// SIGN REQUEST FORM
		access_necessary: COMMON_FIELDS.access_necessary,
		contact: COMMON_FIELDS.contact,
		order_type: COMMON_FIELDS.order_type,
		maintenance_description: COMMON_FIELDS.maintenance_description,
		sign_size: COMMON_FIELDS.sign_size,
		location: COMMON_FIELDS.location,
		rider: COMMON_FIELDS.rider,
		sign_request_reason: COMMON_FIELDS.sign_request_reason,

		// COMMISSION ADJUSTMENTS
		commission_adjustment: COMMON_FIELDS.commission_adjustment,
		commission_adjustment_details: COMMON_FIELDS.commission_adjustment_details,

		// DOCUMENTS
		documents: COMMON_FIELDS.documents
	},
	// * Tenant Rep
	TenantRep: {
		// PROPERTY INFORMATION
		date: COMMON_FIELDS.date,
		project_name: COMMON_FIELDS.project_name,
		illi_number: {
			label: 'illi #',
			type: 'input',
			readonly: true
			// prefix: 'TR',
		},
		project_address: COMMON_FIELDS.project_address,
		street1: COMMON_FIELDS.street1,
		spaces: COMMON_FIELDS.spaces,
		la_city_verification: COMMON_FIELDS.la_city_verification,
		illi_represented_lessee_agents:
			COMMON_FIELDS.illi_represented_lessee_agents,
		property_type: COMMON_FIELDS.property_type,
		type_of_lease: {
			label: 'Type of Lease',
			type: 'select',
			options: ['New Lease'],
			defaultValue: ['New Lease'],
			readonly: true,
		},
		can_deal_be_submitted_to_public_relations:
			COMMON_FIELDS.can_deal_be_submitted_to_public_relations,
		can_deal_be_submitted_to_public_relations_comment:
			COMMON_FIELDS.can_deal_be_submitted_to_public_relations_comment,

		commission_distribution_lessee: COMMON_FIELDS.commission_distribution_lessee,
		commission_distribution_lessor: COMMON_FIELDS.commission_distribution_lessor,

		// PREMISES INFORMATION
		premises_address: COMMON_FIELDS.premises_address,
		unit_number: COMMON_FIELDS.unit_number,
		city: COMMON_FIELDS.city,
		zip: COMMON_FIELDS.zip,
		county: COMMON_FIELDS.county,
		window_signs_returned: COMMON_FIELDS.window_signs_returned,
		window_sign_number: COMMON_FIELDS.window_sign_number,
		sign_removal_requested: COMMON_FIELDS.sign_removal_requested,
		loopnet_marketing_updated: COMMON_FIELDS.loopnet_marketing_updated,
		flat_fee_space: COMMON_FIELDS.flat_fee_space,

		// LEASE INFORMATION
		business_name: COMMON_FIELDS.business_name,
		type_of_business: COMMON_FIELDS.type_of_business,
		lease_start_date: COMMON_FIELDS.lease_start_date,
		lease_length: COMMON_FIELDS.lease_length,
		lease_start_date_estimated: COMMON_FIELDS.lease_start_date_estimated,
		lease_start_date_estimated_tbd:
			COMMON_FIELDS.lease_start_date_estimated_tbd,
		lease_end_date: COMMON_FIELDS.lease_end_date,
		rent_commencement: COMMON_FIELDS.rent_commencement,
		rent_commencement_estimated: COMMON_FIELDS.rent_commencement_estimated,
		rent_commencement_estimated_tbd:
			COMMON_FIELDS.rent_commencement_estimated_tbd,
		total_sf_leased: COMMON_FIELDS.total_sf_leased,
		starting_rent: COMMON_FIELDS.starting_rent,
		flat_fee_commission: COMMON_FIELDS.flat_fee_commission,
		flat_fee_commission_amount: COMMON_FIELDS.flat_fee_commission_amount,
		commission_based_off: COMMON_FIELDS.commission_based_off,
		rate_type: COMMON_FIELDS.rate_type,
		rate_type_cpi_percentage: COMMON_FIELDS.rate_type_cpi_percentage,
		lease_term: COMMON_FIELDS.lease_term,
		lease_type: COMMON_FIELDS.lease_type,
		early_possession: COMMON_FIELDS.early_possession,
		early_possession_date: COMMON_FIELDS.early_possession_date,
		contingency: COMMON_FIELDS.contingency,
		contingency_detail: COMMON_FIELDS.contingency_detail,
		date_lease_signed: COMMON_FIELDS.date_lease_signed,
		occupancy_date: COMMON_FIELDS.occupancy_date,
		occupancy_date_estimated: COMMON_FIELDS.occupancy_date_estimated,
		occupancy_date_estimated_tbd: COMMON_FIELDS.occupancy_date_estimated_tbd,
		option_renewal: COMMON_FIELDS.option_renewal,
		option_commission: COMMON_FIELDS.option_commission,
		abated_rent_ranges: COMMON_FIELDS.abated_rent_ranges,

		// LESSEES
		lessees_id: {
			label: 'Lessee ID',
			type: 'input',
		},
		lessees_name: {
			label: 'Lessee Name',
			type: 'input',
		},
		lessees_name: {
			label: 'Lessee Last Name',
			type: 'input',
		},
		lessees_address1: COMMON_FIELDS.lessees_address1,
		lessees_address2: COMMON_FIELDS.lessees_address2,
		lessees_city: COMMON_FIELDS.lessees_city,
		lessees_state: COMMON_FIELDS.lessees_state,
		lessees_zip: COMMON_FIELDS.lessees_zip,
		lessees_contact: COMMON_FIELDS.lessees_contact,
		lessees_main_phone: COMMON_FIELDS.lessees_main_phone,
		lessees_work_phone: COMMON_FIELDS.lessees_work_phone,
		lessees_mobile_phone: COMMON_FIELDS.lessees_mobile_phone,
		lessees_fax: COMMON_FIELDS.lessees_fax,
		lessees_email: COMMON_FIELDS.lessees_email,
		lessees_agency_disclosure: COMMON_FIELDS.lessees_agency_disclosure,
		lessees_llc_corp_verified: COMMON_FIELDS.lessees_llc_corp_verified,
		lessees_upload_sos: COMMON_FIELDS.lessees_upload_sos,
		lessees_spousal_consent: COMMON_FIELDS.lessees_spousal_consent,
		lessees_upload_spousal_id: COMMON_FIELDS.lessees_upload_spousal_id,
		lessees_guarantor: COMMON_FIELDS.lessees_guarantor,
		lessees_guarantor_name: COMMON_FIELDS.lessees_guarantor_name,
		lessees_upload_guarantor_id: COMMON_FIELDS.lessees_upload_guarantor_id,
		lessees_guarantor_spouse_name: COMMON_FIELDS.lessees_guarantor_spouse_name,
		lessees_can_we_reach_out_for_testimonial:
			COMMON_FIELDS.lessees_can_we_reach_out_for_testimonial,
		lessees_can_we_reach_out_for_testimonial_comment:
			COMMON_FIELDS.lessees_can_we_reach_out_for_testimonial_comment,
		lessees: COMMON_FIELDS.lessees,
		lessee_contacts: COMMON_FIELDS.lessee_contacts,

		// PRE SPLITS
		presplits: COMMON_FIELDS.presplits,

		// REFERRAL FOR LESSEE
		lessee_referral_firm: COMMON_FIELDS.lessee_referral_firm,
		lessee_referral_firm_name: COMMON_FIELDS.lessee_referral_firm_name,
		lessee_referral_firm_lastname: COMMON_FIELDS.lessee_referral_firm_lastname,
		lessee_referral_firm_contact_dba_name: COMMON_FIELDS.lessee_referral_firm_contact_dba_name,
		lessee_referral_firm_address1: COMMON_FIELDS.lessee_referral_firm_address1,
		lessee_referral_firm_city: COMMON_FIELDS.lessee_referral_firm_city,
		lessee_referral_firm_state: COMMON_FIELDS.lessee_referral_firm_state,
		lessee_referral_firm_zip: COMMON_FIELDS.lessee_referral_firm_zip,
		lessee_referral_firm_contact: COMMON_FIELDS.lessee_referral_firm_contact,
		lessee_referral_firm_main_phone: COMMON_FIELDS.lessee_referral_firm_main_phone,
		lessee_referral_firm_work_phone: COMMON_FIELDS.lessee_referral_firm_work_phone,
		lessee_referral_firm_mobile_phone: COMMON_FIELDS.lessee_referral_firm_mobile_phone,
		lessee_referral_firm_fax: COMMON_FIELDS.lessee_referral_firm_fax,
		lessee_referral_firm_email: COMMON_FIELDS.lessee_referral_firm_email,
		lessee_referral_firm_taxid: COMMON_FIELDS.lessee_referral_firm_taxid,
		lessee_referral_firm_w9: COMMON_FIELDS.lessee_referral_firm_w9,
		lessee_referral_firm_w9_upload:
			COMMON_FIELDS.lessee_referral_firm_w9_upload,
		lessee_referral_firm_fee_amount:
			COMMON_FIELDS.lessee_referral_firm_fee_amount,

		// LESSEE OUTSIDE BROKER
		outside_broker: COMMON_FIELDS.outside_broker,
		outside_broker_name: {
			label: 'Company Name / First Name',
			type: 'input',
		},
		outside_broker_lastname: COMMON_FIELDS.outside_broker_lastname,
		outside_broker_address1: COMMON_FIELDS.outside_broker_address1,
		outside_broker_address2: COMMON_FIELDS.outside_broker_address2,
		outside_broker_city: COMMON_FIELDS.outside_broker_city,
		outside_broker_state: COMMON_FIELDS.outside_broker_state,
		outside_broker_zip: COMMON_FIELDS.outside_broker_zip,
		outside_broker_contact: COMMON_FIELDS.outside_broker_contact,
		outside_broker_main_phone: COMMON_FIELDS.outside_broker_main_phone,
		outside_broker_work_phone: COMMON_FIELDS.outside_broker_work_phone,
		outside_broker_mobile_phone: COMMON_FIELDS.outside_broker_mobile_phone,
		outside_broker_fax: COMMON_FIELDS.outside_broker_fax,
		outside_broker_email: COMMON_FIELDS.outside_broker_email,
		outside_broker_taxid: COMMON_FIELDS.outside_broker_taxid,
		outside_broker_w9: COMMON_FIELDS.outside_broker_w9,
		outside_broker_w9_upload: COMMON_FIELDS.outside_broker_w9_upload,
		outside_broker_commission: COMMON_FIELDS.outside_broker_commission,

		// LESSOR - OUTSIDE BROKER
		lessor_outside_broker: {
			label: 'Outside Broker',
			type: 'multi-contact',
		},
		lessor_outside_broker_name: {
			label: 'Company Name',
			type: 'input',
		},
		lessor_outside_broker_lastname:
			COMMON_FIELDS.lessor_outside_broker_lastname,
		lessor_outside_broker_address1:
			COMMON_FIELDS.lessor_outside_broker_address1,
		lessor_outside_broker_address2:
			COMMON_FIELDS.lessor_outside_broker_address2,
		lessor_outside_broker_city: COMMON_FIELDS.lessor_outside_broker_city,
		lessor_outside_broker_state: COMMON_FIELDS.lessor_outside_broker_state,
		lessor_outside_broker_zip: COMMON_FIELDS.lessor_outside_broker_zip,
		lessor_outside_broker_contact: COMMON_FIELDS.lessor_outside_broker_contact,
		lessor_outside_broker_main_phone: COMMON_FIELDS.lessor_outside_broker_main_phone,
		lessor_outside_broker_work_phone: COMMON_FIELDS.lessor_outside_broker_work_phone,
		lessor_outside_broker_mobile_phone: COMMON_FIELDS.lessor_outside_broker_mobile_phone,
		lessor_outside_broker_fax: COMMON_FIELDS.lessor_outside_broker_fax,
		lessor_outside_broker_email: COMMON_FIELDS.lessor_outside_broker_email,
		lessor_outside_broker_taxid: COMMON_FIELDS.lessor_outside_broker_taxid,
		lessor_outside_broker_w9: COMMON_FIELDS.lessor_outside_broker_w9,
		lessor_outside_broker_w9_upload:
			COMMON_FIELDS.lessor_outside_broker_w9_upload,
		lessor_outside_broker_commission:
			COMMON_FIELDS.lessor_outside_broker_commission,

		// REFERRAL
		referral_firm: COMMON_FIELDS.referral_firm,
		referral_firm_name: COMMON_FIELDS.referral_firm_name,
		referral_firm_lastname: COMMON_FIELDS.referral_firm_lastname,
		referral_firm_contact_dba_name: COMMON_FIELDS.referral_firm_contact_dba_name,
		referral_firm_address1: COMMON_FIELDS.referral_firm_address1,
		referral_firm_city: COMMON_FIELDS.referral_firm_city,
		referral_firm_state: COMMON_FIELDS.referral_firm_state,
		referral_firm_zip: COMMON_FIELDS.referral_firm_zip,
		referral_firm_contact: COMMON_FIELDS.referral_firm_contact,
		referral_firm_main_phone: COMMON_FIELDS.referral_firm_main_phone,
		referral_firm_work_phone: COMMON_FIELDS.referral_firm_work_phone,
		referral_firm_mobile_phone: COMMON_FIELDS.referral_firm_mobile_phone,
		referral_firm_fax: COMMON_FIELDS.referral_firm_fax,
		referral_firm_email: COMMON_FIELDS.referral_firm_email,
		referral_firm_taxid: COMMON_FIELDS.referral_firm_taxid,
		referral_firm_w9: COMMON_FIELDS.referral_firm_w9,
		referral_firm_w9_upload: COMMON_FIELDS.referral_firm_w9_upload,
		referral_firm_fee_amount: COMMON_FIELDS.referral_firm_fee_amount,

		// LESSORS
		lessor_contacts: COMMON_FIELDS.lessor_contacts,
		lessors: COMMON_FIELDS.lessors,

		// BILLING INFORMATION
		billing_id: COMMON_FIELDS.billing_id,
		billing_company: COMMON_FIELDS.billing_company,
		billing_attention: COMMON_FIELDS.billing_attention,
		billing_attention_lastname: COMMON_FIELDS.billing_attention_lastname,
		billing_address1: COMMON_FIELDS.billing_address1,
		billing_address2: COMMON_FIELDS.billing_address2,
		billing_city: COMMON_FIELDS.billing_city,
		billing_state: COMMON_FIELDS.billing_state,
		billing_zip: COMMON_FIELDS.billing_zip,
		billing_phone: COMMON_FIELDS.billing_phone,
		billing_fax: COMMON_FIELDS.billing_fax,
		billing_email: COMMON_FIELDS.billing_email,

		// COMMISSION SPLITS
		commission_splits_agent: COMMON_FIELDS.commission_splits_agent,
		commission_splits_other: COMMON_FIELDS.commission_splits_other,
		commission_splits_agent2: COMMON_FIELDS.commission_splits_agent2,
		commission_splits_other2: COMMON_FIELDS.commission_splits_other2,

		// COPY OF INVOICE
		copy_of_invoice: COMMON_FIELDS.copy_of_invoice,
		copy_of_invoice_other: COMMON_FIELDS.copy_of_invoice_other,

		// PAYMENT SCHEDULE
		payment_schedule: COMMON_FIELDS.payment_schedule,

		// SIGN REQUEST FORM
		access_necessary: COMMON_FIELDS.access_necessary,
		contact: COMMON_FIELDS.contact,
		order_type: COMMON_FIELDS.order_type,
		maintenance_description: COMMON_FIELDS.maintenance_description,
		sign_size: COMMON_FIELDS.sign_size,
		location: COMMON_FIELDS.location,
		rider: COMMON_FIELDS.rider,
		sign_support_configuration: COMMON_FIELDS.sign_support_configuration,
		sign_request_reason: COMMON_FIELDS.sign_request_reason,

		// COMMENTS AND SPECIAL INSTRUCTIONS
		comments_and_special_instructions:
			COMMON_FIELDS.comments_and_special_instructions,

		// FINAL
		approved_by: COMMON_FIELDS.approved_by,
		approved_by_tn: COMMON_FIELDS.approved_by_tn,
		notes: COMMON_FIELDS.notes,

		// COMMISSION ADJUSTMENTS
		commission_adjustment: COMMON_FIELDS.commission_adjustment,
		commission_adjustment_details: COMMON_FIELDS.commission_adjustment_details,

		// DOCUMENTS
		documents: COMMON_FIELDS.documents
	},
};

export const SECTION = {
	// * Commission
	Commission: {
		names: {
			1: 'Property Information',
			2: 'Premises Information',
			3: 'Lease Information',
			4: 'Pre-Splits',
			5: 'Lessee',
			6: 'Lessor',
			7: 'Commission Splits',
			8: 'Commission Adjustments',
			9: 'Billing Information',
			10: 'Copy of Invoice',
			11: 'Payment Schedule',
			12: 'Comments and Special Instructions',
			13: 'Documents',
			14: 'Internal Review',
			15: 'SUBMIT',
		},
		requirements: {
			'Property Information': ['date', 'project_name'],
			'Premises Information': ['premises_address'],
			'Lease Information': [
				'business_name',
				'type_of_business',
				'lease_start_date',
				'lease_end_date',
				'rent_commencement',
				'total_sf_leased',
				'starting_rent',
				'date_lease_signed',
				'occupancy_date',
				'lease_term',
				'option_renewal',
				'option_commission',
				'lease_type',
				'early_possession',
			],
			Lessee: ['lessees'],
			Lessor: ['lessors'],
			'Payment Schedule': ['payment_schedule'],
			'Commission Splits': ['commission_splits_agent'],
			'Copy of Invoice': ['copy_of_invoice'],
			'Billing Information': [
				'billing_company',
				'billing_address1',
				'billing_city',
				'billing_state',
				'billing_zip',
			],
			'Comments and Special Instructions': [
				'comments_and_special_instructions',
			],
		},
	},
	// * Consulting
	Consulting: {
		names: {
			1: 'Property Information',
			2: 'Premises Information',
			3: 'Pre-Splits',
			4: 'Lessee/Buyer',
			5: 'Lessor/Seller',
			6: 'Commission Splits',
			7: 'Billing Information',
			8: 'Commission Adjustments',
			9: 'Copy of Invoice',
			10: 'Payment Schedule',
			11: 'Comments and Special Instructions',
			12: 'Documents',
			13: 'Internal Review',
			14: 'SUBMIT',
		},
		requirements: {
			'Property Information': ['date', 'project_name'],
			'Premises Information': ['premises_address'],
			'Lease Information': [
				'business_name',
				'type_of_business',
				'lease_start_date',
				'lease_end_date',
				'rent_commencement',
				'total_sf_leased',
				'starting_rent',
				'date_lease_signed',
				'occupancy_date',
				'lease_term',
				'option_renewal',
				'option_commission',
				'lease_type',
				'early_possession',
			],
			'Lessor/Buyer': ['lessees'],
			'Lessor/Seller': ['lessors'],
			'Payment Schedule': ['payment_schedule'],
			'Commission Splits': ['commission_splits_agent'],
			'Copy of Invoice': ['copy_of_invoice'],
			'Billing Information': [
				'billing_company',
				'billing_address1',
				'billing_city',
				'billing_state',
				'billing_zip',
			],
			'Comments and Special Instructions': [
				'comments_and_special_instructions',
			],
		},
	},
	// * Lease
	Lease: {
		names: {
			1: 'Property Information',
			2: 'Premises Information',
			3: 'Lease Information',
			4: 'Pre-Splits',
			5: 'Lessee',
			6: 'Lessor',
			7: 'Commission Splits',
			8: 'Commission Adjustments',
			9: 'Billing Information',
			10: 'Copy of Invoice',
			11: 'Payment Schedule',
			12: 'Comments and Special Instructions',
			13: 'Documents',
			14: 'Internal Review',
			15: 'SUBMIT',
		},
		requirements: {
			'Property Information': ['date', 'project_name'],
			'Premises Information': ['premises_address'],
			'Lease Information': [
				'business_name',
				'type_of_business',
				'lease_start_date',
				'rent_commencement',
				'date_lease_signed',
				'occupancy_date',
				'lease_term',
				'option_renewal',
				'option_commission',
				'lease_type',
				'early_possession',
			],
			Lessee: ['lessees'],
			Lessor: ['lessors'],
			'Commission Splits': ['commission_splits_agent'],
			'Copy of Invoice': ['copy_of_invoice'],
			'Billing Information': [
				'billing_company',
				'billing_address1',
				'billing_city',
				'billing_state',
				'billing_zip',
			],
			'Comments and Special Instructions': [
				'comments_and_special_instructions',
			],
		},
	},
	// * LeaseAssignmentFee
	LeaseAssignmentFee: {
		names: {
			1: 'Property Information',
			2: 'Premises Information',
			3: 'Pre-Splits',
			4: 'Lessor',
			5: 'Assignee',
			6: 'Assignor',
			7: 'Commission Splits',
			8: 'Billing Information',
			9: 'Commission Adjustments',
			10: 'Copy of Invoice',
			11: 'Payment Schedule',
			12: 'Comments and Special Instructions',
			13: 'Documents',
			14: 'Internal Review',
			15: 'SUBMIT',
		},
		requirements: {
			'Property Information': ['date', 'project_name', 'pm_number'],
			'Premises Information': ['premises_address'],
			'Lease Information': [
				'business_name',
				'type_of_business',
				'lease_start_date',
				'lease_end_date',
				'rent_commencement',
				'total_sf_leased',
				'starting_rent',
				'date_lease_signed',
				'occupancy_date',
				'lease_term',
				'option_renewal',
				'option_commission',
				'lease_type',
				'early_possession',
			],
			Assignee: ['lessees'],
			Lessor: ['lessors'],
			Assignor: ['assignors'],
			'Payment Schedule': ['payment_schedule'],
			'Commission Splits': ['commission_splits_agent'],
			'Copy of Invoice': ['copy_of_invoice'],
			'Billing Information': [
				'billing_company',
				'billing_address1',
				'billing_city',
				'billing_state',
				'billing_zip',
			],
			'Comments and Special Instructions': [
				'comments_and_special_instructions',
			],
		},
	},
	// * NoListing
	NoListing: {
		names: {
			1: 'Property Information',
			2: 'Premises Information',
			3: 'Lease Information',
			4: 'Pre-Splits',
			5: 'Lessee',
			6: 'Lessor',
			7: 'Commission Splits',
			8: 'Commission Adjustments',
			9: 'Billing Information',
			10: 'Copy of Invoice',
			11: 'Payment Schedule',
			12: 'Comments and Special Instructions',
			13: 'Documents',
			14: 'Internal Review',
			15: 'SUBMIT',
		},
		requirements: {
			'Property Information': ['date', 'project_name', 'illi_number'],
			'Premises Information': ['premises_address'],
			'Lease Information': [
				'business_name',
				'type_of_business',
				'lease_start_date',
				'lease_end_date',
				'rent_commencement',
				'total_sf_leased',
				'starting_rent',
				'date_lease_signed',
				'occupancy_date',
				'lease_term',
				'option_renewal',
				'option_commission',
				'lease_type',
				'early_possession',
			],
			Lessee: ['lessees'],
			Lessor: ['lessors'],
			'Payment Schedule': ['payment_schedule'],
			'Commission Splits': ['commission_splits_agent'],
			'Copy of Invoice': ['copy_of_invoice'],
			'Billing Information': [
				'billing_company',
				'billing_address1',
				'billing_city',
				'billing_state',
				'billing_zip',
			],
			'Comments and Special Instructions': [
				'comments_and_special_instructions',
			],
		},
	},
	// * Residential
	Residential: {
		names: {
			1: 'Property Information',
			2: 'Premises Information',
			3: 'Lease Information',
			4: 'Pre-Splits',
			5: 'Lessee',
			6: 'Lessor',
			7: 'Commission Splits',
			8: 'Commission Adjustments',
			9: 'Billing Information',
			10: 'Copy of Invoice',
			11: 'Payment Schedule',
			12: 'Comments and Special Instructions',
			13: 'Documents',
			14: 'Internal Review',
			15: 'SUBMIT',
		},
		requirements: {
			'Property Information': ['date', 'project_name'],
			'Premises Information': ['premises_address'],
			'Lease Information': [
				'lease_start_date',
				'lease_end_date',
				'rent_commencement',
				'date_lease_signed',
				'occupancy_date',
				'flat_fee_commission',
				'lease_term',
			],
			Lessee: ['lessees'],
			Lessor: ['lessors'],
			'Commission Splits': ['commission_splits_agent'],
			'Copy of Invoice': ['copy_of_invoice'],
			'Billing Information': [
				'billing_company',
				'billing_address1',
				'billing_city',
				'billing_state',
				'billing_zip',
			],
			'Comments and Special Instructions': [
				'comments_and_special_instructions',
			],
		},
	},
	// * Sale
	Sale: {
		names: {
			1: 'Property Information',
			2: 'Marketing Information',
			3: 'Escrow Information',
			4: 'Sale Information',
			5: 'Pre-Splits',
			6: 'Buyer',
			7: 'Seller',
			8: 'Commission Splits',
			9: 'Commission Adjustments',
			10: 'Comments and Special Instructions',
			11: 'Documents',
			12: 'Internal Review',
			13: 'SUBMIT',
		},
		requirements: {
			'Property Information': ['date', 'project_name'],
			'Marketing Information': ['premises_address'],
			'Lease Information': [
				'business_name',
				'type_of_business',
				'lease_start_date',
				'lease_end_date',
				'rent_commencement',
				'total_sf_leased',
				'starting_rent',
				'date_lease_signed',
				'occupancy_date',
				'lease_term',
				'option_renewal',
				'option_commission',
				'lease_type',
				'early_possession',
			],
			Buyer: ['lessees'],
			Seller: ['lessors'],
			'Escrow Information': ['escrow_officer_name'],
			'Sale Information': ['sale_purchase_price'],
			'Commission Splits': ['commission_splits_agent'],
			'Comments and Special Instructions': [
				'comments_and_special_instructions',
			],
		},
	},
	// * Sublease
	Sublease: {
		names: {
			1: 'Property Information',
			2: 'Premises Information',
			3: 'Lease Information',
			4: 'Pre-Splits',
			5: 'Sub-Lessee',
			6: 'Sub-Lessor',
			7: 'Commission Splits',
			8: 'Commission Adjustments',
			9: 'Billing Information',
			10: 'Copy of Invoice',
			11: 'Payment Schedule',
			12: 'Comments and Special Instructions',
			13: 'Documents',
			14: 'Internal Review',
			15: 'SUBMIT',
		},
		requirements: {
			'Property Information': ['date', 'project_name'],
			'Premises Information': ['premises_address'],
			'Lease Information': [
				// 'business_name',
				// 'type_of_business',
				// 'lease_start_date',
				// 'lease_end_date',
				// 'rent_commencement',
				// 'total_sf_leased',
				// 'starting_rent',
				// 'date_lease_signed',
				// 'occupancy_date',
				// 'lease_term',
				// 'option_renewal',
				// 'option_commission',
				// 'lease_type',
				// 'early_possession',
			],
			'Sub-Lessee': ['lessees'],
			'Sub-Lessor': ['lessors'],
			'Payment Schedule': ['payment_schedule'],
			'Commission Splits': ['commission_splits_agent'],
			'Copy of Invoice': ['copy_of_invoice'],
			'Billing Information': [
				'billing_company',
				'billing_address1',
				'billing_city',
				'billing_state',
				'billing_zip',
			],
			'Comments and Special Instructions': [
				'comments_and_special_instructions',
			],
		},
	},
	// * TenantRep
	TenantRep: {
		names: {
			1: 'Property Information',
			2: 'Premises Information',
			3: 'Lease Information',
			4: 'Pre-Splits',
			5: 'Lessee',
			6: 'Lessor',
			7: 'Commission Splits',
			8: 'Commission Adjustments',
			9: 'Billing Information',
			10: 'Copy of Invoice',
			11: 'Payment Schedule',
			12: 'Comments and Special Instructions',
			13: 'Documents',
			14: 'Internal Review',
			15: 'SUBMIT',
		},
		requirements: {
			'Property Information': ['date', 'project_name', 'illi_number'],
			'Premises Information': ['premises_address'],
			'Lease Information': [
				'business_name',
				'type_of_business',
				'lease_start_date',
				'lease_end_date',
				'rent_commencement',
				'total_sf_leased',
				'starting_rent',
				'date_lease_signed',
				'occupancy_date',
				'lease_term',
				'option_renewal',
				'option_commission',
				'lease_type',
				'early_possession',
			],
			Lessee: ['lessees'],
			Lessor: ['lessors'],
			'Payment Schedule': ['payment_schedule'],
			'Commission Splits': ['commission_splits_agent'],
			'Copy of Invoice': ['copy_of_invoice'],
			'Billing Information': [
				'billing_company',
				'billing_address1',
				'billing_city',
				'billing_state',
				'billing_zip',
			],
			'Comments and Special Instructions': [
				'comments_and_special_instructions',
			],
		},
	},
};

export const outside_brokers_fields = {
	company_name: {
		label: 'Company Name',
		type: 'input',
		readOnly: true
	},
	first_name: {
		label: 'First Name',
		type: 'input',
		readOnly: true
	},
	last_name: {
		label: 'Last Name',
		type: 'input',
		readOnly: true
	},
	dba_name: {
		label: 'DBA',
		type: 'input',
		readOnly: true
	},
	address1: {
		label: 'Address 1',
		type: 'input',
		required: true
	},
	address2: {
		label: 'Address 2',
		type: 'input',
	},
	city: {
		label: 'City',
		type: 'input',
		required: true
	},
	state: {
		label: 'State',
		type: 'state',
		required: true
	},
	zip: {
		label: 'Zip',
		type: 'input',
	},
	agents: {
		label: 'Agent(s)',
		type: 'input',
		required: true
	},
	main_phone: {
		label: 'Main Phone',
		type: 'input',
	},
	work_phone: {
		label: 'Work Phone',
		type: 'input',
	},
	mobile_phone: {
		label: 'Mobile Phone',
		type: 'input',
	},
	fax: {
		label: 'Fax',
		type: 'input',
	},
	email: {
		label: 'Email',
		type: 'input',
		required: true
	},
	tax_id: {
		label: 'Tax ID',
		type: 'input',
	},
	w9: {
		label: 'W9',
		type: 'radio',
		options: ['Yes', 'No'],
		required: true
	},
	w9_upload: {
		label: 'W9 File',
		type: 'file_upload',
		filetypes: ['pdf', 'png', 'jpg'],
	},
	commission: {
		label: 'Outside Broker Commission',
		type: 'input',
		inputType: 'number',
		required: true,
		percent: true,
		suffix: '%'
	}
}
