import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { connect } from "react-redux";
import * as actions from "store/actions";
import { clone } from "shared/utility";
import Button from "components/CustomButtons/Button.jsx";
import { IconButton } from "@material-ui/core";
import CustomDialogWithContact from "../../../components/CustomDialog/CustomDialogWithContact";
import CompaniesEditContact from "./CompaniesEditContact";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import Notification from "components/Notification/Notification";
import NotificationAsk from "components/Notification/NotificationAsk";
import { TextField } from "@material-ui/core";
import { FadeLoader } from "react-spinners";
import { get } from 'lodash';
import { getUser } from "../../../shared/authValidation";
import axios from 'store/axios/axios-crm';
import { handleError } from '../../../shared/utility';

const CompaniesContacts = (props) => {
    const loggedInUser = getUser();
    const [addContact, setAddContact] = useState(false);
    const [updateContact, setUpdateContact] = useState(false);
    const [openedEditContact, setOpenedEditContact] = useState(false);
    const [contactId, setContactId] = useState(null);
    const [contactCompanies, setContactCompanies] = useState([]);
    const [message, setMessage] = useState("");
    const [notificationAsk, setNotificationAsk] = useState("");
    const [dataToDelete, setDataToDelete] = useState(null);

    const [companyContactsData, setCompanyContactsData] = useState(null);

    const { canEdit, canDelete, contactsForAdd, setContactsForAdd } = props;

    const getCompanyContacts = async (company_id) => {
        const action = { data: { company_id } };
        try {
            const data = action.data;
            let params = '';
            if (data.company_id) {
                params = `?id=${data.company_id}`;
            } else if (data.contact_id) {
                params = `?contact_id=${data.contact_id}`;
            }
            const response = await axios.get('/company_contacts' + params);
            const company_contacts = response.data;
            let cc = [];
            for (let i = 0; i < company_contacts.length; i++) {
                const c = company_contacts[i];
                // get contact info
                try {
                    const company_response = await axios.get('/company?id=' + c.company_id);
                    c.company_data = company_response.data;
                } catch(e) {
                    handleError(e);
                }
                let contact_response = null;
                // get company info
                try {
                    contact_response = await axios.get('/contact?id=' + c.contact_id);
                    c.contact_data = contact_response.data;
                } catch(e) {
                    handleError(e);
                }
                
                // get dba info 
                if (c.dba_id) {
                    try {
                        const dbas_response = await axios.get('/company_dbas?dba_id=' + c.dba_id);
                        c.dba_data = dbas_response.data;
                    } catch(e) {
                        handleError(e)
                    }
                    
                }
                if (c.company_data && c.contact_data) {
                    c.contact_data = contact_response.data;
                    cc.push(c);
                }
            }
            console.log('company contacts', cc);
            setCompanyContactsData(cc);
        } catch (e) {
            handleError(e);
            //setMessage('Unable to retrieve company contacts');
        }
    }

    const createCompanyContacts = async (data) => {
        try {
            await axios.post('/company_contacts', data);
            getCompanyContacts(data.company_id);
        } catch (e) {
            handleError(e);
            setMessage('Unable to create company contact');
        }
    }

    const updateCompanyContacts = async (data) => {
        try {
            await axios.put('/company_contacts', data);
            getCompanyContacts(data.company_id);
            setMessage('Updated company contact');
        } catch (e) {
            handleError(e);
            setMessage('Unable to update company contact');
        }
    }

    const deleteCompanyContacts = async (data) => {
        try {
            await axios.delete('/company_contacts?id=' + data.id);
            getCompanyContacts(data.company_id);
        } catch (e) {
            handleError(e);
            setMessage('Unable to delete company contact');
        }
    }

    useEffect(() => {
        if ((props.action === "Edit" || props.action === "View") && props.dataObj) {
            if (props.dataObj.id) {
                getCompanyContacts(props.dataObj.id);
            }
        } else {
            // clear contacts
            getCompanyContacts(9999999999999999);
        }
    }, []);
    
    useEffect(() => {
        if (props.contactToAdd) {
            const found = contactsForAdd.find(con => con.contact_id === props.contactToAdd.contact_id);
            if (!found) {
                const newContactsToAdd = contactsForAdd.concat([props.contactToAdd]);
                setContactsForAdd(clone(newContactsToAdd))
            }
        }
    }, [props.contactToAdd])

    useEffect(() => {
        if (companyContactsData) {
            setContactCompanies(companyContactsData);
        }
    }, [companyContactsData]);

    useEffect(() => {
        if (updateContact && props.success === "Successfully updated company contact") {
            setMessage(props.success);
            setUpdateContact(false);
        }
    }, [props.success]);

    useEffect(() => {
        if ((props.action === "Edit" || props.action === "View") && props.dataObj) {
            if (props.dataObj.id) {
                getCompanyContacts(props.dataObj.id);
            }
        }
    }, [openedEditContact]);

    let names = [];
    const contactNames = () => {
        if (props.contactData) {
            props.contactData.map((data, i) => {
                names.push(`${data.first_name} ${data.last_name}`);
            });
        }
    };

    contactNames();


    const choose = (chosen) => {
        // open property
        if (props.action === 'Edit') {
            setUpdateContact(true);
            createCompanyContacts({
                contact_id: chosen.id,
                company_id: props.dataObj ? props.dataObj.id : null,
                is_company: true,
                department: chosen.department ? chosen.department : "",
                title: chosen.title ? chosen.title : "",
                email: chosen.email ? chosen.email : "",
                phone: chosen.main_phone ? chosen.main_phone : "",
            });
        } else if (props.action === 'Add') {
            const newData = contactsForAdd.concat([
                {
                    contact_id: chosen.id,
                    company_id: null,
                    is_company: true,
                    department: chosen.department ? chosen.department : "",
                    title: chosen.title ? chosen.title : "",
                    email: chosen.email ? chosen.email : "",
                    phone: chosen.main_phone ? chosen.main_phone : "",
                    contact_data: chosen
                }
            ]);
            setContactsForAdd(newData)
        }
        setAddContact(false);
    }

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <h3 className="red-text">Contacts :</h3>
                {(props.action === 'Edit' || props.action === 'Add') && <Button
                    color="primary"
                    onClick={() => {
                        setAddContact(true);
                    }}
                >
                    <i className="fas fa-plus"></i> Add Contact
                </Button>}
                {props.action !== 'Add' && <h4>{contactCompanies.length} Contact(s):</h4>}
                {props.action === 'Add' && <h4>{contactsForAdd.length} Contact(s):</h4>}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                {props.loading && (
                    <div>
                        <FadeLoader />
                    </div>
                )}
                {!props.loading && (
                    <GridContainer>

                        {/* ADD MODE */}
                        {props.action === 'Add' && <div>
                            {contactsForAdd.map((cd,i) => {
                                const cc = cd.contact_data;
                                return (
                                <GridItem xs={12} sm={12} md={4} key={`${i}-contact-cont-add`}>
                                    <div style={{ fontSize: 16 }} className="boxed">
                                        <div style={{ float: "right" }}>
                                            <IconButton
                                                onClick={() => {
                                                    setContactsForAdd(contactsForAdd.filter((xcd,xi) => {
                                                        if (xi === i) return false;
                                                        return true
                                                    }))
                                                }}
                                            >
                                                <i className="fas fa-trash-alt red-text"></i>
                                            </IconButton>
                                        </div>
                                        <span className="hoverable bold-on-hover">
                                            <small>#{i + 1}</small>
                                            <h3>
                                                <strong>
                                                    {cc ? cc.first_name : ""} {cc ? cc.last_name : ""}
                                                </strong>
                                            </h3>
                                        </span>
                                        <CustomInput
                                            label="Company Department"
                                            value={cd.department}
                                            onChange={(e) => {
                                                setContactsForAdd(contactsForAdd.map((cfa,cfi) => {
                                                    if (cfi === i) {
                                                        cfa.department = e.target.value;
                                                    }
                                                    return cfa;
                                                }))
                                            }}
                                        />
                                        <CustomInput
                                            label="Company Title"
                                            value={cd.title}
                                            onChange={(e) => {
                                                setContactsForAdd(contactsForAdd.map((cfa,cfi) => {
                                                    if (cfi === i) {
                                                        cfa.title = e.target.value;
                                                    }
                                                    return cfa;
                                                }))
                                            }}
                                        />
                                        <CustomInput
                                            label="Company Phone"
                                            value={cd.phone}
                                            onChange={(e) => {
                                                setContactsForAdd(contactsForAdd.map((cfa,cfi) => {
                                                    if (cfi === i) {
                                                        cfa.phone = e.target.value;
                                                    }
                                                    return cfa;
                                                }))
                                            }}
                                        />
                                        <CustomInput
                                            label="Company Email"
                                            value={cd.email}
                                            onChange={(e) => {
                                                setContactsForAdd(contactsForAdd.map((cfa,cfi) => {
                                                    if (cfi === i) {
                                                        cfa.email = e.target.value;
                                                    }
                                                    return cfa;
                                                }))
                                            }}
                                        />
                                        <div className="custom-textfield">
                                            <TextField
                                                label="Notes"
                                                multiline
                                                rowsMax="4"
                                                value={cd.notes}
                                                onChange={(e) => {
                                                    setContactsForAdd(contactsForAdd.map((cfa,cfi) => {
                                                        if (cfi === i) {
                                                            cfa.notes = e.target.value;
                                                        }
                                                        return cfa;
                                                    }))
                                                }}
                                                className=""
                                                margin="normal"
                                            />
                                        </div>
                                    </div>
                                </GridItem> 
                                );
                            })}    
                        </div>}

                        {/* EDIT MODE */}
                        {contactCompanies.map((cd, i) => {
                            const cc = cd.contact_data;
                            return (
                            <GridItem xs={12} sm={12} md={4} key={`${i}-contact-cont`}>
                                <div style={{ 
                                        fontSize: 16, 
                                        backgroundColor: props.action === 'Edit' ? 'white' : '#f4f4f4'
                                }} className="boxed">
                                    <div style={{ float: "right" }}>
                                        {canDelete && (
                                            <IconButton
                                                onClick={() => {
                                                    const data = {
                                                        id: cd.id,
                                                        contact: cd.contact_data.id,
                                                        company_id: cd.company_data.id,
                                                        is_company: true,
                                                    };
                                                    setDataToDelete(data);
                                                    setNotificationAsk(
                                                        "Are you sure you want to delete contact - ",
                                                        cc.first_name + " " + cc.last_name
                                                    );
                                                }}
                                            >
                                                <i className="fas fa-trash-alt red-text"></i>
                                            </IconButton>
                                        )}
                                    </div>
                                    <span className="hoverable bold-on-hover"
                                        onClick={() => {
                                            setOpenedEditContact(true);
                                            setContactId(cd.contact_data.id);
                                        }}
                                    >
                                        <small>#{i + 1}</small>
                                        <h3>
                                            <strong>
                                                {cc ? cc.first_name : ""} {cc ? cc.last_name : ""}
                                            </strong>
                                        </h3>
                                    </span>
                                    <CustomInput
                                        label="Company Department"
                                        value={cd.department}
                                        onChange={(e) => {
                                            const newContactCompanies = clone(contactCompanies);
                                            newContactCompanies[i].department = e.target.value;
                                            setContactCompanies(newContactCompanies);
                                        }}
                                        disabled={props.action === 'Edit' ? false : true}
                                    />
                                    <CustomInput
                                        label="Company Title"
                                        value={cd.title}
                                        onChange={(e) => {
                                            const newContactCompanies = clone(contactCompanies);
                                            newContactCompanies[i].title = e.target.value;
                                            setContactCompanies(newContactCompanies);
                                        }}
                                        disabled={props.action === 'Edit' ? false : true}
                                    />
                                    <CustomInput
                                        label="Company Phone"
                                        value={cd.phone}
                                        onChange={(e) => {
                                            const newContactCompanies = clone(contactCompanies);
                                            newContactCompanies[i].phone = e.target.value;
                                            setContactCompanies(newContactCompanies);
                                        }}
                                        disabled={props.action === 'Edit' ? false : true}
                                    />
                                    <CustomInput
                                        label="Company Email"
                                        value={cd.email}
                                        onChange={(e) => {
                                            const newContactCompanies = clone(contactCompanies);
                                            newContactCompanies[i].email = e.target.value;
                                            setContactCompanies(newContactCompanies);
                                        }}
                                        disabled={props.action === 'Edit' ? false : true}
                                    />
                                    <div className="custom-textfield">
                                        <TextField
                                            label="Notes"
                                            multiline
                                            rowsMax="4"
                                            value={cd.notes ? cd.notes : ''}
                                            onChange={(e) => {
                                                const newContactCompanies = clone(contactCompanies);
                                                newContactCompanies[i].notes = e.target.value;
                                                setContactCompanies(newContactCompanies);
                                            }}
                                            className=""
                                            margin="normal"
                                            disabled={props.action === 'Edit' ? false : true}
                                        />
                                    </div>
                                    {(canEdit && props.action === 'Edit') && (
                                        <Button
                                            color="primary"
                                            className="mt-20"
                                            onClick={() => {
                                                const data = clone(cd);
                                                data.is_company = true;
                                                setUpdateContact(true);
                                                updateCompanyContacts(data);
                                            }}
                                        >
                                            SAVE COMPANY CONTACT
                                        </Button>
                                    )}
                                </div>
                            </GridItem>
                            );
                        })}
                    </GridContainer>
                )}
            </GridItem>

            {addContact && (
                <CustomDialogWithContact
                    options={names}
                    indx="id"
                    desc="name"
                    label="Add Contact"
                    title="Add Contact"
                    open={addContact ? true : false}
                    close={() => {
                        setAddContact(false);
                    }}
                    choose={choose}
                    create_and_choose={choose}
                    theContactData={props.contactData}
                    loggedInUser={loggedInUser}
                />
            )}
            {openedEditContact && (
                <CompaniesEditContact
                    open={openedEditContact}
                    close={() => {
                        setOpenedEditContact(false);
                    }}
                    contactId={contactId}
                />
            )}
            {message && (
                <Notification
                    open={message ? true : false}
                    close={() => {
                        setMessage("");
                    }}
                    message={message}
                />
            )}
            {notificationAsk && (
                <NotificationAsk
                    open={notificationAsk ? true : false}
                    close={() => {
                        setNotificationAsk("");
                        setDataToDelete(null);
                    }}
                    success={() => {
                        deleteCompanyContacts(dataToDelete);
                        setNotificationAsk("");
                        setDataToDelete(null);
                    }}
                    title="Are you sure?"
                    message={notificationAsk}
                    success_button_text="Yes, Delete"
                />
            )}
        </GridContainer>
    );
};

export default connect(null, null)(CompaniesContacts);
