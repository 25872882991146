import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "@material-ui/core";
import { assign, first, get, isEmpty, last, orderBy, sortBy } from "lodash";
import moment from "moment";

import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table.jsx";

import {
    clone,
    commatize,
    formatDollar,
    getDateDiffs2,
    timestampToDate,
    validateDates,
    validateRange,
	validateDatesAndRange,
    getDateDiffs,
	convert,
    chk
} from "shared/utility"

import {
    addTermRangesError,
    calculateCommission,
    calculateEstimatedAbatedYearRanges,
    calculateEstimatedRangeTable,
    calculateEstimatedTermsTotal,
    calculateEstimatedTotal,
    calculateTermRent,
    calculateTermsTotal,
    calculateYearAbatedRanges,
    getNextDate,
    isDateBetween,
    isEstimatedLease,
    isSameOrBefore,
    shouldShowWarningAndResetRanges,
    calculateLeaseLogic,
    checkTermValidity,
    createEstimatedYearRows,
    createYearRows,
    calculateAbatedRanges_RS as calculateAbatedRanges,
    newRangeCalculation,
    newYearlyCalculation,
} from "../../RSheetsLogic";

import EstimatedTermAbated from './EstimatedTermAbated'
import TermAbated from './TermAbated'
import TermForm from './TermForm'
import { formatDate2 } from '../../../../shared/utility';
import NotificationAsk from "../../../../components/Notification/NotificationAsk";

const Term = (props) => {
    const ref = useRef(null);
    const ref2 = useRef(null);

    const handleScrollClick = () => {
        ref.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
        });
    };

    const handleScrollClick2 = (the_ref) => {
        if (the_ref.current) {
            the_ref.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            });
        }
    };

    const [scrollRef, setScrollRef] = useState(null);
    const [scrollRef2, setScrollRef2] = useState(null);
    const [openFormModal, setOpenFormModal] = useState(null); // sets what year the data will be updated
    const [editRange, setEditRange] = useState({
        year_index: -1,
        range_index: -1,
    });
    const [rangeError, setRangeError] = useState("");
    // FIXME - Delete it later
    const [showRangeTable, setShowRangeTable] = useState(false);
    const [showRangesTable, setShowRangesTable] = useState(null);
    const [askDeleteRange, setAskDeleteRange] = useState(null);
    const [localData, setLocalData] = useState({
        value: "",
        flat_fee: "",
        date1: "",
        date2: "",
        month1: "",
        month2: "",
        day1: "",
        day2: "",
        commissionRate: "",
        year_index: "",
        range_index: "",
    });
    const [localAbatedData, setLocalAbatedData] = useState({
        value: "",
        date1: "",
        date2: "",
        usePercentage: false,
        percent: 0,
        commissionRate: "",
        year_index: "",
        range_index: "",
    });
    const [editAbatedRange, setEditAbatedRange] = useState({
        year_index: -1,
        range_index: -1,
    });
    const [calculatedAbatedRanges, setCalculatedAbatedRanges] = useState(null);
    const [openedAddRange, setOpenedAddRange] = useState(false);
    const [notification, setNotification] = useState(false);

    const { data, updateDataObj, field, setData } = props;
    const isFlatFee = props.data.flat_fee_commission === 'Yes';
	const isCPI = get(data, 'rate_type') === 'Fixed Percentage';
    const the_term = field === "lease_term" ? data.lease_term : data.abated_rent_ranges;
	const isActualDays = get(data, "commission_based_off", "") === "Actual Days";

    const { calcs } = props;

    useEffect(() => {
        const cloned_data = clone(data);
        const calc = calculateAbatedRanges({
            lease_term: cloned_data.lease_term,
            abated: cloned_data.abated_rent_ranges,
        });
        if (calc) setCalculatedAbatedRanges(calc);
    }, [data]);

    const defaultRent = (yearIndex) => get(the_term, `[${yearIndex}].ranges[0].value`, "");

    useEffect(() => {
        const percent = parseFloat(localAbatedData.percent);
        if (percent >= 0 && percent <= 100) {
            const value = parseFloat(defaultRent(localAbatedData.year_index)) * percent / 100;
            const newLocalAbatedData = clone(localAbatedData)
            newLocalAbatedData.value = value;
            setLocalAbatedData(newLocalAbatedData);
        }
    }, [localAbatedData.percent, localAbatedData.usePercentage])

    const getDateDifference = (date1, date2) => {
        if (date1 && date2) {
            const diffObj = getDateDiffs(convert(date1), convert(date2));
            return diffObj;
        }
        return null;
    };

    useEffect(() => {
        const the_term_cloned = clone(the_term);
        const rangesTable = the_term_cloned.map((lt) => {
            return lt.ranges.map(() => {
                return { open: false };
            });
        });
        setShowRangesTable(rangesTable);
    }, [the_term]);

    const isShowingRanges = (yearIndex, rangeIndex) =>
        get(showRangesTable, `[${yearIndex}][${rangeIndex}]`, { open: false }).open;
    const showRanges = (yearIndex, rangeIndex) => {
        const newShowRangesTable = clone(showRangesTable);
        const canOpen = get(showRangesTable, `[${yearIndex}][${rangeIndex}]`, false);
        if (canOpen) {
            newShowRangesTable[yearIndex][rangeIndex].open = true;
            setShowRangesTable(newShowRangesTable);
        }
    };

    const hideRanges = (yearIndex, rangeIndex) => {
        const newShowRangesTable = clone(showRangesTable);
        const canOpen = get(showRangesTable, `[${yearIndex}][${rangeIndex}]`, false);
        if (canOpen) {
            newShowRangesTable[yearIndex][rangeIndex].open = false;
            setShowRangesTable(newShowRangesTable);
        }
    };

    const lease_term = data.lease_term;
    const abated_term = data.abated_rent_ranges;

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prev_lease_start_date = usePrevious(data.lease_start_date);
    const prev_lease_end_date = usePrevious(data.lease_end_date);
    const prev_rent_commencement = usePrevious(data.rent_commencement);
    const prev_occupancy_date = usePrevious(data.occupancy_date);
    const prev_lease_start_date_estimated = usePrevious(data.lease_start_date_estimated);
    const prev_rent_commencement_estimated = usePrevious(data.rent_commencement_estimated);
    const prev_occupancy_date_estimated = usePrevious(data.occupancy_date_estimated);
    const prev_lease_start_date_estimated_tbd = usePrevious(data.lease_start_date_estimated_tbd);
    const prev_rent_commencement_estimated_tbd = usePrevious(data.rent_commencement_estimated_tbd);
    const prev_occupancy_date_estimated_tbd = usePrevious(data.occupancy_date_estimated_tdb);

    const updateIt = () => {
        console.log('resetting term data because required input data changed');
        const isEstimated = isEstimatedLease(data, false);
        if (lease_term && abated_term) {
            const newData = clone(data);
            ["abated_rent_ranges", "lease_term"].forEach((ff) => {
                if (newData[ff]) {
                    let no_need_to_create_rows = false;
                    if (newData[ff][0]) {
                        if (newData[ff][0].ranges) {
                            no_need_to_create_rows = true;
                        }
                    }

                    if (!no_need_to_create_rows) {
                        let newTerm = clone(newData[ff]);
                        if (data.lease_start_date && data.lease_end_date && data.rent_commencement) {
                            const lease_end_date_unix = moment(data.lease_end_date, "MM/DD/YYYY").unix();
                            const diffObj = getDateDifference(data.lease_start_date, data.lease_end_date);
                            if (diffObj.hasOwnProperty("years")) {
                                newTerm = createYearRows({
                                    diffObj,
                                    data,
                                    lease_end_date_unix,
                                });
                            }
                        }
                        newData[ff] = newTerm;
                        console.log('gh 1', newData, ff);
                    } else {
                        const oldRangeData = {
                            lease_start_date: prev_lease_start_date,
                            lease_start_date_estimated: prev_lease_start_date_estimated,
                            lease_start_date_estimated_tbd: prev_lease_start_date_estimated_tbd,
                            rent_commencement: prev_rent_commencement,
                            rent_commencement_estimated: prev_rent_commencement_estimated,
                            rent_commencement_estimated_tbd: prev_rent_commencement_estimated_tbd,
                            occupancy_date: prev_occupancy_date,
                            occupancy_date_estimated: prev_occupancy_date_estimated,
                            occupancy_date_estimated_tbd: prev_occupancy_date_estimated_tbd,
                            lease_end_date: prev_lease_end_date,
                        }
                        const oldData =  clone(data);
                        assign(oldData, oldRangeData);
                        console.log('gh 2', oldData, ff);

                        const newRangeData = {
                            lease_start_date: data.lease_start_date,
                            lease_end_date: data.lease_end_date,
                            rent_commencement: data.rent_commencement,
                            occupancy_date: data.occupancy_date,
                        }
                        const isEstimatedSwapped = isEstimatedLease(oldData, false) !== isEstimated;

                        const shouldChange = shouldShowWarningAndResetRanges(oldRangeData, newRangeData, data);
                        const shouldRecalculateDateRange = true; 
                        // prev_lease_start_date !== data.lease_start_date ||
                        // prev_lease_end_date !== data.lease_end_date || prev_rent_commencement !== data.rent_commencement
                        console.log('gh 3', shouldChange, shouldRecalculateDateRange, isEstimated, isEstimatedSwapped);

                        if ((shouldChange || shouldRecalculateDateRange) && !isEstimated && !isEstimatedSwapped) {
                            let newTerm = clone(newData[ff]);
                            if (data.lease_start_date && data.lease_end_date && data.rent_commencement) {
                                const lease_end_date_unix = moment(data.lease_end_date, "MM/DD/YYYY").unix();
                                // determine if rent commencement > lease_start_date
                                let rent_commencement_is_more = false;
                                if (data.rent_commencement && data.lease_start_date) {
                                    if (
                                        moment(data.rent_commencement, "MM/DD/YYYY", true).isValid() &&
                                        moment(data.lease_start_date, "MM/DD/YYYY", true).isValid()
                                    ) {
                                        const timestamp1 = moment(data.rent_commencement, "MM/DD/YYYY", true).unix();
                                        const timestamp2 = moment(data.lease_start_date, "MM/DD/YYYY", true).unix();
                                        if (timestamp1 > timestamp2) {
                                            rent_commencement_is_more = true;
                                        }
                                    }
                                }
                                const diffObj = rent_commencement_is_more
                                    ? getDateDifference(data.rent_commencement, data.lease_end_date)
                                    : getDateDifference(data.lease_start_date, data.lease_end_date);

                                if (diffObj.hasOwnProperty("years")) {
                                    newTerm = createYearRows({
                                        diffObj,
                                        data,
                                        lease_end_date_unix,
                                    });
                                }

                                newData[ff] = newTerm;
                            }
                        } else if (shouldChange && isEstimated) {
                            let newTerm = createEstimatedYearRows({ data });
                            newData[ff] = newTerm;
                        } else if (shouldChange && !isEstimated && isEstimatedSwapped) {
                            let newTerm = [{
                                ranges: [],
                                year_end: null,
                                year_num: 0,
                                year_start: moment(get(data, 'lease_start_date', '')).unix()
                            }];

                            newData[ff] = newTerm;
                        }
                    }
                }
            });
            if (setData) setData(newData);
            setRangeError("");
        }
    };

    useEffect(() => {
        // updateIt();
        // - make user click a button instead of autoupdate
    }, [
        data.lease_start_date,
        data.lease_end_date,
        data.rent_commencement,
        data.occupancy_date,
        data.lease_start_date_estimated,
        data.rent_commencement_estimated,
        data.lease_start_date_estimated_tbd,
        data.rent_commencement_estimated_tbd,
        data.occupancy_date_estimated,
        data.occupancy_date_estimated_tbd,
    ]);

    useEffect(() => {
        if (scrollRef) {
            handleScrollClick();
            setScrollRef(null);
        }
    }, [scrollRef]);

    const getSuggestedRangeStart = (yearIndex) => {
        const yearRanges = get(lease_term, `[${yearIndex}].ranges`, []) || [];
        if (isEstimated) {
            const sortedRanges = orderBy(yearRanges, ["month1", "day1"], "asc");
            if (sortedRanges.length > 0) {
                const lastRange = last(sortedRanges);
                const month2 = lastRange.month2 ? parseInt(lastRange.month2.split(" ")[1]) : null;
                const day2 = lastRange.day2 ? parseInt(lastRange.day2.split(" ")[1]) : null;
                if (month2 && day2) {
                    const nextDate = getNextDate(month2, day2);
                    return {
                        month: `Month ${nextDate.month}`,
                        day: `Day ${nextDate.day}`,
                    };
                }
            }
            return {
                month: "",
                day: "",
            };
        } else {
            console.log('yearRanges', yearRanges);
            const sortedRanges = sortBy(yearRanges, (a) => new Date(a.date1));
            if (sortedRanges.length > 0) {
                const lastRange = last(sortedRanges);
                const date2 = get(lastRange, "date2", "");
                if (moment(date2)) {
                    const nextDate = moment(date2).add(1, "days");
                    return nextDate.format("MM/DD/YYYY");
                }
            }
            return "";
        }
    };

    const getNewFullRange = (yearIndex) => {
        const yearObj = get(lease_term, `[${yearIndex}]`, null) || null;
        if (isEstimated) {
            // year 1 = month 1 - 0, 
            // year 2 = month 13 - 1
            const month1 = (yearIndex * 12) + 1;
            return {
                month1,
                day1: "1",
                month2: month1 + 11,
                day2: "30",
            };
        } else {
            if (yearObj) {
                const obj = {
                    start: moment(yearObj.year_start * 1000).format("MM/DD/YYYY"),
                    end: moment(yearObj.year_end * 1000).format("MM/DD/YYYY")
                };
                console.log('obj->', obj);
                return obj;
            }
            return "";
        }
    };

    const checkMonths = (yearIndex, isAbated = false) => {
        if (isEstimated) {
            if (!localData.month1 || !localData.month2 || !localData.day1 || !localData.day2) {
                return <div style={{ color: "red" }}>Month Start, Month End, Day Start and Day End are required!</div>;
            }
            const start = parseInt(get(localData, "month1", "").split(" ")[1]);
            const end = parseInt(get(localData, "month2", "").split(" ")[1]);

            const dayStart = parseInt(get(localData, "day1", "").split(" ")[1]);
            const dayEnd = parseInt(get(localData, "day2", "").split(" ")[1]);

            if (end < start) {
                return <div style={{ color: "red" }}>Month End must be after Month Start</div>;
            } else if (end === start && dayEnd <= dayStart) {
                return <div style={{ color: "red" }}>Day End must be after Day Start</div>;
            }
        }

        let yearRanges = isAbated
            ? get(abated_term, `[${yearIndex}].ranges`, [])
            : get(lease_term, `[${yearIndex}].ranges`, []);
        yearRanges = clone(yearRanges);
        if (editRange && editRange.year_index !== -1 && editRange.range_index !== -1) {
            yearRanges.splice(editRange.range_index, 1);
        }
        if (yearRanges.length >= 1 && editRange.range_index !== 0) {
            const firstRange = first(yearRanges);

            if (isEstimated) {
                if (isSameOrBefore(localData.month1, localData.day1, firstRange.month1, firstRange.day1)) {
                    return <div style={{ color: "red" }}>Month Start and Day Start must be after first range</div>;
                }
            } else {
                if (moment(localData.date1).isSameOrBefore(moment(firstRange.date1))) {
                    return <div style={{ color: "red" }}>Date Start must be after first range</div>;
                }
            }
        }

        let error = null;

        yearRanges.forEach((range) => {
            if (isEstimated) {
                if (
                    isDateBetween(
                        { month: localData.month1, day: localData.day1 },
                        { month: range.month1, day: range.day1 },
                        { month: range.month2, day: range.day2 },
                        true
                    )
                ) {
                    if (!error) {
                        error = (
                            <div style={{ color: "red" }}>
                                Month Start and Day Start must not in between other ranges
                            </div>
                        );
                    }
                }
            } else {
                if (isDateBetween(localData.date1, range.date1, range.date2)) {
                    if (!error) {
                        error = <div style={{ color: "red" }}>Date Start must not be between other ranges</div>;
                    }
                }
            }
        });

        return error;
    };

    const isEstimated = isEstimatedLease(data);

    console.log('Term', calcs);

    return (
        <div>

            {notification && <NotificationAsk 
                open={notification ? true : false}
                close={() => {
                    setNotification('');
                }}
                title="Are you sure?"
                message={notification}
                success={() => {
                    updateIt();
                    setNotification('');
                }}
            />}

            <Button
                color="rose"
                onClick={() => {
                    let alreadyHasRanges = false;
                    if (data.lease_term) {
                        data.lease_term.forEach(dlt => {
                            if (dlt.ranges) {
                                if (dlt.ranges.length > 0) {
                                    alreadyHasRanges = true;
                                }
                            }
                        })
                    }
                    if (alreadyHasRanges) {
                        setNotification('This will re-generate a new lease term with empty ranges.')
                    } else {
                        updateIt();
                    }
                }}
                style={{marginBottom:20}}
            >
                GENERATE LEASE TERM RANGES
            </Button>

            {!isEstimated && the_term
                ? the_term.map((lt, i) => {
                      const termValidation = checkTermValidity(lt, data, field);

                      //   calcs.lease_term_calculations.years[i]
                      const year_calcs = get(calcs, `lease_term_calculations.years[${i}]`, {});
                      //    calcs.abated_term_calculations.years[i]
                      const abated_year_calcs = get(calcs, `abated_term_calculations.years[${i}]`, {});
                    
                      const yearAbated = get(data, `abated_rent_ranges[${i}].ranges`, []);

                      const abated_ranges = calculateYearAbatedRanges({ lease_term, year_abated: yearAbated });

                      // calculate months
                      let total = 0;
                      let totalCommission = 0;
                      //total = total - abated_total;
                      const ystart = moment(lt.year_start * 1000).format("MM/DD/YYYY");
                      const yend = moment(lt.year_end * 1000).format("MM/DD/YYYY");

                      const yearResult = newYearlyCalculation(lt.ranges, isEstimated, isActualDays)
                      const abatedYearResult = newYearlyCalculation(abated_ranges, isEstimated, isActualDays);

                      return (
                          <div key={`year-${i}`} className="boxed">
                              <h3>Lease Term Year {i + 1}</h3>
                              <p>
                                  Using Lease Start Date ({ystart} to {yend})
                              </p>
                              <p>
                                  Using Rent Commencement Date ({data.rent_commencement} to {yend})
                              </p>
                              <hr />
                              <Button
                                  color="primary"
                                  style={{marginRight:20}}
                                  disabled={!!addTermRangesError(data)}
                                  onClick={() => {
                                      const year_index = i.toString();
                                      const year = parseInt(year_index);
                                      const range_index = lt.ranges.length; // new index
                                      setOpenFormModal(year_index);
                                      const suggestedStart = getSuggestedRangeStart(i);
                                      const newRangeObj = {
                                          value: "",
                                          date1: suggestedStart, //convertUnixToDate(the_term[year].year_start),
                                          date2: "", //convertUnixToDate(the_term[year].year_end),
                                          commissionRate: "",
                                          year_index,
                                          range_index,
                                      };
                                      const newTerm = clone(the_term);
                                      if (newTerm[year].ranges) {
                                          newTerm[year].ranges.push(newRangeObj);
                                      }
                                      updateDataObj(field, newTerm);
                                      setLocalData(newRangeObj);

                                      setEditRange({
                                          year_index: year,
                                          range_index,
                                      });
                                      setScrollRef(`${range_index}-${year_index}`);
                                  }}
                              >
                                  ADD NEW RANGE
                              </Button>
                              {lt.ranges.length === 0 && <Button
                                  color="rose"
                                  disabled={!!addTermRangesError(data)}
                                  onClick={() => {
                                      const year_index = i.toString();
                                      const year = parseInt(year_index);
                                      const range_index = lt.ranges.length; // new index
                                      setOpenFormModal(year_index);
                                      const suggested = getNewFullRange(i);
                                      console.log('suggested', suggested);
                                      const newRangeObj = {
                                          value: "",
                                          date1: suggested.start, //convertUnixToDate(the_term[year].year_start),
                                          date2: suggested.end, //convertUnixToDate(the_term[year].year_end),
                                          commissionRate: "",
                                          year_index,
                                          range_index,
                                      };
                                      const newTerm = clone(the_term);
                                      if (newTerm[year].ranges) {
                                          newTerm[year].ranges.push(newRangeObj);
                                      }
                                      updateDataObj(field, newTerm);
                                      setLocalData(newRangeObj);

                                      setEditRange({
                                          year_index: year,
                                          range_index,
                                      });
                                      setScrollRef(`${range_index}-${year_index}`);
                                  }}
                              >
                                  ADD NEW FULL RANGE
                              </Button>}
                              <hr />
                              {field === "lease_term" && (
                                  <div>
                                      {termValidation.whole_year_days && (
                                          <div className=" mt-20 mb-20">
                                              {termValidation.whole_year_days} days in the range year.
                                          </div>
                                      )}
                                      {termValidation.count_days && (
                                          <div className=" mt-20 mb-20">
                                              {termValidation.count_days} days entered on the ranges.
                                          </div>
                                      )}
                                  </div>
                              )}
                              {termValidation.errors && (
                                  <div className="red-text mt-20 mb-20 red-fixed-error">{termValidation.errors}</div>
                              )}
                              <div className="boxed" style={{ backgroundColor: "#fafafa" }}>
                                  {lt.ranges.length === 0 && (
                                      <div className="text-center">
                                          <em>No Ranges</em>
                                      </div>
                                  )}
                                  <ul className="list-unstyled">
                                      {lt.ranges.map((ltt, j) => {
                                          const { value, date1, date2, commissionRate, flat_fee: ff } = ltt;

                                          const flat_fee = ff ? ff.toString().replace(/[$,]/g, "") : 0;
                                          const isRequired = true;

                                        const rangeCommission = get(calcs, `lease_term_calculations.years[${i}].ranges[${j}].total_commission`, 0);
                                        const allTotal = get(calcs, `lease_term_calculations.years[${i}].ranges[${j}].total_real_value`,0);
                                          const year_start = lt.year_start;
                                          const year_end = lt.year_end;
                                          const dates_passed = validateDatesAndRange(
                                              localData.date1,
                                              localData.date2,
                                              year_start,
                                              year_end
                                          );
                                          const dates_are_in_range = validateDatesAndRange(
                                              localData.date1,
                                              localData.date2,
                                              year_start,
                                              year_end
                                          );

                                          let current_year_range = "";
                                          if (year_start && year_end) {
                                              current_year_range = `${timestampToDate(
                                                  year_start * 1000
                                              )} - ${timestampToDate(year_end * 1000)}`;
                                          }
                                          let range_requirements_passed = true;
                                          if (field === "lease_term") {
                                              if (
                                                  !dates_passed ||
                                                  !dates_are_in_range ||
                                                  !localData.date1 ||
                                                  !localData.date2 ||
                                                  !(isFlatFee ? localData.flat_fee : localData.commissionRate) ||
                                                  !localData.value
                                              ) {
                                                  range_requirements_passed = false;
                                              }
                                          } else {
                                              if (
                                                  !dates_passed ||
                                                  !dates_are_in_range ||
                                                  !localData.date1 ||
                                                  !localData.date2 ||
                                                  !localData.value
                                              ) {
                                                  range_requirements_passed = false;
                                              }
                                          }
                                          console.log('the calc?', field, get(calcs, `lease_term_calculations.years[${i}].ranges[${j}].rd`, [])
                                          .map(rd => {
                                              return [
                                                  rd.date1,
                                                  rd.date2,
                                                  rd.total_days_in_this_range,
                                                  isActualDays ? rd.actual_number_of_days_in_whole_month : 30,
                                                  rd.real_value,
                                              ]
                                          }));
                                          
                                          return (
                                              <div
                                                  key={`ranges-${j}=${i}`}
                                                  ref={scrollRef === `${j}-${i}` ? ref : null}
                                              >
                                                  <h4
                                                      className="bold black-text mr-20"
                                                      style={{ display: "inline-block" }}
                                                  >
                                                      Range #{j + 1}
                                                  </h4>
                                                  {(editRange.range_index !== j || editRange.year_index !== i) && (
                                                      <Button
                                                          color="rose"
                                                          onClick={() => {
                                                              setEditRange({
                                                                  year_index: i,
                                                                  range_index: j,
                                                              });
                                                              setLocalData({
                                                                  value,
                                                                  date1,
                                                                  date2,
                                                                  commissionRate,
                                                              });
                                                          }}
                                                      >
                                                          <span className="">
                                                              <i className="fas fa-edit hoverable"></i> EDIT
                                                          </span>
                                                      </Button>
                                                  )}
                                                  {editRange.range_index === j && editRange.year_index === i && (
                                                      <Button
                                                          disabled={
                                                              !checkMonths(i) && range_requirements_passed
                                                                  ? false
                                                                  : true
                                                          }
                                                          color="primary"
                                                          onClick={() => {
                                                              setEditRange({
                                                                  year_index: -1,
                                                                  range_index: -1,
                                                              });
                                                              const newData = clone(data);
                                                              newData[field][editRange.year_index].ranges[
                                                                  editRange.range_index
                                                              ] = {
                                                                  value: localData.value,
                                                                  date1: localData.date1,
                                                                  date2: localData.date2,
                                                                  commissionRate: localData.commissionRate,
                                                                  flat_fee: localData.flat_fee,
                                                              };
                                                              setData(newData);
                                                          }}
                                                      >
                                                          <span className="">
                                                              <i className="fas fa-check hoverable"></i> SAVE
                                                          </span>
                                                      </Button>
                                                  )}
                                                  {editRange.range_index === j && editRange.year_index === i && (
                                                      <Button
                                                          color="white"
                                                          onClick={() => {
                                                              setEditRange({
                                                                  year_index: -1,
                                                                  range_index: -1,
                                                              });
                                                              if (field === "lease_term") {
                                                                  if (!date1 || !date2 || !value || !commissionRate) {
                                                                      setAskDeleteRange({ j, i });
                                                                  }
                                                              } else {
                                                                  if (!date1 || !date2 || !value) {
                                                                      setAskDeleteRange({ j, i });
                                                                  }
                                                              }
                                                          }}
                                                      >
                                                          <span className="">
                                                              <i className="fas fa-times hoverable"></i> CANCEL
                                                          </span>
                                                      </Button>
                                                  )}
                                                  <Button
                                                      color="white"
                                                      onClick={() => {
                                                          setAskDeleteRange({ j, i });
                                                      }}
                                                  >
                                                      <span className="">
                                                          <i className="fas fa-trash hoverable"></i> DELETE
                                                      </span>
                                                  </Button>
                                                  <div>
                                                      {(editRange.range_index !== j || editRange.year_index !== i) && (
                                                          <div className="boxed mt-20 white-bg">
                                                              <GridContainer>
                                                                    <GridItem GridItem xs={12} sm={2} md={2}>
                                                                      <div>
                                                                          Monthly $
                                                                          <br />
                                                                          Value
                                                                      </div>
                                                                      <strong>$ {commatize(value, true)}</strong>
                                                                  </GridItem>
                                                                  {isFlatFee && (
                                                                      <GridItem xs={12} sm={2} md={2}>
                                                                          <div>Flat Fee</div>
                                                                          <strong>{formatDollar(flat_fee)}</strong>
                                                                      </GridItem>
                                                                  )}
                                                                  {!isFlatFee && field === "lease_term" && (
                                                                      <GridItem xs={12} sm={2} md={2}>
                                                                      <div>
                                                                          Commission
                                                                          <br />
                                                                          Rate
                                                                      </div>
                                                                      <strong>{commissionRate} %</strong>
                                                                  </GridItem>
                                                                  )}
                                                                  <GridItem xs={12} sm={2} md={2}>
                                                                      <div>
                                                                          Date
                                                                          <br />
                                                                          Start
                                                                      </div>
                                                                      <strong>{date1}</strong>
                                                                  </GridItem>
                                                                  <GridItem xs={12} sm={2} md={2}>
                                                                      <div>
                                                                          Date
                                                                          <br />
                                                                          End
                                                                      </div>
                                                                      <strong>{date2}</strong>
                                                                  </GridItem>
                                                                  {field === "lease_term" && (
                                                                      <GridItem xs={12} sm={2} md={2}>
                                                                          <div>
                                                                              Total <br />
                                                                              Commission :
                                                                          </div>
                                                                          {rangeCommission
                                                                              ? `$ ${commatize(rangeCommission, true)}`
                                                                              : 0}
                                                                      </GridItem>
                                                                  )}
                                                                  <GridItem xs={12} sm={2} md={2}>
                                                                      {field === "lease_term" && (
                                                                          <div>
                                                                              Range's Total{" "}
                                                                              {field === "lease_term"
                                                                                  ? "Rent Collected"
                                                                                  : "Abated Rent"}{" "}
                                                                              :<br />{" "}
                                                                              <span
                                                                                  className="text-underline hovered black-text bold"
                                                                                  onClick={() => {
                                                                                      if (isShowingRanges(i, j)) {
                                                                                          hideRanges(i, j);
                                                                                      } else {
                                                                                          showRanges(i, j);
                                                                                      }
                                                                                  }}
                                                                              >
                                                                                  ${" "}
                                                                                  {allTotal ? (
                                                                                      <span>
                                                                                          {field === "lease_term"
                                                                                              ? ""
                                                                                              : "-"}
                                                                                          {commatize(allTotal, true)}
                                                                                      </span>
                                                                                  ) : (
                                                                                      0
                                                                                  )}
                                                                              </span>
                                                                          </div>
                                                                      )}
                                                                  </GridItem>
                                                              </GridContainer>
                                                          </div>
                                                      )}
                                                  </div>
                                                  {editRange && (
                                                      <div>
                                                          {editRange.range_index === j &&
                                                              editRange.year_index === i && (
                                                                  <div
                                                                      className="boxed mt-20 white-bg"
                                                                      style={{ backgroundColor: "white" }}
                                                                  >
                                                                      <TermForm
                                                                          data={data}
                                                                          setData={setData}
                                                                          value={localData.value}
                                                                          date1={localData.date1}
                                                                          date2={localData.date2}
                                                                          commissionRate={localData.commissionRate}
                                                                          flat_fee={localData.flat_fee}
                                                                          update={(fieldType, val) => {
                                                                              const newLocalData = clone(localData);
                                                                              newLocalData[fieldType] = val;
                                                                              setLocalData(newLocalData);
                                                                          }}
                                                                          updateMultiFields={(obj) => {
                                                                            const newLocalData = clone(localData);
                                                                            Object.keys(obj).forEach(key => {
                                                                                newLocalData[key] = obj[key]
                                                                            })
                                                                            setLocalData(newLocalData);
                                                                          }}
                                                                          isRequired={isRequired}
                                                                          allTotal={allTotal}
                                                                          setShowRangeTable={setShowRangeTable}
                                                                          showRangeTable={showRangeTable}
                                                                          field={field}
                                                                          currentYear={i + 1}
                                                                          openFormModal={openFormModal}
                                                                          showRanges={() => showRanges(i, j)}
                                                                          hideRanges={() => hideRanges(i, j)}
                                                                          isShowingRanges={isShowingRanges(i, j)}
																		  isCPI={isCPI}
                                                                          isNextRange={j !== 0}
                                                                          currentIndex={j}
                                                                      />
                                                                      <hr />
                                                                      <em>
                                                                          * Requirements : Start date must be earlier
                                                                          than End date. All fields are required.
                                                                      </em>
                                                                      {!dates_passed && (
                                                                          <div style={{ color: "red" }}>
                                                                              Invalid Date(s), valid MM/DD/YYYY date is
                                                                              required.
                                                                          </div>
                                                                      )}
                                                                      {!dates_are_in_range && (
                                                                          <div style={{ color: "red" }}>
                                                                              Date Range must be within current year
                                                                              range ({current_year_range}).
                                                                          </div>
                                                                      )}
                                                                      {checkMonths(i)}
                                                                  </div>
                                                              )}
                                                      </div>
                                                  )}

                                                  {isShowingRanges(i, j) && (
                                                    <div style={{marginBottom:20}}>
                                                      <Table
                                                          tableHead={[
                                                              "Start Date",
                                                              "End Date",
                                                              "Num of Days",
                                                              "Days in Month",
                                                              "Commission Value",
                                                              "Total Rent",
                                                          ]}
                                                          tableData={field === 'lease_term' ? 
                                                                get(calcs, `lease_term_calculations.years[${i}].ranges[${j}].rd`, [])
                                                                    .map(rd => {
                                                                        console.log('the rd', rd);
                                                                        if (rd.isAbated) {
                                                                            return [
                                                                                <div style={{color:'red'}}>{rd.date1}</div>,
                                                                                <div style={{color:'red'}}>{rd.date2}</div>,
                                                                                <div style={{color:'red'}}>{rd.total_days_in_this_range}</div>,
                                                                                <div style={{color:'red'}}>{isActualDays ? rd.actual_number_of_days_in_whole_month : 30}</div>,
                                                                                <div style={{color:'red'}}>{rd.the_commission_value ? formatDollar(rd.the_commission_value) : null}</div>,
                                                                                <div style={{color:'red'}}>{formatDollar(rd.real_value)}</div>,
                                                                            ];
                                                                        }
                                                                        return [
                                                                            rd.date1,
                                                                            rd.date2,
                                                                            rd.total_days_in_this_range,
                                                                            isActualDays ? rd.actual_number_of_days_in_whole_month : 30,
                                                                            rd.the_commission_value ? formatDollar(rd.the_commission_value) : null,
                                                                            formatDollar(rd.real_value),
                                                                        ]
                                                                    })
                                                          :
                                                                []
                                                          }
                                                      />
                                                    </div>
                                                  )}
                                              </div>
                                          );
                                      })}
                                  </ul>
                                  {lt.ranges.length > 0 && (
                                      <div>
                                          {field === "lease_term" && (
                                              <h4>
                                                  Year {i + 1} Total Annual Amount :
                                                  <strong>
                                                      {formatDollar(year_calcs.total_value)}
                                                  </strong>
                                                  {field === "lease_term" && (
                                                      <span>
                                                          , Total Commission Amount :{" "}
                                                          <strong>{formatDollar(year_calcs.year_total_commission)}</strong>
                                                      </span>
                                                  )}
                                                 
                                              </h4>
                                          )}
                                      </div>
                                  )}
                                  {rangeError && <div className="red-text mt-20">{rangeError}</div>}
                                  <hr />
                              </div>
                              <h3>Abated Rent for Year {i + 1}</h3>
                              <Button
                                  color="primary"
                                  disabled={!!addTermRangesError(data)}
                                  onClick={() => {
                                      const range_index = data.abated_rent_ranges[i]
                                          ? data.abated_rent_ranges[i].ranges.length
                                          : 0; // new index

                                      const newRangeObj = {
                                          value: defaultRent(i),
                                          percent: 100,
                                          date1: "", // convertUnixToDate(the_term[year].year_start),
                                          date2: "", // convertUnixToDate(the_term[year].year_end),
                                          commissionRate: "",
                                          year_index: i,
                                          range_index,
                                      };
                                      const newTerm = clone(data.abated_rent_ranges);
                                      if (newTerm[i].ranges) {
                                          newTerm[i].ranges.push(newRangeObj);
                                      }
                                      updateDataObj("abated_rent_ranges", newTerm);
                                      setLocalAbatedData(newRangeObj);
                                      setEditAbatedRange({
                                          year_index: i,
                                          range_index: data.abated_rent_ranges[i]
                                              ? data.abated_rent_ranges[i].ranges.length
                                              : 0,
                                      });
                                      setScrollRef2(
                                          `${
                                              data.abated_rent_ranges[i] ? data.abated_rent_ranges[i].ranges.length : 0
                                          }-${i}`
                                      );
                                  }}
                              >
                                  ADD NEW ABATED RANGE
                              </Button>
                              <hr />
                              {
                                  <TermAbated
                                      data={data}
                                      year_index={i.toString()}
                                      updateDataObj={updateDataObj}
                                      setData={setData}
                                      setLocalData={setLocalAbatedData}
                                      localData={localAbatedData}
                                      setEditRange={setEditAbatedRange}
                                      editRange={editAbatedRange}
                                      scrollRef={scrollRef2}
                                      setScrollRef={setScrollRef2}
                                      ref={ref2}
                                      handleScrollClick={handleScrollClick2}
                                      calcsy={calcs}
                                  />
                              }
                              {/* {renderAbatedTotals(abated_ranges)} */}

                              <div>
                                  <h4><strong>YEAR {i+1}</strong></h4>
                                  {year_calcs.total_value !== 0 && <p>{chk(year_calcs.date1)} to {chk(year_calcs.date2)}</p>}
                                  <h4>
                                      Gross Rent Collected: <strong>{formatDollar(year_calcs.total_value)}</strong>
                                  </h4>
                                  <h4>
                                      Rent Abated: <strong>({formatDollar(abated_year_calcs.year_total_abated_value)})</strong>
                                  </h4>
                                  <h4>
                                      Net Rent Collected: <strong>{formatDollar(abated_year_calcs.year_net_rent_collected)}</strong>
                                  </h4>
                                  <h4>
                                      Gross Commission: <strong>{formatDollar(year_calcs.year_total_commission)}</strong>
                                  </h4>
                                  <h4>
                                      Abated Commission: <strong>({formatDollar(abated_year_calcs.year_total_abated_commission)})</strong>
                                  </h4>
                                  <h4>
                                      Net Commission:{" "}
                                      <strong>{formatDollar(abated_year_calcs.year_net_commission)}</strong>
                                  </h4>
                              </div>
                          </div>
                      );
                  })
                : null}

            {/* ESTIMATED TERMS */}
            {/* --------------- */}
            {isEstimated && the_term
                ? the_term.map((lt, i) => {
                      const yearAbated = get(data, `abated_rent_ranges[${i}].ranges`, []);

                      const abated_ranges = calculateEstimatedAbatedYearRanges({
                          lease_term,
                          abated_year_range: yearAbated,
                      });

                      let grossRent = 0;
                      let abatedRent = 0;
                      let grossCommission = 0;
                      let abatedCommission = 0;

                      const rentTotal = calculateEstimatedTermsTotal(lt.ranges, isFlatFee);
                      grossRent = rentTotal.totalTermRent;
                      grossCommission = rentTotal.totalCommission;
                      const abatedTotal = calculateEstimatedTermsTotal(abated_ranges, isFlatFee);
                      abatedRent = abatedTotal.totalTermRent;
                      abatedCommission = abatedTotal.totalCommission;

                      console.log('year estimated range', lt);

                      return (
                          <div key={`year-${i}`} className="boxed">
                              <h3>Lease Term Year {i + 1}</h3>
                              <hr />
                              <Button
                                  color="primary"
                                  style={{marginRight:20}}
                                  disabled={!!addTermRangesError(data)}
                                  onClick={() => {
                                      const year_index = i.toString();
                                      const year = parseInt(year_index);
                                      const range_index = lt.ranges.length; // new index
                                      //setOpenFormModal(year_index);
                                      const suggestedStart = getSuggestedRangeStart(i);
                                      const newRangeObj = {
                                          value: "",
                                          month1: suggestedStart.month,
                                          month2: "",
                                          day1: suggestedStart.day,
                                          day2: "",
                                          commissionRate: "",
                                          year_index,
                                          range_index,
                                          flat_fee: "0",
                                      };
                                      const newTerm = JSON.parse(JSON.stringify((the_term)));
                                      if (newTerm[year].ranges) {
                                          newTerm[year].ranges.push(newRangeObj);
                                      }
                                      if (field === 'lease_term') {
                                        // Open Add Range
                                        setLocalData(newRangeObj);
                                        console.log('opening add range', newRangeObj);
                                        setOpenedAddRange(true);
                                        // setEditRange({
                                        //     year_index: year,
                                        //     range_index,
                                        // });
                                        setScrollRef(`addRange-${year}`);
                                      }
                                  }}
                              >
                                  ADD NEW RANGEY
                              </Button>
                              <Button
                                  color="rose"
                                  disabled={!!addTermRangesError(data)}
                                  onClick={() => {
                                      const year_index = i.toString();
                                      const year = parseInt(year_index);
                                      const range_index = lt.ranges.length; // new index
                                      //setOpenFormModal(year_index);
                                      const suggestedStart = getSuggestedRangeStart(i);
                                      const suggested = getNewFullRange(i);
                                      const newRangeObj = {
                                          value: "",
                                          month1: suggested.month1,
                                          month2: suggested.month2,
                                          day1: suggested.day1,
                                          day2: suggested.day2,
                                          commissionRate: "",
                                          year_index,
                                          range_index,
                                          flat_fee: "0",
                                      };
                                      const newTerm = JSON.parse(JSON.stringify((the_term)));
                                      if (newTerm[year].ranges) {
                                          newTerm[year].ranges.push(newRangeObj);
                                      }
                                      if (field === 'lease_term') {
                                        // Open Add Range
                                        setLocalData(newRangeObj);
                                        console.log('opening add range', newRangeObj);
                                        setOpenedAddRange(true);
                                        // setEditRange({
                                        //     year_index: year,
                                        //     range_index,
                                        // });
                                        setScrollRef(`addRange-${year}`);
                                      }
                                  }}
                              >
                                  ADD NEW FULL RANGE
                              </Button>
                              <hr />

                              <div className="boxed" style={{ backgroundColor: "#fafafa" }}>
                                  {lt.ranges.length === 0 && (
                                      <div className="text-center">
                                          <em>No Ranges</em>
                                      </div>
                                  )}
                                  <ul className="list-unstyled">
                                      {lt.ranges.map((ltt, j) => {
                                          const { value, month1, month2, day1, day2, commissionRate, flat_fee: ff } = ltt;
                                          const flat_fee = ff ? ff.toString().replace(/[$,]/g, "") : 0;
                                          const isRequired = true;

                                          const year_start = lt.year_start;
                                          const year_end = lt.year_end;
                                          const dates_passed = validateDatesAndRange(
                                              localData.date1,
                                              localData.date2,
                                              year_start,
                                              year_end
                                          );

                                          const dates_are_in_range = validateDatesAndRange(
                                              localData.date1,
                                              localData.date2,
                                              year_start,
                                              year_end
                                          );

                                          let current_year_range = "";
                                          if (year_start && year_end) {
                                              current_year_range = `${timestampToDate(
                                                  year_start * 1000
                                              )} - ${timestampToDate(year_end * 1000)}`;
                                          }
                                          let range_requirements_passed = true;
                                          if (field === "lease_term") {
                                              if (
                                                  !dates_passed ||
                                                  !dates_are_in_range ||
                                                  !localData.date1 ||
                                                  !localData.date2 ||
                                                  !(localData.commissionRate || localData.flat_fee) ||
                                                  !localData.value
                                              ) {
                                                  range_requirements_passed = false;
                                              }
                                          } else {
                                              if (
                                                  !dates_passed ||
                                                  !dates_are_in_range ||
                                                  !localData.date1 ||
                                                  !localData.date2 ||
                                                  !localData.value
                                              ) {
                                                  range_requirements_passed = false;
                                              }
                                          }
                                          console.log('ltt calculation', ltt);
                                          const totalTerm = calculateEstimatedTotal(ltt, isFlatFee);
                                          const tableData = calculateEstimatedRangeTable(ltt);

                                          const isEditMode = editRange.range_index === j && editRange.year_index === i;

                                          console.log('TermJS', i, j, data.lease_term[i].ranges[j]);
                                          return (
                                              <div
                                                  key={`ranges-${j}=${i}`}
                                                  ref={scrollRef === `${j}-${i}` ? ref : null}
                                              >
                                                  <h4
                                                      className="bold black-text mr-20"
                                                      style={{
                                                          display: "inline-block",
                                                      }}
                                                  >
                                                      Range #{j + 1}
                                                  </h4>
                                                  {!isEditMode && (
                                                      <Button
                                                          color="rose"
                                                          onClick={() => {
                                                            console.log('editing range 1', editRange.year_index, editRange.range_index);
                                                            console.log('editing range 2', i, j);
                                                              setEditRange({
                                                                  year_index: i,
                                                                  range_index: j,
                                                              });
                                                              setLocalData({
                                                                  value,
                                                                  month1,
                                                                  month2,
                                                                  day1,
                                                                  day2,
                                                                  commissionRate,
                                                                  flat_fee,
                                                              });
                                                          }}
                                                      >
                                                          <span className="">
                                                              <i className="fas fa-edit hoverable"></i> EDIT
                                                          </span>
                                                      </Button>
                                                  )}
                                                  {isEditMode && (
                                                      <Button
                                                          disabled={
                                                              (!checkMonths(i) &&
                                                              localData.value &&
                                                              (props.data.flat_fee_commission === 'Yes' ? localData.flat_fee : localData.commissionRate))
                                                              ? false
                                                              : true
                                                          }
                                                          color="primary"
                                                          onClick={() => {
                                                              setEditRange({
                                                                  year_index: -1,
                                                                  range_index: -1,
                                                              });
                                                              const newData = clone(data);
                                                              newData[field][editRange.year_index].ranges[
                                                                  editRange.range_index
                                                              ] = {
                                                                  value: localData.value,
                                                                  month1: localData.month1,
                                                                  month2: localData.month2,
                                                                  day1: localData.day1,
                                                                  day2: localData.day2,
                                                                  commissionRate: localData.commissionRate,
                                                                  flat_fee: localData.flat_fee,
                                                              };
                                                              setData(newData);
                                                          }}
                                                      >
                                                          <span className="">
                                                              <i className="fas fa-check hoverable"></i> SAVE
                                                          </span>
                                                      </Button>
                                                  )}
                                                  {isEditMode && (
                                                      <Button
                                                          color="white"
                                                          onClick={() => {
                                                              setEditRange({
                                                                  year_index: -1,
                                                                  range_index: -1,
                                                              });
                                                              if (field === "lease_term") {
                                                                  if (!day1 || !day2 || !value || !commissionRate) {
                                                                      setAskDeleteRange({
                                                                          j,
                                                                          i,
                                                                      });
                                                                  }
                                                              } else {
                                                                  if (!day1 || !day2 || !value) {
                                                                      setAskDeleteRange({
                                                                          j,
                                                                          i,
                                                                      });
                                                                  }
                                                              }
                                                          }}
                                                      >
                                                          <span className="">
                                                              <i className="fas fa-times hoverable"></i> CANCEL
                                                          </span>
                                                      </Button>
                                                  )}
                                                  <Button
                                                      color="white"
                                                      onClick={() => {
                                                          setAskDeleteRange({
                                                              j,
                                                              i,
                                                          });
                                                      }}
                                                  >
                                                      <span className="">
                                                          <i className="fas fa-trash hoverable"></i> DELETE
                                                      </span>
                                                  </Button>
                                                  <div>
                                                      {!isEditMode && (
                                                          <div className="boxed mt-20 white-bg">
                                                              <GridContainer>
                                                                  <GridItem xs={12} sm={2} md={2}>
                                                                      <div>
                                                                          Monthly $
                                                                          <br />
                                                                          Value
                                                                      </div>
                                                                      <strong>$ {commatize(value, true)}</strong>
                                                                  </GridItem>
                                                                  {data.flat_fee_commission === "Yes" && (
                                                                      <GridItem xs={12} sm={2} md={2}>
                                                                          <div>Flat Fee</div>
                                                                          <strong>{formatDollar(flat_fee)}</strong>
                                                                      </GridItem>
                                                                  )}
                                                                  {data.flat_fee_commission !== "Yes" &&
                                                                      field === "lease_term" && (
                                                                          <GridItem xs={12} sm={2} md={2}>
                                                                              <div>
                                                                                  Commission
                                                                                  <br />
                                                                                  Rate
                                                                              </div>
                                                                              <strong>{commissionRate} %</strong>
                                                                          </GridItem>
                                                                      )}
                                                                  <GridItem xs={12} sm={2} md={2}>
                                                                      <div>
                                                                          Month-
                                                                          <br />
                                                                          Start
                                                                      </div>
                                                                      <strong>{month1}</strong>
                                                                  </GridItem>
                                                                  <GridItem xs={12} sm={2} md={2}>
                                                                      <div>
                                                                          Day
                                                                          <br />
                                                                          Start
                                                                      </div>
                                                                      <strong>{day1}</strong>
                                                                  </GridItem>
                                                                  <GridItem xs={12} sm={2} md={2}>
                                                                      <div>
                                                                          Month
                                                                          <br />
                                                                          End
                                                                      </div>
                                                                      <strong>{month2}</strong>
                                                                  </GridItem>
                                                                  <GridItem xs={12} sm={2} md={2}>
                                                                      <div>
                                                                          Day
                                                                          <br />
                                                                          End
                                                                      </div>
                                                                      <strong>{day2}</strong>
                                                                  </GridItem>
                                                                  {field === "lease_term" && (
                                                                      <GridItem
                                                                          xs={12}
                                                                          sm={6}
                                                                          md={6}
                                                                          style={{ marginTop: "10px" }}
                                                                      >
                                                                          <div>Total Commission :</div>
                                                                          {formatDollar(totalTerm.totalCommission)}
                                                                      </GridItem>
                                                                  )}
                                                                  <GridItem
                                                                      xs={12}
                                                                      sm={6}
                                                                      md={6}
                                                                      style={{ marginTop: "10px" }}
                                                                  >
                                                                      {field === "lease_term" && (
                                                                          <div>
                                                                              Range's Total{" "}
                                                                              {field === "lease_term"
                                                                                  ? "Rent Collected"
                                                                                  : "Abated Rent"}{" "}
                                                                              :
                                                                              <br />
                                                                              <span
                                                                                  className="text-underline hovered black-text bold"
                                                                                  onClick={() => {
                                                                                      if (isShowingRanges(i, j)) {
                                                                                          hideRanges(i, j);
                                                                                      } else {
                                                                                          showRanges(i, j);
                                                                                      }
                                                                                  }}
                                                                              >
                                                                                  {formatDollar(
                                                                                      totalTerm.totalTermRent
                                                                                  )}
                                                                              </span>
                                                                          </div>
                                                                      )}
                                                                  </GridItem>
                                                              </GridContainer>
                                                          </div>
                                                      )}
                                                  </div>
                                                  {editRange && (
                                                      <div>
                                                          {editRange.range_index === j &&
                                                              editRange.year_index === i && (
                                                                  <div
                                                                      className="boxed mt-20 white-bg"
                                                                      style={{
                                                                          backgroundColor: "white",
                                                                      }}
                                                                  >
                                                                      <TermForm
                                                                          data={props.data}
                                                                          value={localData.value}
                                                                          month1={localData.month1}
                                                                          month2={localData.month2}
                                                                          day1={localData.day1}
                                                                          day2={localData.day2}
                                                                          commissionRate={localData.commissionRate}
                                                                          flat_fee={localData.flat_fee}
                                                                          update={(fieldType, val) => {
                                                                              const newLocalData = clone(localData);
                                                                              newLocalData[fieldType] = val;
                                                                              setLocalData(newLocalData);
                                                                          }}
                                                                          updateMultiFields={(obj) => {
                                                                            const newLocalData = clone(localData);
                                                                            Object.keys(obj).forEach(key => {
                                                                                newLocalData[key] = obj[key]
                                                                            })
                                                                            setLocalData(newLocalData);
                                                                          }}
                                                                          isRequired={isRequired}
                                                                          setShowRangeTable={setShowRangeTable}
                                                                          showRangeTable={showRangeTable}
                                                                          field={field}
                                                                          currentYear={i + 1}
                                                                          openFormModal={openFormModal}
                                                                          isEstimated
																		  isCPI={isCPI}
																		  isNextRange={j !== 0}
                                                                          currentIndex={j}
                                                                      />
                                                                      <hr />
                                                                      <em>
                                                                          * Requirements : Start date must be earlier
                                                                          than End date. All fields are required.
                                                                      </em>
                                                                      {checkMonths(i)}
                                                                  </div>
                                                              )}
                                                      </div>
                                                  )}
                                                  {isShowingRanges(i, j) && (
                                                      <Table
                                                          tableHead={[
                                                              "Start Date",
                                                              "Num of Days",
                                                              "Total",
                                                              "Rounded Total",
                                                          ]}
                                                          tableData={tableData}
                                                      />
                                                  )}
                                              </div>
                                          );
                                      })}

                                      {(openedAddRange && field === 'lease_term') && <div
                                                className="boxed mt-20 white-bg"
                                                style={{
                                                    backgroundColor: "white",
                                                    display: i === parseInt(localData.year_index) ? 'block' : 'none'
                                                }}
                                                ref={scrollRef === `addRange-${i}` ? ref : null}
                                            >
                                                <h4
                                                      className="bold black-text mr-20"
                                                      style={{
                                                          display: "inline-block",
                                                      }}
                                                  >
                                                      Range #{localData.range_index + 1}
                                                  </h4>
                                                  <Button
                                                    disabled={
                                                        (!checkMonths(i) &&
                                                        localData.value &&
                                                        (props.data.flat_fee_commission === 'Yes' ? localData.flat_fee : localData.commissionRate))
                                                        ? false
                                                        : true
                                                    }
                                                    color="primary"
                                                    onClick={() => {
                                                        setEditRange({
                                                            year_index: -1,
                                                            range_index: -1,
                                                        });
                                                        const newData = clone(data);
                                                        newData[field][i].ranges.push({
                                                            value: localData.value,
                                                            month1: localData.month1,
                                                            month2: localData.month2,
                                                            day1: localData.day1,
                                                            day2: localData.day2,
                                                            commissionRate: localData.commissionRate,
                                                            flat_fee: localData.flat_fee,
                                                        });
                                                        console.log('newData', localData, newData);
                                                        setData(newData);
                                                        setOpenedAddRange(false);
                                                    }}
                                                >
                                                    <span className="">
                                                        <i className="fas fa-check hoverable"></i> SAVE
                                                    </span>
                                                </Button>
                                                <Button
                                                    color="white"
                                                    onClick={() => {
                                                        setOpenedAddRange(false);
                                                    }}
                                                >
                                                    <span className="">
                                                        CANCEL
                                                    </span>
                                                </Button>
                                                <TermForm
                                                    data={props.data}
                                                    value={localData.value}
                                                    month1={localData.month1}
                                                    month2={localData.month2}
                                                    day1={localData.day1}
                                                    day2={localData.day2}
                                                    commissionRate={localData.commissionRate}
                                                    flat_fee={localData.flat_fee}
                                                    update={(fieldType, val) => {
                                                        const newLocalData = clone(localData);
                                                        newLocalData[fieldType] = val;
                                                        setLocalData(newLocalData);
                                                    }}
                                                    updateMultiFields={(obj) => {
                                                        const newLocalData = clone(localData);
                                                        Object.keys(obj).forEach(key => {
                                                            newLocalData[key] = obj[key]
                                                        })
                                                        setLocalData(newLocalData);
                                                    }}
                                                    isRequired={true}
                                                    setShowRangeTable={setShowRangeTable}
                                                    showRangeTable={showRangeTable}
                                                    field="lease_term"
                                                    currentYear={parseInt(localData.year_index) + 1}
                                                    openFormModal={openFormModal}
                                                    isEstimated
                                                    isCPI={isCPI}
                                                    isNextRange={localData.range_index !== 0}
                                                    currentIndex={localData.range_index}
                                                />
                                                <hr />
                                                <em>
                                                    * Requirements : Start date must be earlier
                                                    than End date. All fields are required.
                                                </em>
                                                {checkMonths(i)}
                                            </div>}
                                  </ul>
                                  <Button
                                      color="primary"
                                      disabled={!!addTermRangesError(data)}
                                      onClick={() => {
                                          const range_index = data.abated_rent_ranges[i]
                                              ? data.abated_rent_ranges[i].ranges.length
                                              : 0; // new index

                                          const newRangeObj = {
                                              value: defaultRent(i),
                                              percent: 100,
                                              month1: "",
                                              month2: "",
                                              day1: "",
                                              day2: "",
                                              date1: "",
                                              date2: "",
                                              commissionRate: "",
                                              year_index: i,
                                              range_index,
                                          };
                                          const newTerm = clone(data.abated_rent_ranges);
                                          if (newTerm[i].ranges) {
                                              newTerm[i].ranges.push(newRangeObj);
                                          }
                                          updateDataObj("abated_rent_ranges", newTerm);
                                          setLocalAbatedData(newRangeObj);
                                          setEditAbatedRange({
                                              year_index: i,
                                              range_index: data.abated_rent_ranges[i]
                                                  ? data.abated_rent_ranges[i].ranges.length
                                                  : 0,
                                          });
                                          setScrollRef2(
                                              `${
                                                  data.abated_rent_ranges[i]
                                                      ? data.abated_rent_ranges[i].ranges.length
                                                      : 0
                                              }-${i}`
                                          );
                                      }}
                                  >
                                      ADD NEW ABATED RANGE
                                  </Button>
                                  <hr />
                                  {
                                      <EstimatedTermAbated
                                          data={data}
                                          year_index={i.toString()}
                                          updateDataObj={updateDataObj}
                                          setData={setData}
                                          setLocalData={setLocalAbatedData}
                                          localData={localAbatedData}
                                          setEditRange={setEditAbatedRange}
                                          editRange={editAbatedRange}
                                          scrollRef={scrollRef2}
                                          setScrollRef={setScrollRef2}
                                          ref={ref2}
                                          handleScrollClick={handleScrollClick2}
                                      />
                                  }

                                  <hr />
                              </div>

                              {/* {renderAbatedTotals(i)} */}
                              <div>
                                  <h4>
                                      Year Gross Rent Collected (Estimated): <strong>{formatDollar(grossRent)}</strong>
                                  </h4>
                                  <h4>
                                      Year Rent Abated (Estimated): <strong>{formatDollar(abatedRent, true)}</strong>
                                  </h4>
                                  <h4>
                                      Year Net Rent Collected (Estimated):{" "}
                                      <strong>{formatDollar(grossRent + abatedRent)}</strong>
                                  </h4>
                                  <h4>
                                      Year Gross Commission (Estimated): <strong>{formatDollar(grossCommission)}</strong>
                                  </h4>
                                  <h4>
                                      Year Abated Commission (Estimated):{" "}
                                      <strong>{formatDollar(abatedCommission, true)}</strong>
                                  </h4>
                                  <h4>
                                      Year Net Commission (Estimated):{" "}
                                      <strong>{formatDollar(grossCommission + abatedCommission)}</strong>
                                  </h4>
                              </div>
                          </div>
                      );
                  })
                : null}

            {askDeleteRange && (
                <Dialog
                    open={askDeleteRange ? true : false}
                    onClose={() => {
                        setAskDeleteRange(null);
                    }}
                    fullWidth={true}
                    maxWidth="sm"
                >
                    <div className="dialog-wrapper text-center">
                        <h4>Are you sure you want to delete this range?</h4>
                        <Button
                            color="primary"
                            onClick={() => {
                                const { i, j } = askDeleteRange;
                                const newTerm = clone(the_term);
                                newTerm[i].ranges = newTerm[i].ranges.filter((r, ii) => {
                                    if (ii !== j) return true;
                                });
                                updateDataObj(field, newTerm);
                                setAskDeleteRange(null);
                            }}
                        >
                            Yes
                        </Button>
                        <Button
                            color="white"
                            onClick={() => {
                                setAskDeleteRange(null);
                            }}
                        >
                            CANCEL
                        </Button>
                    </div>
                </Dialog>
            )}
        </div>
    );
};

export default Term;
