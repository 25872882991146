import React, { useState } from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Table from "components/Table/Table.jsx";
import CustomInput from "components/CustomInput/CustomInputValidate";
import { cloneJ } from "../../shared/utility";
import Button from "../../components/CustomButtons/Button";
import { IconButton } from "@material-ui/core";
import { getSum } from "./CommissionSplitsLogic";
import NotificationAsk from "../../components/Notification/NotificationAsk";
import CustomCRMEditor from "../../components/CustomDialog/CustomCRMEditor";

const Step3 = (props) => {
    const [askBeforeDelete, setAskBeforeDelete] = useState(-1);
    const [edit, setEdit] = useState(null);
    const { dataObj, updateDataObj, setOpenedChooseModal, setQueryFor, currentUserIsSubmitter, currentUserIsAnAgentApprover } = props;
    const sum = getSum(dataObj.referrals);
    const isCompleteTab = props.parentTab === 'my_completed' || props.parentTab === 'all_completed' ? 
        true : false;
    return <div className={currentUserIsAnAgentApprover || props.action === 'view' ? 'no-click' : ''}>
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <h3>Referrals</h3>
                {(props.parentTab !== 'my_completed' && props.parentTab !== 'all_completed') && <div>
                    {currentUserIsSubmitter && <Button color="primary" onClick={() => {
                        setQueryFor({
                            title: 'Select Referrals',
                            label: 'Select Referrals',
                            type: 'referrals'
                        })
                        setOpenedChooseModal(true)
                    }}>ADD NEW</Button>}
                </div>}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
            <Table
                tableHeaderColor="primary"
                tableHead={['Brokerage Firm','Name','Email','Commission %','Commission Flat Amount','Type','Remove']}
                tableData={dataObj.referrals.map((a,i) => {
                    let dba_company = '';
                    let dba_contact = '';
                    if (a.name) {
                        if (a.dba_name) {
                            dba_company = `DBA ${a.dba_name}`;
                        }
                    } else {
                        if (a.dba_name) {
                            dba_contact = `DBA ${a.dba_name}`;
                        }
                    }

                    return [
                        <div>
                            {a.name && <div>
                                <CustomInput 
                                    value={a.name}
                                    label="Company Name"
                                    onClick={() => {
                                        setEdit({
                                            value: a.name,      
                                            type: 'company',       
                                            field: 'name',      
                                            fieldType: 'input',  
                                            label: 'Company Name',      
                                            id: a.company_id, 
                                            chooseVal: (chosenVal) => {
                                                updateDataObj('referrals', dataObj.referrals.map((x,j) => {
                                                    if (j === i) {
                                                        x.name = chosenVal;
                                                    }
                                                    return x;
                                                })); 
                                                setEdit(null); 
                                            },
                                        })
                                    }}
                                />
                            </div>}
                            {(a.dba_name && a.company_id) && <div>
                                <CustomInput 
                                    value={a.dba_name}
                                    onClick={() => {
                                        setEdit({
                                            value: a.dba_id,      
                                            type: 'company_dba',       
                                            field: 'name',      
                                            fieldType: 'select',  
                                            label: 'Company DBA Name',      
                                            id: a.dba_id, 
                                            company_id: a.company_id,
                                            chooseVal: (chosenVal, chosenName) => { 
                                                updateDataObj('referrals', dataObj.referrals.map((x,j) => {
                                                    if (j === i) {
                                                        x.dba_id = chosenVal;
                                                        x.dba_name = chosenName;
                                                    }
                                                    return x;
                                                })); 
                                                setEdit(null); 
                                            },
                                        })
                                    }}
                                    label="Company DBA Name"
                                />
                            </div>}
                        </div>,
                        <div>
                            <div>
                                <CustomInput 
                                    value={a.first_name}
                                    label="First Name"
                                    onClick={() => {
                                        if (a.contact_id) {
                                            setEdit({
                                                value: a.first_name,      
                                                type: 'contact',       
                                                field: 'first_name',      
                                                fieldType: 'input',  
                                                label: 'First Name',      
                                                id: a.contact_id,         
                                                chooseVal: (chosenVal) => {
                                                    updateDataObj('referrals', dataObj.referrals.map((x,j) => {
                                                        if (j === i) {
                                                            x.first_name = chosenVal;
                                                        }
                                                        return x;
                                                    })); 
                                                    setEdit(null); 
                                                }
                                            });
                                        }
                                    }}
                                />
                            </div>
                            <div>
                                <CustomInput 
                                    value={a.last_name}
                                    label="Last Name"
                                    onClick={() => {
                                        if (a.contact_id) {
                                            setEdit({
                                                value: a.last_name,      
                                                type: 'contact',       
                                                field: 'last_name',      
                                                fieldType: 'input',  
                                                label: 'Last Name',      
                                                id: a.contact_id,         
                                                chooseVal: (chosenVal) => {
                                                    updateDataObj('referrals', dataObj.referrals.map((x,j) => {
                                                        if (j === i) {
                                                            x.last_name = chosenVal;
                                                        }
                                                        return x;
                                                    })); 
                                                    setEdit(null); 
                                                }
                                            });
                                        }
                                    }}
                                />
                            </div>
                            {(dba_contact && !a.company_id) && <div>
                                <CustomInput 
                                    value={a.dba_name}
                                    label="Contact DBA"
                                    onClick={() => {
                                        setEdit({
                                            value: a.dba_id,      
                                            type: 'contact_dba',       
                                            field: 'name',      
                                            fieldType: 'select',  
                                            label: 'Contact DBA Name',      
                                            id: a.dba_id, 
                                            contact_id: a.contact_id,
                                            chooseVal: (chosenVal, chosenName) => { 
                                                updateDataObj('referrals', dataObj.referrals.map((x,j) => {
                                                    if (j === i) {
                                                        x.dba_id = chosenVal;
                                                        x.dba_name = chosenName;
                                                    }
                                                    return x;
                                                })); 
                                                setEdit(null); 
                                            },
                                        })
                                    }}
                                />
                            </div>}
                        </div>,
                        <div>
                            <CustomInput 
                                value={a.email}
                                label="Email"
                                onClick={() => {
                                    if (a.contact_id && !a.company_id) {
                                        setEdit({
                                            value: a.email,      
                                            type: 'contact',       
                                            field: 'email',      
                                            fieldType: 'input',  
                                            label: 'Email',      
                                            id: a.contact_id,         
                                            chooseVal: (chosenVal) => {
                                                updateDataObj('referrals', dataObj.referrals.map((x,j) => {
                                                    if (j === i) {
                                                        x.email = chosenVal;
                                                    }
                                                    return x;
                                                })); 
                                                setEdit(null); 
                                            }
                                        });
                                    } else if (a.company_id && a.company_contact_id) {
                                        setEdit({
                                            value: a.email,      
                                            type: 'company_contact',       
                                            field: 'email',      
                                            fieldType: 'input',  
                                            label: 'Email',      
                                            id: a.company_contact_id,         
                                            chooseVal: (chosenVal) => {
                                                updateDataObj('referrals', dataObj.referrals.map((x,j) => {
                                                    if (j === i) {
                                                        x.email = chosenVal;
                                                    }
                                                    return x;
                                                }));
                                                setEdit(null); 
                                            }
                                        });
                                    }
                                }}
                            />
                        </div>,
                        <CustomInput 
                            label="Enter %"
                            value={a.commission_percent}
                            disabled={currentUserIsSubmitter ? false : true}
                            onChange={(e) => {
                                const newData = cloneJ(dataObj);
                                newData.referrals = newData.referrals.map((c,x) => {
                                    if (x === i) {
                                        c.commission_percent = e.target.value;
                                        return c;
                                    }
                                    return c;
                                })
                                updateDataObj('referrals', newData.referrals);
                            }}
                            number_only={true}
                        />,
                        <CustomInput 
                            label="Enter Flat Amt."
                            value={a.commission_flat_amt}
                            disabled={currentUserIsSubmitter ? false : true}
                            onChange={(e) => {
                                const newData = cloneJ(dataObj);
                                newData.referrals = newData.referrals.map((c,x) => {
                                    if (x === i) {
                                        c.commission_flat_amt = e.target.value;
                                        return c;
                                    }
                                    return c;
                                })
                                updateDataObj('referrals', newData.referrals);
                            }}
                            number_only={true}
                            startAdornment="$"
                        />,
                        <div>{a.referral_type}</div>,
                        !isCompleteTab && <IconButton
                            disabled={currentUserIsSubmitter ? false : true}
                            onClick={() => {
                                setAskBeforeDelete(i);
                            }}
                        ><i className="fa fa-trash"></i></IconButton>
                    ]
                }).concat([
                    ['',
                    '',
                    'TOTAL',
                    <div style={{color:sum === 100 ? 'green' : 'red'}}>{sum} %</div>,
                    '']
                ])}
                coloredColls={[1]}
                colorsColls={["primary"]}
            />
            </GridItem>
        </GridContainer>

        {askBeforeDelete !== -1 && <NotificationAsk 
            title="Are you sure?"
            message="Do you want to remove this referral?"
            success={() => {
                if (props.parentTab === 'my_completed' || props.parentTab === 'all_completed') {
                    setAskBeforeDelete(-1);
                    return;
                }
                const newData = cloneJ(dataObj);
                newData.referrals = newData.referrals.filter((c,x) => {
                    if (x === askBeforeDelete) {
                        return false;
                    }
                    return true;
                })
                updateDataObj('referrals', newData.referrals);
                setAskBeforeDelete(-1);
            }}
            open={askBeforeDelete !== -1 ? true : false}
            close={() => {
                setAskBeforeDelete(-1);
            }}
        />}

        {edit !== null && <CustomCRMEditor 
            {...edit}
            close={() => {
                setEdit(null)
            }}
        />}
    </div>
}

export default Step3;