import React, { useEffect, useState, useRef } from "react";
import { get, isEqual, defaultTo } from 'lodash';
import { connect } from 'react-redux';
import { ArrowBack, Close } from "@material-ui/icons";
import { Dialog, Tooltip, Tabs, Tab } from "@material-ui/core";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.jsx";

import RSheetsConsultingFormWizard from "./RSheetsConsultingFormWizard";
import RSheetsConsultingFormFull from "./RSheetsConsultingFormFull";
import Notification from "components/Notification/Notification";
import NotificationAsk from "../../../components/Notification/NotificationAsk";
import { clone } from "../../../shared/utility";
import * as actions from "store/actions";
import History from "../CommonCustomElements/History";

const RSheetsConsultingForm = (props) => {
    const { contactChangesData, companyChangesData, propertyChangesData } = props

    const [tab, setTab] = useState(props.viewOnly ? "full" : "wizard");
    const [openedAskModal, setOpenedAskModal] = useState(false);
    const [step, setStep] = useState(1);
    const tabIndex = tab === "wizard" ? 0 : 1;

    const [isDeclined, setIsDeclined] = useState(false);
    const [declinedContactChangesData, setDeclinedContactChangesData] = useState([])
    const [declinedCompanyChangesData, setDeclinedCompanyChangesData] = useState([])
    const [declinedPropertyChangesData, setDeclinedPropertyChangesData] = useState([])

    const [notifySave, setNotifySave] = useState(false);
    const [notification, setNotification] = useState('');
    const [shouldClose, setShouldClose] = useState(false);

    const [canEditInvoiceRelatedData, setCanEditInvoiceRelatedData] = useState(true);
    const [openedInvoiceRelatedDataAsk, setOpenedInvoiceRelatedDataAsk] = useState(false);

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevSuccessCrud = usePrevious(props.success_crud);
    const prevData = usePrevious(props.data);

    useEffect(() => {
        if (isDeclined) {
			setDeclinedContactChangesData(defaultTo(contactChangesData, []))
            setDeclinedCompanyChangesData(defaultTo(companyChangesData, []))
            setDeclinedPropertyChangesData(defaultTo(propertyChangesData, []))
            updateDataObj('changesDataDeclined', {
                contactChanges: defaultTo(contactChangesData, []).map(m => m.after.id),
                companyChanges: defaultTo(companyChangesData, []).map(m => m.after.id),
                propertyChanges: defaultTo(propertyChangesData, []).map(m => m.after.id),
            });
        }
    }, [isDeclined]);
    useEffect(() => {
        if (contactChangesData) {
            const filteredDeclinedData = declinedContactChangesData.filter((obj) => {
                const id = get(obj, 'before.id', null);
                const found = contactChangesData.findIndex(item => id === get(item, 'before.id', null)) 
                return found !== -1
            })
            if (!isEqual(contactChangesData, filteredDeclinedData)) {
                setIsDeclined(false);
            }
        }
    }, [contactChangesData])
    useEffect(() => {
        if (companyChangesData) {
            const filteredDeclinedData = declinedCompanyChangesData.filter((obj) => {
                const id = get(obj, 'before.id', null);
                const found = companyChangesData.findIndex(item => id === get(item, 'before.id', null)) 
                return found !== -1
            })
            if (!isEqual(companyChangesData, filteredDeclinedData)) {
                setIsDeclined(false);
            }
        }
    }, [companyChangesData])
    useEffect(() => {
        if (propertyChangesData) {
            const filteredDeclinedData = declinedPropertyChangesData.filter((obj) => {
                const id = get(obj, 'before.id', null);
                const found = propertyChangesData.findIndex(item => id === get(item, 'before.id', null)) 
                return found !== -1
            })
            if (!isEqual(propertyChangesData, filteredDeclinedData)) {
                setIsDeclined(false);
            }
        }
    }, [propertyChangesData])

    const cancel = () => {
        const exempted_tabs = ['my_completed', 'all_completed'];
        if (props.activityTracker && exempted_tabs.indexOf(props.tab) === -1) {
            return setOpenedAskModal(true);
        }
        props.close();
    };

    const updateDataObj = (field, value) => {
        if (!props.activityTracker) {
            props.setActivityTracker(true);
        }
        props.updateDataObj(field, value);
    };

    const updateDataMultiObj = (fieldObj) => {
        if (!props.activityTracker) {
            props.setActivityTracker(true);
        }
        props.updateDataMultiObj(fieldObj);
    };

    const setData = (newData) => {
        if (!props.activityTracker) {
            if (props.data && prevData) {
                if (props.data.id && prevData.id) {
                    props.setActivityTracker(true);
                }
            }
        }
        props.setData(newData);
    }

    const save = () => {
        const { data } = props;
        setNotifySave(true);
        if (!data.id) {
            props.createRsheet({
                json_data: JSON.stringify(data),
                status: 1,
                project_name: data.project_name,
                type: 6, // CONSULTING
            });
        } else {
            props.updateRsheet({
                id: data.id,
                json_data: JSON.stringify(data),
                project_name: data.project_name,
            });
        }
        props.setFormDataObj({});
    }

    useEffect(() => {
        if (prevSuccessCrud === null && props.success_crud) {
            if (props.success_crud.indexOf('success') !== -1) {
                if (notifySave) {
                    setNotification('Saved');
                    setNotifySave(false);
                }
                if (shouldClose) {
                    props.close();
                    setShouldClose(false);
                }
            }
        }
    }, [props.success_crud]);

    useEffect(() => {
        props.setActivityTracker(false);
        if (props.rsheetData) {
            if (props.rsheetData.status === 11) {
                setTab('wizard');
            }
        }
    }, []);

    useEffect(() => {
    }, [props.data]);

    useEffect(() => {
        const { invoiceEntries } = props;
        const rsheetData = props.rsheetData;
        console.log('rsheetData', rsheetData, invoiceEntries);
        if (invoiceEntries && rsheetData) {
            if (rsheetData.status === 10 && invoiceEntries.length > 0) {
                setCanEditInvoiceRelatedData(false);
            } else if (rsheetData.title && invoiceEntries.length > 0) {
                // presence of rsheetData title means this sheet is a revision
                setCanEditInvoiceRelatedData(false);
            } else {
                setCanEditInvoiceRelatedData(true);
            }
        }
    }, [props.rsheetData, props.invoiceEntries]);

    let actionToShow = props.action;
    if (props.viewOnly) {
        actionToShow = "View";
    }

    console.log('RSheetsConsultingForm', props);

    return (
        <GridContainer>
            <div
                className="close-btn"
                onClick={() => {
                    cancel();
                }}
            >
                <Close />
            </div>
            <GridItem xs={12} sm={12} md={12}>
                <h3 className="text-center">{actionToShow} Consulting Routing Sheet 
                {props.rsheetData ? 
                    props.rsheetData.title ? 
                        <span style={{color: 'red'}}> - Revision {props.rsheetData.title.split('_').length}</span> 
                    : 
                        ''
                : 
                    ''}</h3>
                <div
                    style={{
                        marginRight: 20,
                        marginBottom: 10,
                        display: "inline-block",
                    }}
                    onClick={() => {
                        cancel();
                    }}
                    className="hoverable"
                >
                    <Tooltip id="tooltip-top-start1" title="Back to List" placement="top">
                        <ArrowBack />
                    </Tooltip>
                </div>
                <Tabs
                    TabIndicatorProps={{
                        style: { background: "#da3b2f", borderBottom: "1px solid #da3b2f", color: "#da3b2f" },
                    }}
                    style={{ marginBottom: 10 }}
                    value={tabIndex}
                >
                    {(
                        <Tab
                            onClick={() => {
                                setTab("wizard");
                            }}
                            style={{ color: tabIndex === 0 ? "#da3b2f" : "rgba(0, 0, 0, 0.87)" }}
                            tabIndex={0}
                            label="Wizard"
                            icon={<i className="fas fa-magic font-22"></i>}
                        />
                    )}
                    {
                        <Tab
                            onClick={() => {
                                setTab("full");
                            }}
                            style={{ color: tabIndex === 1 ? "#da3b2f" : "rgba(0, 0, 0, 0.87)" }}
                            tabIndex={1}
                            label="Full Form"
                            icon={<i className="fas fa-sticky-note font-22"></i>}
                        />
                    }
                    {
                        <Tab
                            onClick={() => {
                                setTab("history");
                            }}
                            style={{ color: tabIndex === 2 ? "#da3b2f" : "rgba(0, 0, 0, 0.87)" }}
                            tabIndex={2}
                            label="History"
                            icon={<i className="fas fa-list font-22"></i>}
                        />
                    }
                </Tabs>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                {tab === "wizard" && (
                    <RSheetsConsultingFormWizard
                        data={props.data}
                        updateDataObj={updateDataObj}
                        updateDataMultiObj={updateDataMultiObj}
                        setData={setData}
                        formDataObj={props.formDataObj}
                        setFormDataObj={props.setFormDataObj}
                        setTab={setTab}
                        setOpenedForm={props.setOpenedForm}
                        level={props.level}
                        getAdminData={props.getAdminData}
                        setActivityTracker={props.setActivityTracker}
                        step={step}
                        setStep={setStep}
                        action={props.action}
                        originalData={props.originalData}
                        setOriginalData={props.setOriginalData}
                        originalContactData={props.originalContactData}
                        setOriginalContactData={props.setOriginalContactData}
                        tab={props.tab}
                        closeTheForm={() => {
                            props.close();
                        }}
                        rsheetData={props.rsheetData}
                        historyPush={props.historyPush}
                        viewOnly={props.viewOnly}
                        isDeclined={isDeclined}
                        setIsDeclined={setIsDeclined}
                        save={save}
                        canEditInvoiceRelatedData={canEditInvoiceRelatedData}
                        setCanEditInvoiceRelatedData={setCanEditInvoiceRelatedData}
                        setOpenedInvoiceRelatedDataAsk={setOpenedInvoiceRelatedDataAsk}
                        setPrefillPropertyObject={props.setPrefillPropertyObject}
                        prefillPropertyObject={props.prefillPropertyObject}
                    />
                )}
                {tab === "full" && (
                    <RSheetsConsultingFormFull
                        setStep={(num) => {
                            // setStep(num);
                            // setTab('wizard');
                        }}
                        data={props.data}
                        updateDataObj={updateDataObj}
                        updateDataMultiObj={updateDataMultiObj}
                        setData={setData}
                        setOpenedForm={props.setOpenedForm}
                        setFormDataObj={props.setFormDataObj}
                        viewOnly={props.viewOnly}
                        level={props.level}
                        setActivityTracker={props.setActivityTracker}
                        originalData={props.originalData}
                        setOriginalData={props.setOriginalData}
                        originalContactData={props.originalContactData}
                        setOriginalContactData={props.setOriginalContactData}
                        tab={props.tab}
                        closeTheForm={() => {
                            props.close();
                        }}
                        historyPush={props.historyPush}
                        rsheetData={props.rsheetData}
                        save={save}
                        canEditInvoiceRelatedData={canEditInvoiceRelatedData}
                        setCanEditInvoiceRelatedData={setCanEditInvoiceRelatedData}
                        setOpenedInvoiceRelatedDataAsk={setOpenedInvoiceRelatedDataAsk}
                    />
                )}
                {tab === 'history' && <History 
                            rsheetData={props.rsheetData}
                        />}
            </GridItem>

            {openedAskModal && (
                <Dialog
                    open={openedAskModal}
                    onClose={() => {
                        setOpenedAskModal(false);
                    }}
                    maxWidth="md"
                    fullWidth={true}
                >
                    <div style={{ padding: 40 }} className="text-center">
                        <h4>
                            Are you sure you want to close this window?
                            <br />
                            Any unsaved progress will be lost.
                        </h4>
                        <hr />
                        <Button
                            className="mr-20"
                            color="primary"
                            onClick={() => {
                                const { data } = props;
                                if (!data.project_address || !data.project_name) {
                                    return setNotification('Please select a property with address and project name to begin saving.')
                                }
                                setOpenedAskModal(false);
                                setShouldClose(true);
                                save();
                            }}
                        >
                            SAVE & CLOSE
                        </Button>
                        <Button
                            className="mr-20"
                            color="success"
                            onClick={() => {
                                setOpenedAskModal(false);
                                props.close();
                            }}
                        >
                            CLOSE
                        </Button>
                        <Button
                            color="white"
                            onClick={() => {
                                setOpenedAskModal(false);
                            }}
                        >
                            CANCEL
                        </Button>
                    </div>
                </Dialog>
            )}
            {notification && <Notification 
                open={notification ? true : false}
                close={() => { setNotification('') }}
                message={notification}
            />}
            {openedInvoiceRelatedDataAsk && <NotificationAsk 
                open={openedInvoiceRelatedDataAsk ? true : false}
                close={() => { setOpenedInvoiceRelatedDataAsk(false) }}
                title="Are you sure?"
                message="By editing this Routing Sheet, the generated invoice will be voided."
                success={() => {
                    const billing_email = get(props, 'data.billing_email', '');
                    const billing_firstname = get(props, 'data.billing_attention', '');
                    const billing_lastname = get(props, 'data.billing_attention_lastname', '');
                    if (billing_email) {
                        const obj = {
                            email: billing_email, 
                            name: `${billing_firstname} ${billing_lastname}`
                        }
                        props.voidInvoices(props.invoiceEntries.map(i => {
                            const newInvoice = clone(i);
                            newInvoice.status = 4; // VOID
                            return newInvoice;
                        }), obj);
                        setCanEditInvoiceRelatedData(true);
                        setOpenedInvoiceRelatedDataAsk(false);
                    } else {
                        alert('No billing email to send notification to');
                    }
                }}
                success_button_text="Yes, let me Edit this routing sheet and Void Invoice(s)"
            />}
        </GridContainer>
    );
};

const mapStateToProps = state => {
    const p = state.crm_properties;
    const c = state.crm_contacts;
    const m = state.crm_companies;
    return {
        contactChangesData: c.contactChangesData,
        companyChangesData: m.companyChangesData,
        propertyChangesData: p.propertyChangesData,
        error: state.rs.error,
        success: state.rs.success,
        success_crud: state.rs.success_crud,
        rsheetData: state.rs.rsheetData,
        pdfBuffer: state.rs.pdfBuffer,
        invoiceID: state.invoice.invoiceID,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        createRsheet: (data, invoiceData = null) => {
            dispatch(actions.createRsheet(data, invoiceData));
        },
        getRsheets: (params) => {
            dispatch(actions.getRsheets(params));
        },
        updateRsheet: (params, invoiceData = null) => {
            dispatch(actions.updateRsheet(params, invoiceData));
        },
        createRsheetPdf: (params) => {
            dispatch(actions.createRsheetPdf(params));
        },
        createRsheetFiles: (params) => {
            dispatch(actions.createRsheetFiles(params));
        },
        createInvoiceEntry: (data) => {
            dispatch(actions.createInvoiceEntry(data));
        },
        voidInvoices: (data, sendNotificationTo) => {
            dispatch(actions.voidInvoices(data, sendNotificationTo));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RSheetsConsultingForm);
