import React from "react";
import { useEffect, useCallback } from "react";
import { get } from 'lodash';
import { clone } from "../../../shared/utility";

import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";

import { checkOutsideBrokerOrReferralFirmFields, setReferralFirmFeeAmountChoice } from "../RSheetsLogic";
import Brokers from "../CommonCustomElements/Brokers";
import LessEntry from "../CommonCustomElements/LessEntry";
import LessContacts from "../CommonCustomElements/LessContacts";
import ReferralsLessee from "../CommonCustomElements/ReferralsLessee";

export const LESSEE_OUTSIDE_BROKER_FIELDS = [
    "outside_broker_company_name",
    "outside_broker_name",
    "outside_broker_lastname",
    "outside_broker_address1",
    "outside_broker_city",
    "outside_broker_state",
    "outside_broker_zip",
    "outside_broker_contact",
    "outside_broker_fax",
    "outside_broker_main_phone",
    "outside_broker_email",
    "outside_broker_taxid",
    "outside_broker_w9",
    "outside_broker_w9_upload",
    "outside_broker_commission",
];
export const LESSEE_OUTSIDE_BROKE_NON_REQUIRED_FIELDS = ["outside_broker_fax","outside_broker_taxid"];

export const LESSEE_REFERRAL_FIRM_FIELDS = [
    "lessee_referral_firm_company_name",
    "lessee_referral_firm_name",
    "lessee_referral_firm_lastname",
    "lessee_referral_firm_address1",
    "lessee_referral_firm_city",
    "lessee_referral_firm_state",
    "lessee_referral_firm_zip",
    "lessee_referral_firm_contact",
    "lessee_referral_firm_fax",
    "lessee_referral_firm_main_phone",
    "lessee_referral_firm_email",
    "lessee_referral_firm_taxid",
    "lessee_referral_firm_w9",
    "lessee_referral_firm_w9_upload",
    "lessee_referral_firm_fee_amount_choice",
    "lessee_referral_firm_fee_amount_percentage",
    "lessee_referral_firm_fee_amount",
];
export const LESSEE_REFERRAL_FIRM_NON_REQUIRED_FIELDS = ["lessee_referral_firm_fax", "lessee_referral_firm_fee_amount_choice",
    "lessee_referral_firm_taxid"
];

const Step4 = (props) => {
    const { setQueryFor, getContacts, setOpenedContactModal, renderElement, data, setData } = props;
    console.log('Step4', data);

    useEffect(() => {
        setReferralFirmFeeAmountChoice(setData, data)("lessee");
    }, []);

    return (
        <div>
            {/* Lessee */}
            <LessEntry 
                data={data}
                setData={setData}
                rsType="No Listing"
                fieldType="lessees"
                fieldName="Lessee"
            />
            {data.lessees.length === 0 && <div className="red-text" style={{margin:20}}>Lessee Required</div>}
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <hr />
                    <LessContacts 
                        data={data}
                        setData={setData}
                        fieldType="lessee_contacts"
                        fieldName="Lessee Contact"
                    />
                    <hr />
                </GridItem>

                <Brokers 
                    data={data}
                    setData={setData}
                    outside_brokers_field_name="outside_broker" 
                    outside_brokers_field_label="LESSEE"
                />

                <ReferralsLessee 
                    data={data}
                    setData={setData}
                    setQueryFor={setQueryFor}
                    getContacts={getContacts}
                    setOpenedContactModal={setOpenedContactModal}
                    renderElement={renderElement}
                />
            </GridContainer>
        </div>
    );
};

export default Step4;
