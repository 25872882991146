import * as actionTypes from './actionTypes';

// GET_COMMISSION_SPLITS 

export const getCommissionSplits = (data) => {
    return {
        type: actionTypes.GET_COMMISSION_SPLITS,
        data
    }
}

export const getCommissionSplitsStart = () => {
    return {
        type: actionTypes.GET_COMMISSION_SPLITS_START
    }
}

export const getCommissionSplitsSuccess = (response) => {
    return {
        type: actionTypes.GET_COMMISSION_SPLITS_SUCCESS,
        response
    }
}

export const getCommissionSplitsFail = (err) => {
    return {
        type: actionTypes.GET_COMMISSION_SPLITS_FAIL,
        err
    }
}

// CREATE_COMMISSION_SPLIT 

export const createCommissionSplit = (data) => {
    return {
        type: actionTypes.CREATE_COMMISSION_SPLIT,
        data
    }
}

export const createCommissionSplitStart = () => {
    return {
        type: actionTypes.CREATE_COMMISSION_SPLIT_START
    }
}

export const createCommissionSplitSuccess = (response) => {
    return {
        type: actionTypes.CREATE_COMMISSION_SPLIT_SUCCESS,
        response
    }
}

export const createCommissionSplitFail = (err) => {
    return {
        type: actionTypes.CREATE_COMMISSION_SPLIT_FAIL,
        err
    }
}

// DELETE_COMMISSION_SPLITS 

export const deleteCommissionSplit = (data) => {
    return {
        type: actionTypes.DELETE_COMMISSION_SPLIT,
        data
    }
}

export const deleteCommissionSplitStart = () => {
    return {
        type: actionTypes.DELETE_COMMISSION_SPLIT_START
    }
}

export const deleteCommissionSplitSuccess = (response) => {
    return {
        type: actionTypes.DELETE_COMMISSION_SPLIT_SUCCESS,
        response
    }
}

export const deleteCommissionSplitFail = (err) => {
    return {
        type: actionTypes.DELETE_COMMISSION_SPLIT_FAIL,
        err
    }
}

// UPDATE_COMMISSION_SPLIT 

export const updateCommissionSplit = (data) => {
    return {
        type: actionTypes.UPDATE_COMMISSION_SPLIT,
        data
    }
}

export const updateCommissionSplitStart = () => {
    return {
        type: actionTypes.UPDATE_COMMISSION_SPLIT_START
    }
}

export const updateCommissionSplitSuccess = (response) => {
    return {
        type: actionTypes.UPDATE_COMMISSION_SPLIT_SUCCESS,
        response
    }
}

export const updateCommissionSplitFail = (err) => {
    return {
        type: actionTypes.UPDATE_COMMISSION_SPLIT_FAIL,
        err
    }
}

// GET_COMMISSION_SPLITS_PROPERTY_EXISTENCE 

export const getCommissionSplitsPropertyExistence = (data) => {
    return {
        type: actionTypes.GET_COMMISSION_SPLITS_PROPERTY_EXISTENCE,
        data
    }
}

export const getCommissionSplitsPropertyExistenceStart = () => {
    return {
        type: actionTypes.GET_COMMISSION_SPLITS_PROPERTY_EXISTENCE_START
    }
}

export const getCommissionSplitsPropertyExistenceSuccess = (response) => {
    return {
        type: actionTypes.GET_COMMISSION_SPLITS_PROPERTY_EXISTENCE_SUCCESS,
        response
    }
}

export const getCommissionSplitsPropertyExistenceFail = (err) => {
    return {
        type: actionTypes.GET_COMMISSION_SPLITS_PROPERTY_EXISTENCE_FAIL,
        err
    }
}

export const setCommissionSplitsPropertyExistence = () => {
    return {
        type: actionTypes.SET_COMMISSION_SPLITS_PROPERTY_EXISTENCE
    }
}

// CREATE_COMMISSION_SPLITS_HISTORY 

export const createCommissionSplitsHistory = (data, notifications) => {
    return {
        type: actionTypes.CREATE_COMMISSION_SPLITS_HISTORY,
        data,
        notifications
    }
}

export const createCommissionSplitsHistoryStart = () => {
    return {
        type: actionTypes.CREATE_COMMISSION_SPLITS_HISTORY_START
    }
}

export const createCommissionSplitsHistorySuccess = (response) => {
    return {
        type: actionTypes.CREATE_COMMISSION_SPLITS_HISTORY_SUCCESS,
        response
    }
}

export const createCommissionSplitsHistoryFail = (err) => {
    return {
        type: actionTypes.CREATE_COMMISSION_SPLITS_HISTORY_FAIL,
        err
    }
}

// GET_COMMISSION_SPLITS_HISTORY 

export const getCommissionSplitsHistory = (data) => {
    return {
        type: actionTypes.GET_COMMISSION_SPLITS_HISTORY,
        data
    }
}

export const getCommissionSplitsHistoryStart = () => {
    return {
        type: actionTypes.GET_COMMISSION_SPLITS_HISTORY_START
    }
}

export const getCommissionSplitsHistorySuccess = (response) => {
    return {
        type: actionTypes.GET_COMMISSION_SPLITS_HISTORY_SUCCESS,
        response
    }
}

export const getCommissionSplitsHistoryFail = (err) => {
    return {
        type: actionTypes.GET_COMMISSION_SPLITS_HISTORY_FAIL,
        err
    }
}

// GET_COMMISSION_SPLITS_REVIEWERS 

export const getCommissionSplitsReviewers = (data) => {
    return {
        type: actionTypes.GET_COMMISSION_SPLITS_REVIEWERS,
        data
    }
}

export const getCommissionSplitsReviewersStart = () => {
    return {
        type: actionTypes.GET_COMMISSION_SPLITS_REVIEWERS_START
    }
}

export const getCommissionSplitsReviewersSuccess = (response) => {
    return {
        type: actionTypes.GET_COMMISSION_SPLITS_REVIEWERS_SUCCESS,
        response
    }
}

export const getCommissionSplitsReviewersFail = (err) => {
    return {
        type: actionTypes.GET_COMMISSION_SPLITS_REVIEWERS_FAIL,
        err
    }
}

// CREATE_COMMISSION_SPLITS_PDF 

export const createCommissionSplitsPdf = (data) => {
    return {
        type: actionTypes.CREATE_COMMISSION_SPLITS_PDF,
        data
    }
}

export const createCommissionSplitsPdfStart = () => {
    return {
        type: actionTypes.CREATE_COMMISSION_SPLITS_PDF_START
    }
}

export const createCommissionSplitsPdfSuccess = (response) => {
    return {
        type: actionTypes.CREATE_COMMISSION_SPLITS_PDF_SUCCESS,
        response
    }
}

export const createCommissionSplitsPdfFail = (err) => {
    return {
        type: actionTypes.CREATE_COMMISSION_SPLITS_PDF_FAIL,
        err
    }
}

// GET_COMMISSION_SPLITS_APPROVERS 

export const getCommissionSplitsApprovers = (data) => {
    return {
        type: actionTypes.GET_COMMISSION_SPLITS_APPROVERS,
        data
    }
}

export const getCommissionSplitsApproversStart = () => {
    return {
        type: actionTypes.GET_COMMISSION_SPLITS_APPROVERS_START
    }
}

export const getCommissionSplitsApproversSuccess = (response) => {
    return {
        type: actionTypes.GET_COMMISSION_SPLITS_APPROVERS_SUCCESS,
        response
    }
}

export const getCommissionSplitsApproversFail = (err) => {
    return {
        type: actionTypes.GET_COMMISSION_SPLITS_APPROVERS_FAIL,
        err
    }
}

// GET_CS_SIGNING_URL 

export const getCsSigningUrl = (data) => {
    return {
        type: actionTypes.GET_CS_SIGNING_URL,
        data
    }
}

export const getCsSigningUrlStart = () => {
    return {
        type: actionTypes.GET_CS_SIGNING_URL_START
    }
}

export const getCsSigningUrlSuccess = (response) => {
    return {
        type: actionTypes.GET_CS_SIGNING_URL_SUCCESS,
        response
    }
}

export const getCsSigningUrlFail = (err) => {
    return {
        type: actionTypes.GET_CS_SIGNING_URL_FAIL,
        err
    }
}

// GET_COMMISSION_SPLIT 

export const getCommissionSplit = (data) => {
    return {
        type: actionTypes.GET_COMMISSION_SPLIT,
        data
    }
}

export const getCommissionSplitStart = () => {
    return {
        type: actionTypes.GET_COMMISSION_SPLIT_START
    }
}

export const getCommissionSplitSuccess = (response) => {
    return {
        type: actionTypes.GET_COMMISSION_SPLIT_SUCCESS,
        response
    }
}

export const getCommissionSplitFail = (err) => {
    return {
        type: actionTypes.GET_COMMISSION_SPLIT_FAIL,
        err
    }
}

// CLEAR CS 

export const clearCsHistory = () => {
    return {
        type: actionTypes.CLEAR_CS_HISTORY
    }
}