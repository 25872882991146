import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import NotificationAsk from '../../components/Notification/NotificationAsk';
import NotificationFixedTop from '../../components/Notification/NotificationFixedTop';
import moment from 'moment';
import { IconButton, Dialog, Tooltip } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Loader from '../../components/Loader/Loader';
import { createAddressObject } from '../CRM/Properties/PropertiesLogic';
import { statusDescriptions } from './CommissionSplitsLogic';
import { getUser } from '../../shared/authValidation';

const CommissionSplitsList = (props) => {
    const [askBeforeDelete, setAskBeforeDelete] = useState(null);
    const [startDelete, setStartDelete] = useState(false);
    const [notificationFixedTop, setNotificationFixedTop] = useState('');
    const [showURL, setShowURL] = useState(false);
    const [delay, setDelay] = useState(false);
    const { data } = props;
    const user = getUser();
    const userIsSuperAdmin = user.name === 'CRS Dev' ? true : false;
    console.log('datda', data, user)
    const final_data = data ? data.map((l, key) => { // map users to table rows
        const json_data = l.json_data;
        const propertyObj = createAddressObject(json_data);
        console.log('propertyObj', propertyObj);
        const status = statusDescriptions[l.status] === 'Completed Docusign' ? 'Completed' : statusDescriptions[l.status];
        return ({
            id: l.id,
            project_name: `${l.project_name ? l.project_name : ''} ${propertyObj.street1 ? propertyObj.street1 : ''}` ,
            date: moment(l.date_created.replace('T',' ')).tz("America/Los_Angeles").format("MM/DD/YYYY h:mm a"),
            status,
            username: l.name,
            actions: <div>
                {props.tab !== 'final_signature' && <IconButton onClick={() => {
                    props.openEntry(l);
                }}>
                    {status.indexOf('Completed') !== -1 || props.tab === 'pending_approver_review' || props.tab === 'pending_signature' ? 
                            <i className="fas fa-eye font-22"></i> 
                        : 
                            <i className="fa fa-edit"></i>}
                </IconButton>}

                {(props.tab === 'my_completed' && userIsSuperAdmin) && <IconButton onClick={() => {
                    // edit 
                    props.updateCompleted(l);
                }}>
                    <i className="fa fa-edit"></i>
                </IconButton>}

                {props.tab === 'my_drafts' && <IconButton
                    onClick={() => {
                        setAskBeforeDelete(l);
                    }}
                >
                    <i className="fa fa-trash"></i>
                </IconButton>}

                {props.tab === 'final_signature' && <Tooltip title="Sign CS" placement="top">
                    <IconButton
                        onClick={() => {
                            props.getCsSigningUrl(l.id);
                            setShowURL(true);
                        }}
                    >
                        <i className="fas fa-edit font-22"></i>
                    </IconButton>
                </Tooltip>}
            </div>
        })
    }) : [];

    const columns = [
        {
            Header: "ID #",
            accessor: "id" 
        },
        {
            Header: "Project Name / Street",
            accessor: "project_name"
        },
        {
            Header: "Date",
            accessor: "date"
        },
        {
            Header: "Submitter",
            accessor: "username"
        },
        {
            Header: "Status",
            accessor: "status"
        },
        {
            Header: "Actions",
            accessor: "actions"
        }
    ]

    useEffect(() => {
        if (props.success_delete && startDelete) {
            setNotificationFixedTop('Entry deleted');
            props.reload();
            setStartDelete(false);
        }
        if (props.error_delete && startDelete) {
            setNotificationFixedTop('There was an error deleting the entry, please contact support if the issue persists.')
            setStartDelete(false);
        }
    }, [props.success_delete, props.error_delete]);

    return (
        <div className="table-adjust2">
            <ReactTable 
                filterable
                className='-striped -highlight' 
                showPaginationTop 
                showPaginationBottom 
                resizable={false} 
                defaultPageSize={10} 
                pageSizeOptions={[10, 20, 30]}
                sorted={[
                    {
                     id: 'id',
                     desc: true
                    }
                ]}
                data={final_data} 
                columns={columns}
            />

            {askBeforeDelete && <NotificationAsk 
                title="Are you sure?"
                message="Do you want to delete this Commission Splits entry?"
                open={askBeforeDelete ? true : false}
                close={() => {
                    setAskBeforeDelete(null);
                }}
                success={() => {
                    setStartDelete(true);
                    props.deleteCommissionSplit(askBeforeDelete.id);
                    setAskBeforeDelete(null);
                }}
            />}

            {notificationFixedTop && <NotificationFixedTop 
                message={notificationFixedTop}
                autoClose={4000}
                close={() => {
                    setNotificationFixedTop('');
                }}
            />}

            {
                <Dialog
                    fullWidth={true}
                    maxWidth={false}
                    open={showURL}
                    onClose={() => {
                        setTimeout(() => {
                            props.reload();
                            setShowURL(false);
                        }, 500);
                    }}
                >
                    <div
                        className="close-btn mt-20 mb-20"
                        onClick={() => {
                            setDelay(true);
                            setTimeout(() => {
                                window.location.href = "/commission_splits?final_approval=s";
                            }, 10000);
                        }}
                    >
                        <Close />
                    </div>
                    {!props.signing_url && !delay && <div style={{margin:'0 auto'}}>
                            <Loader />
                        </div>}
                    {delay && (
                        <div style={{margin:'0 auto'}}>
                            <Loader />
                            Checking status, please refresh as needed if records have not updated.
                        </div>
                    )}
                    {!delay && (
                        <iframe
                            src={props.signing_url}
                            style={{
                                width: "100%",
                                height: 900,
                                overflow: "scroll",
                            }}
                        ></iframe>
                    )}
                </Dialog>
            }
        </div>
    )
}

export default CommissionSplitsList;