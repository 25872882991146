import { fields } from './CommissionSplitsModel';
import { cloneJ, getCompanyDBAData, formatDollar } from '../../shared/utility';
import { createAddressObject } from '../CRM/Properties/PropertiesLogic';
import { get } from 'lodash';
import { getUser } from '../../shared/authValidation';

export const sectionNames = {
    1: 'Property Information',
    2: 'Associates',
    3: 'Referrals',
    4: 'Outside Brokers',
    5: 'Associate Review',
    6: 'Notes',
    7: 'Final Approver'
}

export const statusDescriptions = {
    1: 'Saved to Draft',
    2: 'Submitted to Final Approver',
    3: 'Submitted to Pending Associates',
    4: 'Approved by Associate',
    5: 'Rejected by Associate',
    6: 'Rejected by Approver Level 1',
    7: 'Rejected by Final Approver',
    8: 'Sent to Pending Approval by Associate',
    9: 'Final Approval',
    10: 'Submitted to Approver Level 1',
    11: 'Approved, Sent for Docusign Signature',
    12: 'Pending Final Signature',
    13: 'Completed Docusign',
    14: 'Rejected Docusign by Associate',
    15: 'Initialed by Associate'
}

const obj = {};
Object.keys(statusDescriptions).forEach(key => {
    obj[statusDescriptions[key]] = key;
})
export const statusDescriptionsDict = obj;

export const gatherPropertyContacts = (props) => {
    const { updateDataObj, dataObj } = props;
    console.log('gathering property contacts', props.property_contacts);
    updateDataObj('contacts', props.property_contacts.map(pc => {
        if (pc.contact_data?.connect_user_id) {
            return {
                contact_id: pc.contact_data?.id,
                first_name: pc.contact_data?.first_name,
                last_name: pc.contact_data?.last_name,
                email: pc.contact_data?.email,
                commission_percent: '',
                connect_user_id: pc.contact_data?.connect_user_id
            }
        }
        return null;
    }).filter(d => {
        if (d) return true;
    }));
}

export const choosePropertyObj = (obj) => {
    const { property, setDataObj, dataObj } = obj;
    const p = cloneJ(dataObj);
    p.property_id = property.id;
    p.project_name = property.name;
    p.street1 = property.street1;
    p.street2 = property.street2;
    p.city = property.city;
    p.state = property.state;
    p.zip = property.zip;
    p.illi_number = property.illi_number;

    const additionalFields = [
        'address_is_range','street_range1','street_range2',
        'address2_is_range','street2_range1','street2_range2',
    ];

    additionalFields.forEach(af => {
        p[af] = property[af];
    })
    setDataObj(p);
}

const checkForContactID = (contact_id, dataObj, contactType, chosen_referral_type, is_a_connect_user) => {
    let pass = true;
    if (contact_id) {
        if (dataObj) {
            if (contactType === 'contacts') {
                // illi users can be in twice as long as they are different types (Lessee, Lessor)
                let lessee = [];
                let lessor = [];
                if (dataObj.contacts) {
                    dataObj.contacts.forEach(dc => {
                        if (dc.referral_type === 'Lessee') {
                            lessee.push(dc.contact_id);
                        } else if (dc.referral_type === 'Lessor') {
                            lessor.push(dc.contact_id);
                        }
                    })
                }
                console.log('lessarr', lessee, lessor, contact_id, chosen_referral_type);
                if (chosen_referral_type === 'Lessee') {
                    if (lessee.indexOf(contact_id) !== -1) {
                        pass = false;
                    }
                } else if (chosen_referral_type === 'Lessor') {
                    if (lessor.indexOf(contact_id) !== -1) {
                        pass = false;
                    }
                }
                return pass;
            }

            if (contactType === 'referrals') {
                // Associate can only be a referral if they are not in the associates tab.
                // If they are not on the associates tab then they can be a referral either pre or post split.
                if (is_a_connect_user) {
                    // check if in the associates tab
                    if (dataObj.contacts) {
                        dataObj.contacts.forEach(dc => {
                            if (dc.contact_id === contact_id) {
                                pass = false;
                            }
                        })
                    }
                    if (chosen_referral_type === 'Lessee' || chosen_referral_type === 'Lessor') {
                        pass = false;
                    }
                }
                // No duplicates
                if (dataObj.referrals) {
                    dataObj.referrals.forEach(dr => {
                        if (dr.contact_id === contact_id) pass = false;
                    })
                }
                return pass;
            }

            
            if (dataObj.outside_brokers) {
                dataObj.outside_brokers.forEach(dr => {
                    if (dr.contact_id === contact_id) pass = false;
                })
            }
        }
    }
    return pass;
}

export const chooseContactCompanyObj = (obj) => {
    const { chosen, updateDataObj, dataObj, queryFor, chosenDBA, setNotification } = obj;
    console.log('chosen', chosen);
    let chosenType = null;
    if (chosen.first_name) chosenType = 'contact';
    if (chosen.company_data && chosen.signers) {
        chosenType = 'company';
    } 
    
    if (queryFor.type === 'contacts') {
        // When adding Associates, you can add the same associate twice once to lessor and once to lessee. An associate can technically represent both sides.
        if (chosenType === 'contact') {
            const contact_data = chosen;
            if (contact_data.company_dats) {
                const contact_id = contact_data.id;
                const check = checkForContactID(contact_id, dataObj, 'contacts', chosen.referral_type);
                if (!check) return setNotification('Contact has already been used in this Commission Splits form');

                // COMPANY CONTACT
                updateDataObj('contacts', dataObj.contacts.concat([
                    {
                        company_contact_id: contact_data.company_dats.company_data?.id,
                        company_id: contact_data.company_dats.company_id,
                        contact_id,
                        connect_user_id: contact_data.connect_user_id,
                        first_name: contact_data.first_name,
                        last_name: contact_data.last_name,
                        email: contact_data.company_dats.email,
                        commission_percent: '',
                        dba_id: chosenDBA ? chosenDBA.id : '', 
                        dba_name: chosenDBA ? chosenDBA.name : '',
                        dba_active: chosenDBA ? chosenDBA.id ? 1 : '' : '',
                        approved: false,
                        referral_type: chosen.referral_type
                        // TODO: Active DBA check on Load ?
                    }
                ]))
            } else {
                // CONTACT
                const contact_id = contact_data.id;
                const check = checkForContactID(contact_id, dataObj, 'contacts', chosen.referral_type);
                if (!check) return setNotification('Contact has already been used in this Commission Splits form');

                updateDataObj('contacts', dataObj.contacts.concat([
                    {
                        contact_id: contact_data.id,
                        connect_user_id: contact_data.connect_user_id,
                        first_name: contact_data.first_name,
                        last_name: contact_data.last_name,
                        email: contact_data.email,
                        commission_percent: '',
                        dba_id: chosenDBA ? chosenDBA.id : '', 
                        dba_name: chosenDBA ? chosenDBA.name : '',
                        dba_active: chosenDBA ? chosenDBA.id ? 1 : '' : '',
                        approved: false,
                        referral_type: chosen.referral_type
                        // TODO: Active DBA check on Load ?
                    }
                ]))
            }
            
        }
    } else if (queryFor.type === 'referrals') {
        //  Referrals can only be 
        //  1. a contact that is a Real Estate Broker, 
        //  2. a Company that is a Real Estate Brokerage, 
        //  3. an illi Employee or an illi Associate.
        //  ---
        //  If the referral is a brokerage firm, then they need to prompt to pick the agent. (essentially this is pulling up a company that is contact type = brokerage firm, and then picking from the Contacts tied to that company with Contact Type = Real Estate Agent
        if (chosenType === 'contact') {
            const contact_data = chosen;
            const contact_id = contact_data.id;
            const is_a_connect_user = contact_data.connect_user_id ? true : false;
            const check = checkForContactID(contact_id, dataObj, 'referrals', chosen.referral_type, is_a_connect_user);
            if (!check) return setNotification('This contact cannot been used in referrals.');

            if (contact_data.company_dats) {
                // COMPANY CONTACT
                updateDataObj('referrals', dataObj.referrals.concat([
                    {
                        company_contact_id: contact_data.company_dats.company_data?.id,
                        company_id: contact_data.company_dats.company_id,
                        contact_id: contact_data.id,
                        first_name: contact_data.first_name,
                        last_name: contact_data.last_name,
                        email: contact_data.company_dats.email,
                        commission_percent: '',
                        commission_flat_amt: '',
                        dba_id: chosenDBA ? chosenDBA.id : '', 
                        dba_name: chosenDBA ? chosenDBA.name : '',
                        dba_active: chosenDBA ? chosenDBA.id ? 1 : '' : '',
                        referral_type: chosen.referral_type
                    }
                ]))
            } else {
                // CONTACT
                updateDataObj('referrals', dataObj.referrals.concat([
                    {
                        contact_id: contact_data.id,
                        first_name: contact_data.first_name,
                        last_name: contact_data.last_name,
                        email: contact_data.email,
                        commission_percent: '',
                        commission_flat_amt: '',
                        dba_id: chosenDBA ? chosenDBA.id : '', 
                        dba_name: chosenDBA ? chosenDBA.name : '',
                        dba_active: chosenDBA ? chosenDBA.id ? 1 : '' : '',
                        referral_type: chosen.referral_type
                    }
                ]))
            }
        } else if (chosenType === 'company') {
            const contact_data = chosen.signers[0] ? chosen.signers[0] : null;
            const company_data = chosen.company_data;
            const dba_id = chosen.dba_id;
            const dba_data = chosen.dba_data ? 
                chosen.dba_data.length > 0 ? 
                    getCompanyDBAData(dba_id, chosen.dba_data) 
                : 
                    null 
            : 
                null;
            if (contact_data) {
                const contact_id = contact_data.id;
                const check = checkForContactID(contact_id, dataObj);
                if (!check) return setNotification('Contact has already been used in this Commission Splits form');

                const company_contact_email = get(contact_data, 'company_dats.email');
                updateDataObj('referrals', dataObj.referrals.concat([
                    {
                        company_contact_id: contact_data.company_dats?.id,
                        company_id: company_data.id,
                        contact_id: contact_data.id,
                        name: company_data.name,
                        first_name: contact_data.first_name,
                        last_name: contact_data.last_name,
                        email: company_contact_email,
                        commission_percent: '',
                        commission_flat_amt: '',
                        dba_id: dba_id ? dba_id : '', 
                        dba_name: dba_data ? dba_data.name : '',
                        dba_active: dba_id ? 1 : '',
                        referral_type: chosen.referral_type
                    }
                ]))
            }
        }
    } else if (queryFor.type === 'outside_brokers') {
        //  Outside Brokers can only be 
        //  1. a contact that is a Real Estate Broker, 
        //  2. a Company that is a Real Estate Brokerage, 
        //  3. an illi Employee or an illi Associate.
        //  ---
        //  If the referral is a brokerage firm, then they need to prompt to pick the agent. (essentially this is pulling up a company that is contact type = brokerage firm, and then picking from the Contacts tied to that company with Contact Type = Real Estate Agent
        if (chosenType === 'contact') {
            const contact_data = chosen;
            const contact_id = contact_data.id;
            if (contact_data.connect_user_id) {
                return setNotification('illi Associates cannot be used as Outside Brokers');
            }
            // const check = checkForContactID(contact_id, dataObj);
            // if (!check) return setNotification('Contact has already been used in this Commission Splits form');

            if (contact_data.company_dats) {
                // COMPANY CONTACT
                updateDataObj('outside_brokers', dataObj.outside_brokers.concat([
                    {
                        company_contact_id: contact_data.company_dats.company_data?.id,
                        company_id: contact_data.company_dats.company_id,
                        contact_id: contact_data.id,
                        first_name: contact_data.first_name,
                        last_name: contact_data.last_name,
                        email: contact_data.company_dats.email,
                        commission_percent: '',
                        commission_flat_amt: '',
                        dba_id: chosenDBA ? chosenDBA.id : '', 
                        dba_name: chosenDBA ? chosenDBA.name : '',
                        dba_active: chosenDBA ? chosenDBA.id ? 1 : '' : '',
                        referral_type: chosen.referral_type
                    }
                ]))
            } else {
                // CONTACT
                updateDataObj('outside_brokers', dataObj.outside_brokers.concat([
                    {
                        contact_id: contact_data.id,
                        first_name: contact_data.first_name,
                        last_name: contact_data.last_name,
                        email: contact_data.email,
                        commission_percent: '',
                        commission_flat_amt: '',
                        dba_id: chosenDBA ? chosenDBA.id : '', 
                        dba_name: chosenDBA ? chosenDBA.name : '',
                        dba_active: chosenDBA ? chosenDBA.id ? 1 : '' : '',
                        referral_type: chosen.referral_type
                    }
                ]))
            }
        } else if (chosenType === 'company') {
            const contact_data = chosen.signers[0] ? chosen.signers[0] : null;
            const company_data = chosen.company_data;
            const dba_id = chosen.dba_id;
            const dba_data = chosen.dba_data ? 
                chosen.dba_data.length > 0 ? 
                    getCompanyDBAData(dba_id, chosen.dba_data) 
                : 
                    null 
            : 
                null;
            if (contact_data) {
                if (contact_data.connect_user_id) {
                    return setNotification('illi Associates cannot be used as Outside Brokers');
                }
                const contact_id = contact_data.id;
                // const check = checkForContactID(contact_id, dataObj);
                // if (!check) return setNotification('Contact has already been used in this Commission Splits form');

                const company_contact_email = get(contact_data, 'company_dats.email');
                updateDataObj('outside_brokers', dataObj.outside_brokers.concat([
                    {
                        company_contact_id: contact_data.company_dats?.id,
                        company_id: company_data.id,
                        contact_id: contact_data.id,
                        name: company_data.name,
                        first_name: contact_data.first_name,
                        last_name: contact_data.last_name,
                        email: company_contact_email,
                        commission_percent: '',
                        commission_flat_amt: '',
                        dba_id: dba_id ? dba_id : '', 
                        dba_name: dba_data ? dba_data.name : '',
                        dba_active: dba_id ? 1 : '',
                        referral_type: chosen.referral_type
                    }
                ]))
            }
        }
    }
}

const findLabel = (field) => {
    let fieldLabel = '';
    for (let i = 0; i < Object.keys(fields).length; i++) {
        const f = Object.keys(fields)[i];
        if (f === field) {
            if (fields[f].label) {
                fieldLabel = fields[f].label;
                break;
            }
        }
    }
    return fieldLabel;
}

export const getSum = (arr, sumType) => {
    let sum = 0;
    if (arr) {
        arr.forEach(a => {
            if (!sumType && a.commission_percent) {
                sum += parseFloat(a.commission_percent);
            }
            if (sumType) {
                if (a[sumType]) sum += parseFloat(a[sumType]);
            }
        })
    }
    return sum;
}

export const generateSections = (data, parentTab) => {
    const overrideAssociateApproval = getUser('CommissionSplits override_associates');
    // checking if data is complete
	let sections = [];
	Object.values(sectionNames).forEach((v, index) => {
		if (data) {
			let complete = true;
            let errors = [];
            if (v === 'Property Information') {
                // Required to have a value
                [
                    'date','project_name','illi_number',
                    'street1','city',
                    // 'state',
                    'zip',
                ].forEach(field => {
                    if (!data[field]) {
                        complete = false;
                        const fieldLabel = findLabel(field);
                        errors.push(`${fieldLabel} is required`);
                    }
                });
            }
            if (v === 'Associates') {
                if (data.contacts.length === 0) {
                    complete = false;
                    errors.push('Contacts/Commission Splits are required');
                } else {
                    const sum = getSum(data.contacts);
                    if (sum !== 100) {
                        complete = false;
                        errors.push('Commission splits must total 100%');
                    }
                    const emails = data.contacts.map(c => c.email).filter(c => {
                        if (c) return true;
                    });
                    console.log('emails', emails, data.contacts)
                    if (emails.length !== data.contacts.length) {
                        complete = false;
                        errors.push('All Contacts/Commissions should have an email');
                    }
                    let type_exists = true;
                    data.contacts.forEach(dc => {
                        if (!dc.referral_type) {
                            type_exists = false;
                        }
                    });
                    if (!type_exists) {
                        complete = false;
                        errors.push('Please select Lessee or Lessee representation side for all Associates');
                    }
                }
            }
            if (v === 'Referrals') {
                if (data.referrals.length === 0) {
                    // Not required
                } else {
                    const sum = getSum(data.referrals);
                    if (sum > 100) {
                        complete = false;
                        errors.push('Referrals percentages must not exceed 100%');
                    }
                    // each row has to have either % or flat fee, and not both
                    let rowIsOneTypeOnly = true;
                    let rowIsComplete = true;
                    for (let i = 0; i < data.referrals.length; i++) {
                        const ref = data.referrals[i];
                        if (ref.commission_flat_amt && ref.commission_percent) {
                            rowIsOneTypeOnly = false;
                        }
                        if (!ref.commission_flat_amt && !ref.commission_percent) {
                            rowIsComplete = false;
                        }
                    }
                    if (!rowIsOneTypeOnly) {
                        complete = false;
                        errors.push('Each referral entry must have either commission % or flat fee, but not both');
                    }
                    if (!rowIsComplete) {
                        complete = false;
                        errors.push('Each referral entry must have either commission % or flat fee');
                    }
                }
            }

            if (v === 'Outside Brokers') {
                if (data.outside_brokers.length === 0) {
                    // Not required
                } else {
                    const sum = getSum(data.outside_brokers);
                    if (sum > 100) {
                        complete = false;
                        errors.push('Outside Broker percentages must not exceed 100%');
                    }
                    // each row has to have either % or flat fee, and not both
                    let rowIsOneTypeOnly = true;
                    let rowIsComplete = true;
                    for (let i = 0; i < data.outside_brokers.length; i++) {
                        const ref = data.outside_brokers[i];
                        if (ref.commission_flat_amt && ref.commission_percent) {
                            rowIsOneTypeOnly = false;
                        }
                        if (!ref.commission_flat_amt && !ref.commission_percent) {
                            rowIsComplete = false;
                        }
                    }
                    if (!rowIsOneTypeOnly) {
                        complete = false;
                        errors.push('Each outside broker entry must have either commission % or flat fee, but not both');
                    }
                    if (!rowIsComplete) {
                        complete = false;
                        errors.push('Each outside broker entry must have either commission % or flat fee');
                    }
                }
            }

            if (v === 'Associate Review') {
                console.log('parentTab', parentTab)
                // show as checked on completed tabs
                if (parentTab.indexOf('completed') === -1) {
                    if (!overrideAssociateApproval) {
                        if (data.contacts) {
                            data.contacts.forEach(dc => {
                                if (!dc.approved) {
                                    complete = false;
                                    errors.push('All associates have to approve');
                                }
                            })
                        }
                    } else {
                        if (!data.pdf_attachment) {
                            //complete = false;
                            //errors.push('PDF attachment is required');
                        }
                    }
                }
            }

            if (v === 'Notes') {
                if (!data.notes_now) {
                    complete = false;
                }
            }

            if (v === 'Final Approver') {
                if (!data.final_approver_name || !data.final_approver_email || !data.final_approver_position) {
                    errors.push('Final Approver/Signer is required');
                    complete = false;
                }
            }

			sections.push({
				name: v,
				complete,
                errors
			});
		}
	});
    console.log('sections', sections);
	return sections;
};

export const generatePDF = (dataObj) => {
    const property = dataObj ? createAddressObject(dataObj) : {};
    return `
<html>
    <head>
        <style>
        body {
            font-size: 10px;
            font-family: 'Arial', sans-serif;
        }
        td {
            vertical-align: top;
        }
        .list-unstyled {
            list-style: none;
            }
        .grayed {
            -webkit-filter: grayscale(1);
        }
        .mr-20 {
            margin-right: 20px;
        }
        .mr-10 {
            margin-right: 10px;
        }
        .ml-10 {
            margin-left: 10px;
        }
        .col-5 {
            width: 5%;
        }
        .col-10 {
            width: 10%;
            }
            .col-15 {
                width: 15%;
            }
            .col-20 {
            width: 20%;
            }
            
            .col-30 {
            width: 30%;
            }
            
            .col-40 {
            width: 40%;
            }
            
            .col-50 {
            width: 50%;
            }
            
            .col-60 {
            width: 60%;
            }
            
            .col-70 {
            width: 70%;
            }
            
            .col-80 {
            width: 80%;
            }
            
            .col-90 {
            width: 90%;
            }
            
            .col-100 {
            width: 100%;
            }
            
            .b-t {
            border-top: 1px solid black;
            }
            
            .b-b {
            border-bottom: 1px solid black;
            }
            
            .b-r {
            border-right: 1px solid black;
            }
            
            .b-l {
            border-right: 1px solid black;
            }
            
            .the-table table {
            width: 100%;
            }
            
            .the-table table, .the-table th, .the-table td {
            border: 1px solid #878787;
            border-collapse: collapse;
            font-size: 10px;
            }
            
            .the-table td {
            padding: 5px;
            }
            
            .no-bt {
            border-top: 1px solid rgba(0,0,0,0) !important;
            }
            .no-bb {
            border-bottom: 1px solid rgba(0,0,0,0) !important;
            }
            .the-table .no-br {
            border-right: 1px solid rgba(0,0,0,0) !important;
            }
            .the-table table {
                table-layout: fixed;
            }
            .the-table .no-bl {
            border-left: 1px solid rgba(0,0,0,0) !important;
            }
            .bold {
                font-weight: bold;
            }
            .text-center {
                text-align: center;
            }
            .underlined {
            border-bottom: 1px solid #ccc;
            display: inline-block;
            }
            .bordered-thin {
            border: 1px solid #ccc;
            padding: 10px;
            margin: 10px;
            border-radius: 5px;
            }
            .pagebreak {
            page-break-after: always;
            }
            .small-table-text td,
            .small-table-text th {
                font-size: 9px;
            }
            .mt-10 {
                margin-top: 10px;
            }
            .white-text {
                color: white;
            }
        </style>
    </head>
<body>
    <div>
        <div style="padding:40px;max-width:900px;margin:0 auto">
            <div>
                <table style="width:100%">
                    <tbody>
                        <tr>
                            <td class="col-10">
                                <img
                                    src="https://illicre.com/wp-content/themes/zoacres/assets/illi-logo-simple.png"
                                    style="width:60px"
                                />
                            </td>
                            <td class="col-90">
                                <div class="text-center">
                                    <h3>COMMISSION SPLITS</h3>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <div style="text-align:center">
                    <span>illi # </span>
                    <span class="underlined" style="margin-right:40px">${dataObj.illi_number}</span>

                    <span>Date: </span>
                    <span class="underlined" style="margin-right:40px">${dataObj.date}</span>

                    <span>Submitted by </span>
                    <span class="underlined">${dataObj.submitted_by_name}</span>
                </div>
            </div>
            <h3>PROJECT NAME AND LOCATION</h3>
            <div class="the-table mt-20">
                <table>
                    <tbody>
                        <tr>
                            <td
                                class="col-100"
                            >
                                <strong>Project Name:</strong> ${dataObj.project_name}
                            </td>
                        </tr>
                        <tr>
                            <td
                                class="col-100"
                            >
                                <strong>Street Address:</strong> ${property.street1} ${property.street2}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                class="col-33 no-bt"
                            >
                                <strong>City:</strong> ${dataObj.city}
                            </td>
                            <td
                                class="col-33 no-bt"
                            >
                                <strong>State:</strong> ${dataObj.state}
                            </td>
                            <td
                                class="col-33 no-bt"
                            >
                                <strong>Zip:</strong> ${dataObj.zip}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                class="col-100 no-bt"
                            >
                                <strong>Cross Streets:</strong> ${dataObj.cross_streets}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <h3>CONTACTS/COMMISSIONS</h3>
            <div class="the-table mt-20">
                <table>
                    <tbody>
                        <tr>
                            <td
                                class="col-10"
                            >
                            </td>
                            <td
                                class="col-10"
                            >
                            </td>
                            <td
                                class="col-40"
                            >
                            </td>
                            <td
                                class="col-20"
                            >
                                <strong>Associate Signature</strong>
                            </td>
                            <td
                                class="col-20"
                            >
                                <strong>Commission %</strong>
                            </td>
                        </tr>
                        ${dataObj.contacts.map((c,i) => {
                            return `<tr>
                                <td
                                    class="col-10"
                                >
                                    ${c.referral_type ? c.referral_type : ''}
                                </td>
                                <td
                                    class="col-10"
                                >
                                    <strong>
                                    ${i === 0 ? `<span>Primary Associate</span>` : `<span>Associate ${i+1}</span>`}
                                    </strong>
                                </td>
                                <td
                                    class="col-40"
                                >
                                    ${c.first_name} ${c.last_name} (${c.email})
                                </td>
                                <td
                                    class="col-20"
                                >
                                    <span class="white-text">**_associate_${i}_**</span>
                                </td>
                                <td
                                    class="col-20"
                                >
                                    ${c.commission_percent} %
                                </td>
                            </tr>`
                        }).join('')}
                        <tr>
                            <td
                                class="col-10"
                            >
                            </td>
                            <td
                                class="col-10"
                            >
                            </td>
                            <td
                                class="col-40"
                            >
                            </td>
                            <td
                                class="col-20"
                            >
                                <strong style="float:right">TOTAL</strong>
                            </td>
                            <td
                                class="col-20"
                            >
                                ${getSum(dataObj.contacts)} %
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="the-table mt-20">
                <h3>REFERRALS</h3>
                <table>
                    <tbody>
                        <tr>
                            <td
                                class="col-10"
                            >
                            </td>
                            <td
                                class="col-10"
                            >
                                Brokerage Firm
                            </td>
                            <td
                                class="col-40"
                            >
                                Associate Name
                            </td>
                            <td
                                class="col-20"
                            >
                                <strong>Flat Fee</strong>
                            </td>
                            <td
                                class="col-20"
                            >
                                <strong>Commission %</strong>
                            </td>
                        </tr>
                        ${dataObj.referrals.map((r,i) => {
                            return `<tr>
                                <td
                                    class="col-10"
                                >
                                    ${r.referral_type}
                                </td>
                                <td
                                    class="col-10"
                                >
                                    ${r.name ? r.name : ''}
                                </td>
                                <td
                                    class="col-40"
                                >
                                   ${r.first_name} ${r.last_name} (${r.email})
                                </td>
                                <td
                                    class="col-20"
                                >
                                    ${r.commission_flat_amt ? `${formatDollar(r.commission_flat_amt)}` : ''}
                                </td>
                                <td
                                    class="col-20"
                                >
                                    ${r.commission_percent} %
                                </td>
                            </tr>`
                        }).join('')}
                        <tr>
                            <td
                                class="col-10"
                            >
                            </td>
                            <td
                                class="col-10"
                            >
                            </td>
                            <td
                                class="col-40"
                            >
                                <strong style="float:right">TOTAL</strong>
                            </td>
                            <td
                                class="col-20"
                            >
                                ${getSum(dataObj.referrals, 'commission_flat_amt') ? 
                                    formatDollar(getSum(dataObj.referrals, 'commission_flat_amt')) 
                                : 
                                    ''}
                            </td>
                            <td
                                class="col-20"
                            >
                                ${getSum(dataObj.referrals) ? `${getSum(dataObj.referrals)} %` : ''}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h3>OUTSIDE BROKERS</h3>
                <table>
                    <tbody>
                        <tr>
                            <td
                                class="col-10"
                            >
                            </td>
                            <td
                                class="col-10"
                            >
                                Brokerage Firm
                            </td>
                            <td
                                class="col-40"
                            >
                                Associate Name
                            </td>
                            <td
                                class="col-20"
                            >
                                <strong>Flat Fee</strong>
                            </td>
                            <td
                                class="col-20"
                            >
                                <strong>Commission %</strong>
                            </td>
                        </tr>
                        ${dataObj.outside_brokers.map((r,i) => {
                            return `<tr>
                                <td
                                    class="col-10"
                                >
                                    ${r.referral_type}
                                </td>
                                <td
                                    class="col-10"
                                >
                                    ${r.name ? r.name : ''}
                                </td>
                                <td
                                    class="col-50"
                                >
                                   ${r.first_name} ${r.last_name} (${r.email})
                                </td>
                                <td
                                    class="col-20"
                                >
                                    ${r.commission_flat_amt ? `${formatDollar(r.commission_flat_amt)}` : ''}
                                </td>
                                <td
                                    class="col-20"
                                >
                                    ${r.commission_percent} %
                                </td>
                            </tr>`
                        }).join('')}
                        <tr>
                            <td
                                class="col-10"
                            >
                            </td>
                            <td
                                class="col-10"
                            >
                            </td>
                            <td
                                class="col-40"
                            >
                                <strong style="float:right">TOTAL</strong>
                            </td>
                            <td
                                class="col-20"
                            >
                                ${getSum(dataObj.outside_brokers, 'commission_flat_amt') ? 
                                    formatDollar(getSum(dataObj.outside_brokers, 'commission_flat_amt')) 
                                : 
                                    ''}
                            </td>
                            <td
                                class="col-20"
                            >
                                ${getSum(dataObj.outside_brokers) ? `${getSum(dataObj.outside_brokers)} %` : ''}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div style="margin-top:40px">
                    <strong>Notes:</strong><br>
                    ${dataObj.notes_now}
                </div>

                <div style="margin-top:40px">
                    <strong>Date:</strong> <span class="underlined" style="margin-right:40px"><span class="white-text">**_approver_signature_date_**</span></span>
                    <strong>${dataObj.final_approver_name} Signature</strong> <span class="underlined" style="margin-right:100px"><span class="white-text">**_approver_signature_**</span></span>
                </div>
            </div>
        </div>
    </div>
  </body>
</html>
    `;
}

export const checkIfCurrentUserIsAnAgentApprover = (userObj, history) => {
    let isAnApprover = false;
    const user_id = userObj?.id;
    if (history) {
        let last_action = 0;
        for (let i = 0; i < history.length; i++) {
            const h = history[i];
            if (h.user_id === user_id && h.created_by !== h.user_id) {
                last_action = h.action;
            }
        }
        if (last_action === 8) {
            isAnApprover = true;
        }
    }
    return isAnApprover;
}

export const checkIfCurrentUserIsSubmitter = (userObj, history) => {
    console.log('checking if current user is submiter', userObj, history)
    const user_id = userObj?.id;
    if (history) {
        if (history[0]) {
            const created_id = history[0].created_by;
            if (created_id === user_id) {
                return true;
            }
        } else {
            return true;
        }
    }
    return false;
}

export const getHistory = (user_id, history) => {
    let historyData = null;
    if (user_id && history) {
        if (history.length > 0) {
            for (let i = 0; i < history.length; i++) {
                const h = history[i];
                if (h.user_id === user_id) {
                    if (h.action === 4 || h.action === 8 || h.action === 5) {
                        // Approved by Agent or just Submitted by submitter
                        historyData = h;
                    }
                }
            }
        }
    }
    return historyData;
}

export const checkIfAllContactsHaveUserIds = (all_potential_reviewers, contacts) => {
    let isComplete = true;
    if (!all_potential_reviewers || !contacts) return false;
    contacts.forEach(c => {
        let userExistent = false;
        for (let i = 0; i < all_potential_reviewers.length; i++) {
            const apr = all_potential_reviewers[i];
            if (apr.mail === c.email) {
                userExistent = true;
                break;
            }
        }
        if (!userExistent) {
            isComplete = false;
        }
    })
    return isComplete;
}

export const createDocusignObj = (formData, override) => {
    const newObj = {
        submitDocusign: true, 
        json_data: JSON.stringify(formData),
        status: statusDescriptionsDict['Approved, Sent for Docusign Signature'],
        htmlTemplate: generatePDF(formData),
        tabs: {
            [formData.final_approver_email]: {
                signHereTabs: [
                    {
                        anchorString:
                            "**_approver_signature_**",
                    },
                ],
                dateSignedTabs: [
                    {
                        anchorString:
                            "**_approver_signature_date_**",
                    },
                ],
            },
        },
    };
    if (formData.contacts) {
        if (formData.contacts.length > 0) {
            formData.contacts.forEach(
                (oo, i) => {
                    newObj.tabs[oo.email] = {
                        // signHereTabs: [
                        //     {
                        //         anchorString: `**_associate_${i}_signature_**`,
                        //     },
                        // ],
                        // dateSignedTabs: [
                        //     {
                        //         anchorString: `**_associate_${i}_signature_date_**`,
                        //     },
                        // ],
                        initialHereTabs: [
                            {
                                anchorString: `**_associate_${i}_**`
                            },
                        ],
                    };
                }
            );
        }
    }
    return newObj;
}

export const checkHasBeenCompletedBefore = (history) => {
    let hasBeenCompletedBefore = false;
    if (history) {
        for (let i = 0; i < history.length; i++) {
            const h = history[i];
            if (h.action === 13) {
                hasBeenCompletedBefore = true;
                break;
            }
        }
    }
    return hasBeenCompletedBefore;
}