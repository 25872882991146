import React, { useEffect, useState, useRef } from "react";
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import Button from "components/CustomButtons/Button.jsx";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Tooltip from "@material-ui/core/Tooltip";
import Group from "@material-ui/icons/Group";
import Close from "@material-ui/icons/Close";
import Delete from "@material-ui/icons/Delete";
import { initialPropertyData } from "./PropertiesObj";
import PropertiesFormMain from "./PropertiesFormMain";
import PropertiesFormAgent from "./PropertiesFormAgent";
import PropertiesFormOwner from "./PropertiesFormOwner";
import PropertiesFormOther from "./PropertiesFormOther";
import PropertiesFormReview from "./PropertiesFormReview";
import PropertiesSharing from "./PropertiesSharing";
import PropertiesFormStages from "./PropertiesFormStages";
import PropertiesEditContact from "./PropertiesEditContact";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Lease from "./Lease/Lease";
import Listing from "./Listing/Listing";
import Meeting from "./Meeting/Meeting";
import Offer from "./Offer/Offer";
import Showing from "./Showing/Showing";
import FilesForm from './FilesForm';
import PhotosForm from './PhotosForm';
import { useSaveProperties, getPropertyPermissions, createAddressObject } from './PropertiesLogic';
import { getUserID } from 'shared/authValidation';
import { getUser } from "../../../shared/authValidation";
import { Tabs, Tab } from "@material-ui/core";
import { clone, formatDate2, handleError } from "../../../shared/utility";
import { PropertiesFormSpaces } from "./PropertiesFormSpaces";
import CustomDialogCompanyView from "../../../components/CustomDialog/CustomDialogCompanyView";
import PropertiesFormPhotos from "./PropertiesFormPhotos";
import axios from "store/axios/axios-crm";

const PropertiesForm = (props) => {
	console.log('initialPropertyData', initialPropertyData);
	const [dataObj, setDataObj] = useState(initialPropertyData);
	const [listingsForAdd, setListingsForAddNow] = useState([]);
	const [leasesForAdd, setLeasesForAddNow] = useState([]);
	const [meetingsForAdd, setMeetingsForAddNow] = useState([]);
	const [offersForAdd, setOffersForAddNow] = useState([]);
	const [showingsForAdd, setShowingsForAddNow] = useState([]);
	const [sharesForAdd, setSharesForAddNow] = useState([]);
	const [contactsForAdd, setContactsForAddNow] = useState([]);
	const [companyForAdd, setCompanyForAddNow] = useState([]);
	const [formDataObj, setFormDataObj] = useState(null);
	const [openedEditContact, setOpenedEditContact] = useState(false);
	const [openedEditCompany, setOpenedEditCompany] = useState(false);
	const [contactID, setContactID] = useState(null);
	const [companyID, setCompanyID] = useState(null);
	const [madeChanges, setMadeChanges] = useState(false);
	const [isAutoSave, setIsAutoSave] = useState(false);
	const [openedReview, setOpenedReview] = useState(false);
	const [hasShownAgentMessage, setHasShownAgentMessage] = useState(false);
	const [closeWithoutSaving, setCloseWithoutSaving] = useState(false);
	const [property_options, setPropertyOptions] = useState({
		use_type: [],
		unit_use_type: [],
		lease_type: [],
		agent_message: '',
		next_nl_number: ''
	})
	const [illiNumberExists, setIlliNumberExists] = useState(null);
	const [historyData, setHistoryData] = useState([]);

	console.log('historyData', historyData);
	
	const getCrmHistory = async (property_id) => {
		try {
			const query = '/crm_history?type=property&id='+property_id;
			console.log('query', '/crm_history?type=property&id='+property_id);
			const crmHistories = await axios.get(query);
			setHistoryData(crmHistories.data);
		} catch(e) {
			handleError(e);
		}
	}

	const {tab, setTab} = props;

	useEffect(() => {
		if (props.property_options) {
			setPropertyOptions(clone(props.property_options));
		}
	}, [props.property_options]);

	const checkIlliNumberForProperty = async (checkData) => {
		setIlliNumberExists(null);
		console.log('checking for illi number 1', checkData);
		const { illi_number_check, property_id } = checkData;
		try {
			let str = '/properties?illi_number_check='+illi_number_check;
			if (property_id) {
				str += `&property_id=${property_id}`;
			}
			console.log('checking str', str);
			const response = await axios.get(str);
			const arr = response.data.filter(d => {
				return d.illi_number ? true : false;
			});
			console.log('received check illi number data', response.data);
			setIlliNumberExists(response.data)
		} catch (e) {
			handleError(e);
		}
	}

	useSaveProperties({
		dataObj,
		leasesForAdd,
		listingsForAdd,
		meetingsForAdd,
		offersForAdd,
		showingsForAdd,
		contactsForAdd,
		companyForAdd,
		theContactData: props.theContactData,
		madeChanges,
		action: props.action,
		updateProperty: props.updateProperty,
		isAutoSave,
		setIsAutoSave,
		success: props.success,
		setMadeChanges,
	});

	const setListingsForAdd = (a) => {
		setListingsForAddNow(a);
		setMadeChanges(true);
	};

	const setLeasesForAdd = (a) => {
		setLeasesForAddNow(a);
		setMadeChanges(true);
	};

	const setMeetingsForAdd = (a) => {
		setMeetingsForAddNow(a);
		setMadeChanges(true);
	};

	const setOffersForAdd = (a) => {
		setOffersForAddNow(a);
		setMadeChanges(true);
	};

	const setShowingsForAdd = (a) => {
		setShowingsForAddNow(a);
		setMadeChanges(true);
	};

	const setSharesForAdd = (a) => {
		setSharesForAddNow(a);
		setMadeChanges(true);
	};

	function usePrevious(value) {
		const ref = useRef();
		useEffect(() => {
			ref.current = value;
		});
		return ref.current;
	}

	const prevSuccess = usePrevious(props.success);
	const prevTab = usePrevious(tab);
	const prevUpdatedProperty = usePrevious(props.updated_property);

	const resetData = () => {
		setDataObj({ ...initialPropertyData });
		if (props.action === "Edit") {
			props.clearMessages();
			if (!props.getPropertyContacts) {
				const params = {
					rows: props.rows,
					offset: props.offset,
				};
				props.getProperties(params);
			} else {
				props.getPropertyContacts();
			}
		}
	};

	useEffect(() => {
		if (prevSuccess === null && props.success && !isAutoSave) {
			if (props.success.indexOf("Success") !== -1) {
				if (props.action === "Add") {
					// Load in Edit mode on successfully adding
					if (props.shouldLoadEdit && props.success_data) {
						if (props.success_data.id) return window.location.href = '/crm/properties?property_id='+props.success_data.id;
					}
					resetData();
					props.closeForm();
				}
				setMadeChanges(false);
			}
		}
		if (props.action === 'Edit' && closeWithoutSaving) {
			props.closeForm();
		}
	}, [props.success, isAutoSave]);

	useEffect(() => {
		if (prevUpdatedProperty === null && props.updated_property && dataObj) {
			if (dataObj.id) {
				if (props.updated_property.id === dataObj.id) {
					if (props.updated_property.illi_number) updateDataObj('illi_number', props.updated_property.illi_number)
				}
			}
		}
	}, [props.updated_property]);

	const updateDataObj = (field, value, shouldSave) => {
		setDataObj({ ...dataObj, [field]: value });
		setMadeChanges(true);
		if (shouldSave) setOpenedReview(true);
	};

	useEffect(() => {
		if (tab && (tab !== prevTab)) {
			if (tab === 'Submit') {
				setOpenedReview(true);
				setTab(prevTab);
			}
		}
	}, [tab]);

	const user_id = getUserID();
	const userPerms = getUser();
	const canEdit = getPropertyPermissions(
		props.propertyShares,
		dataObj,
		user_id,
		"edit",
		userPerms
	);
	const canDelete = getPropertyPermissions(
		props.propertyShares,
		dataObj,
		user_id,
		"delete",
		userPerms
	);

	useEffect(() => {
		props.clearContactNames();
		props.getPropertyUseType();
		if (props.propertyData) {
			if (props.propertyData.id) {
				console.log('propss', props.propertyData)
				getCrmHistory(props.propertyData.id);
			}
			const newDataObj = clone(props.propertyData);
			if (newDataObj.illi_represented === 0) {
				newDataObj.illi_represented = "0";
			} else if (newDataObj.illi_represented === 1) {
				newDataObj.illi_represented = "1";
			}

			if (newDataObj.la_city_verification === 1) {
				newDataObj.la_city_verification = "1";
			} else if (newDataObj.la_city_verification === 0) {
				newDataObj.la_city_verification = "0";
			}
			if (!newDataObj.goal || !Array.isArray(newDataObj.goal)) {
				newDataObj.goal = [];
			}

			if (!newDataObj.spaces || !Array.isArray(newDataObj.spaces)) {
				newDataObj.spaces = [];
			}
			props.getPropertyShares(newDataObj.id);
			setDataObj(newDataObj);
		}
	}, []);

	useEffect(() => {
		if (props.propertyData) {
			if (props.propertyData.id && canEdit && canDelete) {
				props.getPropertyDocuments(props.propertyData.id);
			}
		}
	}, [props.propertyData, canEdit, canDelete]);

	const { propertyData } = props;

    const getTabIndex = () => {
        const propertyInfoArr = ['Main', 'Owner', 'Associate','Other'];
        if (propertyInfoArr.indexOf(tab) !== -1) {
            return 0;
        }
        if (tab === 'Lease') {
            return 1;
        }
        if (tab === 'Listing') {
            return 2;
        }
        if (tab === 'Meeting') {
            return 3;
        }
        if (tab === 'Offer') {
            return 4;
        }
        if (tab === 'Showing') {
            return 5;
        }
        if (tab === 'Share') {
            return 6;
        }
        if (tab === 'Submit') {
            return 7;
        }
        if (tab === 'Files') {
            return 8;
        }
        if (tab === 'Photos') {
            return 9;
        }
    }

	useEffect(() => {
		if (!props.openedAsk && props.btnPressed === 'cancel') setCloseWithoutSaving(false);
	}, [props.openedAsk, props.btnPressed]);

	const cancel = () => {
		if (madeChanges && props.setOpenedAsk) {
			setCloseWithoutSaving(true);
			props.setOpenedAsk(true);
		} else {
			props.closeForm();
		}
	};

	const propertyOriginal = props.propertyData;
	let show_illi_owned_message = false;
	if (propertyOriginal) {
		if (propertyOriginal.user_id) {
			const the_user = getUser();
			const property_owner_id = propertyOriginal.user_id;
			if (the_user.type) {
				if (the_user.type === 'A') {
					if (property_owner_id) {
						if (property_owner_id === 1) {
							show_illi_owned_message = true;
						}
					}
				}
			}
		}
	}

	const illiNumberExistsProperty = illiNumberExists ? 
		illiNumberExists.length > 0 ? 
			createAddressObject(illiNumberExists[0]) 
		:
			null
	: 
		null;

	const created_date = historyData[0] ? ' - ' + historyData[0].name : '';
	const modified_date = historyData[historyData.length - 1] ? ' - ' + historyData[historyData.length - 1].name : '';

	console.log('PropertiesForm', props, dataObj, illiNumberExistsProperty);

	return (
		<div>
			{props.showCloseBtn && <div className="close-btn" onClick={() => { cancel() }}><Close /></div>}
			
			<GridContainer>
				<GridItem xs={12} sm={8} md={8}>
					<h3 style={{ display: "inline-block", marginRight: 40 }}>
						{(
							<div
								style={{ marginRight: 20, marginBottom: 10 }}
								className="inline-block blue-text hoverable"
								onClick={() => {
									cancel();
								}}
							>
								<Tooltip
									id="tooltip-top-start1"
									title="Back to List"
									placement="top"
								>
									<ArrowBack />
								</Tooltip>
							</div>
						)}
						{props.action !== "Add" && (
							<div
								className={`${
									props.action === "View" ? "orange" : "lightgray"
								}-text inline-block mr-20 hoverable`}
								onClick={() => {
									props.openForm("View", props.propertyData);
								}}
							>
								<Tooltip
									id="tooltip-top-start1"
									title="View Only Mode"
									placement="top"
								>
									<Visibility />
								</Tooltip>
							</div>
						)}
						{props.action !== "Add" && canEdit && (
							<div
								className={`${
									props.action === "Edit" ? "blue" : "lightgray"
								}-text inline-block mr-20 hoverable`}
								onClick={() => {
									props.openForm("Edit", props.propertyData);
								}}
							>
								<Tooltip
									id="tooltip-top-start1"
									title="Edit Mode"
									placement="top"
								>
									<Edit />
								</Tooltip>
							</div>
						)}
						{props.action !== "Add" && canDelete && !props.noDelete && (
							<div
								className={`lightgray-text inline-block mr-20 hoverable`}
								onClick={() => {
									props.openAskModal(dataObj);
								}}
							>
								<Tooltip
									id="tooltip-top-start3"
									title="Delete Property"
									placement="top"
								>
									<Delete />
								</Tooltip>
							</div>
						)}
						{props.action === "Add" ? (
							"Add Property"
						) : (
							<strong>
								{propertyData ? propertyData.name + " Property" : ""}
							</strong>
						)}
					</h3>
					{show_illi_owned_message && (
						<p><div><strong>illi Company Data</strong></div></p>
					)}
				</GridItem>
				<GridItem xs={12} sm={3} md={3}>
					{props.action !== "Add" && (
						<small className="gray-text">
							{propertyData ? (
								propertyData.illi_number ? (
									<strong className="blue-text">
										illi #: {propertyData.illi_number}
									</strong>
								) : (
									""
								) // - remove temp - propertyData.id
							) : (
								""
							)}
							<br />
							<strong className="blue-text">Date Created:</strong>{" "}
							{propertyData ? formatDate2(propertyData.created_date) + created_date : ""}
							<br />
							<strong className="blue-text">Date Last Modified:</strong>{" "}
							{propertyData ? formatDate2(propertyData.modified_date) + modified_date : ""}
							{!illiNumberExists && <Button
								color={'transparent'}
								style={{
									display: 'flex',
									justifyContent: 'center',
									paddingLeft: '0px',
									marginTop: '10px',
								}}
								onClick={() => {
									setOpenedReview(true);
								}}
							>
								<span className="blue-text">
									<i className="fas fa-save mr-10"></i> Save
								</span>
							</Button>}
						</small>
					)}
				</GridItem>
			</GridContainer>
				<br />
				{
					<Tabs
						TabIndicatorProps={{
							style: {
								background: "#da3b2f",
								borderBottom: "1px solid #da3b2f",
								color: "#da3b2f",
							},
						}}
						style={{ marginBottom: 10 }}
						value={getTabIndex()}
					>
						<Tab
							onClick={() => {
								setTab("Main");
							}}
							style={{
								color: getTabIndex() === 0 ? "#da3b2f" : "rgba(0, 0, 0, 0.87)",
							}}
							tabIndex={0}
							label="Property Info"
							icon={<i className="fas fa-building font-22"></i>}
						/>
						{["Lease", "Listing", "Meeting", "Offer", "Showing"].map((b, i) => {
							const icons = {
								Lease: <i className="fas fa-credit-card font-22"></i>,
								Listing: <i className="fas fa-clipboard-list font-22"></i>,
								Meeting: <Group />,
								Offer: <i className="fas fa-dollar-sign font-22"></i>,
								Showing: <i className="fas fa-boxes font-22"></i>,
								Submit: <i className="fas fa-save font-22"></i>,
							};
							if (props.action === "View" && b === "Submit") return null;
							if (props.action === "Edit" && b === "Submit") return null;
							const tabGotten = getTabIndex();
							if (illiNumberExists && b === "Submit") return null;
							return (
								<Tab
									key={`tb-${b}`}
									onClick={() => {
										setTab(b);
									}}
									style={{
										color:
											tabGotten === i + 1
												? "#da3b2f"
												: b === "Submit"
												? "#1d1e4c"
												: "rgba(0, 0, 0, 0.87)",
									}}
									tabIndex={i + 1}
									label={props.action === "Edit" && b === "Submit" ? "Save" : b}
									icon={icons[b]}
								/>
							);
						})}
						{
							<Tab
								tabIndex={6}
								style={{
									color:
										getTabIndex() === 6 ? "#da3b2f" : "rgba(0, 0, 0, 0.87)",
									display:
										props.action === "Add" || canDelete ? "block" : "none",
								}}
								label="Share Property"
								onClick={() => {
									setTab("Share");
								}}
								icon={<i className="fas fa-handshake font-22"></i>}
							/>
						}
						{
							<Tab
								tabIndex={7}
								style={{
									color:
										getTabIndex() === 7 ? "#da3b2f" : "rgba(0, 0, 0, 0.87)",
									display: props.action === "Add" ? illiNumberExists ? "none" : "block" : "none",
								}}
								label={props.action === "Edit" ? "Submit" : "Save"}
								onClick={() => {
									setOpenedReview(true);
								}}
								icon={<i className="fas fa-save font-22"></i>}
							/>
						}
						{props.action !== 'Add' &&
							<Tab
								tabIndex={8}
								style={{
									color:
										getTabIndex() === 8 ? "#da3b2f" : "rgba(0, 0, 0, 0.87)",
									display:
										props.action === "Add" || canDelete ? "block" : "none",
								}}
								label="Files"
								onClick={() => {
									setTab("Files");
								}}
								icon={<i className="fas fa-file font-22"></i>}
							/>
						}
                        {props.action !== 'Add' && <Tab
                            tabIndex={9}
                            style={{
                                color: getTabIndex() === 9 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)',
                                display: props.action === 'Add' || canDelete ? 'block' : 'none'
                            }}
                            label='Photos'
                            onClick={() => {
                                setTab('Photos');
                            }}
                            icon={<i className="fas fa-image font-22"></i>}
                        />}
					</Tabs>
				}

				<br />
				{["Main", "Spaces", "Owner", "Associate", "Other","Stages"].map(
					(t, i) => {
						if (
							tab !== "Main" &&
							tab !== "Spaces" &&
							tab !== "Owner" &&
							tab !== "Associate" &&
							tab !== "Other" &&
							tab !== "Stages" &&
							tab !== "Submit"
						)
							return null;
						if (props.action === "View" && t === "Submit") return null;
						if (props.action === "Add" && t === "Submit") return null;
						const name =
							t === "Submit" ? (
								<span>
									<i className="fas fa-save mr-10"></i>{" "}
									{props.action === "Edit" ? "Save" : "Submit"}
								</span>
							) : (
								t
							);
						if (dataObj) {
							if (dataObj.property_is_for) {
								if (dataObj.property_is_for.indexOf('Lease') === -1 && t === 'Spaces') {
									return null;
								}
								if (dataObj.property_is_for.indexOf('Lease') !== -1 && t === 'Spaces') {
									let shouldShow = false;
									if (dataObj.lease_space_type) {
										if (dataObj.lease_space_type === 'Lease with Multiple Spaces') {
											shouldShow = true;
										}
									}
									if (!shouldShow) return null;
								}
							} else {
								if (t === 'Spaces') {
									return null;
								}
							}
						}
						return (
							<Button
								key={`tab-${t}`}
								color={
									tab === t
										? "success"
										: t === "Submit"
										? "transparent"
										: "primary"
								}
								onClick={() => {
									if (t === 'Submit') {
										setOpenedReview(true);
									} else {
										setTab(t);
									}
								}}
								style={{ marginRight: 10 }}
							>
								<div className={t === "Submit" ? "blue-text" : ""}>{name}</div>
							</Button>
						);
					}
				)}
			{tab === "Main" && (
				<PropertiesFormMain
					action={props.action}
					dataObj={dataObj}
					updateDataObj={updateDataObj}
					setDataObj={setDataObj}
					checkPropertyExistingAddress={props.checkPropertyExistingAddress}
					existingAddress={props.existingAddress}
					property_is_for_override={props.property_is_for_override}
					property_options={property_options}
					setHasShownAgentMessage={setHasShownAgentMessage}
					hasShownAgentMessage={hasShownAgentMessage}
					propertyData={propertyData}
					sendToMarketing={props.sendToMarketing}
					loading_send_to_marketing={props.loading_send_to_marketing}
					success_send_to_marketing={props.success_send_to_marketing}
					setMadeChanges={setMadeChanges}
					illiNumberExists={illiNumberExists}
					illiNumberExistsProperty={illiNumberExistsProperty}
					checkIlliNumberForProperty={checkIlliNumberForProperty}

					propertyUseTypes={props.propertyUseTypes ? 
						props.propertyUseTypes 
					: 
						{ use_type: [], unit_use_type: [], lease_type: [] }}
				/>
			)}
			{tab === "Spaces" && (
				<PropertiesFormSpaces
					action={props.action}
					dataObj={dataObj}
					setDataObj={setDataObj}
					updateDataObj={updateDataObj}
					setMadeChanges={setMadeChanges}
					propertyUseTypes={props.propertyUseTypes ? 
						props.propertyUseTypes 
					: 
						{ use_type: [], unit_use_type: [], lease_type: [] }}
					sendToMarketing={props.sendToMarketing}
					loading_send_to_marketing={props.loading_send_to_marketing}
					success_send_to_marketing={props.success_send_to_marketing}
				/>
			)}
			{tab === "Owner" && (
				<PropertiesFormOwner
					action={props.action}
					dataObj={dataObj}
					updateDataObj={updateDataObj}
					getContacts={props.getContacts}
					getContact={props.getContact}
					contactsData={props.contactsData}
					contactData={props.contactData}
					getContactNames={props.getContactNames}
					contactNames={props.contactNames}
					setOpenedEditContact={setOpenedEditContact}
					setContactID={setContactID}
					setOpenedEditCompany={setOpenedEditCompany}
                    setCompanyID={setCompanyID}
					// ADD
					setContactsForAddNow={setContactsForAddNow}
					contactsForAdd={contactsForAdd}
					setCompanyForAddNow={setCompanyForAddNow}
					companyForAdd={companyForAdd}
				/>
			)}
			{tab === "Associate" && (
				<PropertiesFormAgent
					action={props.action}
					dataObj={dataObj}
					updateDataObj={updateDataObj}
					getContacts={props.getContacts}
					getContact={props.getContact}
					contactsData={props.contactsData}
					contactData={props.contactData}
					getContactNames={props.getContactNames}
					contactNames={props.contactNames}
					setOpenedEditContact={setOpenedEditContact}
					setContactID={setContactID}
					setDataObj={setDataObj}
					// ADD
					setContactsForAddNow={setContactsForAddNow}
					contactsForAdd={contactsForAdd}
				/>
			)}
			{tab === "Other" && (
				<PropertiesFormOther
					action={props.action}
					dataObj={dataObj}
					updateDataObj={updateDataObj}
					getContacts={props.getContacts}
					getContact={props.getContact}
					contactsData={props.contactsData}
					contactData={props.contactData}
					getContactNames={props.getContactNames}
					contactNames={props.contactNames}
					setOpenedEditContact={setOpenedEditContact}
					setContactID={setContactID}
					// ADD
					setContactsForAddNow={setContactsForAddNow}
					contactsForAdd={contactsForAdd}
					setCompanyForAddNow={setCompanyForAddNow}
					companyForAdd={companyForAdd}
				/>
			)}
			{tab === "Stages" && (
				<PropertiesFormStages
					action={props.action}
					dataObj={dataObj}
					updateDataObj={updateDataObj}
				/>
			)}
			{tab === "Share" && (
				<PropertiesSharing
					action={props.action}
					getPropertyShares={props.getPropertyShares}
					getPropertyUsernames={props.getPropertyUsernames}
					createPropertyShare={props.createPropertyShare}
					deletePropertyShare={props.deletePropertyShare}
					propertyShares={props.propertyShares}
					propertyUsernames={props.propertyUsernames}
					dataObj={dataObj}
					sharesForAdd={sharesForAdd}
					setSharesForAdd={setSharesForAdd}
				/>
			)}
			{openedReview && (
				<PropertiesFormReview
					setOpenedReview={setOpenedReview}
					action={props.action}
					setAction={props.setAction}
					dataObj={dataObj}
					setDataObj={setDataObj}
					createProperty={props.createProperty}
					updateProperty={props.updateProperty}
					updateDataObj={updateDataObj}
					closeForm={props.closeForm}
					closeDialog={props.closeDialog}
					resetData={props.resetData}
					getProperties={props.getProperties}
					success={props.success}
					error={props.error}
					leasesForAdd={leasesForAdd}
					listingsForAdd={listingsForAdd}
					meetingsForAdd={meetingsForAdd}
					offersForAdd={offersForAdd}
					showingsForAdd={showingsForAdd}
					sharesForAdd={sharesForAdd}
					contactsForAdd={contactsForAdd}
					companyForAdd={companyForAdd}
					theContactData={props.theContactData}
					formDataObj={formDataObj}
					existingAddress={props.existingAddress}
					propertySuccessData={props.propertySuccessData}
					getProperty={props.getProperty}
					skipSuccessMsg={closeWithoutSaving}
				/>
			)}
			{tab === "Lease" && (
				<Lease
					propertyAction={props.action}
					leasesForAdd={leasesForAdd}
					setLeasesForAdd={setLeasesForAdd}
					canEdit={canEdit}
					canDelete={canDelete}
					dataObj={dataObj}
					fileList={props.fileList}
					getCrmFileList={props.getCrmFileList}
					getCrmFile={props.getCrmFile}
					createCrmFile={props.createCrmFile}
				/>
			)}
			{tab === "Listing" && (
				<Listing
					propertyAction={props.action}
					listingsForAdd={listingsForAdd}
					setListingsForAdd={setListingsForAdd}
					canEdit={canEdit}
					canDelete={canDelete}
					dataObj={dataObj}
				/>
			)}
			{tab === "Meeting" && (
				<Meeting
					propertyAction={props.action}
					meetingsForAdd={meetingsForAdd}
					setMeetingsForAdd={setMeetingsForAdd}
					canEdit={canEdit}
					canDelete={canDelete}
					dataObj={dataObj}
				/>
			)}
			{tab === "Offer" && (
				<Offer
					propertyAction={props.action}
					offersForAdd={offersForAdd}
					setOffersForAdd={setOffersForAdd}
					canEdit={canEdit}
					canDelete={canDelete}
					dataObj={dataObj}
				/>
			)}
			{tab === "Showing" && (
				<Showing
					propertyAction={props.action}
					showingsForAdd={showingsForAdd}
					setShowingsForAdd={setShowingsForAdd}
					canEdit={canEdit}
					canDelete={canDelete}
					dataObj={dataObj}
				/>
			)}
			{tab === "Files" && (
				<FilesForm
					propertyAction={props.action}
					showingsForAdd={showingsForAdd}
					setShowingsForAdd={setShowingsForAdd}
					canEdit={canEdit}
					canDelete={canDelete}
					dataObj={dataObj}
					formDataObj={formDataObj}
					setFormDataObj={setFormDataObj}
					closeForm={props.closeForm}
					showCloseBtn={props.showCloseBtn}
					downloadedFile={props.downloadedFile}
					clearMessages={props.clearMessages}
					success={props.success}
					error={props.error}
					contactSuccess={props.contactSuccess}
					contactError={props.contactError}
					contactLoading={props.contactLoading}
					fileList={props.fileList}
					getCrmFileList={props.getCrmFileList}
					getCrmFile={props.getCrmFile}
					createCrmFile={props.createCrmFile}
					deleteCrmFile={props.deleteCrmFile}
					action={props.action}
				/>
			)}
			{tab === 'Photos' && <PropertiesFormPhotos
				action={props.action}
				dataObj={dataObj}
				updateDataObj={updateDataObj}
				canEdit={canEdit}
				updateNotes={canEdit ? true : false}
			/>}
            {/* {tab === 'Photos' && <PhotosForm
                propertyAction={props.action}
                showingsForAdd={showingsForAdd}
                setShowingsForAdd={setShowingsForAdd}
                canEdit={canEdit}
                canDelete={canDelete}
                dataObj={dataObj}
                formDataObj={formDataObj}
                setFormDataObj={setFormDataObj}

                closeForm={props.closeForm}
                showCloseBtn={props.showCloseBtn}
                downloadedFile={props.downloadedFile}
                clearMessages={props.clearMessages}
                success={props.success}
                error={props.error}
				contactLoading={props.contactLoading}
                contactSuccess={props.contactSuccess}
                contactError={props.contactError}
                fileList={props.fileList}
                getCrmFileList={props.getCrmFileList}
                getCrmFile={props.getCrmFile}
                createCrmFile={props.createCrmFile}
                deleteCrmFile={props.deleteCrmFile}
				action={props.action}
            />} */}
			{openedEditContact && (
				<PropertiesEditContact
					open={openedEditContact}
					close={() => {
						setOpenedEditContact(false);
					}}
					contactID={contactID}
				/>
			)}
			{openedEditCompany && (
				<CustomDialogCompanyView
					open={openedEditCompany}
					close={() => {
						setOpenedEditCompany(false);
					}}
					companyID={companyID}
				/>
			)}

			{(props.propertyDocuments && canEdit && canDelete) ? props.propertyDocuments : null}
		</div>
	);
};

const mapStateToProps = state => {
	return {
	  property_options: state.crm_properties.options,
	  updated_property: state.crm_properties.updated_property,
	  loading_send_to_marketing: state.crm_properties.loading_send_to_marketing,
	  success_send_to_marketing: state.crm_properties.success_send_to_marketing,
	  success_illi_number_check: state.crm_properties.success_illi_number_check,
	  error_illi_number_check: state.crm_properties.error_illi_number_check,
	}
  }

  const mapDispatchToProps = dispatch => {
	return {
		sendToMarketing: (data) => {
			dispatch(actions.createSendToMarketing(data));
		},
		getPropertyDocuments: (data) => {
			dispatch(actions.getPropertyDocuments(data));
		}
	}
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(PropertiesForm);
