const meetingFields = {
    start_date: {
        type: 'date',
        label: 'Start Date',
        validations: ['required']
    },
    start_time: {
        type: 'time',
        label: 'Start Time',
        validations: ['required']
    },
    end_date: {
        type: 'date',
        label: 'End Date',
        validations: ['required']
    },
    end_time: {
        type: 'time',
        label: 'End Time',
        validations: []
    },
    invitees: {
        type:'multi'
    },
    outcome: {
        label: 'Outcome',
        type: 'select',
        options: ['Secured Listing', 'Scheduled Followup', 'No Interest', 'Follow Up Later']
    },
    notes: {
        type: 'text',
        label: 'Notes',
        validations: ['min1','max250']
    }
}

export default meetingFields;

export const initialMeetingData = {
    start_date: '',
    start_time: '09:00 AM',
    end_date: '',
    end_time: '10:00 AM',
    other_data: {
        invitees: [],
        files: []
    },
    outcome: '',
    notes: ''
}