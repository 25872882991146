import React, { useState, useEffect } from 'react';
import Close from '@material-ui/icons/Close';
import Notification from 'components/Notification/Notification';
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { IconButton, TextField } from '@material-ui/core';
import { clone } from 'shared/utility';
import { getUsername } from 'shared/utility';
import { getUserID } from 'shared/authValidation';
import download from 'downloadjs';
import ClipLoader from "react-spinners/ClipLoader";
import FileUploader from '../../../../components/FileUploader/FileUploader';
import CustomInput from '../../../../components/CustomInput/CustomInputValidate';

const FilesForm2 = (props) => {

    const data = props.data;

    if (!data) return null;

    const files = data.other_data?.files ? data.other_data?.files : [];

    console.log('FilesForm2', props, data, files);

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Button color="primary" onClick={() => {
                        const newData = clone(data);
                        console.log('nd', newData)
                        if (!newData.other_data) {
                            newData.other_data = {
                                files
                            }
                        }
                        if (!newData.other_data.files) newData.other_data.files = [];
                        newData.other_data.files.push({
                            filename: '',
                            description: ''
                        })

                        props.updateDataObj('other_data', {
                            ...data.other_data,
                            files: newData.other_data.files
                        });
                    }}>
                        ADD FILE
                    </Button>
                    <GridContainer>
                    {
                        files.map((f,i) => {
                            return <GridItem xs={12} sm={12} md={3} key={`${i}-fileform`}>
                            <div className="boxed mr-20 mt-20">
                                <FileUploader 
                                    title={`File ${i+1}`}
                                    update={(val) => {
                                        const newData = clone(data);
                                        if (!newData.other_data.files) newData.other_data.files = [];
                                        newData.other_data.files = newData.other_data.files.map((ff,ii) => {
                                            if (ii === i) {
                                                ff.filename = val;
                                                return ff;
                                            }
                                            return ff;
                                        })
                                        props.updateDataObj('other_data', {
                                            ...data.other_data,
                                            files: newData.other_data.files
                                        });
                                    }}
                                    remove={() => {
                                        const newData = clone(data);
                                        if (!newData.other_data.files) newData.other_data.files = [];
                                        newData.other_data.files = newData.other_data.files.filter((ff,ii) => {
                                            if (ii === i) return false;
                                            return true;
                                        })
                                        props.updateDataObj('other_data', {
                                            ...data.other_data,
                                            files: newData.other_data.files
                                        });
                                    }}
                                    the_filename={f.filename}
                                    type="crm"
                                    accept="application/pdf,image/*"
                                    // fileType="application/pdf"
                                    disabled={props.action === 'View' ? true : false}
                                />

                                <CustomInput 
                                    value={f.description}
                                    onChange={(e) => {
                                        const newData = clone(data);
                                        if (!newData.other_data.files) newData.other_data.files = [];
                                        newData.other_data.files = newData.other_data.files.map((ff,ii) => {
                                            if (ii === i) {
                                                ff.description = e.target.value;
                                                return ff;
                                            }
                                            return ff;
                                        })
                                        props.updateDataObj('other_data', {
                                            ...data.other_data,
                                            files: newData.other_data.files
                                        });
                                    }}
                                    label="Description"
                                />
                            </div>
                        </GridItem>
                        })
                    }
                    </GridContainer>
                </GridItem>
            </GridContainer>
        </div>
    )

}

export default FilesForm2;