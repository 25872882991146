import React from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { clone } from "../../shared/utility";
import CustomTextArea from "components/CustomTextArea/CustomTextArea.jsx";

const Step4a = (props) => {
    const { final_approvers, dataObj, setDataObj, currentUserIsAnAgentApprover, renderElement } = props;
    return <div className={
        (props.parentTab === 'my_completed' || props.parentTab === 'all_completed' || props.action === 'view') ? 'gray-bg no-click' : ''
    }>
        <div className={currentUserIsAnAgentApprover || props.action === 'view' ? 'no-click' : ''}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <CustomTextArea
                        label="Notes"
                        value={dataObj.notes_now}
                        onChange={(e) => {
                            const newData = clone(dataObj);
                            newData.notes_now = e.target.value;
                            setDataObj(newData);
                        }}
                        validate={[]}
                    />
                    {/* {renderElement('notes_now')} */}
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    
                </GridItem>
            </GridContainer>
        </div>
    </div>
}

export default Step4a;